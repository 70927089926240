import { DISPLAY_TYPES } from "constants/defaultInputValueTypes";
import React from "react";
import { Link } from "react-router-dom";
import Validation from "util/validate";
import { CONTRACT_EDIT_INFO } from "../../../../../../../constants/contractEditPageInfo";
import { SEND_OTHER_PAPER } from "../../../../../../../constants/messages";
import InputCheckbox from "../../input/inputCheckbox";
import Input from "../../input/inputNormal";
import InputTextRemark from "../../input/inputTextRemark";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 土地 */
export default class Block3 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020203",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    handleTo0101 = e => {
        e.preventDefault();
        // リロードをかける
        this.props.pageSwitch(CONTRACT_EDIT_INFO.PAGE_0101.id);
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        //JYU00296の入力上限判定
        const JYU00296LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00296_${this.props.displayTypeID}`, this.props.pageState.JYU00296);

        // 斜線対応の設定(関連チェックボックスの非活性化のみ)
        let JYU00273_CheckOn = undefined;
        if (this.props.displayTypeID !== DISPLAY_TYPES.SEGMENT_BUILDING.value
            && this.props.pageState.JYU00273) {
            // 関連チェックボックスの非活性化
            JYU00273_CheckOn = "disabled";
        }

        const items = this.props.pageState.TOR00237_ARR !== undefined ? this.props.pageState.TOR00237_ARR : [];
        const domItems = items.map((item, idx) => {

            let itemTOR00238Name = "地番（代表）";
            let itemTOR00239 = (
                <React.Fragment>
                    <div className="dataTableUnit-text">他</div>
                    <div className="dataTableUnit-text">{item.TOR00239}</div>
                    <div className="dataTableUnit-text">筆</div>
                </React.Fragment>
            );

            // 2件目以降の表示制御
            if (idx !== 0) {
                itemTOR00238Name = "地番";
                itemTOR00239 = null;
            }

            return (
                <React.Fragment key={idx}>
                    <tr>
                        <th rowSpan="5" className="dataTable-title dataTable-title__w120 dataTable-title__center">{idx + 1}</th>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在</th>
                        <td className="dataTable-body">{item.TOR00237}</td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">{itemTOR00238Name}</th>
                        <td className="dataTable-body" colSpan="2">
                            <div className="dataTableUnit">
                                <div className="dataTableUnit-text">{item.TOR00238}</div>
                                {itemTOR00239}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">地目</th>
                        <td className="dataTable-body">{item.TOR00240}</td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">地積</th>
                        <td className="dataTable-body">
                            <div className="dataTableUnit">
                                <div className="dataTableUnit-text">公簿</div>
                                <div className="dataTableUnit-text">{item.TOR00241}m<sup>2</sup></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">持分</th>
                        <td className="dataTable-body">{item.TOR00243}</td>
                    </tr>
                </React.Fragment>
            );
        });

        return (
            <React.Fragment>
                <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">土地</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                {domItems}
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">合計</th>
                                    <td className="dataTable-body" colSpan="2">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-text">{this.props.pageState.TOR00256}</div>
                                            <div className="dataTableUnit-text">筆</div>
                                            <div className="dataTableUnit-text">地積（登記簿）</div>
                                            <div className="dataTableUnit-text">{this.props.pageState.TOR00257}m<sup>2</sup></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">土地の売買<br />対象面積</th>
                                    <td className="dataTable-body" colSpan="2">
                                        <ul className="l-checkBox">
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_JYU00264" name="JYU00264" checked={this.props.pageState.JYU00264} {...params} />
                                                    <span className="checkBox-text">1.登記簿（公簿）面積による</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_JYU00265" name="JYU00265" checked={this.props.pageState.JYU00265} {...params} />
                                                    <span className="checkBox-text">2.実測面積による</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_JYU00266" name="JYU00266" checked={this.props.pageState.JYU00266} {...params} />
                                                    <span className="checkBox-text">3.測量によって得られた面積による</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">実測面積</th>
                                    <td className="dataTable-body" colSpan="2">
                                        <div className="dataTableUnit">
                                            <Input id="ID_JYU00251" name="JYU00251" value={this.props.pageState.JYU00251} {...params} length="w112" />
                                            <div className="dataTableUnit-text">m<sup>2</sup></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="3">測量図面</th>
                                    <td className="dataTable-body" colSpan="2">
                                        <div className="dataTableMultiple">
                                            <div className="dataTableMultiple-item">
                                                <ul className="l-checkBox">
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_JYU00267" name="JYU00267" checked={this.props.pageState.JYU00267} {...params} disabled={JYU00273_CheckOn} />
                                                            <span className="checkBox-text">1.測量図</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_JYU00268" name="JYU00268" checked={this.props.pageState.JYU00268} {...params} disabled={JYU00273_CheckOn} />
                                                            <span className="checkBox-text">2.確定測量図</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="dataTableMultiple-item">
                                                <div className="dataTableUnit">
                                                    <div className="dataTableUnit-input">
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_JYU00269" name="JYU00269" checked={this.props.pageState.JYU00269} {...params} />
                                                            <span className="checkBox-text">1.</span>
                                                        </label>
                                                    </div>
                                                    <Input id="ID_JYU00270" name="JYU00270" value={this.props.pageState.JYU00270} {...params} length="w112" />
                                                    <div className="dataTableUnit-text">付</div>
                                                    <div className="dataTableUnit-text">作製者</div>
                                                    <Input id="ID_JYU00271" name="JYU00271" value={this.props.pageState.JYU00271} {...params} length="w333" />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dataTable-body" colSpan="2">
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU00272" name="JYU00272" checked={this.props.pageState.JYU00272} {...params} />
                                            <span className="checkBox-text">2.残代金支払日までに買主に交付</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dataTable-body" colSpan="2">
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU00273" name="JYU00273" checked={this.props.pageState.JYU00273} {...params} />
                                            <span className="checkBox-text">3.作製・交付予定なし　※入力不要の場合は、斜線を引きます。</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">権利の種類</th>
                                    <td className="dataTable-body" colSpan="2">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-input">
                                                <ul className="l-checkBox">
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_JYU00291" name="JYU00291" checked={this.props.pageState.JYU00291} {...params} />
                                                            <span className="checkBox-text">1.所有権</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_JYU00292" name="JYU00292" checked={this.props.pageState.JYU00292} {...params} />
                                                            <span className="checkBox-text">2.借地権</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="dataTableUnit-text">（</div>
                                            <div className="dataTableUnit-input">
                                                <ul className="l-checkBox">
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_JYU00293" name="JYU00293" checked={this.props.pageState.JYU00293} {...params} />
                                                            <span className="checkBox-text">地上権</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_JYU00294" name="JYU00294" checked={this.props.pageState.JYU00294} {...params} />
                                                            <span className="checkBox-text">賃借権</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="dataTableUnit-text">）</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">借地権の場合<br />借地対象面積</th>
                                    <td className="dataTable-body" colSpan="2">
                                        <div className="dataTableUnit">
                                            <Input id="ID_JYU00295" name="JYU00295" value={this.props.pageState.JYU00295} {...params} length="w112" />
                                            <div className="dataTableUnit-text">m<sup>2</sup></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                    <td className="dataTable-body" colSpan="4">
                                        <div className="dataTableTextUnit">
                                            <InputTextRemark id="ID_JYU00296" name="JYU00296" value={this.props.pageState.JYU00296} text={SEND_OTHER_PAPER.JYU00296} placeholder={JYU00296LimitErrorMessage.inputLimitGuide} {...params} />
                                            <p className="LengthOver-error">{JYU00296LimitErrorMessage.inputLimitErrorMessage}</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="l-dataListMore">
                    <Link to="" aria-label="link" className="dataListMore" onClick={this.handleTo0101}>上記内容を修正したい場合は、こちらをクリックしてください</Link>
                </div>
            </React.Fragment>
        );
    }
}