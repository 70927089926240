// ログイン
export const LOGIN = { path: "/public/login", QUERY: { from: "from" }, title: "ログイン" };
export const LOGOUT = { path: "/public/logout", title: "ログアウト" };

// パスワード変更
export const PASSWORD_REMINDER = { path: "/public/password_reminder", title: "パスワードリマインダー" };
export const PASSWORD_RESET = { path: "/public/password_reset", title: "パスワード再設定" };
export const SUCCESSFUL = { path: "/public/successful", title: "成功完了" };
export const TIMEOUT = { path: "/public/timeout", title: "タイムアウト通知" };
export const PASSWORD_CHANGE = { path: "/user/password_change", title: "パスワード変更" };
export const PASSWORD_CHANGE_COMPLETE = { path: "/user/password_change_complete", title: "パスワード変更完了" };

// ホーム
export const DASHBOARD = { path: "/user/dashboard", title: "ダッシュボード", menu: "ホーム" };

// 契約情報種別選択
export const CONTRACT_REGISTER = { path: "/user/contract/register", title: "契約情報作成", menu: "新規作成" };

// 契約情報種別選択（FRK書式）
export const CONTRACT_REGISTER_FRK = { path: "/user/contract_frk/register", title: "契約情報作成", menu: "新規作成" };

// 契約情報入力
// export const CONTRACT_TYPE_SELECTION = { path: "/user/contract_type_selection", title: "契約種別選択", menu: "新規作成" };
export const CONTRACT_SHARING = { path: "/user/contract_sharing", title: "契約情報共有" };
export const EMPLOYEE_LIST = { path: "/user/employee_list", title: "従業員一覧" };
export const PAST_CONTRACT_LIST = { path: "/user/past_contract_list", title: "過去情報一覧" };
export const CONTRACT_LIST = { path: "/user/contract_list", title: "契約情報一覧", menu: "契約書一覧" };
export const CONTRACT_EDIT = { path: "/user/contract/:contractId/:pageId/edit", title: "契約情報入力" };
export const CONTRACT_PREVIEW = { path: "/user/contract/:contractId/:tabId/:pageId/preview", title: "契約情報" };

// 契約情報入力（FRK書式）
export const CONTRACT_LIST_FRK = { path: "/user/contract_list_frk", title: "契約情報一覧", menu: "契約書一覧" };
export const CONTRACT_EDIT_FRK = { path: "/user/contract_frk/:contractId/:pageId/edit", title: "契約情報入力" };
export const CONTRACT_PREVIEW_FRK = { path: "/user/contract_frk/:contractId/:tabId/:pageId/preview", title: "契約情報" };

// メンテナンス機能関連
export const MY_COMPANY_INFO_MAINTENANCE = { path: "/admin/my_company_maintenance", title: "自社情報メンテナンス", menu: "自社情報" };
export const BRANCH_AND_ORGANIZATION_INFO_LIST = { path: "/admin/branch_and_organization_list", title: "支店/組織情報一覧", menu: "支店・組織情報" };
export const BRANCH_AND_ORGANIZATION_INFO_MAINTENANCE = { path: "/admin/branch_and_organization_maintenance", title: "支店/組織情報メンテナンス" };
export const BRANCH_AND_ORGANIZATION_INFO_MAINTENANCE_UPDATE = { path: "/admin/branch_and_organization_maintenance/:id", title: "支店/組織情報メンテナンス" };
export const EMPLOYEE_INFO_LIST = { path: "/admin/employee_list", title: "従業員情報一覧", menu: "従業員情報" };
export const EMPLOYEE_INFO_LIST_FRK = { path: "/admin/employee_list_frk", title: "従業員情報一覧", menu: "従業員情報" };
export const EMPLOYEE_INFO_MAINTENANCE = { path: "/admin/employee_maintenance", title: "従業員情報メンテナンス" };
export const EMPLOYEE_INFO_MAINTENANCE_FRK = { path: "/admin/employee_maintenance_frk", title: "従業員情報メンテナンス" };
export const EMPLOYEE_INFO_MAINTENANCE_UPDATE = { path: "/admin/employee_maintenance/:id", title: "従業員情報メンテナンス" };
export const EMPLOYEE_INFO_MAINTENANCE_UPDATE_FRK = { path: "/admin/employee_maintenance_frk/:id", title: "従業員情報メンテナンス" };
export const GUARANTEE_INFO_LIST = { path: "/admin/guarantee_list", title: "保証協会情報一覧", menu: "保証協会情報\n（供託所情報）" };
export const GUARANTEE_INFO_MAINTENANCE = { path: "/admin/guarantee_maintenance", title: "保証協会情報メンテナンス" };
export const GUARANTEE_INFO_MAINTENANCE_UPDATE = { path: "/admin/guarantee_maintenance/:id", title: "保証協会情報メンテナンス" };
export const INPUT_ITEM_LIST = { path: "/admin/input_item_list", title: "名称マスタ一覧", menu: "名称" };
export const INPUT_ITEM_MAINTENANCE = { path: "/admin/input_item_maintenance/:type", title: "名称マスタメンテナンス" };
export const INPUT_ITEM_MAINTENANCE_UPDATE = { path: "/admin/input_item_maintenance/:type/:id", title: "名称マスタメンテナンス" };
export const DEFAULT_INPUT_VALUE_MAINTENANCE_UPDATE = { path: "/admin/default_input_value_maintenance", title: "デフォルト表記メンテナンス", menu: "デフォルト表記" };
export const TEMPLATE_LIST = { path: "/admin/template_list", title: "定型文一覧", menu: "定型文" };
export const TEMPLATE_MAINTENANCE = { path: "/admin/template_maintenance/:templateHeadlineId/:type", title: "定型文メンテナンス" };
export const CLAUSE_AND_DOCUMENT_MAINTENANCE = { path: "/admin/clauseand_document_maintenance", title: "約款・別表差込メンテナンス", menu: "約款・別表差込" };
export const CLAUSE_AND_DOCUMENT_MAINTENANCE_FRK = { path: "/admin/clauseand_document_maintenance_frk", title: "約款・別表差込メンテナンス", menu: "約款・別表差込" };

// 参考書類ファイル選択
export const SELECT_REF_DOCUMENT = { path: "/user/ref_document", title: "参考書類ファイル選択" };
export const REF_DOCUMENT = { path: "/user/ref_document", title: "参考書類認識結果" };

// タイムアウト
export const SESSION_TIMEOUT = { path: "/session_timeout", title: "タイムアウト", };

// IPアドレスNG
export const IP_ADDRESS_NG = { path: "/ip_address_ng", title: "許可されていないIPアドレスからのアクセスエラー", };
