export function admin(state = false, action) {
    switch (action.type) {
        case "ADMIN":
            return true;

        case "NO_ADMIN":
            return false;

        default:
            return state;
    }
}

// 確定権を持つかどうか
export function confirm(state = false, action) {
    switch (action.type) {

        case "CONFIRM":
            return true;

        case "NOT_CONFIRM":
            return false;

        default:
            return state;
    }
}
