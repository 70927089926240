import React from "react";
import { Link } from "react-router-dom";
import * as helper from "../helper";
import { InputBase } from "./inputBase";

export default class InputEmployee extends InputBase {

    constructor(props) {
        super(props);

        this.state = {
            unit: "", // 表示する単位
            length: this.props.length, // 表示幅
            errorDisp: false // true: 表示する
        };

        // 先祖から設定された値の検証
        let tmp = {};
        tmp[helper.genValidKey(this.props.name)] = this.validate(this.props.value); // 有効化かどうか（true:有効）
        this.props.updatePageState(tmp, false); // 先祖のstateを更新するのでレンダリングが走る
    }

    /** 入力欄更新時のハンドラ */
    handleUpdate = e => {
        let inputValue = e.target.value;

        // 値の更新（先祖コンポーネントから渡されたstateの更新）
        let tmp = {};
        tmp[this.props.name] = inputValue; // 値
        tmp[helper.genValidKey(this.props.name)] = this.validate(inputValue); // 有効化かどうか（true:有効）
        this.props.updatePageState(tmp); // 先祖のstateを更新するのでレンダリングが走る
    }

    /** 初期表示用の処理 */
    componentDidMount() {
        /** 初期表示時に空の場合はエラーメッセージを出さない */
        if (this.props.value === undefined) { return; }

        /** 初期表示時に異常値の場合はエラーメッセージを出す */
        let inputValue = this.props.value;
        if (!this.validate(inputValue)) {
            this.setState({ errorDisp: true });
        }
    }

    /**
     * フォーカスアウト時のハンドラ
     * ※フォーカスアウトした際にエラーメッセージを出すかどうかを決める
     **/
    handlerBlur = e => {
        let inputValue = e.target.value;
        if (!this.validate(inputValue)) {
            this.setState({ errorDisp: true });
        } else {
            this.setState({ errorDisp: false });
        }
    }

    /** 表示する際に整形が必要な場合、表示用の変換形式をここで定義 */
    formatter = (str) => {
        return str;
    }

    /**
     * バリデートロジックを定義する。
     * true: エラー無し false: エラー有り
     **/
    validate = (val) => {
        // 入力無しは許容
        //
        // ※valの状態は下記を前提とする
        // 最初から入力欄が空だった場合はundefined
        // 変更して空にした場合は空文字列
        if (val === undefined) {
            return undefined;
        }
        return true;
    }

    /**
     * ポップアップの表示制御を行う
     * ・支店／組織ポップアップの表示フラグをtrueへ変更する
     * ・ポップアップの入力時の値設定ターゲットをnameで指定された項目へ変更する
     */
    handlePopup = e => {
        e.preventDefault();
        this.props.updatePageState({ dispPopupEmp: true, popupTarget: this.props.name, popupFrom: this.props.from });
    }

    render() {
        const disabled = this.isDisabled(this.props.pageState.disableItem);
        this.handlePopup = disabled ? e => { e.preventDefault(); } : this.handlePopup; // disabledの時はルーペボタン無効化

        // バリデートエラーの場合、エラー用のクラスを付与
        // ※初期表示時の未入力部分にはエラーを出さない
        let classname = "dataTableUnit-input is-flex_full";
        if (this.state.errorDisp) {
            classname = "dataTableUnit-input is-flex_full is-error";
        }

        // 幅指定時
        let inputClass = "";
        if (this.state.length === "w113") {
            inputClass = "input__w113";
            classname = "dataTableUnit-input";
            if (this.state.errorDisp) {
                classname = "dataTableUnit-input is-error";
            }
        }

        // propsとしてvalueが設定がされていない場合、空文字列
        let dispValue = this.props.value === undefined ? "" : this.props.value + "";

        // バリデートエラーの場合、入力値をそのまま出力する
        // バリデートエラーでない場合、formatterをかまして画面表示
        dispValue = this.validate(dispValue) ? this.formatter(dispValue) : dispValue;

        return (
            <React.Fragment>
                <div className={classname}>
                    <input type="text" aria-label="text" id={this.props.id} name={this.props.name}
                        className={inputClass} onChange={this.handleUpdate} onBlur={this.handlerBlur} value={dispValue} disabled={disabled}
                    />
                    <span className="dataTableUnit-input-error">
                        <span className="dataTableUnit-input-error_text">{this.errorMessage}</span>
                    </span>
                </div>
                <div className="dataTableUnit-action"><Link to="" aria-label="link" className="textAreaAction textAreaAction--loupe" onClick={this.handlePopup}></Link></div>
            </React.Fragment>
        );
    }
}