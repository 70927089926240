import React from "react";
import * as helper from "../helper";

export default class PopupBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: undefined, // 表示するべきデータを保持する（APIからのレスポンスが入る）
            keyword: "", // キーワード
            activePage: 1, // 現ページ
            itemsCountPerPage: 10, // 1ページに何件表示するか
            totalItemsCount: 0, // 総件数
            pageRangeDisplayed: 5, // ページネーションで表示するページ数
        };
        this.selectedIdx = undefined; // 選択した項目のインデックス
    }

    // 選択ボタンのハンドラ
    handleSelect = e => {
        e.preventDefault();
        let tmp = {};

        // 何も選択しないで選択ボタンを押下された場合、閉じて終わる
        if (this.selectedIdx === undefined) {
            tmp[this.props.popupFlagName] = false;
            this.props.updatePageState({ ...tmp }, true);
            this.setState({ data: undefined, totalItemsCount: 0 }); // コンポーネントが持っているAPIからの取得値をクリア
            return;
        }

        // ページ側の契約情報を更新する（＆文字列に変換する）
        // ページ側のポップアップ表示管理のフラグをfalseにする
        tmp[this.props.to] = this.state.data[this.selectedIdx][this.props.from] + "";
        tmp[helper.genValidKey(this.props.to)] = true; // ポップアップでの選択なので必ずtrue
        tmp[this.props.popupFlagName] = false;
        this.props.updatePageState({ ...tmp }, true);
        this.setState({ data: undefined, totalItemsCount: 0, activePage: 1 }); // コンポーネントが持っているAPIからの取得値をクリア
        this.selectedIdx = undefined; // 選択のリセット
    }

    // キャンセルボタンのハンドラ
    handleCancel = e => {
        e.preventDefault();
        let tmp = {};
        tmp[this.props.popupFlagName] = false;
        this.props.updatePageState({ ...tmp }, false); // ページ側のポップアップ表示管理のフラグをfalseにする
        this.setState({ data: undefined, totalItemsCount: 0, activePage: 1 }); // コンポーネントが持っているAPIからの取得値をクリア
        this.selectedIdx = undefined; // 選択のリセット
    }

    // ページネーションのハンドラ
    handlePagination = page => {
        this.setState({ data: undefined, activePage: page });
    }

    render() {
        // ポップアップの表示制御
        let disp = this.props.pageState[this.props.popupFlagName] ? "block" : "none";

        // 一覧部分の生成
        let table = null;
        // ポップアップが表示される時の処理
        if (this.state.data === undefined && disp === "block") {
            // APIコールしてデータを取得する
            // ※データ取得されるまでは空を表示する
            // ※RequestAPI()内でAPIレスポンスをsetState()し、再レンダリングさせる
            this.RequestAPI();
            table = this.createEmptyItems();

        } else if (this.state.data !== undefined && disp === "block") {
            // ポップアップ表示中に保持しているデータが更新された場合（初回表示時、ページ指定時）の処理
            // ※APIのレスポンスでデータ更新があった場合の処理
            table = this.createItems(this.state.data);
        }

        // ページネーションの設定
        const pageSettings = {
            activePage: this.state.activePage,
            itemsCountPerPage: this.state.itemsCountPerPage,
            totalItemsCount: this.state.totalItemsCount,
            pageRangeDisplayed: this.state.pageRangeDisplayed,
            onChange: this.handlePagination,
        };

        return (
            <div style={{ display: disp }}>
                {this.createFrame(disp, table, pageSettings)}
            </div>
        );
    }
}