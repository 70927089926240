import InputTextArea from "components/page/user/contract/edit/input/inputTextArea";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";
import Validation from "util/validate";

/** その他 */
export default class Block5 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021705",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        //JYU01547の入力上限判定
        const JYU01547LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU01547_${this.props.displayTypeID}`, this.props.pageState.JYU01547);
        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">その他</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">その他</th>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableTextUnit">
                                        <div className="dataTableTextUnit-body">
                                            <InputTextArea id="ID_JYU01547" name="JYU01547" cols={30} rows={10} height="h165" value={this.props.pageState.JYU01547} {...params} placeholder={JYU01547LimitErrorMessage.inputLimitGuide} {...params} />
                                            <p className="LengthOver-error">{JYU01547LimitErrorMessage.inputLimitErrorMessage}</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}