import React from "react";
import * as dateUtil from "util/dateUtil";
import InputCheckbox from "../../input/inputCheckbox";
import Input from "../../input/inputNormal";
import Select from "../../input/inputSelect";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 建物 */
export default class Block8 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010108",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">建物</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住居表示</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00258" name="TOR00258" value={this.props.pageState.TOR00258} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00259" name="TOR00259" value={this.props.pageState.TOR00259} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">家屋番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00260" name="TOR00260" value={this.props.pageState.TOR00260} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">種類</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00261" name="TOR00261" value={this.props.pageState.TOR00261} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">構造</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00262" name="TOR00262" value={this.props.pageState.TOR00262} {...params} length="w329" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="2">床面積</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_TOR00316" name="TOR00316" checked={this.props.pageState.TOR00316} {...params} />
                                            <span className="checkBox-text">1.登記簿</span>
                                        </label>
                                        <div className="dataTableUnit dataTableUnit--leftofset__w80">
                                            <div className="dataTableUnit-text no-wrap">1階</div>
                                            <Input id="ID_TOR00317" name="TOR00317" value={this.props.pageState.TOR00317} {...params} />
                                            <div className="dataTableUnit-text no-wrap">m<sup>2</sup></div>
                                            <div className="dataTableUnit-text no-wrap">2階</div>
                                            <Input id="ID_TOR00318" name="TOR00318" value={this.props.pageState.TOR00318} {...params} />
                                            <div className="dataTableUnit-text no-wrap">m<sup>2</sup></div>
                                            <Input id="ID_TOR00319" name="TOR00319" value={this.props.pageState.TOR00319} {...params} length="w270" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_TOR00320" name="TOR00320" checked={this.props.pageState.TOR00320} {...params} />
                                            <span className="checkBox-text">2.現況</span>
                                        </label>
                                        <div className="dataTableUnit dataTableUnit--leftofset__w80">
                                            <Input id="ID_TOR00321" name="TOR00321" value={this.props.pageState.TOR00321} {...params} length="w575" />
                                            <div className="dataTableUnit-text">合計</div>
                                            <Input id="ID_TOR00322" name="TOR00322" value={this.props.pageState.TOR00322} {...params} length="w113" />
                                            <div className="dataTableUnit-text no-wrap">m<sup>2</sup></div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center">建築時期</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Select id="ID_TOR00323" name="TOR00323" value={this.props.pageState.TOR00323} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} length="w80" />
                                        <Select id="ID_TOR00324" name="TOR00324" value={this.props.pageState.TOR00324} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} length="w80" />
                                        <div className="dataTableUnit-text">年</div>
                                        <Select id="ID_TOR00325" name="TOR00325" value={this.props.pageState.TOR00325} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} length="w80" />
                                        <div className="dataTableUnit-text">月</div>
                                        <div className="dataTableUnit-text">新築</div>
                                        <Select id="ID_TOR00326" name="TOR00326" value={this.props.pageState.TOR00326} items={this.getSelectbox(this.props.selectboxData.MEI00012)} {...params} length="w80" />
                                        <div className="dataTableUnit-text">（</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_TOR00327" name="TOR00327" checked={this.props.pageState.TOR00327} {...params} />
                                                        <span className="checkBox-text">増築</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_TOR00328" name="TOR00328" checked={this.props.pageState.TOR00328} {...params} />
                                                        <span className="checkBox-text">改築</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <Select id="ID_TOR00329" name="TOR00329" value={this.props.pageState.TOR00329} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} length="w80" />
                                        <Select id="ID_TOR00330" name="TOR00330" value={this.props.pageState.TOR00330} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} length="w80" />
                                        <div className="dataTableUnit-text">年</div>
                                        <Select id="ID_TOR00331" name="TOR00331" value={this.props.pageState.TOR00331} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} length="w80" />
                                        <div className="dataTableUnit-text">月</div>
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}