import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { DISPLAY_TYPES } from "constants/defaultInputValueTypes";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 特記事項 */
export default class Block9 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030109",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 土地の場合
        if (this.props.displayTypeID === DISPLAY_TYPES.LAND.value) {
            // FUD00109の入力上限判定
            const FUD00109LimitErrorMessage = this.validation.validateTextAreaWordCount(`FUD00109_${this.props.displayTypeID}`, this.props.pageState.FUD00109);
            return (
                <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">特記事項</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">特記事項</th>
                                    <td className="dataTable-body" colSpan="3">
                                        <div className="dataTableTextUnit">
                                            <InputTextRemark id="ID_FUD00109" name="FUD00109" value={this.props.pageState.FUD00109} text={SEND_OTHER_PAPER.FUD00109} placeholder={FUD00109LimitErrorMessage.inputLimitGuide} {...params} />
                                            <p className="LengthOver-error">{FUD00109LimitErrorMessage.inputLimitErrorMessage}</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
        // 土地建物の場合
        if (this.props.displayTypeID === DISPLAY_TYPES.LAND_AND_BUILDING.value) {
            // FUD00135の入力上限判定
            const FUD00135LimitErrorMessage = this.validation.validateTextAreaWordCount(`FUD00135_${this.props.displayTypeID}`, this.props.pageState.FUD00135);
            return (
                <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">特記事項</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">特記事項</th>
                                    <td className="dataTable-body" colSpan="3">
                                        <div className="dataTableTextUnit">
                                            <InputTextRemark id="ID_FUD00135" name="FUD00135" value={this.props.pageState.FUD00135} text={SEND_OTHER_PAPER.FUD00135} placeholder={FUD00135LimitErrorMessage.inputLimitGuide} {...params} />
                                            <p className="LengthOver-error">{FUD00135LimitErrorMessage.inputLimitErrorMessage}</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
        // 区分所有の場合
        if (this.props.displayTypeID === DISPLAY_TYPES.SEGMENT_BUILDING.value) {
            // FUD00336の入力上限判定
            const FUD00336LimitErrorMessage = this.validation.validateTextAreaWordCount(`FUD00336_${this.props.displayTypeID}`, this.props.pageState.FUD00336);
            return (
                <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">特記事項</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">特記事項</th>
                                    <td className="dataTable-body" colSpan="3">
                                        <div className="dataTableTextUnit">
                                            <InputTextRemark id="ID_FUD00336" name="FUD00336" value={this.props.pageState.FUD00336} text={SEND_OTHER_PAPER.FUD00336} placeholder={FUD00336LimitErrorMessage.inputLimitGuide} {...params} />
                                            <p className="LengthOver-error">{FUD00336LimitErrorMessage.inputLimitErrorMessage}</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
    }
}