import { PageBase } from "components/page/user/contract/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";
import Block2 from "./block2";

class ImpPage3 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     */
    getPageItems = () => {
        return {
            // 土地（借地権の場合、借地権の対象となるべき土地）
            JYU00360: undefined, // 登記情報取得日
            JYU00361: undefined, // 所有者 住所
            JYU00362: undefined, // 所有者 氏名
            JYU00363: false, // 所有権にかかる権利に関する事項 有
            JYU01277: false, // 所有権にかかる権利に関する事項 無
            JYU00371: undefined, // 所有権にかかる権利に関する事項 内容
            JYU00372: false, // 所有権以外の権利に関する事項 有
            JYU01278: false, // 所有権以外の権利に関する事項 無
            JYU00375: undefined, // 所有権以外の権利に関する事項 内容

            // 建物
            JYU00341: undefined, // 登記情報取得日
            JYU00343: undefined, // 所有者 住所
            JYU00344: undefined, // 所有者 氏名
            JYU00345: false, // 所有権にかかる権利に関する事項 有
            JYU01279: false, // 所有権にかかる権利に関する事項 無
            JYU00352: undefined, // 所有権にかかる権利に関する事項 内容
            JYU00353: false, // 所有権以外の権利に関する事項 有
            JYU01280: false, // 所有権以外の権利に関する事項 無
            JYU00359: undefined, // 所有権以外の権利に関する事項 内容
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 土地（借地権の場合、借地権の対象となるべき土地） */}
                    <li><Block2 {...params} /></li> {/** 建物 */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
            </React.Fragment>
        );
    }
}

export default withRouter(ImpPage3);