import { PageBase } from "components/page/user/contract/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";
import Block4 from "./block4";
import Block5 from "./block5";
import Block6 from "./block6";
import Block7 from "./block7";
import Block8 from "./block8";
import Block9 from "./block9";

class EstPage1 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     * ※チェックボックスの場合、falseにする
     */
    getPageItems = () => {
        return {
            // 種類
            FUD00344: undefined,  // 種類
            FUD00351: undefined,  // 第３７条書面の印刷

            // 区分所有建物
            // 一棟の建物の表示
            TOR00205: undefined, // 建物の名称
            TOR00206: undefined, // 住居表示
            TOR00207: undefined, // 所在
            TOR00208: undefined, // 構造
            TOR00209: undefined, // 延床面積
            // 専有部分の建物の表示
            TOR00210: undefined, // 家屋番号
            TOR00211: undefined, // 建物の名称
            TOR00212: undefined, // 種類
            TOR00213: undefined, // 構造
            TOR00214: undefined, // 床面積 階部分
            TOR00215: undefined, // 床面積 登記簿
            TOR00216: undefined, // 床面積 壁芯
            JYU00142: false,  // 付属建物 有
            JYU01327: false,  // 付属建物 無
            JYU00143: undefined,  // 付属建物 内容

            // 敷地権の目的たる土地
            TOR00217: undefined, // 所在および地番
            TOR00218: undefined, // 地目
            TOR00219: undefined, // 地積 公簿	数値（少数）
            TOR00220: undefined, // 地積 実測
            TOR00221: undefined, // 敷地権の種類
            TOR00222: undefined, // 持分
            TOR00235: undefined, // 合筆数
            TOR00236: undefined, // 登記簿合計面積	数値（少数）
            // 敷地権の種類が借地権（地上権、賃貸権）の場合
            FUD00066: undefined,  // 土地所有者 住所
            FUD00067: undefined,  // 土地所有者 氏名
            FUD00070: undefined,  // 期間 終了
            FUD00072: false,  // 旧法による借地権
            FUD00073: false,  // 普通借地権
            FUD00075: undefined,  // 地代金額
            FUD00336: undefined,  // 特記事項

            // 土地
            TOR00237: undefined, // 所在
            TOR00238: undefined, // 地番
            TOR00240: undefined, // 地目
            TOR00241: undefined, // 地積 公簿	数値（少数）
            TOR00243: undefined, // 持分
            TOR00256: undefined, // 合筆数
            TOR00257: undefined, // 登記簿合計面積	数値（少数）
            FUD00109: undefined,  // 特記事項

            // 建物
            TOR00258: undefined, // 住居表示
            TOR00259: undefined, // 所在
            TOR00260: undefined, // 家屋番号
            TOR00261: undefined, // 種類
            TOR00262: undefined, // 構造
            JYU00306: false, // 登記簿 チェック
            JYU00307: false, // 現況 チェック
            JYU00308: undefined, // １階（㎡）
            JYU00309: undefined, // ２階（㎡）
            JYU00310: undefined, // ３階（㎡）
            JYU00311: undefined, // その他
            JYU00312: undefined, // 合計（㎡）
            FUD00135: undefined,  // 特記事項

            // 建物の構造耐力上主要な部分等の状況について確認した事項
            FUD00363: false,  // 既存の住宅 該当する
            FUD00364: false,  // 既存の住宅 該当しない
            FUD00278: false,  // 確認した事項・有
            FUD00281: false,  // 確認した事項・無
            FUD00279: undefined,  // 資料作成者
            FUD00280: undefined,  // 資料作成日

            // 借地権
            FUD00158: false,  // 借地権の登記 有
            FUD00353: false,  // 借地権の登記 無
            FUD00159: false,  // 地上権
            FUD00160: false,  // 賃借権
            FUD00161: false,  // 旧法による借地権
            FUD00162: false,  // 普通借地権
            FUD00163: false,  // その他 チェック
            FUD00164: undefined,  // その他 内容
            FUD00354: undefined,  // 住所
            FUD00355: undefined,  // 氏名
            FUD00356: false,  // 非堅固
            FUD00174: false,  // 堅固
            FUD00167: undefined,  // 予定／地代１
            FUD00177: false,  // 満了期間 チェック
            FUD00178: undefined,  // 満了期間 年数
            FUD00179: false,  // 存続期間承継 チェック
            FUD00180: undefined,  // 存続期間承継 日付

            // 定期借地権
            FUD00064: false,  // 定期借地権 地上権
            FUD00065: false,  // 定期借地権 賃貸権
            // 土地の所有者
            FUD00175: undefined,  // 住所
            FUD00176: undefined,  // 氏名
            // 売主が有する定期借地権の内容
            FUD00183: false,  // 定期借地権設定契約書 チェック
            FUD00357: undefined,  // 定期借地権設定契約書 日付
            FUD00185: false,  // 定期借地権確認合意書 チェック
            FUD00358: undefined,  // 定期借地権確認合意書 日付
            FUD00187: false,  // 定期借地権譲渡契約書 チェック
            FUD00359: undefined,  // 定期借地権譲渡契約書 日付
            FUD00189: false,  // 定期役地検に関する公正証書 チェック
            FUD00360: undefined,  // 定期役地検に関する公正証書 日付
            FUD00191: false,  // その他 チェック
            FUD00192: undefined,  // その他 内容
            FUD00193: false,  // 居住用
            FUD00194: false,  // 業務用
            FUD00195: false,  // 有
            FUD00196: undefined,  // 有 月額金額
            FUD00197: undefined,  // 有 現在日付
            FUD00198: false,  // 無
            FUD00199: undefined,  // 期限
            // 保証金
            FUD00200: false,  // 授受の有無 有
            FUD00338: false,  // 授受の有無 無
            FUD00201: undefined,  // 保証金の金額
            FUD00202: undefined,  // 備考
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [
            // 区分所有建物部分
            "TOR00205", "TOR00206", "TOR00207", "TOR00208", "TOR00209", "TOR00210", "TOR00211",
            "TOR00212", "TOR00213", "TOR00214", "TOR00215", "TOR00216", "TOR00235", "TOR00236",
            "JYU00142", "JYU01327", "JYU00143",

            // 土地
            "TOR00237", "TOR00238", "TOR00240", "TOR00241", "TOR00243", "TOR00256", "TOR00257",

            // 建物
            "TOR00258", "TOR00259", "TOR00260", "TOR00261", "TOR00262", "JYU00306", "JYU00307",
            "JYU00308", "JYU00309", "JYU00310", "JYU00311", "JYU00312",
        ];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 種類 */}
                    <li><Block2 {...params} /></li> {/** 区分所有建物 */}
                    <li><Block3 {...params} /></li> {/** 敷地権の目的たる土地 */}
                    <li><Block4 {...params} /></li> {/** 土地 */}
                    <li><Block5 {...params} /></li> {/** 建物 */}
                    <li><Block9 {...params} /></li> {/** 特記事項 */}
                    <li><Block6 {...params} /></li> {/** 建物の構造耐力上主要な部分等の状況について確認した事項 */}
                    <li><Block7 {...params} /></li> {/** 借地権 */}
                    <li><Block8 {...params} /></li> {/** 定期借地権 */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
            </React.Fragment>
        );
    }
}

export default withRouter(EstPage1);