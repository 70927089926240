import React from "react";
import * as validation from "util/validate";


/**
 * テキストボックスのレコード
 * props.titleName: 入力ボックスのタイトル
 * props.inputData: 入力値データ  {name: "stateの名前", value: "入力値"}
 * props.titleSetting: タイトルタグの属性情報
 * props.bodySetting: ボディータグの属性情報
 * props.inputSetting: 入力タグの属性情報
 * props.errorMessage: バリデーションエラー時メッセージ
 * props.registeredNumber: 現在登録数
 */
export function InputTextRecord(props) {
    let mainDom = props.textOnly ? props.inputData.value : (<input type="text" aria-label="text" {...props.inputData} {...props.inputSetting} />);

    // エラーメッセージまたは、現在登録数を表示する
    if (props.errorMessage || validation.isNumber(props.registeredNumber)) {
        let className = "dataTableUnit-input";
        if (props.errorMessage) {
            const lengthForErrMsg = !props.lengthForErrMsg ? "wFull" : props.lengthForErrMsg;
            className += validation.isNumber(props.registeredNumber)
                ? " is-error"
                : ` dataTableUnit-input__${lengthForErrMsg} is-error`;
        }

        mainDom = (
            <div className="dataTableUnit">
                <div className={className}>
                    {mainDom}
                    {props.errorMessage && (
                        <span className="dataTableUnit-input-error">
                            <span className="dataTableUnit-input-error_text">{props.errorMessage}</span>
                        </span>
                    )}
                </div>
                {validation.isNumber(props.registeredNumber) && (
                    <div className="dataTableUnit-text">（現登録数：　{props.registeredNumber || 0}）</div>
                )}
            </div>
        );
    }

    return (
        <>
            <th className="dataTable-title" {...props.titleSetting}>{props.titleName}</th>
            <td className="dataTable-body" {...props.bodySetting || null}>
                {mainDom}
                {props.supplementedMessage && (
                    <div className="dataTable-body-note">{props.supplementedMessage}</div>
                )}
            </td>
        </>
    );
}

/**
 * テキストエリアのレコード
 * props.titleName: 入力ボックスのタイトル
 * props.inputData: 入力値データ  {name: "stateの名前", value: "入力値"}
 * props.titleSetting: タイトルタグの属性情報
 * props.bodySetting: ボディータグの属性情報
 * props.inputSetting: 入力タグの属性情報
 * props.errorMessage: バリデーションエラー時メッセージ
 */
export function InputTextareaRecord(props) {
    let mainDom = (<textarea aria-label="textarea" {...props.inputData} {...props.inputSetting} />);
    if (props.errorMessage) {
        mainDom = (
            <div className="dataTableUnit">
                <div className="dataTableUnit-input dataTableUnit-input__wFull is-error">
                    {mainDom}
                    <span className="dataTableUnit-input-error">
                        <span className="dataTableUnit-input-error_text">{props.errorMessage}</span>
                    </span>
                </div>
            </div>
        );
    }
    return (
        <>
            <th className="dataTable-title" {...props.titleSetting}>{props.titleName}</th>
            <td className="dataTable-body" {...props.bodySetting}>
                {mainDom}
            </td>
        </>
    );
}

/**
 * セレクトボックスのレコード
 * props.titleName: 入力ボックスのタイトル
 * props.inputData: 入力値データ  {name: "stateの名前", value: "入力値"}
 * props.optionsDataList: プルダウンするデータリスト  {value: "入力値", display: "表示名"}
 * props.titleSetting: タイトルタグの属性情報
 * props.bodySetting: ボディータグの属性情報
 * props.inputSetting: 入力タグの属性情報
 */
export function InputSelectBoxRecord(props) {
    const optionsDom = props.optionsDataList.map(data => (
        <option key={data.value} value={data.value}>{data.display}</option>
    ));

    const errorMessageDOM = props.errorMessage
        ? <span className="dataTableUnit-input-error"><span className="dataTableUnit-input-error_text">{props.errorMessage}</span></span>
        : null;

    return (
        <>
            <th className="dataTable-title" {...props.titleSetting}>{props.titleName}</th>
            <td className="dataTable-body" {...props.bodySetting || null}>
                <div className="dataTableUnit">
                    <div className="dataTableUnit-input is-error">
                        <div className="selectBox">
                            <select className="selectBox-select" aria-label="select" {...props.inputData} {...props.inputSetting}>
                                {optionsDom}
                            </select>
                        </div>
                        {errorMessageDOM}
                    </div>
                </div>
            </td>
        </>
    );
}

/**
 * ラジオボックスのレコード
 * props.titleName: 入力ボックスのタイトル
 * props.inputData: 入力値データ  {name: "stateの名前", value: "入力値"}
 * props.radioDataList: ラジオボタンのデータリスト  {value: "入力値", display: "表示名"}
 * props.titleSetting: タイトルタグの属性情報
 * props.bodySetting: ボディータグの属性情報
 * props.inputSetting: 入力タグの属性情報
 * props.noTitle: thタグ有無のステータス　ない場合、付ける
 */
export function InputRadioBoxRecord(props) {
    const radioBoxsDom = props.radioDataList.map(data => {
        let className = "radioBox-input";
        let checked = "";

        // 選択された値とラジオボタンの値が一致した場合、checkedとclassNameを変更する
        if (data.value === props.inputData.value) {
            className += " is-checked";
            checked = "checked";
        }

        return (
            <li key={data.value}>
                <label className="radioBox">
                    <input type="radio" className={className} name={props.inputData.name} value={data.value}
                        checked={checked} {...props.inputSetting} />
                    <span className="radioBox-text">{data.display}</span>
                </label>
            </li>
        );
    });

    const errorMessageDOM = props.errorMessage
        ? <span className="dataTableUnit-input-error"><span className="dataTableUnit-input-error_text">{props.errorMessage}</span></span>
        : null;

    // thタグのあり・なし制御
    const mainDom = props.noTitle
        ? (
            <div className="dataTableUnit dataTableUnit--rightend">
                <div className="dataTableUnit-input is-error">
                    <ul className="l-radioBox">
                        {radioBoxsDom}
                    </ul>
                    {errorMessageDOM}
                </div>
            </div>
        )
        : (
            <div className="dataTableUnit">
                <div className="dataTableUnit-input is-error">
                    <ul className="l-radioBox">
                        {radioBoxsDom}
                    </ul>
                    {errorMessageDOM}
                </div>
            </div>
        );

    return (
        <>
            {props.noTitle || <th className="dataTable-title" {...props.titleSetting}>{props.titleName}</th>}
            <td className="dataTable-body" {...props.bodySetting}>
                {mainDom}
            </td>
        </>
    );
}

/**
 * チェックボックスのレコード
 * props.titleName: 入力ボックスのタイトル
 * props.checkboxText: チェックボックスの右に表示する文字
 * props.inputData: 入力値データ  {name: "stateの名前", value: "入力値"}
 * props.titleSetting: タイトルタグの属性情報
 * props.bodySetting: ボディータグの属性情報
 * props.inputSetting: 入力タグの属性情報
 */
export function InputCheckBoxRecord(props) {
    return (
        <>
            <th className="dataTable-title" {...props.titleSetting}>{props.titleName}</th>
            <td className="dataTable-body" {...props.bodySetting || null}>
                <InputCheckBox checkboxText={props.checkboxText} inputData={props.inputData} inputSetting={props.inputSetting} />
            </td>
        </>
    );
}

export function InputCheckBox(props) {
    let className = "checkBox-input";
    if (props.inputData.checked) {
        className += " is-checked";
    }

    return (
        <label className="checkBox">
            <input type="checkbox" className={className} aria-label="text" {...props.inputData}
                {...props.inputSetting} {...props.disabled} />
            <span className="checkBox-text">{props.checkboxText}</span>
        </label>
    );
}

export function InputText(props) {
    let mainDom = (<input type="text" aria-label="text" {...props.inputData} {...props.inputSetting} />);

    // エラーメッセージを表示する
    if (props.errorMessage) {
        let className = "dataTableUnit-input";
        if (props.errorMessage) {
            className += " dataTableUnit-input__wFull is-error";
        }

        mainDom = (
            <div className="dataTableUnit">
                <div className={className}>
                    {mainDom}
                    {props.errorMessage && (
                        <span className="dataTableUnit-input-error">
                            <span className="dataTableUnit-input-error_text">{props.errorMessage}</span>
                        </span>
                    )}
                </div>
            </div>
        );
    }

    return (
        <>
            {mainDom}
            {props.supplementedMessage && (
                <div className="dataTable-body-note">{props.supplementedMessage}</div>
            )}
        </>
    );
}

/**
 * テキストボックスのレコード（Tableの行指定なし）
 * props.inputData: 入力値データ  {name: "stateの名前", value: "入力値"}
 * props.bodySetting: ボディータグの属性情報
 * props.inputSetting: 入力タグの属性情報
 * props.errorMessage: バリデーションエラー時メッセージ
 * props.registeredNumber: 現在登録数
 * props.inputClassName: inputBoxの長さの指定（style.css参照）
 */
export function InputTextRecordWithoutRow(props) {
    let mainClassName = props.inputClassName === "wFull" ? "dataTableUnit-input__wFull" : "";

    let mainDom = props.textOnly ? props.inputData.value : (<input type="text" aria-label="text" {...props.inputData} {...props.inputSetting} className={props.inputClassName} />);

    // エラーメッセージまたは、現在登録数を表示する
    if (props.errorMessage || validation.isNumber(props.registeredNumber)) {
        let className = "dataTableUnit-input";
        if (props.errorMessage) {
            const lengthForErrMsg = !props.lengthForErrMsg ? "wFull" : props.lengthForErrMsg;
            className += validation.isNumber(props.registeredNumber)
                ? " is-error"
                : ` dataTableUnit-input__${lengthForErrMsg} is-error`;
        }

        mainDom = (
            <div className={className}>
                {mainDom}
                {props.errorMessage && (
                    <span className="dataTableUnit-input-error">
                        <span className="dataTableUnit-input-error_text">{props.errorMessage}</span>
                    </span>
                )}
            </div>
        );
    }

    return (
        <div className={`dataTableUnit-input ${mainClassName}`}>
            {mainDom}
        </div>
    );
}

/**
 * セレクトボックスのレコード（Tableの行指定なし）
 * props.inputData: 入力値データ  {name: "stateの名前", value: "入力値"}
 * props.optionsDataList: プルダウンするデータリスト  {value: "入力値", display: "表示名"}
 * props.bodySetting: ボディータグの属性情報
 * props.inputSetting: 入力タグの属性情報
 * props.inputClassName: selectBoxの長さの指定（style.css参照）
 */
export function InputSelectBoxRecordWithoutRow(props) {
    const optionsDom = props.optionsDataList.map(data => (
        <option key={data.value} value={data.value}>{data.display}</option>
    ));

    return (
        <>
            <div className="dataTableUnit-input is-error">
                <div className={`selectBox  ${props.selectClassName}`}>
                    <select className="selectBox-select" aria-label="select" {...props.inputData} {...props.inputSetting}>
                        {optionsDom}
                    </select>
                </div>
            </div>
        </>
    );
}