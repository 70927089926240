import InputDate from "components/page/user/contractFrk/edit/input/inputDate";
import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import React from "react";

/** 支払内容 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010201",
        };
        this.isFirstLoad = true; // 初回ロードかどうか
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        const HYPHEN_TEXT_0102_08 = "--------";
        const HYPHEN_TEXT_0102_12 = "------------";
        //        const HYPHEN_TEXT_0102_20 = "--------------------";

        if (this.isFirstLoad) {
            if (this.props.pageState.TOR00340 === HYPHEN_TEXT_0102_08) {
                this.props.pageState.TOR00340 = "";
            }
            if (this.props.pageState.TOR00341 === HYPHEN_TEXT_0102_12) {
                this.props.pageState.TOR00341 = "";
            }
            if (this.props.pageState.TOR00342 === HYPHEN_TEXT_0102_08) {
                this.props.pageState.TOR00342 = "";
            }
            if (this.props.pageState.TOR00343 === HYPHEN_TEXT_0102_12) {
                this.props.pageState.TOR00343 = "";
            }
            if (this.props.pageState.TOR00344 === HYPHEN_TEXT_0102_08) {
                this.props.pageState.TOR00344 = "";
            }
            if (this.props.pageState.TOR00345 === HYPHEN_TEXT_0102_12) {
                this.props.pageState.TOR00345 = "";
            }
            if (this.props.pageState.TOR00346 === HYPHEN_TEXT_0102_08) {
                this.props.pageState.TOR00346 = "";
            }

            this.isFirstLoad = false;
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">支払内容</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" colSpan="2">&nbsp;</th>
                                <th className="dataTable-title dataTable-title__w220 is-emphasis">日付</th>
                                <th className="dataTable-title dataTable-title__w195 is-emphasis">金額</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title" colSpan="2">手付金</th>
                                {/* 帳票上では「本契約締結時支払い」の文言が入るため、日付は入力させない */}
                                <td className="dataTable-body" />
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00340" name="TOR00340" value={this.props.pageState.TOR00340} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w60" rowSpan="2">内金</th>
                                <th className="dataTable-title dataTable-title__w60 is-emphasis">1</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00341" name="TOR00341" value={this.props.pageState.TOR00341} {...params} length="w107" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00342" name="TOR00342" value={this.props.pageState.TOR00342} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w60 is-emphasis">2</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00343" name="TOR00343" value={this.props.pageState.TOR00343} {...params} length="w107" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00344" name="TOR00344" value={this.props.pageState.TOR00344} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title" colSpan="2">残代金</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00345" name="TOR00345" value={this.props.pageState.TOR00345} {...params} length="w107" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00346" name="TOR00346" value={this.props.pageState.TOR00346} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}