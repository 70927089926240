import InputDate from "components/page/user/contract/edit/input/inputDate";
import Input from "components/page/user/contract/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";

/** 認定手数料配分 */
export default class Block3 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010203",
        };
    }


    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">媒介情報</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">媒介種別</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00040" name="TOR00040" value={this.props.pageState.TOR00040} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">媒介締結日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00041" name="TOR00041" value={this.props.pageState.TOR00041} {...params} length="w108" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">売主</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">媒介番号</div>
                                        <Input id="ID_TOR00042" name="TOR00042" value={this.props.pageState.TOR00042} {...params} length="w253" />
                                        <div className="dataTableUnit-text">領収書番号</div>
                                        <Input id="ID_TOR00043" name="TOR00043" value={this.props.pageState.TOR00043} {...params} length="w253" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">買主</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">媒介番号</div>
                                        <Input id="ID_TOR00044" name="TOR00044" value={this.props.pageState.TOR00044} {...params} length="w253" />
                                        <div className="dataTableUnit-text">領収書番号</div>
                                        <Input id="ID_TOR00045" name="TOR00045" value={this.props.pageState.TOR00045} {...params} length="w253" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}