import BaseGrid from "components/common/frame/baseGrid";
import * as AWS from "constants/aws";
import { CONTRACT_EDIT_INFO, CONTRACT_EDIT_INFO_FRK } from "constants/contractEditPageInfo";
import { FORMAT } from "constants/format";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";
import * as dateUtil from "util/dateUtil";

/** ダッシュボード画面 */
class DashBoard extends React.Component {
    constructor(props) {
        super(props);

        // 仕様で最新10件しか取得しないため、現ページ等は持つ必要がないので注意
        this.state = {
            list: [], // APIから取得したリスト
            initialized: false, // true：初期APIコール済み
        };
    }

    componentDidMount() {
        document.title = pageInfo.DASHBOARD.title;
        this.requestContractList();
    }

    isFrkFormat = () => this.props.useFormat === FORMAT.FRK.value;

    // 再開ボタン押下のハンドラ
    handleEditBtn = (e, idx) => {
        e.preventDefault();

        const isFrk = this.isFrkFormat();
        const path = isFrk ? pageInfo.CONTRACT_EDIT_FRK.path : pageInfo.CONTRACT_EDIT.path;
        const pageId = isFrk ? CONTRACT_EDIT_INFO_FRK.PAGE_0101.id : CONTRACT_EDIT_INFO.PAGE_0101.id;

        this.props.history.push(path
            .replace(":contractId", this.state.list[idx].id)
            .replace(":pageId", pageId)
            + '?param=' + dateUtil.tzStrToParm());
    }

    render() {
        // "ヘッダ＋情報なし"で描画すると、"ヘッダ＋情報有り"の状態になった時（APIコールの結果が取得できたタイミング）で
        // ヘッダの位置が微妙にずれるので中身情報の取得が終わっていない場合、描画しないようにする
        if (!this.state.initialized) return null;

        const isFrk = this.isFrkFormat();
        const documentNo = isFrk ? '稟議書No.' : '書類No.';
        const contractListPath = isFrk ? pageInfo.CONTRACT_LIST_FRK.path : pageInfo.CONTRACT_LIST.path;

        return (
            <BaseGrid>
                <div className="dataList dataList__accordion is-open">
                    <div className="dataList-header">仕掛中 契約情報リスト</div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120">{documentNo}</th>
                                    <th className="dataTable-title">物件名</th>
                                    <th className="dataTable-title">書式</th>
                                    <th className="dataTable-title dataTable-title__w160">最終更新日時</th>
                                    <th className="dataTable-title dataTable-title__w160"></th>
                                </tr>
                                {this.createListDOM()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="l-dataList_more">
                    <Link to={contractListPath} className="dataList_more">もっと見る</Link>
                </div>
            </BaseGrid>
        );
    }

    createListDOM = () => {
        const contractList = this.state.list;

        // contractListが空の場合、null
        if (contractList === undefined || contractList == null) {
            return null;
        }

        return contractList.map((element, idx) => {
            const datetime = dateUtil.tzStrToDateTime(element.updatedAt);

            // 書式名　表示・非表示対応
            let inputFormatName = undefined;
            if (element.inputFormatName !== undefined && element.inputFormatName !== "") {
                inputFormatName = element.inputFormatName + "／";
            }

            return (
                <React.Fragment key={element.id}>
                    <tr>
                        <th className="dataTable-title">{element.documentNo}</th>
                        <td className="dataTable-body">{element.buildingName}</td>
                        <td className="dataTable-body">{inputFormatName}{element.contractTypeName}</td>
                        <td className="dataTable-body">{datetime}</td>
                        <td className="dataTable-body">
                            <Link to="" aria-label="link" className="baseAction" onClick={e => { this.handleEditBtn(e, idx); }}>再開</Link>
                        </td>
                    </tr>
                </React.Fragment>
            );
        });
    }

    /**
     * 取得API呼出し、レスポンスデータをstateに格納する
     */
    requestContractList = () => {
        awsReq.get(
            AWS.ENDPOINT.PATHS.WIP_CONTRACTS,
            response => {
                const camelResponseData = convert.convertToFlatCamelCase(response.data);

                // 既存のリストと結合して、画面に表示する
                const list = this.state.list.concat(camelResponseData);
                this.setState({ list: list, initialized: true });
            },
            awsReq.defaultErrorHandler,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    };
}

const mapStateToProps = state => {
    return { useFormat: state.useFormat };
};

export default withRouter((connect(mapStateToProps))(DashBoard));
