import React from "react";

export class InputBase extends React.Component {

    /** ボタンをdisableにするかどうか */
    isDisabled = disableItemList => {
        if (disableItemList === undefined) {
            return false;
        }

        // 見つからない場合
        if (disableItemList.indexOf(this.props.name) === -1) {
            return false;
        }
        return true;
    }
}