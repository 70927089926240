import { Auth } from "aws-amplify";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect, Route } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as pageInfo from "../constants/pageInfo";
import * as AdminActions from "../redux/actions/authority";
import * as cognitoTokenActions from "../redux/actions/cognitoToken";
import * as loginActions from "../redux/actions/login";
import * as sessionUtils from "../util/sessionUtils";
import { PrivateRoute } from "./privateRoute";

/**
 * ログイン画面のルーティング用
 * ログインしていない場合、ログイン画面を表示
 * ログインしている場合、ログイン画面後にリダイレクトする
 */
class AdminRoute extends PrivateRoute {
    render() {
        if (this.state.doRender === false) {
            return null;
        }

        // セッションタイムアウト時
        if (sessionUtils.sessionTimeouted()) {
            // サインアウト処理を実行する
            this.props.updateToUnauthenticated();
            return <Redirect to={pageInfo.SESSION_TIMEOUT.path} />;
        }

        // ログインしていない場合、ログイン画面へリダイレクトする
        if (!this.loggedIn()) {
            // サインアウト処理を実行する
            this.props.updateToUnauthenticated();
            return <Redirect to={`${pageInfo.LOGIN.path}?${pageInfo.LOGIN.QUERY.from}=${this.props.location.pathname}`} />;
        }

        // IPアドレス制限チェックが完了するまで空のレンダリングを行う
        if (this.state.isIpAddressOK === undefined) {
            this.requestIPAdressCheckAPI();
            return null;
        }

        // IPアドレスチェックがNGの場合
        if (this.state.isIpAddressOK === false) {
            // サインアウト処理を実行する
            this.props.updateToUnauthenticated();
            return <Redirect to={pageInfo.IP_ADDRESS_NG.path} />;
        }

        // セッションタイムアウト値を更新する
        sessionUtils.setNextSessionTimeout();

        // ReduxのidTokenを最新状態に更新
        Auth.currentSession().then(data => {
            this.props.saveIdToken(data.idToken.jwtToken);
        });

        // 管理者の場合、遷移先画面を表示する
        // 管理者でない場合、ダッシュボード画面にリダイレクトする
        if (this.props.admin) {
            const Component = this.props.children;
            return (
                <Route {...this.props}>
                    <Component.type {...Component.props} />
                </Route>
            );
        } else {
            return <Redirect to={pageInfo.DASHBOARD.path}></Redirect>;
        }
    }
}

const mapStateToProps = state => {
    return {
        admin: state.admin,
        login: state.loginStatus,
        idToken: state.idToken,
        jwkInfo: state.jwkInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(loginActions, dispatch),
        ...bindActionCreators(cognitoTokenActions, dispatch),
        ...bindActionCreators(AdminActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminRoute));
