import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";
import InputCheckbox from "../../input/inputCheckbox";
import InputDate from "../../input/inputDate";
import Input from "../../input/inputNormal";

/** 借地契約の内容 */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021703",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">借地契約の内容</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="5">(1)種類</th>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">①借地借家法による借地</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01495" name="JYU01495" checked={this.props.pageState.JYU01495} {...params} />
                                                        <span className="checkBox-text">地上権</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01496" name="JYU01496" checked={this.props.pageState.JYU01496} {...params} />
                                                        <span className="checkBox-text">借地権</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__noborder dataTable-body__w145">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01497" name="JYU01497" checked={this.props.pageState.JYU01497} {...params} />
                                                        <span className="checkBox-text">ア. 普通借地権</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                                <td className="dataTable-body  dataTable-body__noborder dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU01498" name="JYU01498" value={this.props.pageState.JYU01498} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body  dataTable-body__noborder dataTable-body__w145">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01499" name="JYU01499" checked={this.props.pageState.JYU01499} {...params} />
                                                        <span className="checkBox-text">イ. 定期借地権</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                                <td className="dataTable-body  dataTable-body__noborder dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01500" name="JYU01500" checked={this.props.pageState.JYU01500} {...params} />
                                                        <span className="checkBox-text">一般</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01501" name="JYU01501" checked={this.props.pageState.JYU01501} {...params} />
                                                        <span className="checkBox-text">建物譲渡特約付</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01502" name="JYU01502" checked={this.props.pageState.JYU01502} {...params} />
                                                        <span className="checkBox-text">事業用</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">②</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01503" name="JYU01503" checked={this.props.pageState.JYU01503} {...params} />
                                                        <span className="checkBox-text">旧借地法による借地　（4年7月31日以前に契約されたもの）</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">（</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01504" name="JYU01504" checked={this.props.pageState.JYU01504} {...params} />
                                                        <span className="checkBox-text">地上権</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01505" name="JYU01505" checked={this.props.pageState.JYU01505} {...params} />
                                                        <span className="checkBox-text">借地権</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </td>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">（</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01506" name="JYU01506" checked={this.props.pageState.JYU01506} {...params} />
                                                        <span className="checkBox-text">堅固建物所有を目的</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01507" name="JYU01507" checked={this.props.pageState.JYU01507} {...params} />
                                                        <span className="checkBox-text">非堅固建物所有を目的</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="1">(2)期間</th>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU01508" name="JYU01508" value={this.props.pageState.JYU01508} {...params} length="w50" />
                                        <div className="dataTableUnit-text">年</div>
                                        <div className="dataTableUnit-text">（</div>
                                        <InputDate noEra id="ID_JYU01509" name="JYU01509" value={this.props.pageState.JYU01509} {...params} />
                                        <div className="dataTableUnit-text">～</div>
                                        <InputDate noEra id="ID_JYU01510" name="JYU01510" value={this.props.pageState.JYU01510} {...params} />
                                        <div className="dataTableUnit-text">まで</div>
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="1">(3)地代</th>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">月額金</div>
                                        <Input id="ID_JYU01511" name="JYU01511" value={this.props.pageState.JYU01511} {...params} length="w120" />
                                        <div className="dataTableUnit-text">円（　区分所有建物の場合、区分所有者の負担額　）</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="2">
                                    (4)更新の可否<br></br>
                                    （<InputCheckbox id="ID_JYU01512" name="JYU01512" checked={this.props.pageState.JYU01512} {...params} />更新可）<br></br>
                                    （<InputCheckbox id="ID_JYU01513" name="JYU01513" checked={this.props.pageState.JYU01513} {...params} />更新不可）
                                </th>
                                <td className="dataTable-body" colSpan="1">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">更新料の有無</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01514" name="JYU01514" checked={this.props.pageState.JYU01514} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01515" name="JYU01515" checked={this.props.pageState.JYU01515} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                                <td className="dataTable-body" colSpan="1">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">更新料の額</div>
                                        <Input id="ID_JYU01516" name="JYU01516" value={this.props.pageState.JYU01516} {...params} length="w120" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__noborder" colSpan="2">
                                    <div className="dataTableUnit-text">
                                        ※借地借家法による普通借地権・旧借地法による借地権の場合は、更新することができます。<br></br>
                                    　ただし、土地所有者（貸主）の更新拒絶に正当事由があるときは更新できません。<br></br>
                                    ※定期借地権の場合は、更新できません。
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="2">(5)登記の状況</th>
                                <td className="dataTable-body dataTable-body__w260" colSpan="1">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">借地権の登記の有無</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01517" name="JYU01517" checked={this.props.pageState.JYU01517} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01518" name="JYU01518" checked={this.props.pageState.JYU01518} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                                <td className="dataTable-body" colSpan="1">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">建物の登記の有無</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01519" name="JYU01519" checked={this.props.pageState.JYU01519} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01520" name="JYU01520" checked={this.props.pageState.JYU01520} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__noborder" colSpan="2">
                                    <div className="dataTableUnit-text">
                                        ※借地権は、その登記がなくても土地の上に借地権者が登記されている建物を所有するときは、<br></br>
                                    　これをもって、第三者に対抗することができます。（借地借家法第10条・建物保護に関する法律第1条）
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="9">(6)その他の<br></br>契約内容</th>
                                <td className="dataTable-body dataTable-body__w260" colSpan="2">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">①私道負担の有無</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01521" name="JYU01521" checked={this.props.pageState.JYU01521} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01522" name="JYU01522" checked={this.props.pageState.JYU01522} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w260" colSpan="2">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">②第三者に借地権譲渡・転貸時の土地所有者(貸主)の承諾</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01523" name="JYU01523" checked={this.props.pageState.JYU01523} {...params} />
                                                        <span className="checkBox-text">要</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01524" name="JYU01524" checked={this.props.pageState.JYU01524} {...params} />
                                                        <span className="checkBox-text">通知可</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01525" name="JYU01525" checked={this.props.pageState.JYU01525} {...params} />
                                                        <span className="checkBox-text">不要</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__noborder dataTable-body__w260" colSpan="1">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">承諾料の有無</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01526" name="JYU01526" checked={this.props.pageState.JYU01526} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01527" name="JYU01527" checked={this.props.pageState.JYU01527} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                                <td className="dataTable-body dataTable-body__noborder" colSpan="1">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">承諾料金</div>
                                        <Input id="ID_JYU01528" name="JYU01528" value={this.props.pageState.JYU01528} {...params} length="w120" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w260" colSpan="2">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">③増改築時の土地所有者(貸主)の承諾</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01529" name="JYU01529" checked={this.props.pageState.JYU01529} {...params} />
                                                        <span className="checkBox-text">要</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01530" name="JYU01530" checked={this.props.pageState.JYU01530} {...params} />
                                                        <span className="checkBox-text">通知可</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01531" name="JYU01531" checked={this.props.pageState.JYU01531} {...params} />
                                                        <span className="checkBox-text">不要</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__noborder dataTable-body__w260" colSpan="1">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">承諾料の有無</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01532" name="JYU01532" checked={this.props.pageState.JYU01532} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01533" name="JYU01533" checked={this.props.pageState.JYU01533} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                                <td className="dataTable-body dataTable-body__noborder dataTable-body" colSpan="1">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">承諾料金</div>
                                        <Input id="ID_JYU01534" name="JYU01534" value={this.props.pageState.JYU01534} {...params} length="w120" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w260" colSpan="2">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">④契約書が公正証書化されて</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01535" name="JYU01535" checked={this.props.pageState.JYU01535} {...params} />
                                                        <span className="checkBox-text">いる</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01536" name="JYU01536" checked={this.props.pageState.JYU01536} {...params} />
                                                        <span className="checkBox-text">いない</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w260" colSpan="2">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">⑤地代改定に関する特約</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01537" name="JYU01537" checked={this.props.pageState.JYU01537} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01538" name="JYU01538" checked={this.props.pageState.JYU01538} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">内容：</div>
                                        <Input id="ID_JYU01539" name="JYU01539" value={this.props.pageState.JYU01539} {...params} length="w391" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w260" colSpan="2">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text" style={{ width: "90px" }}>⑥その他</div>
                                        <Input id="ID_JYU01540" name="JYU01540" value={this.props.pageState.JYU01540} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__noborder dataTable-body__w260" colSpan="2">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">⑦契約終了時における建物の取壊しに関する特約</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01541" name="JYU01541" checked={this.props.pageState.JYU01541} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01542" name="JYU01542" checked={this.props.pageState.JYU01542} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div >
            </div >
        );
    }
}