import BaseGrid from "components/common/frame/baseGrid";
import { RegisterAndDeleteButtonRecord, RegisterButtonRecord } from "components/common/parts/buttonRecord";
import { InputTextareaRecord, InputTextRecord } from "components/common/parts/inputRecord";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";
import Validation from "util/validate";

/** 定型文メンテナンス画面 */
class TemplateMaintenance extends React.Component {
    TYPE_CREATE = "create";
    TYPE_UPDATE = "update";

    constructor(props) {
        super(props);
        this.state = {};
        this.type = undefined; // 登録画面の場合：create、更新画面の場合：update
        this.templateHeadlineId = undefined; // 見出しID
        this.id = undefined; // 定型文ID

        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();

        // 画面から入力される値の情報
        this.params = {
            templateItemId: {
                label: "項目ID",
                validate: this.validation.notEmpty,
                apiParamKey: "template_item_id"
            },
            templateType: {
                label: "種別",
                validate: this.validation.noCheck,
                apiParamKey: "template_type"
            },
            templateItem: {
                label: "項目",
                validate: this.validation.notEmpty,
                apiParamKey: "template_item"
            },
            template: {
                label: "定型文",
                validate: this.validation.notEmpty,
                apiParamKey: "template"
            },
            templateExplain: {
                label: "解説",
                validate: this.validation.noCheck,
                apiParamKey: "template_explain"
            },
            orderNo: {
                label: "表示並び順",
                validate: this.validation.notEmptyAndIsNumber,
                apiParamKey: "order_no"
            },
        };
    }

    componentDidMount() {
        document.title = pageInfo.TEMPLATE_MAINTENANCE.title;
        // 見出しID取得（新規・更新共通）
        this.templateHeadlineId = this.props.match.params.templateHeadlineId;
        // 定型文ID取得（新規の場合、IDがないのでID部分がcreateになっている）
        this.type = this.props.match.params.type === this.TYPE_CREATE ? this.TYPE_CREATE : this.TYPE_UPDATE;
        this.requestTemplateHeadlines();
    }

    componentDidUpdate() {
        this.type = this.props.match.params.type === this.TYPE_CREATE ? this.TYPE_CREATE : this.TYPE_UPDATE;

        // 更新時、APIからの取得情報がクリアされている場合、再取得
        if (this.type === this.TYPE_UPDATE && this.state.templateItemId === undefined) {
            this.id = parseInt(this.props.match.params.type);
            this.requestTemplate();
        }
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputText = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputNumber = e => {
        const target = e.target;
        if (isNaN(target.value) || target.value === "") {
            this.setState({ [target.name]: target.value });
        } else {
            this.setState({ [target.name]: parseInt(target.value) });
        }
    }

    /** 入力ボックスの入力値を空文字チェック */
    handleBlurInputText = e => {
        const target = e.target;
        const param = this.params[target.name];
        param.validate(target.name, target.value);
        this.setState({});  // 再renderする為の空セット
    }

    // 登録ボタンのハンドラー
    handleRegisterTemplate = e => {
        e.preventDefault();

        // 全入力項目のチェックし、エラーメッセージがない場合、後続処理（登録APIの呼出し）をする
        if (!this.validation.validateTotalInputText(this.params, this.state)) {
            this.registerTemplate();
        } else {
            this.setState({});
        }
    }

    // 削除ボタンのハンドラー
    handleDeleteTemplate = e => {
        e.preventDefault();
        this.deleteTemplate();
    }

    render() {
        // 見出し一覧未取得時は画面非表示
        if (this.state.templateHeadline === undefined) {
            return null;
        }

        // API情報未取得時は画面非表示
        if (this.type === this.TYPE_UPDATE && this.state.templateItemId === undefined) {
            return null;
        }

        // Inputレコードに関する、タグの属性情報
        const bodySetting = { colSpan: "3" };
        const titleSetting = { className: "dataTable-title dataTable-title__w120" };
        const inputSetting = { onChange: this.handleChangeInputText, onBlur: this.handleBlurInputText };
        const inputSettingNum = { onChange: this.handleChangeInputNumber, onBlur: this.handleBlurInputText };

        const registerSetting = !this.apiCalling ? { onClick: this.handleRegisterTemplate } : { onClick: e => { e.preventDefault(); } };
        const deleteSetting = !this.apiCalling ? { onClick: this.handleDeleteTemplate } : { onClick: e => { e.preventDefault(); } };

        const buttonDOM = this.type === this.TYPE_CREATE
            ? <RegisterButtonRecord inActive={this.apiCalling} registerSetting={registerSetting} />
            : <RegisterAndDeleteButtonRecord inActive={this.apiCalling} registerSetting={registerSetting} deleteSetting={deleteSetting} />;

        return (
            <BaseGrid>
                <div className="dataList dataList__accordion is-open">
                    <div className="dataList-header">定型文メンテナンス</div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <InputTextRecord titleName="見出し"
                                        textOnly
                                        inputData={{ name: "templateHeadline", value: this.state.templateHeadline || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={null} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.templateItemId.label}
                                        inputData={{ name: "templateItemId", value: this.state.templateItemId || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.templateItemId || ""} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.templateType.label}
                                        inputData={{ name: "templateType", value: this.state.templateType || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.templateItem.label}
                                        inputData={{ name: "templateItem", value: this.state.templateItem || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.templateItem || ""} />
                                </tr>

                                <tr>
                                    <InputTextareaRecord titleName={this.params.template.label}
                                        inputData={{ name: "template", value: this.state.template || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={{ ...inputSetting, cols: 30, rows: 10 }}
                                        errorMessage={this.validation.errorMessage.template || ""} />
                                </tr>

                                <tr>
                                    <InputTextareaRecord titleName={this.params.templateExplain.label}
                                        inputData={{ name: "templateExplain", value: this.state.templateExplain || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={{ ...inputSetting, cols: 30, rows: 10 }} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.orderNo.label}
                                        inputData={{ name: "orderNo", value: this.state.orderNo !== undefined ? this.state.orderNo : "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSettingNum}
                                        errorMessage={this.validation.errorMessage.orderNo || ""} />
                                </tr>
                            </tbody>
                        </table>
                    </div >
                </div >

                {buttonDOM}

                <div className="l-pageAction l-pageAction--back">
                    <Link to={pageInfo.TEMPLATE_LIST.path} aria-label="link" className="pageAction">一覧へもどる</Link>
                </div>
            </BaseGrid >
        );
    }

    /** 見出し一覧のリクエスト */
    requestTemplateHeadlines = () => {
        // 取得API呼出し
        awsReq.get(
            AWS.ENDPOINT.PATHS.TEMPLATE_HEADLINES,
            response => {
                const res = convert.convertToFlatCamelCase(response.data);

                // URLに一致する見出しの情報取得
                const matched = res.filter(item => { return item.headlineId === this.templateHeadlineId; })[0];
                this.setState({ templateHeadline: matched.headline });
            },
            awsReq.defaultErrorHandler,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    /** 取得APIを呼出し、stateを更新する */
    requestTemplate = () => {
        awsReq.get(
            AWS.ENDPOINT.PATHS.TEMPLATE_DETAIL.replace(":id", this.id),
            response => { this.setState(convert.convertToFlatCamelCase(response.data)); },
            awsReq.defaultErrorHandler,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    registerTemplate = () => {
        this.apiCalling = true;
        this.setState({});

        let query = {};

        query.template_headline_id = this.templateHeadlineId;
        query.order_no = this.state.orderNo;
        query.template_item_id = this.state.templateItemId;
        // 指定されている場合のみtemplate_typeを設定（undefinedの場合、パラメータに設定されない）
        query.template_type = undefined;
        if (this.state.templateType) {
            query.template_type = this.state.templateType;
        }
        query.template_item = this.state.templateItem;
        query.template = this.state.template;
        // 指定されている場合のみtemplate_explainを設定（undefinedの場合、パラメータに設定されない）
        query.template_explain = undefined;
        if (this.state.templateExplain) {
            query.template_explain = this.state.templateExplain;
        }

        if (this.type === this.TYPE_UPDATE) {
            // 更新モード
            query.id = this.id; // 更新時はリクエストにID付与
            awsReq.put(AWS.ENDPOINT.PATHS.TEMPLATE_DETAIL.replace(":id", this.state.id),
                response => {
                    this.apiCalling = false;
                    this.setState({ templateItemId: undefined });
                },
                this.errorCallback,
                query,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        } else {
            // 登録モード
            awsReq.post(AWS.ENDPOINT.PATHS.TEMPLATE,
                response => {
                    this.apiCalling = false;

                    // APIのレスポンスのLocationヘッダの末尾の文字列（ID部分）を取得
                    const id = response.headers["location"].split("/").slice(-1)[0];
                    this.props.history.push(pageInfo.TEMPLATE_MAINTENANCE.path.replace(":templateHeadlineId", this.templateHeadlineId).replace(":type", id));
                    this.setState({ templateItemId: undefined });
                },
                this.errorCallback,
                query,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }

    /** 削除API */
    deleteTemplate = () => {
        this.apiCalling = true;
        this.setState({});

        awsReq.del(
            AWS.ENDPOINT.PATHS.TEMPLATE_DETAIL.replace(":id", this.id),
            () => {
                this.apiCalling = false;
                this.props.history.push(pageInfo.TEMPLATE_LIST.path);
            },
            this.errorCallback,
            {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    /** APIコール時のエラーコールバック関数 */
    errorCallback = error => {
        alert(ERROR_MESSAGE.SERVER_ERROR);
        this.apiCalling = false;
        this.setState({}); // 再renderする為に、stateを空更新
    };
}

export default withRouter(TemplateMaintenance);
