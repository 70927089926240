import { PageBase } from "components/page/user/contractFrk/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";
import Block4 from "./block4";
import Block5 from "./block5";
import Block6 from "./block6";
import Block7 from "./block7";
import Block8 from "./block8";

class ImpPage12 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     * ※チェックボックスの場合、falseにする
     */
    getPageItems = () => {
        return {
            // 取引条件
            // 売買代金および交換差金以外に売主・買主間で授受される金銭の額
            TOR00009: undefined, // 売買代金総額　消費税等相当額
            TOR00010: undefined, // 売買代金総額
            TOR00340: undefined, // 手付金の金額（1項目目の「授受の目的　金額」）
            JYU01086_ARR: [{
                JYU01086: "手付金（売買代金の一部に充当します。）", // 授受の目的　名称
                // 1項目目はTOR00340を参照する
                JYU01089: undefined, // 授受の目的　備考
            }, {
                JYU01086: "固定資産税等清算金", // 授受の目的　名称
                JYU01087: undefined, // 授受の目的　金額
                JYU01716: undefined, // 授受の目的　フリー
                JYU01089: undefined, // 授受の目的　備考
            }, {
                JYU01086: "管理費等清算金", // 授受の目的　名称
                JYU01087: undefined, // 授受の目的　金額
                JYU01716: undefined, // 授受の目的　フリー
                JYU01089: undefined, // 授受の目的　備考
            }, {
                JYU01086: undefined, // 授受の目的　名称
                JYU01087: undefined, // 授受の目的　金額
                JYU01089: undefined, // 授受の目的　備考
            }, {
                JYU01086: undefined, // 授受の目的　名称
                JYU01087: undefined, // 授受の目的　金額
                JYU01089: undefined, // 授受の目的　備考
            }],
            JYU01100: undefined, // 備考

            // 契約の解除等に関する事項
            JYU01101: false, // 手付解除　有
            JYU01443: false, // 手付解除　無
            JYU01102: undefined, // 手付解除　期日
            JYU01104: false, // 引渡し前の滅失による解除　有
            JYU01444: false, // 引渡し前の滅失による解除　無
            JYU01110: false, // 融資利用の特約による解除　有
            JYU01275: false, // 融資利用の特約による解除　無
            TOR00033: undefined, // ローン解除期日１(融資利用の特約による解除　期日)
            JYU01117: false, // 譲渡承諾の特約による解除　有
            JYU01447: false, // 譲渡承諾の特約による解除　無
            JYU01118: undefined, // 譲渡承諾の特約による解除　期日
            JYU01113: false, // 瑕疵担保責任による解除　有
            JYU01446: false, // 瑕疵担保責任による解除　無
            JYU01717: false, //	契約違反による解除　有
            JYU01718: false, // 契約違反による解除　無
            JYU01121: undefined, // その他の解除　内容

            // 損害賠償の予定または違約金に関する事項
            JYU01124: false, // 違約金　手付金の額
            JYU01125: false, // 違約金　売買代金
            JYU01126: undefined, // 違約金　売買代金　％相当額
            JYU01127: false, // 違約金　その他　チェック
            JYU01128: undefined, // 違約金　その他　金額
            JYU01719: undefined, // 違約金　その他　フリー

            // 手付金等の保全措置の概要
            JYU01132: false, // 該当する
            JYU01441: false, // 該当しない
            JYU01133: false, // 講じない　チェック
            JYU01134: false, // 講じない　未完成物件
            JYU01135: false, // 講じない　完成物件
            JYU01136: false, // 講じる　チェック
            JYU01137: false, // 講じる　未完成物件
            JYU01138: false, // 講じる　完成物件
            JYU01139: false, // 講じる　保証委託契約
            JYU01140: false, // 講じる　保証保険契約
            JYU01141: false, // 講じる　手付金等寄託契約および質権設定契約
            JYU01143: undefined, // 講じる　保全機関

            // 支払金または預かり金の保全措置の概要
            JYU01144: false, // 講じる
            JYU01145: false, // 講じない
            JYU01146: undefined, // 保全措置を講じる機関

            // 金銭の貸借のあっせん
            // あっせん有
            JYU01720: false, // あっせん有　斜線
            JYU01148_ARR: [{
                JYU01148: undefined, // あっせん有　金融機関1
                JYU01793: undefined, // あっせん有　金融機関支店名1
                JYU01721: undefined, // あっせん有　金額1（万円）　フリー
                JYU01149: undefined, // あっせん有　金額1（万円）
                JYU01150: undefined, // あっせん有　融資承認取得期日1
                JYU01722: undefined, // あっせん有　金利1（年）（％）　フリー
                JYU01151: undefined, // あっせん有　金利1（年）（％）
                JYU01723: undefined, // あっせん有　借入期間1（年）　フリー
                JYU01153: undefined, // あっせん有　借入期間1（年）
                JYU01154: undefined, // あっせん有　返済方法1
                JYU01724: undefined, // あっせん有　保証料1　フリー
                JYU01155: undefined, // あっせん有　保証料1
                JYU01725: undefined, // あっせん有　ローン事務手数料1（税込）　フリー
                JYU01156: undefined, // あっせん有　ローン事務手数料1（税込）
            },
            {
                JYU01148: undefined, // あっせん有　金融機関2
                JYU01793: undefined, // あっせん有　金融機関支店名2
                JYU01721: undefined, // あっせん有　金額2（万円）　フリー
                JYU01149: undefined, // あっせん有　金額2（万円）
                JYU01150: undefined, // あっせん有　融資承認取得期日2
                JYU01722: undefined, // あっせん有　金利2（年）（％）　フリー
                JYU01151: undefined, // あっせん有　金利2（年）（％）
                JYU01723: undefined, // あっせん有　借入期間2（年）　フリー
                JYU01153: undefined, // あっせん有　借入期間2（年）
                JYU01154: undefined, // あっせん有　返済方法2
                JYU01724: undefined, // あっせん有　保証料2　フリー
                JYU01155: undefined, // あっせん有　保証料2
                JYU01725: undefined, // あっせん有　ローン事務手数料2（税込）　フリー
                JYU01156: undefined, // あっせん有　ローン事務手数料2（税込）
            }],
            JYU01755: false, // あっせん有　無チェック2
            // あっせん無
            JYU01726: false, // あっせん無　斜線
            JYU01168_ARR: [{
                JYU01168: undefined, // あっせん無　金融機関1
                JYU01794: undefined, // あっせん無　金融機関支店名1
                JYU01727: undefined, //	あっせん無　金額1（万円）　フリー
                JYU01169: undefined, // あっせん無　金額1（万円）
                JYU01170: undefined, // あっせん無　融資承認取得期日1
                JYU01728: undefined, //	あっせん無　金利1（年）（％）　フリー
                JYU01171: undefined, // あっせん無　金利1（年）（％）
                JYU01729: undefined, //	あっせん無　借入期間1（年）　フリー
                JYU01173: undefined, // あっせん無　借入期間1（年）
            },
            {
                JYU01168: undefined, // あっせん無　金融機関2
                JYU01794: undefined, // あっせん無　金融機関支店名2
                JYU01727: undefined, //	あっせん無　金額2（万円）　フリー
                JYU01169: undefined, // あっせん無　金額2（万円）
                JYU01170: undefined, // あっせん無　融資承認取得期日2
                JYU01728: undefined, //	あっせん無　金利2（年）（％）　フリー
                JYU01171: undefined, // あっせん無　金利2（年）（％）
                JYU01729: undefined, //	あっせん無　借入期間2（年）　フリー
                JYU01173: undefined, // あっせん無　借入期間2（年）
            },
            {
                JYU01168: undefined, // あっせん無　金融機関3
                JYU01794: undefined, // あっせん無　金融機関支店名3
                JYU01727: undefined, //	あっせん無　金額3（万円）　フリー
                JYU01169: undefined, // あっせん無　金額3（万円）
                JYU01170: undefined, // あっせん無　融資承認取得期日3
                JYU01728: undefined, //	あっせん無　金利3（年）（％）　フリー
                JYU01171: undefined, // あっせん無　金利3（年）（％）
                JYU01729: undefined, //	あっせん無　借入期間3（年）　フリー
                JYU01173: undefined, // あっせん無　借入期間3（年）
            }],
            JYU01756: false, // あっせん無　無チェック2
            JYU01759: false, // あっせん無　無チェック3

            // 割賦販売に係る事項
            JYU01200: false, // 割賦販売　有
            JYU01442: false, // 割賦販売　無

            // 土地の測量によって得られた面積による売買代金の清算
            JYU01209: false, // 清算対象　有
            JYU01449: false, // 清算対象　無
            JYU01210: false, // 清算対象　私道負担のない場合、登記簿面積
            JYU01211: false, // 清算対象　私道負担のある場合、それを除く有効宅地部分
            JYU01214: undefined, // 清算基準面積（㎡）
            JYU01215: undefined, // 清算単価（１㎡当たり）
            JYU01212: false, // 清算対象　その他　チェック
            JYU01213: undefined, // 清算対象　その他　内容
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return ["JYU01086_ARR", "JYU01148_ARR", "JYU01168_ARR"];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return ["TOR00009", "TOR00010", "TOR00340", "TOR00033"
            , "TOR00031", "TOR00032", "TOR00268", "TOR00269", "TOR00271", "TOR00272", "TOR00298", "TOR00299"];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            contractTypeId: this.props.contractTypeId,  // 契約種別ID
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
            useTypeID: this.props.useTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 取引条件 */}
                    <li><Block2 {...params} /></li> {/** 契約の解除等に関する事項 */}
                    <li><Block3 {...params} /></li> {/** 損害賠償の予定または違約金に関する事項 */}
                    <li><Block4 {...params} /></li> {/** 手付金等の保全措置の概要 */}
                    <li><Block5 {...params} /></li> {/** 支払金または預かり金の保全措置の概要 */}
                    <li><Block6 {...params} /></li> {/** 金銭の貸借のあっせん */}
                    <li><Block7 {...params} /></li> {/** 割賦販売に係る事項 */}
                    <li><Block8 {...params} /></li> {/** 土地の測量によって得られた面積による売買代金の清算 */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
            </React.Fragment>
        );
    }
}

export default withRouter(ImpPage12);
