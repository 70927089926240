import { PageBase } from "components/page/user/contract/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router-dom";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";

class ImpPage7 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     */
    getPageItems = () => {
        return {
            // 飲用水・電気・ガスの供給施設および排水施設の整備状況
            JYU00706: false, // 直ぐに利用可能な施設 公営水道
            JYU00707: false, // 直ぐに利用可能な施設 私営水道
            JYU00708: false, // 直ぐに利用可能な施設 井戸
            JYU00709: false, // 配管・供給等の状況 前面道路配管 有
            JYU01384: false, // 配管・供給等の状況 前面道路配管 無
            JYU00710: undefined, // 配管・供給等の状況 前面道路配管 口径
            JYU00711: false, // 配管・供給等の状況 敷地内引込管 有
            JYU01385: false, // 配管・供給等の状況 敷地内引込管 無
            JYU00712: undefined, // 配管・供給等の状況 敷地内引込管 口径
            JYU00713: false, // 配管・供給等の状況 私設管 有
            JYU01386: false, // 配管・供給等の状況 私設管 無
            JYU00714: false, // 整備予定・負担金 有
            JYU01387: false, // 整備予定・負担金 無
            JYU00715: undefined, // 整備予定・負担金 日付
            JYU00716: undefined, // 整備予定・負担金 金額
            JYU01474: false, // 電気 有
            JYU01475: false, // 電気 無
            JYU00718: false, // 直ぐに利用可能な施設 一括受電方式である
            JYU00721: false, // 直ぐに利用可能な施設 一括受電方式でない
            JYU00723: undefined, // 直ぐに利用可能な施設 小売電気事業者 名称
            JYU00720: undefined, // 直ぐに利用可能な施設 一括受電方式 終了日付
            JYU00727: false, // 整備予定・負担金 有
            JYU01388: false, // 整備予定・負担金 無
            JYU00728: undefined, // 整備予定・負担金 日付
            JYU00729: undefined, // 整備予定・負担金 金額
            JYU00731: false, // 直ぐに利用可能な施設 都市ガス
            JYU00732: false, // 直ぐに利用可能な施設 個別プロパン
            JYU00733: false, // 直ぐに利用可能な施設 集中プロパン
            JYU00736: false, // 配管・供給等の状況 前面道路配管 有
            JYU01389: false, // 配管・供給等の状況 前面道路配管 無
            JYU00737: undefined, // 配管・供給等の状況 前面道路配管 口径
            JYU00738: false, // 配管・供給等の状況 敷地内配管 有
            JYU01390: false, // 配管・供給等の状況 敷地内配管 無
            JYU01391: undefined, // 配管・供給等の状況 敷地内配管 口径
            JYU00741: false, // 整備予定・負担金 有
            JYU01392: false, // 整備予定・負担金 無
            JYU00742: undefined, // 整備予定・負担金 日付
            JYU00743: undefined, // 整備予定・負担金 金額
            JYU00745: false, // 直ぐに利用可能な施設 公共下水
            JYU00746: false, // 直ぐに利用可能な施設 個別浄化槽
            JYU00747: false, // 直ぐに利用可能な施設 集中浄化槽
            JYU00748: false, // 直ぐに利用可能な施設 その他
            JYU00749: false, // 整備予定・負担金 有
            JYU01393: false, // 整備予定・負担金 無
            JYU00750: undefined, // 整備予定・負担金 日付
            JYU00751: undefined, // 整備予定・負担金 金額
            JYU00754: false, // 直ぐに利用な施設 公共下水
            JYU00755: false, // 直ぐに利用な施設 個別浄化槽
            JYU00756: false, // 直ぐに利用な施設 個別浄化槽 放流先 埋設管
            JYU00757: false, // 直ぐに利用な施設 個別浄化槽 放流先 側溝
            JYU00758: false, // 直ぐに利用な施設 個別浄化槽 放流先 浸透
            JYU00759: false, // 直ぐに利用な施設 集中浄化槽
            JYU00760: false, // 直ぐに利用な施設 汲取式
            JYU00761: false, // 配管・供給等の状況 前面道路配管 有
            JYU01394: false, // 配管・供給等の状況 前面道路配管 無
            JYU00762: false, // 配管・供給等の状況 敷地内配管 有
            JYU01395: false, // 配管・供給等の状況 敷地内配管 無
            JYU00763: false, // 配管・供給等の状況 浄化槽の設置 既設
            JYU00764: false, // 配管・供給等の状況 浄化槽の設置 可
            JYU00765: false, // 配管・供給等の状況 浄化槽の設置 不可
            JYU00766: false, // 整備予定・負担金 有
            JYU01396: false, // 整備予定・負担金 無
            JYU00767: undefined, // 整備予定・負担金 日付
            JYU00768: undefined, // 整備予定・負担金 金額
            JYU00769: false, // 直ぐに利用な施設 公共下水
            JYU00770: false, // 直ぐに利用な施設 個別浄化槽
            JYU00771: false, // 直ぐに利用な施設 集中浄化槽
            JYU00772: false, // 直ぐに利用な施設 側溝等
            JYU00773: false, // 直ぐに利用な施設 浸透
            JYU00774: false, // 配管・供給等の状況 前面道路配管 有
            JYU01397: false, // 配管・供給等の状況 前面道路配管 無
            JYU00775: false, // 配管・供給等の状況 敷地内配管 有
            JYU01398: false, // 配管・供給等の状況 敷地内配管 無
            JYU00776: false, // 整備予定・負担金 有
            JYU01399: false, // 整備予定・負担金 無
            JYU00777: undefined, // 整備予定・負担金 日付
            JYU00778: undefined, // 整備予定・負担金 金額
            JYU00779: false, // 直ぐに利用な施設 公共下水
            JYU00780: false, // 直ぐに利用な施設 側溝等
            JYU00781: false, // 直ぐに利用な施設 浸透
            JYU00782: false, // 整備予定・負担金 有
            JYU01400: false, // 整備予定・負担金 無
            JYU00783: undefined, // 整備予定・負担金 日付
            JYU00784: undefined, // 整備予定・負担金 金額
            JYU00785: undefined, // 備考
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 私道負担に関する負担等に関する事項 */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
            </React.Fragment>
        );
    }
}

export default withRouter(ImpPage7);