import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import React from "react";
import InputCheckbox from "../../input/inputCheckbox";
import Input from "../../input/inputNormal";

/** 借地の表示 */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021702",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">借地の表示</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在</th>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住居表示</th>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU01487" name="JYU01487" value={this.props.pageState.JYU01487} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">図面の有無</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">地番</th>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">登記地番</th>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU01488" name="JYU01488" value={this.props.pageState.JYU01488} {...params} length="wFull" />
                                    </div>
                                </td>
                                <td className="dataTable-body dataTable-body__leftborder">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01492" name="JYU01492" checked={this.props.pageState.JYU01492} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01493" name="JYU01493" checked={this.props.pageState.JYU01493} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">地積</th>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">登記簿：</div>
                                        <Input id="ID_JYU01489" name="JYU01489" value={this.props.pageState.JYU01489} {...params} length="w70" />
                                        <div className="dataTableUnit-text">
                                            m<sup>2</sup>
                                        </div>
                                        <div className="dataTableUnit-text">契約：</div>
                                        <Input id="ID_JYU01490" name="JYU01490" value={this.props.pageState.JYU01490} {...params} length="w70" />
                                        <div className="dataTableUnit-text">
                                            m<sup>2</sup>
                                        </div>
                                        <div className="dataTableUnit-text">実測：</div>
                                        <Input id="ID_JYU01491" name="JYU01491" value={this.props.pageState.JYU01491} {...params} length="w70" />
                                        <div className="dataTableUnit-text">
                                            m<sup>2</sup>
                                        </div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">地目</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU01494" name="JYU01494" value={this.props.pageState.JYU01494} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}