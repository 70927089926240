import { Auth } from "aws-amplify";
import axios from "axios";
import { ENDPOINT_OCR } from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { Link } from "react-router-dom";

export default class RefDocument extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: undefined, // アップロード選択されたファイルのバイナリ
            name: undefined, // アップロード選択されたファイルの名前
            text: undefined, // OCR読み取った結果のテキスト
            done: false, // OCR読み取り済み false：未読み取り、true：読み取り済み
            apiCalling: false, // true: APIコール中
        };
    }

    componentDidMount() {

        document.title = this.state.done
            ? pageInfo.REF_DOCUMENT.title
            : pageInfo.SELECT_REF_DOCUMENT.title;
    }

    componentDidUpdate() {
        document.title = this.state.done
            ? pageInfo.REF_DOCUMENT.title
            : pageInfo.SELECT_REF_DOCUMENT.title;
    }

    handleRead = async e => {
        e.preventDefault();

        // OCR用のURLへアップロード
        let form = new FormData();
        form.append('image', this.state.data);
        try {
            this.apiCalling = true;
            this.setState({});

            // IDトークン取得する用
            const data = await Auth.currentSession();
            // multipart/form-dataでPOSTする
            const res = await axios.post(`${ENDPOINT_OCR.URL}${ENDPOINT_OCR.PATHS.OCR}`, form, {
                headers: { "x-id-token": data.getIdToken().getJwtToken(), }
            });
            this.apiCalling = false;
            this.setState({ done: true, text: res.data.result });

        } catch (error) {
            this.apiCalling = false;
            this.setState({});
            alert(ERROR_MESSAGE.SERVER_ERROR);
            return;
        }
    }

    /** ファイルアップロードの選択がされた際のハンドラ */
    handleSelectUploadFile = e => {
        const target = e.target.files[0];
        // APIコール失敗→ファイル選択→キャンセル押下でundefinedがくるのでその対応
        if (target === undefined) { return; }
        this.setState({ data: target, name: target.name });
    }

    /** 閉じるボタンが押下された際のハンドラ */
    handleClose = e => {
        window.close();
    }

    render() {
        if (this.apiCalling) {
            //ローディングの回転.svgの位置設定
            const loadCircleTop = window.innerHeight / 2 - 40 + "px"; // -60はLoading画像サイズの半分ずらし
            const loadCircleLeft = window.innerWidth / 2 - 35 + "px"; // -60はLoading画像サイズの半分ずらし
            //ローディングのテキスト付の白枠の位置設定
            const loadFrameTop = window.innerHeight / 2 - 98 + "px"; // --98はLoading画像サイズの半分ずらし
            const loadFrameLeft = window.innerWidth / 2 - 229 + "px"; // --299はLoading画像サイズの半分ずらし

            return <dev><img src="/img/loading_frame.png" alt="" style={{ position: "fixed", zIndex: 99, top: loadFrameTop, left: loadFrameLeft }} /> <img src="/img/loading.svg" alt="" style={{ position: "fixed", zIndex: 100, top: loadCircleTop, left: loadCircleLeft }} /></dev>;
        }

        // 最初は"参考書類ファイル選択"を表示し、ファイルアップロードが完了した場合に"参考書類認識結果"にDOMを切り替える
        // ※画面を遷移させるとOCR-APIのレスポンステキストを引き継ぐのがめんどくさいので
        if (!this.state.done) {
            return this.createSelectDocDOM();
        } else {
            return this.createRefDOM();
        }
    }

    /** 参考書類ファイル選択のDOM */
    createSelectDocDOM = () => {
        // ファイル選択がされていない場合、ボタンを非アクティブ、押下時のアクションを無効にする
        const readButtonClass = this.state.data ? "baseAction" : "baseAction baseAction--inactive";
        const readButtonClick = this.state.data ? this.handleRead : e => { e.preventDefault(); };

        return (
            <React.Fragment>
                <div className="l-referenceField">
                    <div className="referenceField">
                        <div className="referenceField-header">
                            <div className="referenceField-header-note">表が描かれた画像ファイルから枠ごとの文字を読み取ります</div>
                        </div>
                        <div className="referenceField-body">
                            <div className="referenceField-body-dropope">
                                <div className="referenceFile">
                                    <div className="referenceFile-input">
                                        <input type="file" aria-label="text" accept=".png,.jpg,.pdf" onChange={this.handleSelectUploadFile} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="l-baseAction l-baseAction__rightTwo l-baseAction__doubleSpace">
                            <li><Link to="" aria-label="link" className={readButtonClass} onClick={readButtonClick}>読込</Link></li>
                            <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleClose}>閉じる</Link></li>
                        </ul>
                    </div>
                </div>
            </React.Fragment >
        );
    }

    /** 参考書類認識結果のDOM */
    createRefDOM = () => {
        const text = this.state.text === [] ? "" : this.state.text.join("\n");

        return (
            <div className="l-referenceField">
                <div className="referenceField">
                    <div className="referenceField-header">
                        <div className="referenceField-header-title">文字読み取り結果</div>
                        <div className="referenceField-header-note">画像から以下のような文字が読み取れました。</div>
                    </div>
                    <div className="referenceField-body">
                        <textarea name="" id="" cols="30" rows="10" aria-label="textarea" value={text} readOnly></textarea>
                    </div>
                </div>
            </div>
        );
    }
}