import { ERROR_MESSAGE } from "constants/errorMessage";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import * as AWS from "../../../../../../constants/aws";
import * as awsReq from "../../../../../../util/awsRequest";
import Pagination from "../../../../../common/parts/pagination";
import PopupBase from "./popupBase";

/** ポップアップ画面：従業員情報一覧 */
class PopupEmployeeList extends PopupBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            selectedOrg: undefined, // 組織／支店のセレクトボックスで、選択されている項目のvalue
            selectbox: undefined, // 組織／支店のセレクトボックスに表示する項目
        };

        this.apicalling = 0; // APIコール中のページを保持（0はコール無し。連続API防止措置なので簡易）
    }

    componentDidMount() {
        // セレクトボックスに表示するための情報取得
        awsReq.get(
            AWS.ENDPOINT.PATHS.BRANCHAND_LIST,
            res => {
                this.setState({ selectbox: [""].concat(res.data), });
            },
            awsReq.defaultErrorHandler,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    // キーワード入力時のハンドラ
    handleKeyword = e => {
        e.preventDefault();
        this.setState({ keyword: e.target.value });
    }

    // クリアボタン押下のハンドラ
    handleClear = e => {
        e.preventDefault();
        // selectedOrg は null や undefinedにしても、選択状態がクリアされないので、"" を指定する
        this.setState({ keyword: "", selectedOrg: "", data: undefined });
    }

    // ポップアップの中身を表示するためのAPIリクエスト
    RequestAPI = () => {
        if (this.apicalling !== this.state.activePage) {
            this.apicalling = this.state.activePage;

            /** 従業員一覧取得 */
            let query = { page: this.state.activePage, };
            if (this.state.selectedOrg !== undefined && this.state.selectedOrg !== "") {
                query.agency_branch_id = Number(this.state.selectedOrg);
            }

            // キーワードがある時はキーワードをクエリに入れる
            if (this.state.keyword !== "") {
                query.keyword = this.state.keyword;
            }

            awsReq.post(
                AWS.ENDPOINT.PATHS.GET_POPUP_AGENCY_USER_LIST,
                res => {
                    this.apicalling = 0;
                    // 現在表示しているページに対応しているレスポンスの場合、レスポンスをセットする
                    // ※レスポンスを全て画面反映させるとページ送りした分の全ての結果が順番に表示されてしまうため
                    // ※1ページ目を表示している時に8ページ目に行くためには、1,5,8と遷移する必要があるが（この時、5は中継地点なので結果表示の必要がない）、
                    //   レスポンスを全て画面表示すると、8ページ目を表示する前に5ページ目の内容を一瞬表示してしまうことの対策
                    if (this.state.activePage === res.data.page_number) {
                        this.setState({
                            data: res.data.agency_users,
                            itemsCountPerPage: res.data.per_page,
                            totalItemsCount: res.data.total_records,
                        });
                    }
                },
                error => {
                    this.apicalling = 0;
                    this.setState({ data: [] });
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                },
                query,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }

    handlePickup = e => {
        e.preventDefault();
        this.setState({ data: undefined, activePage: 1 });
    }

    /** セレクトボックス用のハンドラ */
    handleSelectbox = e => {
        e.preventDefault();
        this.setState({ selectedOrg: e.target.value });
    }

    // 一覧表示のテーブル部分のDOMを生成
    createTable = list => {
        return (
            <div className="dataList-body dataList-body__modalScroll" tabIndex="0">
                <table className="dataTable">
                    <tbody>
                        <tr>
                            <th className="dataTable-title dataTable-title__w400">氏名</th>
                            <th className="dataTable-title">所属支店／組織</th>
                        </tr>
                        {list}
                    </tbody>
                </table>
            </div>
        );
    }

    // リスト表示用のDOMを生成
    createItems = data => {
        // セレクトボックスの中身が取れるまで値を表示しない
        if (this.state.selectbox === undefined) {
            return this.createTable(null);
        }

        let list = [];
        data.forEach((element, idx) => {
            const isActive = this.selectedIdx === idx ? "is-active" : null;
            const item = (
                <tr key={idx} onClick={e => { this.selectedIdx = idx; this.setState({}); }} className={isActive}>
                    <td className="dataTable-body">{element.user_name}</td>
                    <td className="dataTable-body">{element.branch_name}</td>
                </tr>
            );
            list.push(item);
        });
        return this.createTable(list);
    }

    // Loading中の空行DOMを生成
    createEmptyItems = () => {
        return this.createTable(null);
    }

    // ポップアップ表示用のDOMを生成
    createFrame = (disp, table, pageSettings) => {

        // APIから値を取得している間は絞り込むボタンを押下させないようにする
        // ※それぞれ、this.state.data が従業員一覧で、this.state.selectbox がセレクトボックスに使う値
        const pickupParams = (this.state.data !== undefined && this.state.selectbox !== undefined) ?
            { className: "baseAction", onClick: this.handlePickup }
            : { className: "baseAction baseAction-inactive", onClick: e => e.preventDefault() };

        return (
            <React.Fragment>
                <div className="l-modal_overlay is-active">
                    <div className="modal_overlay"></div>
                </div>
                <main className="l-modalBox">
                    <div className="modalBox">
                        <div className="dataList dataList__accordion is-open">
                            <div className="dataList-header">絞り込み条件</div>
                            <div className="dataList-body">
                                <table className="dataTable">
                                    <tbody>
                                        <tr>
                                            <th className="dataTable-title dataTable-title__w120">所属支店／組織</th>
                                            <td className="dataTable-body dataTable-body__w355">
                                                <div className="selectBox">
                                                    <select name="" className="selectBox-select" aria-label="select" value={this.state.selectedOrg} onChange={this.handleSelectbox}>
                                                        {generateSelectbox(this.state.selectbox)}
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="dataTable-title dataTable-title__w120">キーワード</th>
                                            <td className="dataTable-body dataTable-body__w355"><input type="text" aria-label="text" onChange={this.handleKeyword} value={this.state.keyword} /></td>
                                        </tr>
                                        <tr>
                                            <th className="dataTable-title dataTable-title__w120">&nbsp;</th>
                                            <td className="dataTable-body dataTable-body__w355">
                                                <ul className="l-baseAction l-baseAction__rightTwo">
                                                    <li><Link to="" aria-label="link" {...pickupParams}>絞り込み</Link></li>
                                                    <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleClear}>クリア</Link></li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Pagination pageSettings={pageSettings} />
                        <div className="dataList dataList__accordion dataList__modalScroll is-open">
                            <div className="dataList-header">
                                <div className="dataList-header-title">従業員一覧</div>
                            </div>
                            {table}
                        </div>
                        <Pagination pageSettings={pageSettings} />
                        <ul className="l-baseAction l-baseAction__rightTwo">
                            <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleSelect}>選択</Link></li>
                            <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleCancel}>キャンセル</Link></li>
                        </ul>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

/**
 * objは下記の想定（ポップアップ内のセレクトボックス表示用のAPIからのレスポンス）
 * array : [ { id: ... , branch_name: .... , } ]
 */
const generateSelectbox = array => {
    if (array === undefined || array == null) {
        return null;
    }

    return array.map((item, idx) => {
        return <option key={idx} value={item.id}>{item.branch_name}</option>;
    });
};

export default withRouter(PopupEmployeeList);