import React from "react";
import { Link } from "react-router-dom";
import Validation from "util/validate";
import { CONTRACT_EDIT_INFO } from "../../../../../../../constants/contractEditPageInfo";
import { SEND_OTHER_PAPER } from "../../../../../../../constants/messages";
import InputCheckbox from "../../input/inputCheckbox";
import InputTextArea from "../../input/inputTextArea";
import InputTextRemark from "../../input/inputTextRemark";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 敷地権の目的たる土地 */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020202",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    handleTo0101 = e => {
        e.preventDefault();
        // リロードをかける
        this.props.pageSwitch(CONTRACT_EDIT_INFO.PAGE_0101.id);
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        //JYU00195の入力上限判定
        const JYU00195LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00195_${this.props.displayTypeID}`, this.props.pageState.JYU00195);

        //JYU00196の入力上限判定
        const JYU00196LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00196_${this.props.displayTypeID}`, this.props.pageState.JYU00196);



        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        const items = this.props.pageState.TOR00217_ARR !== undefined ? this.props.pageState.TOR00217_ARR : [];
        const domItems = items.map((item, idx) => {
            return (
                <React.Fragment key={idx}>
                    <tr>
                        <th rowSpan="5" className="dataTable-title dataTable-title__w120 dataTable-title__center">{idx + 1}</th>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在および<br />地番</th>
                        <td className="dataTable-body">{item.TOR00217}</td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">地目</th>
                        <td className="dataTable-body">{item.TOR00218}</td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">地積</th>
                        <td className="dataTable-body" colSpan="2">
                            <div className="dataTableUnit">
                                <div className="dataTableUnit-text">公簿</div>
                                <div className="dataTableUnit-text">{item.TOR00219}</div>
                                <div className="dataTableUnit-text">m<sup>2</sup></div>
                                <div className="dataTableUnit-text">実測</div>
                                <div className="dataTableUnit-text">{item.TOR00220}m<sup>2</sup></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">敷地権の種類</th>
                        <td className="dataTable-body">{item.TOR00221}</td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">持分</th>
                        <td className="dataTable-body">{item.TOR00222}</td>
                    </tr>
                </React.Fragment>
            );
        });

        return (
            <React.Fragment>
                <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">敷地権の目的たる土地</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                {domItems}
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">合計</th>
                                    <td className="dataTable-body" colSpan="2">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-text">{this.props.pageState.TOR00235}</div>
                                            <div className="dataTableUnit-text">筆</div>
                                            <div className="dataTableUnit-text">地積（登記簿）</div>
                                            <div className="dataTableUnit-text">{this.props.pageState.TOR00236}m<sup>2</sup></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">管理規約に<br />規約敷地の<br />定め</th>
                                    <td className="dataTable-body" colSpan="2">
                                        <div className="dataTableMultiple">
                                            <div className="dataTableMultiple-item">
                                                <ul className="l-checkBox">
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_JYU00194" name="JYU00194" checked={this.props.pageState.JYU00194} {...params} />
                                                            <span className="checkBox-text">有</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_JYU01455" name="JYU01455" checked={this.props.pageState.JYU01455} {...params} />
                                                            <span className="checkBox-text">無</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="dataTableMultiple-item">
                                                <InputTextArea id="ID_JYU00195" name="JYU00195" value={this.props.pageState.JYU00195} {...params} placeholder={JYU00195LimitErrorMessage.inputLimitGuide} {...params} />
                                                <p className="LengthOver-error">{JYU00195LimitErrorMessage.inputLimitErrorMessage}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                    <td className="dataTable-body" colSpan="4">
                                        <div className="dataTableTextUnit">
                                            <InputTextRemark id="ID_JYU00196" name="JYU00196" value={this.props.pageState.JYU00196} text={SEND_OTHER_PAPER.JYU00196} placeholder={JYU00196LimitErrorMessage.inputLimitGuide} {...params} />
                                            <p className="LengthOver-error">{JYU00196LimitErrorMessage.inputLimitErrorMessage}</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="l-dataListMore">
                    <Link to="" aria-label="link" className="dataListMore" onClick={this.handleTo0101}>上記内容を修正したい場合は、こちらをクリックしてください</Link>
                </div>
            </React.Fragment>
        );
    }
}