import axios from "axios";
import { Footer } from "components/common/parts/footerUnauth";
import { FormButtonRecord, FormInputTextRecord } from "components/common/parts/formRecord";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import queryString from "query-string";
import React from "react";
import { withRouter } from "react-router";
import Validation from "util/validate";

/** パスワード再設定画面 */
class PasswordReset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkUrl: false, // true: URLチェック済み
            newPassword: undefined, // 新パスワード
            passwordConf: undefined, // 確認用パスワード
            redis_key: undefined, // APIのレスポンス
            email: undefined, // APIのレスポンス
            loginLoadingIndicator: false //ローディング表示中かどうか true:ローディング表示中
        };
        this.passwordValidateErrorMessage = "";

        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();

        // 画面から入力される値の情報
        this.params = {
            email: {
                label: "メールアドレス",
                validate: this.validation.notEmptyAndIsEmail,
                apiParamKey: "username"
            },
            newPassword: {
                label: "パスワード",
                validate: this.validation.notEmpty,
                apiParamKey: "password"
            },
            passwordConf: {
                label: "パスワード確認",
                validate: this.validation.notEmpty,
                apiParamKey: "password_conf"
            }
        };
    }

    componentDidMount() {
        document.title = pageInfo.PASSWORD_RESET.title;
        this.urlCheck();
    }

    urlCheck = async () => {
        const parsed = queryString.parse(this.props.location.search);

        // URLチェック
        try {
            this.setState({ loginLoadingIndicator: true });
            const res = await axios.get(
                `${AWS.ENDPOINT.REST_API.URL}${AWS.ENDPOINT.PATHS.CHECK_OPERATOR_PASSWORD_RESET_URL}`,
                { params: { ...parsed } }
            );
            this.setState({ loginLoadingIndicator: false });
            this.setState({ checkUrl: true, redis_key: res.data.redis_key, email: res.data.email, });
        } catch (error) {
            this.setState({ loginLoadingIndicator: false });
            this.props.history.push(pageInfo.TIMEOUT.path);
        }
    }

    passwordReissue = async () => {
        try {
            const query = {
                redis_key: this.state.redis_key,
                new_password: this.state.newPassword,
                confirmation_password: this.state.passwordConf,
            };
            this.setState({ loginLoadingIndicator: true });
            await axios.put(
                `${AWS.ENDPOINT.REST_API.URL}${AWS.ENDPOINT.PATHS.RESET_AGENCY_PASSWORD}`,
                query,
            );
            this.setState({ loginLoadingIndicator: false });
            // パスワード成功完了画面へ飛ばす
            this.props.history.push(pageInfo.SUCCESSFUL.path);

        } catch (error) {
            this.setState({ loginLoadingIndicator: false });
            // 400系のエラーの場合、とりあえずタイムアウトした画面へ飛ばしてやり直しさせる
            if (error.response.status >= 400 && error.response.status < 500) {
                alert(ERROR_MESSAGE.FAILED_CHANGE_PASSWORD); // TODO: パスワード変更失敗メッセージ

            } else {
                alert(ERROR_MESSAGE.SERVER_ERROR);
            }
        }
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputText = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** 入力ボックスの入力値をバリデーション */
    handleValidateInputText = e => {
        const target = e.target;
        const param = this.params[target.name];
        param.validate(target.name, target.value);
        this.setState({});

        // TODO: パスワード再設定時のメッセージ表示については議論中（APIからメッセージを貰うように提案中らしい）のようなので仮実装とする
        if (target.name === "newPassword") {
            this.passwordValidateErrorMessage = "";
        }
    };

    /** 再発行ボタンのハンドラ */
    handleReissue = e => {
        e.preventDefault();

        // 全入力項目のチェックし、エラーメッセージがない場合、後続処理（登録APIの呼出し）をする
        if (!this.validation.validateTotalInputText(this.params, this.state)) {
            // エラーメッセージがない場合、同値チェックを行う
            this.validation.notEqualValues("passwordConf", this.state.passwordConf, this.state.newPassword);
            if (!this.validation.isErrorMessage()) {
                // APIコール
                this.passwordReissue();

            } else {
                this.setState({}); // エラーメッセージ表示のための空setState()
            }
        } else {
            this.setState({}); // エラーメッセージ表示のための空setState()
        }
    }

    render() {
        if (this.state.loginLoadingIndicator) {
            //ローディングの回転.svgの位置設定
            const loadCircleTop = window.innerHeight / 2 - 40 + "px"; // -60はLoading画像サイズの半分ずらし
            const loadCircleLeft = window.innerWidth / 2 - 35 + "px"; // -60はLoading画像サイズの半分ずらし
            //ローディングのテキスト付の白枠の位置設定
            const loadFrameTop = window.innerHeight / 2 - 98 + "px"; // --98はLoading画像サイズの半分ずらし
            const loadFrameLeft = window.innerWidth / 2 - 229 + "px"; // --299はLoading画像サイズの半分ずらし

            return <div className="modal_overlay_loading"><img src="/img/loading_frame.png" alt="" style={{ position: "fixed", zIndex: 99, top: loadFrameTop, left: loadFrameLeft }} /> <img src="/img/loading.svg" alt="" style={{ position: "fixed", zIndex: 100, top: loadCircleTop, left: loadCircleLeft }} /></div>;
        }


        // URLチェックが終わっていない場合、null（空画面）を表示する
        if (!this.state.checkUrl) {
            return null;
        }

        // Inputレコードに関する、タグの属性情報
        const inputSetting = { onChange: this.handleChangeInputText, onBlur: this.handleValidateInputText };

        return (
            <main>
                <div className="l-loginField">
                    <div className="l-loginField l-left_area"></div>
                    <div className="l-loginField l-right_area">
                        <div className="l-loginField-component">
                            <form>
                                <div className="loginField loginField--main">
                                    <div className="loginField-action">
                                        <div className="loginField-action-list">
                                            <style>
                                                {".password_policy_head { color: red; } .password_policy { font-size: 11px; }"}
                                            </style>
                                            <div className="password_policy password_policy_head">パスワードポリシーをご確認お願いします</div>
                                            <div className="password_policy">・半角英数記号であること</div>
                                            <div className="password_policy">・半角の英大文字、英小文字、数字をそれぞれ1文字以上含むこと</div>
                                            <div className="password_policy">・12文字以上、16文字以下であること</div>
                                            <div className="password_policy">・現在、前回のパスワードと異なること</div>
                                            <div className="password_policy">・仲介業者様でFRKフォーマットの方は現在を含めた過去３回に設定したパスワードと異なること</div>
                                            <div className="password_policy">・ユーザーIDと異なること</div>
                                        </div>

                                        <FormInputTextRecord
                                            titleName={this.params.email.label}
                                            inputData={{ name: "email", value: this.state.email || "" }}
                                            inputSetting={{ placeholder: this.params.email.label, ...inputSetting }}
                                            errorMessage={this.validation.errorMessage.email || ""}
                                        />

                                        <FormInputTextRecord password
                                            titleName={this.params.newPassword.label}
                                            inputData={{ name: "newPassword", value: this.state.newPassword || "" }}
                                            inputSetting={{ placeholder: this.params.newPassword.label, ...inputSetting }}
                                            errorMessage={this.validation.errorMessage.newPassword || this.passwordValidateErrorMessage || ""}
                                        />

                                        <FormInputTextRecord password
                                            titleName={this.params.passwordConf.label}
                                            inputData={{ name: "passwordConf", value: this.state.passwordConf || "" }}
                                            inputSetting={{ placeholder: this.params.passwordConf.label, ...inputSetting }}
                                            errorMessage={this.validation.errorMessage.passwordConf || ""}
                                        />

                                        <FormButtonRecord onClick={this.handleReissue}>再発行</FormButtonRecord>
                                    </div>
                                </div>
                            </form >
                        </div>
                        <Footer />
                    </div>
                </div>
            </main>
        );
    }
}

export default withRouter(PasswordReset);