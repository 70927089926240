/**
 * APIのレスポンスデータのkeyをスネーク型からキャメル型に変更する
 * response: APIレスポンスデータ
 * prefix: keyに先頭に結合する文字列
 */
export const convertToFlatCamelCase = (response, prefix = "") => {
    let result = {};
    if (!Array.isArray(response)) {
        const responseKeys = Object.keys(response);
        responseKeys.forEach(key => {
            if (typeof response[key] === "object" && response[key] !== null && !Array.isArray(response[key])) {
                // 取り出した値がオブジェクトの場合
                // 再帰的に当メソッドを呼出す
                const camelObject = convertToFlatCamelCase(response[key], key);
                const camelObjectKeys = Object.keys(camelObject);
                camelObjectKeys.forEach(k => { result[k] = camelObject[k]; });

            } else if (typeof response[key] === "object" && response[key] !== null && Array.isArray(response[key])) {
                // 取り出した値がリストの場合
                // そのリストを更にループし、再帰的に当メソッドを呼出す
                const list = response[key];
                const camelKey = snakeToCamel(prefix ? prefix + "_" + key : key);
                result[camelKey] = list.map(item => convertToFlatCamelCase(item));

            } else {
                // 取り出した値が文字列の場合
                const camelKey = snakeToCamel(prefix ? prefix + "_" + key : key);
                result[camelKey] = response[key];
            }
        });
    } else {
        // APIレスポンスデータが[]の場合の変換処理
        result = response.map(data => {
            let result = {};
            const keys = Object.keys(data);
            keys.forEach(key => {
                result[snakeToCamel(key)] = data[key];
            });
            return result;
        });
    }
    return result;
};

/**
 * stateデータをAPIのリクエストデータに変換する
 */
export const convertToApiReqCase = (params, state) => {
    let result = {};
    const paramsKeys = Object.keys(params);
    paramsKeys.forEach(key => {
        const apiParamKey = params[key].apiParamKey;
        if (apiParamKey.match(/__/)) {
            let keyList = apiParamKey.split("__");
            if (!result.hasOwnProperty(keyList[0])) {
                result[keyList[0]] = {};
            }
            result[keyList[0]][keyList[1]] = state[key];
        } else {
            result[apiParamKey] = state[key];
        }
    });
    return result;
};

/*--- スネークケースをキャメルケースにする ---*/
// 引数 p = camel_case
// 返値 文字列(camelCase)
const snakeToCamel = val => {
    //_+小文字を大文字にする(例:_a を A)
    return val.replace(/_./g, s => (s.charAt(1).toUpperCase()));
};
