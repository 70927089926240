import { PageBase } from "components/page/user/contractFrk/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";

class ImpPage13 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     */
    getPageItems = () => {
        return {
            // 宅地造成または建物建築の工事完了時における形状・構造等
            JYU00787: false, // 別紙にて説明します。
            JYU00788: false, // 該当しません。

            // 当該宅地建物 区域
            JYU01039: false, // 石綿使用の有無　有
            JYU01281: false, // 石綿使用の有無　無
            JYU01027: false, // 造成宅地防災区域 内
            JYU01028: false, // 造成宅地防災区域 外
            JYU01730: undefined, // 造成宅地防災区域　フリー
            JYU01029: false, // 土砂災害警戒区域 内
            JYU01030: false, // 土砂災害警戒区域 外
            JYU01731: undefined, // 土砂災害警戒区域　フリー
            JYU01033: false, // 津波災害警戒区域 内
            JYU01034: false, // 津波災害警戒区域 外
            JYU01732: undefined, // 津波災害警戒区域　フリー
            JYU01548: false, // 水防法に基づく…水害ハザードマップ　洪水　有
            JYU01549: false, // 水防法に基づく…水害ハザードマップ　洪水　無
            JYU01550: false, // 水防法に基づく…水害ハザードマップ　雨水出水（内水）　有
            JYU01551: false, // 水防法に基づく…水害ハザードマップ　雨水出水（内水）　無
            JYU01552: false, // 水防法に基づく…水害ハザードマップ　高潮　有
            JYU01553: false, // 水防法に基づく…水害ハザードマップ　高潮　無
            JYU01554: undefined, // 水防法に基づく…水害ハザードマップにおける宅地建物の所在地

            // その他
            JYU01457: undefined, // その他
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            contractTypeId: this.props.contractTypeId,  // 契約種別ID
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 私道に関する負担等に関する事項 */}
                    <li><Block2 {...params} /></li> {/** 当該宅地建物　区域 */}
                    <li><Block3 {...params} /></li> {/** その他 */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
            </React.Fragment>
        );
    }
}

export default withRouter(ImpPage13);