import BaseGrid from "components/common/frame/baseGrid";
import { RegisterAndDeleteButtonRecord, RegisterButtonRecord } from "components/common/parts/buttonRecord";
import { InputTextRecord } from "components/common/parts/inputRecord";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import * as apiParamConverter from "util/apiParamConverter";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";
import Validation from "util/validate";

/** 支店／組織情報メンテナンス画面 */
class GuaranteeMaintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();

        // 画面から入力される値の情報
        this.params = {
            displayId: {
                label: "保証協会ID\n（供託所ID）",
                validate: this.validation.notEmpty,
                apiParamKey: "display_id"
            },
            associationName: {
                label: "保証協会名称\n（供託所名称）",
                validate: this.validation.notEmpty,
                apiParamKey: "association_name"
            },
            associationAddress: {
                label: "保証協会住所\n（供託所所在地）",
                validate: this.validation.notEmpty,
                apiParamKey: "association_address"
            },
            officeName: {
                label: "事務所名称",
                validate: this.validation.notEmpty,
                apiParamKey: "office_name",
            },
            officeAddress: {
                label: "事務所所在地",
                validate: this.validation.notEmpty,
                apiParamKey: "office_address",
            }
        };
    }

    componentDidMount() {
        document.title = pageInfo.GUARANTEE_INFO_MAINTENANCE.title;

        // パスパラメータがある場合、取得APIを呼出し、レスポンスデータを入力ボックスの入力値を更新する
        if (this.props.match.params.id) {
            this.requestGuarantee(this.props.match.params.id);
        }
    }

    componentDidUpdate() {
        // パスパラメータがあり、stateが取得APIのレスポンスデータで更新されていない場合、
        // 取得APIを呼出し、レスポンスデータを入力ボックスの入力値を更新する
        if (this.props.match.params.id && !this.state.id) {
            this.requestGuarantee(this.props.match.params.id);
        }
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputText = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** 入力ボックスの入力値をバリデーション */
    handleValidateInputText = e => {
        const target = e.target;
        const param = this.params[target.name];
        param.validate(target.name, target.value);
    }

    // 登録ボタンのハンドラー
    handleRegisterGuarantee = e => {
        e.preventDefault();

        // 全入力項目のチェックし、エラーメッセージがない場合、後続処理（登録、更新APIコール）をする
        if (!this.validation.validateTotalInputText(this.params, this.state)) {

            const requestBody = apiParamConverter.convertToApiReqCase(this.params, this.state);
            this.registerGuarantee(requestBody);
        } else {
            this.setState({});
        }
    }

    // 削除ボタンのハンドラー
    handleDeleteGuarantee = e => {
        e.preventDefault();
        // 更新モードのみ削除処理
        if (this.props.match.params.id) {
            this.apiCalling = true;
            this.setState({});

            awsReq.del(
                AWS.ENDPOINT.PATHS.GUARANTEE_DETAIL.replace(":id", this.state.id),
                () => { this.props.history.push(pageInfo.GUARANTEE_INFO_LIST.path); },
                this.errorCallback,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }

    render() {
        // パスパラメータが指定されており、stateに値が入っていない場合、nullを返す
        // ※componentDidMount、componentDidUpdateでリクエストを行い、レスポンスが入ってくるのを待つ
        if (this.props.match.params.id && !this.state.id) {
            return null;
        }

        // Inputレコードに関する、タグの属性情報
        const bodySetting = { colSpan: "3" };
        const inputSetting = { onChange: this.handleChangeInputText, onBlur: this.handleValidateInputText };
        const titleSetting = { className: "dataTable-title dataTable-title__w160 div-pre" };

        // ボタンの表示制御
        // 登録モードの場合、登録ボタンのみを表示し、更新モードの場合、登録ボタンと削除ボタンを表示する
        // なお、APIコール中は、非アクティブにする
        const buttonDom = this.props.match.params.id
            ? this.apiCalling
                ? (<RegisterAndDeleteButtonRecord inActive
                    registerSetting={{ onClick: e => { e.preventDefault(); } }}
                    deleteSetting={{ onClick: e => { e.preventDefault(); } }} />)
                : (<RegisterAndDeleteButtonRecord
                    registerSetting={{ onClick: this.handleRegisterGuarantee }}
                    deleteSetting={{ onClick: this.handleDeleteGuarantee }} />)
            : this.apiCalling
                ? (<RegisterButtonRecord inActive registerSetting={{ onClick: e => { e.preventDefault(); } }} />)
                : (<RegisterButtonRecord registerSetting={{ onClick: this.handleRegisterGuarantee }} />);

        return (
            <BaseGrid>
                <div className="dataList dataList__accordion is-open">
                    <div className="dataList-header">保証協会情報メンテナンス　※（）内は供託所の場合</div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <InputTextRecord titleName={this.params.displayId.label}
                                        inputData={{ name: "displayId", value: this.state.displayId || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.displayId || ""} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.associationName.label}
                                        inputData={{ name: "associationName", value: this.state.associationName || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.associationName || ""} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.associationAddress.label}
                                        inputData={{ name: "associationAddress", value: this.state.associationAddress || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.associationAddress || ""} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.officeName.label}
                                        inputData={{ name: "officeName", value: this.state.officeName || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.officeName || ""} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.officeAddress.label}
                                        inputData={{ name: "officeAddress", value: this.state.officeAddress || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.officeAddress || ""} />
                                </tr>
                            </tbody>
                        </table>
                    </div >
                </div >
                <div>※供託所の場合：「事務所名称」「事務所所在地」情報は不要ですが、システムの仕様上、任意テキスト（ハイフン等）をご入力ください。</div >

                {buttonDom}

                < div className="l-pageAction l-pageAction--back" >
                    <Link to={pageInfo.GUARANTEE_INFO_LIST.path} aria-label="link" className="pageAction">一覧へもどる</Link>
                </div >
            </BaseGrid >
        );
    }

    /** 保証協会情報取得APIコール、state更新 */
    requestGuarantee = id => {
        awsReq.get(
            AWS.ENDPOINT.PATHS.GUARANTEE_DETAIL.replace(":id", id),
            response => { this.setState(convert.convertToFlatCamelCase(response.data)); },
            this.errorCallback,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    /** モードに応じて、保証協会情報を登録、更新する */
    registerGuarantee = requestBody => {
        this.apiCalling = true;
        this.setState({});

        if (this.props.match.params.id) {
            // 更新モード
            // 正常なAPIレスポンスが返却された後、再度、取得APIをコールする
            const successCallback = () => {
                this.props.history.push(pageInfo.GUARANTEE_INFO_MAINTENANCE_UPDATE.path.replace(":id", this.props.match.params.id));
                this.setState({ id: undefined });
                this.apiCalling = false;
            };

            awsReq.put(AWS.ENDPOINT.PATHS.GUARANTEE_DETAIL.replace(":id", this.props.match.params.id),
                successCallback,
                this.errorCallback,
                { id: Number(this.props.match.params.id), ...requestBody },
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        } else {
            // 登録モード
            // 正常なAPIレスポンスが返却された後、APIのレスポンスのLocationヘッダ末尾の文字列（ID部分）を取得し、このIDをパスパラメータとして遷移する
            const successCallback = response => {
                const id = response.headers["location"].split("/").slice(-1)[0];
                this.props.history.push(pageInfo.GUARANTEE_INFO_MAINTENANCE_UPDATE.path.replace(":id", id));
                this.apiCalling = false;
            };

            awsReq.post(AWS.ENDPOINT.PATHS.GUARANTEE,
                successCallback,
                this.errorCallback,
                requestBody,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }

    /** APIコール時のエラーコールバック関数 */
    errorCallback = error => {
        alert(ERROR_MESSAGE.SERVER_ERROR);
        this.apiCalling = false;
        this.setState({}); // 再renderする為に、stateを空更新
    };
}

export default withRouter(GuaranteeMaintenance);
