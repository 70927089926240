import axios from "axios";
import ShareList from "components/page/user/contractFrk/edit/popup/popupSetting";
import EmployeeList from "components/page/user/contractFrk/register/popup/popupEmployeeList";
import { AUTHORITY_FRK_MUFG } from "constants/authority";
import { DISPLAY_TYPES, USE_TYPES } from "constants/defaultInputValueTypes";
import { ERROR_MESSAGE } from "constants/errorMessage";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link, Redirect } from "react-router-dom";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { bindActionCreators } from "redux";
import * as loginUserAction from "redux/actions/loginUserAction";
import * as dateUtil from "util/dateUtil";
import Validation from "util/validate";
import * as APP from "../../../../../config/app";
import * as AWS from "../../../../../constants/aws";
import { CONTRACT_EDIT_INFO_FRK, EDIT_AUTHORITY } from "../../../../../constants/contractEditPageInfo";
import { MESSAGES } from "../../../../../constants/messages";
import * as pageInfo from "../../../../../constants/pageInfo";
import { REQUIRED_ITEM_LIST } from "../../../../../constants/requiredItem";
import * as awsReq from "../../../../../util/awsRequest";
import BaseGrid from "../../../../common/frame/baseGrid";
import Page0101 from "./basicInfo/0101/page";
import Page0102 from "./basicInfo/0102/page";
import Page0201 from "./importantItemInstruction/0201/page";
import Page0202 from "./importantItemInstruction/0202/page";
import Page0203 from "./importantItemInstruction/0203/page";
import Page0204 from "./importantItemInstruction/0204/page";
import Page0205 from "./importantItemInstruction/0205/page";
import Page0206 from "./importantItemInstruction/0206/page";
import Page0207 from "./importantItemInstruction/0207/page";
import Page0208 from "./importantItemInstruction/0208/page";
import Page0209 from "./importantItemInstruction/0209/page";
import Page0210 from "./importantItemInstruction/0210/page";
import Page0211 from "./importantItemInstruction/0211/page";
import Page0212 from "./importantItemInstruction/0212/page";
import Page0213 from "./importantItemInstruction/0213/page";
import Page0214 from "./importantItemInstruction/0214/page";
import Page0215 from "./importantItemInstruction/0215/page";
import Page0216 from "./importantItemInstruction/0216/page";
import Page0217 from "./importantItemInstruction/0217/page";
import Page0218 from "./importantItemInstruction/0218/page";
import Page0301 from "./realEstate/0301/page";
import Page0302 from "./realEstate/0302/page";
import Page0303 from "./realEstate/0303/page";
import Page0304 from "./realEstate/0304/page";

/**
 * 契約情報入力の構成
 *
 * ContractEdit
 * ・契約情報入力全体のコンポーネント
 * ・契約情報入力のヘッダ側（保存ボタン等）の制御
 * ・ページ部分のコンポーネントの呼び出し
 * ・契約情報（URLの契約書ID部分）に対応している情報をAPIから取得
 * ・保存ボタン等押下時のAPI通信
 * ・共通機能として実装されている内容は、"doc/契約情報入力共通（ヘッダ等）.todo"を参照すると何をテストしたかが記載されている
 *
 * basicinfo..etc/xxx/page配下
 * ・ページの作り方は "doc/契約情報入力の新規ページ追加手順.js" を参照
 * ・ページ（例：物件／売主／買主＠基本情報）単位のコンポーネント
 * ・ページが保持するべき項目情報（TOR00009等）の定義、保持
 * ・ページに対応している情報をAPIから取得し、見出しコンポーネントに情報を渡す
 * ・ページ内で使用されているポップアップのrenderも行う（popup配下のコンポーネントを使う）
 * ・必要な見出しコンポーネントの呼び出し
 * ・項目情報のみをContractEditへ同期する
 *   ※保存ボタンの管轄がContractEditになっている（マークアップ上こうするしかない）ので、保存APIコール時にページの項目情報を渡すためにContractEditにも同じ情報を持つ必要がある
 *
 * basicinfo..etc/xxx/block配下
 * ・見出し単位のコンポーネント
 * ・ページコンポーネントから渡された項目情報の表示
 * ・入力された値でページコンポーネントが保持している項目情報を更新
 *
 * popup配下
 * ・定型文一覧等ポップアップするコンポーネント
 * ・ポップアップ表示に必要な情報をAPIから取得する
 * ・<PopupTmpleteList
 *       pageState=this.state // ページのstateをそのまま渡す
 *       updatePageState=this.updatePageState // ページのthis.setState()をそのまま渡す
 *       to="TORXXXXX" // ポップアップで選択した値が入る項目名（例：TORXXXXX）を指定
 *       from="moge" // toで指定した項目に設定する値のキー名を指定（現状、ポップポップアップ表示用に叩いたAPIのレスポンスJSONから指定されたキー名の値を取得する）
 *       popupFlagName="dispPopupTmpl" // ページコンポーネント内でポップアップの表示／非表示を管理しているフラグ名を指定
 *   />
 */

class ContractEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: undefined,
            confirmed: undefined, // 契約書個別制御の入力確定情報
            contractId: props.match.params.contractId, // 契約書ID
            pageId: props.match.params.pageId, // 現在開いているページ
            type: props.match.params.pageId.substring(0, 2), // 契約情報入力の型（基本情報 or 重要事項説明書 or 不動産売買契約書）
            updated: false, // 入力の更新があったかどうか（true:有り）

            // 設定機能用
            hide: false, // 見出しを隠す:true、見出しを表示する:false
            popupShareListActive: false, // 設定のポップアップを表示する：true
            popupEmployeeListActive: false, // 従業員一覧のポップアップを表示する：true
            settingData: {   // 契約情報
                contractTypeId: undefined,  // 契約種別ID
                propertyPrefectures: undefined, // 都道府県
                propertyCity: undefined,    // 市区町村
                propertyAddress: undefined, // 番地
                buildingName: undefined,    // 物件名
                documentNo: undefined,  // 稟議書No.
                useClause: 1,   // 利用約款
                headquartersConfirm: false, // 本部確定フラグ
            },
            shareList: undefined, // 設定：共有先の状態（共有先のリスト）
            shareListTmp: [], // 契約情報共有の確定が押下されるまでのテンポラリ用途
        };

        this.confirmedFlg = false; // 契約書個別制御の入力確定情報更新フラグ true:「入力済み」押下時
        this.callingAPIpageHeader = false; // ページのヘッダ部分のAPI取得のAPIコール true:コール中
        this.callingAPInameMaster = false; // 名称マスタのAPIコール true:コール中
        this.callingAPIshareList = false; // 契約書共有権限一覧取得のAPIコール true:コール中

        /** 現在表示しているページの契約情報項目の値 */
        this.contractInfoOnSelectedPage = {};

        /** 変更後の時間経過で保存を促すタイマーのID */
        this.notifySavingTimerId = undefined;

        this.validation = new Validation();
        this.validationRules = {
            [CONTRACT_EDIT_INFO_FRK.PAGE_0101.id]: {
                // 物件名
                TOR00200: { validate: this.validation.notEmpty, },
                // 稟議書No.
                TOR00201: { validate: this.validation.notEmpty, },
            }
        };
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount() {
        document.title = pageInfo.CONTRACT_EDIT_FRK.title;
        // ページのヘッダ部、名称マスタを取得する
        this.init();
    }

    componentDidUpdate = () => {
        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.data === undefined || this.state.selectboxData === undefined || this.state.confirmed === undefined) {
            // ページのヘッダ部、名称マスタを取得する
            this.init();
        }
    }

    componentWillUnmount() {
        window.clearTimeout(this.notifySavingTimerId);
        window.removeEventListener("beforeunload", this.closeGuard);
    }

    /** 離脱防止のイベント（beforeunload）用ハンドラ */
    closeGuard = (e) => {
        // 何か設定しないと離脱防止ダイアログが出ないので空文字列を入れる
        e.returnValue = "";
    }

    /**
     * 有効なページIDかどうかのチェック
     *  true:許容する、false:許容しない
     */
    checkAllowPageId(pageId) {
        // 基本情報用の有効ページID
        const basic = [
            CONTRACT_EDIT_INFO_FRK.PAGE_0101.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0102.id,
        ];
        // 重要事項説明用の有効ページID
        const important = [
            CONTRACT_EDIT_INFO_FRK.PAGE_0201.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0202.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0203.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0204.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0205.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0206.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0207.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0208.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0209.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0210.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0211.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0212.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0213.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0214.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0215.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0216.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0217.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0218.id,
        ];
        // 不動産売買契約書の有効ページID
        const realEstate = [
            CONTRACT_EDIT_INFO_FRK.PAGE_0301.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0302.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0303.id,
            CONTRACT_EDIT_INFO_FRK.PAGE_0304.id,
        ];

        // 許容するID生成
        const allow = [...basic, ...important, ...realEstate];
        return allow.includes(pageId);
    }

    /** ページの切り替えハンドラ */
    handlePageChange = e => {
        e.preventDefault();

        const nextPage = e.target.getAttribute("data-page");
        this.pageSwitch(nextPage);
    }

    /** ページの切り替え */
    pageSwitch = async (nextPageId) => {
        if (this.state.updated) {
            if (window.confirm(MESSAGES.CONTRACT_EDIT.PAGE_CHANGE)) {
                // 保存だけして何もしない
                const result = await this.doSave(res => { return; });
                // エラーが発生した場合はアラートを表示し、ページ遷移しない
                if (result) {
                    window.alert(MESSAGES.CONTRACT_EDIT.ERROR_SAVE);
                    return;
                }
            }
            window.clearTimeout(this.notifySavingTimerId);
        }

        // state更新
        this.setState({
            pageId: nextPageId,               // 現ページID
            type: nextPageId.substring(0, 2), // 契約情報入力の型の更新
            updated: false,                   // ページ更新済みのフラグをfalseにする
        });

        this.upload = undefined; // 画面切替のタイミングでファイルアップロード状況をクリアする

        let url = pageInfo.CONTRACT_EDIT_FRK.path; // /user/contract_frk/:contractId/:pageId/edit
        url = url.replace(":contractId", this.state.contractId);
        url = url.replace(":pageId", nextPageId);
        url = url + "?param=" + dateUtil.tzStrToParm();
        this.props.history.push(url);
    }

    /** 設定ボタンのハンドラ */
    handleShareBtn = e => {
        e.preventDefault();
        this.openPopupShareList();
    }

    /** 契約確定ボタンのハンドラ */
    handleCommit = async e => {
        e.preventDefault();
        if (window.confirm(MESSAGES.CONTRACT_EDIT.COMMIT_1ST)) {
            if (window.confirm(MESSAGES.CONTRACT_EDIT.COMMIT_2ND)) {
                // 保存権限がある場合のみ保存する
                // 「閲覧／印刷のみ」権限の場所長の場合、保存はできないが確定操作は可能
                if (this.state.canSave) {
                    // ※保存に成功しても何もしない（確定の方でリロードをかけるので）
                    const save = await this.doSave(res => { return; });
                    // 保存に失敗している場合、処理中断
                    if (save instanceof Error) { return; }
                }

                // ボタンの死活制御の情報を取得
                const { nowBtnCan, btnDisable } = { ...this.getBtnSetting() };
                this.setState({ ...btnDisable }); // APIのレスポンスがあるまで全部のボタンを押せなくする

                /** 確定用のAPIコール実施 */
                await awsReq.put(
                    AWS.ENDPOINT.PATHS.CONFIRM_CONTRACT,
                    res => { this.setState({ data: undefined, updated: false, confirmed: undefined }); },
                    error => {
                        this.setState({ ...nowBtnCan }); // ボタンの死活の復活
                        alert(ERROR_MESSAGE.SERVER_ERROR);
                    },
                    { id: this.state.contractId },
                    {}, AWS.ENDPOINT.REST_API.NAME,
                );

                // 一時保存のタイマー解除
                window.clearTimeout(this.notifySavingTimerId);
            }
        }
    }

    /** 確定解除ボタンのハンドラ */
    handleRelease = async e => {
        e.preventDefault();
        if (window.confirm(MESSAGES.CONTRACT_EDIT.RELEASE_1ST)) {
            if (window.confirm(MESSAGES.CONTRACT_EDIT.RELEASE_2ND)) {

                // ボタンの死活制御の情報を取得
                const { nowBtnCan, btnDisable } = { ...this.getBtnSetting() };
                this.setState({ ...btnDisable }); // APIのレスポンスがあるまで全部のボタンを押せなくする

                /** 確定解除用のAPIコール実施 */
                await awsReq.put(
                    AWS.ENDPOINT.PATHS.RELEASE_CONTRACT,
                    res => { this.setState({ data: undefined, updated: false, confirmed: undefined }); },
                    error => {
                        this.setState({ ...nowBtnCan }); // ボタンの死活の復活
                        alert(ERROR_MESSAGE.SERVER_ERROR);
                    },
                    { id: this.state.contractId },
                    {}, AWS.ENDPOINT.REST_API.NAME,
                );

                // 一時保存のタイマー解除
                window.clearTimeout(this.notifySavingTimerId);
            }
        }
    }

    /** 削除ボタンのハンドラ */
    handleDelete = async e => {
        e.preventDefault();
        if (window.confirm(MESSAGES.CONTRACT_EDIT.DELETE_1ST)) {
            if (window.confirm(MESSAGES.CONTRACT_EDIT.DELETE_2ND)) {

                // ボタンの死活制御の情報を取得
                const { nowBtnCan, btnDisable } = { ...this.getBtnSetting() };
                this.setState({ ...btnDisable }); // APIのレスポンスがあるまで全部のボタンを押せなくする

                /** 削除用のAPIコール実施 */
                await awsReq.put(
                    AWS.ENDPOINT.PATHS.DELETE_CONTRACT,
                    res => { this.props.history.push(pageInfo.DASHBOARD.path); },
                    error => {
                        this.setState({ ...nowBtnCan }); // ボタンの死活の復活
                        alert(ERROR_MESSAGE.SERVER_ERROR);
                    },
                    { id: this.state.contractId },
                    {}, AWS.ENDPOINT.REST_API.NAME,
                );

                // 一時保存のタイマー解除
                window.clearTimeout(this.notifySavingTimerId);
            }
        }
    }

    /** 保存ボタンのハンドラ */
    handleSave = async e => {
        e.preventDefault();
        /** 保存用のAPIコール実施 */
        this.doSave(res => { this.setState({ data: undefined, updated: false }); });
    }

    /**
     * 保存用のAPIをコールする
     *
     * TODO: 引数に不要なコールバック関数をとっている、戻り値がErrorになっている、など改善したい箇所がある。
     *
     * @param {(res) => void} callbackWhenSuccess 保存に成功した際に実行されるコールバック関数
     * @returns {Promise<Error | undefined>} エラーが発生した場合のみ、Errorを返却する
     */
    doSave = async callbackWhenSuccess => {
        const pageValidationRule = this.validationRules[this.state.pageId];
        if (pageValidationRule) {
            if (this.validation.validateTotalInputText(pageValidationRule, this.contractInfoOnSelectedPage)) {
                this.setState({});
                return new Error("Validation Error");
            }
        }

        // ボタンの死活制御の情報を取得
        const { nowBtnCan, btnDisable } = { ...this.getBtnSetting() };
        this.setState({ ...btnDisable }); // APIのレスポンスがあるまで全部のボタンを押せなくする

        // ファイルアップロードが指定されている場合、ファイルのアップロードを行う
        let preSignedRes = undefined;
        if (this.upload !== undefined) {

            let errorRes = undefined;
            // 地図画像アップロード用の署名付きURLの取得
            await awsReq.get(
                AWS.ENDPOINT.PATHS.GET_CONTRACT_IMAGE_UPLOAD_URL,
                res => { preSignedRes = res.data; },
                error => {
                    errorRes = error;
                    this.setState({ ...nowBtnCan }); // ボタンの死活の復活
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                },
                // { fc: "upload" },
                { contract_id: this.state.contractId, page_id: CONTRACT_EDIT_INFO_FRK.PAGE_0204.id, input_id: "JYU00595" }, {}, AWS.ENDPOINT.REST_API.NAME,
            );
            // errorResが入っている場合、APIコールに失敗しているので処理を継続しない
            if (errorRes) { return errorRes; }

            // 地図画像アップロード用の署名付きURLへ地図ファイルのアップロード
            try {
                await axios({
                    method: "PUT",
                    url: preSignedRes["pre_signed_url"],
                    headers: {
                        "Content-Type": "image/*", // アップロードしたいファイルに合わせる
                    },
                    data: this.upload.data
                });
            } catch (error) {
                // エラーの場合処理を継続しない
                this.setState({ ...nowBtnCan }); // ボタンの死活の復活
                alert(ERROR_MESSAGE.SERVER_ERROR);
                return error;
            }

            // ファイル名を入力情報として保存する
            this.contractInfoOnSelectedPage[this.upload.itemId] = this.upload.name;
        }

        // 確定情報は、印刷時の更新リクエストを回避する
        if (this.state.data.status === "02") {
            this.setState({ ...nowBtnCan }); // ボタンの死活の復活
            return;
        }

        // ページボタンの色を更新する
        let updateDate = this.state.confirmed;
        if (this.state.confirmedFlg) {
            updateDate = updateConfirmed(this.state.confirmed, this.state.pageId);
            this.state.confirmedFlg = false;
        }

        // 保存用のリクエスト準備
        const request = {
            contract_id: this.state.contractId,
            page_id: this.state.pageId,
            input_content: this.contractInfoOnSelectedPage,
            input_confirmed: updateDate,
            // ファイルのアップロードがある場合のみリクエストに追加
            input_image: preSignedRes ? { [this.upload.itemId]: preSignedRes.version } : this.contractInfoInputImage,
        };

        // ⑵建築基準法に基づく制限のデフォルト表記(入力がない場合は全角ブランクをDBに登録)
        if (request.input_content["JYU00459"] === "") {
            request.input_content["JYU00459"] = "　";
        }
        if (request.input_content["JYU00468"] === "") {
            request.input_content["JYU00468"] = "　";
        }
        if (request.input_content["JYU00483"] === "") {
            request.input_content["JYU00483"] = "　";
        }
        if (request.input_content["JYU00485"] === "") {
            request.input_content["JYU00485"] = "　";
        }

        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0101.id) {
            if (request.input_content["TOR00072_ARR"] !== undefined) {
                request.input_content["TOR00072_ARR"] = request.input_content["TOR00072_ARR"].map(element => {
                    const [name, honorific] = this.convertNameHonorific(element["TOR00072"], element["TOR00332"]);
                    return {
                        "TOR00072": name,
                        "TOR00332": honorific
                    };
                });
            }
            if (request.input_content["TOR00094_ARR"] !== undefined) {
                request.input_content["TOR00094_ARR"] = request.input_content["TOR00094_ARR"].map(element => {
                    const [name, honorific] = this.convertNameHonorific(element["TOR00094"], element["TOR00334"]);
                    return {
                        "TOR00094": name,
                        "TOR00334": honorific
                    };
                });
            }
        }

        // 0204ページで、空白だったらハイフンを印字する対応
        const HYPHEN_TEXT_0204 = "------";
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0204.id) {
            if (request.input_content["JYU00396"] === undefined || request.input_content["JYU00396"] === "") {
                request.input_content["JYU00396"] = HYPHEN_TEXT_0204;
            }

            if (request.input_content["JYU00398"] === undefined || request.input_content["JYU00398"] === "") {
                request.input_content["JYU00398"] = HYPHEN_TEXT_0204;
            }

            if (request.input_content["JYU00400"] === undefined || request.input_content["JYU00400"] === "") {
                request.input_content["JYU00400"] = HYPHEN_TEXT_0204;
            }

            if (request.input_content["JYU00402"] === undefined || request.input_content["JYU00402"] === "") {
                request.input_content["JYU00402"] = HYPHEN_TEXT_0204;
            }
        }

        // 0102ページで、空白だったらハイフンを印字する対応
        const HYPHEN_TEXT_0102_08 = "--------";
        const HYPHEN_TEXT_0102_12 = "------------";
        const HYPHEN_TEXT_0102_20 = "--------------------";
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0102.id) {
            if (request.input_content["TOR00340"] === undefined || request.input_content["TOR00340"] === "") {
                request.input_content["TOR00340"] = HYPHEN_TEXT_0102_08;
            }

            if (request.input_content["TOR00341"] === undefined || request.input_content["TOR00341"] === "") {
                request.input_content["TOR00341"] = HYPHEN_TEXT_0102_12;
            }

            if (request.input_content["TOR00342"] === undefined || request.input_content["TOR00342"] === "") {
                request.input_content["TOR00342"] = HYPHEN_TEXT_0102_08;
            }

            if (request.input_content["TOR00343"] === undefined || request.input_content["TOR00343"] === "") {
                request.input_content["TOR00343"] = HYPHEN_TEXT_0102_12;
            }

            if (request.input_content["TOR00344"] === undefined || request.input_content["TOR00344"] === "") {
                request.input_content["TOR00344"] = HYPHEN_TEXT_0102_08;
            }

            if (request.input_content["TOR00345"] === undefined || request.input_content["TOR00345"] === "") {
                request.input_content["TOR00345"] = HYPHEN_TEXT_0102_12;
            }

            if (request.input_content["TOR00346"] === undefined || request.input_content["TOR00346"] === "") {
                request.input_content["TOR00346"] = HYPHEN_TEXT_0102_08;
            }

            if (request.input_content["TOR00031"] === undefined || request.input_content["TOR00031"] === "") {
                request.input_content["TOR00031"] = HYPHEN_TEXT_0102_20;
            }

            if (request.input_content["TOR00032"] === undefined || request.input_content["TOR00032"] === "") {
                request.input_content["TOR00032"] = HYPHEN_TEXT_0102_12;
            }

            if (request.input_content["TOR00336"] === undefined || request.input_content["TOR00336"] === "") {
                request.input_content["TOR00336"] = HYPHEN_TEXT_0102_08;
            }

            if (request.input_content["TOR00268"] === undefined || request.input_content["TOR00268"] === "") {
                request.input_content["TOR00268"] = HYPHEN_TEXT_0102_20;
            }

            if (request.input_content["TOR00269"] === undefined || request.input_content["TOR00269"] === "") {
                request.input_content["TOR00269"] = HYPHEN_TEXT_0102_12;
            }

            if (request.input_content["TOR00337"] === undefined || request.input_content["TOR00337"] === "") {
                request.input_content["TOR00337"] = HYPHEN_TEXT_0102_08;
            }

            if (request.input_content["TOR00271"] === undefined || request.input_content["TOR00271"] === "") {
                request.input_content["TOR00271"] = HYPHEN_TEXT_0102_20;
            }

            if (request.input_content["TOR00272"] === undefined || request.input_content["TOR00272"] === "") {
                request.input_content["TOR00272"] = HYPHEN_TEXT_0102_12;
            }

            if (request.input_content["TOR00338"] === undefined || request.input_content["TOR00338"] === "") {
                request.input_content["TOR00338"] = HYPHEN_TEXT_0102_08;
            }
        }

        // 0101ページで、空白だったらハイフンを印字する対応
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0101.id) {
            request.input_content["TOR00355"] = !(request.input_content["TOR00316"] || request.input_content["TOR00320"]);
        }

        // 0218ページで、備考（続き）が空白の場合、斜線を表示する
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0218.id) {
            request.input_content["JYU01777"] = (request.input_content["JYU01776"] === undefined || request.input_content["JYU01776"] === "");
        }

        // 契約書入力情報更新リクエストの実行
        let errorRes = undefined;
        await awsReq.put(
            AWS.ENDPOINT.PATHS.CONTRACTS_PAGE_FRK
                .replace(":contractId", this.state.contractId)
                .replace(":pageId", this.state.pageId),
            callbackWhenSuccess,
            error => {
                this.setState({ ...nowBtnCan }); // ボタンの死活の復活
                alert(ERROR_MESSAGE.SERVER_ERROR);
                errorRes = error;
            },
            request,
            {}, AWS.ENDPOINT.REST_API.NAME,
        );
        if (errorRes) { return errorRes; }

        // 一時保存のタイマー解除
        window.clearTimeout(this.notifySavingTimerId);

        this.setState({ ...nowBtnCan }); // ボタンの死活の復活
    }

    /**
     * DB保存用の名前と敬称に変換する.
     * 
     * 名前と敬称のいずれかのみに値が入っている場合、もう一方には全角スペースを設定する
     * 空文字やNullの場合、帳票が正しく表示されなくなってしまうため
     * 
     * @param {string | undefined} name
     * @param {string | undefined} honorific
     * @returns {[string | undefined, string | undefined]} [名前, 敬称]
     */
    convertNameHonorific = (name, honorific) => {
        if (!name && honorific) {
            return ["　", honorific];
        }
        if (name && !honorific) {
            return [name, "　"];
        }
        return [name, honorific];
    };

    /** 入力済みボタンのハンドラ */
    handleConfirm = async e => {
        e.preventDefault();

        /** 保存用のAPIコール実施 */
        this.state.confirmedFlg = true;
        this.doSave(res => { this.setState({ data: undefined, updated: false, confirmed: undefined }); });
    }

    /** 現在ページ印刷ボタン */
    handlePreview = async e => {
        e.preventDefault();

        const previewURL = pageInfo.CONTRACT_PREVIEW_FRK.path
            .replace(":contractId", this.state.contractId)
            .replace(":tabId", this.state.pageId.substring(0, 2))
            .replace(":pageId", this.state.pageId);

        // 保存権限がない場合、保存しないでプレビュー画面へ
        if (!this.state.canSave) {
            window.open(previewURL);
            return;
        }

        this.doSave(res => { this.setState({ data: undefined, updated: false }); });
        window.open(previewURL);
    }

    /** 全ページ印刷ボタン */
    handleAllPreview = async e => {
        e.preventDefault();

        const previewURL = pageInfo.CONTRACT_PREVIEW_FRK.path
            .replace(":contractId", this.state.contractId)
            .replace(":tabId", this.state.pageId.substring(0, 2))
            .replace(":pageId", "9999");

        // 保存権限がない場合、保存しないでプレビュー画面へ
        if (!this.state.canSave) {
            window.open(previewURL);
            return;
        }

        this.doSave(res => { this.setState({ data: undefined, updated: false }); });
        window.open(previewURL);
    }

    /** 変更後一定時間経過後の保存通知のハンドラ */
    handleNotifySave = async () => {
        if (window.confirm(MESSAGES.CONTRACT_EDIT.NOTIFY_SAVE)) {
            // 保存用のAPIコール実施
            const result = await this.doSave(res => { this.setState({ data: undefined, updated: false }); });
            // エラーが発生した場合はアラートを表示する
            if (result) {
                window.alert(MESSAGES.CONTRACT_EDIT.ERROR_SAVE);
                return;
            }
        }
    }

    /**
     * ページコンポーネントからページの持っている契約情報を同期させる
     * ※保存ボタンがこのコンポーネントにあり、ページコンポーネントから保存を実行できないのでページコンポーネントの値を吸い上げてこっちで処理する
     **/
    syncContractInfo = (val, image = {}) => {
        this.contractInfoOnSelectedPage = val;
        this.contractInfoInputImage = image;
    }

    /**
     * ページコンポーネントからアップロード対象の情報を同期させる
     * valは { pageId: "0204", itemId: "JYU00595", data: blob } の形式とすること
     **/
    syncUpload = val => {
        this.upload = val;
    }

    /** 表示対象になるページのDOMを取得する */
    dispPage = () => {
        let nowPage = null;

        //ページコンポーネントに渡す表示区分(テキストエリアの文字数判定用)を生成
        //デフォルト：土地
        let displayType = DISPLAY_TYPES.LAND.value;

        if (this.state.data.contract_type_name.indexOf("土地建物") !== -1 || this.state.data.contract_type_name.indexOf("借地権付建物") !== -1) {
            displayType = DISPLAY_TYPES.LAND_AND_BUILDING.value;
        }

        if (this.state.data.contract_type_name.indexOf("区分所有") !== -1) {
            displayType = DISPLAY_TYPES.SEGMENT_BUILDING.value;
        }

        //ページコンポーネントに渡す利用区分を生成
        //デフォルト：一般
        let useType = USE_TYPES.GENERAL.value;

        if (this.state.data.contract_type_name.indexOf("消費者契約") !== -1) {
            useType = USE_TYPES.CONSUMER_AGREEMENT.value;
        }

        if (this.state.data.contract_type_name.indexOf("売主宅建業者") !== -1) {
            useType = USE_TYPES.SELLER_HOME_BUILDER.value;
        }

        // ページコンポーネントに渡すパラメータ群
        const params = {
            pageSwitch: this.pageSwitch, // ページ切替用メソッド
            pageUpdated: this.pageUpdated, // 更新状態更新用メソッド
            sync: this.syncContractInfo, // 契約情報項目値同期用メソッド
            syncUpload: this.syncUpload, // ファイルアップロード対象の同期用メソッド
            taxRoundingMethod: this.state.data.tax_rounding_method, // 消費税端数処理（01: 切り捨て, 02: 切り上げ, 03: 四捨五入）
            digitsId: this.state.data.digits_id, // 消費税端数処理桁数（01: 小数点以下を計算, 02: 1円の桁を計算）
            selectboxData: this.state.selectboxData, // 名称マスタの値が入る
            contractTypeId: this.state.data.contract_type_id, //契約種別ID
            contractType: this.state.data.contract_type_name_frk, //契約種別名
            displayTypeID: displayType, //表示区分ID
            useTypeID: useType // 利用区分（01: 一般, 02: 消費者契約, 03: 売主宅建業者)
        };

        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0101.id) { nowPage = <Page0101 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0102.id) { nowPage = <Page0102 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0201.id) { nowPage = <Page0201 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0202.id) { nowPage = <Page0202 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0203.id) { nowPage = <Page0203 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0204.id) { nowPage = <Page0204 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0205.id) { nowPage = <Page0205 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0206.id) { nowPage = <Page0206 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0207.id) { nowPage = <Page0207 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0208.id) { nowPage = <Page0208 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0209.id) { nowPage = <Page0209 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0210.id) { nowPage = <Page0210 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0211.id) { nowPage = <Page0211 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0212.id) { nowPage = <Page0212 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0213.id) { nowPage = <Page0213 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0214.id) { nowPage = <Page0214 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0215.id) { nowPage = <Page0215 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0216.id) { nowPage = <Page0216 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0217.id) { nowPage = <Page0217 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0218.id) { nowPage = <Page0218 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0301.id) { nowPage = <Page0301 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0302.id) { nowPage = <Page0302 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0303.id) { nowPage = <Page0303 {...params} />; }
        if (this.state.pageId === CONTRACT_EDIT_INFO_FRK.PAGE_0304.id) { nowPage = <Page0304 {...params} />; }
        return nowPage;
    }

    /** ページが更新された場合の状態管理する */
    pageUpdated = (bool) => {

        // 保存権限がない場合又は契約情報が確定の場合、何もしない
        if ((!this.state.canSave) || (this.state.data.status === "02")) {
            return;
        }

        // 更新状態にする場合、離脱ガードを入れる
        // 未更新状態にする場合、離脱ガードを外す
        if (bool) {
            window.addEventListener("beforeunload", this.closeGuard);
            window.clearTimeout(this.notifySavingTimerId);

            // 更新後一定期間経過で保存催促する機能のタイムアウト設定
            this.notifySavingTimerId = window.setTimeout(this.handleNotifySave, APP.NOFITY_SAVING_INTERVAL);
        } else {
            window.removeEventListener("beforeunload", this.closeGuard);
        }
        this.setState({ updated: bool });
    }

    /** アクティブページの判定／クラス付与 */
    addActive = type => {
        const now = this.state.type;
        return now === type ? "is-active" : "";
    }

    /** 基本情報ページを開いている時に基本情報タブをアクティブにするクラスを返す */
    addActiveWhenBasic = () => {
        return this.addActive(CONTRACT_EDIT_INFO_FRK.TYPE.BASIC);
    }

    /** 重要事項説明書ページを開いている時に基本情報タブをアクティブにするクラスを返す */
    addActiveWhenImportant = () => {
        return this.addActive(CONTRACT_EDIT_INFO_FRK.TYPE.IMPORTANT);
    }

    /** 不動産売買契約書ページを開いている時に基本情報タブをアクティブにするクラスを返す */
    addActiveWhenContract = () => {
        return this.addActive(CONTRACT_EDIT_INFO_FRK.TYPE.ESTATE);
    }

    /**
     * 各種ボタンの操作可否の取得
     *
     * @param {string} userAuthority ログインユーザの権限ID
     * @param {string} sharingAuthority 対象となる契約書の共有権限ID
     * @return 各種ボタンの操作可否
     */
    getButtonActivity = (userAuthority, sharingAuthority) => {
        // 契約審査部は全て可能
        if (userAuthority === AUTHORITY_FRK_MUFG.CONTRACT_EXAMINATION.value) {
            return { canSave: true, canShare: true, canCommit: true, canRelease: true, canDelete: true, canConfirm: true };
        }

        // 契約審査部以外の確定権限を持つユーザ（すなわち、場所長）
        if (userAuthority === AUTHORITY_FRK_MUFG.AREA_MANAGER.value) {
            if (sharingAuthority === EDIT_AUTHORITY.READ_ONLY) {
                // 「閲覧／印刷のみ」権限の場合でも、確定/確定解除操作は可能
                return { canSave: false, canShare: false, canCommit: true, canRelease: true, canDelete: false, canConfirm: false };
            }
            return { canSave: true, canShare: true, canCommit: true, canRelease: true, canDelete: true, canConfirm: true };
        }

        // 一般および課長権限の場合
        // 「閲覧／印刷のみ」権限の場合は全て不可
        if (sharingAuthority === EDIT_AUTHORITY.READ_ONLY) {
            return { canSave: false, canShare: false, canCommit: false, canRelease: false, canDelete: false, canConfirm: false };
        }

        // 「担当営業」または「編集可能」権限の場合、確定/確定解除操作は不可
        return { canSave: true, canShare: true, canCommit: false, canRelease: false, canDelete: true, canConfirm: true };
    }

    /**
     * 契約情報表示の権限確認
     * true: 権限あり、false: 権限なし
     */
    checkAllowEditContract = auth => {
        // 01: 担当営業, 02: 編集可能, 03: 閲覧／印刷のみ
        const allow = [
            EDIT_AUTHORITY.EIGYO,
            EDIT_AUTHORITY.CAN_EDIT,
            EDIT_AUTHORITY.READ_ONLY,
        ];
        return allow.includes(auth);
    }

    /** 活性制御された状態のボタンのDOMを返す */
    getActivityControlledButtonParams = () => {
        // 全ページ印刷ボタンの表示制御
        const allPreview = (this.state.type === "01") ?
            { className: "nodisplay" }
            : { className: "baseAction baseAction--print baseAction--blue", onClick: this.handleAllPreview, };

        // 現ページ印刷ボタンの表示制御
        const pagePreview = !(this.state.type === "02") ?
            { className: "nodisplay" }
            : { className: "baseAction baseAction--print", onClick: this.handlePreview, };

        // 保存ボタンの活性制御
        let saveButton = undefined;
        if (this.state.data.status === "01") {
            saveButton = this.state.canSave ?
                { className: `baseAction baseAction--emphasis ${this.state.updated ? "is-active" : ""}`, onClick: this.handleSave, }
                : { className: "baseAction baseAction--primary baseAction--inactive", onClick: e => { e.preventDefault(); }, };
        } else {
            saveButton = { className: "nodisplay" };
        }

        // 設定ボタンの活性制御
        let shareButton = undefined;
        if (this.state.data.status === "01") {
            shareButton = this.state.canShare ?
                { className: "baseAction", onClick: this.handleShareBtn, }
                : { className: "baseAction baseAction--inactive", onClick: e => { e.preventDefault(); }, };
        } else {
            shareButton = { className: "nodisplay" };
        }

        // 確定ボタンの活性制御
        let commitButton = undefined;
        if (this.state.data.status === "02") {
            // 確定していたら確定ボタン非表示
            commitButton = { className: "nodisplay" };
        } else {
            if (this.state.data.headquarters_confirm) {
                // 本部確定フラグがtrueの場合、契約審査部のみ確定が可能
                commitButton = this.props.authority === AUTHORITY_FRK_MUFG.CONTRACT_EXAMINATION.value
                    ? { className: "baseAction", onClick: this.handleCommit, }
                    : { className: "nodisplay" };
            } else {
                // 本部確定フラグがtrueの場合、場所長のみ確定が可能
                commitButton = this.props.authority === AUTHORITY_FRK_MUFG.AREA_MANAGER.value
                    ? { className: "baseAction", onClick: this.handleCommit, }
                    : { className: "nodisplay" };
            }
        }

        // 確定解除ボタンの活性制御
        let releaseButton = undefined;
        if (this.state.data.status === "01") {
            // 作成中は確定解除ボタン非表示
            releaseButton = { className: "nodisplay" };
        } else {
            if (this.state.data.headquarters_confirm) {
                // 本部確定フラグがtrueの場合、契約審査部のみ確定解除が可能
                releaseButton = this.props.authority === AUTHORITY_FRK_MUFG.CONTRACT_EXAMINATION.value
                    ? { className: "baseAction", onClick: this.handleRelease }
                    : { className: "nodisplay" };
            } else {
                // 本部確定フラグがfalseの場合、場所長のみ確定解除が可能
                releaseButton = this.props.authority === AUTHORITY_FRK_MUFG.AREA_MANAGER.value
                    ? { className: "baseAction", onClick: this.handleRelease, }
                    : { className: "nodisplay" };
            }
        }

        // 削除ボタンの活性制御
        let deleteButton = undefined;
        if (this.state.data.status === "01") {
            deleteButton = this.state.canDelete ?
                { className: "baseAction", onClick: this.handleDelete, }
                : { className: "baseAction baseAction--inactive", onClick: e => { e.preventDefault(); }, };
        } else {
            deleteButton = { className: "nodisplay" };
        }

        // 入力済みボタンの活性制御
        let confirmButton = undefined;
        if (this.state.data.status === "01") {
            confirmButton = this.state.canConfirm ?
                { className: "baseAction", onClick: this.handleConfirm, }
                : { className: "baseAction baseAction--inactive", onClick: e => { e.preventDefault(); }, };
        } else {
            confirmButton = { className: "nodisplay" };
        }

        return {
            allPreview: allPreview,
            preview: pagePreview,
            save: saveButton,
            share: shareButton,
            commit: commitButton,
            delete: deleteButton,
            confirm: confirmButton,
            release: releaseButton,
        };
    }

    init = () => {
        // データ未設定時にはAPIコールし、データ（ページのヘッダ部、名称マスタを取得する）を取得する
        // ※取得が完了するまで白い画面を出す
        if (this.state.data === undefined) {
            // 新しく契約情報を読み込む際には beforeunload を削除する
            window.removeEventListener("beforeunload", this.closeGuard);

            // 名称マスタのAPIコールが終わってsetStateした後に実行されるcomponentDidUpdate()処理の一環として
            // init()が実行される際に、APIコールの条件を"this.state.data === undefined"だけを条件にしていると、
            // 2発目のAPIコールのリクエストを飛ばしてしまう可能性※があるので、
            // ※名称の取得が終わった時点でヘッダ部分の情報取得が終わっていない
            // APIの実行結果が返って来ているかどうかをフラグ管理して2発目を防ぐ
            if (!this.callingAPIpageHeader) {
                // ヘッダ部分の情報取得
                this.callingAPIpageHeader = true;
                awsReq.get(
                    AWS.ENDPOINT.PATHS.CONTRACTS_CONTRACT.replace(":contractId", this.props.match.params.contractId),
                    this.organizeForDisplay,
                    error => {
                        // ここが死んでいると何もできないので意図的にこれ以降、APIコール出来ないようにする（リロード推奨）
                        this.callingAPIpageHeader = true;
                        alert(ERROR_MESSAGE.SERVER_ERROR);
                    },
                    {}, {}, AWS.ENDPOINT.REST_API.NAME,
                );
            }
        }

        // 名称マスタの読込
        if (this.state.selectboxData === undefined) {

            if (!this.callingAPInameMaster) {
                // ヘッダ部分の情報取得
                this.callingAPInameMaster = true;
                awsReq.get(
                    AWS.ENDPOINT.PATHS.GET_SELECT_BOX_INPUT_ITEM_LIST,
                    this.settingSelectboxData,
                    error => {
                        this.callingAPInameMaster = false;
                        alert(ERROR_MESSAGE.SERVER_ERROR);
                    },
                    {}, {}, AWS.ENDPOINT.REST_API.NAME,
                );
            }
        }

        if (this.state.shareList === undefined) {
            if (!this.callingAPIshareList) {
                // 契約書共有権限一覧取得
                this.callingAPIshareList = true;
                awsReq.post(
                    AWS.ENDPOINT.PATHS.GET_CONTRACT_SHARING_AUTHORITY_LIST,
                    this.settingShareListData,
                    error => {
                        this.callingAPIshareList = false;
                        //対象を更新する権限がありません。のエラー暫定対応
                        // alert(ERROR_MESSAGE.SERVER_ERROR);
                    },
                    { contract_id: this.props.match.params.contractId, },
                    {}, AWS.ENDPOINT.REST_API.NAME,
                );
            }
        }

        // 契約書個別制御の入力確定情報を取得する
        if (this.state.confirmed === undefined) {
            this.getContractIndividualControl();
        }
    }

    /** 画面表示用の情報を整理する */
    organizeForDisplay = res => {
        this.callingAPIpageHeader = false;
        const responseBody = res.data;
        // 権限なし、不明な権限の場合、ダッシュボード画面へ遷移させる
        if (!this.checkAllowEditContract(responseBody.authority) && !this.props.admin) {
            this.props.history.push(pageInfo.DASHBOARD.path);
        } else {
            // 権限が正常な場合、画面を更新
            // ※ボタンの活性制御の取得も行う
            const getButtonActivity = this.getButtonActivity(this.props.authority, responseBody.authority);
            // 元号マスタを格納する
            eraMaster = responseBody.eras;

            // 契約情報のテンポラリ用途を格納する
            const tempSetting = this.state.settingData;
            tempSetting.contractTypeId = responseBody.contract_type_id;
            tempSetting.propertyPrefectures = responseBody.property_prefectures;
            tempSetting.propertyCity = responseBody.property_city;
            tempSetting.propertyAddress = responseBody.property_address;
            tempSetting.buildingName = responseBody.building_name_02;
            tempSetting.documentNo = responseBody.document_no;
            tempSetting.useClause = parseInt(responseBody.use_clause);
            tempSetting.headquartersConfirm = responseBody.headquarters_confirm;

            this.setState({
                data: responseBody, settingData: tempSetting, ...getButtonActivity
            });

            // URLを更新する
            this.props.history.push(
                pageInfo.CONTRACT_EDIT_FRK.path
                    .replace(":contractId", this.state.contractId)
                    .replace(":pageId", this.state.pageId)
                + "?param=" + dateUtil.tzStrToParm()
            );
        }
    }

    /** 名称一覧取得APIから取得した値をstateに設定する */
    settingSelectboxData = res => {
        this.callingAPInameMaster = false;
        this.setState({ selectboxData: res.data });
    }

    /** 契約書共有権限一覧取得APIから取得した値をstateに設定する */
    settingShareListData = res => {
        this.callingAPIshareList = false;
        this.setState({
            shareList: res.data,
            shareListTmp: res.data,
        });
    }

    /** ポップアップ表示中に背景を暗くする用 */
    createModelOverlayActiveDOM = () => {
        if (this.state.popupShareListActive ||
            this.state.popupEmployeeListActive) {
            return (
                <div className="l-modal_overlay is-active">
                    <div className="modal_overlay"></div>
                </div>
            );
        }
        return null;
    }

    /** 契約書個別制御を取得する */
    getContractIndividualControl = async () => {
        // 契約書個別制御が未設定の場合のみAPIを呼び出す。
        await awsReq.get(
            AWS.ENDPOINT.PATHS.GET_CONTRACT_INDIVIDUAL_CONTROL.replace(":contractId", this.props.match.params.contractId),
            this.setContractIndividualControl,
            error => {
                alert(ERROR_MESSAGE.SERVER_ERROR);
            },
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    /** 契約書個別制御の入力確定情報を設定する */
    setContractIndividualControl = res => {
        const response = res.data;
        this.setState({ confirmed: response.input_confirmed });
    }

    /** 設定のポップアップを開く */
    openPopupShareList = e => {
        this.setState({ popupShareListActive: true });
    }

    /** 設定のポップアップを閉じる */
    closePopupShareList = () => {
        this.setState({ popupShareListActive: false });
    }

    /** 従業員一覧のポップアップを開く */
    openPopupEmployeeList = () => {
        this.setState({ popupEmployeeListActive: true });
    }

    /** ボタンの押せるか、押せないかの現状の取得 */
    getBtnSetting = () => {
        return {
            // ボタンの有効無効を保持しておく
            nowBtnCan: {
                canSave: this.state.canSave, canShare: this.state.canShare,
                canCommit: this.state.canCommit, canDelete: this.state.canDelete, canConfirm: this.state.canConfirm,
            },
            // ボタンを全部無効化する
            btnDisable: { canSave: false, canShare: false, canCommit: false, canDelete: false, canConfirm: false, },
        };
    }

    /**
     * 設定の更新
     * ※契約書共有権限更新APIをコールして、一回リロードする
     */
    updateSettingInfo = async (settingTmp, shareListTmp) => {

        // ボタンの死活制御の情報を取得
        const { nowBtnCan, btnDisable } = { ...this.getBtnSetting() };
        this.setState({ ...btnDisable }); // APIのレスポンスがあるまで全部のボタンを押せなくする

        // 設定：契約書情報を更新する
        await awsReq.post(
            AWS.ENDPOINT.PATHS.UPDATE_CONTRACTS_FRK,
            res => { },
            error => {
                this.setState({ ...nowBtnCan }); // ボタンの死活の復活
                alert(ERROR_MESSAGE.SERVER_ERROR);
            },
            {
                contract_id: this.state.contractId,
                contract_type_id: settingTmp.contractTypeId,
                property_prefectures: settingTmp.propertyPrefectures,
                property_city: settingTmp.propertyCity,
                property_address: settingTmp.propertyAddress,
                building_name: settingTmp.buildingName,
                document_no: settingTmp.documentNo,
                use_clause: settingTmp.useClause,
                headquarters_confirm: settingTmp.headquartersConfirm,
                required_item: REQUIRED_ITEM_LIST[settingTmp.contractTypeId],
            },
            {}, AWS.ENDPOINT.REST_API.NAME,
        );

        // 設定：契約書共有権限を更新する
        // 契約書共有権限の更新用リクエストボディ準備
        const reqList = shareListTmp.map(item => {
            return {
                agency_user_id: item.agency_user_id,
                sharing_authority_id: item.sharing_authority_id,
            };
        });

        await awsReq.post(
            AWS.ENDPOINT.PATHS.UPDATE_CONTRACT_SHARING_AUTHORITY,
            res => { },
            error => {
                this.setState({ ...nowBtnCan }); // ボタンの死活の復活
                alert(ERROR_MESSAGE.SERVER_ERROR);
            },
            {
                contract_id: this.state.contractId,
                contract_sharing_authorities: reqList,
            },
            {}, AWS.ENDPOINT.REST_API.NAME,
        );

        // 一時保存のタイマー解除
        window.clearTimeout(this.notifySavingTimerId);

        // 入力情報をクリア
        this.setState({ data: undefined, updated: false, confirmed: undefined, shareList: undefined });
        this.setState({ ...nowBtnCan }); // ボタンの死活の復活
    }

    /** 設定：共有先（テンポラリ）の更新 */
    updateShareListTmp = arrays => {
        const sorted = JSON.parse(JSON.stringify(arrays));
        sorted.sort((a, b) => {
            if (a.agency_user_id > b.agency_user_id) {
                return 1;
            }
            if (a.agency_user_id < b.agency_user_id) {
                return -1;
            }
            return 0;
        });
        this.setState({ shareListTmp: sorted });
    }

    /** 従業員一覧のポップアップを閉じる */
    closePopupEmployeeList = () => {
        this.setState({ popupEmployeeListActive: false });
    }

    /** 見出しの▼を押した際に隠すor表示する */
    handleHide = e => {
        e.preventDefault();
        this.setState({ hide: !this.state.hide });
    }

    render() {
        // URL中のページID指定が異常なページIDの場合、ダッシュボード画面へ遷移させる
        if (!this.checkAllowPageId(this.state.pageId)) {
            return <Redirect to={`${pageInfo.DASHBOARD.path}`} />;
        }

        // ブラウザバック対策
        // ブラウザのURLとコンポーネントが格納しているIDに差分がある場合、ブラウザのURLの内容で更新をかける
        // ※実際の更新処理はcomponentDidUpdate()内
        if (this.state.pageId !== this.props.match.params.pageId) {
            return null;
        }

        // データ未設定時、名称一覧未取得時前の場合、取得が完了するまで白い画面を出す
        if (this.state.data === undefined || this.state.selectboxData === undefined) {
            return null;
        }

        // 活性制御されたボタンのDOM取得
        const controlledBtnParams = this.getActivityControlledButtonParams();

        // ボディー部の上部の作成
        const DOMFormatNameLine = this.BodyTopFormatNameLine(controlledBtnParams);
        const DOMUpDateInfoLine = this.BodyTopUpDateInfoLine();
        const DOMContractInfoLine = this.BodyTopContractInfoLine(controlledBtnParams);
        const DOMScreenSwitchingLine = this.BodyTopScreenSwitchingLine();

        // アコーディオンの設定
        const hideBlockClass = renderCommon(this.state);

        return (
            <BaseGrid noBody>

                {/* 契約書情報用DOM */}
                <div className="l-baseGrid-main-top">
                    <div className="mainTopCard-top">
                        <div className={`mainTopCard-top__accordion ${hideBlockClass}`}>
                            <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                            {DOMFormatNameLine}
                            {DOMUpDateInfoLine}
                        </div>
                        {DOMContractInfoLine}
                        {DOMScreenSwitchingLine}
                    </div>
                </div>

                {/* 契約書入力情報（各ページ毎）用DOM */}
                <div className="l-baseGrid-main-body">
                    {this.dispPage()}
                </div>

                {/* ポップアップ表示中に背景を暗くする用DOM */}
                {this.createModelOverlayActiveDOM()}

                {/* 設定ポップアップ */}
                <ShareList
                    settingData={this.state.settingData}
                    shareList={this.state.shareList}
                    shareListTmp={this.state.shareListTmp}
                    active={this.state.popupShareListActive}
                    onClose={this.closePopupShareList}
                    onAdd={this.openPopupEmployeeList}
                    onCommit={this.updateSettingInfo}
                    updateShareListTmp={this.updateShareListTmp}
                    approvalAuthority={isApprovalAuthority(this.props.authority, this.state.settingData.headquartersConfirm)}
                />

                {/* 従業員一覧ポップアップ */}
                <EmployeeList
                    shareList={this.state.shareListTmp} // テンポラリの方の情報を参照する
                    active={this.state.popupEmployeeListActive}
                    onClose={this.closePopupEmployeeList}
                    onCommit={this.updateShareListTmp}  // 確定押下時にテンポラリの方の情報を更新する
                />
            </BaseGrid >
        );
    }

    /**
     * ボディー部の上部（書式名及び削除ボタン等）
     */
    BodyTopFormatNameLine(controlledBtnParams) {

        return (
            <div className="mainTopCard-body">
                <div className="mainTopCardDetail">
                    <div className="mainTopCardDetail-detail">
                        <div className="manageProperty">
                            <div className="manageProperty-title">{this.state.data.building_name}</div>
                            <div className="manageProperty-format">
                                <div className="registeredData">
                                    <div className="registeredData-title">書式：</div>
                                    <div className="registeredData-text">{this.state.data.input_format_name}|{this.state.data.contract_type_name_frk}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mainTopCardDetail-registered">
                        <div className="manageSubProperty">
                            <ul className="l-baseAction">
                                <li><Link to="" {...controlledBtnParams.release} aria-label="link" >確定解除</Link></li>
                                <li><Link to="" {...controlledBtnParams.share} aria-label="link" >設定</Link></li>
                                <li><Link to="" {...controlledBtnParams.commit} aria-label="link" >確定</Link></li>
                                <li><Link to="" {...controlledBtnParams.delete} aria-label="link" >削除</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * ボディー部の上部（更新日及びユーザ情報等）
     */
    BodyTopUpDateInfoLine() {

        // 新規作成日
        const createdDate = dateUtil.tzStrToDate(this.state.data.created_at);

        // 最終更新日
        const updatedDate = dateUtil.tzStrToDate(this.state.data.updated_at);

        return (
            <div className="mainTopCard-body">
                <div className="mainTopCard-body-tab">
                    <div className="l-actionTab">
                        <div className="mainTopCardDetail">
                            <div className="mainTopCardDetail-registered">
                                <div className="manageSubProperty">
                                    <div className="manageSubProperty-date">
                                        <ul className="l-registeredData">
                                            <li>
                                                <div className="registeredData">
                                                    <div className="registeredData-title">新規作成日：</div>
                                                    <div className="registeredData-text">{createdDate}</div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="registeredData">
                                                    <div className="registeredData-title">最終更新日：</div>
                                                    <div className="registeredData-text">{updatedDate}</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="manageSubProperty-number">
                                        <div className="documentStatus">
                                            <div className="documentStatus-number">
                                                <div className="registeredData">
                                                    <div className="registeredData-title">稟議書No.：</div>
                                                    <div className="registeredData-text">{this.state.data.document_no}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="manageSubProperty-person">
                                        <div className="registeredData">
                                            <div className="registeredData-title">作成者：</div>
                                            <div className="registeredData-text">{this.state.data.person_name_in_charge}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * ボディー部の上部（契約情報タブ及保存ボタン等）
     */
    BodyTopContractInfoLine(controlledBtnParams) {

        return (
            <div className="mainTopCard-body">
                <div className="mainTopCard-body-tab">
                    <div className="l-actionTab">
                        <ul className="l-actionTab-inner-underline">
                            <div className="mainTopCardDetail">
                                <div className="manageSubProperty">
                                    <ul className="l-actionTab-inner">
                                        <li><Link to="" aria-label="link" className={"actionTab " + this.addActiveWhenBasic()} data-page={CONTRACT_EDIT_INFO_FRK.PAGE_0101.id} onClick={this.handlePageChange}>基本情報</Link></li>
                                        <li><Link to="" aria-label="link" className={"actionTab " + this.addActiveWhenImportant()} data-page={CONTRACT_EDIT_INFO_FRK.PAGE_0201.id} onClick={this.handlePageChange}>重要事項説明書</Link></li>
                                        <li><Link to="" aria-label="link" className={"actionTab " + this.addActiveWhenContract()} data-page={CONTRACT_EDIT_INFO_FRK.PAGE_0301.id} onClick={this.handlePageChange}>不動産売買契約書</Link></li>
                                    </ul>
                                </div>
                                <div className="manageSubProperty-action">
                                    <ul className="l-actionTab-inner">
                                        <li><Link to="" {...controlledBtnParams.save} aria-label="link" >保存</Link></li>
                                        <li><Link to="" {...controlledBtnParams.confirm} aria-label="link" >入力済み</Link></li>
                                        <li></li>
                                        <li><Link to="" {...controlledBtnParams.preview} aria-label="link" >
                                            <span className="baseAction-text">現在ページ印刷</span>
                                        </Link>
                                        </li>
                                        <li><Link to="" {...controlledBtnParams.allPreview} aria-label="link">
                                            <span className="baseAction-text">全ページ印刷</span>
                                        </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * ボディー部の上部（画面切替ボタン等）
     */
    BodyTopScreenSwitchingLine() {

        return (
            <div className="mainTopCard-body">
                <ul className="l-actionTab-inner-underline">
                    <div className="mainTopCardDetail">
                        <SelectScreen contractId={this.state.contractId} type={this.state.type} pageSwitch={this.pageSwitch} contractTypeName={this.state.data.contract_type_name} pageId={this.state.pageId} confirmed={this.state.confirmed} />
                    </div>
                </ul>
            </div>
        );
    }

}

/** render()内の共通処理 */
const renderCommon = state => {
    return (state.hide ? "" : "is-open");
};

const mapStateToProps = state => {
    return {
        confirm: state.confirm,
        admin: state.admin,
        confirmed: state.confirmed,
        userName: state.userName,
        agencyId: state.agencyId,
        authority: state.authority,
        useFormat: state.useFormat
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(loginUserAction, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractEdit));

// 取得した元号マスタを別機能でも使用できるようにする
// reduxの方がいいと思うけど時間の都合...
export let eraMaster = {};

/** 画面切替ボタン一覧の生成 */
const SelectScreen = props => {

    let list = null;

    // 基本情報
    if (props.type === CONTRACT_EDIT_INFO_FRK.TYPE.BASIC) {
        list = (
            <ul className="l-baseAction-page">
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0101.id, CONTRACT_EDIT_INFO_FRK.PAGE_0101.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0101.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0101.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0101.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0102.id, CONTRACT_EDIT_INFO_FRK.PAGE_0102.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0102.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0102.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0102.page}</span>
                        </Link>
                    </Tooltip>
                </li>
            </ul >
        );
    }

    // 重要事項説明書
    if (props.type === CONTRACT_EDIT_INFO_FRK.TYPE.IMPORTANT) {
        list = (
            <ul className="l-baseAction-page" >
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0201.id, CONTRACT_EDIT_INFO_FRK.PAGE_0201.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0201.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0201.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0201.page}</span>
                        </Link>
                    </Tooltip>
                </li >
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0202.id, CONTRACT_EDIT_INFO_FRK.PAGE_0202.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0202.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0202.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0202.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0203.id, CONTRACT_EDIT_INFO_FRK.PAGE_0203.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0203.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0203.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0203.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0204.id, CONTRACT_EDIT_INFO_FRK.PAGE_0204.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0204.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0204.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0204.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0205.id, CONTRACT_EDIT_INFO_FRK.PAGE_0205.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0205.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0205.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0205.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0206.id, CONTRACT_EDIT_INFO_FRK.PAGE_0206.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0206.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0206.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0206.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0207.id, CONTRACT_EDIT_INFO_FRK.PAGE_0207.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0207.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0207.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0207.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0208.id, CONTRACT_EDIT_INFO_FRK.PAGE_0208.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0208.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0208.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0208.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0209.id, CONTRACT_EDIT_INFO_FRK.PAGE_0209.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0209.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0209.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0209.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0210.id, CONTRACT_EDIT_INFO_FRK.PAGE_0210.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0210.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0210.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0210.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0211.id, CONTRACT_EDIT_INFO_FRK.PAGE_0211.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0211.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0211.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0211.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0213.id, CONTRACT_EDIT_INFO_FRK.PAGE_0213.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0213.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0213.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0213.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0212.id, CONTRACT_EDIT_INFO_FRK.PAGE_0212.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0212.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0212.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0212.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0214.id, CONTRACT_EDIT_INFO_FRK.PAGE_0214.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0214.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0214.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0214.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0216.id, CONTRACT_EDIT_INFO_FRK.PAGE_0216.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0216.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0216.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0216.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0215.id, CONTRACT_EDIT_INFO_FRK.PAGE_0215.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0215.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0215.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0215.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0218.id, CONTRACT_EDIT_INFO_FRK.PAGE_0218.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0218.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0218.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0218.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0217.id, CONTRACT_EDIT_INFO_FRK.PAGE_0217.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0217.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0217.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0217.page}</span>
                        </Link>
                    </Tooltip>
                </li>
            </ul >
        );
    }

    // 不動産売買契約書
    if (props.type === CONTRACT_EDIT_INFO_FRK.TYPE.ESTATE) {
        list = (
            <ul className="l-baseAction-page">
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0301.id, CONTRACT_EDIT_INFO_FRK.PAGE_0301.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0301.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0301.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0301.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0302.id, CONTRACT_EDIT_INFO_FRK.PAGE_0302.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0302.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0302.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0302.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0303.id, CONTRACT_EDIT_INFO_FRK.PAGE_0303.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0303.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0303.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0303.page}</span>
                        </Link>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip trigger="mouseenter" size='small' title={contractPageNo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0304.id, CONTRACT_EDIT_INFO_FRK.PAGE_0304.title)}>
                        <Link to="" aria-label="link" className={contractPageInfo(props, CONTRACT_EDIT_INFO_FRK.PAGE_0304.id)} onClick={e => { e.preventDefault(); props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0304.id); }}>
                            <span className="baseAction-text">{CONTRACT_EDIT_INFO_FRK.PAGE_0304.page}</span>
                        </Link>
                    </Tooltip>
                </li>
            </ul>
        );
    }

    return (
        <div className="manageSubProperty-page">
            {list}
        </div>
    );

};

/**
 *  入力確定情報を元に契約書情報ボタンの表示をコントロールする
 */
function selectConfirmed(confirmed, targetId) {
    // 入力確定情報を取得
    for (const id in confirmed) {
        if (id === targetId) {
            return (confirmed[id]);
        }
    }
    // 該当なしの場合
    return (false);
}

/**
 *  入力確定情報の契約書情報ボタン（オン・オフ）を更新する
 */
function updateConfirmed(confirmed, targetId) {
    const tmpConfirmed = { ...confirmed };
    // 入力確定のboolを反転
    tmpConfirmed[targetId] = !tmpConfirmed[targetId];
    return tmpConfirmed;
}

/**
*  契約書ページボタンの表示・非表示及び現在ページ・入力確定をコントロールする
*  優先順位：非表示＞現在ページ＞入力確定＞表示（デフォルト）
*/
function contractPageInfo(props, targetId) {

    // デフォルトのボタンクラスを設定
    let rtnClassNm = "baseAction-page";

    // 入力確定の場合、ボタンを濃い朱色に設定
    if (selectConfirmed(props.confirmed, targetId)) {
        rtnClassNm = "baseAction-page__confirm";
    }

    // 現在ページの場合、ボタンを橙色に設定
    if (props.pageId === targetId) {
        rtnClassNm = "baseAction-page__now";
    }

    // 現在ページ且つ入力確定の場合、ボタンを薄い朱色に設定
    if (props.pageId === targetId && selectConfirmed(props.confirmed, targetId)) {
        rtnClassNm = "baseAction-page__confnow";
    }

    // 非表示ページの場合、非表示に設定
    if (contractPageDisable(props, targetId)) {
        rtnClassNm = "nodisplay";
    }

    // 表示（デフォルト）
    return (rtnClassNm);
}

/**
 *  契約書ページボタンの非表示判定
 *  表示：false、非表示：true
 */
function contractPageDisable(props, targetId) {
    // 「土地」の場合、非表示の制御有り
    const land = ["0208", "0209", "0210", "0211", "0217"];
    // 「土地建物」の場合、非表示の制御有り
    const building = ["0208", "0209", "0210", "0217"];
    // 「借地権付建物」の場合、非表示の制御有り
    const leasehold = ["0208", "0209", "0210"];

    //「土地」の場合：非表示判定
    if (props.contractTypeName.indexOf("土地") !== -1
        && props.contractTypeName.indexOf("土地建物") === -1) {
        for (const pageId of land) {
            if (pageId === targetId) {
                return true;
            }
        }
    }

    //「土地建物」の場合：非表示判定
    if (props.contractTypeName.indexOf("土地建物") !== -1) {
        for (const pageId of building) {
            if (pageId === targetId) {
                return true;
            }
        }
    }

    //「借地権付建物」の場合：非表示判定
    if (props.contractTypeName.indexOf("借地権付建物") !== -1) {
        for (const pageId of leasehold) {
            if (pageId === targetId) {
                return true;
            }
        }
    }

    // 該当なしの場合
    return false;
}

/**
 *  契約書ページのページ番号を取得
 *  （対象が、重要事項説明書のみ）
 */
function contractPageNo(props, targetId, title) {
    // 「一般・土地」のページ番号定義
    const general = [
        { id: "0201", page_no: "P.1_" },
        { id: "0202", page_no: "P.3_" },
        { id: "0203", page_no: "P.4_" },
        { id: "0204", page_no: "P.4_" },
        { id: "0205", page_no: "P.6_" },
        { id: "0206", page_no: "P.7_" },
        { id: "0207", page_no: "P.7_" },
        { id: "0212", page_no: "P.8_" },
        { id: "0213", page_no: "P.8_" },
        { id: "0214", page_no: "P.10_" },
        { id: "0215", page_no: "P.11_" },
        { id: "0216", page_no: "P.10_" },
        { id: "0218", page_no: "P.11_" }
    ];
    // 「消費者契約・土地」、「売主宅建業者」のページ番号定義
    const consumer = [
        { id: "0201", page_no: "P.1_" },
        { id: "0202", page_no: "P.3_" },
        { id: "0203", page_no: "P.4_" },
        { id: "0204", page_no: "P.4_" },
        { id: "0205", page_no: "P.6_" },
        { id: "0206", page_no: "P.7_" },
        { id: "0207", page_no: "P.7_" },
        { id: "0212", page_no: "P.8_" },
        { id: "0213", page_no: "P.8_" },
        { id: "0214", page_no: "P.10_" },
        { id: "0215", page_no: "P.11_" },
        { id: "0216", page_no: "P.10_" },
        { id: "0218", page_no: "P.11_" }
    ];
    // 「土地建物」のページ番号定義
    const building = [
        { id: "0201", page_no: "P.1_" },
        { id: "0202", page_no: "P.3_" },
        { id: "0203", page_no: "P.4_" },
        { id: "0204", page_no: "P.4_" },
        { id: "0205", page_no: "P.6_" },
        { id: "0206", page_no: "P.7_" },
        { id: "0207", page_no: "P.7_" },
        { id: "0211", page_no: "P.8_" },
        { id: "0212", page_no: "P.9_" },
        { id: "0213", page_no: "P.9_" },
        { id: "0214", page_no: "P.11_" },
        { id: "0215", page_no: "P.12_" },
        { id: "0216", page_no: "P.11_" },
        { id: "0218", page_no: "P.12_" }
    ];
    // 「区分所有」のページ番号定義
    const apartment = [
        { id: "0201", page_no: "P.1_" },
        { id: "0202", page_no: "P.3_" },
        { id: "0203", page_no: "P.4_" },
        { id: "0204", page_no: "P.4_" },
        { id: "0205", page_no: "P.6_" },
        { id: "0206", page_no: "P.6_" },
        { id: "0207", page_no: "P.7_" },
        { id: "0208", page_no: "P.7_" },
        { id: "0209", page_no: "P.8_" },
        { id: "0210", page_no: "P.9_" },
        { id: "0211", page_no: "P.10_" },
        { id: "0212", page_no: "P.11_" },
        { id: "0213", page_no: "P.11_" },
        { id: "0214", page_no: "P.13_" },
        { id: "0215", page_no: "P.14_" },
        { id: "0216", page_no: "P.13_" },
        { id: "0218", page_no: "P.14_" }
    ];

    // 重要事項説明書以外は、何も返さない
    if (!props.type === CONTRACT_EDIT_INFO_FRK.TYPE.IMPORTANT) {
        return (title);
    }

    //「一般・土地」の場合のページ数表記
    if (props.contractTypeName.indexOf("土地") !== -1
        && props.contractTypeName.indexOf("土地建物") === -1
        && props.contractTypeName.indexOf("一般") !== -1) {
        for (const pageNo of general) {
            if (pageNo.id === targetId) {
                return (pageNo.page_no + title);
            }
        }
    }

    //「消費者契約・土地」、「売主宅建業者・土地」の場合のページ数表記
    if (props.contractTypeName.indexOf("土地") !== -1
        && props.contractTypeName.indexOf("土地建物") === -1
        && (props.contractTypeName.indexOf("消費者契約") !== -1
            || props.contractTypeName.indexOf("売主宅建業者") !== -1)) {
        for (const pageNo of consumer) {
            if (pageNo.id === targetId) {
                return (pageNo.page_no + title);
            }
        }
    }

    //「土地建物」の場合のページ数表記
    if (props.contractTypeName.indexOf("土地建物") !== -1
        || props.contractTypeName.indexOf("借地権付建物") !== -1) {
        for (const pageNo of building) {
            if (pageNo.id === targetId) {
                return (pageNo.page_no + title);
            }
        }
    }

    //「区分所有」の場合のページ数表記
    if (props.contractTypeName.indexOf("区分所有") !== -1) {
        for (const pageNo of apartment) {
            if (pageNo.id === targetId) {
                return (pageNo.page_no + title);
            }
        }
    }

    // 該当なしの場合
    return (title);
}

/**
 *  確定権限の権限者のチェック
 * 　<引数>
 *  　authority：ログインユーザの権限
 *  　headquartersConfirm：契約書情報の本部確定フラグ
 * 　<戻り値>
 *  　true：権限あり、false：権限なし
 */
function isApprovalAuthority(authority, headquartersConfirm) {
    // 本部が確定のチェックが入っている場合
    if (headquartersConfirm) {
        // 権限が「契約審査部」の場合
        if (authority === AUTHORITY_FRK_MUFG.CONTRACT_EXAMINATION.value) {
            return true;
        }
    } else {
        // 本部が確定のチェックが入っていない場合
        return true;
    }

    return false;
}

/** 見出しのヘッダ部分のコンポーネント */
export const HeadlineHeader = props => {
    let className = "mainTopCard-top-action";
    className += props.hide ? " is-active" : "";

    return (
        <div className={className}><Link to="" aria-label="link" className="mainTopCard-top-action__item" onClick={props.onClick}></Link></div>
    );
};