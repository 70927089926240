import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 取引条件 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021201",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 文字数制限定義
        const JYU01100LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU01100",
                this.props.pageState.JYU01100);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        const arrayItemsDOM = this.createDOMItems(
            this.props.pageState.JYU01086_ARR,
            this.props.pageState.TOR00340,
            this.props.contractType
        );

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">取引条件</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-body is-emphasis dataTable-body__noborder" colSpan="5">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-text">売買代金</div>
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.props.pageState.TOR00010)}</div>
                                        <div className="dataTableUnit-text">円</div>
                                        <div className="dataTableUnit-text">（うち消費税等相当額</div>
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.props.pageState.TOR00009)}</div>
                                        <div className="dataTableUnit-text">円）</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title is-emphasis_single" colSpan="5">売買代金および交換差金以外に売主・買主間で授受される金銭の額</th>
                            </tr>
                            {arrayItemsDOM}
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU01100" name="JYU01100" value={this.props.pageState.JYU01100} text={SEND_OTHER_PAPER.JYU01100} placeholder={JYU01100LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU01100LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    createDOMItems = (arrays, tor00340, contractType) => {
        // 動的項目の共通パラメータ
        const arrayName = "JYU01086_ARR"; // 動的項目の代表名
        const params = {
            arrayName: arrayName,
            pageState: this.props.pageState,
            updatePageState: this.props.updatePageState,
        };

        const arrayItemsDOM = arrays.map((item, idx) => {

            if (idx >= 5) {
                return <></>;
            }

            // "通番"部分生成
            let head = null;
            // 番号をつける
            head = <th className="dataTable-title dataTable-title__center" rowSpan="2">{idx + 1}</th>;

            // "授受の目的"部分の生成
            let item1 = null;
            if (idx === 0) {
                // 1番目は入力欄にならない
                item1 = <td className="dataTable-body">{item.JYU01086}</td>;
            } else if (idx === 1) {
                // 2番目は固定文字＋入力欄になる
                item1 = (
                    <td className="dataTableUnit dataTable-body">
                        {item.JYU01086}
                        <Input idx={idx} noBlock mode="dynamic" id={`ID_JYU01716_${idx}`} name="JYU01716" value={item.JYU01716} {...params} length="wFlexLeftauto" />
                    </td>
                );
            } else if (idx === 2) {
                if (contractType.indexOf('区分所有') === -1) {
                    // 3番目の区分所有以外は入力欄になる
                    item1 = (
                        <td className="dataTable-body">
                            <Input idx={idx} mode="dynamic" id={`ID_JYU01716_${idx}`} name="JYU01716" value={item.JYU01716} {...params} />
                        </td>
                    );
                } else {
                    // 3番目の区分所有は固定文字＋入力欄になる
                    item1 = (
                        <td className="dataTableUnit dataTable-body">
                            {item.JYU01086}
                            <Input idx={idx} noBlock mode="dynamic" id={`ID_JYU01716_${idx}`} name="JYU01716" value={item.JYU01716} {...params} length="wFlexLeftauto" />
                        </td>
                    );
                }
            } else {
                item1 = (
                    // 4番目、5番目は入力欄になる
                    <td className="dataTable-body">
                        <Input idx={idx} mode="dynamic" id={`ID_JYU01086_${idx}`} name="JYU01086" value={item.JYU01086} {...params} />
                    </td>
                );
            }
            // "金額"部分の生成
            let item2 = null;
            if (idx === 0) {
                // 1番目（手付金）は別入力項目参照
                item2 = <div className="dataTableUnit-text">{this.amountCommaEdit(tor00340)}</div>;
            } else {
                // 2番目以降は入力欄になる
                item2 = <Input idx={idx} mode="dynamic" id={`ID_JYU01087_${idx}`} name="JYU01087" value={item.JYU01087} {...params} />;
            }

            return (
                <React.Fragment key={idx} >
                    <tr>
                        {head}
                        <th className="dataTable-title dataTable-title__center">授受の目的</th>
                        {item1}
                        <th className="dataTable-title dataTable-title__center">金額</th>
                        <td className="dataTable-body">
                            <div className="dataTableUnit">
                                {item2}
                                <div className="dataTableUnit-text">円</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__center">備考</th>
                        <td className="dataTable-body" colSpan="2">
                            <Input idx={idx} mode="dynamic" id={`ID_JYU01089_${idx}`} name="JYU01089" value={item.JYU01089} {...params} />
                        </td>
                        <td className="dataTable-body">&nbsp;</td>
                    </tr>
                </React.Fragment>
            );
        });

        return (
            <React.Fragment>
                {arrayItemsDOM}
            </React.Fragment>
        );
    }
}