export function jwkInfo(state = {}, action) {
    switch (action.type) {
        case "SAVE_JWK_INFO":
            // `kidの値`.jwk に jwk の値を保持する
            // `kidの値`.pem に公開鍵を保持する
            state[action.jwkInfo.jwk.kid] = {};
            var _ = state[action.jwkInfo.jwk.kid];
            _.jwk = action.jwkInfo.jwk;
            _.pem = action.jwkInfo.pem;
            return state;

        default:
            return state;
    }
}

export function idToken(state = null, action) {
    switch (action.type) {
        case "SAVE_ID_TOKEN":
            state = action.idToken;
            return state;

        default:
            return state;
    }
}
