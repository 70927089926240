import React from "react";
import { Link } from "react-router-dom";

/**
 * 入力ボックス
 * props.titleName: 入力ボックスのタイトル
 * props.inputData: 入力値データ \n {name: "stateの名前", value: "入力値"}
 * props.inputSetting: 入力タグの属性情報
 */
export function FormInputTextRecord(props) {
    let className = "loginField-input";
    if (props.errorMessage) className += " is-error";
    const inputType = !props.password ? "text" : "password";

    return (
        <div className="loginField-action-list">
            <div className="loginField-head">{props.titleName}</div>
            <div className={className}>
                <input type={inputType} aria-label="text" {...props.inputData} {...props.inputSetting} />
                {props.errorMessage && (
                    <span className="loginField-input-error"><span className="loginField-input-error_text">{props.errorMessage}</span></span>
                )}
            </div>
        </div>
    );
}

/**
 * リンク
 * props.isCenter: [任意] リンクを中央に寄せるか
 * props.to: [任意] リンクの遷移先
 * props.onClick: [任意] リンク押下時のハンドラ
 * props.isCenter: [任意] 中央に表示
 * props.children: リンクのValue
 */
export function FormLinkRecord(props) {
    return (
        <div className={props.isCenter ? "loginField-link loginField-link__center" : "loginField-link"}>
            <Link to={props.to || ""} aria-label="link" onClick={props.onClick || null}>
                {props.children}
            </Link>
        </div>
    );
}

/**
 * ボタン
 * props.to: [任意] ボタンの遷移先
 * props.isActive: [任意] クラス名"is-active"の使用の有無
 * props.onClick: [任意] ボタン押下時のハンドラ
 * props.children: ボタンのValue
 */
export function FormButtonRecord(props) {
    return (
        <div className="loginField-button">
            <Link to={props.to || ""} aria-label="link" className={props.isActive ? "baseAction is-active" : "baseAction"} onClick={props.onClick || null}>
                {props.children}
            </Link>
        </div>
    );
}
