import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 共有部分に関する規約等の定め */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020802",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 斜線対応の設定
        let DomDiagonalLine = undefined;
        if (this.onloadFlg && this.props.pageState.JYU01401) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_JYU01401");
        }

        // 文字数制限定義
        const JYU00813LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00813",
                this.props.pageState.JYU00813);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU01401:AR_JYU01401-1,AR_JYU01401-2,AR_JYU01401-3")}>
                <div className="dataList-header">
                    <div className="dataList-header-title">
                        <div className="dataTableUnit dataTableUnit--center">
                            <div className="dataTableUnit-input"><span className="checkBox-text">共用部分に関する規約等の定め</span></div>
                            <div className="dataTableUnit-text">（</div>
                            <div className="dataTableUnit-input">
                                <ul className="l-checkBox">
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU00805" name="JYU00805" checked={this.props.pageState.JYU00805} {...params} />
                                            <span className="checkBox-text">有</span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU01401" name="JYU01401" checked={this.props.pageState.JYU01401} {...params} />
                                            <span className="checkBox-text">無　※チェックした場合は、斜線を引きます。</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div className="dataTableUnit-text">）</div>
                        </div>
                    </div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            {DomDiagonalLine}
                            <tr id="AR_JYU01401-1">
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">共用部分<br />の範囲</th>
                                <td className="dataTable-body">共用の玄関・廊下・階段・外壁・建物躯体・配線（主線）・配管（主管）等
                                    <br />
                                    <Input noBlock id="ID_JYU01658" name="JYU01658" value={this.props.pageState.JYU01658} {...params} length="wFull" />
                                </td>
                                <td className="dataTable-body dataTable-body__w285 dataTable-body__rightHandSide">
                                    管理規約&nbsp;&nbsp;<Input noBlock id="ID_JYU01659" name="JYU01659" value={this.props.pageState.JYU01659} {...params} length="w200" />
                                    <br />
                                    <Input noBlock id="ID_JYU01660" name="JYU01660" value={this.props.pageState.JYU01660} {...params} length="w176" />&nbsp;&nbsp;条
                                    <br />
                                    <Input noBlock id="ID_JYU01661" name="JYU01661" value={this.props.pageState.JYU01661} {...params} length="wFlexFull" />
                                </td>
                            </tr>
                            <tr id="AR_JYU01401-2">
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">共用部分の<br />持分の割合</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00809" name="JYU00809" checked={this.props.pageState.JYU00809} {...params} />
                                                <span className="checkBox-text">1.専有部分の床面積の割合による</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00810" name="JYU00810" checked={this.props.pageState.JYU00810} {...params} />
                                                <span className="checkBox-text">
                                                    2.<Input noBlock id="ID_JYU01662" name="JYU01662" value={this.props.pageState.JYU01662} {...params} length="w537" />
                                                </span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body dataTable-body__w221 dataTable-body__rightHandSide">
                                    <Input noBlock id="ID_JYU00812" name="JYU00812" value={this.props.pageState.JYU00812} {...params} length="w176" />&nbsp;&nbsp;条
                                </td>
                            </tr>
                            <tr id="AR_JYU01401-3">
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00813" name="JYU00813" value={this.props.pageState.JYU00813} text={SEND_OTHER_PAPER.JYU00813} placeholder={JYU00813LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00813LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}