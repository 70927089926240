import React from "react";
import { FORMAT_JUDGE_INFO, isFormatJudge } from "util/businessRules";
import Validation from "util/validate";
import InputCheckbox from "../../input/inputCheckbox";
import InputDate from "../../input/inputDate";
import Input from "../../input/inputNormal";
import InputTextRemark from "../../input/inputTextRemark";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 他社情報 */
export default class Block5 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020105",
        };
        // アップロードするファイル名// 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 文字数制限定義
        const JYU00054LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00054",
                this.props.pageState.JYU00054);
        const JYU01768LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU01768",
                this.props.pageState.JYU01768);
        const JYU01769LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU01769",
                this.props.pageState.JYU01769);

        // 斜線対応の設定
        let DomDiagonalLine = undefined;
        if (this.onloadFlg && this.props.pageState.JYU01571) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_JYU01571");
        }

        // 契約種別が売主宅建業者の場合、JYU00050を非活性にする
        const disabledJYU00050 = isFormatJudge(this.props.contractTypeId, FORMAT_JUDGE_INFO.USER.TRAD);

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU01571:AJYU01571-1")}>
                <div className="dataList-header">
                    <ul className="l-checkBox">
                        <li>
                            <label className="checkBox">
                                <InputCheckbox id="ID_JYU01571" name="JYU01571" checked={this.props.pageState.JYU01571} {...params} />
                                <span className="checkBox-text">斜線</span>
                            </label>
                        </li>
                    </ul>
                    <div className="dataList-header-title is-emphasis">他社情報</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                {DomDiagonalLine}
                <div id="AJYU01571-1" className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis">取引態様</td>
                            </tr>
                            <tr>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00046" name="JYU00046" checked={this.props.pageState.JYU00046} {...params} />
                                                <span className="checkBox-text">売買</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00047" name="JYU00047" checked={this.props.pageState.JYU00047} {...params} />
                                                <span className="checkBox-text">交換</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00048" name="JYU00048" checked={this.props.pageState.JYU00048} {...params} />
                                                <span className="checkBox-text">媒介</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00049" name="JYU00049" checked={this.props.pageState.JYU00049} {...params} />
                                                <span className="checkBox-text">代理</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00050" name="JYU00050" checked={this.props.pageState.JYU00050} {...params} disabled={disabledJYU00050} />
                                                <span className="checkBox-text">売主</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="4">宅地建物取引業者</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">免許証番号</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00052" name="JYU00052" value={this.props.pageState.JYU00052} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">免許年月日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_JYU00053" name="JYU00053" value={this.props.pageState.JYU00053} {...params} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">主たる事務所<br />の所在地</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope disableReturn id="ID_JYU00054" name="JYU00054" value={this.props.pageState.JYU00054} placeholder={JYU00054LimitMsg.inputLimitGuide} {...params} height="h92" />
                                        <p className="LengthOver-error">{JYU00054LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">商号</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00056" name="JYU00056" value={this.props.pageState.JYU00056} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">代表者氏名</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00057" name="JYU00057" value={this.props.pageState.JYU00057} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">取扱店<br />宅地建物取引士等</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope disableReturn id="ID_JYU01768" name="JYU01768" value={this.props.pageState.JYU01768} placeholder={JYU01768LimitMsg.inputLimitGuide} {...params} height="h148" />
                                        <p className="LengthOver-error">{JYU01768LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">供託所等</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope disableReturn id="ID_JYU01769" name="JYU01769" value={this.props.pageState.JYU01769} placeholder={JYU01769LimitMsg.inputLimitGuide} {...params} height="h148" />
                                        <p className="LengthOver-error">{JYU01769LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
