import React from "react";
import Validation from "util/validate";
import { SEND_OTHER_PAPER } from "../../../../../../../constants/messages";
import InputCheckbox from "../../input/inputCheckbox";
import InputDate from "../../input/inputDate";
import Input from "../../input/inputNormal";
import InputTextRemark from "../../input/inputTextRemark";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 建物 */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020302",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 文字数制限定義
        const JYU00352LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00352",
                this.props.pageState.JYU00352);
        const JYU00359LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00359",
                this.props.pageState.JYU00359);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 非活性の設定
        let JYU01279_disabled = undefined;
        let JYU01280_disabled = undefined;

        if (this.props.pageState.JYU01279) {
            JYU01279_disabled = true;
        }

        if (this.props.pageState.JYU01280) {
            JYU01280_disabled = true;
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">建物</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableUnit dataTableUnit--rightend">
                                        <InputDate id="ID_JYU00341" name="JYU00341" value={this.props.pageState.JYU00341} {...params} />
                                        <div className="dataTableUnit-text">現在　　</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="3">権利部<br />（甲区）</th>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="2">所有者</th>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住所</th>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU00343" name="JYU00343" value={this.props.pageState.JYU00343} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title">氏名</th>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU00344" name="JYU00344" value={this.props.pageState.JYU00344} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center" colSpan="2">
                                    <div className="dataTableUnit_vr">
                                        <div className="dataTableUnit-text">所有権にかかる<br />権利に関する事項</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox l-checkBox--topspace l-checkBox--center">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00345" name="JYU00345" checked={this.props.pageState.JYU00345} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01279" name="JYU01279" checked={this.props.pageState.JYU01279} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <td className="dataTable-body">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope id="ID_JYU00352" name="JYU00352" cols={30} rows={10} value={this.props.pageState.JYU00352} text={SEND_OTHER_PAPER.JYU00352} placeholder={JYU00352LimitMsg.inputLimitGuide} {...params} disabled={JYU01279_disabled} />
                                        <p className="LengthOver-error">{JYU00352LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center">権利部<br />（乙区）</th>
                                <th className="dataTable-title dataTable-title__center" colSpan="2">
                                    <div className="dataTableUnit_vr">
                                        <div className="dataTableUnit-text">所有権以外の<br />権利に関する事項</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox l-checkBox--topspace l-checkBox--center">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00353" name="JYU00353" checked={this.props.pageState.JYU00353} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01280" name="JYU01280" checked={this.props.pageState.JYU01280} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <td className="dataTable-body">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope id="ID_JYU00359" name="JYU00359" cols={30} rows={10} value={this.props.pageState.JYU00359} text={SEND_OTHER_PAPER.JYU00359} placeholder={JYU00359LimitMsg.inputLimitGuide} {...params} disabled={JYU01280_disabled} />
                                        <p className="LengthOver-error">{JYU00359LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}