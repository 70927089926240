import React from "react";
import Validation from "util/validate";
import { SEND_OTHER_PAPER } from "../../../../../../../constants/messages";
import InputCheckbox from "../../input/inputCheckbox";
import InputDate from "../../input/inputDate";
import Input from "../../input/inputNormal";
import InputTextRemark from "../../input/inputTextRemark";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 区分所有建物 */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020303",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 文字数制限定義
        const JYU01783LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU01783",
                this.props.pageState.JYU01783);
        const JYU01786LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU01786",
                this.props.pageState.JYU01786);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 非活性の設定
        let JYU01782_disabled = undefined;
        let JYU01785_disabled = undefined;

        if (this.props.pageState.JYU01782) {
            JYU01782_disabled = true;
        }

        if (this.props.pageState.JYU01785) {
            JYU01785_disabled = true;
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">
                        <div className="dataTableUnit dataTableUnit--center">
                            <div className="dataTableUnit-input"><span className="checkBox-text">区分所有建物</span></div>
                            <div className="dataTableUnit-input">
                                <ul className="l-checkBox">
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU01750" name="JYU01750" checked={this.props.pageState.JYU01750} {...params} />
                                            <span className="checkBox-text">土地と建物が一体</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableUnit dataTableUnit--rightend">
                                        <InputDate id="ID_JYU01778" name="JYU01778" value={this.props.pageState.JYU01778} {...params} />
                                        <div className="dataTableUnit-text">現在　　</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="3">権利部<br />（甲区）</th>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="2">所有者</th>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住所</th>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01779" name="JYU01779" value={this.props.pageState.JYU01779} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title">氏名</th>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01780" name="JYU01780" value={this.props.pageState.JYU01780} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center" colSpan="2">
                                    <div className="dataTableUnit_vr">
                                        <div className="dataTableUnit-text">所有権にかかる<br />権利に関する事項</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox l-checkBox--topspace l-checkBox--center">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01781" name="JYU01781" checked={this.props.pageState.JYU01781} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01782" name="JYU01782" checked={this.props.pageState.JYU01782} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <td className="dataTable-body">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope id="ID_JYU01783" name="JYU01783" cols={30} rows={10} value={this.props.pageState.JYU01783} text={SEND_OTHER_PAPER.JYU01783} placeholder={JYU01783LimitMsg.inputLimitGuide} {...params} disabled={JYU01782_disabled} />
                                        <p className="LengthOver-error">{JYU01783LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center">権利部<br />（乙区）</th>
                                <th className="dataTable-title dataTable-title__center" colSpan="2">
                                    <div className="dataTableUnit_vr">
                                        <div className="dataTableUnit-text">所有権以外の<br />権利に関する事項</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox l-checkBox--topspace l-checkBox--center">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01784" name="JYU01784" checked={this.props.pageState.JYU01784} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01785" name="JYU01785" checked={this.props.pageState.JYU01785} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <td className="dataTable-body">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope id="ID_JYU01786" name="JYU01786" cols={30} rows={10} value={this.props.pageState.JYU01786} text={SEND_OTHER_PAPER.JYU01786} placeholder={JYU01786LimitMsg.inputLimitGuide} {...params} disabled={JYU01785_disabled} />
                                        <p className="LengthOver-error">{JYU01786LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}