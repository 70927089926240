/**
 *  ビジネスルールの共通処理
 * 　・判定処理及び編集処理を記述する。
 */

export const FORMAT_JUDGE_INFO = {
    USER: {             // 利用者の分類
        GENE: 'gene',       // 一般仲介
        CUST: 'cust',       // 消費者契約
        TRAD: 'trad',       // 売主宅建業者
    },
    MERCH: {            // 商品の分類
        LAND: 'land',       // 土地
        BUIL: 'buil',       // 土地・建物
        SECT: 'sect',       // 区分所有建物
    },
    RIGHT: {            // 権利の分類
        OWNE: 'owner',      // 所有権
        LEAS: 'lease',      // 借地権
    },
    NEWLY: {            // 新築物件の分類
        NEW: 'new',         // 新築物件
        OTH: 'oth',         // 新築物件以外
    }
};

/**
*  書式の判定用定義
*/
const FORMAT_JUDGE = [
    { id: '0101', info: { user: 'gene', merch: 'land', right: 'owner', newly: 'oth' } },
    { id: '0102', info: { user: 'gene', merch: 'land', right: 'owner', newly: 'oth' } },
    { id: '0103', info: { user: 'gene', merch: 'land', right: 'owner', newly: 'oth' } },
    { id: '0104', info: { user: 'gene', merch: 'buil', right: 'owner', newly: 'oth' } },
    { id: '0105', info: { user: 'gene', merch: 'buil', right: 'owner', newly: 'oth' } },
    { id: '0106', info: { user: 'gene', merch: 'buil', right: 'owner', newly: 'oth' } },
    { id: '0107', info: { user: 'gene', merch: 'sect', right: 'owner', newly: 'oth' } },
    { id: '0108', info: { user: 'gene', merch: 'sect', right: 'owner', newly: 'oth' } },
    { id: '0109', info: { user: 'gene', merch: 'buil', right: 'lease', newly: 'oth' } },
    { id: '0110', info: { user: 'gene', merch: 'buil', right: 'lease', newly: 'oth' } },
    { id: '0111', info: { user: 'gene', merch: 'sect', right: 'lease', newly: 'oth' } },
    { id: '0201', info: { user: 'cust', merch: 'land', right: 'owner', newly: 'oth' } },
    { id: '0202', info: { user: 'cust', merch: 'land', right: 'owner', newly: 'oth' } },
    { id: '0203', info: { user: 'cust', merch: 'land', right: 'owner', newly: 'oth' } },
    { id: '0204', info: { user: 'cust', merch: 'buil', right: 'owner', newly: 'oth' } },
    { id: '0205', info: { user: 'cust', merch: 'buil', right: 'owner', newly: 'oth' } },
    { id: '0206', info: { user: 'cust', merch: 'buil', right: 'owner', newly: 'oth' } },
    { id: '0207', info: { user: 'cust', merch: 'sect', right: 'owner', newly: 'oth' } },
    { id: '0208', info: { user: 'cust', merch: 'sect', right: 'owner', newly: 'oth' } },
    { id: '0209', info: { user: 'cust', merch: 'buil', right: 'lease', newly: 'oth' } },
    { id: '0301', info: { user: 'trad', merch: 'land', right: 'owner', newly: 'oth' } },
    { id: '0302', info: { user: 'trad', merch: 'land', right: 'owner', newly: 'oth' } },
    { id: '0303', info: { user: 'trad', merch: 'land', right: 'owner', newly: 'oth' } },
    { id: '0304', info: { user: 'trad', merch: 'buil', right: 'owner', newly: 'oth' } },
    { id: '0305', info: { user: 'trad', merch: 'buil', right: 'owner', newly: 'oth' } },
    { id: '0306', info: { user: 'trad', merch: 'buil', right: 'owner', newly: 'oth' } },
    { id: '0307', info: { user: 'trad', merch: 'sect', right: 'owner', newly: 'oth' } },
    { id: '0308', info: { user: 'trad', merch: 'sect', right: 'owner', newly: 'oth' } },
    { id: '0309', info: { user: 'trad', merch: 'buil', right: 'owner', newly: 'new' } },
    { id: '0310', info: { user: 'trad', merch: 'buil', right: 'lease', newly: 'oth' } },
    { id: '0311', info: { user: 'trad', merch: 'buil', right: 'lease', newly: 'new' } },
];

/**
 * 書式ID及び書式のチェック
 * 　引数
 * 　　formatId :書式ID（必須）
 * 　　user     :利用者の分類
 * 　　merch    :商品の分類
 * 　　right    :権利の分類
 * 　　newly    :新築物件の分類
 * 　戻り値
 * 　　書式ID及び指定の条件が一致:true、不一致:falseを返す。
 */
export const isFormatJudge = (
    formatId,
    user = undefined,
    merch = undefined,
    right = undefined,
    newly = undefined
) => {

    // 初期設定
    let rtn_judgment = false;
    let form = undefined;

    // 書式IDが未設定の場合はFalseを返す
    if (formatId === undefined) {
        return (rtn_judgment);
    }

    // 書式IDの存在しない場合
    for (const tmpform of FORMAT_JUDGE) {
        if (tmpform.id === formatId) {
            form = tmpform.info;
            rtn_judgment = true;
            break;
        }

    }

    // 書式IDの存在チェックのみの場合
    if (user === undefined && merch === undefined
        && right === undefined && newly === undefined) {
        return (rtn_judgment);
    }

    // 戻り値のリセット
    rtn_judgment = false;

    // 利用者の分類チェックが不一致の場合
    if (user !== undefined && user !== form.user) {
        return (rtn_judgment);
    }

    // 商品の分類チェックが不一致の場合
    if (merch !== undefined && merch !== form.merch) {
        return (rtn_judgment);
    }

    // 権利の分類チェックが不一致の場合
    if (right !== undefined && right !== form.right) {
        return (rtn_judgment);
    }

    // 新築物件者の分類チェックが不一致の場合
    if (newly !== undefined && newly !== form.newly) {
        return (rtn_judgment);
    }

    // 引数が全て一致の場合
    rtn_judgment = true;

    // 戻り値
    return (rtn_judgment);
};
