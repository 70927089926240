import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import InputDate from "components/page/user/contract/edit/input/inputDate";
import Input from "components/page/user/contract/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";

/** 建物の構造耐力上主要な部分等の状況について確認した事項 */
export default class Block6 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030106",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 斜線対応の設定
        let DomDiagonalLine = undefined;
        if (this.onloadFlg && this.props.pageState.FUD00364) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_FUD00364");
        }

        return (
            <React.Fragment>
                <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_FUD00364:AFUD00364-1,AFUD00364-2")}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">建物の構造耐久力上主要な部分等の状況について確認した事項</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <td className="dataTable-body is-emphasis" colSpan="5">
                                        <div className="dataTableUnit dataTableUnit--center">
                                            <div className="dataTableUnit-input">
                                                <ul className="l-checkBox">
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_FUD00363" name="FUD00363" checked={this.props.pageState.FUD00363} {...params} />
                                                            <span className="checkBox-text">既存の住宅に該当する</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_FUD00364" name="FUD00364" checked={this.props.pageState.FUD00364} {...params} />
                                                            <span className="checkBox-text">既存の住宅に該当しない　※入力不要の場合は、斜線を引きます。</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {DomDiagonalLine}
                                <tr id="AFUD00364-1">
                                    <th className="dataTable-title dataTable-title__w120">建物状況調査<br />の結果の概要</th>
                                    <td className="dataTable-body" colSpan="5">
                                        <ul className="l-checkBox">
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00278" name="FUD00278" checked={this.props.pageState.FUD00278} {...params} />
                                                    <span className="checkBox-text">1.有</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00281" name="FUD00281" checked={this.props.pageState.FUD00281} {...params} />
                                                    <span className="checkBox-text">2.無</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr id="AFUD00364-2">
                                    <th className="dataTable-title dataTable-title__w120">資料作成者</th>
                                    <td className="dataTable-body dataTable-body__w355">
                                        <div className="dataTableUnit">
                                            <Input id="ID_FUD00279" name="FUD00279" value={this.props.pageState.FUD00279} {...params} />
                                        </div>
                                    </td>
                                    <th className="dataTable-title dataTable-title__w120">資料作成日</th>
                                    <td className="dataTable-body dataTable-body__w355">
                                        <div className="dataTableUnit">
                                            <InputDate oneline id="ID_FUD00280" name="FUD00280" value={this.props.pageState.FUD00280} {...params} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};
