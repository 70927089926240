import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import { Link } from "react-router-dom";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 対象不動産に付随する専用使用権について */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020902",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    /** 削除ボタンのハンドラ */
    handleDeleteJYU00919 = e => {
        this.handleDelete(e, "JYU00919_ARR");
    }

    /** 追加ボタンのハンドラ */
    handleAddJYU00919 = e => {

        const addObj = {
            JYU00919: undefined, // 名称
            JYU00920: undefined, // 使用部分、面積、期間、料金等
        };

        this.handleAdd(e, "JYU00919_ARR", addObj);
    }

    render() {
        const { hideBlockClass } = this.renderCommon();

        // 文字数制限定義
        const JYU00934LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00934",
                this.props.pageState.JYU00934);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 動的項目の共通パラメータ
        const arrayName = "JYU00919_ARR"; // 動的項目の代表名
        const params = {
            arrayName: arrayName,
            pageState: this.props.pageState,
            updatePageState: this.props.updatePageState,
        };

        // ページが持っている動的項目の値を取得する
        const data = this.props.pageState[arrayName];

        let items = [];
        let nodisplay;
        if (data.length >= 5) {
            nodisplay = "nodisplay";
        }

        // 動的項目部分のDOM生成
        data.forEach((obj, idx) => {
            if (idx >= 5) {
                return;
            }

            // 項目名部分のDOM生成
            // 最初の２項目は項目名が決まっているので入力欄にしない
            let itemField = null;
            if (idx === 0 || idx === 1) {
                itemField = <td className="dataTable-body is-rightborder">{obj.JYU00919}</td>;
            } else {
                itemField = (
                    <td className="dataTable-body is-rightborder">
                        <Input idx={idx} id={`ID_JYU00919_${idx}`} name="JYU00919" value={obj.JYU00919} {...params} mode="dynamic" />
                    </td>
                );
            }

            // 項目番号部分のDOM生成
            // 最初の２項目は項目名が決まっているので入力欄にしない
            let itemNumber = null;
            if (idx === 0 || idx === 1) {
                itemNumber = <div className="dataTableUnit-text">{idx + 1}</div>;
            } else {
                itemNumber = (
                    <div className="dataTableUnit dataTableUnit--center">
                        <div className="dataTableUnit-text">{idx + 1}</div>
                        <div className="dataTableUnit-input">
                            <Link to="" id={`LINK_DEL_JYU00919_ARR_${idx}`} aria-label="link" data-idx={idx} data-array-name={arrayName} className="baseAction baseAction--minus baseAction__w40" onClick={this.handleDeleteJYU00919}>
                                <span data-idx={idx} className="baseAction-text">削除</span>
                            </Link>
                        </div>
                    </div>
                );
            }

            items.push(
                <React.Fragment key={idx}>
                    <tr>
                        <th className="dataTable-title dataTable-title__center">
                            {itemNumber}
                        </th>
                        {itemField}
                        <td className="dataTable-body">
                            <Input idx={idx} id={`ID_JYU00920_${idx}`} name="JYU00920" value={obj.JYU00920} {...params} mode="dynamic" />
                        </td>
                    </tr>
                </React.Fragment>
            );
        });

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">対象不動産に付随する専用使用権について</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title">&nbsp;</th>
                                <td className="dataTable-body is-emphasis dataTable-body__center dataTable-body__w260">名称</td>
                                <td className="dataTable-body is-emphasis dataTable-body__center">使用部分、面積、期間、料金等</td>
                            </tr>
                            {items}
                            <tr className={`${nodisplay}`}>
                                <td className="dataTable-body dataTable-body__center" colSpan="4">
                                    <Link to="" id="LINK_ADD_JYU00919_ARR" aria-label="link" data-array-name={arrayName} className="baseAction baseAction--plus baseAction__w40" onClick={this.handleAddJYU00919} >
                                        <span className="baseAction-text">追加</span>
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00934" name="JYU00934" value={this.props.pageState.JYU00934} text={SEND_OTHER_PAPER.JYU00934} placeholder={JYU00934LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00934LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}