import BaseGrid from "components/common/frame/baseGrid";
import { RegisterAndDeleteButtonRecord, RegisterButtonRecord } from "components/common/parts/buttonRecord";
import { InputTextRecord } from "components/common/parts/inputRecord";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import * as apiParamConverter from "util/apiParamConverter";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";
import Validation from "util/validate";

/** 名称マスタメンテナンス画面 */
class InputItemMaintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mustReload: true, // 更新画面時（登録時は関係なし）にリロードするかどうか、true: する
        };
        this.apiCalling = false; // true: 登録 or 更新APIコール中

        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();


        // 画面から入力される値の情報
        this.params = {
            inputItemType: {
                label: "名称区分",
                validate: this.validation.notEmpty,
                apiParamKey: "input_item_type"
            },
            displayId: {
                label: "名称ID",
                validate: this.validation.notEmpty,
                apiParamKey: "display_id"
            },
            inputItemName: {
                label: "名称",
                validate: this.validation.notEmpty,
                apiParamKey: "input_item_name"
            },
            orderNo: {
                label: "表示並び順",
                validate: this.validation.notEmptyAndIsNumber,
                apiParamKey: "order_no"
            },
        };
    }

    componentDidMount() {
        document.title = pageInfo.INPUT_ITEM_MAINTENANCE.title;
        if (!this.props.match.params.id) {
            this.setState({ inputItemType: this.props.match.params.type });
        }


        // 更新画面の場合、情報取得
        if (this.props.match.params.id) {
            this.setState({ mustReload: true });
        }
    }

    componentDidUpdate() {
        // 更新画面でリロードのフラグが立っている場合、情報取得
        if (this.props.match.params.id && this.state.mustReload) {
            this.requestInputItem();
        }
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputText = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputNumber = e => {
        const target = e.target;
        if (isNaN(target.value) || target.value === "") {
            this.setState({ [target.name]: target.value });
        } else {
            this.setState({ [target.name]: parseInt(target.value) });
        }
    }

    /** 入力ボックスの入力値を空文字チェック */
    handleValidateInputText = e => {
        const target = e.target;
        const param = this.params[target.name];
        param.validate(target.name, target.value);
        this.setState({});  // 再renderする為の空セット
    }

    // 登録ボタンのハンドラー
    handleRegisterInputItem = e => {
        e.preventDefault();

        // 全入力項目のチェックし、エラーメッセージがない場合、後続処理（登録APIの呼出し）をする
        if (!this.validation.validateTotalInputText(this.params, this.state)) {
            this.registerItem();
        } else {
            this.setState({}); // エラーメッセージをレンダリングさせる
        }
    }

    // 削除ボタンのハンドラー
    handleDeleteInputItem = e => {
        e.preventDefault();
        this.delItem();
    }

    render() {
        // 更新画面（this.props.idの指定がある）で、リロードの指定がある場合、空画面を返す（APIのレスポンスを待つ）
        if (this.props.match.params.id && this.state.mustReload) {
            return null;
        }

        // Inputレコードに関する、タグの属性情報
        const bodySetting = { colSpan: "3" };
        const inputSetting = { onChange: this.handleChangeInputText, onBlur: this.handleValidateInputText };
        const inputSettingNum = { onChange: this.handleChangeInputNumber, onBlur: this.handleValidateInputText };
        const titleSetting = { className: "dataTable-title dataTable-title__w120" };

        // 登録ボタンと削除ボタンの活性制御
        const buttonRegister = !this.apiCalling
            ? { onClick: this.handleRegisterInputItem }
            : { onClick: e => { e.preventDefault(); } };

        const buttonDelete = !this.apiCalling
            ? { onClick: this.handleDeleteInputItem }
            : { onClick: e => { e.preventDefault(); } };

        // 登録と更新で表示するボタンを出しわける
        const buttonDOM = this.props.match.params.id
            ? <RegisterAndDeleteButtonRecord inActive={this.apiCalling} registerSetting={buttonRegister} deleteSetting={buttonDelete} />
            : <RegisterButtonRecord inActive={this.apiCalling} registerSetting={buttonRegister} />;

        return (
            <BaseGrid>
                <div className="dataList dataList__accordion is-open">
                    <div className="dataList-header">名称マスタメンテナンス</div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <InputTextRecord titleName={this.params.inputItemType.label}
                                        textOnly
                                        inputData={{ name: "inputItemType", value: this.state.inputItemType || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.displayId.label}
                                        inputData={{ name: "displayId", value: this.state.displayId || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.displayId || ""} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.inputItemName.label}
                                        inputData={{ name: "inputItemName", value: this.state.inputItemName || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.inputItemName || ""} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.orderNo.label}
                                        inputData={{ name: "orderNo", value: this.state.orderNo !== undefined ? this.state.orderNo : "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSettingNum}
                                        errorMessage={this.validation.errorMessage.orderNo || ""} />
                                </tr>
                            </tbody>
                        </table>
                    </div >
                </div >

                {buttonDOM}

                <div className="l-pageAction l-pageAction--back">
                    <Link to={pageInfo.INPUT_ITEM_LIST.path} aria-label="link" className="pageAction">一覧へもどる</Link>
                </div>
            </BaseGrid >
        );
    }

    /** 取得APIを呼出し、stateを更新する */
    requestInputItem = () => {
        awsReq.get(
            AWS.ENDPOINT.PATHS.INPUT_ITEM_DETAIL.replace(":id", this.props.match.params.id),
            response => {
                this.setState({
                    ...convert.convertToFlatCamelCase(response.data),
                    mustReload: false,
                });
            },
            awsReq.defaultErrorHandler,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    /** 登録・更新リクエスト */
    registerItem = () => {
        this.apiCalling = true;
        this.setState({});

        let requestBody = apiParamConverter.convertToApiReqCase(this.params, this.state);

        if (this.props.match.params.id) {
            // リクエストに更新用のIDを設定する
            requestBody.id = parseInt(this.props.match.params.id);

            // 更新モード
            awsReq.put(AWS.ENDPOINT.PATHS.INPUT_ITEM_DETAIL.replace(":id", this.props.match.params.id),
                res => {
                    this.apiCalling = false;
                    this.setState({ mustReload: true });
                },
                this.errorCallback,
                requestBody,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );

        } else {
            // APIレスポンスのHTTPステータスが200の場合に実行させるコールバック関数
            const successCallback = async response => {
                this.apiCalling = false;

                await this.setState(convert.convertToFlatCamelCase(response.data));
                // APIのレスポンスのLocationヘッダの末尾の文字列（ID部分）を取得
                const id = response.headers["location"].split("/").slice(-1)[0];
                this.props.history.push(pageInfo.INPUT_ITEM_MAINTENANCE_UPDATE.path.replace(":type", this.state.inputItemType).replace(":id", id));
            };

            // 登録モード
            awsReq.post(AWS.ENDPOINT.PATHS.INPUT_ITEM,
                successCallback,
                this.errorCallback,
                requestBody,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }

    /** 削除リクエスト */
    delItem = () => {
        this.apiCalling = true;
        this.setState({});

        awsReq.del(
            AWS.ENDPOINT.PATHS.INPUT_ITEM_DETAIL.replace(":id", this.props.match.params.id),
            () => { this.props.history.push(pageInfo.INPUT_ITEM_LIST.path); },
            error => {
                alert(ERROR_MESSAGE.SERVER_ERROR);
                this.apiCalling = false;
                this.setState({}); // 再renderする為に、stateを空更新
            },
            {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    // APIレスポンスのHTTPステータスが200以外の場合に実行させるコールバック関数
    errorCallback = error => {
        alert(ERROR_MESSAGE.SERVER_ERROR);
        this.apiCalling = false;
        this.setState({}); // 再renderする為に、stateを空更新
    };
}

export default withRouter(InputItemMaintenance);
