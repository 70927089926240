import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";

/** 売主宅建業者 */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030302",
        };
    }

    render() {
        const { hideBlockClass } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">売主宅建業者</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="4">売主（宅地建物取引業者）</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">免許証番号</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">{this.props.pageState.JYU00096}</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">主たる事務所<br />の所在地</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">{this.props.pageState.JYU00098}</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">商号</th>
                                <td className="dataTable-body dataTable-body__w355">{this.props.pageState.JYU00100}</td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">代表者氏名</th>
                                <td className="dataTable-body dataTable-body__w355">{this.props.pageState.JYU00101}</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="4">宅地建物取引士</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">登録番号</th>
                                <td className="dataTable-body dataTable-body__w355">{this.props.pageState.JYU00102}</td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">氏名</th>
                                <td className="dataTable-body dataTable-body__w355">{this.props.pageState.JYU00103}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}