import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import Input from "components/page/user/contract/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";

/** 契約不適合の責任期間起算の時 */
export default class Block5 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030205",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">契約不適合の責任期間起算の時</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240" colSpan="2">契約不適合の責任期間起算の時<br />（第12条第4項、第5項）</th>
                                <td className="dataTable-body" colSpan="4">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--Wfull">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_FUD00368" name="FUD00368" checked={this.props.pageState.FUD00368} {...params} />
                                                <span className="checkBox-text">1.第7条第2項の引渡完了日</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_FUD00369" name="FUD00369" checked={this.props.pageState.FUD00369} {...params} />
                                                <span className="checkBox-text">2.住宅新築請負契約の請負人から売主への引渡しの時</span>
                                            </label>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_FUD00370" name="FUD00370" checked={this.props.pageState.FUD00370} {...params} />
                                                        <span className="checkBox-text">3.</span>
                                                    </label>
                                                </div>
                                                <div className="dataTableUnit-input dataTableUnit-input__wFull">
                                                    <Input id="ID_FUD00371" name="FUD00371" value={this.props.pageState.FUD00371} {...params} />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}