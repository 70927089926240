import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import ja from 'date-fns/locale/ja';
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as dateUtil from "util/dateUtil";
import * as helper from "../helper";
import { InputBase } from './inputBase';

// jaのロケールの設定が週頭が月曜始まりになっているので日曜始まりにする
ja.options.weekStartsOn = 0;
// ReactDatepickerのロケール登録
registerLocale('ja', ja);

export default class InputDate extends InputBase {
    constructor(props) {
        super(props);

        this.state = {
            errorDisp: false // true: 表示する
        };

        // 通常項目用の初期化、更新ハンドラを設定する
        let init = this.initStandard;
        this.handleUpdate = this.handleUpdateStandard;
        this.handleChangeRaw = this.handleChangeRawStandard;

        // 動的項目の場合、動的項目用の初期化、更新ハンドラを使用する
        if (this.props.mode === "dynamic") {
            init = this.initDynamic;
            this.handleUpdate = this.handleUpdateDynamic;
            this.handleChangeRaw = this.handleChangeRawDynamic;
        }
        this.strIsValidDate = true; // true: 入力値が日付として有効
        this.dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"];

        // 初期化
        init();
    }

    /** 通常項目用の初期化処理 */
    initStandard = () => {
        // 先祖から設定された値の検証、設定
        let tmp = this.genPageStateUpdateObjStandard(this.props.value);
        this.props.updatePageState(tmp, false); // 先祖のstateを更新するのでレンダリングが走る
    }

    /** 動的項目用の初期化処理（動的用） */
    initDynamic = () => {
        // 先祖から設定された値の検証、設定
        let tmp = this.genPageStateUpdateObjDynamic(this.props.value);
        this.props.updatePageState(tmp, false); // 先祖のstateを更新するのでレンダリングが走る
    }

    /**
     * 入力欄に更新があった際のハンドラ
     * ※DatePickerコンポーネントに渡しているハンドラであり、onChange始動のハンドラではないので注意
     */
    handleUpdateStandard = date => {

        // 入力された内容が文字列でない場合、ここの処理は飛ばす
        // ※onChangeRawに指定されているハンドラ内で処理を完結させる
        if (!this.strIsValidDate) {
            return;
        }

        // 値の更新（先祖コンポーネントから渡されたstateの更新）
        const val = date === null ? undefined : `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`; // 値
        const tmp = this.genPageStateUpdateObjStandard(val);

        tmp[helper.genValidKey(this.props.name)] = this.validate(tmp[this.props.name]); // 有効化かどうか（true:有効）
        this.props.updatePageState(tmp); // 先祖のstateを更新するのでレンダリングが走る
    };

    /**
     * 入力欄に更新があった際のハンドラ（動的用）
     * ※DatePickerコンポーネントに渡しているハンドラであり、onChange始動のハンドラではないので注意
     */
    handleUpdateDynamic = date => {

        // 入力された内容が文字列でない場合、ここの処理は飛ばす
        // ※onChangeRawに指定されているハンドラ内で処理を完結させる
        if (!this.strIsValidDate) {
            return;
        }

        // 入力された対象を更新
        // 日付入力が空（null）の場合、undefinedへ変換する
        let val = date === null ? undefined : `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`; // 値
        const obj = this.genPageStateUpdateObjDynamic(val);

        // 先祖のstateを更新するのでレンダリングが走る
        this.props.updatePageState(obj);
    };

    /**
     * 入力された文字が日付でない場合、日付としての更新処理を行わない
     * ※
     * 入力値が日付でない場合、DatePickerコンポーネントのonChangeに渡しているハンドラへ
     * 入力値が渡されないため、苦肉の策でここでケアする。。
     */
    handleChangeRawStandard = e => {

        // 日付でない場合、入力された値で直接更新する
        // ※this.strIsValidDate を false にすることで、onChange側では何もしないようにする
        const tmp = this.genPageStateUpdateObjStandard(e.target.value);

        if (!dateUtil.isValidDate(tmp[this.props.name])) {
            this.props.updatePageState(tmp);
            this.strIsValidDate = false;
            return;
        }

        this.strIsValidDate = true;
    }

    /** handleChangeRawStandard() の動的版 */
    handleChangeRawDynamic = e => {

        if (!dateUtil.isValidDate(e.target.value)) {

            const obj = this.genPageStateUpdateObjDynamic(e.target.value);
            this.props.updatePageState(obj);
            this.strIsValidDate = false;
            return;
        }
        this.strIsValidDate = true;
    }

    /** 単項目の値更新用（updatePageState()用）オブジェクトを生成する */
    genPageStateUpdateObjStandard = val => {
        let tmp = {};
        tmp[this.props.name] = val;
        tmp[helper.genValidKey(this.props.name)] = this.validate(tmp[this.props.name]); // 有効化かどうか（true:有効）

        return tmp;
    }

    /** 動的項目の値更新用（updatePageState()用）オブジェクトを生成する */
    genPageStateUpdateObjDynamic = val => {
        const arrayName = this.props.arrayName;
        const arrayNameValid = helper.genValidKey(this.props.arrayName);
        const idx = this.props.idx;
        const name = this.props.name;

        // 更新用の配列をページが保持している値からコピーする
        let obj = Object.assign([], this.props.pageState[arrayName]);
        // 更新用の配列をページが保持している値からコピーする（バリデートチェック用）
        let objValid = Object.assign([], this.props.pageState[arrayNameValid]);

        // 入力値で更新
        obj[idx][name] = val;
        objValid[idx][name] = this.validate(this.props.value);

        return { [arrayName]: obj, [arrayNameValid]: objValid };
    }

    /**
     * バリデートロジックを定義する。
     * true: エラー無し false: エラー有り
     */
    validate = (val) => {
        // 入力無しは許容
        //
        // ※valの状態は下記を前提とする
        // 最初から入力欄が空だった場合はundefined
        // 変更して空にした場合は空文字列
        if (val === undefined) {
            return undefined;
        }

        // 下記のため、基本的にtrue
        // 空入力は許容する
        // 日付以外入力できないようにしている
        return true;
    }

    /**
     * フォーカスアウト時のハンドラ
     * ※フォーカスアウトした際にエラーメッセージを出すかどうかを決める
     */
    handlerBlur = e => {
        if (!this.validate(e.target.value)) {
            this.setState({ errorDisp: true });
        } else {
            this.setState({ errorDisp: false });
        }
    }

    /**
     * 文字列を日付（Date型）に変換
     * 文字列が日付でない場合、undefinedを返却する
     **/
    convToDate = str => {
        let now = undefined;
        if (str !== undefined) {
            const ret = new Date(str);
            if (dateUtil.isValidDate(str)) {
                now = ret;
            }
        }
        return now;
    }

    /**
     * カレンダーのヘッダ部分をカスタマイズする
     */
    renderCustomHeader = ({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
        var startYear = 1912; // カレンダーに表示する最初の西暦（大正元年となる1912を指定）
        var futureListUp = 5; // カレンダーに表示する未来の年数
        var years = Array.from({ length: getYear(new Date()) - startYear + futureListUp }, (v, k) => k + startYear).reverse();
        const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

        return (
            <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>

                {/* 前月ボタン */}
                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>

                {/* 年の部分 */}
                <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} >
                    {years.map((option) => (
                        // eraHandler()で年のプルダウンに元号を付ける
                        <option key={option} value={option}>{option}年（{dateUtil.convToEra(option)}）</option>
                    ))}
                </select>

                {/* 月の部分 */}
                <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))} >
                    {months.map(option => (
                        <option key={option} value={option}> {option}月 </option>
                    ))}
                </select>

                {/* 次月ボタン */}
                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
            </div>
        );
    }

    /** 元号部分のDOM生成 */
    createEraDOM = now => {

        // サフィックス
        let sufix = null;
        if (this.props.until) { sufix = "まで"; } // 末尾に"まで"
        if (this.props.with) { sufix = "付き"; } // 末尾に"付き"

        // 改行有無
        const br = this.props.oneline ? null : <br />; // onelineの場合、改行しない

        if (now === undefined || now === null) {
            return null;

        } else {
            // 元号なし（noEra） の場合、元号、曜日は出さない
            if (this.props.noEra) {
                return null;
            }
            const { dayOfWeek, era } = dateUtil.genEraText(now);

            // 元号のみ（eraOnly） の場合、元号だけ出す
            if (this.props.eraOnly) {
                return <div className="dataTableUnit-text">{era}{sufix}</div>;
            }
            return <div className="dataTableUnit-text">{era}{dayOfWeek}曜日{br}{sufix}</div>;
        }
    }

    render() {
        this.strIsValidDate = true;

        const disabled = this.isDisabled(this.props.pageState.disableItem);

        // バリデートエラーの場合、エラー用のクラスを付与
        // ※初期表示時の未入力部分にはエラーを出さない
        let classname = "dataTableUnit-input";
        if (this.state.errorDisp) {
            classname = "dataTableUnit-input is-error";
        }

        // dataTableUnit-input 部分にクラスを追加する
        classname += ` ${classname} ${this.props.addClassUnitInput}`;

        // 幅の指定
        let inputClass = null;
        if (this.props.length === "w112") { inputClass = "input__w112"; }
        if (this.props.length === "w107") { inputClass = "input__w107"; }
        if (this.props.length === "w108") { inputClass = "input__w108"; }

        // 表示値設定（props.valueが日付でない場合、空（undefined）にする）
        const now = this.convToDate(this.props.value);

        // 元号部分のDOM生成
        const dispItemDOM = this.createEraDOM(now);

        // 元号部分表示のみの場合
        if (this.props.dispOnlyEra) {
            return dispItemDOM;
        }

        let dom = (
            <React.Fragment>
                <div className={classname}>
                    <DatePicker
                        name={this.props.name}
                        autoComplete="off"
                        locale='ja'
                        className={inputClass}
                        selected={now}
                        value={this.props.value}
                        onMonthChange={this.handleUpdate}
                        onYearChange={this.handleUpdate}
                        onChange={this.handleUpdate}
                        onBlur={this.handlerBlur}
                        placeholderText=""
                        dateFormat="yyyy/MM/dd"
                        disabledKeyboardNavigation
                        showMonthDropdown
                        showYearDropdown
                        todayButton="今日"
                        dropdownMode="select"
                        // カレンダーのヘッダ部分をカスタマイズする
                        renderCustomHeader={this.renderCustomHeader}
                        onChangeRaw={this.handleChangeRaw}
                        disabled={disabled}
                    />
                    <span className="dataTableUnit-input-error">
                        <span className="dataTableUnit-input-error_text">{this.errorMessage}</span>
                    </span>
                </div>
                {dispItemDOM}
            </React.Fragment>
        );

        return dom;
    }
};

/** 元号部分のみの表示専用 */
export const Era = props => {
    return <InputDate dispOnlyEra {...props} />;
};
