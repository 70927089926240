// 元ネタ：https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Math/ceil の "10進補正 (Decimal adjustment)"

function decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

/** 四捨五入 */
export const round10 = (value, exp) => {
    return decimalAdjust('round', value, exp);
};

/** 切り捨て */
export const floor10 = (value, exp) => {
    return decimalAdjust('floor', value, exp);
};

/** 切り上げ */
export const ceil10 = (value, exp) => {
    return decimalAdjust('ceil', value, exp);
};

/**
 * 足算関数
 * ※数値じゃないもの（null, undefined, 文字列）は 0 として扱う
 */
export const sum = (a, b) => {

    // isNaN で null が true になったりするのは許容
    // ※ここでは、入力がない場合、数値じゃない場合に 0 として扱ってくれれば良い
    let num1 = Number(a);
    if (isNaN(num1)) { num1 = Number(0); }

    let num2 = Number(b);
    if (isNaN(num2)) { num2 = Number(0); }

    return num1 + num2;
};

/**
 * 引き算関数
 * ※数値じゃないもの（null, undefined, 文字列）は 0 として扱う
 */
export const sub = (a, b) => {

    // isNaN で null が true になったりするのは許容
    // ※ここでは、入力がない場合、数値じゃない場合に 0 として扱ってくれれば良い
    let num1 = Number(a);
    if (isNaN(num1)) { num1 = Number(0); }

    let num2 = Number(b);
    if (isNaN(num2)) { num2 = Number(0); }

    return num1 - num2;
};
