import Input from "components/page/user/contract/edit/input/inputNormal";
import Select from "components/page/user/contract/edit/input/inputSelect";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";

/** 認定手数料配分 */
export default class Block3 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010303",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">認定手数料配分</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="6">売側</th>
                            </tr>

                            <tr>
                                <th className="dataTable-title dataTable-title__w120">担当者①</th>
                                <td className="dataTable-body dataTable-body__w200">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00175" name="TOR00175" value={this.props.pageState.TOR00175} {...params} length="w148" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">手数料配分</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00176" name="TOR00176" value={this.props.pageState.TOR00176} {...params} length="w142" />
                                        <div className="dataTableUnit-text">%</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">税抜手数料</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00177" name="TOR00177" value={this.props.pageState.TOR00177} {...params} length="w139" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th className="dataTable-title dataTable-title__w120">担当者②</th>
                                <td className="dataTable-body dataTable-body__w200">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00178" name="TOR00178" value={this.props.pageState.TOR00178} {...params} length="w148" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">手数料配分</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00179" name="TOR00179" value={this.props.pageState.TOR00179} {...params} length="w143" />
                                        <div className="dataTableUnit-text">%</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">税抜手数料</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00180" name="TOR00180" value={this.props.pageState.TOR00180} {...params} length="w139" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th className="dataTable-title dataTable-title__w120">担当者③</th>
                                <td className="dataTable-body dataTable-body__w200">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00181" name="TOR00181" value={this.props.pageState.TOR00181} {...params} length="w148" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">手数料配分</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00182" name="TOR00182" value={this.props.pageState.TOR00182} {...params} length="w143" />
                                        <div className="dataTableUnit-text">%</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">税抜手数料</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00183" name="TOR00183" value={this.props.pageState.TOR00183} {...params} length="w139" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th className="dataTable-title"><span className="dataTable-title_sub">（支払手数料のうち）</span><br />会社負担金</th>
                                <td className="dataTable-body dataTable-body__w200">
                                    <Select id="ID_TOR00184" name="TOR00184" value={this.props.pageState.TOR00184} items={this.getSelectbox(this.props.selectboxData.MEI00001)} {...params} />
                                </td>
                                <th className="dataTable-title">会社負担<br />割合</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00185" name="TOR00185" value={this.props.pageState.TOR00185} {...params} length="w143" />
                                        <div className="dataTableUnit-text">%</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">会社負担<br />税抜手数料</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00186" name="TOR00186" value={this.props.pageState.TOR00186} {...params} length="w139" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="6">買側</th>
                            </tr>

                            <tr>
                                <th className="dataTable-title dataTable-title__w120">担当者①</th>
                                <td className="dataTable-body dataTable-body__w200">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00187" name="TOR00187" value={this.props.pageState.TOR00187} {...params} length="w148" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">手数料配分</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00188" name="TOR00188" value={this.props.pageState.TOR00188} {...params} length="w142" />
                                        <div className="dataTableUnit-text">%</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">税抜手数料</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00189" name="TOR00189" value={this.props.pageState.TOR00189} {...params} length="w139" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th className="dataTable-title dataTable-title__w120">担当者②</th>
                                <td className="dataTable-body dataTable-body__w200">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00190" name="TOR00190" value={this.props.pageState.TOR00190} {...params} length="w148" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">手数料配分</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00191" name="TOR00191" value={this.props.pageState.TOR00191} {...params} length="w143" />
                                        <div className="dataTableUnit-text">%</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">税抜手数料</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00192" name="TOR00192" value={this.props.pageState.TOR00192} {...params} length="w139" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th className="dataTable-title dataTable-title__w120">担当者③</th>
                                <td className="dataTable-body dataTable-body__w200">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00193" name="TOR00193" value={this.props.pageState.TOR00193} {...params} length="w148" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">手数料配分</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00194" name="TOR00194" value={this.props.pageState.TOR00194} {...params} length="w143" />
                                        <div className="dataTableUnit-text">%</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">税抜手数料</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00195" name="TOR00195" value={this.props.pageState.TOR00195} {...params} length="w139" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th className="dataTable-title"><span className="dataTable-title_sub">（支払手数料のうち）</span><br />会社負担金</th>
                                <td className="dataTable-body dataTable-body__w200">
                                    <Select id="ID_TOR00196" name="TOR00196" value={this.props.pageState.TOR00196} items={this.getSelectbox(this.props.selectboxData.MEI00001)} {...params} />
                                </td>
                                <th className="dataTable-title">会社負担<br />割合</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00197" name="TOR00197" value={this.props.pageState.TOR00197} {...params} length="w143" />
                                        <div className="dataTableUnit-text">%</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">会社負担<br />税抜手数料</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00198" name="TOR00198" value={this.props.pageState.TOR00198} {...params} length="w139" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}