import { PageBase } from "components/page/user/contract/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";

class BasPage3 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     * ※チェックボックスの場合、falseにする
     */
    getPageItems = () => {
        return {
            // 取引報酬額
            // 受入手数料（売側）
            TOR00126: undefined, // 受入手数料（売側） 税抜
            TOR00274: undefined, // 受入手数料（売側） 消費税率
            TOR00127: undefined, // 受入手数料（売側） 消費税等
            TOR00128: undefined, // 受入手数料（売側） 合計
            TOR00129: undefined, // 予定 日付１
            TOR00130: undefined, // 予定 金額（税込）１
            TOR00131: undefined, // 予定 日付２
            TOR00132: undefined, // 予定 金額（税込）２
            TOR00133: undefined, // 実績 日付１
            TOR00134: undefined, // 実績 金額１
            TOR00135: undefined, // 実績 日付２
            TOR00136: undefined, // 実績 金額２
            TOR00278: undefined, // 受入手数料（売側） 予定合計
            TOR00279: undefined, // 受入手数料（売側） 実績合計
            // 受入手数料（買側）
            TOR00137: undefined, // 受入手数料（買側） 税抜
            TOR00275: undefined, // 受入手数料（買側） 消費税率
            TOR00138: undefined, // 受入手数料（買側） 消費税等
            TOR00139: undefined, // 受入手数料（買側） 合計
            TOR00140: undefined, // 予定 日付１
            TOR00141: undefined, // 予定 金額（税込）１
            TOR00142: undefined, // 予定 日付２
            TOR00143: undefined, // 予定 金額（税込）２
            TOR00144: undefined, // 実績 日付１
            TOR00145: undefined, // 実績 金額１
            TOR00146: undefined, // 実績 日付２
            TOR00147: undefined, // 実績 金額２
            TOR00280: undefined, // 受入手数料（買側） 予定合計
            TOR00281: undefined, // 受入手数料（買側） 実績合計
            // 支払手数料（売側）
            TOR00148: undefined, // 支払手数料（売側） 税抜
            TOR00276: undefined, // 支払手数料（売側） 消費税率
            TOR00149: undefined, // 支払手数料（売側） 消費税等
            TOR00150: undefined, // 支払手数料（売側） 合計
            TOR00151: undefined, // 支払先
            TOR00152: undefined, // 予定 日付１
            TOR00153: undefined, // 予定 金額（税込）１
            TOR00154: undefined, // 予定 日付２
            TOR00155: undefined, // 予定 金額（税込）２
            TOR00156: undefined, // 実績 日付１
            TOR00157: undefined, // 実績 金額１
            TOR00158: undefined, // 実績 日付２
            TOR00159: undefined, // 実績 金額２
            TOR00282: undefined, // 支払手数料（売側） 予定合計
            TOR00283: undefined, // 支払手数料（売側） 実績合計
            // 支払手数料（買側）
            TOR00160: undefined, // 支払手数料（買側） 税抜
            TOR00277: undefined, // 支払手数料（買側） 消費税率
            TOR00161: undefined, // 支払手数料（買側） 消費税等
            TOR00162: undefined, // 支払手数料（買側） 合計
            TOR00163: undefined, // 支払先
            TOR00164: undefined, // 予定 日付１
            TOR00165: undefined, // 予定 金額（税込）１
            TOR00166: undefined, // 予定 日付２
            TOR00167: undefined, // 予定 金額（税込）２
            TOR00168: undefined, // 実績 日付１
            TOR00169: undefined, // 実績 金額１
            TOR00170: undefined, // 実績 日付２
            TOR00171: undefined, // 実績 金額２
            TOR00284: undefined, // 支払手数料（買側） 予定合計
            TOR00285: undefined, // 支払手数料（買側） 実績合計

            // 純手数料
            TOR00172: undefined, // 売側
            TOR00173: undefined, // 買側
            TOR00174: undefined, // 合計

            // 認定手数料配分
            // 売側
            TOR00175: undefined, // 担当者１
            TOR00176: undefined, // 手数料配分１
            TOR00177: undefined, // 税抜手数料１
            TOR00178: undefined, // 担当者２
            TOR00179: undefined, // 手数料配分２
            TOR00180: undefined, // 税抜手数料２
            TOR00181: undefined, // 担当者３
            TOR00182: undefined, // 手数料配分３
            TOR00183: undefined, // 税抜手数料３
            TOR00184: undefined, // （支払手数料のうち）会社負担金
            TOR00185: undefined, // 会社負担割合
            TOR00186: undefined, // 会社負担税抜手数料
            // 買側
            TOR00187: undefined, // 担当者１
            TOR00188: undefined, // 手数料配分１
            TOR00189: undefined, // 税抜手数料１
            TOR00190: undefined, // 担当者２
            TOR00191: undefined, // 手数料配分２
            TOR00192: undefined, // 税抜手数料２
            TOR00193: undefined, // 担当者３
            TOR00194: undefined, // 手数料配分３
            TOR00195: undefined, // 税抜手数料３
            TOR00196: undefined, // （支払手数料のうち）会社負担金
            TOR00197: undefined, // 会社負担割合
            TOR00198: undefined, // 会社負担税抜手数料
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            pageSwitch: this.props.pageSwitch,
            taxRoundingMethod: this.props.taxRoundingMethod, // 消費税端数処理（01: 切り捨て, 02: 切り上げ, 03: 四捨五入）
            digitsId: this.props.digitsId, // 消費税端数処理桁数（01: 小数点以下を計算, 02: 1円の桁を計算）
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 取引報酬額 */}
                    <li><Block2 {...params} /></li> {/** 純手数料 */}
                    <li><Block3 {...params} /></li> {/** 認定手数料配分 */}
                </ul>
            </React.Fragment>
        );
    }
}

export default withRouter(BasPage3);