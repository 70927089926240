import React from "react";
import { Link } from "react-router-dom";
import Validation from "util/validate";
import InputCheckbox from "../..//input/inputCheckbox";
import Input from "../../input/inputNormal";
import Select from "../../input/inputSelect";
import InputTextArea from "../../input/inputTextArea";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 買主様 */
export default class Block10 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010110",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
        this.isSame = this.props.pageState.TOR00353;
    }

    /** 削除ボタンのハンドラ */
    handleDeleteTOR00094 = e => {
        this.handleDelete(e, "TOR00094_ARR");
    }

    /** 追加ボタンのハンドラ */
    handleAddTOR00094 = e => {

        const addObj = {
            TOR00094: undefined, // 買主 氏名
            TOR00095: undefined, // 買主 住所
            TOR00098: undefined, // 買主 電話番号
        };

        this.handleAdd(e, "TOR00094_ARR", addObj);
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 文字数制限定義
        const TOR00352LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "TOR00352",
                this.props.pageState.TOR00352);
        const TOR00354LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "TOR00354",
                this.props.pageState.TOR00354);

        // 動的項目の共通パラメータ
        const arrayName = "TOR00094_ARR"; // 動的項目の代表名
        const paramsForDynamic = {
            ...params,
            arrayName: arrayName,
            mode: "dynamic",
        };

        // ページが持っている動的項目の値を取得する
        const data = this.props.pageState[arrayName];

        // 項目が上限数に達したら、追加ボタンを削除する
        let noDisplay;
        if (data.length >= 3) {
            noDisplay = "nodisplay";
        }

        const items = [];

        // 2行目以降の繰り返し部分の出力
        data.forEach((obj, idx) => {
            // 1行目は別でDOMを生成しているので飛ばす
            // 合計３つまでしか表示しないので、idxが3以上なら飛ばす
            if (idx === 0 || idx >= 3) return;

            items.push(
                <React.Fragment key={idx}>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 is-emphasis">
                            <div className="dataTable-title-vr">{idx + 1}</div>
                            <div className="dataTable-title-vr">
                                <Link to="" id={`LINK_DEL_TOR00094_ARR_${idx}`} aria-label="link" data-idx={idx} data-array-name={arrayName} className="baseAction baseAction--minus baseAction__w40" onClick={this.handleDeleteTOR00094}>
                                    <span data-idx={idx} className="baseAction-text">削除</span>
                                </Link>
                            </div>
                        </th>
                        <th className="dataTable-title dataTable-title__w120">氏名</th>
                        <td className="dataTable-body">
                            <div className="dataTableUnit">
                                <Input idx={idx} id={`ID_TOR00094_${idx}`} name="TOR00094" value={this.excludeBlanks(obj.TOR00094)} {...paramsForDynamic} length="w483" />
                                <Select idx={idx} id={`ID_TOR00334_${idx}`} name="TOR00334" value={this.excludeBlanks(obj.TOR00334)} items={this.getSelectbox(this.props.selectboxData.MEI00010)} {...paramsForDynamic} length="w120" />
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            );
        });

        // 重説の内容を反映をチェック時の設定
        if (!this.isSame && this.props.pageState.TOR00353) {
            this.isSame = true;
            this.props.updatePageState(this.genUpdateInformation("TOR00354", this.props.pageState.TOR00352));
        }

        // チェックが外れたらフラグをfalseにする
        if (!this.props.pageState.TOR00353) {
            this.isSame = false;
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">買主様</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 is-emphasis">1</th>
                                <th className="dataTable-title dataTable-title__w120">氏名</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input idx={0} id={`ID_TOR00094_${0}`} name="TOR00094" value={this.excludeBlanks(data[0].TOR00094)} {...paramsForDynamic} length="w483" />
                                        <Select idx={0} id={`ID_TOR00334_${0}`} name="TOR00334" value={this.excludeBlanks(data[0].TOR00334)} items={this.getSelectbox(this.props.selectboxData.MEI00010)} {...paramsForDynamic} length="w120" />
                                    </div>
                                </td>
                            </tr>
                            {items}
                            <tr className={noDisplay}>
                                <td className="dataTable-body dataTable-body__center" colSpan="3">
                                    <Link to="" id="LINK_ADD_TOR00094_ARR" aria-label="link" data-array-name={arrayName} className="baseAction baseAction--plus baseAction__w40" onClick={this.handleAddTOR00094}>
                                        <span className="baseAction-text">追加</span>
                                    </Link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240">重要事項説明書署名<br />（住所欄）</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit-text">※署名欄の住所等を印字する場合は、以下に記載</div>
                                    <InputTextArea id="ID_TOR00352" name="TOR00352" cols={70} rows={3} height="h92" value={this.props.pageState.TOR00352} placeholder={TOR00352LimitMsg.inputLimitGuide} {...params} />
                                    <p className="LengthOver-error">{TOR00352LimitMsg.inputLimitMsg}</p>
                                    <div className="dataTableUnit-text">※記載箇所イメージ図</div>
                                    <img src="\img\page0101\buyer\jusetu_buyer_sign.png" alt="重要事項説明書 買主署名イメージ" />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240">
                                    <div className="dataTableUnit-text">不動産売買契約書署名<br />（住所欄）</div>
                                    <ul className="l-dataList">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_TOR00353" name="TOR00353" checked={this.props.pageState.TOR00353} {...params} />
                                                <span className="checkBox-text">重説の内容を反映</span>
                                            </label>
                                        </li>
                                    </ul>
                                </th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit-text">※署名欄の住所等を印字する場合は、以下に記載</div>
                                    <InputTextArea id="ID_TOR00354" name="TOR00354" cols={70} rows={3} height="h92" value={this.props.pageState.TOR00354} placeholder={TOR00354LimitMsg.inputLimitGuide} {...params} />
                                    <p className="LengthOver-error">{TOR00354LimitMsg.inputLimitMsg}</p>
                                    <div className="dataTableUnit-text">※記載箇所イメージ図</div>
                                    <img src="\img\page0101\buyer\baikei_buyer_sign.png" alt="売買契約書 買主署名イメージ" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}