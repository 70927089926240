import BaseGrid from "components/common/frame/baseGrid";
import { RegisterButtonRecord } from "components/common/parts/buttonRecord";
import { InputRadioBoxRecord, InputTextareaRecord } from "components/common/parts/inputRecord";
import * as AWS from "constants/aws";
import { DISPLAY_TYPES } from "constants/defaultInputValueTypes";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { withRouter } from "react-router";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";
import Validation from "util/validate";

/** デフォルト表記メンテナンス画面 */
class DefaultInputValueMaintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayType: DISPLAY_TYPES.LAND.value
        };

        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();

        this.apiCalling = false; // true: 登録 or 更新APIコール中
        this.apiCallingGet = ""; // "": APIコールなし、"01": "土地"をAPIコール中、...
    }

    componentDidMount() {
        document.title = pageInfo.DEFAULT_INPUT_VALUE_MAINTENANCE_UPDATE.title;

        // パスパラメータを基に、取得APIを呼出し、レスポンスデータを入力ボックスの入力値を更新する
        this.requestDefaultInputValues(this.state.displayType);
    }

    componentDidUpdate() {
        // 取得APIのレスポンスデータで更新されていない場合、取得APIを呼出し、レスポンスデータを入力ボックスの入力値を更新する
        if (this.state.data === undefined && this.apiCallingGet !== this.state.displayType) {
            this.requestDefaultInputValues(this.state.displayType);
        }
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputText = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    handleChangeRadio = e => {
        this.handleChangeInputText(e);
        this.setState({ data: undefined });
    }

    handleChangeInputTextarea = (e, idx) => {
        const obj = this.state.data;
        let tmp = JSON.parse(JSON.stringify(obj));
        tmp.defaultValues[idx].text = e.target.value;
        this.setState({ data: tmp });
    }

    handleChangeBlurTextarea = e => {
        this.setState({});
    }

    /** 登録ボタンのハンドラ */
    handleRegisterDefaultInputValues = e => {
        e.preventDefault();
        this.registertDefaultInputValues();
    }

    render() {
        // 入力レコードの属性情報
        const bodySetting = { colSpan: "2" };

        // 入力ボックスレコードのリストDom
        const defaultInputValuesList = this.state.data === undefined ? [] : this.state.data.defaultValues;
        const defaultInputValuesDom = defaultInputValuesList
            ? defaultInputValuesList.map((item, idx) => (
                <tr key={item.defaultValueId}>
                    <InputTextareaRecord titleName={item.defaultValueName}
                        inputData={{ name: item.defaultValueId, value: item.text || "" }}
                        titleSetting={{ className: "dataTable-title dataTable-title__w120" }}
                        bodySetting={bodySetting}
                        inputSetting={{
                            onChange: e => { this.handleChangeInputTextarea(e, idx); },
                            onBlur: this.handleChangeBlurTextarea,
                            cols: "10", rows: "5"
                        }}
                        errorMessage={this.validation.errorMessage[item.defaultValueId] || ""}
                    />
                </tr>
            ))
            : null;

        // 表記タイプをラジオレコード用に加工
        const radioDataList = Object.keys(DISPLAY_TYPES).map(key => ({ value: DISPLAY_TYPES[key].value, display: DISPLAY_TYPES[key].display }));

        const registerActive = !this.apiCalling && this.apiCallingGet === "" ? true : false;
        const registerSetting = registerActive
            ? { onClick: this.handleRegisterDefaultInputValues }
            : { onClick: e => { e.preventDefault(); } };

        return (
            <BaseGrid>
                <div className="dataList dataList__accordion is-open">
                    <div className="dataList-header">デフォルト表記メンテナンス</div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <InputRadioBoxRecord noTitle
                                        inputData={{ name: "displayType", value: this.state.displayType || "" }}
                                        radioDataList={radioDataList} bodySetting={bodySetting} inputSetting={{ onChange: this.handleChangeRadio }} />
                                </tr>

                                {defaultInputValuesDom}
                            </tbody>
                        </table>
                    </div>
                </div>

                <RegisterButtonRecord inActive={!registerActive} registerSetting={registerSetting} />
            </BaseGrid>
        );
    }

    /** デフォルト表記を取得し、stateに格納する */
    requestDefaultInputValues = displayType => {
        this.apiCallingGet = displayType;
        this.setState({});

        awsReq.get(
            AWS.ENDPOINT.PATHS.DEFAULT_INPUT_VALUES,
            response => {
                this.apiCallingGet = "";
                const camelResponseData = convert.convertToFlatCamelCase(response.data);

                // stateで管理する入力ボックスの入力値を取り出す
                var inputData = {};
                camelResponseData.defaultValues.forEach(item => {
                    inputData[item.defaultValueId] = item.text;
                });

                this.validation.errorMessage = {}; // バリデート結果をリセットする
                this.setState({ data: camelResponseData });
            },
            error => {
                alert(ERROR_MESSAGE.SERVER_ERROR);
                this.apiCallingGet = "";
                this.setState({}); // 再renderする為に、stateを空更新
            },
            { display_type: displayType },
            {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    registertDefaultInputValues = () => {
        this.apiCalling = true;
        this.setState({});

        // リクエストパラメータを作成
        // 入力画面にある入力値のみをリクエストパラメータとする
        const defaultValuesList = this.state.data.defaultValues;
        const requestBodyList = defaultValuesList.map(item => ({
            "default_value_id": item.defaultValueId,
            "text": item.text,
        }));
        const requestBody = {
            display_type_id: this.state.displayType,
            default_values: requestBodyList,
        };

        awsReq.put(
            AWS.ENDPOINT.PATHS.DEFAULT_INPUT_VALUES,
            () => {
                this.apiCalling = false;
                this.setState({ data: undefined });
            },
            error => {
                alert(ERROR_MESSAGE.SERVER_ERROR);
                this.apiCalling = false;
                this.setState({}); // 再renderする為に、stateを空更新
            },
            requestBody,
            {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }
}

export default withRouter(DefaultInputValueMaintenance);
