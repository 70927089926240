/**
 *  ＦＲＫ書式
 * 書式毎に必要な項目を定義
 * 　・書式ID：書式ID
 * 　・ページID：契約書入力情報に対応するページID
 * 　・項目ID：入力内容のID
 * 　・配列ID：入力内容の配列ID
 * 　・初期値：初期値が必要な場合の値(現在、利用なし)
 * 　◆形式
 * 　　{
 * 　　　書式ID: {
 * 　　     ページID: 
 * 　　　       {項目ID:初期値, 
 * 　　　        配列ID:{項目ID:初期値}
 * 　　         }
 * 　　     }
 * 　　}
 */
export const REQUIRED_ITEM_LIST = {
    // 0101_FRK一般仲介用・土地（実測・清算）
    '0101': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00296': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '',
            'JYU00327': '', 'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '',
            'JYU01579': '', 'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01113': '',
            'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '',
            'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '',
            'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '',
            'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '', 'JYU01215': '',
            'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01446': '', 'JYU01449': '',
            'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU00787': '', 'JYU00788': '', 'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '',
            'JYU01034': '', 'JYU01457': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '',
            'JYU01553': '', 'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '', 'JYU01760': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '', 'JYU01776': '', 'JYU01777': '',
        },
        '0301': {
            'FUD00135': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0102_FRK一般仲介用・土地（確定測量・清算）
    '0102': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00296': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '',
            'JYU00327': '', 'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '',
            'JYU01579': '', 'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01113': '',
            'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '',
            'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '',
            'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '',
            'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '', 'JYU01215': '',
            'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01446': '', 'JYU01449': '',
            'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU00787': '', 'JYU00788': '', 'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '',
            'JYU01034': '', 'JYU01457': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '',
            'JYU01553': '', 'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '', 'JYU01760': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '', 'JYU01776': '', 'JYU01777': '',
        },
        '0301': {
            'FUD00135': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0103_FRK一般仲介用・土地（売買代金固定）
    '0103': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00296': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '',
            'JYU00327': '', 'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '',
            'JYU01579': '', 'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01113': '',
            'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '',
            'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '',
            'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '',
            'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '', 'JYU01215': '',
            'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01446': '', 'JYU01449': '',
            'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU00787': '', 'JYU00788': '', 'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '',
            'JYU01034': '', 'JYU01457': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '',
            'JYU01553': '', 'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '', 'JYU01760': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '', 'JYU01776': '', 'JYU01777': '',
        },
        '0301': {
            'FUD00135': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0104_FRK一般仲介用・土地建物（実測・清算）
    '0104': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00258': '', 'TOR00259': '', 'TOR00260': '', 'TOR00261': '', 'TOR00262': '',
            'TOR00316': '', 'TOR00317': '', 'TOR00318': '', 'TOR00319': '', 'TOR00320': '', 'TOR00321': '', 'TOR00322': '',
            'TOR00323': '', 'TOR00324': '', 'TOR00325': '', 'TOR00326': '', 'TOR00327': '', 'TOR00328': '', 'TOR00329': '',
            'TOR00330': '', 'TOR00331': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '', 'TOR00355': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '', 'JYU00295': '',
            'JYU00296': '', 'JYU00322': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '',
            'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '', 'JYU01579': '',
            'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '', 'JYU01789': '',
        },
        '0203': {
            'JYU00341': '', 'JYU00343': '', 'JYU00344': '', 'JYU00345': '', 'JYU00352': '', 'JYU00353': '', 'JYU00359': '',
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01279': '', 'JYU01280': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
            'JYU01771': '', 'JYU01772': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01113': '',
            'JYU01117': '', 'JYU01118': '', 'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '',
            'JYU01128': '', 'JYU01132': '', 'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '',
            'JYU01138': '', 'JYU01139': '', 'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '',
            'JYU01146': '', 'JYU01200': '', 'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '',
            'JYU01214': '', 'JYU01215': '', 'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '',
            'JYU01446': '', 'JYU01447': '', 'JYU01449': '', 'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '',
            'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00135': '', 'FUD00278': '', 'FUD00281': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0105_FRK一般仲介用・土地建物（確定測量・清算）
    '0105': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00258': '', 'TOR00259': '', 'TOR00260': '', 'TOR00261': '', 'TOR00262': '',
            'TOR00316': '', 'TOR00317': '', 'TOR00318': '', 'TOR00319': '', 'TOR00320': '', 'TOR00321': '', 'TOR00322': '',
            'TOR00323': '', 'TOR00324': '', 'TOR00325': '', 'TOR00326': '', 'TOR00327': '', 'TOR00328': '', 'TOR00329': '',
            'TOR00330': '', 'TOR00331': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '', 'TOR00355': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '', 'JYU00295': '',
            'JYU00296': '', 'JYU00322': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '',
            'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '', 'JYU01579': '',
            'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '', 'JYU01789': '',
        },
        '0203': {
            'JYU00341': '', 'JYU00343': '', 'JYU00344': '', 'JYU00345': '', 'JYU00352': '', 'JYU00353': '', 'JYU00359': '',
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01279': '', 'JYU01280': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
            'JYU01771': '', 'JYU01772': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01113': '',
            'JYU01117': '', 'JYU01118': '', 'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '',
            'JYU01128': '', 'JYU01132': '', 'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '',
            'JYU01138': '', 'JYU01139': '', 'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '',
            'JYU01146': '', 'JYU01200': '', 'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '',
            'JYU01214': '', 'JYU01215': '', 'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '',
            'JYU01446': '', 'JYU01447': '', 'JYU01449': '', 'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '',
            'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00135': '', 'FUD00278': '', 'FUD00281': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0106_FRK一般仲介用・土地建物（売買代金固定）
    '0106': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00258': '', 'TOR00259': '', 'TOR00260': '', 'TOR00261': '', 'TOR00262': '',
            'TOR00316': '', 'TOR00317': '', 'TOR00318': '', 'TOR00319': '', 'TOR00320': '', 'TOR00321': '', 'TOR00322': '',
            'TOR00323': '', 'TOR00324': '', 'TOR00325': '', 'TOR00326': '', 'TOR00327': '', 'TOR00328': '', 'TOR00329': '',
            'TOR00330': '', 'TOR00331': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '', 'TOR00355': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '', 'JYU00295': '',
            'JYU00296': '', 'JYU00322': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '',
            'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '', 'JYU01579': '',
            'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '', 'JYU01789': '',
        },
        '0203': {
            'JYU00341': '', 'JYU00343': '', 'JYU00344': '', 'JYU00345': '', 'JYU00352': '', 'JYU00353': '', 'JYU00359': '',
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01279': '', 'JYU01280': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
            'JYU01771': '', 'JYU01772': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01113': '',
            'JYU01117': '', 'JYU01118': '', 'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '',
            'JYU01128': '', 'JYU01132': '', 'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '',
            'JYU01138': '', 'JYU01139': '', 'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '',
            'JYU01146': '', 'JYU01200': '', 'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '',
            'JYU01214': '', 'JYU01215': '', 'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '',
            'JYU01446': '', 'JYU01447': '', 'JYU01449': '', 'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '',
            'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00135': '', 'FUD00278': '', 'FUD00281': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0107_FRK一般仲介用・区分所有建物（敷地権）
    '0107': {
        '0101': {
            'TOR00205': '', 'TOR00206': '', 'TOR00207': '', 'TOR00208': '', 'TOR00209': '', 'TOR00210': '', 'TOR00211': '',
            'TOR00212': '', 'TOR00213': '', 'TOR00214': '', 'TOR00215': '', 'TOR00216': '', 'TOR00235': '', 'TOR00236': '',
            'TOR00300': '', 'TOR00301': '', 'TOR00302': '', 'TOR00306': '', 'TOR00307': '', 'TOR00308': '', 'TOR00309': '',
            'TOR00310': '', 'TOR00311': '', 'TOR00312': '', 'TOR00314': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '',
            'TOR00352': '', 'TOR00353': '', 'TOR00354': '', 'TOR00356': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00217_ARR': { 'TOR00217': '', 'TOR00218': '', 'TOR00219': '', 'TOR00221': '', 'TOR00315': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00147': '', 'JYU00194': '', 'JYU00195': '', 'JYU00196': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '',
            'JYU00326': '', 'JYU00327': '', 'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '',
            'JYU01455': '', 'JYU01577': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01750': '', 'JYU01778': '', 'JYU01779': '', 'JYU01780': '', 'JYU01781': '',
            'JYU01782': '', 'JYU01783': '', 'JYU01784': '', 'JYU01785': '', 'JYU01786': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00537': '', 'JYU00538': '',
            'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00543': '', 'JYU00544': '', 'JYU00545': '', 'JYU00546': '',
            'JYU00547': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '', 'JYU00552': '', 'JYU00553': '', 'JYU00554': '',
            'JYU00555': '', 'JYU00556': '', 'JYU00557': '', 'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00566': '',
            'JYU00567': '', 'JYU00568': '', 'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '',
            'JYU00577': '', 'JYU00578': '', 'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '',
            'JYU01329': '', 'JYU01330': '', 'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '',
            'JYU01336': '', 'JYU01337': '', 'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '',
            'JYU01343': '', 'JYU01432': '', 'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '',
            'JYU01593': '', 'JYU01594': '', 'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '',
            'JYU01600': '', 'JYU01601': '', 'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '',
            'JYU01608': '', 'JYU01609': '', 'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '',
            'JYU01646': '', 'JYU01647': '', 'JYU01648': '', 'JYU01649': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00714': '', 'JYU00716': '', 'JYU00718': '', 'JYU00721': '',
            'JYU00723': '', 'JYU00720': '', 'JYU00731': '', 'JYU00732': '', 'JYU00733': '', 'JYU00741': '', 'JYU00743': '',
            'JYU00745': '', 'JYU00746': '', 'JYU00747': '', 'JYU00749': '', 'JYU00751': '', 'JYU00785': '', 'JYU01387': '',
            'JYU01392': '', 'JYU01393': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01618': '', 'JYU01622': '',
            'JYU01623': '', 'JYU01624': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01628': '', 'JYU01629': '',
            'JYU01630': '', 'JYU01631': '', 'JYU01632': '', 'JYU01633': '', 'JYU01634': '',
        },
        '0208': {
            'JYU00790': '', 'JYU00791': '', 'JYU00792': '', 'JYU00793': '', 'JYU00794': '', 'JYU00795': '', 'JYU00796': '',
            'JYU00797': '', 'JYU00798': '', 'JYU00799': '', 'JYU00800': '', 'JYU00801': '', 'JYU00804': '', 'JYU00805': '',
            'JYU00809': '', 'JYU00810': '', 'JYU00812': '', 'JYU00813': '', 'JYU00814': '', 'JYU00816': '', 'JYU00817': '',
            'JYU00818': '', 'JYU00819': '', 'JYU00820': '', 'JYU00821': '', 'JYU00822': '', 'JYU00823': '', 'JYU00824': '',
            'JYU00825': '', 'JYU00826': '', 'JYU00827': '', 'JYU00828': '', 'JYU00829': '', 'JYU00830': '', 'JYU00833': '',
            'JYU01401': '', 'JYU01402': '', 'JYU01407': '', 'JYU01413': '', 'JYU01418': '', 'JYU01657': '', 'JYU01658': '',
            'JYU01659': '', 'JYU01660': '', 'JYU01661': '', 'JYU01662': '', 'JYU01773': '', 'JYU01774': '',
        },
        '0209': {
            'JYU00835': '', 'JYU00836': '', 'JYU00837': '', 'JYU00838': '', 'JYU00839': '', 'JYU00840': '', 'JYU00841': '',
            'JYU00842': '', 'JYU00843': '', 'JYU00844': '', 'JYU00845': '', 'JYU00846': '', 'JYU00847': '', 'JYU00848': '',
            'JYU00849': '', 'JYU00850': '', 'JYU00851': '', 'JYU00852': '', 'JYU00853': '', 'JYU00854': '', 'JYU00855': '',
            'JYU00856': '', 'JYU00857': '', 'JYU00858': '', 'JYU00859': '', 'JYU00860': '', 'JYU00861': '', 'JYU00862': '',
            'JYU00863': '', 'JYU00864': '', 'JYU00865': '', 'JYU00866': '', 'JYU00867': '', 'JYU00868': '', 'JYU00869': '',
            'JYU00870': '', 'JYU00871': '', 'JYU00872': '', 'JYU00873': '', 'JYU00874': '', 'JYU00875': '', 'JYU00876': '',
            'JYU00877': '', 'JYU00878': '', 'JYU00879': '', 'JYU00880': '', 'JYU00881': '', 'JYU00882': '', 'JYU00883': '',
            'JYU00884': '', 'JYU00885': '', 'JYU00886': '', 'JYU00887': '', 'JYU00888': '', 'JYU00889': '', 'JYU00890': '',
            'JYU00891': '', 'JYU00892': '', 'JYU00893': '', 'JYU00894': '', 'JYU00895': '', 'JYU00896': '', 'JYU00897': '',
            'JYU00898': '', 'JYU00899': '', 'JYU00901': '', 'JYU00902': '', 'JYU00903': '', 'JYU00904': '', 'JYU00905': '',
            'JYU00906': '', 'JYU00907': '', 'JYU00908': '', 'JYU00909': '', 'JYU00910': '', 'JYU00911': '', 'JYU00912': '',
            'JYU00913': '', 'JYU00914': '', 'JYU00915': '', 'JYU00916': '', 'JYU00917': '', 'JYU00918': '', 'JYU00934': '',
            'JYU00935': '', 'JYU00936': '', 'JYU00937': '', 'JYU01308': '', 'JYU01309': '', 'JYU01310': '', 'JYU01311': '',
            'JYU01312': '', 'JYU01313': '', 'JYU01314': '', 'JYU01315': '', 'JYU01316': '', 'JYU01317': '', 'JYU01318': '',
            'JYU01319': '', 'JYU01320': '', 'JYU01321': '', 'JYU01322': '', 'JYU01323': '', 'JYU01324': '', 'JYU01325': '',
            'JYU01326': '', 'JYU01663': '', 'JYU01791': '',
            'JYU00919_ARR': { 'JYU00919': '', 'JYU00920': '', },
        },
        '0210': {
            'JYU00938': '', 'JYU00939': '', 'JYU00940': '', 'JYU00941': '', 'JYU00942': '', 'JYU00943': '', 'JYU00951': '',
            'JYU00952': '', 'JYU00953': '', 'JYU00954': '', 'JYU00955': '', 'JYU00956': '', 'JYU00958': '', 'JYU00959': '',
            'JYU00960': '', 'JYU00961': '', 'JYU00962': '', 'JYU00963': '', 'JYU00964': '', 'JYU00965': '', 'JYU00967': '',
            'JYU00968': '', 'JYU00969': '', 'JYU00970': '', 'JYU00971': '', 'JYU00972': '', 'JYU00973': '', 'JYU00975': '',
            'JYU00976': '', 'JYU00977': '', 'JYU00978': '', 'JYU00979': '', 'JYU01301': '', 'JYU01302': '', 'JYU01303': '',
            'JYU01304': '', 'JYU01305': '', 'JYU01451': '', 'JYU01452': '', 'JYU01664': '', 'JYU01665': '', 'JYU01666': '',
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '',
            'JYU01118': '', 'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '',
            'JYU01132': '', 'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '',
            'JYU01139': '', 'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '',
            'JYU01200': '', 'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '',
            'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
            'JYU01481': '', 'JYU01482': '', 'JYU01483': '', 'JYU01484': '', 'JYU01485': '', 'JYU01486': '', 'JYU01487': '',
            'JYU01488': '', 'JYU01489': '', 'JYU01490': '', 'JYU01491': '', 'JYU01492': '', 'JYU01493': '', 'JYU01494': '',
            'JYU01495': '', 'JYU01496': '', 'JYU01503': '', 'JYU01504': '', 'JYU01505': '', 'JYU01506': '', 'JYU01507': '',
            'JYU01508': '', 'JYU01509': '', 'JYU01510': '', 'JYU01511': '', 'JYU01512': '', 'JYU01513': '', 'JYU01514': '',
            'JYU01515': '', 'JYU01516': '', 'JYU01517': '', 'JYU01518': '', 'JYU01519': '', 'JYU01520': '', 'JYU01521': '',
            'JYU01522': '', 'JYU01523': '', 'JYU01524': '', 'JYU01525': '', 'JYU01526': '', 'JYU01527': '', 'JYU01528': '',
            'JYU01529': '', 'JYU01530': '', 'JYU01531': '', 'JYU01532': '', 'JYU01533': '', 'JYU01534': '', 'JYU01535': '',
            'JYU01536': '', 'JYU01537': '', 'JYU01538': '', 'JYU01539': '', 'JYU01540': '', 'JYU01543': '', 'JYU01544': '',
            'JYU01545': '', 'JYU01546': '', 'JYU01547': '', 'JYU01765': '', 'JYU01766': '', 'JYU01775': '',
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00066': '', 'FUD00067': '', 'FUD00070': '', 'FUD00072': '', 'FUD00073': '', 'FUD00075': '', 'FUD00135': '',
            'FUD00278': '', 'FUD00281': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '', 'FUD00379': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0108_FRK一般仲介用・区分所有建物（非敷地権）
    '0108': {
        '0101': {
            'TOR00205': '', 'TOR00206': '', 'TOR00207': '', 'TOR00208': '', 'TOR00209': '', 'TOR00210': '', 'TOR00211': '',
            'TOR00212': '', 'TOR00213': '', 'TOR00214': '', 'TOR00215': '', 'TOR00216': '', 'TOR00256': '', 'TOR00257': '',
            'TOR00300': '', 'TOR00301': '', 'TOR00302': '', 'TOR00306': '', 'TOR00307': '', 'TOR00308': '', 'TOR00309': '',
            'TOR00310': '', 'TOR00311': '', 'TOR00312': '', 'TOR00314': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '',
            'TOR00352': '', 'TOR00353': '', 'TOR00354': '', 'TOR00356': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00147': '', 'JYU00194': '', 'JYU00195': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '',
            'JYU00296': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '', 'JYU00328': '',
            'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01455': '', 'JYU01577': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01750': '', 'JYU01778': '', 'JYU01779': '', 'JYU01780': '', 'JYU01781': '',
            'JYU01782': '', 'JYU01783': '', 'JYU01784': '', 'JYU01785': '', 'JYU01786': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00537': '', 'JYU00538': '',
            'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00543': '', 'JYU00544': '', 'JYU00545': '', 'JYU00546': '',
            'JYU00547': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '', 'JYU00552': '', 'JYU00553': '', 'JYU00554': '',
            'JYU00555': '', 'JYU00556': '', 'JYU00557': '', 'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00566': '',
            'JYU00567': '', 'JYU00568': '', 'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '',
            'JYU00577': '', 'JYU00578': '', 'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '',
            'JYU01329': '', 'JYU01330': '', 'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '',
            'JYU01336': '', 'JYU01337': '', 'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '',
            'JYU01343': '', 'JYU01432': '', 'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '',
            'JYU01593': '', 'JYU01594': '', 'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '',
            'JYU01600': '', 'JYU01601': '', 'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '',
            'JYU01608': '', 'JYU01609': '', 'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '',
            'JYU01646': '', 'JYU01647': '', 'JYU01648': '', 'JYU01649': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00714': '', 'JYU00716': '', 'JYU00718': '', 'JYU00721': '',
            'JYU00723': '', 'JYU00720': '', 'JYU00731': '', 'JYU00732': '', 'JYU00733': '', 'JYU00741': '', 'JYU00743': '',
            'JYU00745': '', 'JYU00746': '', 'JYU00747': '', 'JYU00749': '', 'JYU00751': '', 'JYU00785': '', 'JYU01387': '',
            'JYU01392': '', 'JYU01393': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01618': '', 'JYU01622': '',
            'JYU01623': '', 'JYU01624': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01628': '', 'JYU01629': '',
            'JYU01630': '', 'JYU01631': '', 'JYU01632': '', 'JYU01633': '', 'JYU01634': '',
        },
        '0208': {
            'JYU00790': '', 'JYU00791': '', 'JYU00792': '', 'JYU00793': '', 'JYU00794': '', 'JYU00795': '', 'JYU00796': '',
            'JYU00797': '', 'JYU00798': '', 'JYU00799': '', 'JYU00800': '', 'JYU00801': '', 'JYU00804': '', 'JYU00805': '',
            'JYU00809': '', 'JYU00810': '', 'JYU00812': '', 'JYU00813': '', 'JYU00814': '', 'JYU00816': '', 'JYU00817': '',
            'JYU00818': '', 'JYU00819': '', 'JYU00820': '', 'JYU00821': '', 'JYU00822': '', 'JYU00823': '', 'JYU00824': '',
            'JYU00825': '', 'JYU00826': '', 'JYU00827': '', 'JYU00828': '', 'JYU00829': '', 'JYU00830': '', 'JYU00833': '',
            'JYU01401': '', 'JYU01402': '', 'JYU01407': '', 'JYU01413': '', 'JYU01418': '', 'JYU01657': '', 'JYU01658': '',
            'JYU01659': '', 'JYU01660': '', 'JYU01661': '', 'JYU01662': '', 'JYU01773': '', 'JYU01774': '',
        },
        '0209': {
            'JYU00835': '', 'JYU00836': '', 'JYU00837': '', 'JYU00838': '', 'JYU00839': '', 'JYU00840': '', 'JYU00841': '',
            'JYU00842': '', 'JYU00843': '', 'JYU00844': '', 'JYU00845': '', 'JYU00846': '', 'JYU00847': '', 'JYU00848': '',
            'JYU00849': '', 'JYU00850': '', 'JYU00851': '', 'JYU00852': '', 'JYU00853': '', 'JYU00854': '', 'JYU00855': '',
            'JYU00856': '', 'JYU00857': '', 'JYU00858': '', 'JYU00859': '', 'JYU00860': '', 'JYU00861': '', 'JYU00862': '',
            'JYU00863': '', 'JYU00864': '', 'JYU00865': '', 'JYU00866': '', 'JYU00867': '', 'JYU00868': '', 'JYU00869': '',
            'JYU00870': '', 'JYU00871': '', 'JYU00872': '', 'JYU00873': '', 'JYU00874': '', 'JYU00875': '', 'JYU00876': '',
            'JYU00877': '', 'JYU00878': '', 'JYU00879': '', 'JYU00880': '', 'JYU00881': '', 'JYU00882': '', 'JYU00883': '',
            'JYU00884': '', 'JYU00885': '', 'JYU00886': '', 'JYU00887': '', 'JYU00888': '', 'JYU00889': '', 'JYU00890': '',
            'JYU00891': '', 'JYU00892': '', 'JYU00893': '', 'JYU00894': '', 'JYU00895': '', 'JYU00896': '', 'JYU00897': '',
            'JYU00898': '', 'JYU00899': '', 'JYU00901': '', 'JYU00902': '', 'JYU00903': '', 'JYU00904': '', 'JYU00905': '',
            'JYU00906': '', 'JYU00907': '', 'JYU00908': '', 'JYU00909': '', 'JYU00910': '', 'JYU00911': '', 'JYU00912': '',
            'JYU00913': '', 'JYU00914': '', 'JYU00915': '', 'JYU00916': '', 'JYU00917': '', 'JYU00918': '', 'JYU00934': '',
            'JYU00935': '', 'JYU00936': '', 'JYU00937': '', 'JYU01308': '', 'JYU01309': '', 'JYU01310': '', 'JYU01311': '',
            'JYU01312': '', 'JYU01313': '', 'JYU01314': '', 'JYU01315': '', 'JYU01316': '', 'JYU01317': '', 'JYU01318': '',
            'JYU01319': '', 'JYU01320': '', 'JYU01321': '', 'JYU01322': '', 'JYU01323': '', 'JYU01324': '', 'JYU01325': '',
            'JYU01326': '', 'JYU01663': '', 'JYU01791': '',
            'JYU00919_ARR': { 'JYU00919': '', 'JYU00920': '', },
        },
        '0210': {
            'JYU00938': '', 'JYU00939': '', 'JYU00940': '', 'JYU00941': '', 'JYU00942': '', 'JYU00943': '', 'JYU00951': '',
            'JYU00952': '', 'JYU00953': '', 'JYU00954': '', 'JYU00955': '', 'JYU00956': '', 'JYU00958': '', 'JYU00959': '',
            'JYU00960': '', 'JYU00961': '', 'JYU00962': '', 'JYU00963': '', 'JYU00964': '', 'JYU00965': '', 'JYU00967': '',
            'JYU00968': '', 'JYU00969': '', 'JYU00970': '', 'JYU00971': '', 'JYU00972': '', 'JYU00973': '', 'JYU00975': '',
            'JYU00976': '', 'JYU00977': '', 'JYU00978': '', 'JYU00979': '', 'JYU01301': '', 'JYU01302': '', 'JYU01303': '',
            'JYU01304': '', 'JYU01305': '', 'JYU01451': '', 'JYU01452': '', 'JYU01664': '', 'JYU01665': '', 'JYU01666': '',
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '',
            'JYU01118': '', 'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '',
            'JYU01132': '', 'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '',
            'JYU01139': '', 'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '',
            'JYU01200': '', 'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '',
            'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
            'JYU01481': '', 'JYU01482': '', 'JYU01483': '', 'JYU01484': '', 'JYU01485': '', 'JYU01486': '', 'JYU01487': '',
            'JYU01488': '', 'JYU01489': '', 'JYU01490': '', 'JYU01491': '', 'JYU01492': '', 'JYU01493': '', 'JYU01494': '',
            'JYU01495': '', 'JYU01496': '', 'JYU01503': '', 'JYU01504': '', 'JYU01505': '', 'JYU01506': '', 'JYU01507': '',
            'JYU01508': '', 'JYU01509': '', 'JYU01510': '', 'JYU01511': '', 'JYU01512': '', 'JYU01513': '', 'JYU01514': '',
            'JYU01515': '', 'JYU01516': '', 'JYU01517': '', 'JYU01518': '', 'JYU01519': '', 'JYU01520': '', 'JYU01521': '',
            'JYU01522': '', 'JYU01523': '', 'JYU01524': '', 'JYU01525': '', 'JYU01526': '', 'JYU01527': '', 'JYU01528': '',
            'JYU01529': '', 'JYU01530': '', 'JYU01531': '', 'JYU01532': '', 'JYU01533': '', 'JYU01534': '', 'JYU01535': '',
            'JYU01536': '', 'JYU01537': '', 'JYU01538': '', 'JYU01539': '', 'JYU01540': '', 'JYU01543': '', 'JYU01544': '',
            'JYU01545': '', 'JYU01546': '', 'JYU01547': '', 'JYU01765': '', 'JYU01766': '', 'JYU01775': '',
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00066': '', 'FUD00067': '', 'FUD00070': '', 'FUD00072': '', 'FUD00073': '', 'FUD00075': '', 'FUD00135': '',
            'FUD00158': '', 'FUD00278': '', 'FUD00281': '', 'FUD00353': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '',
            'FUD00379': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0109_FRK一般仲介用・借地権付建物
    '0109': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00258': '', 'TOR00259': '', 'TOR00260': '', 'TOR00261': '', 'TOR00262': '',
            'TOR00316': '', 'TOR00317': '', 'TOR00318': '', 'TOR00319': '', 'TOR00320': '', 'TOR00321': '', 'TOR00322': '',
            'TOR00323': '', 'TOR00324': '', 'TOR00325': '', 'TOR00326': '', 'TOR00327': '', 'TOR00328': '', 'TOR00329': '',
            'TOR00330': '', 'TOR00331': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '', 'TOR00355': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '', 'JYU00295': '',
            'JYU00296': '', 'JYU00322': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '',
            'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '', 'JYU01579': '',
            'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '', 'JYU01789': '',
        },
        '0203': {
            'JYU00341': '', 'JYU00343': '', 'JYU00344': '', 'JYU00345': '', 'JYU00352': '', 'JYU00353': '', 'JYU00359': '',
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01279': '', 'JYU01280': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
            'JYU01771': '', 'JYU01772': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01113': '',
            'JYU01117': '', 'JYU01118': '', 'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '',
            'JYU01128': '', 'JYU01132': '', 'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '',
            'JYU01138': '', 'JYU01139': '', 'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '',
            'JYU01146': '', 'JYU01200': '', 'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '',
            'JYU01214': '', 'JYU01215': '', 'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '',
            'JYU01446': '', 'JYU01447': '', 'JYU01449': '', 'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '',
            'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
            'JYU01481': '', 'JYU01482': '', 'JYU01483': '', 'JYU01484': '', 'JYU01485': '', 'JYU01486': '', 'JYU01487': '',
            'JYU01488': '', 'JYU01489': '', 'JYU01490': '', 'JYU01491': '', 'JYU01492': '', 'JYU01493': '', 'JYU01494': '',
            'JYU01495': '', 'JYU01496': '', 'JYU01503': '', 'JYU01504': '', 'JYU01505': '', 'JYU01506': '', 'JYU01507': '',
            'JYU01508': '', 'JYU01509': '', 'JYU01510': '', 'JYU01511': '', 'JYU01512': '', 'JYU01513': '', 'JYU01514': '',
            'JYU01515': '', 'JYU01516': '', 'JYU01517': '', 'JYU01518': '', 'JYU01519': '', 'JYU01520': '', 'JYU01521': '',
            'JYU01522': '', 'JYU01523': '', 'JYU01524': '', 'JYU01525': '', 'JYU01526': '', 'JYU01527': '', 'JYU01528': '',
            'JYU01529': '', 'JYU01530': '', 'JYU01531': '', 'JYU01532': '', 'JYU01533': '', 'JYU01534': '', 'JYU01535': '',
            'JYU01536': '', 'JYU01537': '', 'JYU01538': '', 'JYU01539': '', 'JYU01540': '', 'JYU01543': '', 'JYU01544': '',
            'JYU01545': '', 'JYU01546': '', 'JYU01547': '', 'JYU01765': '', 'JYU01766': '', 'JYU01775': '',
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00135': '', 'FUD00158': '', 'FUD00159': '', 'FUD00160': '', 'FUD00161': '', 'FUD00162': '', 'FUD00163': '',
            'FUD00164': '', 'FUD00167': '', 'FUD00174': '', 'FUD00177': '', 'FUD00178': '', 'FUD00179': '', 'FUD00180': '',
            'FUD00278': '', 'FUD00281': '', 'FUD00353': '', 'FUD00354': '', 'FUD00355': '', 'FUD00356': '', 'FUD00363': '',
            'FUD00364': '', 'FUD00373': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0110_FRK一般仲介用・定期借地権付建物
    '0110': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00258': '', 'TOR00259': '', 'TOR00260': '', 'TOR00261': '', 'TOR00262': '',
            'TOR00316': '', 'TOR00317': '', 'TOR00318': '', 'TOR00319': '', 'TOR00320': '', 'TOR00321': '', 'TOR00322': '',
            'TOR00323': '', 'TOR00324': '', 'TOR00325': '', 'TOR00326': '', 'TOR00327': '', 'TOR00328': '', 'TOR00329': '',
            'TOR00330': '', 'TOR00331': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '', 'TOR00355': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '', 'JYU00295': '',
            'JYU00296': '', 'JYU00322': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '',
            'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '', 'JYU01579': '',
            'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '', 'JYU01789': '',
        },
        '0203': {
            'JYU00341': '', 'JYU00343': '', 'JYU00344': '', 'JYU00345': '', 'JYU00352': '', 'JYU00353': '', 'JYU00359': '',
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01279': '', 'JYU01280': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
            'JYU01771': '', 'JYU01772': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01113': '',
            'JYU01117': '', 'JYU01118': '', 'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '',
            'JYU01128': '', 'JYU01132': '', 'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '',
            'JYU01138': '', 'JYU01139': '', 'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '',
            'JYU01146': '', 'JYU01200': '', 'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '',
            'JYU01214': '', 'JYU01215': '', 'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '',
            'JYU01446': '', 'JYU01447': '', 'JYU01449': '', 'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '',
            'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
            'JYU01481': '', 'JYU01482': '', 'JYU01483': '', 'JYU01484': '', 'JYU01485': '', 'JYU01486': '', 'JYU01487': '',
            'JYU01488': '', 'JYU01489': '', 'JYU01490': '', 'JYU01491': '', 'JYU01492': '', 'JYU01493': '', 'JYU01494': '',
            'JYU01765': '', 'JYU01766': '', 'JYU01767': '',
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00064': '', 'FUD00065': '', 'FUD00175': '', 'FUD00176': '', 'FUD00183': '', 'FUD00185': '', 'FUD00187': '',
            'FUD00189': '', 'FUD00191': '', 'FUD00192': '', 'FUD00193': '', 'FUD00194': '', 'FUD00195': '', 'FUD00196': '',
            'FUD00197': '', 'FUD00198': '', 'FUD00199': '', 'FUD00200': '', 'FUD00201': '', 'FUD00202': '', 'FUD00278': '',
            'FUD00281': '', 'FUD00338': '', 'FUD00357': '', 'FUD00358': '', 'FUD00359': '', 'FUD00360': '', 'FUD00363': '',
            'FUD00364': '', 'FUD00373': '',
        },
        '0302': {
            'FUD00215': '', 'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0111_FRK一般仲介用・定期借地権付区分所有建物
    '0111': {
        '0101': {
            'TOR00205': '', 'TOR00206': '', 'TOR00207': '', 'TOR00208': '', 'TOR00209': '', 'TOR00210': '', 'TOR00211': '',
            'TOR00212': '', 'TOR00213': '', 'TOR00214': '', 'TOR00215': '', 'TOR00216': '', 'TOR00235': '', 'TOR00236': '',
            'TOR00300': '', 'TOR00301': '', 'TOR00302': '', 'TOR00306': '', 'TOR00307': '', 'TOR00308': '', 'TOR00309': '',
            'TOR00310': '', 'TOR00311': '', 'TOR00312': '', 'TOR00314': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '',
            'TOR00352': '', 'TOR00353': '', 'TOR00354': '', 'TOR00356': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00217_ARR': { 'TOR00217': '', 'TOR00218': '', 'TOR00219': '', 'TOR00221': '', 'TOR00315': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00147': '', 'JYU00194': '', 'JYU00195': '', 'JYU00196': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '',
            'JYU00326': '', 'JYU00327': '', 'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '',
            'JYU01455': '', 'JYU01577': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01750': '', 'JYU01778': '', 'JYU01779': '', 'JYU01780': '', 'JYU01781': '',
            'JYU01782': '', 'JYU01783': '', 'JYU01784': '', 'JYU01785': '', 'JYU01786': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00537': '', 'JYU00538': '',
            'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00543': '', 'JYU00544': '', 'JYU00545': '', 'JYU00546': '',
            'JYU00547': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '', 'JYU00552': '', 'JYU00553': '', 'JYU00554': '',
            'JYU00555': '', 'JYU00556': '', 'JYU00557': '', 'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00566': '',
            'JYU00567': '', 'JYU00568': '', 'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '',
            'JYU00577': '', 'JYU00578': '', 'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '',
            'JYU01329': '', 'JYU01330': '', 'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '',
            'JYU01336': '', 'JYU01337': '', 'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '',
            'JYU01343': '', 'JYU01432': '', 'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '',
            'JYU01593': '', 'JYU01594': '', 'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '',
            'JYU01600': '', 'JYU01601': '', 'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '',
            'JYU01608': '', 'JYU01609': '', 'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '',
            'JYU01646': '', 'JYU01647': '', 'JYU01648': '', 'JYU01649': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00714': '', 'JYU00716': '', 'JYU00718': '', 'JYU00721': '',
            'JYU00723': '', 'JYU00720': '', 'JYU00731': '', 'JYU00732': '', 'JYU00733': '', 'JYU00741': '', 'JYU00743': '',
            'JYU00745': '', 'JYU00746': '', 'JYU00747': '', 'JYU00749': '', 'JYU00751': '', 'JYU00785': '', 'JYU01387': '',
            'JYU01392': '', 'JYU01393': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01618': '', 'JYU01622': '',
            'JYU01623': '', 'JYU01624': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01628': '', 'JYU01629': '',
            'JYU01630': '', 'JYU01631': '', 'JYU01632': '', 'JYU01633': '', 'JYU01634': '',
        },
        '0208': {
            'JYU00790': '', 'JYU00791': '', 'JYU00792': '', 'JYU00793': '', 'JYU00794': '', 'JYU00795': '', 'JYU00796': '',
            'JYU00797': '', 'JYU00798': '', 'JYU00799': '', 'JYU00800': '', 'JYU00801': '', 'JYU00804': '', 'JYU00805': '',
            'JYU00809': '', 'JYU00810': '', 'JYU00812': '', 'JYU00813': '', 'JYU00814': '', 'JYU00816': '', 'JYU00817': '',
            'JYU00818': '', 'JYU00819': '', 'JYU00820': '', 'JYU00821': '', 'JYU00822': '', 'JYU00823': '', 'JYU00824': '',
            'JYU00825': '', 'JYU00826': '', 'JYU00827': '', 'JYU00828': '', 'JYU00829': '', 'JYU00830': '', 'JYU00833': '',
            'JYU01401': '', 'JYU01402': '', 'JYU01407': '', 'JYU01413': '', 'JYU01418': '', 'JYU01657': '', 'JYU01658': '',
            'JYU01659': '', 'JYU01660': '', 'JYU01661': '', 'JYU01662': '', 'JYU01773': '', 'JYU01774': '',
        },
        '0209': {
            'JYU00835': '', 'JYU00836': '', 'JYU00837': '', 'JYU00838': '', 'JYU00839': '', 'JYU00840': '', 'JYU00841': '',
            'JYU00842': '', 'JYU00843': '', 'JYU00844': '', 'JYU00845': '', 'JYU00846': '', 'JYU00847': '', 'JYU00848': '',
            'JYU00849': '', 'JYU00850': '', 'JYU00851': '', 'JYU00852': '', 'JYU00853': '', 'JYU00854': '', 'JYU00855': '',
            'JYU00856': '', 'JYU00857': '', 'JYU00858': '', 'JYU00859': '', 'JYU00860': '', 'JYU00861': '', 'JYU00862': '',
            'JYU00863': '', 'JYU00864': '', 'JYU00865': '', 'JYU00866': '', 'JYU00867': '', 'JYU00868': '', 'JYU00869': '',
            'JYU00870': '', 'JYU00871': '', 'JYU00872': '', 'JYU00873': '', 'JYU00874': '', 'JYU00875': '', 'JYU00876': '',
            'JYU00877': '', 'JYU00878': '', 'JYU00879': '', 'JYU00880': '', 'JYU00881': '', 'JYU00882': '', 'JYU00883': '',
            'JYU00884': '', 'JYU00885': '', 'JYU00886': '', 'JYU00887': '', 'JYU00888': '', 'JYU00889': '', 'JYU00890': '',
            'JYU00891': '', 'JYU00892': '', 'JYU00893': '', 'JYU00894': '', 'JYU00895': '', 'JYU00896': '', 'JYU00897': '',
            'JYU00898': '', 'JYU00899': '', 'JYU00901': '', 'JYU00902': '', 'JYU00903': '', 'JYU00904': '', 'JYU00905': '',
            'JYU00906': '', 'JYU00907': '', 'JYU00908': '', 'JYU00909': '', 'JYU00910': '', 'JYU00911': '', 'JYU00912': '',
            'JYU00913': '', 'JYU00914': '', 'JYU00915': '', 'JYU00916': '', 'JYU00917': '', 'JYU00918': '', 'JYU00934': '',
            'JYU00935': '', 'JYU00936': '', 'JYU00937': '', 'JYU01308': '', 'JYU01309': '', 'JYU01310': '', 'JYU01311': '',
            'JYU01312': '', 'JYU01313': '', 'JYU01314': '', 'JYU01315': '', 'JYU01316': '', 'JYU01317': '', 'JYU01318': '',
            'JYU01319': '', 'JYU01320': '', 'JYU01321': '', 'JYU01322': '', 'JYU01323': '', 'JYU01324': '', 'JYU01325': '',
            'JYU01326': '', 'JYU01663': '', 'JYU01791': '',
            'JYU00919_ARR': { 'JYU00919': '', 'JYU00920': '', },
        },
        '0210': {
            'JYU00938': '', 'JYU00939': '', 'JYU00940': '', 'JYU00941': '', 'JYU00942': '', 'JYU00943': '', 'JYU00951': '',
            'JYU00952': '', 'JYU00953': '', 'JYU00954': '', 'JYU00955': '', 'JYU00956': '', 'JYU00958': '', 'JYU00959': '',
            'JYU00960': '', 'JYU00961': '', 'JYU00962': '', 'JYU00963': '', 'JYU00964': '', 'JYU00965': '', 'JYU00967': '',
            'JYU00968': '', 'JYU00969': '', 'JYU00970': '', 'JYU00971': '', 'JYU00972': '', 'JYU00973': '', 'JYU00975': '',
            'JYU00976': '', 'JYU00977': '', 'JYU00978': '', 'JYU00979': '', 'JYU01301': '', 'JYU01302': '', 'JYU01303': '',
            'JYU01304': '', 'JYU01305': '', 'JYU01451': '', 'JYU01452': '', 'JYU01664': '', 'JYU01665': '', 'JYU01666': '',
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '',
            'JYU01118': '', 'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '',
            'JYU01132': '', 'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '',
            'JYU01139': '', 'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '',
            'JYU01200': '', 'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '',
            'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
            'JYU01481': '', 'JYU01482': '', 'JYU01483': '', 'JYU01484': '', 'JYU01485': '', 'JYU01486': '', 'JYU01487': '',
            'JYU01488': '', 'JYU01489': '', 'JYU01490': '', 'JYU01491': '', 'JYU01492': '', 'JYU01493': '', 'JYU01494': '',
            'JYU01765': '', 'JYU01766': '', 'JYU01767': '',
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00175': '', 'FUD00176': '', 'FUD00183': '', 'FUD00185': '', 'FUD00187': '', 'FUD00189': '', 'FUD00191': '',
            'FUD00192': '', 'FUD00193': '', 'FUD00194': '', 'FUD00195': '', 'FUD00196': '', 'FUD00197': '', 'FUD00198': '',
            'FUD00199': '', 'FUD00200': '', 'FUD00201': '', 'FUD00202': '', 'FUD00278': '', 'FUD00281': '', 'FUD00338': '',
            'FUD00357': '', 'FUD00358': '', 'FUD00359': '', 'FUD00360': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '',
        },
        '0302': {
            'FUD00215': '', 'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0201_FRK消費者契約用・土地（実測・清算）
    '0201': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00296': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '',
            'JYU00327': '', 'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '',
            'JYU01579': '', 'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01121': '',
            'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '', 'JYU01133': '',
            'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '', 'JYU01140': '',
            'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '', 'JYU01209': '',
            'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '', 'JYU01215': '', 'JYU01275': '',
            'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01449': '', 'JYU01719': '', 'JYU01720': '',
            'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU00787': '', 'JYU00788': '', 'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '',
            'JYU01034': '', 'JYU01457': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '',
            'JYU01553': '', 'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '', 'JYU01760': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '', 'JYU01776': '', 'JYU01777': '',
        },
        '0301': {
            'FUD00135': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0202_FRK消費者契約用・土地（確定測量・清算）
    '0202': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00296': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '',
            'JYU00327': '', 'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '',
            'JYU01579': '', 'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01121': '',
            'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '', 'JYU01133': '',
            'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '', 'JYU01140': '',
            'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '', 'JYU01209': '',
            'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '', 'JYU01215': '', 'JYU01275': '',
            'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01449': '', 'JYU01719': '', 'JYU01720': '',
            'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU00787': '', 'JYU00788': '', 'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '',
            'JYU01034': '', 'JYU01457': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '',
            'JYU01553': '', 'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '', 'JYU01760': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '', 'JYU01776': '', 'JYU01777': '',
        },
        '0301': {
            'FUD00135': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0203_FRK消費者契約用・土地（売買代金固定）
    '0203': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00296': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '',
            'JYU00327': '', 'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '',
            'JYU01579': '', 'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01121': '',
            'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '', 'JYU01133': '',
            'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '', 'JYU01140': '',
            'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '', 'JYU01209': '',
            'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '', 'JYU01215': '', 'JYU01275': '',
            'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01449': '', 'JYU01719': '', 'JYU01720': '',
            'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU00787': '', 'JYU00788': '', 'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '',
            'JYU01034': '', 'JYU01457': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '',
            'JYU01553': '', 'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '', 'JYU01760': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '', 'JYU01776': '', 'JYU01777': '',
        },
        '0301': {
            'FUD00135': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0204_FRK消費者契約用・土地建物（実測・清算）
    '0204': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00258': '', 'TOR00259': '', 'TOR00260': '', 'TOR00261': '', 'TOR00262': '',
            'TOR00316': '', 'TOR00317': '', 'TOR00318': '', 'TOR00319': '', 'TOR00320': '', 'TOR00321': '', 'TOR00322': '',
            'TOR00323': '', 'TOR00324': '', 'TOR00325': '', 'TOR00326': '', 'TOR00327': '', 'TOR00328': '', 'TOR00329': '',
            'TOR00330': '', 'TOR00331': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '', 'TOR00355': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '', 'JYU00295': '',
            'JYU00296': '', 'JYU00322': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '',
            'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '', 'JYU01579': '',
            'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '', 'JYU01789': '',
        },
        '0203': {
            'JYU00341': '', 'JYU00343': '', 'JYU00344': '', 'JYU00345': '', 'JYU00352': '', 'JYU00353': '', 'JYU00359': '',
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01279': '', 'JYU01280': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
            'JYU01771': '', 'JYU01772': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '',
            'JYU01118': '', 'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '',
            'JYU01132': '', 'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '',
            'JYU01139': '', 'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '',
            'JYU01200': '', 'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '',
            'JYU01215': '', 'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '',
            'JYU01449': '', 'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '',
            'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00135': '', 'FUD00278': '', 'FUD00281': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '', 'FUD00380': '',
            'FUD00381': '', 'FUD00382': '', 'FUD00383': '', 'FUD00384': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0205_FRK消費者契約用・土地建物（確定測量・清算）
    '0205': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00258': '', 'TOR00259': '', 'TOR00260': '', 'TOR00261': '', 'TOR00262': '',
            'TOR00316': '', 'TOR00317': '', 'TOR00318': '', 'TOR00319': '', 'TOR00320': '', 'TOR00321': '', 'TOR00322': '',
            'TOR00323': '', 'TOR00324': '', 'TOR00325': '', 'TOR00326': '', 'TOR00327': '', 'TOR00328': '', 'TOR00329': '',
            'TOR00330': '', 'TOR00331': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '', 'TOR00355': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '', 'JYU00295': '',
            'JYU00296': '', 'JYU00322': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '',
            'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '', 'JYU01579': '',
            'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '', 'JYU01789': '',
        },
        '0203': {
            'JYU00341': '', 'JYU00343': '', 'JYU00344': '', 'JYU00345': '', 'JYU00352': '', 'JYU00353': '', 'JYU00359': '',
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01279': '', 'JYU01280': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
            'JYU01771': '', 'JYU01772': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '',
            'JYU01118': '', 'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '',
            'JYU01132': '', 'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '',
            'JYU01139': '', 'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '',
            'JYU01200': '', 'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '',
            'JYU01215': '', 'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '',
            'JYU01449': '', 'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '',
            'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00135': '', 'FUD00278': '', 'FUD00281': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '', 'FUD00380': '',
            'FUD00381': '', 'FUD00382': '', 'FUD00383': '', 'FUD00384': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0206_FRK消費者契約用・土地建物（売買代金固定）
    '0206': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00258': '', 'TOR00259': '', 'TOR00260': '', 'TOR00261': '', 'TOR00262': '',
            'TOR00316': '', 'TOR00317': '', 'TOR00318': '', 'TOR00319': '', 'TOR00320': '', 'TOR00321': '', 'TOR00322': '',
            'TOR00323': '', 'TOR00324': '', 'TOR00325': '', 'TOR00326': '', 'TOR00327': '', 'TOR00328': '', 'TOR00329': '',
            'TOR00330': '', 'TOR00331': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '', 'TOR00355': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '', 'JYU00295': '',
            'JYU00296': '', 'JYU00322': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '',
            'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '', 'JYU01579': '',
            'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '', 'JYU01789': '',
        },
        '0203': {
            'JYU00341': '', 'JYU00343': '', 'JYU00344': '', 'JYU00345': '', 'JYU00352': '', 'JYU00353': '', 'JYU00359': '',
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01279': '', 'JYU01280': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
            'JYU01771': '', 'JYU01772': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '',
            'JYU01118': '', 'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '',
            'JYU01132': '', 'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '',
            'JYU01139': '', 'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '',
            'JYU01200': '', 'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '',
            'JYU01215': '', 'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '',
            'JYU01449': '', 'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '',
            'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00135': '', 'FUD00278': '', 'FUD00281': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '', 'FUD00380': '',
            'FUD00381': '', 'FUD00382': '', 'FUD00383': '', 'FUD00384': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0207_FRK消費者契約用・区分所有建物（敷地権）
    '0207': {
        '0101': {
            'TOR00205': '', 'TOR00206': '', 'TOR00207': '', 'TOR00208': '', 'TOR00209': '', 'TOR00210': '', 'TOR00211': '',
            'TOR00212': '', 'TOR00213': '', 'TOR00214': '', 'TOR00215': '', 'TOR00216': '', 'TOR00235': '', 'TOR00236': '',
            'TOR00300': '', 'TOR00301': '', 'TOR00302': '', 'TOR00306': '', 'TOR00307': '', 'TOR00308': '', 'TOR00309': '',
            'TOR00310': '', 'TOR00311': '', 'TOR00312': '', 'TOR00314': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '',
            'TOR00352': '', 'TOR00353': '', 'TOR00354': '', 'TOR00356': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00217_ARR': { 'TOR00217': '', 'TOR00218': '', 'TOR00219': '', 'TOR00221': '', 'TOR00315': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00147': '', 'JYU00194': '', 'JYU00195': '', 'JYU00196': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '',
            'JYU00326': '', 'JYU00327': '', 'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '',
            'JYU01455': '', 'JYU01577': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01750': '', 'JYU01778': '', 'JYU01779': '', 'JYU01780': '', 'JYU01781': '',
            'JYU01782': '', 'JYU01783': '', 'JYU01784': '', 'JYU01785': '', 'JYU01786': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00537': '', 'JYU00538': '',
            'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00543': '', 'JYU00544': '', 'JYU00545': '', 'JYU00546': '',
            'JYU00547': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '', 'JYU00552': '', 'JYU00553': '', 'JYU00554': '',
            'JYU00555': '', 'JYU00556': '', 'JYU00557': '', 'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00566': '',
            'JYU00567': '', 'JYU00568': '', 'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '',
            'JYU00577': '', 'JYU00578': '', 'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '',
            'JYU01329': '', 'JYU01330': '', 'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '',
            'JYU01336': '', 'JYU01337': '', 'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '',
            'JYU01343': '', 'JYU01432': '', 'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '',
            'JYU01593': '', 'JYU01594': '', 'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '',
            'JYU01600': '', 'JYU01601': '', 'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '',
            'JYU01608': '', 'JYU01609': '', 'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '',
            'JYU01646': '', 'JYU01647': '', 'JYU01648': '', 'JYU01649': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00714': '', 'JYU00716': '', 'JYU00718': '', 'JYU00721': '',
            'JYU00723': '', 'JYU00720': '', 'JYU00731': '', 'JYU00732': '', 'JYU00733': '', 'JYU00741': '', 'JYU00743': '',
            'JYU00745': '', 'JYU00746': '', 'JYU00747': '', 'JYU00749': '', 'JYU00751': '', 'JYU00785': '', 'JYU01387': '',
            'JYU01392': '', 'JYU01393': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01618': '', 'JYU01622': '',
            'JYU01623': '', 'JYU01624': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01628': '', 'JYU01629': '',
            'JYU01630': '', 'JYU01631': '', 'JYU01632': '', 'JYU01633': '', 'JYU01634': '',
        },
        '0208': {
            'JYU00790': '', 'JYU00791': '', 'JYU00792': '', 'JYU00793': '', 'JYU00794': '', 'JYU00795': '', 'JYU00796': '',
            'JYU00797': '', 'JYU00798': '', 'JYU00799': '', 'JYU00800': '', 'JYU00801': '', 'JYU00804': '', 'JYU00805': '',
            'JYU00809': '', 'JYU00810': '', 'JYU00812': '', 'JYU00813': '', 'JYU00814': '', 'JYU00816': '', 'JYU00817': '',
            'JYU00818': '', 'JYU00819': '', 'JYU00820': '', 'JYU00821': '', 'JYU00822': '', 'JYU00823': '', 'JYU00824': '',
            'JYU00825': '', 'JYU00826': '', 'JYU00827': '', 'JYU00828': '', 'JYU00829': '', 'JYU00830': '', 'JYU00833': '',
            'JYU01401': '', 'JYU01402': '', 'JYU01407': '', 'JYU01413': '', 'JYU01418': '', 'JYU01657': '', 'JYU01658': '',
            'JYU01659': '', 'JYU01660': '', 'JYU01661': '', 'JYU01662': '', 'JYU01773': '', 'JYU01774': '',
        },
        '0209': {
            'JYU00835': '', 'JYU00836': '', 'JYU00837': '', 'JYU00838': '', 'JYU00839': '', 'JYU00840': '', 'JYU00841': '',
            'JYU00842': '', 'JYU00843': '', 'JYU00844': '', 'JYU00845': '', 'JYU00846': '', 'JYU00847': '', 'JYU00848': '',
            'JYU00849': '', 'JYU00850': '', 'JYU00851': '', 'JYU00852': '', 'JYU00853': '', 'JYU00854': '', 'JYU00855': '',
            'JYU00856': '', 'JYU00857': '', 'JYU00858': '', 'JYU00859': '', 'JYU00860': '', 'JYU00861': '', 'JYU00862': '',
            'JYU00863': '', 'JYU00864': '', 'JYU00865': '', 'JYU00866': '', 'JYU00867': '', 'JYU00868': '', 'JYU00869': '',
            'JYU00870': '', 'JYU00871': '', 'JYU00872': '', 'JYU00873': '', 'JYU00874': '', 'JYU00875': '', 'JYU00876': '',
            'JYU00877': '', 'JYU00878': '', 'JYU00879': '', 'JYU00880': '', 'JYU00881': '', 'JYU00882': '', 'JYU00883': '',
            'JYU00884': '', 'JYU00885': '', 'JYU00886': '', 'JYU00887': '', 'JYU00888': '', 'JYU00889': '', 'JYU00890': '',
            'JYU00891': '', 'JYU00892': '', 'JYU00893': '', 'JYU00894': '', 'JYU00895': '', 'JYU00896': '', 'JYU00897': '',
            'JYU00898': '', 'JYU00899': '', 'JYU00901': '', 'JYU00902': '', 'JYU00903': '', 'JYU00904': '', 'JYU00905': '',
            'JYU00906': '', 'JYU00907': '', 'JYU00908': '', 'JYU00909': '', 'JYU00910': '', 'JYU00911': '', 'JYU00912': '',
            'JYU00913': '', 'JYU00914': '', 'JYU00915': '', 'JYU00916': '', 'JYU00917': '', 'JYU00918': '', 'JYU00934': '',
            'JYU00935': '', 'JYU00936': '', 'JYU00937': '', 'JYU01308': '', 'JYU01309': '', 'JYU01310': '', 'JYU01311': '',
            'JYU01312': '', 'JYU01313': '', 'JYU01314': '', 'JYU01315': '', 'JYU01316': '', 'JYU01317': '', 'JYU01318': '',
            'JYU01319': '', 'JYU01320': '', 'JYU01321': '', 'JYU01322': '', 'JYU01323': '', 'JYU01324': '', 'JYU01325': '',
            'JYU01326': '', 'JYU01663': '', 'JYU01791': '',
            'JYU00919_ARR': { 'JYU00919': '', 'JYU00920': '', },
        },
        '0210': {
            'JYU00938': '', 'JYU00939': '', 'JYU00940': '', 'JYU00941': '', 'JYU00942': '', 'JYU00943': '', 'JYU00951': '',
            'JYU00952': '', 'JYU00953': '', 'JYU00954': '', 'JYU00955': '', 'JYU00956': '', 'JYU00958': '', 'JYU00959': '',
            'JYU00960': '', 'JYU00961': '', 'JYU00962': '', 'JYU00963': '', 'JYU00964': '', 'JYU00965': '', 'JYU00967': '',
            'JYU00968': '', 'JYU00969': '', 'JYU00970': '', 'JYU00971': '', 'JYU00972': '', 'JYU00973': '', 'JYU00975': '',
            'JYU00976': '', 'JYU00977': '', 'JYU00978': '', 'JYU00979': '', 'JYU01301': '', 'JYU01302': '', 'JYU01303': '',
            'JYU01304': '', 'JYU01305': '', 'JYU01451': '', 'JYU01452': '', 'JYU01664': '', 'JYU01665': '', 'JYU01666': '',
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '',
            'JYU01118': '', 'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '',
            'JYU01132': '', 'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '',
            'JYU01139': '', 'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '',
            'JYU01200': '', 'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '',
            'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
            'JYU01481': '', 'JYU01482': '', 'JYU01483': '', 'JYU01484': '', 'JYU01485': '', 'JYU01486': '', 'JYU01487': '',
            'JYU01488': '', 'JYU01489': '', 'JYU01490': '', 'JYU01491': '', 'JYU01492': '', 'JYU01493': '', 'JYU01494': '',
            'JYU01495': '', 'JYU01496': '', 'JYU01503': '', 'JYU01504': '', 'JYU01505': '', 'JYU01506': '', 'JYU01507': '',
            'JYU01508': '', 'JYU01509': '', 'JYU01510': '', 'JYU01511': '', 'JYU01512': '', 'JYU01513': '', 'JYU01514': '',
            'JYU01515': '', 'JYU01516': '', 'JYU01517': '', 'JYU01518': '', 'JYU01519': '', 'JYU01520': '', 'JYU01521': '',
            'JYU01522': '', 'JYU01523': '', 'JYU01524': '', 'JYU01525': '', 'JYU01526': '', 'JYU01527': '', 'JYU01528': '',
            'JYU01529': '', 'JYU01530': '', 'JYU01531': '', 'JYU01532': '', 'JYU01533': '', 'JYU01534': '', 'JYU01535': '',
            'JYU01536': '', 'JYU01537': '', 'JYU01538': '', 'JYU01539': '', 'JYU01540': '', 'JYU01543': '', 'JYU01544': '',
            'JYU01545': '', 'JYU01546': '', 'JYU01547': '', 'JYU01765': '', 'JYU01766': '', 'JYU01775': '',
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00066': '', 'FUD00067': '', 'FUD00070': '', 'FUD00072': '', 'FUD00073': '', 'FUD00075': '', 'FUD00135': '',
            'FUD00278': '', 'FUD00281': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '', 'FUD00379': '', 'FUD00380': '',
            'FUD00381': '', 'FUD00382': '', 'FUD00383': '', 'FUD00384': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0208_FRK消費者契約用・区分所有建物（非敷地権）
    '0208': {
        '0101': {
            'TOR00205': '', 'TOR00206': '', 'TOR00207': '', 'TOR00208': '', 'TOR00209': '', 'TOR00210': '', 'TOR00211': '',
            'TOR00212': '', 'TOR00213': '', 'TOR00214': '', 'TOR00215': '', 'TOR00216': '', 'TOR00235': '', 'TOR00236': '',
            'TOR00256': '', 'TOR00257': '', 'TOR00300': '', 'TOR00301': '', 'TOR00302': '', 'TOR00306': '', 'TOR00307': '',
            'TOR00308': '', 'TOR00309': '', 'TOR00310': '', 'TOR00311': '', 'TOR00312': '', 'TOR00314': '', 'TOR00349': '',
            'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '', 'TOR00354': '', 'TOR00356': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00147': '', 'JYU00194': '', 'JYU00195': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '',
            'JYU00296': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '', 'JYU00328': '',
            'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01455': '', 'JYU01577': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01750': '', 'JYU01778': '', 'JYU01779': '', 'JYU01780': '', 'JYU01781': '',
            'JYU01782': '', 'JYU01783': '', 'JYU01784': '', 'JYU01785': '', 'JYU01786': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00537': '', 'JYU00538': '',
            'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00543': '', 'JYU00544': '', 'JYU00545': '', 'JYU00546': '',
            'JYU00547': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '', 'JYU00552': '', 'JYU00553': '', 'JYU00554': '',
            'JYU00555': '', 'JYU00556': '', 'JYU00557': '', 'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00566': '',
            'JYU00567': '', 'JYU00568': '', 'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '',
            'JYU00577': '', 'JYU00578': '', 'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '',
            'JYU01329': '', 'JYU01330': '', 'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '',
            'JYU01336': '', 'JYU01337': '', 'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '',
            'JYU01343': '', 'JYU01432': '', 'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '',
            'JYU01593': '', 'JYU01594': '', 'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '',
            'JYU01600': '', 'JYU01601': '', 'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '',
            'JYU01608': '', 'JYU01609': '', 'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '',
            'JYU01646': '', 'JYU01647': '', 'JYU01648': '', 'JYU01649': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00714': '', 'JYU00716': '', 'JYU00718': '', 'JYU00721': '',
            'JYU00723': '', 'JYU00720': '', 'JYU00731': '', 'JYU00732': '', 'JYU00733': '', 'JYU00741': '', 'JYU00743': '',
            'JYU00745': '', 'JYU00746': '', 'JYU00747': '', 'JYU00749': '', 'JYU00751': '', 'JYU00785': '', 'JYU01387': '',
            'JYU01392': '', 'JYU01393': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01618': '', 'JYU01622': '',
            'JYU01623': '', 'JYU01624': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01628': '', 'JYU01629': '',
            'JYU01630': '', 'JYU01631': '', 'JYU01632': '', 'JYU01633': '', 'JYU01634': '',
        },
        '0208': {
            'JYU00790': '', 'JYU00791': '', 'JYU00792': '', 'JYU00793': '', 'JYU00794': '', 'JYU00795': '', 'JYU00796': '',
            'JYU00797': '', 'JYU00798': '', 'JYU00799': '', 'JYU00800': '', 'JYU00801': '', 'JYU00804': '', 'JYU00805': '',
            'JYU00809': '', 'JYU00810': '', 'JYU00812': '', 'JYU00813': '', 'JYU00814': '', 'JYU00816': '', 'JYU00817': '',
            'JYU00818': '', 'JYU00819': '', 'JYU00820': '', 'JYU00821': '', 'JYU00822': '', 'JYU00823': '', 'JYU00824': '',
            'JYU00825': '', 'JYU00826': '', 'JYU00827': '', 'JYU00828': '', 'JYU00829': '', 'JYU00830': '', 'JYU00833': '',
            'JYU01401': '', 'JYU01402': '', 'JYU01407': '', 'JYU01413': '', 'JYU01418': '', 'JYU01657': '', 'JYU01658': '',
            'JYU01659': '', 'JYU01660': '', 'JYU01661': '', 'JYU01662': '', 'JYU01773': '', 'JYU01774': '',
        },
        '0209': {
            'JYU00835': '', 'JYU00836': '', 'JYU00837': '', 'JYU00838': '', 'JYU00839': '', 'JYU00840': '', 'JYU00841': '',
            'JYU00842': '', 'JYU00843': '', 'JYU00844': '', 'JYU00845': '', 'JYU00846': '', 'JYU00847': '', 'JYU00848': '',
            'JYU00849': '', 'JYU00850': '', 'JYU00851': '', 'JYU00852': '', 'JYU00853': '', 'JYU00854': '', 'JYU00855': '',
            'JYU00856': '', 'JYU00857': '', 'JYU00858': '', 'JYU00859': '', 'JYU00860': '', 'JYU00861': '', 'JYU00862': '',
            'JYU00863': '', 'JYU00864': '', 'JYU00865': '', 'JYU00866': '', 'JYU00867': '', 'JYU00868': '', 'JYU00869': '',
            'JYU00870': '', 'JYU00871': '', 'JYU00872': '', 'JYU00873': '', 'JYU00874': '', 'JYU00875': '', 'JYU00876': '',
            'JYU00877': '', 'JYU00878': '', 'JYU00879': '', 'JYU00880': '', 'JYU00881': '', 'JYU00882': '', 'JYU00883': '',
            'JYU00884': '', 'JYU00885': '', 'JYU00886': '', 'JYU00887': '', 'JYU00888': '', 'JYU00889': '', 'JYU00890': '',
            'JYU00891': '', 'JYU00892': '', 'JYU00893': '', 'JYU00894': '', 'JYU00895': '', 'JYU00896': '', 'JYU00897': '',
            'JYU00898': '', 'JYU00899': '', 'JYU00901': '', 'JYU00902': '', 'JYU00903': '', 'JYU00904': '', 'JYU00905': '',
            'JYU00906': '', 'JYU00907': '', 'JYU00908': '', 'JYU00909': '', 'JYU00910': '', 'JYU00911': '', 'JYU00912': '',
            'JYU00913': '', 'JYU00914': '', 'JYU00915': '', 'JYU00916': '', 'JYU00917': '', 'JYU00918': '', 'JYU00934': '',
            'JYU00935': '', 'JYU00936': '', 'JYU00937': '', 'JYU01308': '', 'JYU01309': '', 'JYU01310': '', 'JYU01311': '',
            'JYU01312': '', 'JYU01313': '', 'JYU01314': '', 'JYU01315': '', 'JYU01316': '', 'JYU01317': '', 'JYU01318': '',
            'JYU01319': '', 'JYU01320': '', 'JYU01321': '', 'JYU01322': '', 'JYU01323': '', 'JYU01324': '', 'JYU01325': '',
            'JYU01326': '', 'JYU01663': '', 'JYU01791': '',
            'JYU00919_ARR': { 'JYU00919': '', 'JYU00920': '', },
        },
        '0210': {
            'JYU00938': '', 'JYU00939': '', 'JYU00940': '', 'JYU00941': '', 'JYU00942': '', 'JYU00943': '', 'JYU00951': '',
            'JYU00952': '', 'JYU00953': '', 'JYU00954': '', 'JYU00955': '', 'JYU00956': '', 'JYU00958': '', 'JYU00959': '',
            'JYU00960': '', 'JYU00961': '', 'JYU00962': '', 'JYU00963': '', 'JYU00964': '', 'JYU00965': '', 'JYU00967': '',
            'JYU00968': '', 'JYU00969': '', 'JYU00970': '', 'JYU00971': '', 'JYU00972': '', 'JYU00973': '', 'JYU00975': '',
            'JYU00976': '', 'JYU00977': '', 'JYU00978': '', 'JYU00979': '', 'JYU01301': '', 'JYU01302': '', 'JYU01303': '',
            'JYU01304': '', 'JYU01305': '', 'JYU01451': '', 'JYU01452': '', 'JYU01664': '', 'JYU01665': '', 'JYU01666': '',
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '',
            'JYU01118': '', 'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '',
            'JYU01132': '', 'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '',
            'JYU01139': '', 'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '',
            'JYU01200': '', 'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '',
            'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
            'JYU01481': '', 'JYU01482': '', 'JYU01483': '', 'JYU01484': '', 'JYU01485': '', 'JYU01486': '', 'JYU01487': '',
            'JYU01488': '', 'JYU01489': '', 'JYU01490': '', 'JYU01491': '', 'JYU01492': '', 'JYU01493': '', 'JYU01494': '',
            'JYU01495': '', 'JYU01496': '', 'JYU01503': '', 'JYU01504': '', 'JYU01505': '', 'JYU01506': '', 'JYU01507': '',
            'JYU01508': '', 'JYU01509': '', 'JYU01510': '', 'JYU01511': '', 'JYU01512': '', 'JYU01513': '', 'JYU01514': '',
            'JYU01515': '', 'JYU01516': '', 'JYU01517': '', 'JYU01518': '', 'JYU01519': '', 'JYU01520': '', 'JYU01521': '',
            'JYU01522': '', 'JYU01523': '', 'JYU01524': '', 'JYU01525': '', 'JYU01526': '', 'JYU01527': '', 'JYU01528': '',
            'JYU01529': '', 'JYU01530': '', 'JYU01531': '', 'JYU01532': '', 'JYU01533': '', 'JYU01534': '', 'JYU01535': '',
            'JYU01536': '', 'JYU01537': '', 'JYU01538': '', 'JYU01539': '', 'JYU01540': '', 'JYU01543': '', 'JYU01544': '',
            'JYU01545': '', 'JYU01546': '', 'JYU01547': '', 'JYU01765': '', 'JYU01766': '', 'JYU01775': '',
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00066': '', 'FUD00067': '', 'FUD00070': '', 'FUD00072': '', 'FUD00073': '', 'FUD00075': '', 'FUD00135': '',
            'FUD00158': '', 'FUD00278': '', 'FUD00281': '', 'FUD00353': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '',
            'FUD00379': '', 'FUD00380': '', 'FUD00381': '', 'FUD00382': '', 'FUD00383': '', 'FUD00384': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0209_FRK消費者契約用・借地権付建物
    '0209': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00258': '', 'TOR00259': '', 'TOR00260': '', 'TOR00261': '', 'TOR00262': '',
            'TOR00316': '', 'TOR00317': '', 'TOR00318': '', 'TOR00319': '', 'TOR00320': '', 'TOR00321': '', 'TOR00322': '',
            'TOR00323': '', 'TOR00324': '', 'TOR00325': '', 'TOR00326': '', 'TOR00327': '', 'TOR00328': '', 'TOR00329': '',
            'TOR00330': '', 'TOR00331': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '', 'TOR00355': '',
            'TOR00072_ARR': { 'TOR00072': '', 'TOR00332': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00007': '', 'JYU00009': '', 'JYU00010': '',
            'JYU00011': '', 'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '',
            'JYU00021': '', 'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '',
            'JYU00048': '', 'JYU00049': '', 'JYU00050': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '',
            'JYU00057': '', 'JYU01571': '', 'JYU01746': '', 'JYU01762': '', 'JYU01764': '', 'JYU01768': '', 'JYU01769': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '', 'JYU00295': '',
            'JYU00296': '', 'JYU00322': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '',
            'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '', 'JYU01579': '',
            'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '', 'JYU01789': '',
        },
        '0203': {
            'JYU00341': '', 'JYU00343': '', 'JYU00344': '', 'JYU00345': '', 'JYU00352': '', 'JYU00353': '', 'JYU00359': '',
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01279': '', 'JYU01280': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
            'JYU01771': '', 'JYU01772': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01102': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '',
            'JYU01118': '', 'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '',
            'JYU01132': '', 'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '',
            'JYU01139': '', 'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '',
            'JYU01200': '', 'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '',
            'JYU01215': '', 'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '',
            'JYU01449': '', 'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '',
            'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
            'JYU01481': '', 'JYU01482': '', 'JYU01483': '', 'JYU01484': '', 'JYU01485': '', 'JYU01486': '', 'JYU01487': '',
            'JYU01488': '', 'JYU01489': '', 'JYU01490': '', 'JYU01491': '', 'JYU01492': '', 'JYU01493': '', 'JYU01494': '',
            'JYU01495': '', 'JYU01496': '', 'JYU01503': '', 'JYU01504': '', 'JYU01505': '', 'JYU01506': '', 'JYU01507': '',
            'JYU01508': '', 'JYU01509': '', 'JYU01510': '', 'JYU01511': '', 'JYU01512': '', 'JYU01513': '', 'JYU01514': '',
            'JYU01515': '', 'JYU01516': '', 'JYU01517': '', 'JYU01518': '', 'JYU01519': '', 'JYU01520': '', 'JYU01521': '',
            'JYU01522': '', 'JYU01523': '', 'JYU01524': '', 'JYU01525': '', 'JYU01526': '', 'JYU01527': '', 'JYU01528': '',
            'JYU01529': '', 'JYU01530': '', 'JYU01531': '', 'JYU01532': '', 'JYU01533': '', 'JYU01534': '', 'JYU01535': '',
            'JYU01536': '', 'JYU01537': '', 'JYU01538': '', 'JYU01539': '', 'JYU01540': '', 'JYU01543': '', 'JYU01544': '',
            'JYU01545': '', 'JYU01546': '', 'JYU01547': '', 'JYU01765': '', 'JYU01766': '', 'JYU01775': '',
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00135': '', 'FUD00158': '', 'FUD00161': '', 'FUD00162': '', 'FUD00163': '', 'FUD00164': '', 'FUD00167': '',
            'FUD00174': '', 'FUD00177': '', 'FUD00178': '', 'FUD00179': '', 'FUD00180': '', 'FUD00278': '', 'FUD00281': '',
            'FUD00353': '', 'FUD00354': '', 'FUD00355': '', 'FUD00356': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '',
            'FUD00380': '', 'FUD00381': '', 'FUD00382': '', 'FUD00383': '', 'FUD00384': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0301_FRK売主宅建業者用・土地（実測・清算）
    '0301': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '',
            'TOR00072_ARR': { 'TOR00072': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00009': '', 'JYU00010': '', 'JYU00011': '',
            'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '', 'JYU00021': '',
            'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '', 'JYU00048': '',
            'JYU00049': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '', 'JYU00057': '', 'JYU00093': '',
            'JYU00094': '', 'JYU00096': '', 'JYU00097': '', 'JYU00098': '', 'JYU00100': '', 'JYU00101': '', 'JYU00102': '',
            'JYU00103': '', 'JYU00106': '', 'JYU00107': '', 'JYU00108': '', 'JYU00111': '', 'JYU00112': '', 'JYU00113': '',
            'JYU00115': '', 'JYU01461': '', 'JYU01463': '', 'JYU01464': '', 'JYU01465': '', 'JYU01466': '', 'JYU01571': '',
            'JYU01746': '', 'JYU01762': '', 'JYU01768': '', 'JYU01769': '', 'JYU01792': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00296': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '',
            'JYU00327': '', 'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '',
            'JYU01579': '', 'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01104': '', 'JYU01110': '', 'JYU01121': '', 'JYU01124': '',
            'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '', 'JYU01133': '', 'JYU01134': '',
            'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '', 'JYU01140': '', 'JYU01141': '',
            'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '', 'JYU01209': '', 'JYU01210': '',
            'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '', 'JYU01215': '', 'JYU01275': '', 'JYU01441': '',
            'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01449': '', 'JYU01719': '', 'JYU01720': '', 'JYU01726': '',
            'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU00787': '', 'JYU00788': '', 'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '',
            'JYU01034': '', 'JYU01457': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '',
            'JYU01553': '', 'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '', 'JYU01760': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '', 'JYU01776': '', 'JYU01777': '',
        },
        '0301': {
            'FUD00135': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '', 'FUD00389': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0302_FRK売主宅建業者用・土地（確定測量・清算）
    '0302': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '',
            'TOR00072_ARR': { 'TOR00072': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00009': '', 'JYU00010': '', 'JYU00011': '',
            'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '', 'JYU00021': '',
            'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '', 'JYU00048': '',
            'JYU00049': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '', 'JYU00057': '', 'JYU00093': '',
            'JYU00094': '', 'JYU00096': '', 'JYU00097': '', 'JYU00098': '', 'JYU00100': '', 'JYU00101': '', 'JYU00102': '',
            'JYU00103': '', 'JYU00106': '', 'JYU00107': '', 'JYU00108': '', 'JYU00111': '', 'JYU00112': '', 'JYU00113': '',
            'JYU00115': '', 'JYU01461': '', 'JYU01463': '', 'JYU01464': '', 'JYU01465': '', 'JYU01466': '', 'JYU01571': '',
            'JYU01746': '', 'JYU01762': '', 'JYU01768': '', 'JYU01769': '', 'JYU01792': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00296': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '',
            'JYU00327': '', 'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '',
            'JYU01579': '', 'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01104': '', 'JYU01110': '', 'JYU01121': '', 'JYU01124': '',
            'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '', 'JYU01133': '', 'JYU01134': '',
            'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '', 'JYU01140': '', 'JYU01141': '',
            'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '', 'JYU01209': '', 'JYU01210': '',
            'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '', 'JYU01215': '', 'JYU01275': '', 'JYU01441': '',
            'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01449': '', 'JYU01719': '', 'JYU01720': '', 'JYU01726': '',
            'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU00787': '', 'JYU00788': '', 'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '',
            'JYU01034': '', 'JYU01457': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '',
            'JYU01553': '', 'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '', 'JYU01760': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '', 'JYU01776': '', 'JYU01777': '',
        },
        '0301': {
            'FUD00135': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '', 'FUD00389': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0303_FRK売主宅建業者用・土地（売買代金固定）
    '0303': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '',
            'TOR00072_ARR': { 'TOR00072': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00009': '', 'JYU00010': '', 'JYU00011': '',
            'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '', 'JYU00021': '',
            'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '', 'JYU00048': '',
            'JYU00049': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '', 'JYU00057': '', 'JYU00093': '',
            'JYU00094': '', 'JYU00096': '', 'JYU00097': '', 'JYU00098': '', 'JYU00100': '', 'JYU00101': '', 'JYU00102': '',
            'JYU00103': '', 'JYU00106': '', 'JYU00107': '', 'JYU00108': '', 'JYU00111': '', 'JYU00112': '', 'JYU00113': '',
            'JYU00115': '', 'JYU01461': '', 'JYU01463': '', 'JYU01464': '', 'JYU01465': '', 'JYU01466': '', 'JYU01571': '',
            'JYU01746': '', 'JYU01762': '', 'JYU01768': '', 'JYU01769': '', 'JYU01792': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00296': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '',
            'JYU00327': '', 'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '',
            'JYU01579': '', 'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01104': '', 'JYU01110': '', 'JYU01121': '', 'JYU01124': '',
            'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '', 'JYU01133': '', 'JYU01134': '',
            'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '', 'JYU01140': '', 'JYU01141': '',
            'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '', 'JYU01209': '', 'JYU01210': '',
            'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '', 'JYU01215': '', 'JYU01275': '', 'JYU01441': '',
            'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01449': '', 'JYU01719': '', 'JYU01720': '', 'JYU01726': '',
            'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU00787': '', 'JYU00788': '', 'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '',
            'JYU01034': '', 'JYU01457': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '',
            'JYU01553': '', 'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '', 'JYU01760': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '', 'JYU01776': '', 'JYU01777': '',
        },
        '0301': {
            'FUD00135': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '', 'FUD00389': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0304_FRK売主宅建業者用・土地建物（実測・清算）
    '0304': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00258': '', 'TOR00259': '', 'TOR00260': '', 'TOR00261': '', 'TOR00262': '',
            'TOR00316': '', 'TOR00317': '', 'TOR00318': '', 'TOR00319': '', 'TOR00320': '', 'TOR00321': '', 'TOR00322': '',
            'TOR00323': '', 'TOR00324': '', 'TOR00325': '', 'TOR00326': '', 'TOR00327': '', 'TOR00328': '', 'TOR00329': '',
            'TOR00330': '', 'TOR00331': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '', 'TOR00355': '',
            'TOR00072_ARR': { 'TOR00072': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00009': '', 'JYU00010': '', 'JYU00011': '',
            'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '', 'JYU00021': '',
            'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '', 'JYU00048': '',
            'JYU00049': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '', 'JYU00057': '', 'JYU00093': '',
            'JYU00094': '', 'JYU00096': '', 'JYU00097': '', 'JYU00098': '', 'JYU00100': '', 'JYU00101': '', 'JYU00102': '',
            'JYU00103': '', 'JYU00106': '', 'JYU00107': '', 'JYU00108': '', 'JYU00111': '', 'JYU00112': '', 'JYU00113': '',
            'JYU00115': '', 'JYU01461': '', 'JYU01463': '', 'JYU01464': '', 'JYU01465': '', 'JYU01466': '', 'JYU01571': '',
            'JYU01746': '', 'JYU01762': '', 'JYU01768': '', 'JYU01769': '', 'JYU01792': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '', 'JYU00295': '',
            'JYU00296': '', 'JYU00322': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '',
            'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '', 'JYU01579': '',
            'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '', 'JYU01789': '',
        },
        '0203': {
            'JYU00341': '', 'JYU00343': '', 'JYU00344': '', 'JYU00345': '', 'JYU00352': '', 'JYU00353': '', 'JYU00359': '',
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01279': '', 'JYU01280': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
            'JYU01771': '', 'JYU01772': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '', 'JYU01118': '',
            'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '',
            'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '',
            'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '',
            'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '', 'JYU01215': '',
            'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '', 'JYU01449': '',
            'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00135': '', 'FUD00278': '', 'FUD00281': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '', 'FUD00380': '',
            'FUD00381': '', 'FUD00382': '', 'FUD00383': '', 'FUD00384': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '', 'FUD00389': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0305_FRK売主宅建業者用・土地建物（確定測量・清算）
    '0305': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00258': '', 'TOR00259': '', 'TOR00260': '', 'TOR00261': '', 'TOR00262': '',
            'TOR00316': '', 'TOR00317': '', 'TOR00318': '', 'TOR00319': '', 'TOR00320': '', 'TOR00321': '', 'TOR00322': '',
            'TOR00323': '', 'TOR00324': '', 'TOR00325': '', 'TOR00326': '', 'TOR00327': '', 'TOR00328': '', 'TOR00329': '',
            'TOR00330': '', 'TOR00331': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '', 'TOR00355': '',
            'TOR00072_ARR': { 'TOR00072': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00009': '', 'JYU00010': '', 'JYU00011': '',
            'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '', 'JYU00021': '',
            'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '', 'JYU00048': '',
            'JYU00049': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '', 'JYU00057': '', 'JYU00093': '',
            'JYU00094': '', 'JYU00096': '', 'JYU00097': '', 'JYU00098': '', 'JYU00100': '', 'JYU00101': '', 'JYU00102': '',
            'JYU00103': '', 'JYU00106': '', 'JYU00107': '', 'JYU00108': '', 'JYU00111': '', 'JYU00112': '', 'JYU00113': '',
            'JYU00115': '', 'JYU01461': '', 'JYU01463': '', 'JYU01464': '', 'JYU01465': '', 'JYU01466': '', 'JYU01571': '',
            'JYU01746': '', 'JYU01762': '', 'JYU01768': '', 'JYU01769': '', 'JYU01792': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '', 'JYU00295': '',
            'JYU00296': '', 'JYU00322': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '',
            'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '', 'JYU01579': '',
            'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '', 'JYU01789': '',
        },
        '0203': {
            'JYU00341': '', 'JYU00343': '', 'JYU00344': '', 'JYU00345': '', 'JYU00352': '', 'JYU00353': '', 'JYU00359': '',
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01279': '', 'JYU01280': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
            'JYU01771': '', 'JYU01772': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '', 'JYU01118': '',
            'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '',
            'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '',
            'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '',
            'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '', 'JYU01215': '',
            'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '', 'JYU01449': '',
            'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00135': '', 'FUD00278': '', 'FUD00281': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '', 'FUD00380': '',
            'FUD00381': '', 'FUD00382': '', 'FUD00383': '', 'FUD00384': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '', 'FUD00389': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0306_FRK売主宅建業者用・土地建物（売買代金固定）
    '0306': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00258': '', 'TOR00259': '', 'TOR00260': '', 'TOR00261': '', 'TOR00262': '',
            'TOR00316': '', 'TOR00317': '', 'TOR00318': '', 'TOR00319': '', 'TOR00320': '', 'TOR00321': '', 'TOR00322': '',
            'TOR00323': '', 'TOR00324': '', 'TOR00325': '', 'TOR00326': '', 'TOR00327': '', 'TOR00328': '', 'TOR00329': '',
            'TOR00330': '', 'TOR00331': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '', 'TOR00355': '',
            'TOR00072_ARR': { 'TOR00072': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00009': '', 'JYU00010': '', 'JYU00011': '',
            'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '', 'JYU00021': '',
            'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '', 'JYU00048': '',
            'JYU00049': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '', 'JYU00057': '', 'JYU00093': '',
            'JYU00094': '', 'JYU00096': '', 'JYU00097': '', 'JYU00098': '', 'JYU00100': '', 'JYU00101': '', 'JYU00102': '',
            'JYU00103': '', 'JYU00106': '', 'JYU00107': '', 'JYU00108': '', 'JYU00111': '', 'JYU00112': '', 'JYU00113': '',
            'JYU00115': '', 'JYU01461': '', 'JYU01463': '', 'JYU01464': '', 'JYU01465': '', 'JYU01466': '', 'JYU01571': '',
            'JYU01746': '', 'JYU01762': '', 'JYU01768': '', 'JYU01769': '', 'JYU01792': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '', 'JYU00295': '',
            'JYU00296': '', 'JYU00322': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '',
            'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '', 'JYU01579': '',
            'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '', 'JYU01789': '',
        },
        '0203': {
            'JYU00341': '', 'JYU00343': '', 'JYU00344': '', 'JYU00345': '', 'JYU00352': '', 'JYU00353': '', 'JYU00359': '',
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01279': '', 'JYU01280': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
            'JYU01771': '', 'JYU01772': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '', 'JYU01118': '',
            'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '',
            'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '',
            'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '',
            'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '', 'JYU01215': '',
            'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '', 'JYU01449': '',
            'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00135': '', 'FUD00278': '', 'FUD00281': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '', 'FUD00380': '',
            'FUD00381': '', 'FUD00382': '', 'FUD00383': '', 'FUD00384': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '', 'FUD00389': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0307_FRK売主宅建業者用・区分所有建物（敷地権）
    '0307': {
        '0101': {
            'TOR00205': '', 'TOR00206': '', 'TOR00207': '', 'TOR00208': '', 'TOR00209': '', 'TOR00210': '', 'TOR00211': '',
            'TOR00212': '', 'TOR00213': '', 'TOR00214': '', 'TOR00215': '', 'TOR00216': '', 'TOR00235': '', 'TOR00236': '',
            'TOR00300': '', 'TOR00301': '', 'TOR00302': '', 'TOR00306': '', 'TOR00307': '', 'TOR00308': '', 'TOR00309': '',
            'TOR00310': '', 'TOR00311': '', 'TOR00312': '', 'TOR00314': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '',
            'TOR00352': '', 'TOR00353': '', 'TOR00354': '', 'TOR00356': '',
            'TOR00072_ARR': { 'TOR00072': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00217_ARR': { 'TOR00217': '', 'TOR00218': '', 'TOR00219': '', 'TOR00221': '', 'TOR00315': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00009': '', 'JYU00010': '', 'JYU00011': '',
            'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '', 'JYU00021': '',
            'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '', 'JYU00048': '',
            'JYU00049': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '', 'JYU00057': '', 'JYU00093': '',
            'JYU00094': '', 'JYU00096': '', 'JYU00097': '', 'JYU00098': '', 'JYU00100': '', 'JYU00101': '', 'JYU00102': '',
            'JYU00103': '', 'JYU00106': '', 'JYU00107': '', 'JYU00108': '', 'JYU00111': '', 'JYU00112': '', 'JYU00113': '',
            'JYU00115': '', 'JYU01461': '', 'JYU01463': '', 'JYU01464': '', 'JYU01465': '', 'JYU01466': '', 'JYU01571': '',
            'JYU01746': '', 'JYU01762': '', 'JYU01768': '', 'JYU01769': '', 'JYU01792': '',
        },
        '0202': {
            'JYU00147': '', 'JYU00194': '', 'JYU00195': '', 'JYU00196': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '',
            'JYU00326': '', 'JYU00327': '', 'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '',
            'JYU01455': '', 'JYU01577': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01750': '', 'JYU01778': '', 'JYU01779': '', 'JYU01780': '', 'JYU01781': '',
            'JYU01782': '', 'JYU01783': '', 'JYU01784': '', 'JYU01785': '', 'JYU01786': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00537': '', 'JYU00538': '',
            'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00543': '', 'JYU00544': '', 'JYU00545': '', 'JYU00546': '',
            'JYU00547': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '', 'JYU00552': '', 'JYU00553': '', 'JYU00554': '',
            'JYU00555': '', 'JYU00556': '', 'JYU00557': '', 'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00566': '',
            'JYU00567': '', 'JYU00568': '', 'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '',
            'JYU00577': '', 'JYU00578': '', 'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '',
            'JYU01329': '', 'JYU01330': '', 'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '',
            'JYU01336': '', 'JYU01337': '', 'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '',
            'JYU01343': '', 'JYU01432': '', 'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '',
            'JYU01593': '', 'JYU01594': '', 'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '',
            'JYU01600': '', 'JYU01601': '', 'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '',
            'JYU01608': '', 'JYU01609': '', 'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '',
            'JYU01646': '', 'JYU01647': '', 'JYU01648': '', 'JYU01649': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00714': '', 'JYU00716': '', 'JYU00718': '', 'JYU00721': '',
            'JYU00723': '', 'JYU00720': '', 'JYU00731': '', 'JYU00732': '', 'JYU00733': '', 'JYU00741': '', 'JYU00743': '',
            'JYU00745': '', 'JYU00746': '', 'JYU00747': '', 'JYU00749': '', 'JYU00751': '', 'JYU00785': '', 'JYU01387': '',
            'JYU01392': '', 'JYU01393': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01618': '', 'JYU01622': '',
            'JYU01623': '', 'JYU01624': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01628': '', 'JYU01629': '',
            'JYU01630': '', 'JYU01631': '', 'JYU01632': '', 'JYU01633': '', 'JYU01634': '',
        },
        '0208': {
            'JYU00790': '', 'JYU00791': '', 'JYU00792': '', 'JYU00793': '', 'JYU00794': '', 'JYU00795': '', 'JYU00796': '',
            'JYU00797': '', 'JYU00798': '', 'JYU00799': '', 'JYU00800': '', 'JYU00801': '', 'JYU00804': '', 'JYU00805': '',
            'JYU00809': '', 'JYU00810': '', 'JYU00812': '', 'JYU00813': '', 'JYU00814': '', 'JYU00816': '', 'JYU00817': '',
            'JYU00818': '', 'JYU00819': '', 'JYU00820': '', 'JYU00821': '', 'JYU00822': '', 'JYU00823': '', 'JYU00824': '',
            'JYU00825': '', 'JYU00826': '', 'JYU00827': '', 'JYU00828': '', 'JYU00829': '', 'JYU00830': '', 'JYU00833': '',
            'JYU01401': '', 'JYU01402': '', 'JYU01407': '', 'JYU01413': '', 'JYU01418': '', 'JYU01657': '', 'JYU01658': '',
            'JYU01659': '', 'JYU01660': '', 'JYU01661': '', 'JYU01662': '', 'JYU01773': '', 'JYU01774': '',
        },
        '0209': {
            'JYU00835': '', 'JYU00836': '', 'JYU00837': '', 'JYU00838': '', 'JYU00839': '', 'JYU00840': '', 'JYU00841': '',
            'JYU00842': '', 'JYU00843': '', 'JYU00844': '', 'JYU00845': '', 'JYU00846': '', 'JYU00847': '', 'JYU00848': '',
            'JYU00849': '', 'JYU00850': '', 'JYU00851': '', 'JYU00852': '', 'JYU00853': '', 'JYU00854': '', 'JYU00855': '',
            'JYU00856': '', 'JYU00857': '', 'JYU00858': '', 'JYU00859': '', 'JYU00860': '', 'JYU00861': '', 'JYU00862': '',
            'JYU00863': '', 'JYU00864': '', 'JYU00865': '', 'JYU00866': '', 'JYU00867': '', 'JYU00868': '', 'JYU00869': '',
            'JYU00870': '', 'JYU00871': '', 'JYU00872': '', 'JYU00873': '', 'JYU00874': '', 'JYU00875': '', 'JYU00876': '',
            'JYU00877': '', 'JYU00878': '', 'JYU00879': '', 'JYU00880': '', 'JYU00881': '', 'JYU00882': '', 'JYU00883': '',
            'JYU00884': '', 'JYU00885': '', 'JYU00886': '', 'JYU00887': '', 'JYU00888': '', 'JYU00889': '', 'JYU00890': '',
            'JYU00891': '', 'JYU00892': '', 'JYU00893': '', 'JYU00894': '', 'JYU00895': '', 'JYU00896': '', 'JYU00897': '',
            'JYU00898': '', 'JYU00899': '', 'JYU00901': '', 'JYU00902': '', 'JYU00903': '', 'JYU00904': '', 'JYU00905': '',
            'JYU00906': '', 'JYU00907': '', 'JYU00908': '', 'JYU00909': '', 'JYU00910': '', 'JYU00911': '', 'JYU00912': '',
            'JYU00913': '', 'JYU00914': '', 'JYU00915': '', 'JYU00916': '', 'JYU00917': '', 'JYU00918': '', 'JYU00934': '',
            'JYU00935': '', 'JYU00936': '', 'JYU00937': '', 'JYU01308': '', 'JYU01309': '', 'JYU01310': '', 'JYU01311': '',
            'JYU01312': '', 'JYU01313': '', 'JYU01314': '', 'JYU01315': '', 'JYU01316': '', 'JYU01317': '', 'JYU01318': '',
            'JYU01319': '', 'JYU01320': '', 'JYU01321': '', 'JYU01322': '', 'JYU01323': '', 'JYU01324': '', 'JYU01325': '',
            'JYU01326': '', 'JYU01663': '', 'JYU01791': '',
            'JYU00919_ARR': { 'JYU00919': '', 'JYU00920': '', },
        },
        '0210': {
            'JYU00938': '', 'JYU00939': '', 'JYU00940': '', 'JYU00941': '', 'JYU00942': '', 'JYU00943': '', 'JYU00951': '',
            'JYU00952': '', 'JYU00953': '', 'JYU00954': '', 'JYU00955': '', 'JYU00956': '', 'JYU00958': '', 'JYU00959': '',
            'JYU00960': '', 'JYU00961': '', 'JYU00962': '', 'JYU00963': '', 'JYU00964': '', 'JYU00965': '', 'JYU00967': '',
            'JYU00968': '', 'JYU00969': '', 'JYU00970': '', 'JYU00971': '', 'JYU00972': '', 'JYU00973': '', 'JYU00975': '',
            'JYU00976': '', 'JYU00977': '', 'JYU00978': '', 'JYU00979': '', 'JYU01301': '', 'JYU01302': '', 'JYU01303': '',
            'JYU01304': '', 'JYU01305': '', 'JYU01451': '', 'JYU01452': '', 'JYU01664': '', 'JYU01665': '', 'JYU01666': '',
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '', 'JYU01118': '',
            'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '',
            'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '',
            'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '',
            'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '', 'JYU01719': '',
            'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
            'JYU01481': '', 'JYU01482': '', 'JYU01483': '', 'JYU01484': '', 'JYU01485': '', 'JYU01486': '', 'JYU01487': '',
            'JYU01488': '', 'JYU01489': '', 'JYU01490': '', 'JYU01491': '', 'JYU01492': '', 'JYU01493': '', 'JYU01494': '',
            'JYU01495': '', 'JYU01496': '', 'JYU01503': '', 'JYU01504': '', 'JYU01505': '', 'JYU01506': '', 'JYU01507': '',
            'JYU01508': '', 'JYU01509': '', 'JYU01510': '', 'JYU01511': '', 'JYU01512': '', 'JYU01513': '', 'JYU01514': '',
            'JYU01515': '', 'JYU01516': '', 'JYU01517': '', 'JYU01518': '', 'JYU01519': '', 'JYU01520': '', 'JYU01521': '',
            'JYU01522': '', 'JYU01523': '', 'JYU01524': '', 'JYU01525': '', 'JYU01526': '', 'JYU01527': '', 'JYU01528': '',
            'JYU01529': '', 'JYU01530': '', 'JYU01531': '', 'JYU01532': '', 'JYU01533': '', 'JYU01534': '', 'JYU01535': '',
            'JYU01536': '', 'JYU01537': '', 'JYU01538': '', 'JYU01539': '', 'JYU01540': '', 'JYU01543': '', 'JYU01544': '',
            'JYU01545': '', 'JYU01546': '', 'JYU01547': '', 'JYU01765': '', 'JYU01766': '', 'JYU01775': '',
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00066': '', 'FUD00067': '', 'FUD00070': '', 'FUD00072': '', 'FUD00073': '', 'FUD00075': '', 'FUD00135': '',
            'FUD00278': '', 'FUD00281': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '', 'FUD00379': '', 'FUD00380': '',
            'FUD00381': '', 'FUD00382': '', 'FUD00383': '', 'FUD00384': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '', 'FUD00389': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0308_FRK売主宅建業者用・区分所有建物（非敷地権）
    '0308': {
        '0101': {
            'TOR00205': '', 'TOR00206': '', 'TOR00207': '', 'TOR00208': '', 'TOR00209': '', 'TOR00210': '', 'TOR00211': '',
            'TOR00212': '', 'TOR00213': '', 'TOR00214': '', 'TOR00215': '', 'TOR00216': '', 'TOR00256': '', 'TOR00257': '',
            'TOR00300': '', 'TOR00301': '', 'TOR00302': '', 'TOR00306': '', 'TOR00307': '', 'TOR00308': '', 'TOR00309': '',
            'TOR00310': '', 'TOR00311': '', 'TOR00312': '', 'TOR00314': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '',
            'TOR00352': '', 'TOR00353': '', 'TOR00354': '', 'TOR00356': '',
            'TOR00072_ARR': { 'TOR00072': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00009': '', 'JYU00010': '', 'JYU00011': '',
            'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '', 'JYU00021': '',
            'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '', 'JYU00048': '',
            'JYU00049': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '', 'JYU00057': '', 'JYU00093': '',
            'JYU00094': '', 'JYU00096': '', 'JYU00097': '', 'JYU00098': '', 'JYU00100': '', 'JYU00101': '', 'JYU00102': '',
            'JYU00103': '', 'JYU00106': '', 'JYU00107': '', 'JYU00108': '', 'JYU00111': '', 'JYU00112': '', 'JYU00113': '',
            'JYU00115': '', 'JYU01461': '', 'JYU01463': '', 'JYU01464': '', 'JYU01465': '', 'JYU01466': '', 'JYU01571': '',
            'JYU01746': '', 'JYU01762': '', 'JYU01768': '', 'JYU01769': '', 'JYU01792': '',
        },
        '0202': {
            'JYU00147': '', 'JYU00194': '', 'JYU00195': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '',
            'JYU00296': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '', 'JYU00328': '',
            'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01455': '', 'JYU01577': '',
        },
        '0203': {
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01750': '', 'JYU01778': '', 'JYU01779': '', 'JYU01780': '', 'JYU01781': '',
            'JYU01782': '', 'JYU01783': '', 'JYU01784': '', 'JYU01785': '', 'JYU01786': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00537': '', 'JYU00538': '',
            'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00543': '', 'JYU00544': '', 'JYU00545': '', 'JYU00546': '',
            'JYU00547': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '', 'JYU00552': '', 'JYU00553': '', 'JYU00554': '',
            'JYU00555': '', 'JYU00556': '', 'JYU00557': '', 'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00566': '',
            'JYU00567': '', 'JYU00568': '', 'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '',
            'JYU00577': '', 'JYU00578': '', 'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '',
            'JYU01329': '', 'JYU01330': '', 'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '',
            'JYU01336': '', 'JYU01337': '', 'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '',
            'JYU01343': '', 'JYU01432': '', 'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '',
            'JYU01593': '', 'JYU01594': '', 'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '',
            'JYU01600': '', 'JYU01601': '', 'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '',
            'JYU01608': '', 'JYU01609': '', 'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '',
            'JYU01646': '', 'JYU01647': '', 'JYU01648': '', 'JYU01649': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00714': '', 'JYU00716': '', 'JYU00718': '', 'JYU00721': '',
            'JYU00723': '', 'JYU00720': '', 'JYU00731': '', 'JYU00732': '', 'JYU00733': '', 'JYU00741': '', 'JYU00743': '',
            'JYU00745': '', 'JYU00746': '', 'JYU00747': '', 'JYU00749': '', 'JYU00751': '', 'JYU00785': '', 'JYU01387': '',
            'JYU01392': '', 'JYU01393': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01618': '', 'JYU01622': '',
            'JYU01623': '', 'JYU01624': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01628': '', 'JYU01629': '',
            'JYU01630': '', 'JYU01631': '', 'JYU01632': '', 'JYU01633': '', 'JYU01634': '',
        },
        '0208': {
            'JYU00790': '', 'JYU00791': '', 'JYU00792': '', 'JYU00793': '', 'JYU00794': '', 'JYU00795': '', 'JYU00796': '',
            'JYU00797': '', 'JYU00798': '', 'JYU00799': '', 'JYU00800': '', 'JYU00801': '', 'JYU00804': '', 'JYU00805': '',
            'JYU00809': '', 'JYU00810': '', 'JYU00812': '', 'JYU00813': '', 'JYU00814': '', 'JYU00816': '', 'JYU00817': '',
            'JYU00818': '', 'JYU00819': '', 'JYU00820': '', 'JYU00821': '', 'JYU00822': '', 'JYU00823': '', 'JYU00824': '',
            'JYU00825': '', 'JYU00826': '', 'JYU00827': '', 'JYU00828': '', 'JYU00829': '', 'JYU00830': '', 'JYU00833': '',
            'JYU01401': '', 'JYU01402': '', 'JYU01407': '', 'JYU01413': '', 'JYU01418': '', 'JYU01657': '', 'JYU01658': '',
            'JYU01659': '', 'JYU01660': '', 'JYU01661': '', 'JYU01662': '', 'JYU01773': '', 'JYU01774': '',
        },
        '0209': {
            'JYU00835': '', 'JYU00836': '', 'JYU00837': '', 'JYU00838': '', 'JYU00839': '', 'JYU00840': '', 'JYU00841': '',
            'JYU00842': '', 'JYU00843': '', 'JYU00844': '', 'JYU00845': '', 'JYU00846': '', 'JYU00847': '', 'JYU00848': '',
            'JYU00849': '', 'JYU00850': '', 'JYU00851': '', 'JYU00852': '', 'JYU00853': '', 'JYU00854': '', 'JYU00855': '',
            'JYU00856': '', 'JYU00857': '', 'JYU00858': '', 'JYU00859': '', 'JYU00860': '', 'JYU00861': '', 'JYU00862': '',
            'JYU00863': '', 'JYU00864': '', 'JYU00865': '', 'JYU00866': '', 'JYU00867': '', 'JYU00868': '', 'JYU00869': '',
            'JYU00870': '', 'JYU00871': '', 'JYU00872': '', 'JYU00873': '', 'JYU00874': '', 'JYU00875': '', 'JYU00876': '',
            'JYU00877': '', 'JYU00878': '', 'JYU00879': '', 'JYU00880': '', 'JYU00881': '', 'JYU00882': '', 'JYU00883': '',
            'JYU00884': '', 'JYU00885': '', 'JYU00886': '', 'JYU00887': '', 'JYU00888': '', 'JYU00889': '', 'JYU00890': '',
            'JYU00891': '', 'JYU00892': '', 'JYU00893': '', 'JYU00894': '', 'JYU00895': '', 'JYU00896': '', 'JYU00897': '',
            'JYU00898': '', 'JYU00899': '', 'JYU00901': '', 'JYU00902': '', 'JYU00903': '', 'JYU00904': '', 'JYU00905': '',
            'JYU00906': '', 'JYU00907': '', 'JYU00908': '', 'JYU00909': '', 'JYU00910': '', 'JYU00911': '', 'JYU00912': '',
            'JYU00913': '', 'JYU00914': '', 'JYU00915': '', 'JYU00916': '', 'JYU00917': '', 'JYU00918': '', 'JYU00934': '',
            'JYU00935': '', 'JYU00936': '', 'JYU00937': '', 'JYU01308': '', 'JYU01309': '', 'JYU01310': '', 'JYU01311': '',
            'JYU01312': '', 'JYU01313': '', 'JYU01314': '', 'JYU01315': '', 'JYU01316': '', 'JYU01317': '', 'JYU01318': '',
            'JYU01319': '', 'JYU01320': '', 'JYU01321': '', 'JYU01322': '', 'JYU01323': '', 'JYU01324': '', 'JYU01325': '',
            'JYU01326': '', 'JYU01663': '', 'JYU01791': '',
            'JYU00919_ARR': { 'JYU00919': '', 'JYU00920': '', },
        },
        '0210': {
            'JYU00938': '', 'JYU00939': '', 'JYU00940': '', 'JYU00941': '', 'JYU00942': '', 'JYU00943': '', 'JYU00951': '',
            'JYU00952': '', 'JYU00953': '', 'JYU00954': '', 'JYU00955': '', 'JYU00956': '', 'JYU00958': '', 'JYU00959': '',
            'JYU00960': '', 'JYU00961': '', 'JYU00962': '', 'JYU00963': '', 'JYU00964': '', 'JYU00965': '', 'JYU00967': '',
            'JYU00968': '', 'JYU00969': '', 'JYU00970': '', 'JYU00971': '', 'JYU00972': '', 'JYU00973': '', 'JYU00975': '',
            'JYU00976': '', 'JYU00977': '', 'JYU00978': '', 'JYU00979': '', 'JYU01301': '', 'JYU01302': '', 'JYU01303': '',
            'JYU01304': '', 'JYU01305': '', 'JYU01451': '', 'JYU01452': '', 'JYU01664': '', 'JYU01665': '', 'JYU01666': '',
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '', 'JYU01118': '',
            'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '',
            'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '',
            'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '',
            'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '', 'JYU01719': '',
            'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
            'JYU01481': '', 'JYU01482': '', 'JYU01483': '', 'JYU01484': '', 'JYU01485': '', 'JYU01486': '', 'JYU01487': '',
            'JYU01488': '', 'JYU01489': '', 'JYU01490': '', 'JYU01491': '', 'JYU01492': '', 'JYU01493': '', 'JYU01494': '',
            'JYU01495': '', 'JYU01496': '', 'JYU01503': '', 'JYU01504': '', 'JYU01505': '', 'JYU01506': '', 'JYU01507': '',
            'JYU01508': '', 'JYU01509': '', 'JYU01510': '', 'JYU01511': '', 'JYU01512': '', 'JYU01513': '', 'JYU01514': '',
            'JYU01515': '', 'JYU01516': '', 'JYU01517': '', 'JYU01518': '', 'JYU01519': '', 'JYU01520': '', 'JYU01521': '',
            'JYU01522': '', 'JYU01523': '', 'JYU01524': '', 'JYU01525': '', 'JYU01526': '', 'JYU01527': '', 'JYU01528': '',
            'JYU01529': '', 'JYU01530': '', 'JYU01531': '', 'JYU01532': '', 'JYU01533': '', 'JYU01534': '', 'JYU01535': '',
            'JYU01536': '', 'JYU01537': '', 'JYU01538': '', 'JYU01539': '', 'JYU01540': '', 'JYU01543': '', 'JYU01544': '',
            'JYU01545': '', 'JYU01546': '', 'JYU01547': '', 'JYU01765': '', 'JYU01766': '', 'JYU01775': '',
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00066': '', 'FUD00067': '', 'FUD00070': '', 'FUD00072': '', 'FUD00073': '', 'FUD00075': '', 'FUD00135': '',
            'FUD00158': '', 'FUD00278': '', 'FUD00281': '', 'FUD00353': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '',
            'FUD00379': '', 'FUD00380': '', 'FUD00381': '', 'FUD00382': '', 'FUD00383': '', 'FUD00384': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '', 'FUD00389': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0309_FRK売主宅建業者用・新築・土地建物
    '0309': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00258': '', 'TOR00259': '', 'TOR00260': '', 'TOR00261': '', 'TOR00262': '',
            'TOR00316': '', 'TOR00317': '', 'TOR00318': '', 'TOR00319': '', 'TOR00320': '', 'TOR00321': '', 'TOR00322': '',
            'TOR00323': '', 'TOR00324': '', 'TOR00325': '', 'TOR00326': '', 'TOR00327': '', 'TOR00328': '', 'TOR00329': '',
            'TOR00330': '', 'TOR00331': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '', 'TOR00355': '',
            'TOR00072_ARR': { 'TOR00072': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00009': '', 'JYU00010': '', 'JYU00011': '',
            'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '', 'JYU00021': '',
            'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '', 'JYU00048': '',
            'JYU00049': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '', 'JYU00057': '', 'JYU00093': '',
            'JYU00094': '', 'JYU00096': '', 'JYU00097': '', 'JYU00098': '', 'JYU00100': '', 'JYU00101': '', 'JYU00102': '',
            'JYU00103': '', 'JYU00106': '', 'JYU00107': '', 'JYU00108': '', 'JYU00111': '', 'JYU00112': '', 'JYU00113': '',
            'JYU00115': '', 'JYU01461': '', 'JYU01463': '', 'JYU01464': '', 'JYU01465': '', 'JYU01466': '', 'JYU01571': '',
            'JYU01746': '', 'JYU01762': '', 'JYU01768': '', 'JYU01769': '', 'JYU01792': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '', 'JYU00295': '',
            'JYU00296': '', 'JYU00322': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '',
            'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '', 'JYU01579': '',
            'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '', 'JYU01789': '',
        },
        '0203': {
            'JYU00341': '', 'JYU00343': '', 'JYU00344': '', 'JYU00345': '', 'JYU00352': '', 'JYU00353': '', 'JYU00359': '',
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01279': '', 'JYU01280': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
            'JYU01771': '', 'JYU01772': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '', 'JYU01118': '',
            'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '',
            'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '',
            'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '',
            'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '', 'JYU01215': '',
            'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '', 'JYU01449': '',
            'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00135': '', 'FUD00380': '', 'FUD00381': '', 'FUD00382': '', 'FUD00383': '', 'FUD00384': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '', 'FUD00365': '', 'FUD00366': '', 'FUD00367': '', 'FUD00368': '',
            'FUD00369': '', 'FUD00370': '', 'FUD00371': '', 'FUD00390': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '', 'FUD00389': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0310_FRK売主宅建業者用・借地権付建物
    '0310': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00258': '', 'TOR00259': '', 'TOR00260': '', 'TOR00261': '', 'TOR00262': '',
            'TOR00316': '', 'TOR00317': '', 'TOR00318': '', 'TOR00319': '', 'TOR00320': '', 'TOR00321': '', 'TOR00322': '',
            'TOR00323': '', 'TOR00324': '', 'TOR00325': '', 'TOR00326': '', 'TOR00327': '', 'TOR00328': '', 'TOR00329': '',
            'TOR00330': '', 'TOR00331': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '', 'TOR00355': '',
            'TOR00072_ARR': { 'TOR00072': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00009': '', 'JYU00010': '', 'JYU00011': '',
            'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '', 'JYU00021': '',
            'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '', 'JYU00048': '',
            'JYU00049': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '', 'JYU00057': '', 'JYU00093': '',
            'JYU00094': '', 'JYU00096': '', 'JYU00097': '', 'JYU00098': '', 'JYU00100': '', 'JYU00101': '', 'JYU00102': '',
            'JYU00103': '', 'JYU00106': '', 'JYU00107': '', 'JYU00108': '', 'JYU00111': '', 'JYU00112': '', 'JYU00113': '',
            'JYU00115': '', 'JYU01461': '', 'JYU01463': '', 'JYU01464': '', 'JYU01465': '', 'JYU01466': '', 'JYU01571': '',
            'JYU01746': '', 'JYU01762': '', 'JYU01768': '', 'JYU01769': '', 'JYU01792': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '', 'JYU00295': '',
            'JYU00296': '', 'JYU00322': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '',
            'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '', 'JYU01579': '',
            'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '', 'JYU01789': '',
        },
        '0203': {
            'JYU00341': '', 'JYU00343': '', 'JYU00344': '', 'JYU00345': '', 'JYU00352': '', 'JYU00353': '', 'JYU00359': '',
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01279': '', 'JYU01280': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
            'JYU01771': '', 'JYU01772': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '', 'JYU01118': '',
            'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '',
            'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '',
            'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '',
            'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '', 'JYU01215': '',
            'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '', 'JYU01449': '',
            'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
            'JYU01481': '', 'JYU01482': '', 'JYU01483': '', 'JYU01484': '', 'JYU01485': '', 'JYU01486': '', 'JYU01487': '',
            'JYU01488': '', 'JYU01489': '', 'JYU01490': '', 'JYU01491': '', 'JYU01492': '', 'JYU01493': '', 'JYU01494': '',
            'JYU01495': '', 'JYU01496': '', 'JYU01503': '', 'JYU01504': '', 'JYU01505': '', 'JYU01506': '', 'JYU01507': '',
            'JYU01508': '', 'JYU01509': '', 'JYU01510': '', 'JYU01511': '', 'JYU01512': '', 'JYU01513': '', 'JYU01514': '',
            'JYU01515': '', 'JYU01516': '', 'JYU01517': '', 'JYU01518': '', 'JYU01519': '', 'JYU01520': '', 'JYU01521': '',
            'JYU01522': '', 'JYU01523': '', 'JYU01524': '', 'JYU01525': '', 'JYU01526': '', 'JYU01527': '', 'JYU01528': '',
            'JYU01529': '', 'JYU01530': '', 'JYU01531': '', 'JYU01532': '', 'JYU01533': '', 'JYU01534': '', 'JYU01535': '',
            'JYU01536': '', 'JYU01537': '', 'JYU01538': '', 'JYU01539': '', 'JYU01540': '', 'JYU01543': '', 'JYU01544': '',
            'JYU01545': '', 'JYU01546': '', 'JYU01547': '', 'JYU01765': '', 'JYU01766': '', 'JYU01775': '',
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00135': '', 'FUD00158': '', 'FUD00161': '', 'FUD00162': '', 'FUD00163': '', 'FUD00164': '', 'FUD00167': '',
            'FUD00174': '', 'FUD00177': '', 'FUD00178': '', 'FUD00179': '', 'FUD00180': '', 'FUD00278': '', 'FUD00281': '',
            'FUD00353': '', 'FUD00354': '', 'FUD00355': '', 'FUD00356': '', 'FUD00363': '', 'FUD00364': '', 'FUD00373': '',
            'FUD00380': '', 'FUD00381': '', 'FUD00382': '', 'FUD00383': '', 'FUD00384': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '', 'FUD00389': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
    // 0311_FRK売主宅建業者用・新築・借地権付建物
    '0311': {
        '0101': {
            'TOR00256': '', 'TOR00257': '', 'TOR00258': '', 'TOR00259': '', 'TOR00260': '', 'TOR00261': '', 'TOR00262': '',
            'TOR00316': '', 'TOR00317': '', 'TOR00318': '', 'TOR00319': '', 'TOR00320': '', 'TOR00321': '', 'TOR00322': '',
            'TOR00323': '', 'TOR00324': '', 'TOR00325': '', 'TOR00326': '', 'TOR00327': '', 'TOR00328': '', 'TOR00329': '',
            'TOR00330': '', 'TOR00331': '', 'TOR00349': '', 'TOR00350': '', 'TOR00351': '', 'TOR00352': '', 'TOR00353': '',
            'TOR00354': '', 'TOR00355': '',
            'TOR00072_ARR': { 'TOR00072': '', },
            'TOR00094_ARR': { 'TOR00094': '', 'TOR00334': '', },
            'TOR00237_ARR': { 'TOR00237': '', 'TOR00238': '', 'TOR00239': '', 'TOR00240': '', 'TOR00241': '', 'TOR00243': '', },
        },
        '0102': {
            'TOR00030': '', 'TOR00031': '', 'TOR00032': '', 'TOR00033': '', 'TOR00268': '', 'TOR00269': '', 'TOR00271': '',
            'TOR00272': '', 'TOR00336': '', 'TOR00337': '', 'TOR00338': '', 'TOR00340': '', 'TOR00341': '', 'TOR00342': '',
            'TOR00343': '', 'TOR00344': '', 'TOR00345': '', 'TOR00346': '', 'TOR00009': '', 'TOR00010': '',
        },
        '0201': {
            'JYU00003': '', 'JYU00004': '', 'JYU00005': '', 'JYU00006': '', 'JYU00009': '', 'JYU00010': '', 'JYU00011': '',
            'JYU00013': '', 'JYU00014': '', 'JYU00016': '', 'JYU00017': '', 'JYU00019': '', 'JYU00020': '', 'JYU00021': '',
            'JYU00023': '', 'JYU00025': '', 'JYU00027': '', 'JYU00028': '', 'JYU00046': '', 'JYU00047': '', 'JYU00048': '',
            'JYU00049': '', 'JYU00052': '', 'JYU00053': '', 'JYU00054': '', 'JYU00056': '', 'JYU00057': '', 'JYU00093': '',
            'JYU00094': '', 'JYU00096': '', 'JYU00097': '', 'JYU00098': '', 'JYU00100': '', 'JYU00101': '', 'JYU00102': '',
            'JYU00103': '', 'JYU00106': '', 'JYU00107': '', 'JYU00108': '', 'JYU00111': '', 'JYU00112': '', 'JYU00113': '',
            'JYU00115': '', 'JYU01461': '', 'JYU01463': '', 'JYU01464': '', 'JYU01465': '', 'JYU01466': '', 'JYU01571': '',
            'JYU01746': '', 'JYU01762': '', 'JYU01768': '', 'JYU01769': '', 'JYU01792': '',
        },
        '0202': {
            'JYU00251': '', 'JYU00264': '', 'JYU00265': '', 'JYU00267': '', 'JYU00268': '', 'JYU00269': '', 'JYU00271': '',
            'JYU00272': '', 'JYU00273': '', 'JYU00291': '', 'JYU00292': '', 'JYU00293': '', 'JYU00294': '', 'JYU00295': '',
            'JYU00296': '', 'JYU00322': '', 'JYU00323': '', 'JYU00324': '', 'JYU00325': '', 'JYU00326': '', 'JYU00327': '',
            'JYU00328': '', 'JYU00329': '', 'JYU00330': '', 'JYU00331': '', 'JYU01328': '', 'JYU01578': '', 'JYU01579': '',
            'JYU01582': '', 'JYU01583': '', 'JYU01749': '', 'JYU01788': '', 'JYU01789': '',
        },
        '0203': {
            'JYU00341': '', 'JYU00343': '', 'JYU00344': '', 'JYU00345': '', 'JYU00352': '', 'JYU00353': '', 'JYU00359': '',
            'JYU00360': '', 'JYU00361': '', 'JYU00362': '', 'JYU00363': '', 'JYU00371': '', 'JYU00372': '', 'JYU00375': '',
            'JYU01277': '', 'JYU01278': '', 'JYU01279': '', 'JYU01280': '',
        },
        '0204': {
            'JYU00388': '', 'JYU00389': '', 'JYU00390': '', 'JYU00391': '', 'JYU00392': '', 'JYU00393': '', 'JYU00396': '',
            'JYU00398': '', 'JYU00400': '', 'JYU00402': '', 'JYU00403': '', 'JYU00404': '', 'JYU00405': '', 'JYU00406': '',
            'JYU00407': '', 'JYU00408': '', 'JYU00409': '', 'JYU00410': '', 'JYU00411': '', 'JYU00412': '', 'JYU00444': '',
            'JYU00445': '', 'JYU00446': '', 'JYU00447': '', 'JYU00448': '', 'JYU00449': '', 'JYU00450': '', 'JYU00451': '',
            'JYU00452': '', 'JYU00453': '', 'JYU00454': '', 'JYU00455': '', 'JYU00456': '', 'JYU00457': '', 'JYU00458': '',
            'JYU00459': '', 'JYU00463': '', 'JYU00464': '', 'JYU00465': '', 'JYU00466': '', 'JYU00467': '', 'JYU00468': '',
            'JYU00469': '', 'JYU00470': '', 'JYU00471': '', 'JYU00472': '', 'JYU00473': '', 'JYU00474': '', 'JYU00475': '',
            'JYU00476': '', 'JYU00478': '', 'JYU00479': '', 'JYU00480': '', 'JYU00481': '', 'JYU00482': '', 'JYU00483': '',
            'JYU00484': '', 'JYU00485': '', 'JYU00489': '', 'JYU00490': '', 'JYU00501': '', 'JYU00502': '', 'JYU00507': '',
            'JYU00509': '', 'JYU00513': '', 'JYU00524': '', 'JYU00525': '', 'JYU00526': '', 'JYU00527': '', 'JYU00528': '',
            'JYU00529': '', 'JYU00530': '', 'JYU00531': '', 'JYU00534': '', 'JYU00535': '', 'JYU00536': '', 'JYU00537': '',
            'JYU00538': '', 'JYU00539': '', 'JYU00540': '', 'JYU00541': '', 'JYU00542': '', 'JYU00543': '', 'JYU00544': '',
            'JYU00545': '', 'JYU00546': '', 'JYU00547': '', 'JYU00548': '', 'JYU00549': '', 'JYU00550': '', 'JYU00551': '',
            'JYU00558': '', 'JYU00562': '', 'JYU00563': '', 'JYU00564': '', 'JYU00566': '', 'JYU00567': '', 'JYU00568': '',
            'JYU00569': '', 'JYU00572': '', 'JYU00574': '', 'JYU00575': '', 'JYU00576': '', 'JYU00577': '', 'JYU00578': '',
            'JYU00579': '', 'JYU00580': '', 'JYU00585': '', 'JYU00586': '', 'JYU00587': '', 'JYU01329': '', 'JYU01330': '',
            'JYU01331': '', 'JYU01332': '', 'JYU01333': '', 'JYU01334': '', 'JYU01335': '', 'JYU01336': '', 'JYU01337': '',
            'JYU01338': '', 'JYU01339': '', 'JYU01340': '', 'JYU01341': '', 'JYU01342': '', 'JYU01343': '', 'JYU01432': '',
            'JYU01588': '', 'JYU01589': '', 'JYU01590': '', 'JYU01591': '', 'JYU01592': '', 'JYU01593': '', 'JYU01594': '',
            'JYU01595': '', 'JYU01596': '', 'JYU01597': '', 'JYU01598': '', 'JYU01599': '', 'JYU01600': '', 'JYU01601': '',
            'JYU01602': '', 'JYU01603': '', 'JYU01604': '', 'JYU01605': '', 'JYU01607': '', 'JYU01608': '', 'JYU01609': '',
            'JYU01610': '', 'JYU01611': '', 'JYU01612': '', 'JYU01613': '', 'JYU01614': '', 'JYU01646': '', 'JYU01647': '',
            'JYU01648': '', 'JYU01790': '',
        },
        '0205': {
            'JYU00596': '', 'JYU00597': '', 'JYU00598': '', 'JYU00599': '', 'JYU00600': '', 'JYU00601': '', 'JYU00602': '',
            'JYU00603': '', 'JYU00604': '', 'JYU00605': '', 'JYU00606': '', 'JYU00607': '', 'JYU00608': '', 'JYU00609': '',
            'JYU00610': '', 'JYU00611': '', 'JYU00612': '', 'JYU00613': '', 'JYU00614': '', 'JYU00615': '', 'JYU00616': '',
            'JYU00617': '', 'JYU00623': '', 'JYU00625': '', 'JYU00626': '', 'JYU00627': '', 'JYU00628': '', 'JYU00629': '',
            'JYU00630': '', 'JYU00631': '', 'JYU00632': '', 'JYU00633': '', 'JYU00634': '', 'JYU00635': '', 'JYU00636': '',
            'JYU00637': '', 'JYU00638': '', 'JYU00639': '', 'JYU00640': '', 'JYU00641': '', 'JYU00642': '', 'JYU00643': '',
            'JYU00644': '', 'JYU00645': '', 'JYU00646': '', 'JYU00647': '', 'JYU00648': '', 'JYU00649': '', 'JYU00650': '',
            'JYU00651': '', 'JYU00652': '', 'JYU00653': '', 'JYU00654': '', 'JYU00655': '', 'JYU00656': '', 'JYU00657': '',
            'JYU00658': '', 'JYU00659': '', 'JYU00660': '', 'JYU00661': '', 'JYU00662': '', 'JYU00663': '', 'JYU00664': '',
            'JYU00665': '', 'JYU00666': '', 'JYU00667': '', 'JYU00668': '', 'JYU00669': '', 'JYU00670': '', 'JYU00671': '',
            'JYU00672': '', 'JYU00673': '', 'JYU00674': '', 'JYU00675': '', 'JYU00676': '', 'JYU00677': '', 'JYU00678': '',
            'JYU00679': '', 'JYU00680': '', 'JYU00681': '', 'JYU00693': '', 'JYU01377': '', 'JYU01378': '', 'JYU01379': '',
            'JYU01380': '', 'JYU01381': '', 'JYU01650': '', 'JYU01651': '', 'JYU01652': '', 'JYU01653': '', 'JYU01654': '',
            'JYU01770': '',
        },
        '0206': {
            'JYU00695': '', 'JYU00696': '', 'JYU00699': '', 'JYU00700': '', 'JYU00701': '', 'JYU00702': '', 'JYU00703': '',
            'JYU00704': '', 'JYU00705': '', 'JYU01382': '', 'JYU01383': '', 'JYU01450': '',
        },
        '0207': {
            'JYU00706': '', 'JYU00707': '', 'JYU00708': '', 'JYU00709': '', 'JYU00710': '', 'JYU00711': '', 'JYU00712': '',
            'JYU00713': '', 'JYU00714': '', 'JYU00716': '', 'JYU00727': '', 'JYU00729': '', 'JYU00731': '', 'JYU00732': '',
            'JYU00733': '', 'JYU00736': '', 'JYU00737': '', 'JYU00738': '', 'JYU00741': '', 'JYU00743': '', 'JYU00754': '',
            'JYU00755': '', 'JYU00756': '', 'JYU00757': '', 'JYU00758': '', 'JYU00759': '', 'JYU00760': '', 'JYU00761': '',
            'JYU00762': '', 'JYU00763': '', 'JYU00764': '', 'JYU00765': '', 'JYU00766': '', 'JYU00768': '', 'JYU00769': '',
            'JYU00770': '', 'JYU00771': '', 'JYU00772': '', 'JYU00773': '', 'JYU00774': '', 'JYU00775': '', 'JYU00776': '',
            'JYU00778': '', 'JYU00779': '', 'JYU00780': '', 'JYU00781': '', 'JYU00782': '', 'JYU00784': '', 'JYU00785': '',
            'JYU01384': '', 'JYU01385': '', 'JYU01386': '', 'JYU01387': '', 'JYU01388': '', 'JYU01389': '', 'JYU01390': '',
            'JYU01392': '', 'JYU01394': '', 'JYU01395': '', 'JYU01396': '', 'JYU01397': '', 'JYU01398': '', 'JYU01399': '',
            'JYU01400': '', 'JYU01474': '', 'JYU01475': '', 'JYU01615': '', 'JYU01616': '', 'JYU01617': '', 'JYU01619': '',
            'JYU01620': '', 'JYU01621': '', 'JYU01622': '', 'JYU01625': '', 'JYU01626': '', 'JYU01627': '', 'JYU01635': '',
            'JYU01636': '', 'JYU01637': '', 'JYU01638': '', 'JYU01639': '', 'JYU01640': '', 'JYU01641': '', 'JYU01642': '',
            'JYU01643': '',
        },
        '0208': {
        },
        '0209': {
            'JYU00837': '', 'JYU00842': '', 'JYU00846': '', 'JYU00851': '', 'JYU00855': '', 'JYU00860': '', 'JYU00864': '',
            'JYU00869': '', 'JYU00873': '', 'JYU00878': '', 'JYU00882': '', 'JYU00887': '', 'JYU00891': '', 'JYU00896': '',
            'JYU00905': '', 'JYU00914': '',
            'JYU00919_ARR': { 'JYU00919': '', },
        },
        '0210': {
        },
        '0211': {
            'JYU00980': '', 'JYU00981': '', 'JYU00982': '', 'JYU00987': '', 'JYU01013': '', 'JYU01014': '', 'JYU01016': '',
            'JYU01017': '', 'JYU01019': '', 'JYU01020': '', 'JYU01282': '', 'JYU01283': '', 'JYU01284': '', 'JYU01468': '',
            'JYU01469': '', 'JYU01470': '', 'JYU01471': '', 'JYU01472': '', 'JYU01473': '', 'JYU01667': '', 'JYU01668': '',
            'JYU01669': '', 'JYU01670': '', 'JYU01671': '', 'JYU01672': '', 'JYU01673': '', 'JYU01674': '', 'JYU01675': '',
            'JYU01676': '', 'JYU01677': '', 'JYU01678': '', 'JYU01679': '', 'JYU01680': '', 'JYU01681': '', 'JYU01682': '',
            'JYU01683': '', 'JYU01684': '', 'JYU01685': '', 'JYU01686': '', 'JYU01687': '', 'JYU01688': '', 'JYU01689': '',
            'JYU01690': '', 'JYU01691': '', 'JYU01692': '', 'JYU01693': '', 'JYU01694': '', 'JYU01695': '', 'JYU01696': '',
            'JYU01697': '', 'JYU01698': '', 'JYU01699': '', 'JYU01700': '', 'JYU01701': '', 'JYU01702': '', 'JYU01703': '',
            'JYU01704': '', 'JYU01705': '', 'JYU01706': '', 'JYU01707': '', 'JYU01708': '', 'JYU01709': '', 'JYU01712': '',
            'JYU01715': '', 'JYU01751': '', 'JYU01752': '', 'JYU01753': '', 'JYU01754': '', 'JYU01757': '', 'JYU01758': '',
            'JYU01771': '', 'JYU01772': '',
        },
        '0212': {
            'JYU01759': '', 'JYU01100': '', 'JYU01101': '', 'JYU01104': '', 'JYU01110': '', 'JYU01117': '', 'JYU01118': '',
            'JYU01121': '', 'JYU01124': '', 'JYU01125': '', 'JYU01126': '', 'JYU01127': '', 'JYU01128': '', 'JYU01132': '',
            'JYU01133': '', 'JYU01134': '', 'JYU01135': '', 'JYU01136': '', 'JYU01137': '', 'JYU01138': '', 'JYU01139': '',
            'JYU01140': '', 'JYU01141': '', 'JYU01143': '', 'JYU01144': '', 'JYU01145': '', 'JYU01146': '', 'JYU01200': '',
            'JYU01209': '', 'JYU01210': '', 'JYU01211': '', 'JYU01212': '', 'JYU01213': '', 'JYU01214': '', 'JYU01215': '',
            'JYU01275': '', 'JYU01441': '', 'JYU01442': '', 'JYU01443': '', 'JYU01444': '', 'JYU01447': '', 'JYU01449': '',
            'JYU01719': '', 'JYU01720': '', 'JYU01726': '', 'JYU01755': '', 'JYU01756': '', 'JYU01717': '', 'JYU01718': '',
            'JYU01086_ARR': { 'JYU01086': '', 'JYU01087': '', 'JYU01089': '', 'JYU01716': '', },
            'JYU01148_ARR': { 'JYU01148': '', 'JYU01793': '', 'JYU01149': '', 'JYU01150': '', 'JYU01151': '', 'JYU01153': '', 'JYU01154': '', 'JYU01155': '', 'JYU01156': '', 'JYU01721': '', 'JYU01722': '', 'JYU01723': '', 'JYU01724': '', 'JYU01725': '', },
            'JYU01168_ARR': { 'JYU01169': '', 'JYU01170': '', 'JYU01171': '', 'JYU01173': '', 'JYU01168': '', 'JYU01794': '', 'JYU01727': '', 'JYU01728': '', 'JYU01729': '', },
        },
        '0213': {
            'JYU01027': '', 'JYU01028': '', 'JYU01029': '', 'JYU01030': '', 'JYU01033': '', 'JYU01034': '', 'JYU01039': '',
            'JYU01281': '', 'JYU01548': '', 'JYU01549': '', 'JYU01550': '', 'JYU01551': '', 'JYU01552': '', 'JYU01553': '',
            'JYU01554': '', 'JYU01730': '', 'JYU01731': '', 'JYU01732': '',
        },
        '0214': {
            'JYU01216': '', 'JYU01217': '', 'JYU01479': '', 'JYU01480': '',
        },
        '0215': {
            'JYU01223': '', 'JYU01225': '', 'JYU01227': '', 'JYU01229': '', 'JYU01231': '', 'JYU01233': '', 'JYU01235': '',
            'JYU01237': '', 'JYU01238': '', 'JYU01239': '', 'JYU01240': '', 'JYU01241': '', 'JYU01242': '', 'JYU01243': '',
            'JYU01244': '', 'JYU01245': '', 'JYU01246': '', 'JYU01247': '', 'JYU01248': '', 'JYU01249': '', 'JYU01250': '',
            'JYU01251': '', 'JYU01252': '', 'JYU01253': '', 'JYU01254': '', 'JYU01255': '', 'JYU01256': '', 'JYU01257': '',
            'JYU01258': '', 'JYU01259': '', 'JYU01260': '', 'JYU01261': '', 'JYU01262': '', 'JYU01266': '', 'JYU01267': '',
            'JYU01268': '', 'JYU01269': '', 'JYU01270': '', 'JYU01271': '', 'JYU01453': '', 'JYU01454': '', 'JYU01735': '',
            'JYU01737': '', 'JYU01739': '', 'JYU01741': '', 'JYU01743': '', 'JYU01745': '',
        },
        '0216': {
            'JYU01222': '',
        },
        '0217': {
            'JYU01481': '', 'JYU01482': '', 'JYU01483': '', 'JYU01484': '', 'JYU01485': '', 'JYU01486': '', 'JYU01487': '',
            'JYU01488': '', 'JYU01489': '', 'JYU01490': '', 'JYU01491': '', 'JYU01492': '', 'JYU01493': '', 'JYU01494': '',
            'JYU01495': '', 'JYU01496': '', 'JYU01503': '', 'JYU01504': '', 'JYU01505': '', 'JYU01506': '', 'JYU01507': '',
            'JYU01508': '', 'JYU01509': '', 'JYU01510': '', 'JYU01511': '', 'JYU01512': '', 'JYU01513': '', 'JYU01514': '',
            'JYU01515': '', 'JYU01516': '', 'JYU01517': '', 'JYU01518': '', 'JYU01519': '', 'JYU01520': '', 'JYU01521': '',
            'JYU01522': '', 'JYU01523': '', 'JYU01524': '', 'JYU01525': '', 'JYU01526': '', 'JYU01527': '', 'JYU01528': '',
            'JYU01529': '', 'JYU01530': '', 'JYU01531': '', 'JYU01532': '', 'JYU01533': '', 'JYU01534': '', 'JYU01535': '',
            'JYU01536': '', 'JYU01537': '', 'JYU01538': '', 'JYU01539': '', 'JYU01540': '', 'JYU01543': '', 'JYU01544': '',
            'JYU01545': '', 'JYU01546': '', 'JYU01547': '', 'JYU01765': '', 'JYU01766': '', 'JYU01775': '',
        },
        '0218': {
            'JYU01748': '',
        },
        '0301': {
            'FUD00135': '', 'FUD00158': '', 'FUD00161': '', 'FUD00162': '', 'FUD00163': '', 'FUD00164': '', 'FUD00167': '',
            'FUD00174': '', 'FUD00177': '', 'FUD00178': '', 'FUD00179': '', 'FUD00180': '', 'FUD00353': '', 'FUD00354': '',
            'FUD00355': '', 'FUD00356': '', 'FUD00380': '', 'FUD00381': '', 'FUD00382': '', 'FUD00383': '', 'FUD00384': '',
        },
        '0302': {
            'FUD00225': '', 'FUD00226': '', 'FUD00227': '', 'FUD00365': '', 'FUD00366': '', 'FUD00367': '', 'FUD00368': '',
            'FUD00369': '', 'FUD00370': '', 'FUD00371': '', 'FUD00390': '',
        },
        '0303': {
            'FUD00386': '', 'FUD00388': '', 'FUD00389': '',
        },
        '0304': {
            'FUD00333': '',
        },
    },
};
