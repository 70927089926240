import * as AWS from "constants/aws";
import { EDIT_AUTHORITY } from "constants/contractEditPageInfo";
import { ERROR_MESSAGE } from "constants/errorMessage";
import React from "react";
import { Link } from "react-router-dom";
import * as awsReq from "util/awsRequest";

/** システム管理画面：契約情報共有一覧 */
export default class ShareList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authorityList: undefined, // 共有権限のリスト
        };
    }

    componentDidMount() {
        // 共有権限のリストを共有権限一覧取得APIから取得する
        awsReq.get(
            AWS.ENDPOINT.PATHS.GET_SHARING_AUTHORITY_LIST,
            res => { this.setState({ authorityList: res.data, }); },
            awsReq.defaultErrorHandler,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    componentDidUpdate(prevProps, prevState) {
        // コンポーネント更新時に props.shareListTmp に前回との差分がある場合、props.shareListTmp を優先して描画する
        if (prevProps.shareListTmp !== this.props.shareListTmp) {
            this.setState({ shareList: JSON.parse(JSON.stringify(this.props.shareListTmp)) });
        }
    }

    /** キャンセルボタンのハンドラ */
    handleCancelBtn = e => {
        e.preventDefault();
        // 設定されている内容を props で渡されていた確定済みの内容に戻す ※自分が変更した内容をリセットする
        this.props.updateShareListTmp(JSON.parse(JSON.stringify(this.props.shareList)));
        this.props.onClose();
    }

    /** 追加ボタンのハンドラ */
    handleAddBtn = e => {
        e.preventDefault();
        this.props.onAdd();
    }

    /**
     * 共有権限設定のバリデート
     * true: OK、false、NG
     */
    validateCommitBtn = array => {

        // 共有権限設定が1人も設定されていない場合、false
        if (!array) { return false; }

        // 担当営業のみの人をピックアップする
        const sales = array.filter(item => {
            return item.sharing_authority_id === EDIT_AUTHORITY.EIGYO;
        });

        // 担当営業が2人以上の場合、false
        if (sales.length > 1) { return false; }

        // 担当営業が1人もいない場合の場合、false
        if (sales.length === 0) { return false; }

        return true;
    }

    /** 確定ボタンのハンドラ */
    handleCommitBtn = e => {
        e.preventDefault();
        if (!this.validateCommitBtn(this.props.shareListTmp)) {
            alert(ERROR_MESSAGE.CONTRACT_REGISTER_SHARE_SETTING_NG);
            return;
        }
        this.props.onCommit(this.props.shareListTmp);
        this.props.onClose();
    }

    /** 削除ボタンのハンドラ */
    handleDeleteBtn = (e, idx) => {
        e.preventDefault();

        // 指定されたレコードを削除する
        const tmp = this.props.shareListTmp.filter((item, i) => { return idx !== i; });
        this.props.updateShareListTmp(tmp);
    }

    /** 共有権限変更のハンドラ */
    handleChangeShareSelect = (e, idx) => {
        let tmp = JSON.parse(JSON.stringify(this.props.shareListTmp));
        tmp[idx].sharing_authority_id = e.target.value; // 変更された設定のオブジェクトを生成
        this.props.updateShareListTmp(tmp); // テンポラリの更新
    }

    render() {
        const displayActive = this.props.active ? "block" : "none";

        return (
            <React.Fragment>
                <div style={{ display: displayActive }}>
                    <main className="l-modalBox" title="modalBox">
                        <div className="modalBox">
                            <ul className="l-baseAction">
                                <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleAddBtn}>追加</Link></li>
                            </ul>

                            <div className="l-dataList l-dataList__topSpace"></div>
                            <div className="dataList dataList__accordion dataList__modalScroll is-open">
                                <div className="dataList-header">
                                    <div className="dataList-header-title">契約情報共有</div>
                                </div>
                                <div className="dataList-body dataList-body__modalScroll" tabIndex="0">
                                    <table className="dataTable">
                                        <tbody>
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w120 is-emphasis">氏名</th>
                                                <th className="dataTable-title is-emphasis">共有権限</th>
                                                <th className="dataTable-title is-emphasis">&nbsp;</th>
                                            </tr>
                                            {this.createListDOM()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <ul className="l-baseAction l-baseAction__rightTwo l-baseAction__doubleSpace">
                                <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleCommitBtn}>確定</Link></li>
                                <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleCancelBtn}>キャンセル</Link></li>
                            </ul>
                        </div>
                    </main>
                </div>
            </React.Fragment>
        );
    }

    /** リスト部分の生成 */
    createListDOM = () => {
        if (!this.state.authorityList) { return null; }

        // 共有権限一覧取得APIの結果を元にoptions部分の生成
        const options = this.state.authorityList.map(item => {
            return <option key={item.sharing_authority_id} value={item.sharing_authority_id}>{item.name}</option>;
        });

        return this.props.shareListTmp.map((item, idx) => {
            return (
                <tr key={item.agency_user_id}>
                    <td className="dataTable-title dataTable-title__w320">{item.user_name}</td>
                    <td className="dataTable-body dataTable-body__w485">
                        <div className="selectBox">
                            <select name="" id="" aria-label="select" className="selectBox-select"
                                value={item.sharing_authority_id} onChange={e => { this.handleChangeShareSelect(e, idx); }}>
                                {options}
                            </select>
                        </div>
                    </td>
                    <td className="dataTable-body"><Link to="" aria-label="link" className="baseAction" onClick={e => { this.handleDeleteBtn(e, idx); }}>削除</Link></td>
                </tr>
            );
        });
    }
}