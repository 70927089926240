import Input from "components/page/user/contract/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";
import * as math from "util/math";

/** 純手数料 */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010302",
        };
        this.nowTOR00172 = 0; // 売側の値が変わったのを検知するための比較用
        this.nowTOR00173 = 0; // 買側の値が変わったのを検知するための比較用
        this.nowTOR00126 = 0; // "受入手数料（売側）> 税抜" の値が変わったのを検知するための比較用
        this.nowTOR00148 = 0; // "支払手数料（売側）> 税抜" の値が変わったのを検知するための比較用
        this.nowTOR00137 = 0; // "受入手数料（買側）> 税抜" の値が変わったのを検知するための比較用
        this.nowTOR00160 = 0; // "支払手数料（買側）> 税抜" の値が変わったのを検知するための比較用
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        // "受入手数料（売側）> 税抜" と "支払手数料（売側）> 税抜" の値が変わった場合、"純手数料 > 売側" を再計算する
        if ((this.props.pageState.TOR00126 !== this.nowTOR00126) || (this.props.pageState.TOR00148 !== this.nowTOR00148)) {
            this.nowTOR00126 = this.props.pageState.TOR00126;
            this.nowTOR00148 = this.props.pageState.TOR00148;
            this.nowTOR00172 = math.sub(this.nowTOR00126, this.nowTOR00148) + "";
            this.props.updatePageState({ TOR00172: this.nowTOR00172 }, false);
        }

        // "受入手数料（買側）> 税抜" と "支払手数料（買側）> 税抜" の値が変わった場合、"純手数料 > 買側" を再計算する
        if ((this.props.pageState.TOR00137 !== this.nowTOR00137) || (this.props.pageState.TOR00160 !== this.nowTOR00160)) {
            this.nowTOR00137 = this.props.pageState.TOR00137;
            this.nowTOR00160 = this.props.pageState.TOR00160;
            this.nowTOR00173 = math.sub(this.nowTOR00137, this.nowTOR00160) + "";
            this.props.updatePageState({ TOR00173: this.nowTOR00173 }, false);
        }

        // "純手数料 > 売側" と "純手数料 > 買側" の値が変わった場合、"純手数料 > 合計" を再計算する
        if ((this.props.pageState.TOR00172 !== this.nowTOR00172) || (this.props.pageState.TOR00173 !== this.nowTOR00173)) {
            this.nowTOR00172 = this.props.pageState.TOR00172;
            this.nowTOR00173 = this.props.pageState.TOR00173;
            const nowTOR00174 = math.sum(this.nowTOR00172, this.nowTOR00173) + "";
            this.props.updatePageState({ TOR00174: nowTOR00174 }, false);
        }
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">純手数料</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">売側</th>
                                <td className="dataTable-body dataTable-body__w200">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00172" name="TOR00172" value={this.props.pageState.TOR00172} {...params} length="w148" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">買側</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00173" name="TOR00173" value={this.props.pageState.TOR00173} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">合計</th>
                                <td className="dataTable-body dataTable-body__w195">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">{this.amountCommaEdit(this.props.pageState.TOR00174)}</div>
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}