import BaseGrid from "components/common/frame/baseGrid";
import PaginationPage from "components/common/frame/paginationPage";
import { InputTextRecord } from "components/common/parts/inputRecord";
import { OutputRecord } from "components/common/parts/outputRecord";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";

/** 支店／組織情報一覧画面 */
class GuaranteeInfoList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1, // 現ページ
            totalRecords: 0, // 総件数
        };
        this.apicalling = 0; // APIコール中のページを保持（0はコール無し。連続API防止措置なので簡易）
    }

    componentDidMount() {
        document.title = pageInfo.GUARANTEE_INFO_LIST.title;
        this.setState({ guaranteeAssociations: undefined });
    }

    componentDidUpdate() {
        if (this.state.guaranteeAssociations === undefined) {
            this.requestGuaranteeList();
        }
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputText = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** 絞り込みボタンのハンドラ */
    handleSerch = e => {
        e.preventDefault();
        this.setState({ guaranteeAssociations: undefined, page: 1 });
    }

    /** クリアボタンのハンドラ */
    handleClear = async e => {
        e.preventDefault();
        const page = 1;
        this.setState({
            page: page,
            keyword: "",
            guaranteeAssociations: undefined,
        });
    }

    /** ページネーションのページ番号押した時用 */
    handlePageNumber = page => {
        this.setState({
            page: page,
            guaranteeAssociations: undefined,
        });
    }

    render() {
        // 保証協会情報一覧Dom
        const guaranteeList = this.state.guaranteeAssociations;
        const guaranteeListDom = guaranteeList
            ? guaranteeList.map(guarantee => {
                const outputList = [
                    guarantee.displayId,
                    guarantee.associationName,
                    guarantee.officeName,
                    () => (<Link to={pageInfo.GUARANTEE_INFO_MAINTENANCE_UPDATE.path.replace(":id", guarantee.id)} aria-label="link" className="baseAction">詳細</Link>)
                ];
                return (<OutputRecord outputList={outputList} key={guarantee.id} />);
            })
            : null;

        // APIから値を取得している間は絞り込むボタンを押下させないようにする
        const pickupParams = (this.state.guaranteeAssociations !== undefined)
            ? { className: "baseAction", onClick: this.handleSerch }
            : { className: "baseAction baseAction--inactive", onClick: e => e.preventDefault() };

        return (
            <BaseGrid>
                <div className="dataList dataList__accordion is-open">
                    <div className="dataList-header">絞り込み条件</div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <InputTextRecord titleName="キーワード"
                                        inputData={{ name: "keyword", value: this.state.keyword || "" }}
                                        titleSetting={{ className: "dataTable-title dataTable-title__w120" }}
                                        bodySetting={{ className: "dataTable-body dataTable-body__w355" }}
                                        inputSetting={{ onChange: this.handleChangeInputText }} />
                                </tr>

                                <tr>
                                    <th className="dataTable-title dataTable-title__w120">&nbsp;</th>
                                    <td className="dataTable-body dataTable-body__w355">
                                        <ul className="l-baseAction l-baseAction__rightTwo">
                                            <li><Link to="" aria-label="link" className="baseAction" {...pickupParams}>絞り込み</Link></li>
                                            <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleClear}>クリア</Link></li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <PaginationPage activePage={this.state.page} totalItemsCount={this.state.totalRecords} toCreate={pageInfo.GUARANTEE_INFO_MAINTENANCE.path} onChange={this.handlePageNumber}>
                    <div className="dataList dataList__accordion is-open">
                        <div className="dataList-header"><div className="dataList-header-title">保証協会情報一覧　※（）内は供託所の場合</div></div>
                        <div className="dataList-body">
                            <table className="dataTable">
                                <tbody>
                                    <tr>
                                        <th className="dataTable-title is-emphasis dataTable-title__w120 dataTable-title__top">保証協会ID<br />（供託所ID）</th>
                                        <th className="dataTable-title is-emphasis dataTable-title__w390 is-leftJustified dataTable-title__top">保証協会名称<br />（供託所名称）</th>
                                        <th className="dataTable-title is-emphasis is-leftJustified dataTable-title__top">事務所名称</th>
                                        <th className="dataTable-title is-emphasis dataTable-title__w125"></th>
                                    </tr>

                                    {guaranteeListDom}
                                </tbody>
                            </table>
                        </div>
                    </div >
                </PaginationPage >
            </BaseGrid >
        );
    }

    /** 一覧取得APIを呼出し、その結果をstateに格納する */
    requestGuaranteeList = () => {
        if (this.apicalling !== this.state.page) {
            this.apicalling = this.state.page;

            // リクエストパラメータ作成
            let requestBody = { page: this.state.page, keyword: this.state.keyword };

            awsReq.post(
                AWS.ENDPOINT.PATHS.GUARANTEE_LIST,
                response => {
                    this.apicalling = 0;
                    // 現在表示しているページとページ番号が同じレスポンスだった場合に画面へ反映させる
                    // ※古いリクエストが返ってきても無視する
                    if (this.state.page === response.data.page_number) {
                        this.setState(convert.convertToFlatCamelCase(response.data));
                    }
                },
                error => {
                    this.apicalling = 0;
                    this.setState({ guaranteeAssociations: [] });
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                },
                requestBody,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }
}

export default withRouter(GuaranteeInfoList);
