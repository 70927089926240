import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import InputTextArea from "components/page/user/contractFrk/edit/input/inputTextArea";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 専用部分の用途その他の制限に関する規約等の定め */
export default class Block3 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020803",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
        this.isSameJYU01402 = this.props.pageState.JYU01402;
        this.isSameJYU01407 = this.props.pageState.JYU01407;
        this.isSameJYU01413 = this.props.pageState.JYU01413;
        this.isSameJYU01418 = this.props.pageState.JYU01418;
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 文字数制限定義
        const JYU00816LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00816",
                this.props.pageState.JYU00816);
        const JYU00833LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00833",
                this.props.pageState.JYU00833);
        const JYU00820LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00820",
                this.props.pageState.JYU00820);
        const JYU00824LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00824",
                this.props.pageState.JYU00824);
        const JYU00828LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00828",
                this.props.pageState.JYU00828);

        // 非活性の設定
        let JYU01402_disabled = this.props.pageState.JYU01402;
        let JYU01407_disabled = this.props.pageState.JYU01407;
        let JYU01413_disabled = this.props.pageState.JYU01413;
        let JYU01418_disabled = this.props.pageState.JYU01418;

        // ハイフン挿入の設定
        const HYPHEN_TEXT = "--";

        if (!this.isSameJYU01402 && this.props.pageState.JYU01402) {
            this.isSameJYU01402 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00817", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00818", HYPHEN_TEXT));
        }

        if (this.isSameJYU01402 && !this.props.pageState.JYU01402) {
            this.isSameJYU01402 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00817", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00818", ""));
        }

        if (!this.isSameJYU01407 && this.props.pageState.JYU01407) {
            this.isSameJYU01407 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00821", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00822", HYPHEN_TEXT));
        }

        if (this.isSameJYU01407 && !this.props.pageState.JYU01407) {
            this.isSameJYU01407 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00821", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00822", ""));
        }

        if (!this.isSameJYU01413 && this.props.pageState.JYU01413) {
            this.isSameJYU01413 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00825", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00826", HYPHEN_TEXT));
        }

        if (this.isSameJYU01413 && !this.props.pageState.JYU01413) {
            this.isSameJYU01413 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00825", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00826", ""));
        }

        if (!this.isSameJYU01418 && this.props.pageState.JYU01418) {
            this.isSameJYU01418 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00829", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00830", HYPHEN_TEXT));
        }

        if (this.isSameJYU01418 && !this.props.pageState.JYU01418) {
            this.isSameJYU01418 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00829", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00830", ""));
        }

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">専有部分の用途その他の利用の制限に関する規約等の定め（別添管理規約・使用細則に記載されています。）</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240 dataTable-title__center" colSpan="2">規約等の定めの有無</th>
                                <th className="dataTable-title dataTable-title__center">制限の内容</th>
                                <th className="dataTable-title dataTable-title__w145 dataTable-title__center">管理規約</th>
                                <th className="dataTable-title dataTable-title__w145 dataTable-title__center">使用細則</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">用途制限</th>
                                <td className="dataTable-title dataTable-title__w120">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00814" name="JYU00814" checked={this.props.pageState.JYU00814} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01402" name="JYU01402" checked={this.props.pageState.JYU01402} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-rightborder">
                                    <InputTextArea id="ID_JYU00816" name="JYU00816" cols={30} rows={10} height="h148" value={this.props.pageState.JYU00816} {...params} placeholder={JYU00816LimitMsg.inputLimitGuide} {...params} />
                                    <p className="LengthOver-error">{JYU00816LimitMsg.inputLimitMsg}</p>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00817" name="JYU00817" value={this.props.pageState.JYU00817} {...params} length="w93" disabled={JYU01402_disabled} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00818" name="JYU00818" value={this.props.pageState.JYU00818} {...params} length="w93" disabled={JYU01402_disabled} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">ペットの飼育<br />制限</th>
                                <td className="dataTable-title dataTable-title__w120">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00819" name="JYU00819" checked={this.props.pageState.JYU00819} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01407" name="JYU01407" checked={this.props.pageState.JYU01407} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-rightborder">
                                    <InputTextArea id="ID_JYU00820" name="JYU00820" cols={30} rows={10} height="h148" value={this.props.pageState.JYU00820} {...params} placeholder={JYU00820LimitMsg.inputLimitGuide} {...params} />
                                    <p className="LengthOver-error">{JYU00820LimitMsg.inputLimitMsg}</p>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00821" name="JYU00821" value={this.props.pageState.JYU00821} {...params} length="w93" disabled={JYU01407_disabled} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00822" name="JYU00822" value={this.props.pageState.JYU00822} {...params} length="w93" disabled={JYU01407_disabled} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">フローリング<br />の制限</th>
                                <td className="dataTable-title dataTable-title__w120">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00823" name="JYU00823" checked={this.props.pageState.JYU00823} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01413" name="JYU01413" checked={this.props.pageState.JYU01413} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-rightborder">
                                    <InputTextArea id="ID_JYU00824" name="JYU00824" cols={30} rows={10} height="h148" value={this.props.pageState.JYU00824} {...params} placeholder={JYU00824LimitMsg.inputLimitGuide} {...params} />
                                    <p className="LengthOver-error">{JYU00824LimitMsg.inputLimitMsg}</p>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00825" name="JYU00825" value={this.props.pageState.JYU00825} {...params} length="w93" disabled={JYU01413_disabled} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00826" name="JYU00826" value={this.props.pageState.JYU00826} {...params} length="w93" disabled={JYU01413_disabled} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">楽器の使用<br />制限</th>
                                <td className="dataTable-title dataTable-title__w120">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00827" name="JYU00827" checked={this.props.pageState.JYU00827} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01418" name="JYU01418" checked={this.props.pageState.JYU01418} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-rightborder">
                                    <InputTextArea id="ID_JYU00828" name="JYU00828" cols={30} rows={10} height="h148" value={this.props.pageState.JYU00828} {...params} placeholder={JYU00828LimitMsg.inputLimitGuide} {...params} />
                                    <p className="LengthOver-error">{JYU00828LimitMsg.inputLimitMsg}</p>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00829" name="JYU00829" value={this.props.pageState.JYU00829} {...params} length="w93" disabled={JYU01418_disabled} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00830" name="JYU00830" value={this.props.pageState.JYU00830} {...params} length="w93" disabled={JYU01418_disabled} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00833" name="JYU00833" value={this.props.pageState.JYU00833} text={SEND_OTHER_PAPER.JYU00833} placeholder={JYU00833LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00833LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}