export const ERROR_MESSAGE = {
    IS_EMPTY: "未入力です",
    NOT_NUMBER: "半角数字で入力してください",
    NOT_EMAL: "メールアドレスが不正です",
    NOT_EQUAL_VALUES: "同値ではありません",
    SERVER_ERROR: "システムで正しく処理できませんでした\n\n大変に申し訳ございません。正常に処理されるよう、対応しております。\nしばらく時間をおいた後に改めてシステムをご覧ください。\n時間をおいても解決されない場合は、お手数ですが弊社の担当営業までご連絡をください。",
    PREVIEW_FILE_NOT_FOUND: "ファイルはまだアップロードされていません。", // TODO: 要確認
    CONTRACT_REGISTER_SHARE_SETTING_NG: "担当営業の設定は一人にしてください。また、一人は必ず設定してください。", // TODO: 要確認
    INVALID_PASSWORD: "新パスワードは12文字以上16文字以下で入力してください。", // TODO: 要確認
    FAILED_CHANGE_PASSWORD: "パスワードの変更に失敗しました。", // TODO: 要確認
    NOT_DATE: "日付が不正です", // TODO: 要確認
    NOT_IP_ADDRESS_FORMAT: "IPアドレス形式で入力してください（複数件の場合、改行して入力ください）",
    EXCEED_THE_NUMBER_OF_CONCURRENT_REQUESTS: "帳票の同時印字可能数に達しているため、しばらく経ってから再度印刷処理を行ってください。",
};