import { InputSelectBoxRecord, InputTextRecord } from "components/common/parts/inputRecord";
import Pagination from "components/common/parts/pagination";
import { HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import * as AWS from "constants/aws";
import { CONTRACT_TYPE_FRK } from "constants/contractEditPageInfo";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { Link } from "react-router-dom";
import * as awsReq from "util/awsRequest";
import * as dateUtil from "util/dateUtil";

/** システム管理画面：情報一覧(過去物件) */
export default class PastContractList extends React.Component {
    constructor(props) {
        super(props);

        // this.props.active: ポップアップがアクティブかどうか
        // this.props.shareList: 現在選択されている共有先として従業員のリスト
        // ※[{ "agency_user_id":"sampleuser001", "sharing_authority_id":"01" }, ...]

        this.state = {
            hide: false, // 見出しを隠す:true、見出しを表示する:false
            data: undefined, // 表示するべきデータを保持する（APIからのレスポンスが入る）
            keyword: "", // キーワード
            sale: "", // 担当営業
            org: "", // 支店／組織
            isNotConfirmed: false, // 契約未確定
            committedDateStart: "", // 確定日（から）
            committedDateEnd: "", // 確定日（まで）
            isCondo: false, // 区分所有建物
            isLand: false, // 土地／土地建物
            location: "", // 所在地
            contractTypeId: "", // 契約書式

            activePage: 1, // 現ページ
            itemsCountPerPage: 10, // 1ページに何件表示するか
            totalItemsCount: 0, // 総件数
            pageRangeDisplayed: 5, // ページネーションで表示するページ数
        };
        this.apicallingPast = 0; // 従業員一覧取得のAPIコール中のページを保持（連続API防止措置なので簡易）
    }

    componentDidUpdate(prevProps) {
        // ポップアップが表示されるタイミングでリロードする
        if (prevProps.active === false && this.props.active === true) {
            this.setState({
                data: undefined, // APIレスポンス保持情報のクリア
            });
        }

        // 保持しているAPIからの情報がクリアされた場合、再度APIコールする
        if (this.state.data === undefined && this.props.active === true) {
            this.RequestAPI();
            return;
        }
    }

    /** 見出しの▼を押した際に隠すor表示する */
    handleHide = e => {
        e.preventDefault();
        this.setState({ hide: !this.state.hide });
    }

    // ポップアップの中身を表示するためのAPIリクエスト
    RequestAPI = () => {
        /** 情報一覧(過去物件)取得 */
        if (this.apicallingPast !== this.state.activePage) {
            this.apicallingPast = this.state.activePage;

            let query = { page: this.state.activePage, };
            if (this.state.selectedOrg !== undefined && this.state.selectedOrg !== "") {
                query.agency_branch_id = this.state.selectedOrg;
            }

            // キーワードがある時はキーワードをクエリに入れる
            if (this.state.keyword !== "") {
                query.keyword = this.state.keyword;
            }

            // 担当営業がある時はキーワードをクエリに入れる
            if (this.state.sale !== "") {
                query.staff_name = this.state.sale;
            }

            // 支店／組織がある時はキーワードをクエリに入れる
            if (this.state.org !== "") {
                query.branch_name = this.state.org;
            }

            // 契約未確定
            query.is_not_confirmed = this.state.isNotConfirmed;

            // 確定日開始がある時はクエリに入れる
            if (this.state.committedDateStart !== "") {
                query.contract_fixed_on_from = this.state.committedDateStart.replace(/\//g, "-");
            }

            // 確定日終了がある時はクエリに入れる
            if (this.state.committedDateEnd !== "") {
                query.contract_fixed_on_to = this.state.committedDateEnd.replace(/\//g, "-");
            }

            query.is_condo = this.state.isCondo; // 区分所有建物
            query.is_land = this.state.isLand; // 土地／土地建物

            // 所在地がある時はキーワードをクエリに入れる
            if (this.state.location !== "") {
                query.location = this.state.location;
            }

            // 契約書式がある時はキーワードをクエリに入れる
            if (this.state.contractTypeId !== "") {
                query.contract_type_id = this.state.contractTypeId;
            }

            query.input_format = "02";  // 入力書式

            awsReq.post(
                AWS.ENDPOINT.PATHS.GET_PAST_CONTRACT_LIST,
                res => {
                    this.apicallingPast = 0;
                    // 現在表示しているページに対応しているレスポンスの場合、レスポンスをセットする
                    // ※レスポンスを全て画面反映させるとページ送りした分の全ての結果が順番に表示されてしまうため
                    // ※1ページ目を表示している時に8ページ目に行くためには、1,5,8と遷移する必要があるが（この時、5は中継地点なので結果表示の必要がない）、
                    //   レスポンスを全て画面表示すると、8ページ目を表示する前に5ページ目の内容を一瞬表示してしまうことの対策
                    if (this.state.activePage === res.data.page_number) {
                        this.setState({
                            data: res.data.past_contracts,
                            itemsCountPerPage: res.data.per_page,
                            totalItemsCount: res.data.total_records,
                        });
                    }
                },
                error => {
                    this.apicallingPast = 0;
                    this.setState({ data: [] });
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                },
                query,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }

    /** キーワード入力時のハンドラ */
    handleKeywordInput = e => {
        e.preventDefault();
        this.setState({ keyword: e.target.value });
    }

    /** 担当営業入力時のハンドラ */
    handleSaleInput = e => {
        e.preventDefault();
        this.setState({ sale: e.target.value });
    }

    /** 支店／組織入力時のハンドラ */
    handleOrgInput = e => {
        e.preventDefault();
        this.setState({ org: e.target.value });
    }

    /** 契約未確定のハンドラ */
    handleIsNotConfirmedCheck = e => {
        this.setState({ isNotConfirmed: !this.state.isNotConfirmed, committedDateStart: "", committedDateEnd: "" });
    }

    /** 確定日開始入力時のハンドラ */
    handleCommittedDateStartInput = e => {
        e.preventDefault();
        this.setState({ committedDateStart: e.target.value });
    }

    /** 確定日開始入力時のハンドラ（blurした時に強制的に日付に戻す） */
    blurCommittedDateStartInput = e => {
        e.preventDefault();
        if (e.target.value === "") { return; }

        let st = "";
        const date = new Date(e.target.value);
        if (date.toString() === "Invalid Date") { st = "1980/01/01"; }
        else { st = date.toLocaleDateString("ja-JP", { timezone: "JST", year: "numeric", month: "2-digit", day: "2-digit", }); }
        this.setState({ committedDateStart: st });
    }

    /** 確定日終了入力時のハンドラ */
    handleCommittedDateEndInput = e => {
        e.preventDefault();
        this.setState({ committedDateEnd: e.target.value });
    }

    /** 確定日終了入力時のハンドラ（blurした時に強制的に日付に戻す） */
    blurCommittedDateEndInput = e => {
        e.preventDefault();
        if (e.target.value === "") { return; }

        let st = "";
        const date = new Date(e.target.value);
        if (date.toString() === "Invalid Date") { st = "2199/12/31"; }
        else { st = date.toLocaleDateString("ja-JP", { timezone: "JST", year: "numeric", month: "2-digit", day: "2-digit", }); }
        this.setState({ committedDateEnd: st });
    }

    /** 区分所有建物のハンドラ */
    handleIsCondoCheck = e => {
        this.setState({ isCondo: !this.state.isCondo });
    }

    /** 土地／土地建物のハンドラ */
    handleIsLandCheck = e => {
        this.setState({ isLand: !this.state.isLand });
    }

    /** 所在地のハンドラ */
    handleLocation = e => {
        e.preventDefault();
        this.setState({ location: e.target.value });
    }

    /** セレクトボックスの入力値をstateに格納 */
    handleChangeInputSelectBox = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** キャンセルボタン押下のハンドラ */
    handleCancelBtn = e => {
        e.preventDefault();
        this.props.onClose();
    }

    /** 絞り込みボタン用のハンドラ */
    handlePickupBtn = e => {
        e.preventDefault();
        this.setState({ data: undefined, activePage: 1 });
    }

    // クリアボタン押下のハンドラ
    handleClearBtn = e => {
        e.preventDefault();
        const clear = {
            data: undefined, // APIコールを再実行させる
            activePage: 1,

            keyword: "", // キーワード
            sale: "", // 担当営業
            org: "", // 支店／組織
            isNotConfirmed: false, // 契約未確定
            committedDateStart: "", // 確定日（から）
            committedDateEnd: "", // 確定日（まで）
            isCondo: false, // 区分所有建物
            isLand: false, // 土地／土地建物
            location: "", // 所在地
            contractTypeId: "", // 契約書式
        };
        this.setState(clear);
    }

    handleImportBtn = (e, idx) => {
        e.preventDefault();
        this.props.updatePastContract(this.state.data[idx]);
        this.props.onClose();
    }

    // ページネーションのハンドラ
    handlePagination = page => {
        this.setState({ data: undefined, activePage: page });
    }

    /** プレビュー：重説ボタンのハンドラ */
    handleImpoPreview = async (e, idx) => {
        e.preventDefault();

        // 重要事項説明書の全ページ印刷を指定
        const previewURL = pageInfo.CONTRACT_PREVIEW_FRK.path
            .replace(":contractId", this.state.data[idx].id)
            .replace(":tabId", "02")
            .replace(":pageId", "0000");

        // プレビュー画面を表示
        window.open(previewURL);
    }

    /** プレビュー：売契ボタンのハンドラ */
    handleContPreview = async (e, idx) => {
        e.preventDefault();

        // 不動産売買契約書の全ページ印刷を指定
        const previewURL = pageInfo.CONTRACT_PREVIEW_FRK.path
            .replace(":contractId", this.state.data[idx].id)
            .replace(":tabId", "03")
            .replace(":pageId", "0000");

        // プレビュー画面を表示
        window.open(previewURL);
    }

    render() {
        const displayActive = this.props.active ? "block" : "none";
        const hideBlockClass = this.state.hide ? "" : " is-open";

        // APIから値を取得している間は絞り込むボタンを押下させないようにする
        // ※それぞれ、this.state.data が従業員一覧で、this.state.selectbox がセレクトボックスに使う値
        const pickupParams = (this.state.data !== undefined)
            ? { className: "baseAction", onClick: this.handlePickupBtn }
            : { className: "baseAction baseAction--inactive", onClick: e => e.preventDefault() };

        // 契約確定日の契約未確定がチェックの場合、日付の入力が不要
        const disabledRelateIsNotConfirmed = this.state.isNotConfirmed;

        // セレクトリストの生成
        const contractTypes = CONTRACT_TYPE_FRK;
        let contractTypeList = contractTypes
            ? contractTypes.map(contractType => ({ value: contractType.contract_type_id, display: contractType.name }))
            : [];
        contractTypeList.unshift({ value: '', display: '' });

        const titleSetting = { className: "dataTable-title dataTable-title__w120" };
        const bodysetting = { className: "dataTable-body dataTable-body__w355" };

        // ページネーションの設定
        const pageSettings = {
            activePage: this.state.activePage,
            itemsCountPerPage: this.state.itemsCountPerPage,
            totalItemsCount: this.state.totalItemsCount,
            pageRangeDisplayed: this.state.pageRangeDisplayed,
            onChange: this.handlePagination,
        };

        return (
            <React.Fragment>
                <div style={{ display: displayActive }}>
                    <main className="l-modalBox" title="modalBox">
                        <div className="modalBox">
                            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                                <div className="dataList-header">
                                    <div className="dataList-header-title">絞り込み条件</div>
                                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                                </div>
                                <div className="dataList-body">
                                    <table className="dataTable">
                                        <tbody>
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w120">キーワード</th>
                                                <td className="dataTable-body" colSpan="3">
                                                    <input type="text" aria-label="text" value={this.state.keyword} onChange={this.handleKeywordInput} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w120">担当営業</th>
                                                <td className="dataTable-body dataTable-body__w355">
                                                    <input type="text" aria-label="text" value={this.state.sale} onChange={this.handleSaleInput} />
                                                </td>
                                                <th className="dataTable-title dataTable-title__w120">支店/組織</th>
                                                <td className="dataTable-body dataTable-body__w355">
                                                    <input type="text" aria-label="text" value={this.state.org} onChange={this.handleOrgInput} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w120">契約確定日</th>
                                                <td className="dataTable-body">
                                                    <div className="dataTableUnit">
                                                        <ul className="l-checkBox">
                                                            <li>
                                                                <label className="checkBox">
                                                                    <input type="checkbox" className="checkBox-input" checked={this.state.isNotConfirmed} onChange={this.handleIsNotConfirmedCheck} />
                                                                    <span className="checkBox-text">契約未確定 </span>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                        <div className="dataTableUnit-input">
                                                            <input type="text" aria-label="text" value={this.state.committedDateStart}
                                                                onChange={this.handleCommittedDateStartInput}
                                                                onBlur={this.blurCommittedDateStartInput}
                                                                disabled={disabledRelateIsNotConfirmed}
                                                            />
                                                        </div>
                                                        <div className="dataTableUnit-text">〜</div>
                                                        <div className="dataTableUnit-input">
                                                            <input type="text" aria-label="text" value={this.state.committedDateEnd}
                                                                onChange={this.handleCommittedDateEndInput}
                                                                onBlur={this.blurCommittedDateEndInput}
                                                                disabled={disabledRelateIsNotConfirmed}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="dataTable-body" colSpan="2">
                                                    <ul className="l-checkBox">
                                                        <li>
                                                            <label className="checkBox">
                                                                <input type="checkbox" className="checkBox-input" checked={this.state.isCondo} onChange={this.handleIsCondoCheck} />
                                                                <span className="checkBox-text">区分所有建物 </span>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="checkBox">
                                                                <input type="checkbox" className="checkBox-input" checked={this.state.isLand} onChange={this.handleIsLandCheck} />
                                                                <span className="checkBox-text">土地／土地建物</span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <InputTextRecord titleName="所在地"
                                                    inputData={{ name: "location", value: this.state.location || "" }}
                                                    titleSetting={titleSetting}
                                                    bodySetting={bodysetting}
                                                    inputSetting={{ onChange: this.handleLocation }} />
                                                <InputSelectBoxRecord titleName="契約書式"
                                                    inputData={{ name: "contractTypeId", value: this.state.contractTypeId || "" }}
                                                    optionsDataList={contractTypeList} titleSetting={titleSetting}
                                                    inputSetting={{ onChange: this.handleChangeInputSelectBox }} />
                                            </tr>
                                            <tr>
                                                <td className="dataTable-body" colSpan="4">
                                                    <ul className="l-baseAction l-baseAction__rightTwo">
                                                        <li><Link to="" aria-label="link" className="baseAction" {...pickupParams}>絞り込み</Link></li>
                                                        <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleClearBtn}>クリア</Link></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Pagination pageSettings={pageSettings} />
                            <div className="dataList dataList__accordion dataList__modalScroll is-open">
                                <div className="dataList-header">
                                    <div className="dataList-header-title">情報一覧</div>
                                </div>
                                <div className="dataList-body dataList-body__modalScroll">
                                    <table className="dataTable">
                                        <tbody>
                                            <tr>
                                                <th className="dataTable-title">稟議書No.</th>
                                                <th className="dataTable-title">物件名</th>
                                                <th className="dataTable-title">書式</th>
                                                <th className="dataTable-title">担当営業</th>
                                                <th className="dataTable-title">支店／組織</th>
                                                <th className="dataTable-title">契約確定日</th>
                                                <th className="dataTable-title">契約</th>
                                                <th className="dataTable-title">プレビュー</th>
                                            </tr>
                                            {this.createListDOM()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Pagination pageSettings={pageSettings} />
                            <ul className="l-baseAction l-baseAction__rightTwo">
                                <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleCancelBtn}>キャンセル</Link></li>
                            </ul>

                            <div className="l-pageAction l-pageAction--back">
                                <Link to="" aria-label="link" className="pageAction">一覧へもどる</Link>
                            </div>
                        </div>
                    </main>
                </div>
            </React.Fragment>
        );
    }

    createListDOM = () => {
        const array = this.state.data;

        // arrayが空の場合、null
        if (array === undefined || array == null) {
            return null;
        }

        return array.map((element, idx) => {
            const datetime = dateUtil.tzStrToDateTime(element.contract_fixed_at);
            return (
                <React.Fragment key={element.id}>
                    <tr>
                        <th className="dataTable-title">{element.document_no}</th>
                        <td className="dataTable-body">{element.building_name}</td>
                        <td className="dataTable-body">{element.contract_type_name_frk}</td>
                        <td className="dataTable-body">{element.staff_name}</td>
                        <td className="dataTable-body">{element.branch_name}</td>
                        <td className="dataTable-body">{datetime}</td>
                        <td className="dataTable-body">
                            <Link to="" aria-label="link" className="baseActionS" onClick={e => { this.handleImportBtn(e, idx); }}>取込</Link>
                        </td>
                        <td className="dataTable-body">
                            <ul className="l-baseAction">
                                <li><Link to="" aria-label="link" className="baseActionS" onClick={e => { this.handleImpoPreview(e, idx); }}>重説</Link></li>
                                <li><Link to="" aria-label="link" className="baseActionS" onClick={e => { this.handleContPreview(e, idx); }}>売契</Link></li>
                            </ul>
                        </td>
                    </tr>
                </React.Fragment>
            );
        });
    }
}
