import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import InputDate from "components/page/user/contract/edit/input/inputDate";
import Input from "components/page/user/contract/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";

/** 借地権 */
export default class Block7 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030107",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <React.Fragment>
                <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">借地権</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120">借地権の登記</th>
                                    <td className="dataTable-body">
                                        <ul className="l-checkBox">
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00158" name="FUD00158" checked={this.props.pageState.FUD00158} {...params} />
                                                    <span className="checkBox-text">1.有</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00353" name="FUD00353" checked={this.props.pageState.FUD00353} {...params} />
                                                    <span className="checkBox-text">2.無</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120" rowSpan="2">借地権の種類</th>
                                    <td className="dataTable-body">
                                        <ul className="l-checkBox">
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00159" name="FUD00159" checked={this.props.pageState.FUD00159} {...params} />
                                                    <span className="checkBox-text">1.地上権</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00160" name="FUD00160" checked={this.props.pageState.FUD00160} {...params} />
                                                    <span className="checkBox-text">2.賃貸権</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dataTable-body">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-i">
                                                <ul className="l-checkBox">
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_FUD00161" name="FUD00161" checked={this.props.pageState.FUD00161} {...params} />
                                                            <span className="checkBox-text">1.旧法による借地権</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_FUD00162" name="FUD00162" checked={this.props.pageState.FUD00162} {...params} />
                                                            <span className="checkBox-text">2.普通借地権</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <div className="dataTableUnit">
                                                            <div className="dataTableUnit-input">
                                                                <label className="checkBox">
                                                                    <InputCheckbox id="ID_FUD00163" name="FUD00163" checked={this.props.pageState.FUD00163} {...params} />
                                                                    <span className="checkBox-text">3.</span>
                                                                </label>
                                                            </div>
                                                            <Input id="ID_FUD00164" name="FUD00164" value={this.props.pageState.FUD00164} {...params} />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title dataTable-title__center is-emphasis" colSpan="4">土地の所有者</th>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120">住所</th>
                                    <td className="dataTable-body" colSpan="4">
                                        <Input id="ID_FUD00354" name="FUD00354" value={this.props.pageState.FUD00354} {...params} />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120">氏名</th>
                                    <td className="dataTable-body" colSpan="4">
                                        <Input id="ID_FUD00355" name="FUD00355" value={this.props.pageState.FUD00355} {...params} />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120">目的</th>
                                    <td className="dataTable-body dataTable-body__w355">
                                        <ul className="l-checkBox">
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00356" name="FUD00356" checked={this.props.pageState.FUD00356} {...params} />
                                                    <span className="checkBox-text">1.非堅固</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00174" name="FUD00174" checked={this.props.pageState.FUD00174} {...params} />
                                                    <span className="checkBox-text">2.堅固</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </td>
                                    <th className="dataTable-title dataTable-title__w120">予定地代</th>
                                    <td className="dataTable-body dataTable-body__w355">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-text">月額</div>
                                            <Input id="ID_FUD00167" name="FUD00167" value={this.props.pageState.FUD00167} {...params} />
                                            <div className="dataTableUnit-text">円</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120">予定期間<br />（期限）</th>
                                    <td className="dataTable-body" colSpan="3">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-input">
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00177" name="FUD00177" checked={this.props.pageState.FUD00177} {...params} />
                                                    <span className="checkBox-text">1.満</span>
                                                </label>
                                            </div>
                                            <Input id="ID_FUD00178" name="FUD00178" value={this.props.pageState.FUD00178} {...params} length="w50" />
                                            <div className="dataTableUnit-text">年間</div>
                                            <div className="dataTableUnit-input">
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00179" name="FUD00179" checked={this.props.pageState.FUD00179} {...params} />
                                                    <span className="checkBox-text">2.存続期間承継</span>
                                                </label>
                                            </div>
                                            <InputDate until id="ID_FUD00180" name="FUD00180" value={this.props.pageState.FUD00180} {...params} length="w108" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}