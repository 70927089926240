import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import InputDate, { Era } from "components/page/user/contract/edit/input/inputDate";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 契約の解除に関する事項 */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021202",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        //JYU01121の入力上限判定
        const JYU01121LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU01121_${this.props.displayTypeID}`, this.props.pageState.JYU01121);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">契約の解除に関する事項</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">手付解除</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01101" name="JYU01101" checked={this.props.pageState.JYU01101} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01443" name="JYU01443" checked={this.props.pageState.JYU01443} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">手付解除期日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_JYU01102" name="JYU01102" value={this.props.pageState.JYU01102} {...params} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" colSpan="3">引渡し完了前の滅失・損傷による解除</th>
                                <td className="dataTable-body" colSpan="3">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01104" name="JYU01104" checked={this.props.pageState.JYU01104} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01444" name="JYU01444" checked={this.props.pageState.JYU01444} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" colSpan="2">融資利用の特約による解除</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="2">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01110" name="JYU01110" checked={this.props.pageState.JYU01110} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01275" name="JYU01275" checked={this.props.pageState.JYU01275} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">契約解除期日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">{this.props.pageState.TOR00033}</div>
                                        <Era oneline value={this.props.pageState.TOR00033} {...params} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" colSpan="2">譲渡承諾の特約による解除</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="2">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01117" name="JYU01117" checked={this.props.pageState.JYU01117} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01447" name="JYU01447" checked={this.props.pageState.JYU01447} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">契約解除期日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_JYU01118" name="JYU01118" value={this.props.pageState.JYU01118} {...params} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__left" colSpan="3">契約不適合による修補請求・解除</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01113" name="JYU01113" checked={this.props.pageState.JYU01113} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01446" name="JYU01446" checked={this.props.pageState.JYU01446} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01585" name="JYU01585" checked={this.props.pageState.JYU01585} {...params} />
                                                        <span className="checkBox-text">該当なし</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-note">※借地権付建物売買または定期借地権付建物売買の場合は後記Ⅱ-11.に記載</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__left" colSpan="3">修補の遅滞等を含む契約違反による解除</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01476" name="JYU01476" checked={this.props.pageState.JYU01476} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01477" name="JYU01477" checked={this.props.pageState.JYU01477} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" colSpan="2">その他の解除</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="2">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01119" name="JYU01119" checked={this.props.pageState.JYU01119} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01448" name="JYU01448" checked={this.props.pageState.JYU01448} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">契約解除期日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_JYU01120" name="JYU01120" value={this.props.pageState.JYU01120} {...params} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU01121" name="JYU01121" value={this.props.pageState.JYU01121} text={SEND_OTHER_PAPER.JYU01121} placeholder={JYU01121LimitErrorMessage.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU01121LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}