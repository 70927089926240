import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 建物状況調査の結果の概要 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021101",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        //JYU00982の入力上限判定
        const JYU00982LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00982_${this.props.displayTypeID}`, this.props.pageState.JYU00982);

        // 斜線対応の設定
        let DomDiagonalLine = undefined;
        if (this.onloadFlg && this.props.pageState.JYU01282) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_JYU01282");
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU01282:AR_JYU01282-1,AR_JYU01282-2")}>
                <div className="dataList-header">
                    <div className="dataList-header-title">建物状況調査の結果の概要（既存建物のとき）</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-body is-emphasis" colSpan="4">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox l-checkBox--center">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00980" name="JYU00980" checked={this.props.pageState.JYU00980} {...params} />
                                                        <span className="checkBox-text">該当する</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01282" name="JYU01282" checked={this.props.pageState.JYU01282} {...params} />
                                                        <span className="checkBox-text">該当しないので、説明を省略します。　※入力不要の場合は、斜線を引きます。</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {DomDiagonalLine}
                            <tr id="AR_JYU01282-1">
                                <td className="dataTable-body is-emphasis" colSpan="4">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-text is-emphasis">建物状況調査の実施の有無（１年以内に実施している場合）<br />
                                            ※鉄筋コンクリート造又は鉄骨鉄筋コンクリート造の共同住宅等にあっては2年以内に実施している場合</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox l-checkBox--center">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00981" name="JYU00981" checked={this.props.pageState.JYU00981} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01283" name="JYU01283" checked={this.props.pageState.JYU01283} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr id="AR_JYU01282-2">
                                <th className="dataTable-title dataTable-title__left dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00982" name="JYU00982" value={this.props.pageState.JYU00982} text={SEND_OTHER_PAPER.JYU00982} placeholder={JYU00982LimitErrorMessage.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00982LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
