import { Auth } from "aws-amplify";
import axios from "axios";
import { ENDPOINT_ECS as ECS } from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { withRouter } from "react-router";

class PrintPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: undefined,
            contentLength: 0,
            contractId: props.match.params.contractId,  // 契約書ID
            tabId: props.match.params.tabId,            // タブID
            pageId: props.match.params.pageId,          // ページID
        };
    }

    async componentDidMount() {
        document.title = pageInfo.CONTRACT_PREVIEW.title;

        if (this.state.data === undefined) {
            // PDF出力
            try {
                // IDトークン取得する用
                const data = await Auth.currentSession();
                const request = {
                    // fc: "preview",
                    contract_id: this.state.contractId,
                    tab_id: this.state.tabId,
                    page_id: this.state.pageId
                };

                const res = await axios.post(`${ECS.URL}${ECS.PATHS.PDF}`, request, {
                    headers: { "x-id-token": data.getIdToken().getJwtToken(), }
                });
                this.setState({
                    data: res.data,
                    contentLength: res.headers['content-length']
                });

            } catch (error) {
                alert(ERROR_MESSAGE.SERVER_ERROR);
                return;
            }
        }
    }

    render() {
        if (this.state.data === undefined) {
            //ローディングの回転.svgの位置設定
            const loadCircleTop = window.innerHeight / 2 - 40 + "px"; // -60はLoading画像サイズの半分ずらし
            const loadCircleLeft = window.innerWidth / 2 - 35 + "px"; // -60はLoading画像サイズの半分ずらし
            //ローディングのテキスト付の白枠の位置設定
            const loadFrameTop = window.innerHeight / 2 - 98 + "px"; // --98はLoading画像サイズの半分ずらし
            const loadFrameLeft = window.innerWidth / 2 - 229 + "px"; // --299はLoading画像サイズの半分ずらし

            return <dev><img src="/img/loading_frame.png" alt="" style={{ position: "fixed", zIndex: 99, top: loadFrameTop, left: loadFrameLeft }} /> <img src="/img/loading.svg" alt="" style={{ position: "fixed", zIndex: 100, top: loadCircleTop, left: loadCircleLeft }} /></dev>;
        }

        let pdf_element = undefined;
        if (this.state.contentLength > 2 * 1000 * 1000) {
            // CONTRACT_CLOUD_ENHANCE-171
            // PDFファイルサイズが2MBを超えていた場合
            // ファイルダウンロードボタン表示
            const linkSource = `data:application/pdf;base64,${this.state.data.data}`;
            const filename = 'ダウンロード.pdf'
            pdf_element = (<>
                <div style={{ padding: "50px 50px", fontSize: "22px" }}>
                    <p>ファイルの生成が完了しました。</p>
                    <p>下記のダウンロードボタンを押下し、PDFをダウンロードしてください。</p>
                    <p>ダウンロード完了後、タブを閉じてください</p>
                </div>
                <div style={{ textAlign: "center" }}>
                    <div className="col-md">
                        <a download={filename} href={linkSource} className="baseAction baseAction--print baseAction--blue baseAction--agree">
                            <span className="baseAction-text">ダウンロード</span>
                        </a>
                    </div>
                </div>
            </>)
        } else {
            pdf_element = (
                <div style={{ height: "100%", width: "100%", overflow: "hidden", margin: "0px", backgroundColor: "rgb(82, 86, 89)", }}>
                    <embed style={{ position: "absolute", left: 0, top: 0, width: "100%", height: "100%", type: "application/pdf", }}
                        src={`data:application/pdf;base64,${this.state.data.data}`} />
                </div>
            )
        }

        return (
            <React.Fragment>
                {pdf_element}
            </React.Fragment>
        );
    }
}

export default withRouter(PrintPreview);