import * as helper from "components/page/user/contract/edit/helper";
import React from "react";
import { Link } from "react-router-dom";
import * as dateUtil from "util/dateUtil";

/** 見出し部分のベース */
export class BlockBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hide: false, // 見出しを隠す:true、見出しを表示する:false
            element: undefined, // 斜線の為の情報[Tid:ID,elementWidth:横幅,elementHeight:縦幅]
        };
        this.onloadFlg = false; // 画面表示完了フラグ
    }

    /**
     * 見出しを非表示にするかどうか
     * true: する、false: しない
     */
    dohide = hidingHeadlineList => {
        if (hidingHeadlineList === undefined) {
            return false;
        }

        // 見つからない場合
        if (hidingHeadlineList.indexOf(this.state.headlineId) === -1) {
            return false;
        }
        return true;
    }

    /** 見出しの▼を押した際に隠すor表示する */
    handleHide = e => {
        e.preventDefault();
        this.setState({ hide: !this.state.hide });
    }

    /** 削除ボタンのハンドラ */
    handleDelete = (e, id) => {
        e.preventDefault();

        // aタグの中にspanが有ってどっちがクリックされるのか特定できないので、ハンドラ中で狙い撃ちする
        const idx = e.target.getAttribute("data-idx");
        const target = document.querySelector(`#LINK_DEL_${id}_${idx}`);
        const arrayName = target.getAttribute("data-array-name");

        const del = this.props.pageState[arrayName].splice(idx, 1);
        const delValid = this.props.pageState[helper.genValidKey(arrayName)].splice(idx, 1);

        this.props.updatePageState({ ...del, ...delValid });
    }

    /** 追加ボタンのハンドラ */
    handleAdd = (e, id, addObj) => {
        e.preventDefault();

        // aタグの中にspanが有ってどっちがクリックされるのか特定できないので、ハンドラ中で狙い撃ちする
        const target = document.querySelector(`#LINK_ADD_${id}`);
        const arrayName = target.getAttribute("data-array-name");
        const arrayNameValid = helper.genValidKey(arrayName);

        let add = Object.assign([], this.props.pageState[arrayName]);
        let addValid = Object.assign([], this.props.pageState[arrayNameValid]);
        add.push(addObj);
        addValid.push(Object.assign({}, addObj));

        this.props.updatePageState({ [arrayName]: add, [arrayNameValid]: addValid });
    }

    /** render()内の共通処理 */
    renderCommon = () => {
        return {
            hideBlockClass: this.state.hide ? "" : " is-open",
            params: {
                pageState: this.props.pageState,
                updatePageState: this.props.updatePageState,
            }
        };
    }

    /** セレクトボックス用のデータを取得する */
    getSelectbox = (data) => {

        //dataが存在しない場合、空配列を返す
        if (!data) {
            return [""];
        }

        // 取得した名称マスタデータを先頭に空を足してreturnする
        // let tmp = JSON.parse(JSON.stringify(data.items));
        let tmp = Object.assign([], data.items);
        tmp.unshift("");

        return tmp;
    }

    /** 印刷セレクトボックス用のデータを取得する */
    getPrintSelectbox = (data) => {

        //dataが存在しない場合、無を返す
        if (!data) {
            return [""];
        }

        // 取得した名称マスタデータをreturnする
        let tmp = Object.assign([], data.items);

        return tmp;
    }

    /**
     * 文字列を日付（Date型）に変換
     * 文字列が日付でない場合、undefinedを返却する
     **/
    convToDate = str => {
        let now = undefined;
        if (str !== undefined) {
            const ret = new Date(str);
            if (dateUtil.isValidDate(str)) {
                now = ret;
            }
        }
        return now;
    }

    /**
     * 金額項目のカンマ編集
     * ・入力値が未設定の場合、undefinedを返却する
     * ・入力値が文字列の場合、入力値を返却する
     **/
    amountCommaEdit = str => {
        let val = str;
        // 金額項目が未設定
        if (val === undefined || val === null) {
            return val;
        }
        // 金額項目の数値判定(true,falseを除外)
        if (val === true || val === false) {
            return val;
        }
        // 金額項目の数値判定(有限数判定)
        if (!isFinite(val)) {
            return val;
        }
        // カンマ編集して戻す
        return Number(val).toLocaleString();
    }

    /**
     * 表示サイズを求める。
     * Tid：対象項目のID
     */
    findDisplaySize(Tid) {

        // 設定済みの場合は、スキップする
        if (!this.onloadFlg) {
            let tmpElement = [];

            // Tidを分割する
            // 例：JYU0000の2項目とJYU0001の1項目が設定されている
            // AR_JYU0000:AJYU0000-1,AJYU0000-2/AR_JYU0001:AJYU0001-1
            let Tids = Tid.split("/");
            for (var i in Tids) {
                let tmpTid = Tids[i].split(":");
                // elementを取得
                let element = undefined;
                let tmpWidth = 0;
                let tmpHeight = 0;
                let elems = tmpTid[1].split(",");
                for (var j in elems) {
                    element = document.getElementById(elems[j]);
                    if (element === null) {
                        break;
                    }
                    this.onloadFlg = true;
                    tmpWidth = element.offsetWidth;
                    tmpHeight = tmpHeight + element.offsetHeight;
                }
                if (element === null) {
                    break;
                }
                tmpElement.push({ "Tid": tmpTid[0], "elementWidth": tmpWidth, "elementHeight": tmpHeight });
            }

            if (tmpElement.length > 0) {
                this.setState({ element: tmpElement });
            }
        }
    }

    /**
     * 斜線のエリアを定義する
     */
    DiagonalLine(Tid) {

        // 画面表示完了フラグ
        //        this.onloadFlg = false;
        let tmpWidth = "100%";
        let tmpHeight = "100%";
        let tmpRatioName = "diagonalLine-body__120";
        for (var i in this.state.element) {
            if (this.state.element[i].Tid === Tid) {
                let tmpRatio = (this.state.element[i].elementHeight / this.state.element[i].elementWidth) * 100;
                if (tmpRatio > 120) {
                    tmpRatioName = "diagonalLine-body__120";
                } else if (tmpRatio > 50) {
                    tmpRatioName = "diagonalLine-body__50";
                } else if (tmpRatio > 20) {
                    tmpRatioName = "diagonalLine-body__20";
                } else if (tmpRatio > 10) {
                    tmpRatioName = "diagonalLine-body__10";
                } else {
                    tmpRatioName = "diagonalLine-body__0";
                }
                tmpWidth = this.state.element[i].elementWidth + "px";
                tmpHeight = this.state.element[i].elementHeight + "px";
                break;
            }
        }

        return (
            <div className={tmpRatioName}
                style={{
                    width: `${tmpWidth}`,
                    height: `${tmpHeight}`
                }}
            >
            </div >
        );
    }
};

/** 見出しのヘッダ部分のコンポーネント */
export const HeadlineHeader = props => {
    let className = "dataList-header-action";
    className += props.hide ? " is-active" : "";

    return (
        <div className={className}><Link to="" aria-label="link" className="dataList-header-action__item" onClick={props.onClick}></Link></div>
    );
};