import * as helper from "components/page/user/contract/edit/helper";
import * as AWS from "constants/aws";
import React from "react";
import * as awsReq from "util/awsRequest";

export class PageBase extends React.Component {
    constructor(props) {
        super(props);

        // データ取得時点で値が空になっている項目はundefinedにする
        this.state = {
            // ここからページの設定値
            init: false, // 初期情報未取得（true:取得済み）
            upload: undefined, // ファイルアップロード対象の項目ID
            apicalling: false, // true: ページ情報取得のAPIコール中

            popupTarget: "",
            popupFrom: "", // ポップアップから選択時にAPIのレスポンスのどのキーを値として採用するかの格納
            dispPopupOrg: false, // 組織一覧ポップアップ表示（true:表示する）
            dispPopupGua: false, // 保証協会一覧ポップアップ表示（true:表示する）
            dispPopupTmpl: false, // 定型文一覧ポップアップ表示（true:表示する）
            dispPopupEmp: false, // 従業員一覧ポップアップ表示（true:表示する）
        };
    }

    setup = () => {
        // 動的項目の項目IDリスト
        this.arrayKeys = this.getDynamicItemKeys();

        // ページで想定する契約情報項目のキー名
        // ※契約情報の項目一覧のキー名とイコール
        let itemKeyList = [];
        for (const itemKey in this.getPageItems()) {
            itemKeyList.push(itemKey);
        }

        // 参照専用のキー情報を契約情報から除外する
        const refKey = this.getRefOnlyItemKeys();

        itemKeyList = itemKeyList.filter(item => !refKey.includes(item));
        this.contractInfoKeys = itemKeyList;
    }

    /** ページで使用する項目を定義する */
    getPageItems = () => {
        return {};
    }

    /** 動的に項目数が変動する項目のキー名を設定する ※参照のみの場合は不要 */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /**
     * 初期情報取得のためのAPIコール用
     * ※0303だけ独自のinit()を持っているのでそちらの修正も忘れない事
     */
    init = () => {

        if (!this.state.apicalling) {
            this.setState({ apicalling: true });
            awsReq.get(
                AWS.ENDPOINT.PATHS.CONTRACTS_PAGE
                    .replace(":contractId", this.props.match.params.contractId)
                    .replace(":pageId", this.props.match.params.pageId),
                this.initialRequestHandler,
                awsReq.defaultErrorHandler,
                // {}, {},
                {}, {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }

    initialRequestHandler = res => {

        // 以降、レスポンスをページコンポーネントのstateとして管理するため、setStateするための前処理
        let responseBody = res.data.input_content;
        responseBody.input_image = res.data.input_image;

        // 郵便番号の入力欄が3桁4桁に分割されているので、値を分割する（7桁→3桁+4桁）
        if (responseBody.TOR00074 !== undefined && responseBody.TOR00074 !== "") {
            [responseBody.TOR00074_1, responseBody.TOR00074_2] = responseBody.TOR00074.split("-");
        }
        if (responseBody.TOR00096 !== undefined && responseBody.TOR00096 !== "") {
            [responseBody.TOR00096_1, responseBody.TOR00096_2] = responseBody.TOR00096.split("-");
        }

        // 動的項目の場合のみここで数分だけ空オブジェクトを定義する
        // ※動的項目以外は入力コンポーネントのコンストラクタ内で自項目のバリデート項を作成しているが、動的項目はそれが難しいため
        let validArraySetting = {};
        this.arrayKeys.forEach(key => {

            if (key in responseBody) {
                // レスポンスボディ内に動的項目のキーが存在する場合（サーバ側に項目が既に登録されている場合）
                // 項目の数分だけバリデート項を追加する
                // ※空オブジェクトで追加し、バリデートそのものは入力コンポーネント内（InputNormal内）でやる
                validArraySetting[helper.genValidKey(key)] = responseBody[key].map(v => { return {}; });

            } else {
                // レスポンスボディ内に動的項目のキーが存在しない場合（サーバ側に項目が登録されていない場合）
                // page.js内で設定されている動的項目の数分、バリデート項を追加する
                // ※空オブジェクトで追加し、バリデートそのものは入力コンポーネント内（InputNormal内）でやる
                validArraySetting[helper.genValidKey(key)] = this.state[key].map(v => { return {}; });
            }
        });

        const set = {
            ...responseBody,
            ...validArraySetting,
            hidingHeadline: res.data.hiding_headline_id,
            disableItem: res.data.disable_item_id,
            init: true,
            apicalling: false,
        };
        this.setState(set);
    }

    /** this.stateの中から契約情報項目のみを抽出する */
    pickupContractInfo = () => {
        let tmp = {};
        this.contractInfoKeys.forEach(val => {
            tmp[val] = this.state[val];
        });

        // オブジェクトの値が全て空の場合、false
        const hasObjectValue = (obj) => {
            for (const item in obj) {
                const tmp = obj[item];
                if (!(tmp === undefined || tmp === null || tmp === "")) {
                    return true;
                }
            }
            return false;
        };

        // 動的に数が変動する項目に関して値が入っていない場合、項目値をundefinedにする
        // ※Amplifyの仕様でundefinedになっている項目はAPIリクエストパラメータから除外されるため、保存用のAPIコールのパラメータから除外される
        this.arrayKeys.forEach(name => {
            let _ = []; // 値が入っているオブジェクトを暫定で格納するための配列

            // 動的項目の中身をチェックして、オブジェクトの値が全て空の場合、該当のオブジェクトを除外する
            // ※リクエストの対象から外すため
            const arrayItem = tmp[name];
            arrayItem.forEach(obj => {

                // オブジェクトがキーを持っている場合 かつ
                // オブジェクトのキー値が空でない場合
                if (Object.keys(obj).length !== 0 && hasObjectValue(obj)) {
                    _.push(obj);
                }
            });

            // 全ての動的項目の値が空だった場合、undefinedにする
            //tmp[name] = _.length !== 0 ? _ : undefined;
        });
        return tmp;
    }

    /**
     * ・ページが持っているstateを更新する
     * ・doNofityがtrueの場合、大元（contractEdit.js）のupdatedも更新する ※入力値に更新があったことを通知する
     */
    updatePageState = (state, doNofity = true) => {
        this.setState(state);

        if (doNofity) {
            this.props.pageUpdated(true);
        }
    }
}