import Amplify from "aws-amplify";
import * as AWS from "../constants/aws";


export function amplify_initialize() {
    Amplify.configure({
        Auth: {
            identityPoolId: AWS.COGNITO.IDENTITY_POOL_ID, // Amazon Cognito Identity Pool ID
            region: AWS.COGNITO.REGION, // Amazon Cognito Region
            userPoolId: AWS.COGNITO.USER_POOL_ID, // Amazon Cognito User Pool ID
            userPoolWebClientId: AWS.COGNITO.USER_POOL_WEB_CLIENT_ID // Amazon Cognito Web Client ID
            // authenticationFlowType: 'CUSTOM_AUTH', // cognitoのカスタム認証フローが必要になった時に必要（今は不要）
        },
        API: {
            endpoints: [
                {
                    name: AWS.ENDPOINT.REST_API.NAME,
                    endpoint: AWS.ENDPOINT.REST_API.URL,
                    region: AWS.ENDPOINT.REST_API.REGION,
                },
                {
                    name: AWS.ENDPOINT.REST_API_MOCK.NAME,
                    endpoint: AWS.ENDPOINT.REST_API_MOCK.URL,
                    region: AWS.ENDPOINT.REST_API_MOCK.REGION,
                },
            ]
        }
    });
}
