import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import Input from "components/page/user/contract/edit/input/inputNormal";
import InputTextArea from "components/page/user/contract/edit/input/inputTextArea";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 専用部分の用途その他の制限に関する規約等の定め */
export default class Block3 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020803",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        //JYU00816の入力上限判定
        const JYU00816LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00816_${this.props.displayTypeID}`, this.props.pageState.JYU00816);

        //JYU00833の入力上限判定
        const JYU00833LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00833_${this.props.displayTypeID}`, this.props.pageState.JYU00833);

        //JYU00820の入力上限判定
        const JYU00820LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00820_${this.props.displayTypeID}`, this.props.pageState.JYU00820);

        //JYU00824の入力上限判定
        const JYU00824LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00824_${this.props.displayTypeID}`, this.props.pageState.JYU00824);

        //JYU00828の入力上限判定
        const JYU00828LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00828_${this.props.displayTypeID}`, this.props.pageState.JYU00828);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">専有部分の用途その他の制限に関する規約等の定め（別添管理規約・使用細則に記載されています。）</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240 dataTable-title__center" colSpan="2">規約等の定めの有無</th>
                                <th className="dataTable-title dataTable-title__center">制限の内容</th>
                                <th className="dataTable-title dataTable-title__w145 dataTable-title__center">管理規約</th>
                                <th className="dataTable-title dataTable-title__w145 dataTable-title__center">使用細則</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">用途制限</th>
                                <td className="dataTable-title dataTable-title__w120">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00814" name="JYU00814" checked={this.props.pageState.JYU00814} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01402" name="JYU01402" checked={this.props.pageState.JYU01402} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-rightborder">
                                    <InputTextArea id="ID_JYU00816" name="JYU00816" cols={30} rows={10} height="h148" value={this.props.pageState.JYU00816} {...params} placeholder={JYU00816LimitErrorMessage.inputLimitGuide} {...params}/>
                                    <p className="LengthOver-error">{JYU00816LimitErrorMessage.inputLimitErrorMessage}</p>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00817" name="JYU00817" value={this.props.pageState.JYU00817} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01403" name="JYU01403" value={this.props.pageState.JYU01403} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01404" name="JYU01404" value={this.props.pageState.JYU01404} {...params} length="w93" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00818" name="JYU00818" value={this.props.pageState.JYU00818} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01405" name="JYU01405" value={this.props.pageState.JYU01405} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01406" name="JYU01406" value={this.props.pageState.JYU01406} {...params} length="w93" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">ペットの飼育<br />制限</th>
                                <td className="dataTable-title dataTable-title__w120">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00819" name="JYU00819" checked={this.props.pageState.JYU00819} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01407" name="JYU01407" checked={this.props.pageState.JYU01407} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-rightborder">
                                    <InputTextArea id="ID_JYU00820" name="JYU00820" cols={30} rows={10} height="h148" value={this.props.pageState.JYU00820} {...params} placeholder={JYU00820LimitErrorMessage.inputLimitGuide} {...params}/>
                                    <p className="LengthOver-error">{JYU00820LimitErrorMessage.inputLimitErrorMessage}</p>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00821" name="JYU00821" value={this.props.pageState.JYU00821} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01408" name="JYU01408" value={this.props.pageState.JYU01408} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01409" name="JYU01409" value={this.props.pageState.JYU01409} {...params} length="w93" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00822" name="JYU00822" value={this.props.pageState.JYU00822} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01410" name="JYU01410" value={this.props.pageState.JYU01410} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01411" name="JYU01411" value={this.props.pageState.JYU01411} {...params} length="w93" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">フローリング<br />の制限</th>
                                <td className="dataTable-title dataTable-title__w120">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00823" name="JYU00823" checked={this.props.pageState.JYU00823} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01413" name="JYU01413" checked={this.props.pageState.JYU01413} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-rightborder">
                                    <InputTextArea id="ID_JYU00824" name="JYU00824" cols={30} rows={10} height="h148" value={this.props.pageState.JYU00824} {...params} placeholder={JYU00824LimitErrorMessage.inputLimitGuide} {...params}/>
                                    <p className="LengthOver-error">{JYU00824LimitErrorMessage.inputLimitErrorMessage}</p>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00825" name="JYU00825" value={this.props.pageState.JYU00825} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01414" name="JYU01414" value={this.props.pageState.JYU01414} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01415" name="JYU01415" value={this.props.pageState.JYU01415} {...params} length="w93" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00826" name="JYU00826" value={this.props.pageState.JYU00826} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01416" name="JYU01416" value={this.props.pageState.JYU01416} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01417" name="JYU01417" value={this.props.pageState.JYU01417} {...params} length="w93" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">楽器の使用<br />制限</th>
                                <td className="dataTable-title dataTable-title__w120">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00827" name="JYU00827" checked={this.props.pageState.JYU00827} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01418" name="JYU01418" checked={this.props.pageState.JYU01418} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-rightborder">
                                    <InputTextArea id="ID_JYU00828" name="JYU00828" cols={30} rows={10} height="h148" value={this.props.pageState.JYU00828} {...params} placeholder={JYU00828LimitErrorMessage.inputLimitGuide} {...params}/>
                                    <p className="LengthOver-error">{JYU00828LimitErrorMessage.inputLimitErrorMessage}</p>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00829" name="JYU00829" value={this.props.pageState.JYU00829} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01419" name="JYU01419" value={this.props.pageState.JYU01419} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01420" name="JYU01420" value={this.props.pageState.JYU01420} {...params} length="w93" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <div className="dataTableUnit_vr">
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU00830" name="JYU00830" value={this.props.pageState.JYU00830} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01421" name="JYU01421" value={this.props.pageState.JYU01421} {...params} length="w93" />
                                                </div>
                                                <div className="dataTableUnit__item">
                                                    <Input id="ID_JYU01422" name="JYU01422" value={this.props.pageState.JYU01422} {...params} length="w93" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">条</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00833" name="JYU00833" value={this.props.pageState.JYU00833} text={SEND_OTHER_PAPER.JYU00833} placeholder={JYU00833LimitErrorMessage.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00833LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}