import React from "react";
import InputDate from "../../input/inputDate";
import Input from "../../input/inputNormal";
import InputTime from "../../input/inputTime";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 契約情報 */
export default class Block4 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010104",
        };
    }


    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">契約情報</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">契約年月日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00014" name="TOR00014" value={this.props.pageState.TOR00014} {...params} length="w112" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">契約時間</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputTime id="ID_TOR00015" name="TOR00015" value={this.props.pageState.TOR00015} {...params} length="w112" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">契約場所</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00016" name="TOR00016" value={this.props.pageState.TOR00016} {...params} length="wFull" />
                                    </div>
                                </td>
                                <td className="dataTable-body dataTable-body__w355 dataTable-body__leftborder" colSpan="2">&nbsp;</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">契約書上の<br />決済日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00017" name="TOR00017" value={this.props.pageState.TOR00017} {...params} length="w112" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">確定決済日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00018" name="TOR00018" value={this.props.pageState.TOR00018} {...params} length="w112" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">引渡日予定日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00019" name="TOR00019" value={this.props.pageState.TOR00019} {...params} length="w112" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">引渡し確定日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00020" name="TOR00020" value={this.props.pageState.TOR00020} {...params} length="w112" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">引渡猶予理由</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00021" name="TOR00021" value={this.props.pageState.TOR00021} {...params} length="wFull" />
                                    </div>
                                </td>
                                <td className="dataTable-body dataTable-body__w355 dataTable-body__leftborder" colSpan="2">&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}