import axios from "axios";
import BaseGrid from "components/common/frame/baseGrid";
import * as AWS from "constants/aws";
import { CONTRACT_TYPE_ID } from "constants/contractEditPageInfo";
import { ERROR_MESSAGE } from "constants/errorMessage";
import { FORMAT } from "constants/format";
import { DOCUMENT_UPLOAD } from "constants/messages";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { Link } from "react-router-dom";
import * as awsReq from "util/awsRequest";

export default class ClauseAndDocumentMaintenanceFrk extends React.Component {
    constructor(props) {
        super(props);
        this.reader = new FileReader();
    }

    componentDidMount() {
        document.title = pageInfo.CLAUSE_AND_DOCUMENT_MAINTENANCE.title;
    }

    /** PDF添付からファイル選択時のハンドラ */
    handleChange = async e => {
        const target = e.target;

        // APIコール失敗→ファイル選択→キャンセル押下でundefinedがくるのでその対応
        if (target === undefined) { return; }
        // ファイルアップロード成功→再度、ファイル選択→キャンセル押下でtargetはあるけどfilesが無いでくるのでその対応
        if (target.files.length === 0) { return; }

        this.reader.readAsArrayBuffer(target.files[0]);

        // ファイルの読み込みが完了した時に発火されるイベント用のリスナーを登録
        const event = async e => {
            this.reader.removeEventListener('load', event);
            const data = this.reader.result;

            // アップロード用の署名付きURLの取得
            // エラー時はダイアログを出す
            // target.value = ""; はファイル再選択時にchangeイベントを発火させる用
            const preSignedRes = await awsReq.get(
                AWS.ENDPOINT.PATHS.GET_ADDITION_DOCUMENT_UPLOAD_URL,
                res => { return res.data; },
                error => { alert(ERROR_MESSAGE.SERVER_ERROR); target.value = ""; },
                {
                    contract_type_id: target.dataset.contractid,
                    addition_type: target.dataset.type,
                    pdf_file_name: target.files[0].name,
                },
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
            // 署名付きURLエラー時は処理を継続しない
            if (preSignedRes === undefined) { return; }

            // アップロード用の署名付きURLへファイルのアップロード
            try {
                await axios({
                    method: 'PUT',
                    url: preSignedRes['pre_signed_url'],
                    headers: { 'Content-Type': 'application/pdf', }, // アップロードしたいファイルに合わせる
                    data: data,
                });

            } catch (error) {
                alert(ERROR_MESSAGE.SERVER_ERROR);
                target.value = "";
                return;
            }

            // PDFファイルS3キー更新
            await awsReq.put(
                AWS.ENDPOINT.PATHS.UPDATE_ADDITION_DOCUMENT_S3_KEY,
                res => { return res.data; },
                error => { alert(ERROR_MESSAGE.SERVER_ERROR); target.value = ""; },
                {
                    input_format: FORMAT.FRK.value,
                    contract_type_id: target.dataset.contractid,
                    addition_type: target.dataset.type,
                    pdf_version: preSignedRes.version,
                },
                {}, AWS.ENDPOINT.REST_API.NAME,
            );

            alert(DOCUMENT_UPLOAD.UPLOAD_COMPLETE);
        };
        this.reader.addEventListener('load', event);
    }

    /** プレビュー押下時のハンドラ */
    handlePreview = async e => {
        e.preventDefault();

        // アップロード用の署名付きURLの取得
        // エラー時はダイアログを出す
        // target.value = ""; はファイル再選択時にchangeイベントを発火させる用
        const res = await awsReq.get(
            AWS.ENDPOINT.PATHS.GET_ADDITION_DOCUMENT_DOWNLOAD_URL,
            res => { return res; },
            error => { return error.response; },
            {
                // fc: "download",
                input_format: FORMAT.FRK.value,
                contract_type_id: e.target.dataset.contractid,
                addition_type: e.target.dataset.type,
            },
            {}, AWS.ENDPOINT.REST_API.NAME,
        );
        if (res.status >= 500) { alert(ERROR_MESSAGE.SERVER_ERROR); return; } // 500系
        // TODO: 404の時のメッセージを決めてもらう
        if (res.status === 404) { alert(ERROR_MESSAGE.PREVIEW_FILE_NOT_FOUND); return; } // 404専用
        if (res.status >= 400) { alert(ERROR_MESSAGE.SERVER_ERROR); return; } // 400系

        window.open(res.data["pre_signed_url"]);
    }

    render() {
        return (
            <React.Fragment>
                <BaseGrid>
                    <div className="dataList dataList__accordion is-open">
                        <div className="dataList-header">種別条件</div>
                        <div className="dataList-body">
                            <table className="dataTable">
                                <tbody>
                                    <tr>
                                        <th className="dataTable-title is-emphasis" rowSpan="2" colSpan="3">&nbsp;</th>
                                        <th className="dataTable-title is-emphasis" colSpan="2">約 款</th>
                                        <th className="dataTable-title is-emphasis" rowSpan="2">別 表</th>
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title is-emphasis">関東地方</th>
                                        <th className="dataTable-title2">関西地方</th>
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" rowSpan="11">一般</th>
                                        <th className="dataTable-title dataTable-title__w237" rowSpan="3">土地用</th>
                                        <th className="dataTable-title dataTable-title__w237">実測・清算</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0101} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title">確定測量・清算</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0102} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title">売買代金固定</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0103} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" rowSpan="3">土地建物用</th>
                                        <th className="dataTable-title">実測・清算</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0104} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title">確定測量・清算</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0105} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title">売買代金固定</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0106} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" rowSpan="2">区分所有建物</th>
                                        <th className="dataTable-title">敷地権</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0107} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title">非敷地権</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0108} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" colSpan="2">借地権付建物用</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0109} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" colSpan="2">定期借地権付建物用</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0110} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" colSpan="2">定期借地権付区分所有建物用</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0111} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" rowSpan="9">消費者契約</th>
                                        <th className="dataTable-title" rowSpan="3">土地用</th>
                                        <th className="dataTable-title">実測・清算</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0201} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title">確定測量・清算</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0202} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title">売買代金固定</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0203} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" rowSpan="3">土地建物用</th>
                                        <th className="dataTable-title">実測・清算</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0204} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title">確定測量・清算</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0205} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title">売買代金固定</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0206} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" rowSpan="2">区分所有建物用</th>
                                        <th className="dataTable-title">敷地権</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0207} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title">非敷地権</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0208} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" colSpan="2">借地権付建物</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0209} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" rowSpan="11">売主宅建業者</th>
                                        <th className="dataTable-title" rowSpan="3">土地用</th>
                                        <th className="dataTable-title">実測・清算</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0301} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title">確定測量・清算</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0302} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title">売買代金固定</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0303} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" rowSpan="3">土地建物用</th>
                                        <th className="dataTable-title">実測・清算</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0304} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title">確定測量・清算</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0305} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title">売買代金固定</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0306} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" rowSpan="2">区分所有建物用</th>
                                        <th className="dataTable-title">敷地権</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0307} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title">非敷地権</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0308} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" colSpan="2">土地建物・新築用</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0309} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" colSpan="2">借地権付建物用</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0310} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                    <tr>
                                        <th className="dataTable-title" colSpan="2">新築・借地権付建物</th>
                                        <ComponentTD contractId={CONTRACT_TYPE_ID.ID_0311} handleChange={this.handleChange} handlePreview={this.handlePreview} />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </BaseGrid>
            </React.Fragment>
        );
    }
}

const ComponentTD = props => {

    const inputSettings = {
        accept: ".pdf",
        onChange: props.handleChange,
    };

    const previewSettings = {
        onClick: props.handlePreview,
    };

    const id01 = `id_pdf_uploads_${props.contractId}_01`;
    const id02 = `id_pdf_uploads_${props.contractId}_02`;
    const id03 = `id_pdf_uploads_${props.contractId}_03`;

    return (
        <React.Fragment>
            <td className="dataTable-body dataTable-body__w240">
                <ul className="l-baseAction l-baseAction__rightTwo">
                    <li className="doclist">
                        {/* 約款の方：関東地方 */}
                        <label htmlFor={id01} className="baseAction">PDF添付</label>
                        <input type="file" data-type="01" data-contractid={props.contractId} id={id01} name="pdf_uploads" {...inputSettings} />
                    </li>
                    <li><Link to="" aria-label="link" className="baseAction" data-type="01" data-contractid={props.contractId} {...previewSettings}>プレビュー</Link></li>
                </ul>
            </td>
            <td className="dataTable-body dataTable-body__w240">
                <ul className="l-baseAction l-baseAction__rightTwo">
                    <li className="doclist">
                        {/* 約款の方：関西地方 */}
                        <label htmlFor={id03} className="baseAction">PDF添付</label>
                        <input type="file" data-type="03" data-contractid={props.contractId} id={id03} name="pdf_uploads" {...inputSettings} />
                    </li>
                    <li><Link to="" aria-label="link" className="baseAction" data-type="03" data-contractid={props.contractId} {...previewSettings}>プレビュー</Link></li>
                </ul>
            </td>
            <td className="dataTable-body dataTable-body__w240">
                <ul className="l-baseAction l-baseAction__rightTwo">
                    <li className="doclist">
                        {/* 別表の方 */}
                        <label htmlFor={id02} className="baseAction">PDF添付</label>
                        <input type="file" data-type="02" data-contractid={props.contractId} id={id02} name="pdf_uploads" {...inputSettings} />
                    </li>
                    <li><Link to="" aria-label="link" className="baseAction" data-type="02" data-contractid={props.contractId} {...previewSettings}>プレビュー</Link></li>
                </ul>
            </td>
        </React.Fragment>
    );
};