/** ユーザID */
export function saveAgencyUserId(agencyUserId) {
    return {
        type: "SAVE_AGENCY_USER_ID",
        agencyUserId: agencyUserId
    };
}

/** 氏名 */
export function saveUserName(userName) {
    return {
        type: "SAVE_USER_NAME",
        userName: userName
    };
}

/** 仲介業者会社ID */
export function saveAgencyId(agencyId) {
    return {
        type: "SAVE_AGENCY_ID",
        agencyId: agencyId
    };
}

/** 仲介業者支店・組織ID */
export function saveAgencyBranchId(agencyBranchId) {
    return {
        type: "SAVE_AGENCY_BRANCH_ID",
        agencyBranchId: agencyBranchId
    };
}

/** 権限 */
export function saveAuthority(authority) {
    return {
        type: "SAVE_AUTHORITY",
        authority: authority
    };
}

/** 利用フォーマット */
export function saveUseFormat(useFormat) {
    return {
        type: "SAVE_USE_FORMAT",
        useFormat: useFormat
    };
}