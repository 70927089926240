import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 私道に関する負担等に関する事項 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020601",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
        this.isSameJYU01450 = this.props.pageState.JYU01450;
        this.isSameJYU01382 = this.props.pageState.JYU01382;
    }

    render() {

        // 非活性の設定
        let JYU01450_disabled = this.props.pageState.JYU01450;
        let JYU01382_disabled = this.props.pageState.JYU01382;

        // ハイフン挿入の設定
        const HYPHEN_TEXT = "---------------------------------------------------------------------------";

        if (!this.isSameJYU01450 && this.props.pageState.JYU01450) {
            this.isSameJYU01450 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00696", HYPHEN_TEXT));
        }

        if (this.isSameJYU01450 && !this.props.pageState.JYU01450) {
            this.isSameJYU01450 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00696", ""));
        }

        if (!this.isSameJYU01382 && this.props.pageState.JYU01382) {
            this.isSameJYU01382 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00700", HYPHEN_TEXT));

        }

        if (this.isSameJYU01382 && !this.props.pageState.JYU01382) {
            this.isSameJYU01382 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00700", ""));
        }

        // 斜線対応の設定
        let DomDiagonalLine = undefined;
        if (this.onloadFlg && this.props.pageState.JYU01383) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_JYU01383");
        }

        const { hideBlockClass, params } = this.renderCommon();

        // 文字数制限定義
        const JYU00701LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00701",
                this.props.pageState.JYU00701);
        const JYU00705LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00705",
                this.props.pageState.JYU00705);


        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU01383:AR_JYU01383-1,AR_JYU01383-2,AR_JYU01383-3")}>
                <div className="dataList-header">
                    <div className="dataList-header-title">私道に関する負担等に関する事項</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-body is-emphasis" colSpan="4">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-text is-emphasis">対象不動産に含まれる私道に関する負担の内容</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center">負担面積</th>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00695" name="JYU00695" checked={this.props.pageState.JYU00695} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01450" name="JYU01450" checked={this.props.pageState.JYU01450} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <Input id="ID_JYU00696" name="JYU00696" value={this.props.pageState.JYU00696} {...params} length="wFull" disabled={JYU01450_disabled} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center">負担金の有無</th>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00699" name="JYU00699" checked={this.props.pageState.JYU00699} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01382" name="JYU01382" checked={this.props.pageState.JYU01382} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <Input id="ID_JYU00700" name="JYU00700" value={this.props.pageState.JYU00700} {...params} length="wFull" disabled={JYU01382_disabled} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00701" name="JYU00701" value={this.props.pageState.JYU00701} text={SEND_OTHER_PAPER.JYU00701} placeholder={JYU00701LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00701LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="3">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-input"><span className="checkBox-text">対象不動産に含まれない私道に関する事項</span></div>
                                        <div className="dataTableUnit-text">（</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00702" name="JYU00702" checked={this.props.pageState.JYU00702} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01383" name="JYU01383" checked={this.props.pageState.JYU01383} {...params} />
                                                        <span className="checkBox-text">無　※チェックした場合は、斜線を引きます。</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </th>
                            </tr>
                            {DomDiagonalLine}
                            <tr id="AR_JYU01383-1">
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120" rowSpan="2">所有者</th>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">住所</th>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU00703" name="JYU00703" value={this.props.pageState.JYU00703} {...params} length="wFull" />
                                </td>
                            </tr>
                            <tr id="AR_JYU01383-2">
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">氏名</th>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU00704" name="JYU00704" value={this.props.pageState.JYU00704} {...params} length="wFull" />
                                </td>
                            </tr>
                            <tr id="AR_JYU01383-3">
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00705" name="JYU00705" value={this.props.pageState.JYU00705} text={SEND_OTHER_PAPER.JYU00705} placeholder={JYU00705LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00705LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}