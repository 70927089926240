
import BaseGrid from "components/common/frame/baseGrid";
import PaginationPage from "components/common/frame/paginationPage";
import { InputCheckBoxRecord, InputSelectBoxRecord, InputTextRecord } from "components/common/parts/inputRecord";
import { OutputRecord } from "components/common/parts/outputRecord";
import * as APP from "config/app";
import { AUTHORITY_FRK, AUTHORITY_FRK_MUFG } from "constants/authority";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";

/** 従業員一覧画面（FRK書式） */
class EmployeeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1, // 現ページ
            totalRecords: 0, // 総件数
            showStoppedUser: false
        };
        this.apicalling = 0; // APIコール中のページを保持（0はコール無し。連続API防止措置なので簡易）
    }

    componentDidMount() {
        document.title = pageInfo.EMPLOYEE_INFO_LIST_FRK.title;
        this.requestBranchList();
        this.requestEmployeeList();
    }

    componentDidUpdate() {
        if (!this.state.agencyUsers) {
            this.requestEmployeeList();
        }
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputText = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** セレクトボックスの入力値をstateに格納 */
    handleChangeInputSelectBox = e => {
        const target = e.target;
        this.setState({ [target.name]: Number(target.value) });
    }

    /** チェックボックスの入力値をstateに格納 */
    handleChangeInputCheckBox = e => {
        const target = e.target;
        this.setState(state => ({ [target.name]: !state[target.name] }));
    }

    /** 絞り込み条件 => 絞込みハンドラ */
    handleNarrowDown = e => {
        e.preventDefault();
        this.setState({ agencyUsers: undefined, page: 1 });
    }

    /** クリアボタンのハンドラ */
    handleClear = e => {
        e.preventDefault();
        this.setState({ page: 1, keyword: undefined, agencyBranchId: undefined, showStoppedUser: false });
        this.setState({ agencyUsers: undefined });
    }

    handlePageNumber = page => {
        this.setState({ page: page });
        this.setState({ agencyUsers: undefined });
    }

    render() {
        // 支店リスト
        const agencyBranches = this.state.agencyBranches;
        let branchList = agencyBranches
            ? agencyBranches.map(agencyBranche => ({ value: agencyBranche.id, display: agencyBranche.branchName }))
            : [];
        branchList.unshift({ value: 0, display: "" });

        // 従業員一覧Dom
        const agencyUsers = this.state.agencyUsers;
        const agencyUsersListDom = agencyUsers
            ? agencyUsers.map(agencyUser => {
                // 権限による表示制御
                var authority = null;
                // MUFG様向けと他社様向けの表示分岐
                if (APP.OPERATOR_AGENCY_MUFG.includes(this.props.agencyId.toString())) {
                    switch (agencyUser.authority) {
                        case AUTHORITY_FRK_MUFG.GENERAL.value:
                            authority = ""; // 権限が担当者の場合、未表示
                            break;
                        case AUTHORITY_FRK_MUFG.MANAGER.value:
                            authority = AUTHORITY_FRK_MUFG.MANAGER.display;
                            break;
                        case AUTHORITY_FRK_MUFG.AREA_MANAGER.value:
                            authority = AUTHORITY_FRK_MUFG.AREA_MANAGER.display;
                            break;
                        case AUTHORITY_FRK_MUFG.CONTRACT_EXAMINATION.value:
                            authority = AUTHORITY_FRK_MUFG.CONTRACT_EXAMINATION.display;
                            break;
                        default:
                            return null;
                    }
                } else {
                    switch (agencyUser.authority) {
                        case AUTHORITY_FRK.GENERAL.value:
                            authority = ""; // 権限が担当者の場合、未表示
                            break;
                        case AUTHORITY_FRK.MANAGER.value:
                            authority = AUTHORITY_FRK.MANAGER.display;
                            break;
                        case AUTHORITY_FRK.AREA_MANAGER.value:
                            authority = AUTHORITY_FRK.AREA_MANAGER.display;
                            break;
                        case AUTHORITY_FRK.CONTRACT_EXAMINATION.value:
                            authority = AUTHORITY_FRK.CONTRACT_EXAMINATION.display;
                            break;
                        default:
                            return null;
                    }
                }

                // 表示させるデータリスト
                const outputList = [
                    agencyUser.isStopped ? "停止中" : "",
                    agencyUser.userName,
                    agencyUser.branchName,
                    agencyUser.employeeNumber,
                    authority,
                    agencyUser.realEstateNotaryNumber ? "あり" : "なし",
                    () => (<Link to={pageInfo.EMPLOYEE_INFO_MAINTENANCE_UPDATE_FRK.path.replace(":id", agencyUser.agencyUserId)} aria-label="link" className="baseAction">詳細</Link>)
                ];
                return (<OutputRecord outputList={outputList} key={agencyUser.agencyUserId} />);
            })
            : null;

        const titleSetting = { className: "dataTable-title dataTable-title__w120" };
        const inputSetting = { onChange: this.handleChangeInputText };

        // 新規作成ボタンを有効にするかどうか
        const createBtnActive = this.state.employeeContractQuantity === 0 ? false : true;

        // APIから値を取得している間は絞り込むボタンを押下させないようにする
        // ※それぞれ、this.state.data が従業員一覧で、this.state.selectbox がセレクトボックスに使う値
        const pickupParams = (this.state.agencyUsers !== undefined && this.state.agencyBranches !== undefined)
            ? { className: "baseAction", onClick: this.handleNarrowDown }
            : { className: "baseAction baseAction--inactive", onClick: e => e.preventDefault() };

        return (
            <BaseGrid>
                <div className="l-fieldText">
                    <div className="fieldText fieldText--right">
                        <span>有効従業員登録数 {this.state.totalRecords}　（ご契約による登録可能数 {this.state.employeeContractQuantity}）</span>
                    </div>
                </div>

                <div className="dataList dataList__accordion is-open">
                    <div className="dataList-header">絞り込み条件</div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <InputTextRecord titleName="キーワード"
                                        inputData={{ name: "keyword", value: this.state.keyword || "" }}
                                        titleSetting={titleSetting} bodySetting={{ colSpan: "3" }} inputSetting={inputSetting} />
                                </tr>

                                <tr>
                                    <InputSelectBoxRecord titleName="支店／組織"
                                        inputData={{ name: "agencyBranchId", value: this.state.agencyBranchId || "" }}
                                        optionsDataList={branchList} titleSetting={titleSetting}
                                        inputSetting={{ onChange: this.handleChangeInputSelectBox, onBlur: this.handleValidateInputText }} />

                                    <InputCheckBoxRecord titleName="停止中従業員" checkboxText="含み表示"
                                        inputData={{ name: "showStoppedUser", checked: this.state.showStoppedUser }}
                                        titleSetting={titleSetting} inputSetting={{ onChange: this.handleChangeInputCheckBox }} />
                                </tr>

                                <tr>
                                    <td className="dataTable-body" colSpan="4">
                                        <ul className="l-baseAction l-baseAction__rightTwo">
                                            <li><Link to="" aria-label="link" className="baseAction" {...pickupParams}>絞り込み</Link></li>
                                            <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleClear}>クリア</Link></li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <PaginationPage activePage={this.state.page} totalItemsCount={this.state.totalRecords} onChange={this.handlePageNumber}
                    toCreate={pageInfo.EMPLOYEE_INFO_MAINTENANCE_FRK.path} createButtonInActive={!createBtnActive}>

                    <div className="dataList dataList__accordion is-open">
                        <div className="dataList-header"><div className="dataList-header-title">従業員情報一覧</div></div>
                        <div className="dataList-body">
                            <table className="dataTable">
                                <tbody>
                                    <tr>
                                        <th className="dataTable-title is-emphasis dataTable-title__w80"></th>
                                        <th className="dataTable-title is-emphasis dataTable-title__w400">氏名</th>
                                        <th className="dataTable-title is-emphasis">所属支店／組織</th>
                                        <th className="dataTable-title is-emphasis dataTable-title__w130">従業員番号</th>
                                        <th className="dataTable-title is-emphasis dataTable-title__w140">権限</th>
                                        <th className="dataTable-title is-emphasis dataTable-title__w110">宅建士資格</th>
                                        <th className="dataTable-title is-emphasis dataTable-title__w140"></th>
                                    </tr>
                                    {agencyUsersListDom || null}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </PaginationPage>
            </BaseGrid >
        );
    }

    /** 支店リスト取得APIを呼出し、stateを更新する */
    requestBranchList = () => {
        awsReq.get(
            AWS.ENDPOINT.PATHS.BRANCHAND_LIST,
            response => {
                this.setState({ agencyBranches: convert.convertToFlatCamelCase(response.data) });
            },
            awsReq.defaultErrorHandler,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    /** 従業員一覧取得APIコール、state更新 */
    requestEmployeeList = () => {
        if (this.apicalling !== this.state.page) {
            this.apicalling = this.state.page;

            let requestBody = {
                page: this.state.page,
                show_stopped_user: this.state.showStoppedUser,
                keyword: this.state.keyword,
                agency_branch_id: this.state.agencyBranchId === 0 ? undefined : this.state.agencyBranchId,
            };

            awsReq.post(
                AWS.ENDPOINT.PATHS.EMPLOYEE_LIST,
                response => {
                    this.apicalling = 0;
                    // 現在表示しているページとページ番号が同じレスポンスだった場合に画面へ反映させる
                    // ※古いリクエストが返ってきても無視する
                    if (this.state.page === response.data.page_number) {
                        this.setState(convert.convertToFlatCamelCase(response.data));
                    }
                },
                error => {
                    this.apicalling = 0;
                    this.setState({ agencyUsers: [] });
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                },
                requestBody,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        agencyId: state.agencyId
    };
};

export default withRouter(connect(mapStateToProps)(EmployeeList));
