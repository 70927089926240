import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import InputTextArea from "components/page/user/contractFrk/edit/input/inputTextArea";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import React from "react";
import Validation from "util/validate";

/** 契約不適合責任の履行に関する措置 */
export default class Block3 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030204",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 文字数制限定義
        const FUD00367LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "FUD00367",
                this.props.pageState.FUD00367);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">契約不適合責任の履行に関する措置</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">種類</th>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_FUD00365" name="FUD00365" checked={this.props.pageState.FUD00365} {...params} />
                                                        <span className="checkBox-text">1.住宅販売瑕疵担保保証金の供託</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_FUD00366" name="FUD00366" checked={this.props.pageState.FUD00366} {...params} />
                                                        <span className="checkBox-text">2.住宅瑕疵担保責任保険の付保</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body">
                                    <div className="dataTableTextUnit">
                                        <div className="dataTableTextUnit-body">
                                            <InputTextArea id="ID_FUD00367" name="FUD00367" value={this.props.pageState.FUD00367} height="h165" {...params} placeholder={FUD00367LimitMsg.inputLimitGuide} {...params} />
                                            <p className="LengthOver-error">{FUD00367LimitMsg.inputLimitMsg}</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}