import { API, Auth } from "aws-amplify";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import * as sessionUtils from "util/sessionUtils";
import * as AWS from "../constants/aws";

export const defaultErrorHandler = undefined;

//Loadingの表示が行われているかの変数。※多重にLoadingの表示をかけない制御。
let loadingIndicator = false;

export const get = async (path, callback, errorHandle, queryStringParameters = {}, headers = {}, apiName = AWS.ENDPOINT.REST_API_MOCK.NAME) => {

    // LoadingDOMの初期設定
    const { loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM } = setupLoadingDOM();

    try {
        // セッションタイムアウトしている場合、セッションタイムアウト画面へ遷移させる
        if (sessionUtils.sessionTimeouted()) {
            window.location.href = pageInfo.SESSION_TIMEOUT.path;
            return;
        }

        // IDトークンの期限切れが5分前だった場合、IDトークンを再発行させる
        await refreshIdToken();

        // IDトークン取得する用
        const data = await Auth.currentSession();

        // Loadingの表示
        if (!loadingIndicator) {
            enableLoadingDOM(loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM);
        }
        loadingIndicator = true;

        // GETリクエストする
        const res = await API.get(apiName, path, {
            headers: {
                "x-id-token": data.getIdToken().getJwtToken(),
                ...headers
            },
            response: true,
            queryStringParameters: {
                ...queryStringParameters,
            }
        });

        // セッションタイムアウトの更新
        sessionUtils.setNextSessionTimeout();

        // Loadingを消す
        disableLoadingDOM(loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM);
        loadingIndicator = false;

        // callbackで指定された処理を実行
        return callback(res);

    } catch (error) {

        // デフォルト（errorHandleがundefined）として、エラーメッセージ付きのダイアログを出すだけのハンドラを定義
        const handler = errorHandle !== undefined ? errorHandle : () => { alert(ERROR_MESSAGE.SERVER_ERROR); };

        // Loadingを消す
        disableLoadingDOM(loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM);
        loadingIndicator = false;

        // HTTPステータスがエラーの場合もcatchできる（例：404、500等
        return handler(error);
    }
};

export const post = async (path, callback, errorHandle, body, headers = {}, apiName = AWS.ENDPOINT.REST_API_MOCK.NAME) => {

    // LoadingDOMの初期設定
    const { loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM } = setupLoadingDOM();

    try {
        // セッションタイムアウトしている場合、セッションタイムアウト画面へ遷移させる
        if (sessionUtils.sessionTimeouted()) {
            window.location.href = pageInfo.SESSION_TIMEOUT.path;
            return;
        }

        // IDトークンの期限切れが5分前だった場合、IDトークンを再発行させる
        await refreshIdToken();

        // IDトークン取得する用
        const data = await Auth.currentSession();

        // Loadingの表示
        if (!loadingIndicator) {
            enableLoadingDOM(loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM);
        }
        loadingIndicator = true;

        // POSTリクエストする
        const res = await API.post(apiName, path, {
            headers: {
                "x-id-token": data.getIdToken().getJwtToken(),
                ...headers
            },
            response: true,
            body: {
                ...body
            },
        });

        // セッションタイムアウトの更新
        sessionUtils.setNextSessionTimeout();

        // Loadingを消す
        disableLoadingDOM(loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM);
        loadingIndicator = false;
        // callbackで指定された処理を実行
        return callback(res);

    } catch (error) {
        // デフォルト（errorHandleがundefined）として、エラーメッセージ付きのダイアログを出すだけのハンドラを定義
        const handler = errorHandle !== undefined ? errorHandle : () => { alert(ERROR_MESSAGE.SERVER_ERROR); };

        // Loadingを消す
        disableLoadingDOM(loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM);
        loadingIndicator = false;

        // HTTPステータスがエラーの場合もcatchできる（例：404、500等
        return handler(error);
    }
};

export const put = async (path, callback, errorHandle, body, headers = {}, apiName = AWS.ENDPOINT.REST_API_MOCK.NAME) => {

    // LoadingDOMの初期設定
    const { loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM } = setupLoadingDOM();

    try {
        // セッションタイムアウトしている場合、セッションタイムアウト画面へ遷移させる
        if (sessionUtils.sessionTimeouted()) {
            window.location.href = pageInfo.SESSION_TIMEOUT.path;
            return;
        }

        // IDトークンの期限切れが5分前だった場合、IDトークンを再発行させる
        await refreshIdToken();

        // IDトークン取得する用
        const data = await Auth.currentSession();

        // Loadingの表示
        if (!loadingIndicator) {
            enableLoadingDOM(loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM);
        }
        loadingIndicator = true;

        // GETリクエストする
        const res = await API.put(apiName, path, {
            headers: {
                "x-id-token": data.getIdToken().getJwtToken(),
                ...headers
            },
            response: true,
            body: {
                ...body
            },
        });

        // セッションタイムアウトの更新
        sessionUtils.setNextSessionTimeout();

        // Loadingを消す
        disableLoadingDOM(loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM);
        loadingIndicator = false;

        // callbackで指定された処理を実行
        return callback(res);

    } catch (error) {
        // デフォルト（errorHandleがundefined）として、エラーメッセージ付きのダイアログを出すだけのハンドラを定義
        const handler = errorHandle !== undefined ? errorHandle : () => { alert(ERROR_MESSAGE.SERVER_ERROR); };

        // Loadingを消す
        disableLoadingDOM(loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM);
        loadingIndicator = false;

        // HTTPステータスがエラーの場合もcatchできる（例：404、500等
        return handler(error);
    }
};

export const del = async (path, callback, errorHandle, headers = {}, apiName = AWS.ENDPOINT.REST_API_MOCK.NAME) => {

    // LoadingDOMの初期設定
    const { loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM } = setupLoadingDOM();

    try {
        // セッションタイムアウトしている場合、セッションタイムアウト画面へ遷移させる
        if (sessionUtils.sessionTimeouted()) {
            window.location.href = pageInfo.SESSION_TIMEOUT.path;
            return;
        }

        // IDトークンの期限切れが5分前だった場合、IDトークンを再発行させる
        await refreshIdToken();

        // IDトークン取得する用
        const data = await Auth.currentSession();

        // Loadingの表示
        if (!loadingIndicator) {
            enableLoadingDOM(loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM);
        }
        loadingIndicator = true;

        // GETリクエストする
        const res = await API.del(apiName, path, {
            headers: {
                "x-id-token": data.getIdToken().getJwtToken(),
                ...headers
            },
            response: true
        });

        // セッションタイムアウトの更新
        sessionUtils.setNextSessionTimeout();

        // Loadingを消す
        disableLoadingDOM(loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM);
        loadingIndicator = false;

        // callbackで指定された処理を実行
        return callback(res);

    } catch (error) {
        // デフォルト（errorHandleがundefined）として、エラーメッセージ付きのダイアログを出すだけのハンドラを定義
        const handler = errorHandle !== undefined ? errorHandle : () => { alert(ERROR_MESSAGE.SERVER_ERROR); };

        // Loadingを消す
        disableLoadingDOM(loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM);
        loadingIndicator = false;

        // HTTPステータスがエラーの場合もcatchできる（例：404、500等
        return handler(error);
    }
};

/**
 * IDトークンの期限切れが5分前だった場合、IDトークンを再発行させる
 * ※
 * 期限が切れている場合、自動的にIDトークンは再発行されるが、
 * "FE側ではギリギリ期限が切れてないけど、APIに着弾した時に期限が切れている"
 * という状況がありえるのでその対策
 */
const refreshIdToken = async () => {
    // IDトークンの有効期限取得（UNIX秒）
    const limit = (await Auth.currentSession()).getIdToken().getExpiration();
    // 現在時刻取得（UNIXミリ秒）
    const now = (new Date()).getTime();
    // 期限切れ5分前の場合、IDトークン取り直し
    if (now >= (limit - 60 * 5) * 1000) {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        await cognitoUser.refreshSession(currentSession.refreshToken, () => { });
    }
};

export const setupLoadingDOM = () => {
    // Loadingの画像は→から拝借する https://icons8.com/preloaders/
    // ※SVG, Transparent background にチェックを入れる

    // Loadingの表示中のユーザー操作を非活性にする設定
    const backgroundModalDOM = document.createElement("div");
    backgroundModalDOM.className = "l-modal_overlay is-active";

    // Loadingの表示の背景色を設定
    const backgroundModalChild = document.createElement("div");
    backgroundModalChild.id = "background-child";
    backgroundModalChild.className = "modal_overlay_loading";

    backgroundModalDOM.appendChild(backgroundModalChild);

    // Loadingの表示の回転画像を追加
    const loadingImgDOM = document.createElement("img");
    loadingImgDOM.src = "/img/loading.svg";
    loadingImgDOM.style.position = "fixed";
    loadingImgDOM.style.zIndex = "100";
    loadingImgDOM.style.top = window.innerHeight / 2 - 40 + "px"; // -40はLoading画像サイズの半分ずらし
    loadingImgDOM.style.left = window.innerWidth / 2 - 40 + "px"; // -40はLoading画像サイズの半分ずらし

    // Loadingの表示の外枠を追加
    const loadingFrameDOM = document.createElement("img");
    loadingFrameDOM.src = "/img/loading_frame.png";
    loadingFrameDOM.style.position = "fixed";
    loadingFrameDOM.style.zIndex = "99";
    loadingFrameDOM.style.top = window.innerHeight / 2 - 98 + "px"; // -98はLoading画像サイズの半分ずらし
    loadingFrameDOM.style.left = window.innerWidth / 2 - 229 + "px"; // -229はLoading画像サイズの半分ずらし

    const bodyDOM = document.querySelector("body");


    return { loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM };
};

export const enableLoadingDOM = (loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM) => {
    bodyDOM.appendChild(loadingImgDOM);
    bodyDOM.appendChild(loadingFrameDOM);
    bodyDOM.appendChild(backgroundModalDOM);
    return { loadingImgDOM, loadingFrameDOM, bodyDOM, backgroundModalDOM };
};

export const disableLoadingDOM = (loadingImgDOM, loadingFrameDOM, bodyDOM, background_modalDOM) => {
    try {
        bodyDOM.removeChild(loadingImgDOM);
        bodyDOM.removeChild(loadingFrameDOM);
        bodyDOM.removeChild(background_modalDOM);
    } catch (error) {
        // removeChild()対象となるLoadingの表示が存在しないエラーをスルーする
        // Nodeの情報から検索取得(document.getElementById()やdocument.getElementById())をして判定する方式を試みたが、
        // 取得結果と実際の挙動がかみ合わなかった。そのため現状は、この方式で実装。   
    }
};