import { EMPLOYEE_POPUP as EP, GUARANTEE_ASSOCIATION_POPUP as GA, ORGANIZATION_POPUP as OP } from "constants/contractEditPageInfo";
import React from "react";
import Validation from "util/validate";
import InputCheckbox from "../../input/inputCheckbox";
import InputDate from "../../input/inputDate";
import InputEmployee from "../../input/inputEmployee";
import InputGua from "../../input/inputGua";
import Input from "../../input/inputNormal";
import InputTextOrg from "../../input/inputTextOrg";
import InputTextRemark from "../../input/inputTextRemark";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 自社情報 */
export default class Block4 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020104",
        };
        // アップロードするファイル名// 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        //JYU00011の入力上限判定
        const JYU00011LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00011_${this.props.displayTypeID}`, this.props.pageState.JYU00011);
        //JYU00016の入力上限判定
        const JYU00016LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00016_${this.props.displayTypeID}`, this.props.pageState.JYU00016);
        //JYU00017の入力上限判定
        const JYU00017LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00017_${this.props.displayTypeID}`, this.props.pageState.JYU00017);
        //JYU00023の入力上限判定
        const JYU00023LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00023_${this.props.displayTypeID}`, this.props.pageState.JYU00023);
        //JYU00022の入力上限判定
        const JYU00022LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00022_${this.props.displayTypeID}`, this.props.pageState.JYU00022);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title is-emphasis">自社情報</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis">取引態様</td>
                            </tr>
                            <tr>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00003" name="JYU00003" checked={this.props.pageState.JYU00003} {...params} />
                                                <span className="checkBox-text">売買</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00004" name="JYU00004" checked={this.props.pageState.JYU00004} {...params} />
                                                <span className="checkBox-text">交換</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00005" name="JYU00005" checked={this.props.pageState.JYU00005} {...params} />
                                                <span className="checkBox-text">媒介</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00006" name="JYU00006" checked={this.props.pageState.JYU00006} {...params} />
                                                <span className="checkBox-text">代理</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00007" name="JYU00007" checked={this.props.pageState.JYU00007} {...params} />
                                                <span className="checkBox-text">売主</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="4">宅地建物取引業者</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">免許証番号</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00009" name="JYU00009" value={this.props.pageState.JYU00009} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">免許年月日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_JYU00010" name="JYU00010" value={this.props.pageState.JYU00010} {...params} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">主たる事務所<br />の所在地</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope disableReturn id="ID_JYU00011" name="JYU00011" value={this.props.pageState.JYU00011} placeholder={JYU00011LimitErrorMessage.inputLimitGuide} {...params} height="h92" />
                                        <p className="LengthOver-error">{JYU00011LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">商号</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00013" name="JYU00013" value={this.props.pageState.JYU00013} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">代表者氏名</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00014" name="JYU00014" value={this.props.pageState.JYU00014} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">従たる事務所</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope disableReturn id="ID_JYU00016" name="JYU00016" value={this.props.pageState.JYU00016} placeholder={JYU00016LimitErrorMessage.inputLimitGuide} {...params} height="h84" />
                                        <p className="LengthOver-error">{JYU00016LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">部署名</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope disableReturn id="ID_JYU00017" name="JYU00017" value={this.props.pageState.JYU00017} placeholder={JYU00017LimitErrorMessage.inputLimitGuide} {...params} height="h84" />
                                        <p className="LengthOver-error">{JYU00017LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">責任者<br />（役職・氏名）</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00019" name="JYU00019" value={this.props.pageState.JYU00019} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="4">説明をする宅地建物取引士</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">登録番号</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputEmployee id="ID_JYU00020" name="JYU00020" value={this.props.pageState.JYU00020} {...params} from={EP.EMP00006} />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">氏名</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputEmployee id="ID_JYU00021" name="JYU00021" value={this.props.pageState.JYU00021} {...params} from={EP.EMP00001} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">業務に従事<br />する事務所名</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextOrg id="ID_JYU00023" name="JYU00023" value={this.props.pageState.JYU00023} placeholder={JYU00023LimitErrorMessage.inputLimitGuide} {...params} height="h84" from={OP.SIT00002} />
                                        <p className="LengthOver-error">{JYU00023LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住所</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextOrg id="ID_JYU00022" name="JYU00022" value={this.props.pageState.JYU00022} placeholder={JYU00022LimitErrorMessage.inputLimitGuide} {...params} height="h92" from={OP.SIT00003} />
                                        <p className="LengthOver-error">{JYU00022LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">電話番号</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00025" name="JYU00025" value={this.props.pageState.JYU00025} {...params} length="w135" from={OP.SIT00003} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="4">供託所等に関する説明</td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="4">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00026" name="JYU00026" checked={this.props.pageState.JYU00026} {...params} />
                                        <span className="checkBox-text">（1）供託所に営業保証金を供託</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">供託所</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00027" name="JYU00027" value={this.props.pageState.JYU00027} {...params} from={GA.HOS00001} />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在地</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00028" name="JYU00028" value={this.props.pageState.JYU00028} {...params} from={GA.HOS00002} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="4">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00029" name="JYU00029" checked={this.props.pageState.JYU00029} {...params} />
                                        <span className="checkBox-text">（2）宅地建物取引業保証協会の社員</span>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240 dataTable-title__center" rowSpan="2">宅地建物取引業保証<br />協会の名 称・住所</th>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">保証協会<br />の名称</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00030" name="JYU00030" value={this.props.pageState.JYU00030} {...params} from={GA.HOS00001} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">保証協会<br />の所在地</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00031" name="JYU00031" value={this.props.pageState.JYU00031} {...params} from={GA.HOS00002} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240 dataTable-title__center" rowSpan="3">宅地建物取引業保証<br />保証協会の<br />事務所・所在地</th>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">保証協会<br />の名称</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU01425" name="JYU01425" value={this.props.pageState.JYU01425} {...params} from={GA.HOS00001} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">事務所<br />の名称</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00032" name="JYU00032" value={this.props.pageState.JYU00032} {...params} from={GA.HOS00003} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">事務所<br />の所在地</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00033" name="JYU00033" value={this.props.pageState.JYU00033} {...params} from={GA.HOS00004} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240 dataTable-title__center">弁済業務保証金<br />の供託所名称</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00035" name="JYU00035" value={this.props.pageState.JYU00035} {...params} from={GA.HOS00001} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240 dataTable-title__center">弁済業務保証金<br />の供託所所在地</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00036" name="JYU00036" value={this.props.pageState.JYU00036} {...params} from={GA.HOS00002} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}