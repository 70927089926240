import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import { Era } from "components/page/user/contract/edit/input/inputDate";
import Input from "components/page/user/contract/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";

/** 種類 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030201",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">売買代金および支払い方法等</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">売買代金</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">消費税等相当額</div>
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.props.pageState.TOR00009)}円含む</div>
                                        <div className="dataTableUnit-text">合計</div>
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.props.pageState.TOR00010)}</div>
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">手付金</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.props.pageState.TOR00011)}</div>
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" rowSpan="2">内金</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">第1回</div>
                                        <div className="dataTableUnit-text">{this.props.pageState.TOR00116}</div>
                                        <Era value={this.props.pageState.TOR00116} {...params} />
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.props.pageState.TOR00117)}</div>
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">第2回</div>
                                        <div className="dataTableUnit-text">{this.props.pageState.TOR00264}</div>
                                        <Era value={this.props.pageState.TOR00264} {...params} />
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.props.pageState.TOR00265)}</div>
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">残代金</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">{this.props.pageState.TOR00118}</div>
                                        <Era value={this.props.pageState.TOR00118} {...params} />
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.props.pageState.TOR00119)}</div>
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">引渡日</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_FUD00225" name="FUD00225" checked={this.props.pageState.FUD00225} {...params} />
                                                        <span className="checkBox-text">1.売買代金全額受領日</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_FUD00226" name="FUD00226" checked={this.props.pageState.FUD00226} {...params} />
                                                        <span className="checkBox-text">2.</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <Input id="ID_FUD00227" name="FUD00227" value={this.props.pageState.FUD00227} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">手付解除期日</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">{this.props.pageState.JYU01102}</div>
                                        <Era oneline value={this.props.pageState.JYU01102} {...params} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">違約金の額</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox disabled id="ID_JYU01124" name="JYU01124" checked={this.props.pageState.JYU01124} {...params} />
                                                <div className="checkBox-text dataTableUnit-text">1.手付金の額</div>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox disabled id="ID_JYU01125" name="JYU01125" checked={this.props.pageState.JYU01125} {...params} />
                                                <div className="checkBox-text dataTableUnit">
                                                    <div className="dataTableUnit-text">2.売買代金の</div>
                                                    <div className="dataTableUnit-text">{this.props.pageState.JYU01126}%相当額</div>
                                                </div>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox disabled id="ID_JYU01127" name="JYU01127" checked={this.props.pageState.JYU01127} {...params} />
                                                <div className="checkBox-text dataTableUnit">
                                                    <div className="dataTableUnit-text">3.</div>
                                                    <div className="dataTableUnit-text">{this.amountCommaEdit(this.props.pageState.JYU01128)}円</div>
                                                </div>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis_single" colSpan="5">融資</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">融資利用の<br />有無</th>
                                <td className="dataTable-body" colSpan="4">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox disabled id="ID_JYU01110" name="JYU01110" checked={this.props.pageState.JYU01110} {...params} />
                                                <span className="checkBox-text">1.有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox disabled id="ID_JYU01275" name="JYU01275" checked={this.props.pageState.JYU01275} {...params} />
                                                <span className="checkBox-text">2.無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">申込先</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="2">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">{this.props.pageState.TOR00031}</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">融資金額</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_FUD00251" name="FUD00251" value={this.props.pageState.FUD00251} {...params} length="w135" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">融資承認<br />取得期日</th>
                                <td className="dataTable-body dataTable-body" colSpan="4">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">{this.props.pageState.TOR00032}</div>
                                        <Era oneline value={this.props.pageState.TOR00032} {...params} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">申込先</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="2">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">{this.props.pageState.TOR00268}</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">融資金額</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_FUD00254" name="FUD00254" value={this.props.pageState.FUD00254} {...params} length="w135" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">融資承認<br />取得期日</th>
                                <td className="dataTable-body dataTable-body" colSpan="4">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">{this.props.pageState.TOR00269}</div>
                                        <Era oneline value={this.props.pageState.TOR00269} {...params} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w320" colSpan="3">融資利用の特約に基づく契約解除期日</th>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">{this.props.pageState.TOR00033}</div>
                                        <Era oneline value={this.props.pageState.TOR00033} {...params} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w320" colSpan="3">賃借権譲渡承諾の特約に基づく契約解除期日</th>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">{this.props.pageState.JYU01118}</div>
                                        <Era oneline value={this.props.pageState.JYU01118} {...params} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}