export const INPUT_LIMITS = {
    //最大入力可能文字数のエラーメッセージ、プレースホルダー表示用
    //　項目ID_帳票区分：[入力可能行数, 1行あたりの入力可能文字数]

    //取引成立台帳
    "TOR00199": [7, 32], //備考

    //重要事項説明書‗土地
    "JYU00011_01": [3, 22],    //表紙 自社情報＞宅地建物取引業者＞主たる事務所＞所在地
    "JYU00016_01": [2, 22],    //表紙 自社情報＞宅地建物取引業者＞従たる事務所＞事業所名
    "JYU00017_01": [2, 22],    //表紙 自社情報＞宅地建物取引業者＞従たる事務所＞部署名
    "JYU00023_01": [2, 22],    //表紙 自社情報＞説明をする宅地建物取引士＞業務に従事する事務所＞名称
    "JYU00022_01": [3, 22],    //表紙 自社情報＞説明をする宅地建物取引士＞業務に従事する事務所＞住所
    "JYU00054_01": [3, 22],    //表紙 他社情報＞宅地建物取引業者＞主たる事務所＞所在地
    "JYU00059_01": [2, 22],    //表紙 他社情報＞宅地建物取引業者＞従たる事務所＞事業所名
    "JYU00060_01": [2, 22],    //表紙 他社情報＞宅地建物取引業者＞従たる事務所＞部署名
    "JYU00066_01": [2, 22],    //表紙 他社情報＞説明をする宅地建物取引士＞業務に従事する事務所＞名称
    "JYU00065_01": [3, 22],    //表紙 他社情報＞説明をする宅地建物取引士＞業務に従事する事務所＞住所
    "JYU00296_01": [22, 70],   //Ａ 不動産の表示＞1. 土地>備考
    "JYU00327_01": [3, 70],    //Ｂ 売主の表示と占有に関する事項＞1. 売主>備考
    "JYU00331_01": [3, 62],    //Ｂ 売主の表示と占有に関する事項＞2. 売買契約締結時の占有に関する事項　第三者による占有>占有に関する権利
    "JYU00352_01": [5, 56],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞1. 登記記録に記録された事項>(2) 建物>権利部（甲区）>所有権にかかる権利に関する事項
    "JYU00359_01": [8, 56],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞1. 登記記録に記録された事項>(2) 建物>権利部（乙区）>所有権以外の権利に関する事項
    "JYU00371_01": [14, 56],   //Ⅰ 取引の対象となる宅地に直接関係する事項＞1. 登記記録に記録された事項>(1) 土地>権利部（甲区）>所有権にかかる権利に関する事項
    "JYU00375_01": [21, 56],   //Ⅰ 取引の対象となる宅地に直接関係する事項＞1. 登記記録に記録された事項>(1) 土地>権利部（乙区）>所有権以外の権利に関する事項
    "JYU00412_01": [4, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞2. 都市計画法、建築基準法等の法令に基づく制限の概要>(1) 都市計画法に基づく制限>備考
    "JYU00513_01": [8, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞2. 都市計画法、建築基準法等の法令に基づく制限の概要>(2) 建築基準法に基づく制限>④ 建築物の延べ面積の敷地面積に対する割合の限度(容積率)>備考
    "JYU00558_01": [7, 29],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞2. 都市計画法、建築基準法等の法令に基づく制限の概要>(2) 建築基準法に基づく制限>⑤ 敷地等と道路との関係>備考
    "JYU00587_01": [7, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞2. 都市計画法、建築基準法等の法令に基づく制限の概要>(2) 建築基準法に基づく制限>⑬ 地方公共団体の条例等による制限>備考
    "JYU00693_01": [11, 68],   //Ⅰ 取引の対象となる宅地に直接関係する事項＞2. 都市計画法、建築基準法等の法令に基づく制限の概要>(3) 都市計画法、建築基準法以外の法令に基づく制限>制限の概要
    "JYU00701_01": [3, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞3. 私道に関する負担等に関する事項>(1) 対象不動産に含まれる私道に関する負担の内容>備考
    "JYU00705_01": [2, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞3. 私道に関する負担等に関する事項>(2) 対象不動産に含まれない私道に関する事項>利用条件等
    "JYU00785_01": [11, 70],   //Ⅰ 取引の対象となる宅地に直接関係する事項＞4. 飲用水・電気・ガスの供給施設および排水施設の整備状況>備考
    "JYU01100_01": [3, 70],    //Ⅱ 取引条件に関する事項＞1. 売買代金および交換差金以外に売主・買主間で授受される金銭の額>備考
    "JYU01121_01": [3, 70],    //Ⅱ 取引条件に関する事項＞2. 契約の解除等に関する事項>備考
    "JYU01222_01": [41, 70],   //Ⅲ その他重要な事項＞備考
    "JYU01457_01": [4, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞10. その他>備考
    "JYU01478_01": [7, 70],    //Ⅱ 取引条件に関する事項＞7.契約不適合責任>備考
    "JYU01554_01": [3, 56],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞9. 水防法施行規則の規定に基づく水害ハザードマップにおける当該宅地建物の所在地>水害ハザードマップにおける宅地建物の所在地

    //重要事項説明書建物_土地建物
    "JYU00011_02": [3, 22],    //表紙 自社情報＞宅地建物取引業者＞主たる事務所＞所在地
    "JYU00016_02": [2, 22],    //表紙 自社情報＞宅地建物取引業者＞従たる事務所＞事業所名
    "JYU00017_02": [2, 22],    //表紙 自社情報＞宅地建物取引業者＞従たる事務所＞部署名
    "JYU00023_02": [2, 22],    //表紙 自社情報＞説明をする宅地建物取引士＞業務に従事する事務所＞名称
    "JYU00022_02": [3, 22],    //表紙 自社情報＞説明をする宅地建物取引士＞業務に従事する事務所＞住所
    "JYU00054_02": [3, 22],    //表紙 他社情報＞宅地建物取引業者＞主たる事務所＞所在地
    "JYU00059_02": [2, 22],    //表紙 他社情報＞宅地建物取引業者＞従たる事務所＞事業所名
    "JYU00060_02": [2, 22],    //表紙 他社情報＞宅地建物取引業者＞従たる事務所＞部署名
    "JYU00066_02": [2, 22],    //表紙 他社情報＞説明をする宅地建物取引士＞業務に従事する事務所＞名称
    "JYU00065_02": [3, 22],    //表紙 他社情報＞説明をする宅地建物取引士＞業務に従事する事務所＞住所
    "JYU00296_02": [4, 70],    //Ａ 不動産の表示＞1. 土地>備考
    "JYU00322_02": [3, 70],    //Ａ 不動産の表示＞2. 建物>備考
    "JYU00327_02": [3, 70],    //Ｂ 売主の表示と占有に関する事項＞1. 売主>備考
    "JYU00331_02": [3, 62],    //Ｂ 売主の表示と占有に関する事項＞2. 売買契約締結時の占有に関する事項　第三者による占有>占有に関する権利
    "JYU00352_02": [5, 56],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞1. 登記記録に記録された事項>(2) 建物>権利部（甲区）>所有権にかかる権利に関する事項
    "JYU00359_02": [8, 56],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞1. 登記記録に記録された事項>(2) 建物>権利部（乙区）>所有権以外の権利に関する事項
    "JYU00371_02": [5, 56],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞1. 登記記録に記録された事項>(1) 土地>権利部（甲区）>所有権にかかる権利に関する事項
    "JYU00375_02": [8, 56],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞1. 登記記録に記録された事項>(1) 土地>権利部（乙区）>所有権以外の権利に関する事項
    "JYU00412_02": [5, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞2. 都市計画法、建築基準法等の法令に基づく制限の概要>(1) 都市計画法に基づく制限>備考
    "JYU00513_02": [8, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞2. 都市計画法、建築基準法等の法令に基づく制限の概要>(2) 建築基準法に基づく制限>④ 建築物の延べ面積の敷地面積に対する割合の限度(容積率)>備考
    "JYU00558_02": [7, 29],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞2. 都市計画法、建築基準法等の法令に基づく制限の概要>(2) 建築基準法に基づく制限>⑤ 敷地等と道路との関係>備考
    "JYU00587_02": [7, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞2. 都市計画法、建築基準法等の法令に基づく制限の概要>(2) 建築基準法に基づく制限>⑬ 地方公共団体の条例等による制限>備考
    "JYU00693_02": [11, 68],   //Ⅰ 取引の対象となる宅地に直接関係する事項＞2. 都市計画法、建築基準法等の法令に基づく制限の概要>(3) 都市計画法、建築基準法以外の法令に基づく制限>制限の概要
    "JYU00701_02": [5, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞3. 私道に関する負担等に関する事項>(1) 対象不動産に含まれる私道に関する負担の内容>備考
    "JYU00705_02": [4, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞3. 私道に関する負担等に関する事項>(2) 対象不動産に含まれない私道に関する事項>利用条件等
    "JYU00785_02": [6, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞4. 飲用水・電気・ガスの供給施設および排水施設の整備状況>備考
    "JYU00982_02": [3, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞6. 建物状況調査の結果の概要>備考
    "JYU01013_02": [4, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞7. 建物の建築および維持保全の状況に関する書類の保存の状況・耐震診断の内容>備考
    "JYU01020_02": [4, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞8. 建築確認済証等の発行年月日・番号>備考
    "JYU01100_02": [7, 70],    //Ⅱ 取引条件に関する事項＞1. 売買代金および交換差金以外に売主・買主間で授受される金銭の額>備考
    "JYU01121_02": [6, 70],    //Ⅱ 取引条件に関する事項＞2. 契約の解除等に関する事項>備考
    "JYU01222_02": [41, 70],   //Ⅲ その他重要な事項＞備考
    "JYU01457_02": [4, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞10. その他>備考
    "JYU01478_02": [7, 70],    //Ⅱ 取引条件に関する事項＞7.契約不適合責任（瑕疵担保責任）の履行に関する措置の概要>備考
    "JYU01547_02": [11, 70],   //借地説明書＞5.その他
    "JYU01767_02": [11, 70],   //借地説明書＞6.定期借地権の主な内容
    "JYU01554_02": [3, 56],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞14. 水防法施行規則の規定に基づく水害ハザードマップにおける当該宅地建物の所在地>水害ハザードマップにおける宅地建物の所在地

    //重要事項説明書_区分所有
    "JYU00011_03": [3, 22],    //表紙 自社情報＞宅地建物取引業者＞主たる事務所＞所在地
    "JYU00016_03": [2, 22],    //表紙 自社情報＞宅地建物取引業者＞従たる事務所＞事業所名
    "JYU00017_03": [2, 22],    //表紙 自社情報＞宅地建物取引業者＞従たる事務所＞部署名
    "JYU00023_03": [2, 22],    //表紙 自社情報＞説明をする宅地建物取引士＞業務に従事する事務所＞名称
    "JYU00022_03": [3, 22],    //表紙 自社情報＞説明をする宅地建物取引士＞業務に従事する事務所＞住所
    "JYU00054_03": [3, 22],    //表紙 他社情報＞宅地建物取引業者＞主たる事務所＞所在地
    "JYU00059_03": [2, 22],    //表紙 他社情報＞宅地建物取引業者＞従たる事務所＞事業所名
    "JYU00060_03": [2, 22],    //表紙 他社情報＞宅地建物取引業者＞従たる事務所＞部署名
    "JYU00066_03": [2, 22],    //表紙 他社情報＞説明をする宅地建物取引士＞業務に従事する事務所＞名称
    "JYU00065_03": [3, 22],    //表紙 他社情報＞説明をする宅地建物取引士＞業務に従事する事務所＞住所
    "JYU00147_03": [6, 70],    //Ａ 不動産の表示＞1. 区分所有建物>備考
    "JYU00195_03": [2, 60],    //Ａ 不動産の表示＞2. 敷地権の目的たる土地>管理規約に規約敷地の定め
    "JYU00196_03": [4, 70],    //Ａ 不動産の表示＞2. 敷地権の目的たる土地>備考
    "JYU00296_03": [3, 70],    //Ａ 不動産の表示＞2. 敷地権の目的たる土地>備考
    "JYU00327_03": [3, 70],    //Ｂ 売主の表示と占有に関する事項＞1. 売主>備考
    "JYU00331_03": [3, 62],    //Ｂ 売主の表示と占有に関する事項＞2. 売買契約締結時の占有に関する事項　第三者による占有>占有に関する権利
    "JYU00352_03": [5, 56],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞1. 登記記録に記録された事項>(1) 土地>権利部（甲区）>所有権にかかる権利に関する事項
    "JYU00359_03": [8, 56],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞1. 登記記録に記録された事項>(1) 土地>権利部（乙区）>所有権以外の権利に関する事項
    "JYU00371_03": [5, 56],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞1. 登記記録に記録された事項>(2) 建物>権利部（甲区）>所有権にかかる権利に関する事項
    "JYU00375_03": [8, 56],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞1. 登記記録に記録された事項>(2) 建物>権利部（乙区）>所有権以外の権利に関する事項
    "JYU00412_03": [5, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞2. 都市計画法、建築基準法等の法令に基づく制限の概要>(1) 都市計画法に基づく制限>備考
    "JYU00513_03": [13, 70],   //Ⅰ 取引の対象となる宅地に直接関係する事項＞2. 都市計画法、建築基準法等の法令に基づく制限の概要>(2) 建築基準法に基づく制限>④ 建築物の延べ面積の敷地面積に対する割合の限度(容積率)>備考
    "JYU00558_03": [5, 36],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞2. 都市計画法、建築基準法等の法令に基づく制限の概要>(2) 建築基準法に基づく制限>⑤ 敷地等と道路との関係>備考
    "JYU00587_03": [13, 70],   //Ⅰ 取引の対象となる宅地に直接関係する事項＞2. 都市計画法、建築基準法等の法令に基づく制限の概要>(2) 建築基準法に基づく制限>⑬ 地方公共団体の条例等による制限>備考
    "JYU00693_03": [11, 68],   //Ⅰ 取引の対象となる宅地に直接関係する事項＞2. 都市計画法、建築基準法等の法令に基づく制限の概要>(3) 都市計画法、建築基準法以外の法令に基づく制限>制限の概要
    "JYU00701_03": [3, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞3. 私道に関する負担等に関する事項>(1) 対象不動産に含まれる私道に関する負担の内容>備考
    "JYU00705_03": [2, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞3. 私道に関する負担等に関する事項>(2) 対象不動産に含まれない私道に関する事項>利用条件等
    "JYU00785_03": [3, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞4. 飲用水・電気・ガスの供給施設および排水施設の整備状況>備考
    "JYU00804_03": [3, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞6. 建物状況調査の結果の概要>(1) 敷地に関する権利の種類および内容>備考
    "JYU00813_03": [4, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞6. 建物状況調査の結果の概要>(2) 共用部分に関する規約等の定め>備考
    "JYU00816_03": [3, 43],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞6. 建物状況調査の結果の概要>(3) 専有部分の用途その他の利用の制限に関する規約等の定め>制限の内容
    "JYU00820_03": [3, 43],    // 一棟の建物・敷地に関する権利・管理・使用に関する事項_1 > 専有部分の用途その他の制限に関する規約等の定め > ペットの飼育制限 > 制限の内容
    "JYU00824_03": [3, 43],    // 一棟の建物・敷地に関する権利・管理・使用に関する事項_1 > 専有部分の用途その他の制限に関する規約等の定め > フローリングの制限 > 制限の内容
    "JYU00828_03": [3, 43],    // 一棟の建物・敷地に関する権利・管理・使用に関する事項_1 > 専有部分の用途その他の制限に関する規約等の定め > 楽器の使用制限 > 制限の内容
    "JYU00833_03": [4, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞6. 建物状況調査の結果の概要>(3) 専有部分の用途その他の利用の制限に関する規約等の定め>備考
    "JYU00918_03": [7, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞6. 建物状況調査の結果の概要>(4) 専用使用権に関する規約等の定め (別添管理規約>①一棟の建物およびその敷地の専用使用権について>備考
    "JYU00934_03": [5, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞6. 建物状況調査の結果の概要>(4) 専用使用権に関する規約等の定め (別添管理規約>②対象不動産に付随する専用使用権について>備考
    "JYU00979_03": [10, 70],   //Ⅰ 取引の対象となる宅地に直接関係する事項＞6. 建物状況調査の結果の概要>(10) その他>備考
    "JYU00982_03": [2, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞7. 建物状況調査の結果の概要>備考
    "JYU01013_03": [7, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞8. 建物の建築および維持保全の状況に関する書類の保存の状況・耐震診断の内容>備考
    "JYU01020_03": [4, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞9. 建築確認済証等の発行年月日・番号>備考
    "JYU01100_03": [2, 70],    //Ⅱ 取引条件に関する事項＞1. 売買代金および交換差金以外に売主・買主間で授受される金銭の>備考
    "JYU01121_03": [3, 70],    //Ⅱ 取引条件に関する事項＞2. 契約の解除等に関する事項>備考
    "JYU01222_03": [42, 70],   //Ⅲ その他重要な事項＞備考
    "JYU01457_03": [4, 70],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞10. その他>備考
    "JYU01478_03": [7, 70],    //Ⅱ 取引条件に関する事項＞7.契約不適合責任（瑕疵担保責任）の履行に関する措置の概要>備考
    "JYU01547_03": [11, 70],   //借地説明書＞5.その他
    "JYU01554_03": [3, 56],    //Ⅰ 取引の対象となる宅地に直接関係する事項＞15. 水防法施行規則の規定に基づく水害ハザードマップにおける当該宅地建物の所在地>水害ハザードマップにおける宅地建物の所在地

    //不動産売買契約書_土地
    "FUD00109_01": [26, 67],   //不動産の表示＞特記事項(土地)
    "FUD00202_01": [9, 67],    //不動産の表示＞特記事項(定位借地権)
    "FUD00333_01": [23, 70],   //特約＞
    "FUD00367_01": [6, 70],    // 契約不適合責任（瑕疵担保責任）の履行に関する措置＞備考

    //不動産売買契約書_土地建物
    "FUD00135_02": [19, 67],   //不動産の表示＞特記事項
    "FUD00202_02": [9, 67],    //不動産の表示＞特記事項(定期借地権)
    "FUD00333_02": [13, 70],   //特約＞
    "FUD00367_02": [6, 70],    //契約不適合責任（瑕疵担保責任）の履行に関する措置＞備考

    //不動産売買契約書_区分所有
    "FUD00202_03": [7, 67],    //不動産の表示＞特記事項(定期借地権)
    "FUD00333_03": [15, 70],   //特約＞
    "FUD00336_03": [13, 67],   //不動産の表示＞特記事項
    "FUD00367_03": [6, 70],    // 契約不適合責任（瑕疵担保責任）の履行に関する措置＞備考
};