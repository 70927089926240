import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";
import Input from "../../input/inputNormal";

/** 手付金等の保全措置の概要 */
export default class Block4 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021204",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 斜線対応の設定
        let DomDiagonalLine = undefined;
        if (this.onloadFlg && this.props.pageState.JYU01441) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_JYU01441");
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU01441:AR_JYU01441-1,AR_JYU01441-2,AR_JYU01441-3,AR_JYU01441-4,AR_JYU01441-5,AR_JYU01441-6")}>
                <div className="dataList-header">
                    <div className="dataList-header-title">手付金等の保全措置の概要(宅地建物取引業者が自ら売主の場合)</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td colSpan="2" className="dataTable-body is-emphasis">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01132" name="JYU01132" checked={this.props.pageState.JYU01132} {...params} />
                                                        <span className="checkBox-text">該当する</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01441" name="JYU01441" checked={this.props.pageState.JYU01441} {...params} />
                                                        <span className="checkBox-text">該当しない　※入力不要の場合は、斜線を引きます。</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {DomDiagonalLine}
                            <tr id="AR_JYU01441-1">
                                <td colSpan="2" className="dataTable-body is-emphasis">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-input">
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01133" name="JYU01133" checked={this.props.pageState.JYU01133} {...params} />
                                                <span className="checkBox-text">1.講じない</span>
                                            </label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr id="AR_JYU01441-2">
                                <th className="dataTable-title dataTable-title__w240">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01134" name="JYU01134" checked={this.props.pageState.JYU01134} {...params} />
                                        <span className="checkBox-text">1.未完成物件</span>
                                    </label>
                                </th>
                                <td className="dataTable-body">
                                    <p>宅地建物取引業法第41条第1項に基づき、手付金等 は売買代金の100分の5以下かつ1,000万円以下で あるため保全措置は講じません。</p>
                                </td>
                            </tr>
                            <tr id="AR_JYU01441-3">
                                <th className="dataTable-title dataTable-title__w240">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01135" name="JYU01135" checked={this.props.pageState.JYU01135} {...params} />
                                        <span className="checkBox-text">2.完成物件</span>
                                    </label>
                                </th>
                                <td className="dataTable-body">
                                    <p>宅地建物取引業法第41条の2第1項に基づき、手付 金等は売買代金の10分の1以下かつ1,000万円以下 であるため保全措置は講じません。</p>
                                </td>
                            </tr>
                            <tr id="AR_JYU01441-4">
                                <td colSpan="2" className="dataTable-body is-emphasis">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-input">
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01136" name="JYU01136" checked={this.props.pageState.JYU01136} {...params} />
                                                <span className="checkBox-text">2.講じる</span>
                                            </label>
                                        </div>
                                        <div className="dataTableUnit-text">（</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01137" name="JYU01137" checked={this.props.pageState.JYU01137} {...params} />
                                                        <span className="checkBox-text">1.未完成物件</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01138" name="JYU01138" checked={this.props.pageState.JYU01138} {...params} />
                                                        <span className="checkBox-text">2.完成物件</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </td>
                            </tr>
                            <tr id="AR_JYU01441-5">
                                <th className="dataTable-title dataTable-title__w240">保全方式</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01139" name="JYU01139" checked={this.props.pageState.JYU01139} {...params} />
                                                <span className="checkBox-text">1.保証委託契約</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01140" name="JYU01140" checked={this.props.pageState.JYU01140} {...params} />
                                                <span className="checkBox-text">2.保証保険契約</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01141" name="JYU01141" checked={this.props.pageState.JYU01141} {...params} />
                                                <span className="checkBox-text">3.手付金等寄託契約および質権設定契約</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01441-6">
                                <th className="dataTable-title dataTable-title__w240">保全機関</th>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01143" name="JYU01143" value={this.props.pageState.JYU01143} {...params} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};
