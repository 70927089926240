import { PageBase } from "components/page/user/contract/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";
import Block4 from "./block4";

class ImpPage11 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     * ※チェックボックスの場合、falseにする
     */
    getPageItems = () => {
        return {
            // 建物状況調査の結果の概要
            JYU00980: false, // 該当する
            JYU01282: false, // 該当しない
            JYU00981: false, // 建物状況調査の実施の有無　有
            JYU01283: false, // 建物状況調査の実施の有無　無
            JYU00982: undefined, // 建物状況調査の結果の概要

            // 建物の建築及び維持保全の状況に関する書類の保存の状況
            JYU00987: false, // 該当する
            JYU01284: false, // 該当しない
            JYU00991: false, // 検査済証　有
            JYU01285: false, // 検査済証　無
            JYU00988: false, // 確認の申請書及び添付図書並びに確認済証　有
            JYU01286: false, // 確認の申請書及び添付図書並びに確認済証　無
            JYU00994: false, // 増改築等　確認の申請及び添付図書並びに確認済証　有
            JYU01287: false, // 増改築等　確認の申請及び添付図書並びに確認済証　無
            JYU00995: false, // 増改築等　検査済証　有
            JYU01288: false, // 増改築等　検査済証　無
            JYU00996: false, // 建物状況調査を実施した住宅　建物状況調査結果報告書　有
            JYU01289: false, // 建物状況調査を実施した住宅　建物状況調査結果報告書　無
            JYU00997: false, // 建築基準法第１２条の規定による定期調査報告の対象　該当する
            JYU01290: false, // 建築基準法第１２条の規定による定期調査報告の対象　該当しない
            JYU00998: false, // 建築基準法第１２条の規定による定期調査報告の対象　定期調査報告書・定期検査報告書　有
            JYU01291: false, // 建築基準法第１２条の規定による定期調査報告の対象　定期調査報告書・定期検査報告書　無
            JYU00999: false, // 既存住宅性能評価を受けた住宅である場合　既存住宅性能評価書　有
            JYU01292: false, // 既存住宅性能評価を受けた住宅である場合　既存住宅性能評価書　無
            JYU01000: false, // 昭和５６年５月３１日以前に新築の工事に着手した住宅　該当する
            JYU01293: false, // 昭和５６年５月３１日以前に新築の工事に着手した住宅　該当しない
            JYU01001: false, // 昭和５６年５月３１日以前に新築の工事に着手した住宅　耐震等級の区分　 １以上（適）
            JYU01002: false, // 昭和５６年５月３１日以前に新築の工事に着手した住宅　耐震等級の区分　  ０（不適）
            JYU01003: false, // 昭和５６年５月３１日以前に新築の工事に着手した住宅　耐震診断結果報告書　有
            JYU01294: false, // 昭和５６年５月３１日以前に新築の工事に着手した住宅　耐震診断結果報告書　無
            JYU01004: false, // 昭和５６年５月３１日以前に新築の工事に着手した住宅　新耐震基準の適合性を証する記載　有
            JYU01295: false, // 昭和５６年５月３１日以前に新築の工事に着手した住宅　新耐震基準の適合性を証する記載　無
            JYU01005: false, // 昭和５６年５月３１日以前に新築の工事に着手した住宅　既存住宅売買瑕疵保険の付保証明書　有
            JYU01296: false, // 昭和５６年５月３１日以前に新築の工事に着手した住宅　既存住宅売買瑕疵保険の付保証明書　無
            JYU01006: false, // 昭和５６年５月３１日以前に新築の工事に着手した住宅　耐震基準適合証明書　有
            JYU01297: false, // 昭和５６年５月３１日以前に新築の工事に着手した住宅　耐震基準適合証明書　無
            JYU01007: undefined, // 昭和５６年５月３１日以前に新築の工事に着手した住宅　耐震診断結果報告書　その他　名称
            JYU01008: false, // 昭和５６年５月３１日以前に新築の工事に着手した住宅　耐震診断結果報告書　その他　有
            JYU01298: false, // 昭和５６年５月３１日以前に新築の工事に着手した住宅　耐震診断結果報告書　その他　無
            JYU01013: undefined, // 備考

            // 建築確認認証等の発行年月日・番号
            JYU01014: false, // 建築確認（新築時）　有
            JYU01015: undefined, // 建築確認（新築時）　日付	日付（年月日）
            JYU01016: undefined, // 建築確認（新築時）　条例番号
            JYU01017: false, // 検査済証　有
            JYU01018: undefined, // 検査済証　日付	日付（年月日）
            JYU01019: undefined, // 検査済証　条例番号
            JYU01020: undefined, // 備考

            // 性能住宅評価を受けた新築住宅の場合
            JYU01468: false, // 該当する
            JYU01469: false, // 該当しない
            JYU01470: false, // 設計住宅性能評価書　有
            JYU01471: false, // 設計住宅性能評価書　無
            JYU01472: false, // 建設住宅性能評価書　有
            JYU01473: false, // 建設住宅性能評価書　無
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 建物状況調査の結果の概要 */}
                    <li><Block2 {...params} /></li> {/** 建物の建築及び維持保全の状況に関する書類の保存の状況 */}
                    <li><Block3 {...params} /></li> {/** 建築確認認証等の発行年月日・番号 */}
                    <li><Block4 {...params} /></li> {/** 住宅性能評価を受けた新築住宅の場合 */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
            </React.Fragment>
        );
    }
}

export default withRouter(ImpPage11);