import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 建物の建築及び維持保全の状況に関する書類の保存の状況 */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021102",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 文字数制限定義
        const JYU01013LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU01013",
                this.props.pageState.JYU01013);

        // 斜線対応の設定
        let DomDiagonalLine = undefined;
        if (this.onloadFlg && this.props.pageState.JYU01284) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_JYU01284");
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU01284:AR_JYU01284-1,AR_JYU01284-2,AR_JYU01284-3,AR_JYU01284-4,AR_JYU01284-5,AR_JYU01284-6,AR_JYU01284-7,AR_JYU01284-8,AR_JYU01284-9,AR_JYU01284-10,AR_JYU01284-11,AR_JYU01284-12,AR_JYU01284-13,AR_JYU01284-14,AR_JYU01284-15,AR_JYU01284-16,AR_JYU01284-17,AR_JYU01284-18")}>
                <div className="dataList-header">
                    <div className="dataList-header-title">建物の建築および維持保全の状況に関する書類（原本）の保存の状況・耐震診断の内容</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-body is-emphasis" colSpan="4">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox l-checkBox--center">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00987" name="JYU00987" checked={this.props.pageState.JYU00987} {...params} />
                                                        <span className="checkBox-text">該当する</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01284" name="JYU01284" checked={this.props.pageState.JYU01284} {...params} />
                                                        <span className="checkBox-text">該当しないので、説明を省略します。　※チェックした場合は、斜線を引きます。</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {DomDiagonalLine}
                            <tr id="AR_JYU01284-1">
                                <th className="dataTable-title is-emphasis" colSpan="3">書類の種類等</th>
                                <th className="dataTable-title dataTable-title__w270 is-emphasis">保存の状況</th>
                            </tr>
                            <tr id="AR_JYU01284-2">
                                <th className="dataTable-title dataTable-title__left dataTable-title__w30" rowSpan="9">
                                    建物の建築および維持保全の状況
                                </th>
                                <th className="dataTable-title dataTable-title__left dataTable-title__w700" colSpan="2">①確認の申請書および添付図書（新築時のもの）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01667" name="JYU01667" checked={this.props.pageState.JYU01667} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01668" name="JYU01668" checked={this.props.pageState.JYU01668} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-3">
                                <th className="dataTable-title dataTable-title__left dataTable-title__w700" colSpan="2">②確認済証（新築時のもの）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01669" name="JYU01669" checked={this.props.pageState.JYU01669} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01670" name="JYU01670" checked={this.props.pageState.JYU01670} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-4">
                                <th className="dataTable-title dataTable-title__left dataTable-title__w700" colSpan="2">③検査済証（新築時のもの）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="JYU01757" name="JYU01757" checked={this.props.pageState.JYU01757} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01758" name="JYU01758" checked={this.props.pageState.JYU01758} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-5">
                                <th className="dataTable-title dataTable-title__left" rowSpan="3">
                                    <div className="dataTableUnit_vr dataTableUnit_vr__equallySpaced">
                                        <div className="dataTableUnit-text">増改築等を行った建物に</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01671" name="JYU01671" checked={this.props.pageState.JYU01671} {...params} />
                                                        <span className="checkBox-text">該当する</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01672" name="JYU01672" checked={this.props.pageState.JYU01672} {...params} />
                                                        <span className="checkBox-text">該当しない</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01673" name="JYU01673" checked={this.props.pageState.JYU01673} {...params} />
                                                        <span className="checkBox-text">該当するか否か不明</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <th className="dataTable-title dataTable-title__left dataTable-title__w340">④確認の申請書及び添付書類（増改築時のもの）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01674" name="JYU01674" checked={this.props.pageState.JYU01674} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01675" name="JYU01675" checked={this.props.pageState.JYU01675} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-6">
                                <th className="dataTable-title dataTable-title__left">⑤確認済証（増改築等時のもの）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01676" name="JYU01676" checked={this.props.pageState.JYU01676} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01677" name="JYU01677" checked={this.props.pageState.JYU01677} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-7">
                                <th className="dataTable-title dataTable-title__left">⑥検査済証（増改築等時のもの）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01678" name="JYU01678" checked={this.props.pageState.JYU01678} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01679" name="JYU01679" checked={this.props.pageState.JYU01679} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-8">
                                <th className="dataTable-title dataTable-title__left">
                                    <div className="dataTableUnit_vr dataTableUnit_vr__equallySpaced">
                                        <div className="dataTableUnit-text">※建物状況調査を実施した住宅に</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01680" name="JYU01680" checked={this.props.pageState.JYU01680} {...params} />
                                                        <span className="checkBox-text">該当する</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01681" name="JYU01681" checked={this.props.pageState.JYU01681} {...params} />
                                                        <span className="checkBox-text">該当しない</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01682" name="JYU01682" checked={this.props.pageState.JYU01682} {...params} />
                                                        <span className="checkBox-text">該当するか否か不明</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <th className="dataTable-title dataTable-title__left">⑦建物状況調査報告書（1年以内のものに限らない）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01683" name="JYU01683" checked={this.props.pageState.JYU01683} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01684" name="JYU01684" checked={this.props.pageState.JYU01684} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-9">
                                <th className="dataTable-title dataTable-title__left">
                                    <div className="dataTableUnit_vr dataTableUnit_vr__equallySpaced">
                                        <div className="dataTableUnit-text">建築基準法第12条の規定による定期調査報告の対象である住宅に</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01685" name="JYU01685" checked={this.props.pageState.JYU01685} {...params} />
                                                        <span className="checkBox-text">該当する</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01686" name="JYU01686" checked={this.props.pageState.JYU01686} {...params} />
                                                        <span className="checkBox-text">該当しない</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01687" name="JYU01687" checked={this.props.pageState.JYU01687} {...params} />
                                                        <span className="checkBox-text">該当するか否か不明</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <th className="dataTable-title dataTable-title__left">⑧定期調査報告書（特定建築物）・定期検査報告書（昇降機等）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01688" name="JYU01688" checked={this.props.pageState.JYU01688} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01689" name="JYU01689" checked={this.props.pageState.JYU01689} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-10">
                                <th className="dataTable-title dataTable-title__left">
                                    <div className="dataTableUnit_vr dataTableUnit_vr__equallySpaced">
                                        <div className="dataTableUnit-text">既存住宅性能評価（建設）を受けた住宅に</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01690" name="JYU01690" checked={this.props.pageState.JYU01690} {...params} />
                                                        <span className="checkBox-text">該当する</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01691" name="JYU01691" checked={this.props.pageState.JYU01691} {...params} />
                                                        <span className="checkBox-text">該当しない</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01692" name="JYU01692" checked={this.props.pageState.JYU01692} {...params} />
                                                        <span className="checkBox-text">該当するか否か不明</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <th className="dataTable-title dataTable-title__left">⑨既存住宅性能評価書（現況検査・評価書）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01693" name="JYU01693" checked={this.props.pageState.JYU01693} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01694" name="JYU01694" checked={this.props.pageState.JYU01694} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-11">
                                <th className="dataTable-title dataTable-title__left dataTable-title__w30" rowSpan="7">
                                    建物の耐震診断書の内容
                                </th>
                                <td className="dataTable-title dataTable-title__left dataTable-title__top dataTable-title__rightBorder" colSpan="4">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>昭和56年5月31日以前に新築の工事に着手した既存の建物に</li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01695" name="JYU01695" checked={this.props.pageState.JYU01695} {...params} />
                                                <span className="checkBox-text">該当する</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01696" name="JYU01696" checked={this.props.pageState.JYU01696} {...params} />
                                                <span className="checkBox-text">該当しないので説明を省略します。</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-12">
                                <th className="dataTable-title dataTable-title__left dataTable-title__w340" rowSpan="7">耐震基準の適合性に関する書類</th>
                                <th className="dataTable-title dataTable-title__left">既存住宅性能評価書（現況検査・評価書）記載中、１-１. 耐震等級の区分</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01697" name="JYU01697" checked={this.props.pageState.JYU01697} {...params} />
                                                <span className="checkBox-text">1以上（適）</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01698" name="JYU01698" checked={this.props.pageState.JYU01698} {...params} />
                                                <span className="checkBox-text">0（不適）</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-13">
                                <th className="dataTable-title dataTable-title__left dataTable-title__noborder">⑩耐震診断結果報告書<br />（有の場合、その概要は別添の耐震診断結果報告書のとおり。）</th>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01699" name="JYU01699" checked={this.props.pageState.JYU01699} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01700" name="JYU01700" checked={this.props.pageState.JYU01700} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-14">
                                <th className="dataTable-title dataTable-title__left dataTable-title__noborder">結果報告書の記載中、新耐震基準の適合性を証する記載</th>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01701" name="JYU01701" checked={this.props.pageState.JYU01701} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01702" name="JYU01702" checked={this.props.pageState.JYU01702} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-15">
                                <th className="dataTable-title dataTable-title__left dataTable-title__noborder">⑪既存住宅売買瑕疵保険の付保証明書</th>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01703" name="JYU01703" checked={this.props.pageState.JYU01703} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01704" name="JYU01704" checked={this.props.pageState.JYU01704} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-16">
                                <th className="dataTable-title dataTable-title__left dataTable-title__noborder">⑫耐震基準適合証明書</th>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01705" name="JYU01705" checked={this.props.pageState.JYU01705} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01706" name="JYU01706" checked={this.props.pageState.JYU01706} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-17">
                                <th className="dataTable-title dataTable-title__left dataTable-title__noborder">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">⑬その他（</div>
                                        <Input id="ID_JYU01707" name="JYU01707" value={this.props.pageState.JYU01707} {...params} length="w160" />
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </th>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01708" name="JYU01708" checked={this.props.pageState.JYU01708} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01709" name="JYU01709" checked={this.props.pageState.JYU01709} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr id="AR_JYU01284-18">
                                <th className="dataTable-title dataTable-title__left dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU01013" name="JYU01013" value={this.props.pageState.JYU01013} text={SEND_OTHER_PAPER.JYU01013} placeholder={JYU01013LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU01013LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
