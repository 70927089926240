import { LinkToOtherDomain } from "components/common/parts/link";
import React from "react";
import * as AWS from "constants/aws";

/** フッター（認証済み） */
export const Footer = (props) => {

    const termsUrl = AWS.ENDPOINT_CLOUDFRONT.URL + AWS.ENDPOINT_CLOUDFRONT.PATHS.TERMS;

    return (
        <footer className="l-footer">
            <div className="footer">
                <div className="footer-auth-policy">
                    <LinkToOtherDomain to="https://sre-group.co.jp/terms/privacy.html" className="footer-auth-policy__action" target="_blank">
                        <span className="footer-auth-policy__text">プライバシーポリシー</span>
                    </LinkToOtherDomain>
                </div>
                <div className="footer-auth-terms">
                    <LinkToOtherDomain to={termsUrl} className="footer-auth-terms__action" target="_blank">
                        <span className="footer-auth-terms__text">利用規約</span>
                    </LinkToOtherDomain>
                </div>
                <div className="footer-auth-copyright">Copyright SRE Holdings Corporation.</div>
            </div>
        </footer>
    );
};