import { PageBase } from "components/page/user/contractFrk/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";

class ImpPage9 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     * ※チェックボックスの場合、falseにする
     */
    getPageItems = () => {
        return {
            // 一棟の建物またはその敷地に関する権利およびこれらの管理・使用に関する事項
            JYU00835: undefined, // 管理規約
            JYU00836: undefined, // 使用規則
            // 一棟の建物およびその敷地の専用使用権について
            JYU00837: undefined, // 名称１
            JYU00838: false, // 有１
            JYU01308: false, // 無１
            JYU00839: undefined, // 区分所有者１
            JYU00840: false, // 専用使用料１ 有
            JYU01309: false, // 専用使用料１ 無
            JYU00841: false, // 帰属先１ チェック１
            JYU00842: "管理組合", // 帰属先１ 名称１
            JYU00843: false, // 帰属先１ チェック２
            JYU00844: undefined, // 帰属先１ 名称２
            JYU00845: undefined, // 備考１
            JYU00846: undefined, // 名称２
            JYU00847: false, // 有２
            JYU01310: false, // 無２
            JYU00848: undefined, // 区分所有者２
            JYU00849: false, // 専用使用料２ 有
            JYU01311: false, // 専用使用料２ 無
            JYU00850: false, // 帰属先２ チェック１
            JYU00851: undefined, // 帰属先２ 名称１
            JYU00852: false, // 帰属先２ チェック２
            JYU00853: undefined, // 帰属先２ 名称２
            JYU00854: undefined, // 備考２
            JYU00855: undefined, // 名称３
            JYU00856: false, // 有３
            JYU01312: false, // 無３
            JYU00857: undefined, // 区分所有者３
            JYU00858: false, // 専用使用料３ 有
            JYU01313: false, // 専用使用料３ 無
            JYU00859: false, // 帰属先３ チェック１
            JYU00860: "管理組合", // 帰属先３ 名称１
            JYU00861: false, // 帰属先３ チェック２
            JYU00862: undefined, // 帰属先３ 名称２
            JYU00863: undefined, // 備考３
            JYU00864: undefined, // 名称４
            JYU00865: false, // 有４
            JYU01314: false, // 無４
            JYU00866: undefined, // 区分所有者４
            JYU00867: false, // 専用使用料４ 有
            JYU01315: false, // 専用使用料４ 無
            JYU00868: false, // 帰属先４ チェック１
            JYU00869: "管理組合", // 帰属先４ 名称１
            JYU00870: false, // 帰属先４ チェック２
            JYU00871: undefined, // 帰属先４ 名称２
            JYU00872: undefined, // 備考４
            JYU00873: undefined, // 名称５
            JYU00874: false, // 有５
            JYU01316: false, // 無５
            JYU00875: undefined, // 区分所有者５
            JYU00876: false, // 専用使用料５ 有
            JYU01317: false, // 専用使用料５ 無
            JYU00877: false, // 帰属先５ チェック１
            JYU00878: "管理組合", // 帰属先５ 名称１
            JYU00879: false, // 帰属先５ チェック２
            JYU00880: undefined, // 帰属先５ 名称２
            JYU00881: undefined, // 備考５
            JYU00882: undefined, // 名称６
            JYU00883: false, // 有６
            JYU01318: false, // 無６
            JYU00884: undefined, // 区分所有者６
            JYU00885: false, // 専用使用料６ 有
            JYU01319: false, // 専用使用料６ 無
            JYU00886: false, // 帰属先６ チェック１
            JYU00887: "管理組合", // 帰属先６ 名称１
            JYU00888: false, // 帰属先６ チェック２
            JYU00889: undefined, // 帰属先６ 名称２
            JYU00890: undefined, // 備考６
            JYU00891: undefined, // 名称７
            JYU00892: false, // 有７
            JYU01320: false, // 無７
            JYU00893: undefined, // 区分所有者７
            JYU00894: false, // 専用使用料７ 有
            JYU01321: false, // 専用使用料７ 無
            JYU00895: false, // 帰属先７ チェック１
            JYU00896: "管理組合", // 帰属先７ 名称１
            JYU00897: false, // 帰属先７ チェック２
            JYU00898: undefined, // 帰属先７ 名称２
            JYU00899: undefined, // 備考７
            JYU01663: undefined, // 名称８
            JYU00901: false, // 有８
            JYU01322: false, // 無８
            JYU00902: undefined, // 区分所有者８
            JYU00903: false, // 専用使用料８ 有
            JYU01323: false, // 専用使用料８ 無
            JYU00904: false, // 帰属先８ チェック１
            JYU00905: "管理組合", // 帰属先８ 名称１
            JYU00906: false, // 帰属先８ チェック２
            JYU00907: undefined, // 帰属先８ 名称２
            JYU00908: undefined, // 備考８
            JYU00909: undefined, // 名称９
            JYU01324: false, // 有９
            JYU00910: false, // 無９
            JYU00911: undefined, // 区分所有者９
            JYU00912: false, // 専用使用料９ 有
            JYU01325: false, // 専用使用料９ 無
            JYU00913: false, // 帰属先９ チェック１
            JYU00914: "管理組合", // 帰属先９ 名称１
            JYU00915: false, // 帰属先９ チェック２
            JYU00916: undefined, // 帰属先９ 名称２
            JYU00917: undefined, // 備考９
            JYU00918: undefined, // 備考

            // 対象不動産に付随する専用使用権について
            JYU00919_ARR: [{
                JYU00919: "バルコニー", // 名称
                JYU00920: undefined, // 使用部分、面積、期間、料金等
            }, {
                JYU00919: "玄関扉、窓枠、窓ガラス", // 名称
                JYU00920: undefined, // 使用部分、面積、期間、料金等
            },],
            JYU00934: undefined, // 備考

            // 所有者が負担すべき費用を特定の者のみに減免する旨の規約等の定め
            JYU00935: false, // 規約の定め 有
            JYU01326: false, // 規約の定め 無
            JYU00936: undefined, // 別添管理規約
            JYU00937: undefined, // 規約の定めの概要
            JYU01791: undefined, // 規約の定めの概要　フリー

        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return ["JYU00919_ARR"];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            contractTypeId: this.props.contractTypeId,  // 契約種別ID
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 一棟の建物またはその敷地に関する権利およびこれらの管理・使用に関する事項 */}
                    <li><Block2 {...params} /></li> {/** 共有部分に関する規約等の定め */}
                    <li><Block3 {...params} /></li> {/** 専用部分の用途その他の制限に関する規約等の定め */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
            </React.Fragment>
        );
    }
}

export default withRouter(ImpPage9);