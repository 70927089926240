import { PageBase } from "components/page/user/contract/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import PopupEmployeeList from "../../popup/popupEmployeeList";
import PopupGuaranteeAssociationList from "../../popup/popupGuaranteeAssociationList";
import PopupOrganizationList from "../../popup/popupOrgList";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";
import Block4 from "./block4";
import Block5 from "./block5";

class ImpPage1 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     */
    getPageItems = () => {
        return {

            // 買主
            TOR00094_ARR: [{
                TOR00094: undefined, // 買主 氏名
            },],

            // 売主
            TOR00072_ARR: [{
                TOR00072: undefined, // 売主 氏名
            },],

            // 取引態様
            JYU00093: false, // 売買
            JYU00094: false, // 交換
            // 売主（宅地建物取引業者）
            JYU00096: undefined, // 免許証番号
            JYU00097: undefined, // 免許年月日
            JYU00098: undefined, // 主たる事務所の所在地
            JYU00100: undefined, // 商号
            JYU00101: undefined, // 代表者氏名
            JYU01458: undefined, // 業務に従事する事務所 住所
            JYU01459: undefined, // 従たる事務所 部署名
            JYU01460: undefined, // 従たる事務所 責任者
            // 宅地健物取引士
            JYU00102: undefined, // 登録番号
            JYU00103: undefined, // 氏名
            JYU01461: undefined, // 業務に従事する事務所 名前
            JYU00104: undefined, // 事務所住所
            JYU00106: undefined, // 電話番号
            // 供託所に関する説明
            JYU00107: false, // 営業保証金の供託所 チェック
            JYU00111: undefined, // 営業保証金の供託所 名称
            JYU01463: undefined, // 営業保証金の供託所 住所
            JYU00108: false, // 宅地建物取引業保証協会 チェック
            JYU00112: undefined, // 保証協会 名称
            JYU01464: undefined, // 保証協会 住所
            JYU00113: undefined, // 事務所 名称
            JYU01465: undefined, // 事務所 住所
            JYU00115: undefined, // 弁済業務保証金の供託所 名称
            JYU01466: undefined, // 弁済業務保証金の供託所 住所

            // 自社情報
            // 取引態様
            JYU00003: false, // 売買
            JYU00004: false, // 交換
            JYU00005: false, // 媒介
            JYU00006: false, // 代理
            JYU00007: false, // 売主
            // 宅地建物取引業者
            JYU00009: undefined, // 免許証番号
            JYU00010: undefined, // 免許年月日
            JYU00011: undefined, // 主たる事務所の所在地
            JYU00013: undefined, // 商号
            JYU00014: undefined, // 代表者氏名
            JYU00016: undefined, // 従たる事務所 住所
            JYU00017: undefined, // 従たる事務所 部署名
            JYU00019: undefined, // 従たる事務所 責任者
            // 説明をする宅地建物取引士
            JYU00020: undefined, // 登録番号
            JYU00021: undefined, // 氏名
            JYU00023: undefined, // 業務に従事する事務所 名前
            JYU00022: undefined, // 業務に従事する事務所 住所
            JYU00025: undefined, // 業務に従事する事務所 電話番号
            // 供給所等に関する説明
            JYU00026: false, // 営業保証金の供託所
            JYU00027: undefined, // 営業保証金の供託所 名称
            JYU00028: undefined, // 営業保証金の供託所 所在地
            JYU00029: false, // 宅地建物取引業保証協会
            JYU00030: undefined, // 保証協会 名称
            JYU00031: undefined, // 保証協会 住所
            JYU01425: undefined, // 保証協会 名称
            JYU00032: undefined, // 事務所 名称
            JYU00033: undefined, // 事務所 住所
            JYU00035: undefined, // 弁済業務保証金の供託所 名称
            JYU00036: undefined, // 弁済業務保証金の供託所 住所

            // 他社情報
            // 取引態様
            JYU01571: false, // 斜線
            JYU00046: false, // 売買
            JYU00047: false, // 交換
            JYU00048: false, // 媒介
            JYU00049: false, // 代理
            JYU00050: false, // 売主
            // 宅地建物取引業者
            JYU00052: undefined, // 免許証番号
            JYU00053: undefined, // 免許年月日
            JYU00054: undefined, // 主たる事務所の所在地
            JYU00056: undefined, // 商号
            JYU00057: undefined, // 代表者氏名
            JYU00059: undefined, // 従たる事務所 住所
            JYU00060: undefined, // 従たる事務所 部署名
            JYU00062: undefined, // 従たる事務所 責任者
            // 説明をする宅地建物取引士
            JYU00063: undefined, // 登録番号
            JYU00064: undefined, // 氏名
            JYU00066: undefined, // 業務に従事する事務所 名前
            JYU00065: undefined, // 業務に従事する事務所 住所
            JYU00068: undefined, // 業務に従事する事務所 電話番号
            // 供給所等に関する説明
            JYU00069: false, // 営業保証金の供託所 チェック
            JYU00070: undefined, // 営業保証金の供託所 名称
            JYU00071: undefined, // 営業保証金の供託所 所在地
            JYU00072: false, // 宅地建物取引業保証協会 チェック
            JYU00073: undefined, // 保証協会 名称
            JYU00074: undefined, // 保証協会 住所
            JYU01429: undefined, // 保証協会 名称
            JYU00075: undefined, // 事務所 名称
            JYU00076: undefined, // 事務所 住所
            JYU00078: undefined, // 弁済業務保証金の事務所 名称
            JYU00079: undefined, // 弁済業務保証金の事務所 住所
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return ["TOR00094_ARR", "TOR00072_ARR"];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 買主 */}
                    <li><Block2 {...params} /></li> {/** 売主 */}
                    <li><Block3 {...params} /></li> {/** 売主宅建業者 */}
                    <li><Block4 {...params} /></li> {/** 自社情報 */}
                    <li><Block5 {...params} /></li> {/** 他社情報 */}
                </ul>
                <PopupOrganizationList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupOrg" />
                <PopupGuaranteeAssociationList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupGua" />
                <PopupEmployeeList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupEmp" />
            </React.Fragment>

        );
    }
}

export default withRouter(ImpPage1);