export function openContractPullDown() {
    return {
        type: "OPEN_CONTRACT"
    };
}

export function closeContractPullDown() {
    return {
        type: "CLOSE_CONTRACT"
    };
}

export function openSystemManagePullDown() {
    return {
        type: "OPEN_SYSTEM_MANAGE"
    };
}

export function closeSystemManagePullDown() {
    return {
        type: "CLOSE_SYSTEM_MANAGE"
    };
}
