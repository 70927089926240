import React from "react";

/**
 * 表示の１レコード
 * props.outputList: 表示されるデータリスト
 */
export function OutputRecord(props) {
    const outputList = props.outputList;
    const outputDom = outputList.map((output, index) => {
        // outputが関数の場合、関数を実行する
        output = typeof (output) === "function" ? output() : output;
        // 1つ目のデータをtitleのCSSにする
        return (<td className={index === 0 ? "dataTable-title" : "dataTable-body"} key={index}>{output}</td>);
    });

    return (<tr>{outputDom}</tr>);
}
