import React from "react";
import Validation from "util/validate";
import { SEND_OTHER_PAPER } from "../../../../../../../constants/messages";
import InputTextRemark from "../../input/inputTextRemark";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 備考 */
export default class Block12 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010112",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        //TOR00199の入力上限判定
        const TOR00199LimitErrorMessage = this.validation.validateTextAreaWordCount("TOR00199", this.props.pageState.TOR00199);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">備考</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableReturn id="ID_TOR00199" name="TOR00199" value={this.props.pageState.TOR00199} text={SEND_OTHER_PAPER.TOR00199} placeholder={TOR00199LimitErrorMessage.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{TOR00199LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}