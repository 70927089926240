import {
    InputCheckBoxRecord, InputRadioBoxRecord, InputSelectBoxRecord,
    InputSelectBoxRecordWithoutRow, InputTextRecord, InputTextRecordWithoutRow
} from "components/common/parts/inputRecord";
import * as AWS from "constants/aws";
import { CONTRACT_TYPE_FRK, EDIT_AUTHORITY } from "constants/contractEditPageInfo";
import { ERROR_MESSAGE } from "constants/errorMessage";
import { USE_CLAUSE } from "constants/format";
import { PREFECTURES } from "constants/prefectures";
import React from "react";
import { Link } from "react-router-dom";
import * as awsReq from "util/awsRequest";
import Validation from "util/validate";
import PopupBase from "./popupBase";

/** ポップアップ画面：基本情報 */
export default class popupSetting extends PopupBase {
    constructor(props) {
        super(props);
        this.state = {
            settingTemp: {
                propertyPrefectures: this.props.settingData.propertyPrefectures,
                buildingName: this.props.settingData.buildingName,
                contractTypeId: this.props.settingData.contractTypeId,
                documentNo: this.props.settingData.documentNo,
                headquartersConfirm: this.props.settingData.headquartersConfirm,
                propertyAddress: this.props.settingData.propertyAddress,
                propertyCity: this.props.settingData.propertyCity,
                useClause: this.props.settingData.useClause,
            },
            authorityList: undefined, // 共有権限のリスト
        };

        this.apicalling = 0; // APIコール中のページを保持（0はコール無し。連続API防止措置なので簡易）

        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
        // 画面から入力される値の情報
        this.params = {
            propertyPrefectures: { validate: this.validation.noCheck, },
            propertyCity: { validate: this.validation.noCheck, },
            propertyAddress: { validate: this.validation.noCheck, },
            buildingName: { validate: this.validation.notEmpty, },
            documentNo: { validate: this.validation.notEmpty, },
            useClause: { validate: this.validation.notEmpty, },
            headquartersConfirm: { validate: this.validation.noCheck, },
        };
    }

    componentDidMount() {
        // 共有権限のリストを共有権限一覧取得APIから取得する
        awsReq.get(
            AWS.ENDPOINT.PATHS.GET_SHARING_AUTHORITY_LIST,
            res => { this.setState({ authorityList: res.data, }); },
            awsReq.defaultErrorHandler,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    componentDidUpdate(prevProps, prevState) {
        // コンポーネント更新時に props.xxxxxTemp に前回との差分がある場合、props.xxxxxTmp を優先して描画する
        if (prevProps.shareListTmp !== this.props.shareListTmp) {
            this.setState({ shareList: JSON.parse(JSON.stringify(this.props.shareListTmp)) });
        }
    }

    /** キャンセルボタンのハンドラ */
    handleCancelBtn = e => {
        e.preventDefault();
        // 設定されている内容を props で渡されていた確定済みの内容に戻す ※自分が変更した内容をリセットする
        // this.props.updateShareListTmp(JSON.parse(JSON.stringify(this.props.shareList)));
        this.props.onClose();
    }

    /** 追加ボタンのハンドラ */
    handleAddBtn = e => {
        e.preventDefault();
        this.props.onAdd();
    }

    /**
     * 設定のバリデート
     * true: OK、false: NG
     */
    validateSetting = () => {
        if (this.validation.validateTotalInputText(this.params, this.state.settingTemp)) {
            return false;
        }
        return true;
    }

    /**
     * 契約情報共有のバリデート
     * true: OK、false: NG
     */
    validateSharingAuthority = array => {
        // 共有権限設定が1人も設定されていない場合、false
        if (!array) { return false; }

        // 担当営業のみの人をピックアップする
        const sales = array.filter(item => {
            return item.sharing_authority_id === EDIT_AUTHORITY.EIGYO;
        });

        // 担当営業が2人以上の場合、false
        if (sales.length > 1) { return false; }

        // 担当営業が1人もいない場合の場合、false
        if (sales.length === 0) { return false; }

        return true;
    }

    /** 確定ボタンのハンドラ */
    handleCommitBtn = e => {
        e.preventDefault();
        if (!this.validateSetting()) {
            this.setState({});
            return;
        }
        if (!this.validateSharingAuthority(this.props.shareListTmp)) {
            alert(ERROR_MESSAGE.CONTRACT_REGISTER_SHARE_SETTING_NG);
            return;
        }
        this.props.onCommit(this.state.settingTemp, this.props.shareListTmp);
        this.props.onClose();
    }

    /** 削除ボタンのハンドラ */
    handleDeleteBtn = (e, idx) => {
        e.preventDefault();

        // 指定されたレコードを削除する
        const tmp = this.props.shareListTmp.filter((item, i) => { return idx !== i; });
        this.props.updateShareListTmp(tmp);
    }

    /** 共有権限変更のハンドラ */
    handleChangeShareSelect = (e, idx) => {
        let tmp = JSON.parse(JSON.stringify(this.props.shareListTmp));
        tmp[idx].sharing_authority_id = e.target.value; // 変更された設定のオブジェクトを生成
        this.props.updateShareListTmp(tmp); // テンポラリの更新
    }

    /** 契約種別IDのハンドラ */
    handleContractTypeIdSelect = e => {
        this.setState({ settingTemp: { ...this.state.settingTemp, contractTypeId: e.target.value } });
    }

    /** 物件名_都道府県のハンドラ */
    handlePropertyPrefecturesSelect = e => {
        this.setState({ settingTemp: { ...this.state.settingTemp, propertyPrefectures: e.target.value } });
    }

    /** 物件名_市区町村のハンドラ */
    handlePropertyCityInput = e => {
        this.setState({ settingTemp: { ...this.state.settingTemp, propertyCity: e.target.value } });
    }

    /** 物件名_番地のハンドラ */
    handlePropertyAddressInput = e => {
        this.setState({ settingTemp: { ...this.state.settingTemp, propertyAddress: e.target.value } });
    }

    /** 物件名のハンドラ */
    handleBuildingNameInput = e => {
        this.setState({ settingTemp: { ...this.state.settingTemp, buildingName: e.target.value } });
    }

    /** 稟議書No.のハンドラ */
    handleDocumentNoInput = e => {
        this.setState({ settingTemp: { ...this.state.settingTemp, documentNo: e.target.value } });
    }

    /** 利用約款のハンドラ */
    handleUseClauseInput = e => {
        this.setState({ settingTemp: { ...this.state.settingTemp, useClause: Number(e.target.value) } });
    }

    /** 本部確定フラグのハンドラ */
    handleheadquartersConfirmCheck = e => {
        const newValue = !this.state.settingTemp.headquartersConfirm;
        this.setState({ settingTemp: { ...this.state.settingTemp, headquartersConfirm: newValue } });
    }

    /** 入力ボックスの入力値を空文字チェック */
    handleInputOnBlur = e => {
        const target = e.target;
        const param = this.params[target.name];
        param.validate(target.name, target.value);
        this.setState({});  // 再renderする為の空セット
    }

    render() {
        const displayActive = this.props.active ? "block" : "none";

        // セレクトリストの生成
        const contractTypes = CONTRACT_TYPE_FRK;
        let contractTypeList = contractTypes
            ? contractTypes.map(contractType => ({ value: contractType.contract_type_id, display: contractType.name }))
            : [];

        const prefectures = PREFECTURES;
        let prefecturesList = prefectures
            ? prefectures.map(prefecture => ({ value: prefecture, display: prefecture }))
            : [];
        prefecturesList.unshift({ value: "", display: "" });

        // チェックボックスの非活性化
        let headquartersConfirm_CheckOn = false;
        if (!this.props.approvalAuthority) {
            headquartersConfirm_CheckOn = true;
        }

        return (
            <React.Fragment>
                <div style={{ display: displayActive }}>
                    <main className="l-modalBox">
                        <div className="modalBox">
                            <div className="dataList dataList__accordion is-open">
                                <div className="dataList-header">設定</div>
                                <div className="dataList-body">
                                    <table className="dataTable">
                                        <tbody>
                                            {/* 参照: CONTRACT_CLOUD_ENHANCE-209
                                            契約書式変更機能は、今後復活する可能性があるため一時的にコメントアウトとする
                                            コメントアウトされた部分にのみ存在する変数・関数はそのまま放置している */}
                                            {/* <tr>
                                                <InputSelectBoxRecord titleName="契約書式"
                                                    titleSetting={{ className: "dataTable-title dataTable-title__w120" }}
                                                    inputSetting={{ onChange: this.handleContractTypeIdSelect }}
                                                    inputData={{ name: "contractTypeId", value: this.state.settingTemp.contractTypeId || "" }}
                                                    optionsDataList={contractTypeList}
                                                />
                                            </tr> */}
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w120">物件名</th>
                                                <td className="dataTable-body" colSpan="2">
                                                    <div className="dataTableUnit">
                                                        <InputSelectBoxRecordWithoutRow
                                                            inputData={{ name: "propertyPrefectures", value: this.state.settingTemp.propertyPrefectures || "" }}
                                                            inputSetting={{ onChange: this.handlePropertyPrefecturesSelect }}
                                                            optionsDataList={prefecturesList}
                                                            selectClassName="selectBox__w120"
                                                            lengthForErrMsg="w201"
                                                        />
                                                        <div className="dataTableUnit-text">／</div>
                                                        <InputTextRecordWithoutRow
                                                            inputData={{ name: "propertyCity", value: this.state.settingTemp.propertyCity || "" }}
                                                            inputSetting={{ onChange: this.handlePropertyCityInput, onBlur: this.handleInputOnBlur, placeholder: "市区町村" }}
                                                            lengthForErrMsg="w201"
                                                            inputClassName="input__w270"
                                                            errorMessage={this.validation.errorMessage.propertyCity || ""}
                                                        />
                                                        <div className="dataTableUnit-text">／</div>
                                                        <InputTextRecordWithoutRow
                                                            inputData={{ name: "propertyAddress", value: this.state.settingTemp.propertyAddress || "" }}
                                                            inputSetting={{ onChange: this.handlePropertyAddressInput, onBlur: this.handleInputOnBlur, placeholder: "丁目" }}
                                                            lengthForErrMsg="w201"
                                                            inputClassName="input__w270"
                                                            errorMessage={this.validation.errorMessage.propertyAddress || ""}
                                                        />
                                                        <InputTextRecordWithoutRow
                                                            inputData={{ name: "buildingName", value: this.state.settingTemp.buildingName || "" }}
                                                            inputSetting={{ className: "", onChange: this.handleBuildingNameInput, onBlur: this.handleInputOnBlur, placeholder: "マンション名、備考など" }}
                                                            lengthForErrMsg="w201"
                                                            inputClassName="wFull"
                                                            errorMessage={this.validation.errorMessage.buildingName || ""}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <InputTextRecord
                                                    titleName="稟議書No."
                                                    titleSetting={{ className: "dataTable-title dataTable-title__w120" }}
                                                    bodySetting={{ colSpan: "2" }}
                                                    inputData={{ name: "documentNo", value: this.state.settingTemp.documentNo || "" }}
                                                    inputSetting={{ className: "input__w201", onChange: this.handleDocumentNoInput, onBlur: this.handleInputOnBlur }}
                                                    lengthForErrMsg="w201"
                                                    errorMessage={this.validation.errorMessage.documentNo || ""}
                                                />
                                            </tr>
                                            <tr>
                                                <InputRadioBoxRecord
                                                    titleName="約款"
                                                    titleSetting={{ className: "dataTable-title dataTable-title__w120" }}
                                                    bodySetting={{ colSpan: "2" }}
                                                    inputSetting={{ onChange: this.handleUseClauseInput }}
                                                    inputData={{ name: "useClause", value: this.state.settingTemp.useClause }}
                                                    radioDataList={[
                                                        { display: USE_CLAUSE.KANTOU_LOCAL.display, value: USE_CLAUSE.KANTOU_LOCAL.value },
                                                        { display: USE_CLAUSE.KANSAI_LOCAL.display, value: USE_CLAUSE.KANSAI_LOCAL.value }
                                                    ]}
                                                    errorMessage={this.validation.errorMessage.useClause || ""}
                                                />
                                            </tr>
                                            <tr>
                                                <InputCheckBoxRecord
                                                    titleName="確定"
                                                    titleSetting={{ className: "dataTable-title dataTable-title__w120" }}
                                                    bodySetting={{ colSpan: "2" }}
                                                    inputSetting={{ onChange: this.handleheadquartersConfirmCheck }}
                                                    inputData={{ name: "headquartersConfirm", checked: this.state.settingTemp.headquartersConfirm, disabled: headquartersConfirm_CheckOn }}
                                                    checkboxText={"本部稟議の場合はチェック要"}
                                                />
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="l-dataList l-dataList__topSpace"></div>
                            <ul className="l-baseAction">
                                <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleAddBtn}>追加</Link></li>
                            </ul>
                            <div className="l-dataList l-dataList__topSpace"></div>
                            <div className="dataList dataList__accordion dataList__modalScroll is-open">
                                <div className="dataList-header">
                                    <div className="dataList-header-title">契約情報共有</div>
                                </div>
                                <div className="dataList-body dataList-body__modalScroll" tabIndex="0">
                                    <table className="dataTable">
                                        <tbody>
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w320 is-emphasis">氏名</th>
                                                <th className="dataTable-title dataTable-title__w320 is-emphasis">共有権限</th>
                                                <th className="dataTable-title dataTable-title__w320 is-emphasis">&nbsp;</th>
                                            </tr>
                                            {this.createListDOM()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <ul className="l-baseAction l-baseAction__rightTwo l-baseAction__doubleSpace">
                                <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleCommitBtn}>確定</Link></li>
                                <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleCancelBtn}>キャンセル</Link></li>
                            </ul>
                        </div>
                    </main>
                </div>
            </React.Fragment>
        );
    }

    /** リスト部分の生成 */
    createListDOM = () => {
        if (!this.state.authorityList) { return null; }

        // 共有権限一覧取得APIの結果を元にoptions部分の生成
        const options = this.state.authorityList.map(item => {
            return <option key={item.sharing_authority_id} value={item.sharing_authority_id}>{item.name}</option>;
        });

        return this.props.shareListTmp.map((item, idx) => {
            return (
                <tr key={item.agency_user_id}>
                    <td className="dataTable-title">{item.user_name}</td>
                    <td className="dataTable-body">
                        <div className="selectBox">
                            <select name="" id="" aria-label="select" className="selectBox-select"
                                value={item.sharing_authority_id} onChange={e => { this.handleChangeShareSelect(e, idx); }}>
                                {options}
                            </select>
                        </div>
                    </td>
                    <td className="dataTable-body">
                        <Link to="" aria-label="link" className="baseAction" onClick={e => { this.handleDeleteBtn(e, idx); }}>削除</Link>
                    </td>
                </tr>
            );
        });
    }
}
