import moment from 'moment/moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import React from "react";
import * as helper from "../helper";
import { InputBase } from './inputBase';

export default class InputTime extends InputBase {
    constructor(props) {
        super(props);

        this.state = {
            errorDisp: false // true: 表示する
        };

        // 時間入力のパネルを表示するとinputタグからフォーカスが外れてonBlurが発火してしまうため（onBlurのタイミングでエラーメッセージ表示用の判定処理が走る）、
        // 時間入力のパネルが開いている間はバリデートを無効化するフラグを作る
        // ※未入力の状態から時間を入力しようとすると、パネルを開くタイミングでonBlurが発火され必ずエラーメッセージが表示されてしまうため
        this.validateInvalid = false;

        // 先祖から設定された値の検証
        let tmp = {};
        tmp[helper.genValidKey(this.props.name)] = this.validate(this.props.value); // 有効化かどうか（true:有効）
        this.props.updatePageState(tmp, false); // 先祖のstateを更新するのでレンダリングが走る
    }

    /**
     * 入力欄に更新があった際のハンドラ
     * ※TimePickerコンポーネントに渡しているハンドラであり、onChange始動のハンドラではないので注意
     */
    handleChange = value => {

        // 値の更新（先祖コンポーネントから渡されたstateの更新）
        let tmp = {};
        tmp[this.props.name] = value === null ? "" : value.format('HH:mm'); // TimePcikerのライブラリが未入力時にはnullを渡してくるのでその対策として空文字列変換する
        tmp[helper.genValidKey(this.props.name)] = this.validate(value); // 有効化かどうか（true:有効）
        this.props.updatePageState(tmp); // 先祖のstateを更新するのでレンダリングが走る
    };

    /**
     * バリデートロジックを定義する。
     * true: エラー無し false: エラー有り
     */
    validate = (val) => {
        // 入力無しは許容
        //
        // ※valの状態は下記を前提とする
        // 最初から入力欄が空だった場合はundefined
        // 変更して空にした場合は空文字列
        if (val === undefined) {
            return undefined;
        }

        // 時間として成立していないものは入力できないようになっているので入力がある場合、バリデートはtrue
        // this.errorMessage = "時間を入力してください";
        // return !(val === "" || val === null);
        return true; // 現時点では必ずtrue
    }

    /**
     * フォーカスアウト時のハンドラ
     * ※フォーカスアウトした際にエラーメッセージを出すかどうかを決める
     */
    handlerBlur = e => {
        // 時間入力のパネルを開いている間は無効化する
        if (this.validateInvalid) return;

        if (!this.validate(e.target.value)) {
            this.setState({ errorDisp: true });
        } else {
            this.setState({ errorDisp: false });
        }
    }

    handlePanelOpen = e => {
        // 時間入力のパネルが開いている間はバリデートを無効化するフラグを立てる
        this.validateInvalid = true;
    }

    handlePanelClose = e => {
        // 時間入力のパネルを閉じるタイミングでバリデートを有効化するフラグを立てる
        this.validateInvalid = false;

        // 時間入力はライブラリが生成したパネル対して行っており（そちらにフォーカスが移る）、
        // 時間入力が終わってもinputタグに付けたonBlurが発動しないため、セレクタを使ってフォーカスをinputタグに戻す
        const element = document.querySelector(`input[name=${this.props.name}]`);
        element.focus();
    }

    render() {
        const disabled = this.isDisabled(this.props.pageState.disableItem);

        // 時間以外がvalueとして渡されている場合、nullにする
        let time = moment(this.props.value, "H:m", true);
        time = time.isValid() ? time : null;

        // バリデートエラーの場合、エラー用のクラスを付与
        // ※初期表示時の未入力部分にはエラーを出さない
        let classname = "dataTableUnit-input";
        if (this.state.errorDisp) {
            classname = "dataTableUnit-input is-error";
        }

        // TimePickerに無理やりスタイル当てる
        var styles = [];
        styles.push(<style key="1">{'.rc-time-picker-panel-input, .rc-time-picker-panel-select {font-size:16px}'}</style>);
        styles.push(<style key="2">{'.rc-time-picker-panel-select li {text-align:center; padding: 0 0 0 0}'}</style>);
        styles.push(<style key="3">{'.rc-time-picker-panel-select {width:112px}'}</style>);
        styles.push(<style key="4">{'.rc-time-picker-panel-mystyle {max-width:240px;width:240px}'}</style>);

        return (
            <React.Fragment>
                {styles}
                <style>
                    {/* inputタグに設定されるclassを指定できないので、指定されるclassの内容を書き換える */}
                    {".rc-time-picker-input { height: auto; width: 96px !important }"}
                </style>
                <div className={classname}>
                    <TimePicker
                        name={this.props.name}
                        autoComplete="off"
                        // style={{ height: "auto" }}
                        showSecond={false}
                        onChange={this.handleChange}
                        onBlur={this.handlerBlur}
                        // placeholder="時間を入力してください"
                        allowEmpty={false}
                        onOpen={this.handlePanelOpen}
                        onClose={this.handlePanelClose}
                        popupClassName="rc-time-picker-panel-mystyle"
                        disabled={disabled}
                        value={time}
                    />
                    <span className="dataTableUnit-input-error">
                        <span className="dataTableUnit-input-error_text">{this.errorMessage}</span>
                    </span>
                </div>
                <div className="dataTableUnit-text">から</div>
            </React.Fragment>
        );
    }
}