export const COGNITO = {
    ENDPOINT_URL: process.env.REACT_APP_COGNITO_ENDPOINT_URL,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    USER_POOL_WEB_CLIENT_ID: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    REGION: process.env.REACT_APP_COGNITO_REGION
};

export const ENDPOINT = {
    REST_API: {
        NAME: process.env.REACT_APP_API_NAME,
        URL: process.env.REACT_APP_API_ENDPOINT_URL,
        REGION: process.env.REACT_APP_API_ENDPOINT_REGION
    },
    REST_API_MOCK: {
        NAME: "cmaker-dev-api-mock",
        URL: process.env.REACT_APP_API_ENDPOINT_URL_MOCK,
        REGION: process.env.REACT_APP_API_ENDPOINT_REGION
    },
    PATHS: {
        LOGIN_USER: "general/v1/login-users",
        WIP_CONTRACTS: "general/v1/wip-contracts",

        CONTRACTS: "general/v1/contracts",
        CONTRACTS_FRK: "general/v1/contracts_frk",
        CONTRACTS_CONTRACT: "general/v1/contracts/:contractId",
        CONTRACT_LIST: "general/v1/get-contract-list", // 契約書情報一覧取得
        CONTRACTS_PAGE: "general/v1/contracts/:contractId/contract-pages/:pageId",
        CONTRACTS_PAGE_FRK: "general/v1/contracts/:contractId/contract-pages-frk/:pageId",
        GET_CONTRACT_INDIVIDUAL_CONTROL: "general/v1/contracts/:contractId/get_contract_individual_control", // 契約書個別制御の取得
        CSV_DOWNLOAD_BASIC_INFO: "general/v1/contracts/:contractId/csv-download-basic-info", // 基本情報CSVダウンロード
        GET_CONTRACT_IMAGE_UPLOAD_URL: "general/v1/get_contract_image_upload_url", // 契約書入力画像アップロードURL取得
        GET_SHARING_AUTHORITY_LIST: "general/v1/sharing-authorities", // 共有権限一覧取得
        GET_CONTRACT_SHARING_AUTHORITY_LIST: "general/v1/contract-sharing-authorities", // 契約書共有権限一覧取得
        CONFIRM_CONTRACT: "confirm/v1/confirm-contract", // 契約確定
        DELETE_CONTRACT: "general/v1/delete-contract", // 契約削除
        RELEASE_CONTRACT: "general/v1/release-contract", // 確定解除
        GET_PAST_CONTRACT_LIST: "general/v1/get-past-contract-list", // 過去情報一覧取得
        GET_POPUP_AGENCY_BRANCH_LIST: "general/v1/popup-agency-branches", // 組織情報一覧取得(ポップアップ)
        GET_POPUP_AGENCY_USER_LIST: "general/v1/get-agency-users", // 従業員情報一覧取得(ポップアップ)
        GET_POPUP_GUARANTEE_ASSOCIATION_LIST: "general/v1/popup-guarantee-associations", // 保証協会一覧取得(ポップアップ)
        GET_POPUP_TEMPLATE_LIST: "general/v1/template-headlines/:templateHeadlineId/templates", // 定型文一覧取得(ポップアップ)
        UPDATE_CONTRACTS_FRK: "general/v1/contracts/update-contract-frk",  // 契約書情報更新
        UPDATE_CONTRACT_SHARING_AUTHORITY: "general/v1/update-contract-sharing-authorities", // 契約書共有権限更新
        GET_SELECT_BOX_INPUT_ITEM_LIST: "general/v1/input-items", // 名称一覧取得

        SYSTEM_ANNOUCEMENT: "general/v1/get-system-announcement-message",

        MY_COMPANY: "system-admin/v1/agencies",
        CONSUMPTION_TAX_FRACTION_DIGITS: "system-admin/v1/consumption-tax-fraction-digits", // 消費税端数桁数一覧取得
        GET_LOGO_IMAGE_UPLOAD_URL: "system-admin/v1/logo-image-upload-urls", // ロゴ画像アップロードURL取得

        BRANCHAND_ORGANIZATION_LIST: "system-admin/v1/agency-branch-list",
        BRANCHAND_ORGANIZATION: "system-admin/v1/agency-branches",
        BRANCHAND_ORGANIZATION_DETAIL: "system-admin/v1/agency-branches/:id",
        BRANCHAND_LIST: "general/v1/get-agency-branches", // 支店/組織情報一覧取得(セレクトボックス)
        EMPLOYEE_LIST: "system-admin/v1/agency-user-list", // 従業員情報一覧取得
        EMPLOYEE: "system-admin/v1/agency-users", // 従業員情報登録
        EMPLOYEE_DETAIL: "general/v1/agency-users/:id",
        EMPLOYEE_DETAIL_SYS: "system-admin/v1/agency-users/:id",
        GUARANTEE_LIST: "system-admin/v1/guarantee-association-list", // 保証協会情報一覧取得
        GUARANTEE: "system-admin/v1/guarantee-associations", // 保証協会情報登録
        GUARANTEE_DETAIL: "system-admin/v1/guarantee-associations/:id",
        INPUT_ITEM_LIST: "system-admin/v1/input-item-list", // 名称マスタ一覧取得
        INPUT_ITEM: "system-admin/v1/input-items", // 名称マスタ登録
        INPUT_ITEM_DETAIL: "system-admin/v1/input-items/:id", // 名称マスタ取得
        INPUT_ITEM_TYPES: "system-admin/v1/input-item-types", // 名称マスタ名称区分一覧取得
        DEFAULT_INPUT_VALUES: "system-admin/v1/default-values", // デフォルト表記一覧取得
        DEFAULT_INPUT_VALUES_DETAIL: "general/v1/default-input-values/:type",
        TEMPLATE_LIST: "system-admin/v1/template-list", // 定型文一覧取得
        TEMPLATE: "system-admin/v1/templates", // 定型文登録
        TEMPLATE_DETAIL: "system-admin/v1/templates/:id", // 定型文取得
        TEMPLATE_HEADLINES: "system-admin/v1/template-headlines", // 定型文見出し一覧取得

        // 約款・別表系
        GET_ADDITION_DOCUMENT_UPLOAD_URL: "system-admin/v1/addition-document-upload-urls", // 約款・別表差込PDFアップロードURL取得
        UPDATE_ADDITION_DOCUMENT_S3_KEY: "system-admin/v1/update-addition-documents-s3-keys", // 約款・別表差込PDFファイルS3キー更新
        GET_ADDITION_DOCUMENT_DOWNLOAD_URL: "system-admin/v1/addition-document-download-urls", // 約款・別表差込PDFダウンロードURL取得

        // パスワード系
        SEND_AGENCY_PASSWORD_RESET_MAIL: "v1/send-password-reset-mail",
        CHECK_OPERATOR_PASSWORD_RESET_URL: "v1/check-password-reset-url",
        RESET_AGENCY_PASSWORD: "v1/reset-password",
        UPDATE_AGENCY_PASSWORD_AND_SEND_MAIL: "general/v1/agency-user-password",

        // IP制限チェック
        CHECK_IP_LIMITATION: "general/v1/check-ip-limitation",

        // パスワード有効期限切れチェック
        CHECK_PASSWORD_EXPIRED: "general/v1/check-password-expired",

        TEST: "general/v1/dammy-contract",
    }
};

export const ENDPOINT_ECS = {
    URL: process.env.REACT_APP_API_ENDPOINT_URL_PDF,
    PATHS: {
        PDF: "pdf", // PDF出力
    },
};

export const ENDPOINT_FRK_ECS = {
    URL: process.env.REACT_APP_API_ENDPOINT_URL_FRK_PDF,
    PATHS: {
        PDF: "pdf", // PDF出力
    },
};

export const ENDPOINT_OCR = {
    URL: process.env.REACT_APP_API_ENDPOINT_URL_OCR,
    PATHS: {
        OCR: "ocr", // OCR
    },
};

export const ENDPOINT_CLOUDFRONT = {
    URL: process.env.REACT_APP_CLOUDFRONT_URL,
    PATHS: {
        MANUAL_OK: "SRE契約重説CLOUDご利用マニュアル.pdf", // おうちクラベル書式ご利用マニュアル
        MANUAL_FRK: "不動産売買契約書類作成システムご利用マニュアル(FRK書式).pdf", // FRK書式ご利用マニュアル
        MANUAL_MUFG: "不動産売買契約書類作成システムご利用マニュアル(三菱様FRK書式).pdf", // 三菱様FRK書式ご利用マニュアル
        TERMS: "terms/terms.html", // 利用規約
    },
};