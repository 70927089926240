import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import React from "react";
import Validation from "util/validate";
import { SEND_OTHER_PAPER } from "../../../../../../../constants/messages";
import InputTextRemark from "../../input/inputTextRemark";

/** 飲用水・電気・ガスの供給施設および排水施設の整備状況 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021601",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 文字数制限定義
        const JYU01222LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU01222",
                this.props.pageState.JYU01222);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">その他重要な事項</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU01222" name="JYU01222" value={this.props.pageState.JYU01222} text={SEND_OTHER_PAPER.JYU01222} placeholder={JYU01222LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU01222LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}