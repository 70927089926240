import React from "react";
import Validation from "util/validate";
import Input from "../../input/inputNormal";
import Select from "../../input/inputSelect";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 物件名と稟議書No. */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010101",
        };
        this.validation = new Validation();
    }

    notEmpty = (key) => (value) => {
        this.validation.notEmpty(key, value);
        const errorMessage = this.validation.errorMessage[key];
        return errorMessage !== '' ? errorMessage : null;
    }

    /** 物件名入力チェック */
    validateTOR00200 = this.notEmpty('TOR00200');

    /** 稟議書No.入力チェック */
    validateTOR00201 = this.notEmpty('TOR00201');

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">物件名と稟議書No.</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">物件名</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Select id="ID_TOR00303" name="TOR00303" value={this.props.pageState.TOR00303} items={this.getSelectbox(this.genPrefecturesList())} {...params} length="w120" />
                                        <div className="dataTableUnit-text">／</div>
                                        <Input id="ID_TOR00304" name="TOR00304" value={this.props.pageState.TOR00304} {...params} placeholder="市区町村" length="w270" />
                                        <div className="dataTableUnit-text">／</div>
                                        <Input id="ID_TOR00305" name="TOR00305" value={this.props.pageState.TOR00305} {...params} placeholder="丁目" length="w270" />
                                        <Input id="ID_TOR00200" name="TOR00200" value={this.props.pageState.TOR00200} {...params} placeholder="マンション名、備考など" length="wFull" validate={this.validateTOR00200} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">稟議書No.</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00201" name="TOR00201" value={this.props.pageState.TOR00201} {...params} validate={this.validateTOR00201} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >
        );
    }
}