import BaseGrid from "components/common/frame/baseGrid";
import { FormButtonRecord, FormInputTextRecord } from "components/common/parts/formRecord";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import * as awsReq from "util/awsRequest";
import Validation from "util/validate";
import * as loginActions from "../../../../redux/actions/login";

/** パスワード変更画面 */
class PasswordChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();

        // 画面から入力される値の情報
        this.params = {
            oldPassword: {
                label: "既存のパスワード",
                validate: this.validation.notEmpty,
                apiParamKey: "oldPassword"
            },
            newPassword: {
                label: "パスワード",
                validate: this.validation.notEmpty,
                apiParamKey: "newPassword"
            },
            newConfirmPassword: {
                label: "パスワード確認",
                validate: this.validation.notEmpty,
                apiParamKey: "newConfirmPassword"
            }
        };
    }

    componentDidMount() {
        document.title = pageInfo.PASSWORD_CHANGE.title;
    }

    passwordChange = async () => {
        const query = {
            current_password: this.state.oldPassword,
            new_password: this.state.newPassword,
            confirm_password: this.state.newConfirmPassword,
        };

        await awsReq.put(
            AWS.ENDPOINT.PATHS.UPDATE_AGENCY_PASSWORD_AND_SEND_MAIL,
            res => { this.props.history.push(pageInfo.PASSWORD_CHANGE_COMPLETE.path); },
            error => { alert(ERROR_MESSAGE.FAILED_CHANGE_PASSWORD); }, // TODO: パスワード変更失敗画面（https://redmine.yamanote.in/issues/31426）
            query,
            {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputText = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** 入力ボックスの入力値をバリデーション */
    handleValidateInputText = e => {
        const target = e.target;
        const param = this.params[target.name];
        param.validate(target.name, target.value);
        this.setState({});  // 再renderする為の空セット
    }

    // ユーザーパスワードを変更する
    handleChangePassword = e => {
        e.preventDefault();

        // 全入力項目のチェックし、エラーメッセージがない場合、後続処理（登録APIの呼出し）をする
        if (!this.validation.validateTotalInputText(this.params, this.state)) {
            // エラーメッセージがない場合、同値チェックを行う
            this.validation.notEqualValues("newConfirmPassword", this.state.newConfirmPassword, this.state.newPassword);
            if (!this.validation.isErrorMessage()) {
                // APIコール
                this.passwordChange();
            } else {
                this.setState({}); // エラーメッセージ表示のための空setState()
            }
        } else {
            this.setState({}); // エラーメッセージ表示のための空setState()
        }
    };

    render() {
        // Inputレコードに関する、タグの属性情報
        const inputSetting = { onChange: this.handleChangeInputText, onBlur: this.handleValidateInputText };

        return (
            <BaseGrid>
                <form>
                    <div className="l-loginField l-loginField--menu">
                        <div className="loginField">
                            <div className="loginField-action">
                                <div className="loginField-action-list">
                                    <style>
                                        {".password_policy_head { color: red; } .password_policy { font-size: 11px; }"}
                                    </style>
                                    <div className="password_policy password_policy_head">パスワードポリシーをご確認お願いします</div>
                                    <div className="password_policy">・半角英数記号であること</div>
                                    <div className="password_policy">・半角の英大文字、英小文字、数字をそれぞれ1文字以上含むこと</div>
                                    <div className="password_policy">・12文字以上、16文字以下であること</div>
                                    <div className="password_policy">・現在、前回のパスワードと異なること</div>
                                    <div className="password_policy">・仲介業者様でFRKフォーマットの方は現在を含めた過去３回に設定したパスワードと異なること</div>
                                    <div className="password_policy">・ユーザーIDと異なること</div>
                                </div>

                                <FormInputTextRecord password
                                    titleName={this.params.oldPassword.label}
                                    inputData={{ name: "oldPassword", value: this.state.oldPassword || "" }}
                                    inputSetting={{ placeholder: this.params.oldPassword.placeholder, ...inputSetting }}
                                    errorMessage={this.validation.errorMessage.oldPassword || ""} />

                                <FormInputTextRecord password
                                    titleName={this.params.newPassword.label}
                                    inputData={{ name: "newPassword", value: this.state.newPassword || "" }}
                                    inputSetting={{ placeholder: this.params.newPassword.placeholder, ...inputSetting }}
                                    errorMessage={this.validation.errorMessage.newPassword || ""} />

                                <FormInputTextRecord password
                                    titleName={this.params.newConfirmPassword.label}
                                    inputData={{ name: "newConfirmPassword", value: this.state.newConfirmPassword || "" }}
                                    inputSetting={{ placeholder: this.params.newConfirmPassword.placeholder, ...inputSetting }}
                                    errorMessage={this.validation.errorMessage.newConfirmPassword || ""} />

                                <FormButtonRecord onClick={this.handleChangePassword}>変更</FormButtonRecord>
                            </div>
                        </div>
                    </div>
                </form >
            </BaseGrid>
        );
    }
}

const mapStateToProps = state => {
    return { login: state.loginStatus };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(loginActions, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordChange));
