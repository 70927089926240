import React from "react";
import { Link } from "react-router-dom";
import * as dateUtil from "util/dateUtil";
import Validation from "util/validate";
import { CONTRACT_EDIT_INFO_FRK } from "../../../../../../../constants/contractEditPageInfo";
import { SEND_OTHER_PAPER } from "../../../../../../../constants/messages";
import InputCheckbox from "../../input/inputCheckbox";
import Input from "../../input/inputNormal";
import Select from "../../input/inputSelect";
import InputTextRemark from "../../input/inputTextRemark";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 区分所有健物 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020201",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    handleTo0101 = e => {
        e.preventDefault();
        // リロードをかける
        this.props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0101.id);
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 文字数制限定義
        const JYU00147LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00147",
                this.props.pageState.JYU00147);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        let building = undefined;
        let floor = undefined;
        let room = undefined;

        if (this.props.pageState.TOR00300) {
            building = "棟";
        }
        if (this.props.pageState.TOR00301) {
            floor = "階";
        }
        if (this.props.pageState.TOR00302) {
            room = "号室";
        }

        return (
            <React.Fragment>
                <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">区分所有建物</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title is-emphasis" colSpan="2">一棟の建物の表示</th>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">建物の名称</th>
                                    <td className="dataTable-body">
                                        {this.props.pageState.TOR00205}　{this.props.pageState.TOR00300}{building}　{this.props.pageState.TOR00301}{floor}　{this.props.pageState.TOR00302}{room}
                                        <br />{this.props.pageState.TOR00356}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住居表示</th>
                                    <td className="dataTable-body">{this.props.pageState.TOR00206}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在</th>
                                    <td className="dataTable-body">{this.props.pageState.TOR00207}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">構造</th>
                                    <td className="dataTable-body">{this.props.pageState.TOR00208}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">延床面積</th>
                                    <td className="dataTable-body">{this.props.pageState.TOR00209}m<sup>2</sup></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title is-emphasis" colSpan="4">専有部分の建物の表示</th>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">家屋番号</th>
                                    <td className="dataTable-body dataTable-body__w355">{this.props.pageState.TOR00210}</td>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">建物の名称</th>
                                    <td className="dataTable-body dataTable-body__w355">{this.props.pageState.TOR00211}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">種類</th>
                                    <td className="dataTable-body dataTable-body__w355">{this.props.pageState.TOR00212}</td>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">構造</th>
                                    <td className="dataTable-body dataTable-body__w355">{this.props.pageState.TOR00213}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">床面積</th>
                                    <td className="dataTable-body" colSpan="3">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-text">{this.props.pageState.TOR00214}階部分</div>
                                            <div className="dataTableUnit-text">登記簿</div>
                                            <div className="dataTableUnit-text">{this.props.pageState.TOR00215}m<sup>2</sup></div>
                                            <div className="dataTableUnit-text">壁芯</div>
                                            <div className="dataTableUnit-text">{this.props.pageState.TOR00216}m<sup>2</sup></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__center">建築時期</th>
                                    <td className="dataTable-body" colSpan="3">
                                        <div className="dataTableUnit">
                                            <Select id="ID_TOR00306" name="TOR00306" value={this.props.pageState.TOR00306} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} />
                                            <Select id="ID_TOR00307" name="TOR00307" value={this.props.pageState.TOR00307} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} />
                                            <div className="dataTableUnit-text">年</div>
                                            <Select id="ID_TOR00308" name="TOR00308" value={this.props.pageState.TOR00308} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} />
                                            <div className="dataTableUnit-text">月</div>
                                            <div className="dataTableUnit-text">新築</div>
                                            <Select id="ID_TOR00309" name="TOR00309" value={this.props.pageState.TOR00309} items={this.getSelectbox(this.props.selectboxData.MEI00012)} {...params} length="w120" />
                                            <Input id="ID_TOR00310" name="TOR00310" value={this.props.pageState.TOR00310} {...params} length="wFlexFull" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__center" rowSpan="2">付属建物</th>
                                    <td className="dataTable-body" colSpan="4">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-input">
                                                <ul className="l-checkBox">
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_TOR00311" name="TOR00311" checked={this.props.pageState.TOR00311} {...params} />
                                                            <span className="checkBox-text">有</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_TOR00312" name="TOR00312" checked={this.props.pageState.TOR00312} {...params} />
                                                            <span className="checkBox-text">無</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dataTable-body" colSpan="4">
                                        <Input id="ID_TOR00314" name="TOR00314" value={this.props.pageState.TOR00314} {...params} length="wFull" />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                    <td className="dataTable-body" colSpan="4">
                                        <div className="dataTableTextUnit">
                                            <InputTextRemark id="ID_JYU00147" name="JYU00147" value={this.props.pageState.JYU00147} text={SEND_OTHER_PAPER.JYU00147} placeholder={JYU00147LimitMsg.inputLimitGuide} {...params} />
                                            <p className="LengthOver-error">{JYU00147LimitMsg.inputLimitMsg}</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="l-dataListMore">
                    <Link to="" aria-label="link" className="dataListMore" onClick={this.handleTo0101}>上記内容を修正したい場合は、こちらをクリックしてください</Link>
                </div>
            </React.Fragment>
        );
    }
}