import React from "react";
import BasePagination from "react-js-pagination";

// https://www.npmjs.com/package/react-js-pagination 参照
export default function Pagenation(props) {
    return (
        <BasePagination
            {...props.pageSettings}
            itemsCountPerPage={10} // 1ページに何件表示するか
            pageRangeDisplayed={5} // ページネーションで表示するページ数
            firstPageText="«" prevPageText="❮" nextPageText="❯" endPageText="»"
            innerClass="l-pagenation l-pagenation--space"
            linkClass="pagenation" activeLinkClass="is-active"
            linkClassFirst="pagenation--first" linkClassPrev="pagenation--prev"
            linkClassNext="pagenation--next" linkClassLast="pagenation--end"
        />
    );
}
