import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { CONTRACT_EDIT_INFO } from "constants/contractEditPageInfo";
import React from "react";
import { Link } from "react-router-dom";
import Validation from "util/validate";

/** 土地 */
export default class Block4 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030104",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    handleTo0101 = e => {
        e.preventDefault();
        // リロードをかける
        this.props.pageSwitch(CONTRACT_EDIT_INFO.PAGE_0101.id);
    }

    render() {
        const { hideBlockClass } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        const itemsDOM = this.createDOMTOR00237_ARR(this.props.pageState);

        return (
            <React.Fragment>
                <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">土地</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                {itemsDOM}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="l-dataListMore">
                    <Link to="" aria-label="link" className="dataListMore" onClick={this.handleTo0101}>上記内容を修正したい場合は、こちらをクリックしてください</Link>
                </div>
            </React.Fragment>
        );
    }


    createDOMTOR00237_ARR = pageState => {

        const arrays = pageState.TOR00237_ARR;

        if (!arrays) {
            return null;
        }

        const arraysDOM = arrays.map((item, idx) => {

            const tibanDisp = idx === 0 ? "地番（代表）" : "地番";

            return (
                <React.Fragment key={idx}>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="5">{idx + 1}</th>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在</th>
                        <td className="dataTable-body">{item.TOR00237}</td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">{tibanDisp}</th>
                        <td className="dataTable-body">{item.TOR00238}</td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">地目</th>
                        <td className="dataTable-body">{item.TOR00240}</td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">地積</th>
                        <td className="dataTable-body">公簿　{item.TOR00241}m<sup>2</sup></td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">持分</th>
                        <td className="dataTable-body">{item.TOR00243}</td>
                    </tr>
                </React.Fragment>
            );
        });

        return (
            <React.Fragment>
                {arraysDOM}
                <tr>
                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">合計</th>
                    <td className="dataTable-body" colSpan="4">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-moretext">{pageState.TOR00256}</div>
                            <div className="dataTableUnit-moretext">筆</div>
                            <div className="dataTableUnit-moretext">地積（登記簿）{pageState.TOR00257}m<sup>2</sup></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">借地面積</th>
                    <td className="dataTable-body" colSpan="4">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-moretext">{pageState.JYU00295}m<sup>2</sup></div>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}