import { EMPLOYEE_POPUP as EP, GUARANTEE_ASSOCIATION_POPUP as GA, ORGANIZATION_POPUP as OP } from "constants/contractEditPageInfo";
import React from "react";
import { FORMAT_JUDGE_INFO, isFormatJudge } from "util/businessRules";
import Validation from "util/validate";
import InputCheckbox from "../../input/inputCheckbox";
import InputDate from "../../input/inputDate";
import InputEmployee from "../../input/inputEmployee";
import InputGua from "../../input/inputGua";
import Input from "../../input/inputNormal";
import Select from "../../input/inputSelect";
import InputTextOrg from "../../input/inputTextOrg";
import InputTextRemark from "../../input/inputTextRemark";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 自社情報 */
export default class Block4 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020104",
        };
        // アップロードするファイル名// 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 文字数制限定義
        const JYU00011LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00011",
                this.props.pageState.JYU00011);
        const JYU00016LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00016",
                this.props.pageState.JYU00016);
        const JYU00017LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00017",
                this.props.pageState.JYU00017);
        const JYU00023LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00023",
                this.props.pageState.JYU00023);

        // 契約種別が売主宅建業者の場合、JYU00007を非活性にする
        const disabledJYU00007 = isFormatJudge(this.props.contractTypeId, FORMAT_JUDGE_INFO.USER.TRAD);

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title is-emphasis">自社情報</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis">取引態様</td>
                            </tr>
                            <tr>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00003" name="JYU00003" checked={this.props.pageState.JYU00003} {...params} />
                                                <span className="checkBox-text">売買</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00004" name="JYU00004" checked={this.props.pageState.JYU00004} {...params} />
                                                <span className="checkBox-text">交換</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00005" name="JYU00005" checked={this.props.pageState.JYU00005} {...params} />
                                                <span className="checkBox-text">媒介</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00006" name="JYU00006" checked={this.props.pageState.JYU00006} {...params} />
                                                <span className="checkBox-text">代理</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00007" name="JYU00007" checked={this.props.pageState.JYU00007} {...params} disabled={disabledJYU00007} />
                                                <span className="checkBox-text">売主</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="4">宅地建物取引業者</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">免許証番号</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00009" name="JYU00009" value={this.props.pageState.JYU00009} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">免許年月日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_JYU00010" name="JYU00010" value={this.props.pageState.JYU00010} {...params} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">主たる事務所<br />の所在地</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope disableReturn id="ID_JYU00011" name="JYU00011" value={this.props.pageState.JYU00011} placeholder={JYU00011LimitMsg.inputLimitGuide} {...params} height="h92" />
                                        <p className="LengthOver-error">{JYU00011LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">商号</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00013" name="JYU00013" value={this.props.pageState.JYU00013} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">代表者氏名</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00014" name="JYU00014" value={this.props.pageState.JYU00014} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">従たる事務所</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope disableReturn id="ID_JYU00016" name="JYU00016" value={this.props.pageState.JYU00016} placeholder={JYU00016LimitMsg.inputLimitGuide} {...params} height="h84" />
                                        <p className="LengthOver-error">{JYU00016LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">部署名</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope disableReturn id="ID_JYU00017" name="JYU00017" value={this.props.pageState.JYU00017} placeholder={JYU00017LimitMsg.inputLimitGuide} {...params} height="h84" />
                                        <p className="LengthOver-error">{JYU00017LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">責任者<br />（役職・氏名）</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00019" name="JYU00019" value={this.props.pageState.JYU00019} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="4">説明をする宅地建物取引士</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">登録番号</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">(</div>
                                        <Select id="ID_JYU01746" name="JYU01746" value={this.props.pageState.JYU01746} items={this.getSelectbox(this.genBuildingTraderAreaList())} {...params} length="w120" />
                                        <div className="dataTableUnit-text">) 第</div>
                                        <InputEmployee id="ID_JYU00020" name="JYU00020" value={this.props.pageState.JYU00020} {...params} from={EP.EMP00006} suffix="号" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">氏名</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputEmployee id="ID_JYU00021" name="JYU00021" value={this.props.pageState.JYU00021} {...params} from={EP.EMP00001} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">業務に従事<br />する事務所名</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextOrg id="ID_JYU00023" name="JYU00023" value={this.props.pageState.JYU00023} placeholder={JYU00023LimitMsg.inputLimitGuide} {...params} height="h84" from={OP.SIT00002} />
                                        <p className="LengthOver-error">{JYU00023LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">電話番号</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00025" name="JYU00025" value={this.props.pageState.JYU00025} {...params} length="w135" from={OP.SIT00003} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="4">営業保証金供託所</td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">（1）営業保証金供託所</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">供託所</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00027" name="JYU00027" value={this.props.pageState.JYU00027} {...params} from={GA.HOS00001} />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在地</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00028" name="JYU00028" value={this.props.pageState.JYU00028} {...params} from={GA.HOS00002} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}