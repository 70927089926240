import axios from "axios";
import BaseGrid from "components/common/frame/baseGrid";
import { RegisterButtonRecord } from "components/common/parts/buttonRecord";
import { InputRadioBoxRecord, InputSelectBoxRecord, InputTextareaRecord, InputTextRecord } from "components/common/parts/inputRecord";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { withRouter } from "react-router";
import * as apiParamConverter from "util/apiParamConverter";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";
import Validation from "util/validate";

/** 自社情報メンテナンス画面 */
class MyCompanyInfoMaintenance extends React.Component {
    ERANAME_TYPE_01 = "01" // 印刷時元号: 和暦・元年
    ERANAME_TYPE_02 = "02" // 印刷時元号: 和暦・1年
    ERANAME_TYPE_03 = "03" // 印刷時元号: 西暦

    TAX_ROUNDING_METHOD_01 = "01" // 消費税端数計算: 切り捨て
    TAX_ROUNDING_METHOD_02 = "02" // 消費税端数計算: 切り上げ
    TAX_ROUNDING_METHOD_03 = "03" // 消費税端数計算: 四捨五入

    消費税端数計算

    constructor(props) {
        super(props);
        this.state = {
            eraNameOnPrint: undefined, // 01: 和暦・元年, 02: 和暦・1年, 03: 西暦
        };
        this.apiCalling = false; // APIコール中かどうか true: コール中
        this.reader = new FileReader(); // ファイルアップロード用のリーダー
        this.uploadFileName = undefined; // 選択されたファイル名

        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();

        // 画面から入力される値の情報
        this.params = {
            companyName: {
                label: "法人名",
                apiParamKey: "company_name"
            },
            tradeName: {
                label: "商号名称",
                validate: this.validation.notEmpty,
                apiParamKey: "trade_name",
            },
            agencyLicenseNumber: {
                label: "免許証番号",
                validate: this.validation.notEmpty,
                apiParamKey: "agency_license_number"
            },
            licenseOn: {
                label: "免許年月日",
                validate: this.validation.notEmptyAndIsDate,
                apiParamKey: "license_on"
            },
            representativeName: {
                label: (<span>代表者氏名<br />（役職、氏名）</span>),
                validate: this.validation.notEmpty,
                apiParamKey: "representative_name"
            },
            address: {
                label: "住所",
                validate: this.validation.notEmpty,
                apiParamKey: "address"
            },
            logoVersion: {
                apiParamKey: "logo_version"
            },
            eraNameOnPrint: {
                apiParamKey: "era_name_on_print"
            },
            currencySymbol: {
                label: "金額前記号",
                validate: this.validation.notEmpty,
                apiParamKey: "currency_symbol"
            },
            taxRoundingMethod: {
                label: "計算方法",
                validate: this.validation.notEmpty,
                apiParamKey: "tax_rounding_method"
            },
            digitsId: {
                label: "桁数",
                validate: this.validation.notEmpty,
                apiParamKey: "digits_id"
            },
            permittedIpAddress: {
                label: "IPアドレス",
                validate: this.validation.validateIpAddressTextArea,
                apiParamKey: "permitted_ip_address"
            }
        };
    }

    componentDidMount() {
        document.title = pageInfo.MY_COMPANY_INFO_MAINTENANCE.title;
        this.requestConsumptionTaxFractionDigits();
    }

    componentDidUpdate() {
        if (!this.state.companyName) {
            this.requestMyCompanyInfo();
        }
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputText = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputEra = e => {
        const target = e.target;
        this.setState({ eraNameOnPrint: target.value });
    }

    /** 入力ボックスの入力値を空文字チェック */
    handleValidateInputText = e => {
        const target = e.target;
        const param = this.params[target.name];
        param.validate(target.name, target.value);
        this.setState({});  // 再renderする為の空セット
    }

    /** 免許年月日の入力値チェック */
    handleOnBlurLicencOn = e => {
        const target = e.target;
        const param = this.params[target.name];
        param.validate(target.name, target.value);
        let stateUpdate = {}; // setState用

        // バリデートエラーがない場合、yyyy/mm/dd形式へ日付にフォーマットをかける
        if (!this.validation.errorMessage.licenseOn) {
            const val = new Date(target.value);
            const dateFmted = val.getFullYear() + "/" + ("0" + (val.getMonth() + 1)).slice(-2) + "/" + ("0" + val.getDate()).slice(-2);
            stateUpdate.licenseOn = dateFmted;
        }
        this.setState(stateUpdate);
    }

    /** 登録ボタンのハンドラ */
    handleRegister = e => {
        e.preventDefault();

        if (!this.state.logoPreSignedUrl && !this.uploadFileName) {
            alert("ロゴを選択してください。");
            return;
        }

        // 全入力項目のチェックし、エラーメッセージがない場合、後続処理（登録APIの呼出し）をする
        if (!this.validation.validateTotalInputText(this.params, this.state)) {

            let requestBody = apiParamConverter.convertToApiReqCase(this.params, this.state);
            delete requestBody.company_name;
            this.requestPutMyCompanyInfo(requestBody);

        } else {
            this.setState({});
        }
    }

    handleLogo = e => {
        const target = e.target;

        // APIコール失敗→ファイル選択→キャンセル押下でundefinedがくるのでその対応
        if (target === undefined) { return; }
        // ファイルアップロード成功→再度、ファイル選択→キャンセル押下でtargetはあるけどfilesが無いでくるのでその対応
        if (target.files.length === 0) { return; }

        this.uploadFileName = target.files[0].name;
        this.reader.readAsArrayBuffer(target.files[0]);
        // ファイルの読み込みが完了した時に発火されるイベント用のリスナーを登録
        const event = e => {
            this.reader.removeEventListener('load', event);
            this.setState({});
        };
        this.reader.addEventListener('load', event);
    }

    /** 印刷時元号で 和暦 が押された時専用 */
    handleEraSelect = e => {
        this.setState({ eraNameOnPrint: this.ERANAME_TYPE_01 });
    }

    render() {
        if (!this.state.companyName) {
            return null;
        }

        // 消費税端数桁数一覧をコンポーネントに渡す用に加工
        const consumptionTaxFractionDigits = this.state.consumptionTaxFractionDigits;
        const consumptionTaxFractionDigitsOptions = consumptionTaxFractionDigits
            ? consumptionTaxFractionDigits.map(data => ({ value: data.digitsId, display: data.digitsDisplayName }))
            : [];

        // Inputレコードに関する、タグの属性情報
        const titleSetting = { className: "dataTable-title dataTable-title__w120" };
        const bodySetting3 = { colSpan: "3" };
        const bodySetting2 = { colSpan: "2" };
        const inputSetting = { onChange: this.handleChangeInputText, onBlur: this.handleValidateInputText };
        const inputSettingLicenceOn = { onChange: this.handleChangeInputText, onBlur: this.handleOnBlurLicencOn };
        const inputTextareaSetting = { cols: "30", rows: "10" };

        // 印刷時元号のラジオボタンのチェック制御（左から、和暦、元年、1年、西暦）
        let eraChecked = [false, false, false, false,];
        if (this.state.eraNameOnPrint === this.ERANAME_TYPE_01) { eraChecked = [true, true, false, false,]; }
        if (this.state.eraNameOnPrint === this.ERANAME_TYPE_02) { eraChecked = [true, false, true, false,]; }
        if (this.state.eraNameOnPrint === this.ERANAME_TYPE_03) { eraChecked = [false, false, false, true,]; }

        // ロゴ画像が登録済みの場合、表示する
        const serverLogoDOM = this.state.logoPreSignedUrl ? <img src={this.state.logoPreSignedUrl} style={{ maxHeight: "150px" }} alt="" /> : null;

        // ロゴ画像が選択された場合、そちらを優先して表示する
        let encodedData = null;
        if (this.reader.result) {
            /// Base64変換を小分けにする
            const APPLY_MAX = 1024;
            let encodedStr = "";
            for (var i = 0; i < this.reader.result.byteLength; i += APPLY_MAX) {
                encodedStr += String.fromCharCode.apply(null, new Uint8Array(this.reader.result.slice(i, i + APPLY_MAX)));
            }
            encodedData = window.btoa(encodedStr);
        }
        const selectedLogoDOM = this.reader.result ? <img src={`data:image/*;base64,${encodedData}`} style={{ maxHeight: "150px" }} alt="" /> : null;
        const logoDOM = selectedLogoDOM ? selectedLogoDOM : serverLogoDOM;

        return (
            <BaseGrid>
                <ul className="l-dataList">
                    <li>
                        <div className="dataList dataList__accordion is-open">
                            <div className="dataList-header">自社情報メンテナンス</div>
                            <div className="dataList-body">
                                <table className="dataTable">
                                    <tbody>
                                        <tr>
                                            <InputTextRecord
                                                textOnly
                                                titleName={this.params.companyName.label}
                                                inputData={{ name: "companyName", value: this.state.companyName || "" }}
                                                titleSetting={titleSetting} bodySetting={bodySetting3} inputSetting={inputSetting} />
                                        </tr>

                                        <tr>
                                            <InputTextareaRecord titleName={this.params.tradeName.label}
                                                inputData={{ name: "tradeName", value: this.state.tradeName || "" }}
                                                titleSetting={titleSetting} bodySetting={bodySetting3}
                                                inputSetting={{ ...inputSetting, ...inputTextareaSetting, className: "textarea__h165" }}
                                                errorMessage={this.validation.errorMessage.tradeName || ""} />
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="dataTable">
                                    <tbody>
                                        <tr>
                                            <th className="dataTable-title is-emphasis" colSpan="4">宅地建物取引業者</th>
                                        </tr>

                                        <tr>
                                            <InputTextRecord titleName={this.params.agencyLicenseNumber.label}
                                                inputData={{ name: "agencyLicenseNumber", value: this.state.agencyLicenseNumber || "" }}
                                                titleSetting={titleSetting} bodySetting={null} inputSetting={inputSetting}
                                                errorMessage={this.validation.errorMessage.agencyLicenseNumber || ""} />

                                            <InputTextRecord titleName={this.params.licenseOn.label}
                                                inputData={{ name: "licenseOn", value: this.state.licenseOn || "" }}
                                                titleSetting={titleSetting} bodySetting={null} inputSetting={{ ...inputSettingLicenceOn, placeholder: "例：2020/01/01" }}
                                                errorMessage={this.validation.errorMessage.licenseOn || ""} />
                                        </tr>

                                        <tr>
                                            <InputTextRecord titleName={this.params.representativeName.label}
                                                inputData={{ name: "representativeName", value: this.state.representativeName || "" }}
                                                titleSetting={titleSetting} bodySetting={bodySetting3} inputSetting={inputSetting}
                                                errorMessage={this.validation.errorMessage.representativeName || ""} />
                                        </tr>

                                        <tr>
                                            <InputTextareaRecord titleName={this.params.address.label}
                                                inputData={{ name: "address", value: this.state.address || "" }}
                                                titleSetting={titleSetting} bodySetting={bodySetting3}
                                                inputSetting={{ ...inputSetting, ...inputTextareaSetting }}
                                                errorMessage={this.validation.errorMessage.address || ""} />
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="dataList dataList__accordion is-open">
                            <div className="dataList-header">契約情報 基本設定</div>
                            <div className="dataList-body">
                                <table className="dataTable">
                                    <tbody>
                                        <tr>
                                            <th className="dataTable-title dataTable-title__w120">ロゴイメージ</th>
                                            <td className="dataTable-body">
                                                <div className="dataTableUnit">
                                                    <div className="dataTableUnit-object">
                                                        {logoDOM}
                                                    </div>
                                                    <div className="dataTableUnit-action">
                                                        <label>
                                                            <input type="file" aria-label="link" accept=".png,.jpg" onChange={this.handleLogo} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th className="dataTable-title dataTable-title__w120">印刷時元号</th>
                                            <td colSpan="2" className="dataTable-body">
                                                <div className="eraSelect">
                                                    <div className="eraSelect-list">
                                                        <ul className="l-radioBox">
                                                            <li>
                                                                <label className="radioBox">
                                                                    <input type="radio" name="b1" className="radioBox-input" checked={eraChecked[0]} onChange={this.handleEraSelect} />
                                                                    <span className="radioBox-text">和暦</span>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="eraSelect-deco">（</div>
                                                    <div className="eraSelect-list">
                                                        <ul className="l-radioBox">
                                                            <li>
                                                                <label className="radioBox">
                                                                    <input type="radio" name="b2" className="radioBox-input" id="era-gengou" value={this.ERANAME_TYPE_01} checked={eraChecked[1]} onChange={this.handleChangeInputEra} />
                                                                    <span className="radioBox-text">元年</span>
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="radioBox">
                                                                    <input type="radio" name="b2" className="radioBox-input" id="era-number" value={this.ERANAME_TYPE_02} checked={eraChecked[2]} onChange={this.handleChangeInputEra} />
                                                                    <span className="radioBox-text">1年</span>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="eraSelect-deco">）</div>
                                                    <div className="eraSelect-list">
                                                        <ul className="l-radioBox">
                                                            <li>
                                                                <label className="radioBox">
                                                                    <input type="radio" name="b1" className="radioBox-input" value={this.ERANAME_TYPE_03} checked={eraChecked[3]} onChange={this.handleChangeInputEra} />
                                                                    <span className="radioBox-text">西暦</span>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <InputTextRecord titleName={this.params.currencySymbol.label}
                                                inputData={{ name: "currencySymbol", value: this.state.currencySymbol || "" }}
                                                titleSetting={titleSetting} bodySetting={bodySetting2}
                                                inputSetting={{ ...inputSetting, className: "input__w120" }}
                                                errorMessage={this.validation.errorMessage.currencySymbol || ""} lengthForErrMsg="w120"
                                            />
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="dataTable">
                                    <tbody>
                                        <tr>
                                            <th className="dataTable-title is-emphasis" colSpan="2">消費税端数計算</th>
                                        </tr>

                                        <tr>
                                            <InputRadioBoxRecord titleName={this.params.taxRoundingMethod.label}
                                                inputData={{ name: "taxRoundingMethod", value: this.state.taxRoundingMethod }}
                                                radioDataList={[
                                                    { value: this.TAX_ROUNDING_METHOD_01, display: "切り捨て" },
                                                    { value: this.TAX_ROUNDING_METHOD_02, display: "切り上げ" },
                                                    { value: this.TAX_ROUNDING_METHOD_03, display: "四捨五入" }
                                                ]}
                                                titleSetting={titleSetting} bodySetting={bodySetting2} inputSetting={inputSetting}
                                                errorMessage={this.validation.errorMessage.taxRoundingMethod || ""}
                                            />
                                        </tr>

                                        <tr>
                                            <InputSelectBoxRecord titleName={this.params.digitsId.label}
                                                inputData={{ name: "digitsId", value: this.state.digitsId || "" }}
                                                optionsDataList={consumptionTaxFractionDigitsOptions}
                                                titleSetting={titleSetting} bodySetting={bodySetting2} inputSetting={inputSetting}
                                                errorMessage={this.validation.errorMessage.digitsId || ""}
                                            />
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="dataList dataList__accordion is-open">
                            <div className="dataList-header">アクセス許可IPアドレス群</div>
                            <div className="dataList-body">
                                <table className="dataTable">
                                    <tbody>
                                        <tr>
                                            <InputTextareaRecord titleName={this.params.permittedIpAddress.label}
                                                inputData={{ name: "permittedIpAddress", value: this.state.permittedIpAddress || "" }}
                                                titleSetting={titleSetting} bodySetting={bodySetting2}
                                                inputSetting={{ ...inputSetting, ...inputTextareaSetting }}
                                                errorMessage={this.validation.errorMessage.permittedIpAddress || ""} />
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </li>
                </ul>

                <RegisterButtonRecord inActive={this.apiCalling} registerSetting={{ onClick: this.handleRegister }} />
            </BaseGrid>
        );
    }

    /** 消費税端数桁数一覧を取得し、stateを更新する */
    requestConsumptionTaxFractionDigits = () => {
        awsReq.get(
            AWS.ENDPOINT.PATHS.CONSUMPTION_TAX_FRACTION_DIGITS,
            response => {
                const list = convert.convertToFlatCamelCase(response.data);
                this.setState({ consumptionTaxFractionDigits: list });
            },
            awsReq.defaultErrorHandler,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    /** 自社情報を取得し、stateを更新する */
    requestMyCompanyInfo = () => {
        this.apiCalling = true;
        awsReq.get(
            AWS.ENDPOINT.PATHS.MY_COMPANY,
            response => {
                this.apiCalling = false;
                let res = convert.convertToFlatCamelCase(response.data);
                res.eraNameOnPrint = res.eraNameOnPrint || this.ERANAME_TYPE_01; // 元号のデフォルト値を01（和暦・元号）にする
                res.taxRoundingMethod = res.taxRoundingMethod || this.TAX_ROUNDING_METHOD_01; // 消費税端数計算のデフォルト値を "切り捨て"にする
                res.digitsId = res.digitsId || this.state.consumptionTaxFractionDigits[0].digitsId; // 桁数のデフォルト値を設定する
                res.licenseOn = res.licenseOn && res.licenseOn.replace(/-/g, "/"); // 免許年月日が返ってくる場合（自社情報の初回更新があるまでは値がない）、2020-01-01になっているので2020/01/01へ置換する
                res.permittedIpAddress = res.permittedIpAddress && res.permittedIpAddress.replace(/,/g, '\n'); // カンマを改行に置換する
                this.setState(res);
            },
            error => {
                alert(ERROR_MESSAGE.SERVER_ERROR);
                this.apiCalling = false;
                this.setState({});
            },
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    requestPutMyCompanyInfo = async requestBody => {
        this.apiCalling = true;
        this.setState({});

        // ファイル名が選択された場合、アップロード処理を行う
        if (this.uploadFileName) {
            const res = await awsReq.get(AWS.ENDPOINT.PATHS.GET_LOGO_IMAGE_UPLOAD_URL,
                res => { return res.data; },
                error => {
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                    this.apiCalling = false;
                    this.setState({});
                    return;
                },
                { logo_image_name: this.uploadFileName },
                {}, AWS.ENDPOINT.REST_API.NAME,
            );

            // 地図画像アップロード用の署名付きURLへ地図ファイルのアップロード
            try {
                await axios({
                    method: "PUT",
                    url: res["pre_signed_url"],
                    headers: {
                        "Content-Type": "image/*", // アップロードしたいファイルに合わせる
                    },
                    data: this.reader.result
                });
            } catch (error) {
                // エラーの場合処理を継続しない
                alert(ERROR_MESSAGE.SERVER_ERROR);
                this.apiCalling = false;
                this.setState({});
                return;
            }
            requestBody.logo_version = res.version;
        }

        // 免許年月日を2020/01/01の形式で保持しているので、APIのIFに合わせて2020-01-01へ置換する
        requestBody.license_on = this.state.licenseOn.replace(/\//g, "-");

        // IPアドレスの入力がある場合、csv形式に変換する
        requestBody.permitted_ip_address = requestBody.permitted_ip_address === undefined || requestBody.permitted_ip_address === ""
            ? undefined // 入力無しはundefined
            : requestBody.permitted_ip_address
                .split("\n")                           // 文字列を\n区切りで配列に変換する
                .filter(val => { return val !== ""; }) // 空行部分（値が""の要素）を除去する
                .join(',');                            // 配列をカンマ区切りの文字列に戻す

        await awsReq.put(AWS.ENDPOINT.PATHS.MY_COMPANY,
            res => {
                this.uploadFileName = undefined;
                this.setState({ companyName: undefined }); // リロードをかける
            },
            error => {
                alert(ERROR_MESSAGE.SERVER_ERROR);
                this.apiCalling = false;
                this.setState({});
                return;
            },
            requestBody,
            {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }
}

export default withRouter(MyCompanyInfoMaintenance);
