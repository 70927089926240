import { Auth } from "aws-amplify";
import BaseGrid from "components/common/frame/baseGrid";
import { FormLinkRecord } from "components/common/parts/formRecord";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import * as loginActions from "../../../../redux/actions/login";

/** パスワード変更完了画面 */
class PasswordChangeComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        document.title = pageInfo.PASSWORD_CHANGE_COMPLETE.title;
        Auth.signOut({ global: true });
    }

    render() {
        return (
            <BaseGrid>
                <main>
                    <form>
                        <div className="l-loginField l-loginField--menu">
                            <div className="loginField">
                                <div className="loginField-read">パスワードが変更されました。<br />ログインしなおしてください。</div>
                                <FormLinkRecord isCenter to={pageInfo.LOGIN.path}>ログイン画面へ戻る</FormLinkRecord>
                            </div>
                        </div>
                    </form >
                </main>
            </BaseGrid>
        );
    }
}

const mapStateToProps = state => {
    return { login: state.loginStatus };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(loginActions, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordChangeComplete));
