import { PageBase } from "components/page/user/contractFrk/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";

class ImpPage5 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     */
    getPageItems = () => {
        return {
            JYU00596: false, // 土地区画整理法に基づく制限 有
            JYU01377: false, // 土地区画整理法に基づく制限 無
            JYU00597: false, // 土地区画整理事業 計画有
            JYU00598: false, // 土地区画整理事業 施行中
            JYU00599: false, // 土地区画整理事業 仮換地
            JYU00600: false, // 土地区画整理事業 換地
            JYU00601: false, // 土地区画整理事業 保留地
            JYU00602: undefined, // 土地区画整理事業 名称
            JYU00603: false, // 仮換地指定 未
            JYU00604: false, // 仮換地指定 済
            JYU00605: undefined, // 仮換地指定 日付
            JYU00606: undefined, // 仮換地指定 号数
            JYU00607: undefined, // （仮）換地・保留地の街区番号等
            JYU00608: undefined, // 換地処分の公告（予定）
            JYU00609: false, // 仮換地図等 有
            JYU01378: false, // 仮換地図等 無
            JYU00610: false, // 清算金の徴収 有
            JYU01379: false, // 清算金の徴収 無
            JYU01380: false, // 清算金の徴収 未定
            JYU00611: false, // 清算金の徴収 有 徴収
            JYU00612: false, // 清算金の徴収 有 交付
            JYU00613: false, // 清算金の徴収 金額 未定
            JYU00614: false, // 清算金の徴収 金額 確定
            JYU00615: undefined, // 清算金の徴収 金額 確定 金額
            JYU00616: false, // 清算金の徴収 帰属先 売主
            JYU00617: false, // 清算金の徴収 帰属先 買主
            JYU00623: false, // 建築等の制限 有
            JYU01381: false, // 建築等の制限 無
            JYU01651: undefined, // 建築等の制限　テキスト１
            JYU01652: undefined, // 建築等の制限　テキスト２
            JYU00625: false, // 古都保存法 チェック
            JYU00626: false, // 都市緑地法 チェック
            JYU00627: false, // 生産緑地法 チェック
            JYU00628: false, // 特定空港周辺特別措置法 チェック
            JYU00629: false, // 景観法 チェック
            JYU00630: false, // 大都市地域における住宅 チェック
            JYU00631: false, // 地方拠点都市地域の整備 チェック
            JYU00632: false, // 被災市街地復興特別措置法 チェック
            JYU00633: false, // 新住宅市街地開発法 チェック
            JYU00634: false, // 新都市基盤整備法 チェック
            JYU00635: false, // 旧 市 街 地 改 造 法 チェック
            JYU00636: false, // 首都圏の近郊整備地帯 チェック
            JYU00637: false, // 近畿圏の近郊整備区域 チェック
            JYU00638: false, // 流通業務市街地整備法 チェック
            JYU00639: false, // 都市再開発法 チェック
            JYU00640: false, // 沿道整備法 チェック
            JYU00641: false, // 集落地域整備法 チェック
            JYU00642: false, // 密集市街地における防災 チェック
            JYU00643: false, // 地域における歴史的風致街区の整備の促進 チェック
            JYU00644: false, // 港湾法 チェック
            JYU00645: false, // 住宅地区改良法 チェック
            JYU00646: false, // 公有地拡大推進法 チェック
            JYU00647: false, // 農地法 チェック
            JYU00648: false, // 宅地造成等規制法 チェック
            JYU00649: false, // マンションの建替え等の円滑化に関する法律 チェック
            JYU01770: false, // 長期優良住宅の普及の促進に関する法律　チェック
            JYU00650: false, // 都市公園法 チェック
            JYU00651: false, // 自然公園法 チェック
            JYU00652: false, // 首都圏近郊緑地保全法 チェック
            JYU00653: false, // 近畿圏の保全区域の整備に関する法律 チェック
            JYU00654: false, // 都市の低炭素化の促進に関する法律 チェック
            JYU00655: false, // 水防法 チェック
            JYU00656: false, // 下水道法 チェック
            JYU00657: false, // 河川法 チェック
            JYU00658: false, // 特定都市河川浸水被害対策法 チェック
            JYU00659: false, // 海岸法 チェック
            JYU00660: false, // 津波防災地域づくりに関する法律 チェック
            JYU00661: false, // 砂防法 チェック
            JYU00662: false, // 地すべり等防止法 チェック
            JYU00663: false, // 急傾斜地法 チェック
            JYU00664: false, // 土砂災害防止対策推進法 チェック
            JYU00665: false, // 森林法 チェック
            JYU00666: false, // 森林経営管理法 チェック
            JYU00667: false, // 道路法 チェック
            JYU01650: false, // 踏切道改良促進法　チェック
            JYU00668: false, // 全国新幹線鉄道整備法 チェック
            JYU00669: false, // 土地収用法 チェック
            JYU00670: false, // 文化財保護法 チェック
            JYU00671: false, // 航空法 チェック
            JYU00672: false, // 国土利用計画法 チェック
            JYU00673: false, // 核原料物質、核燃料物質及び原子炉の規制に関する法律 チェック
            JYU00674: false, // 廃棄物の処理及び清掃に関する法律 チェック
            JYU00675: false, // 土壌汚染対策法 チェック
            JYU00676: false, // 都市再生特別措置法 チェック
            JYU00677: false, // 地域再生法 チェック
            JYU00678: false, // 高齢者、障害者等の移動等の円滑化の促進 チェック
            JYU00679: false, // 災害対策基本法 チェック
            JYU00680: false, // 東日本大震災復興特別区域法 チェック
            JYU00681: false, // 大規模災害からの復興に関する法律 チェック
            JYU01653: false, // その他法令　フリー１　チェック
            JYU01654: undefined, // その他法令　フリー１　内容
            JYU00693: undefined, // 制限の概要
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            contractTypeId: this.props.contractTypeId,  // 契約種別ID
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 都市計画法、建築基準法以外の法令に基づく制限 */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
            </React.Fragment>
        );
    }
}

export default withRouter(ImpPage5);