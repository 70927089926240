import { PageBase } from "components/page/user/contractFrk/edit/parts/pageBase";
import { USE_TYPES } from "constants/defaultInputValueTypes";
import React from "react";
import { withRouter } from "react-router";
import * as AWS from "../../../../../../../constants/aws";
import * as awsReq from "../../../../../../../util/awsRequest";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";
import Block4 from "./block4";

class EstPage3 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     * ※チェックボックスの場合、falseにする
     */
    getPageItems = () => {
        return {
            //代理人の署名による買主／売主の印刷
            TOR00094_ARR: [{
                TOR00094: undefined, // 買主 氏名
            },],
            FUD00386: "印字しない", // 買主　署名欄　印字
            TOR00354: undefined, // 買主　売買契約書　署名欄
            TOR00072_ARR: [{
                TOR00072: undefined, // 売主 氏名
            },],
            FUD00388: "印字しない", // 売主　署名欄　印字
            TOR00351: undefined, // 売主　売買契約書　署名欄

            //売主宅建業者
            //売主（宅地建物取引業者）
            JYU00096: undefined, // 免許証番号
            JYU00098: undefined, // 主たる事務所の所在地
            JYU00100: undefined, // 商号
            JYU00101: undefined, // 代表者氏名
            //宅地建物取引士
            JYU00102: undefined, // 登録番号
            JYU00103: undefined, // 氏名
            FUD00389: undefined, // 契約締結場所

            //宅地建物取引業者・宅地建物取引士（左）
            JYU00009: undefined, // 免許証番号
            JYU00010: undefined, // 免許年月日
            JYU00011: undefined, // 主たる事務所の所在地
            JYU00013: undefined, // 商号
            JYU00014: undefined, // 代表者氏名
            JYU00016: undefined, // 従たる事務所 所在地
            JYU00017: undefined, // 従たる事務所 部署名
            JYU00019: undefined, // 従たる事務所 責任者
            JYU00020: undefined, // 登録番号
            JYU00021: undefined, // 氏名

            //宅地建物取引業者・宅地建物取引士（右）
            JYU00052: undefined, // 免許証番号
            JYU00053: undefined, // 免許年月日
            JYU00054: undefined, // 主たる事務所の所在地
            JYU00056: undefined, // 商号
            JYU00057: undefined, // 代表者氏名
            JYU01768: undefined, // 宅地建物取引士
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [
            //代理人の署名による買主／売主の印刷
            "TOR00094_ARR", "TOR00354", "TOR00072_ARR", "TOR00351",

            //売主宅建業者
            //売主（宅地建物取引業者）
            "JYU00096", "JYU00098", "JYU00100", "JYU00101",
            //宅地建物取引士
            "JYU00102", "JYU00103",

            //宅地建物取引業者・宅地建物取引士（左）
            "JYU00009", "JYU00010", "JYU00011", "JYU00013", "JYU00014", "JYU00016",
            "JYU00017", "JYU00019", "JYU00020", "JYU00021",

            //宅地建物取引業者・宅地建物取引士（右）
            "JYU00052", "JYU00053", "JYU00054", "JYU00056", "JYU00057",
            "JYU01768",
        ];
    }

    /**
     * 初期情報取得
     * ※ハンドラの処理が特有なので個別で設定
     */
    init = () => {

        if (!this.state.apicalling) {
            this.setState({ apicalling: true });
            awsReq.get(
                AWS.ENDPOINT.PATHS.CONTRACTS_PAGE_FRK
                    .replace(":contractId", this.props.match.params.contractId)
                    .replace(":pageId", this.props.match.params.pageId),
                this.initialRequestHandlerSp,
                awsReq.defaultErrorHandler,
                {}, {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }

    initialRequestHandlerSp = res => {

        // 以降、レスポンスをページコンポーネントのstateとして管理するため、setStateするための前処理
        // ※このページ特化
        let responseBody = res.data.input_content;

        // レスポンスボディにTOR00094_ARRがない場合、追加する
        let data = Object.assign({}, responseBody);
        if (data.TOR00094_ARR === undefined) { data.TOR00094_ARR = [{}]; }

        // レスポンスボディにTOR00072_ARRがない場合、追加する
        if (data.TOR00072_ARR === undefined) { data.TOR00072_ARR = [{}]; }

        res.data.input_content = data;

        // 以降、レスポンスをページコンポーネントのstateとして管理するため、setStateするための前処理
        // ※全ページ共通用
        this.initialRequestHandler(res);
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            contractTypeId: this.props.contractTypeId,  // 契約種別ID
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        // 売主宅建業者の表示設定
        let sellerHomeBuilderDOM = undefined;

        if (this.props.useTypeID === USE_TYPES.SELLER_HOME_BUILDER.value) {
            sellerHomeBuilderDOM = (<li><Block2 {...params} /></li>);
        }

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 代理人の署名による買主／売主の印刷 */}
                    {sellerHomeBuilderDOM} {/** 売主宅建業者 */}
                    <li><Block3 {...params} /></li> {/** 宅地建物取引業者・宅地建物取引士（左） */}
                    <li><Block4 {...params} /></li> {/** 宅地建物取引業者・宅地建物取引士（右） */}
                </ul>
            </React.Fragment>
        );
    }
}

export default withRouter(EstPage3);