import React from "react";
import Input from "../../input/inputNormal";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 建物 */
export default class Block8 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010108",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">建物</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住居表示</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00258" name="TOR00258" value={this.props.pageState.TOR00258} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00259" name="TOR00259" value={this.props.pageState.TOR00259} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">家屋番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00260" name="TOR00260" value={this.props.pageState.TOR00260} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">種類</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00261" name="TOR00261" value={this.props.pageState.TOR00261} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">構造</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00262" name="TOR00262" value={this.props.pageState.TOR00262} {...params} length="w329" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">延床面積</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00263" name="TOR00263" value={this.props.pageState.TOR00263} {...params} length="w112" />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}