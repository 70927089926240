import React from "react";
import * as helper from "../helper";
import { InputBase } from "./inputBase";

export default class InputSelect extends InputBase {
    constructor(props) {
        super(props);

        // 通常項目用の初期化、更新ハンドラを設定する
        let init = this.initStandard;
        this.handleUpdate = this.handleUpdateStandard;

        // 動的項目の場合、動的項目用の初期化、更新ハンドラを使用する
        if (this.props.mode === "dynamic") {
            init = this.initDynamic;
            this.handleUpdate = this.handleUpdateDynamic;
        }

        // 初期化
        init();
    }

    /**
     * バリデートロジックを定義する。
     * true: エラー無し false: エラー有り
     **/
    validate = (val) => {
        // 入力無しは許容
        //
        // ※valの状態は下記を前提とする
        // 最初から入力欄が空だった場合はundefined
        // 変更して空にした場合は空文字列
        if (val === undefined) {
            return undefined;
        }

        // 選択式なので常に有効
        return true;
    }

    /** 通常項目用の初期化処理 */
    initStandard = () => {
        // 先祖のstateを更新するのでレンダリングが走る
        let tmp = {};
        tmp[helper.genValidKey(this.props.name)] = this.validate(this.props.value); // 有効化かどうか（true:有効）
        this.props.updatePageState(tmp, false); // 先祖のstateを更新するのでレンダリングが走る
    }

    /** 動的項目用の初期化処理 */
    initDynamic = () => {
        // 先祖から設定された値の検証
        const arrayNameValid = helper.genValidKey(this.props.arrayName);  // 動的項目の代表名（バリデート結果格納用）
        const idx = this.props.idx; // 動的項目のインデックス
        const name = this.props.name; //  実際の操作対象の項目名

        let objValid = Object.assign([], this.props.pageState[arrayNameValid]);
        objValid[idx][name] = this.validate(this.props.value);

        this.props.updatePageState({ [arrayNameValid]: objValid }, false); // 先祖のstateを更新するのでレンダリングが走る
    }

    /** 入力可能な文字列を限定させたい時はここで、入力文字列を限定後の状態へ変換する */
    inputConvert = value => {
        return value;
    }

    /** 通常項目用の入力欄更新時のハンドラ */
    handleUpdateStandard = e => {
        let inputValue = e.target.value;

        // 値の更新（先祖コンポーネントから渡されたstateの更新）
        let tmp = {};
        tmp[this.props.name] = inputValue; // 値
        tmp[helper.genValidKey(this.props.name)] = this.validate(inputValue); // 選択式なので常に有効
        this.props.updatePageState(tmp); // 先祖のstateを更新するのでレンダリングが走る
    }

    /** 通常項目用の入力欄更新時のハンドラ */
    handleUpdateDynamic = e => {
        const inputValue = this.inputConvert(e.target.value);
        const arrayName = this.props.arrayName;
        const arrayNameValid = helper.genValidKey(this.props.arrayName);
        const idx = this.props.idx;
        const name = this.props.name;

        // 更新用の配列をページが保持している値からコピーする
        let obj = Object.assign([], this.props.pageState[arrayName]);

        // 更新用の配列をページが保持している値からコピーする（バリデートチェック用）
        let objValid = Object.assign([], this.props.pageState[arrayNameValid]);

        // 入力された対象を更新
        obj[idx][name] = inputValue;
        objValid[idx][name] = this.validate(inputValue);

        // 先祖のstateを更新するのでレンダリングが走る
        this.props.updatePageState({ [arrayName]: obj, [arrayNameValid]: objValid });
    }

    render() {
        const disabled = this.props.disabled === true ? this.props.disabled : this.isDisabled(this.props.pageState.disableItem);
        let options = null;
        // 消費税の時は専用の渡された値に応じて専用の表示をする
        // 選択された％に応じて消費税を計算する必要があるので、valueを数値として有効なものにしておく必要がある
        if (this.props.type === "consumptionTax") {
            options = this.props.items.map((value, idx) => {
                let disp = null;
                switch (value) {
                    case "": disp = null; break;
                    case "0": disp = "なし"; break;
                    default: disp = `${value}%`; break;
                }
                return <option key={idx} value={value}>{disp}</option>;
            });

        } else {
            options = this.props.items.map((value, idx) => {
                return <option key={idx} value={value}>{value}</option>;
            });
        }

        // 幅の指定
        let className = "selectBox";
        if (this.props.length === "w80") { className += " selectBox__w80"; }
        if (this.props.length === "w95") { className += " selectBox__w95"; }
        if (this.props.length === "w120") { className += " selectBox__w120"; }
        if (this.props.length === "w272") { className += " selectBox__w272"; }

        return (
            <div className="dataTableUnit-input">
                <div className={className}>
                    <select aria-label="select" className={"selectBox-select"} value={this.props.value} onChange={this.handleUpdate} disabled={disabled}>{options}</select>
                </div>
            </div>
        );
    }
}