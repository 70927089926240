import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { CONTRACT_EDIT_INFO } from "constants/contractEditPageInfo";
import React from "react";
import { Link } from "react-router-dom";
import Validation from "util/validate";

/** 建物 */
export default class Block5 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030105",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    handleTo0202 = e => {
        e.preventDefault();
        // リロードをかける
        this.props.pageSwitch(CONTRACT_EDIT_INFO.PAGE_0202.id);
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <React.Fragment>
                <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">建物</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住居表示</th>
                                    <td className="dataTable-body" colSpan="3">{this.props.pageState.TOR00258}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在</th>
                                    <td className="dataTable-body" colSpan="3">{this.props.pageState.TOR00259}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">家屋番号</th>
                                    <td className="dataTable-body dataTable-body__w355">{this.props.pageState.TOR00260}</td>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">種類</th>
                                    <td className="dataTable-body dataTable-body__w355">{this.props.pageState.TOR00261}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">構造</th>
                                    <td className="dataTable-body" colSpan="3">{this.props.pageState.TOR00262}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="3">床面積</th>
                                    <td className="dataTable-body" colSpan="3">
                                        <ul className="l-checkBox">
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox disabled id="ID_JYU00306" name="JYU00306" checked={this.props.pageState.JYU00306} {...params} />
                                                    <span className="checkBox-text">1.登記簿</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox disabled id="ID_JYU00307" name="JYU00307" checked={this.props.pageState.JYU00307} {...params} />
                                                    <span className="checkBox-text">2.現況</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dataTable-body" colSpan="3">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-moretext">1階</div>
                                            <div className="dataTableUnit-moretext">{this.props.pageState.JYU00308}m<sup>2</sup></div>
                                            <div className="dataTableUnit-moretext">2階</div>
                                            <div className="dataTableUnit-moretext">{this.props.pageState.JYU00309}m<sup>2</sup></div>
                                            <div className="dataTableUnit-moretext">テキスト</div>
                                            <div className="dataTableUnit-moretext">{this.props.pageState.JYU00310}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dataTable-body" colSpan="3">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-moretext">{this.props.pageState.JYU00311}</div>
                                            <div className="dataTableUnit-moretext">合計</div>
                                            <div className="dataTableUnit-moretext">{this.props.pageState.JYU00312}m<sup>2</sup></div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="l-dataListMore">
                    <Link to="" aria-label="link" className="dataListMore" onClick={this.handleTo0202}>上記内容を修正したい場合は、こちらをクリックしてください</Link>
                </div>
            </React.Fragment>
        );
    }
}