import React from "react";
import Validation from "util/validate";
import { SEND_OTHER_PAPER } from "../../../../../../../constants/messages";
import InputCheckbox from "../../input/inputCheckbox";
import InputDate from "../../input/inputDate";
import Input from "../../input/inputNormal";
import InputTextRemark from "../../input/inputTextRemark";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 土地（借地権の場合、借地権の対象となるべき土地） */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020301",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
        this.isSame = this.props.pageState.JYU01750;
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 文字数制限定義
        const JYU00371LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00371",
                this.props.pageState.JYU00371);
        const JYU00375LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00375",
                this.props.pageState.JYU00375);

        // 非活性の設定
        let JYU01277_disabled = undefined;
        let JYU01278_disabled = undefined;

        if (this.props.pageState.JYU01277) {
            JYU01277_disabled = true;
        }

        if (this.props.pageState.JYU01278) {
            JYU01278_disabled = true;
        }

        // 土地と建物が一体 の設定
        // 土地と建物が一体 の場合に表示する値
        const LAND_AND_BUILDINGS_INTEGRATED = "敷地権につき建物と一体";

        if (!this.isSame && this.props.pageState.JYU01750) {
            this.isSame = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00361", LAND_AND_BUILDINGS_INTEGRATED));
            this.props.updatePageState(this.genUpdateInformation("JYU00371", LAND_AND_BUILDINGS_INTEGRATED));
            this.props.updatePageState(this.genUpdateInformation("JYU00375", LAND_AND_BUILDINGS_INTEGRATED));
        }

        // チェックが外れたらフラグをfalseにする
        if (this.isSame && !this.props.pageState.JYU01750) {
            this.isSame = false;
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">土地（借地権の場合、借地権の対象となるべき土地）</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableUnit dataTableUnit--rightend">
                                        <InputDate id="ID_JYU00360" name="JYU00360" value={this.props.pageState.JYU00360} {...params} />
                                        <div className="dataTableUnit-text">現在　　</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="3">権利部<br />（甲区）</th>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="2">所有者</th>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住所</th>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU00361" name="JYU00361" value={this.props.pageState.JYU00361} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title">氏名</th>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU00362" name="JYU00362" value={this.props.pageState.JYU00362} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center" colSpan="2">
                                    <div className="dataTableUnit_vr">
                                        <div className="dataTableUnit-text">所有権にかかる<br />権利に関する事項</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox l-checkBox--topspace l-checkBox--center">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00363" name="JYU00363" checked={this.props.pageState.JYU00363} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01277" name="JYU01277" checked={this.props.pageState.JYU01277} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <td className="dataTable-body">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope id="ID_JYU00371" name="JYU00371" cols={30} rows={10} value={this.props.pageState.JYU00371} text={SEND_OTHER_PAPER.JYU00371} placeholder={JYU00371LimitMsg.inputLimitGuide} {...params} disabled={JYU01277_disabled} />
                                        <p className="LengthOver-error">{JYU00371LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center">権利部<br />（乙区）</th>
                                <th className="dataTable-title dataTable-title__center" colSpan="2">
                                    <div className="dataTableUnit_vr">
                                        <div className="dataTableUnit-text">所有権以外の<br />権利に関する事項</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox l-checkBox--topspace l-checkBox--center">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00372" name="JYU00372" checked={this.props.pageState.JYU00372} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01278" name="JYU01278" checked={this.props.pageState.JYU01278} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <td className="dataTable-body">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope id="ID_JYU00375" name="JYU00375" cols={30} rows={10} value={this.props.pageState.JYU00375} text={SEND_OTHER_PAPER.JYU00375} placeholder={JYU00375LimitMsg.inputLimitGuide} {...params} disabled={JYU01278_disabled} />
                                        <p className="LengthOver-error">{JYU00375LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}