export const DISPLAY_TYPES = {
    LAND: {
        value: "01",
        display: "土地"
    },
    LAND_AND_BUILDING: {
        value: "02",
        display: "土地建物"
    },
    SEGMENT_BUILDING: {
        value: "03",
        display: "区分所有建物"
    },
};

export const USE_TYPES = {
    GENERAL: {
        value: "01",
        display: "一般"
    },
    CONSUMER_AGREEMENT: {
        value: "02",
        display: "消費者契約"
    },
    SELLER_HOME_BUILDER: {
        value: "03",
        display: "売主宅建業者"
    },
};

/** ローン情報の定数：ローン特約 */
export const LOAN_SPECIAL_CONTRACT = {
    MEDIATION_YES: {
        value: "有",
        display: "あっせん有"
    },
    MEDIATION_NO: {
        value: "無",
        display: "あっせん無"
    },
};