import InputDate from "components/page/user/contract/edit/input/inputDate";
import Input from "components/page/user/contract/edit/input/inputNormal";
import Select from "components/page/user/contract/edit/input/inputSelect";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";

/** ローン情報 */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010202",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">ローン情報</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="2">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-text">債務超過</div>
                                        <div className="dataTableUnit-input">
                                            <Select id="ID_TOR00022" name="TOR00022" value={this.props.pageState.TOR00022} items={this.getSelectbox(this.props.selectboxData.MEI00001)} {...params} length="w120" />
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">残債務</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00023" name="TOR00023" value={this.props.pageState.TOR00023} {...params} />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="2">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-text">手付預かりの有無</div>
                                        <div className="dataTableUnit-input">
                                            <Select id="ID_TOR00024" name="TOR00024" value={this.props.pageState.TOR00024} items={this.getSelectbox(this.props.selectboxData.MEI00001)} {...params} length="w120" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">預かり理由</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00025" name="TOR00025" value={this.props.pageState.TOR00025} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="2">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-text">ローン特約の有無</div>
                                        <div className="dataTableUnit-input">
                                            <Select id="ID_TOR00030" name="TOR00030" value={this.props.pageState.TOR00030} items={this.getSelectbox(this.props.selectboxData.MEI00001)} {...params} length="w120" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title" colSpan="2">ローン解除期日</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00033" name="TOR00033" value={this.props.pageState.TOR00033} {...params} length="w108" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" rowSpan="2">1</th>
                                <th className="dataTable-title dataTable-title__w120">金融機関名</th>
                                <td className="dataTable-body" colSpan="3">
                                    <Input id="ID_TOR00031" name="TOR00031" value={this.props.pageState.TOR00031} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">承認取得期日</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00032" name="TOR00032" value={this.props.pageState.TOR00032} {...params} length="w108" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" rowSpan="2">2</th>
                                <th className="dataTable-title dataTable-title__w120">金融機関名</th>
                                <td className="dataTable-body" colSpan="3">
                                    <Input id="ID_TOR00268" name="TOR00268" value={this.props.pageState.TOR00268} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">承認取得期日</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00269" name="TOR00269" value={this.props.pageState.TOR00269} {...params} length="w108" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" rowSpan="2">3</th>
                                <th className="dataTable-title dataTable-title__w120">金融機関名</th>
                                <td className="dataTable-body" colSpan="3">
                                    <Input id="ID_TOR00271" name="TOR00271" value={this.props.pageState.TOR00271} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">承認取得期日</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00272" name="TOR00272" value={this.props.pageState.TOR00272} {...params} length="w108" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" rowSpan="2">4</th>
                                <th className="dataTable-title dataTable-title__w120">金融機関名</th>
                                <td className="dataTable-body" colSpan="3">
                                    <Input id="ID_TOR00298" name="TOR00298" value={this.props.pageState.TOR00298} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">承認取得期日</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00299" name="TOR00299" value={this.props.pageState.TOR00299} {...params} length="w108" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="2">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-text">停止条件・解除条件</div>
                                        <Select id="ID_TOR00034" name="TOR00034" value={this.props.pageState.TOR00034} items={this.getSelectbox(this.props.selectboxData.MEI00001)} {...params} length="w120" />
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">条件内容</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00035" name="TOR00035" value={this.props.pageState.TOR00035} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="2">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-text">買い替え特約</div>
                                        <Select id="ID_TOR00037" name="TOR00037" value={this.props.pageState.TOR00037} items={this.getSelectbox(this.props.selectboxData.MEI00001)} {...params} length="w120" />
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">特約内容</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00038" name="TOR00038" value={this.props.pageState.TOR00038} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};
