import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import Input from "components/page/user/contract/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";

/** 付属書類 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021501",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">付属書類(本説明書と共にお渡しする書類には、□欄を黒で塗りつぶしています。)</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01223" name="JYU01223" checked={this.props.pageState.JYU01223} {...params} />
                                        <span className="checkBox-text">1</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01224" name="JYU01224" value={this.props.pageState.JYU01224} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01225" name="JYU01225" checked={this.props.pageState.JYU01225} {...params} />
                                        <span className="checkBox-text">2</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01226" name="JYU01226" value={this.props.pageState.JYU01226} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01227" name="JYU01227" checked={this.props.pageState.JYU01227} {...params} />
                                        <span className="checkBox-text">3</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01228" name="JYU01228" value={this.props.pageState.JYU01228} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01229" name="JYU01229" checked={this.props.pageState.JYU01229} {...params} />
                                        <span className="checkBox-text">4</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01230" name="JYU01230" value={this.props.pageState.JYU01230} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01231" name="JYU01231" checked={this.props.pageState.JYU01231} {...params} />
                                        <span className="checkBox-text">5</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01232" name="JYU01232" value={this.props.pageState.JYU01232} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01233" name="JYU01233" checked={this.props.pageState.JYU01233} {...params} />
                                        <span className="checkBox-text">6</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01234" name="JYU01234" value={this.props.pageState.JYU01234} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01235" name="JYU01235" checked={this.props.pageState.JYU01235} {...params} />
                                        <span className="checkBox-text">7</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01236" name="JYU01236" value={this.props.pageState.JYU01236} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01237" name="JYU01237" checked={this.props.pageState.JYU01237} {...params} />
                                        <span className="checkBox-text">8</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01238" name="JYU01238" value={this.props.pageState.JYU01238} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01239" name="JYU01239" checked={this.props.pageState.JYU01239} {...params} />
                                        <span className="checkBox-text">9</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01240" name="JYU01240" value={this.props.pageState.JYU01240} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01241" name="JYU01241" checked={this.props.pageState.JYU01241} {...params} />
                                        <span className="checkBox-text">10</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01242" name="JYU01242" value={this.props.pageState.JYU01242} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01243" name="JYU01243" checked={this.props.pageState.JYU01243} {...params} />
                                        <span className="checkBox-text">11</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01244" name="JYU01244" value={this.props.pageState.JYU01244} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01245" name="JYU01245" checked={this.props.pageState.JYU01245} {...params} />
                                        <span className="checkBox-text">12</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01246" name="JYU01246" value={this.props.pageState.JYU01246} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01247" name="JYU01247" checked={this.props.pageState.JYU01247} {...params} />
                                        <span className="checkBox-text">13</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01248" name="JYU01248" value={this.props.pageState.JYU01248} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01249" name="JYU01249" checked={this.props.pageState.JYU01249} {...params} />
                                        <span className="checkBox-text">14</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01250" name="JYU01250" value={this.props.pageState.JYU01250} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01251" name="JYU01251" checked={this.props.pageState.JYU01251} {...params} />
                                        <span className="checkBox-text">15</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01252" name="JYU01252" value={this.props.pageState.JYU01252} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01253" name="JYU01253" checked={this.props.pageState.JYU01253} {...params} />
                                        <span className="checkBox-text">16</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01254" name="JYU01254" value={this.props.pageState.JYU01254} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01255" name="JYU01255" checked={this.props.pageState.JYU01255} {...params} />
                                        <span className="checkBox-text">17</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01256" name="JYU01256" value={this.props.pageState.JYU01256} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01257" name="JYU01257" checked={this.props.pageState.JYU01257} {...params} />
                                        <span className="checkBox-text">18</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01258" name="JYU01258" value={this.props.pageState.JYU01258} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01259" name="JYU01259" checked={this.props.pageState.JYU01259} {...params} />
                                        <span className="checkBox-text">19</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01260" name="JYU01260" value={this.props.pageState.JYU01260} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01261" name="JYU01261" checked={this.props.pageState.JYU01261} {...params} />
                                        <span className="checkBox-text">20</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01262" name="JYU01262" value={this.props.pageState.JYU01262} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01453" name="JYU01453" checked={this.props.pageState.JYU01453} {...params} />
                                        <span className="checkBox-text">21</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01454" name="JYU01454" value={this.props.pageState.JYU01454} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01266" name="JYU01266" checked={this.props.pageState.JYU01266} {...params} />
                                        <span className="checkBox-text">22</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01267" name="JYU01267" value={this.props.pageState.JYU01267} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01268" name="JYU01268" checked={this.props.pageState.JYU01268} {...params} />
                                        <span className="checkBox-text">23</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01269" name="JYU01269" value={this.props.pageState.JYU01269} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w69">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01270" name="JYU01270" checked={this.props.pageState.JYU01270} {...params} />
                                        <span className="checkBox-text">24</span>
                                    </label>
                                </td>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU01271" name="JYU01271" value={this.props.pageState.JYU01271} {...params} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}