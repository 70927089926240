import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import React from "react";

/** 住宅性能評価を受けた新築住宅の場合 */
export default class Block4 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021104",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 斜線対応の設定
        let DomDiagonalLine = undefined;
        if (this.onloadFlg && this.props.pageState.JYU01469) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_JYU01469");
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU01469:AR_JYU01469-1,AR_JYU01469-2")}>
                <div className="dataList-header">
                    <div className="dataList-header-title">住宅性能評価を受けた新築住宅の場合</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-body is-emphasis" colSpan="4">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox l-checkBox--center">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01468" name="JYU01468" checked={this.props.pageState.JYU01468} {...params} />
                                                        <span className="checkBox-text">該当する</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01469" name="JYU01469" checked={this.props.pageState.JYU01469} {...params} />
                                                        <span className="checkBox-text">該当しないので、説明を省略します。　※チェックした場合は、斜線を引きます。</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {DomDiagonalLine}
                            <tr id="AR_JYU01469-1">
                                <th className="dataTable-title dataTable-title__left dataTable-title__w340" rowSpan="2">登録住宅性能評価機関による<br />住宅性能評価書の交付の有無</th>
                                <th className="dataTable-title dataTable-title__left dataTable-title__w340">設計住宅性能評価書</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01470" name="JYU01470" checked={this.props.pageState.JYU01470} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01471" name="JYU01471" checked={this.props.pageState.JYU01471} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01469-2">
                                <th className="dataTable-title dataTable-title__left">建設住宅性能評価書</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01472" name="JYU01472" checked={this.props.pageState.JYU01472} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01473" name="JYU01473" checked={this.props.pageState.JYU01473} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
