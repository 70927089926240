import { PageBase } from "components/page/user/contractFrk/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";
import Block4 from "./block4";
import Block5 from "./block5";
import Block6 from "./block6";

class ImpPage17 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     */
    getPageItems = () => {
        return {
            // 貸主の表示
            JYU01481: undefined, // 住所
            JYU01482: undefined, // 氏名
            JYU01483: false, // 土地所有者と貸主が　同じ
            JYU01484: false, // 土地所有者と貸主が　異なる
            JYU01485: false, // 土地所有者と貸主が　異なる　転賃借
            JYU01486: false, // 土地所有者と貸主が　異なる　相続
            JYU01765: false, // 土地所有者と貸主が　異なる　その他　チェック
            JYU01766: undefined, // 土地所有者と貸主が　異なる　その他

            // 借地の表示
            JYU01487: undefined, // 所在　住居表示
            JYU01488: undefined, // 地番　登記地番
            JYU01489: undefined, // 地積　登記簿
            JYU01490: undefined, // 地積　契約
            JYU01491: undefined, // 地積　実測
            JYU01492: false, // 図面の有無　有
            JYU01493: false, // 図面の有無　無
            JYU01494: undefined, // 地目

            // 借地契約の内容
            JYU01775: false, //	(1)　①借地借家法による借地　チェック
            JYU01495: false, // (1)　①借地借家法による借地　地上権
            JYU01496: false, // (1)　①借地借家法による借地　賃借権
            JYU01503: false, // (1)　②旧借地法による借地
            JYU01504: false, // (1)　②　地上権
            JYU01505: false, // (1)　②　賃借権
            JYU01506: false, // (1)　②　堅固建物所有を目的
            JYU01507: false, // (1)　②　非堅固建物所有を目的
            JYU01508: undefined, // (2)　年
            JYU01509: undefined, // (2)　From
            JYU01510: undefined, // (2)　To
            JYU01511: undefined, // (3)　月額金
            JYU01512: false, // (4)　更新可
            JYU01513: false, // (4)　更新不可
            JYU01514: false, // (4)　更新料の有無　有
            JYU01515: false, // (4)　更新料の有無　無
            JYU01516: undefined, // (4)　更新料の額
            JYU01517: false, // (5)　借地権の登記の有無　有
            JYU01518: false, // (5)　借地権の登記の有無　無
            JYU01519: false, // (5)　建物の登記の有無　有
            JYU01520: false, // (5)　建物の登記の有無　無
            JYU01521: false, // (6)　①私道負担の有無　有
            JYU01522: false, // (6)　①私道負担の有無　無
            JYU01523: false, // (6)　②第三者に借地権譲渡…　要
            JYU01524: false, // (6)　②第三者に借地権譲渡…　通知可
            JYU01525: false, // (6)　②第三者に借地権譲渡…　不要
            JYU01526: false, // (6)　②　承諾料の有無　有
            JYU01527: false, // (6)　②　承諾料の有無　無
            JYU01528: undefined, // (6)　②　承諾料金
            JYU01529: false, // (6)　③増改築時の…　要
            JYU01530: false, // (6)　③増改築時の…　通知可
            JYU01531: false, // (6)　③増改築時の…　不要
            JYU01532: false, // (6)　③　承諾料の有無　有
            JYU01533: false, // (6)　③　承諾料の有無　無
            JYU01534: undefined, // (6)　③　承諾料金
            JYU01535: false, // (6)　④契約書が公正証書化されて　いる
            JYU01536: false, // (6)　④契約書が公正証書化されて　いない
            JYU01537: false, // (6)　⑤地代改定に関する特約　有
            JYU01538: false, // (6)　⑤地代改定に関する特約　無
            JYU01539: undefined, // (6)　⑤地代改定に関する特約　内容
            JYU01540: undefined, // (6)　⑥その他

            // 対象不動産の借地権の譲渡
            JYU01543: false, // 借地権譲渡についての… 取得済
            JYU01544: false, // 借地権譲渡についての… 未取得
            JYU01545: false, // 借地権譲渡についての… 通知可
            JYU01546: false, // 借地権譲渡についての… 不要

            // その他
            JYU01547: undefined, // その他

            // 定期借地権の主な内容
            JYU01767: undefined, // 定期借地権の主な内容
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            contractTypeId: this.props.contractTypeId,  // 契約種別ID
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        // 「定期借地権」かその他で、表示内容の場合分けを行う
        let block3Item = undefined;
        let block4Item = undefined;
        let block5Item = undefined;
        let block6Item = undefined;

        if (this.props.contractType.indexOf("定期借地権") !== -1) {
            block6Item = (<li><Block6 {...params} /></li>); // 定期借地権の主な内容
        } else {
            block3Item = (<li><Block3 {...params} /></li>); // 借地契約の内容
            block4Item = (<li><Block4 {...params} /></li>); // 対象不動産の借地権の譲渡
            block5Item = (<li><Block5 {...params} /></li>); // その他
        }

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 貸主の表示 */}
                    <li><Block2 {...params} /></li> {/** 借地の表示 */}
                    {block3Item} {/** 借地契約の内容 */}
                    {block4Item} {/** 対象不動産の借地権の譲渡 */}
                    {block5Item} {/** その他 */}
                    {block6Item} {/** 定期借地権の主な内容 */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
            </React.Fragment>
        );
    }
}

export default withRouter(ImpPage17);