import { Auth } from "aws-amplify";
import { Footer } from "components/common/parts/footerUnauth";
import { FormButtonRecord, FormInputTextRecord, FormLinkRecord } from "components/common/parts/formRecord";
import { LinkToOtherDomain } from "components/common/parts/link";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import queryString from "query-string";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import * as adminActions from "redux/actions/authority";
import * as cognitoTokenActions from "redux/actions/cognitoToken";
import * as loginActions from "redux/actions/login";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";
import Validation from "util/validate";

/** ログイン画面 */
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            userPassword: "",
            loginError: false, // ログイン認証失敗時のフラグ true:ログイン失敗した
            loginLoadingIndicator: false, //ローディング表示中かどうか true:ローディング表示中
            loginErrorAttemptsExceeded: false // アカウントロックフラグ true: パスワード入力回数制限を超えた
        };

        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();

        // 画面から入力される値の情報
        this.params = {
            userId: {
                label: "ユーザーID",
                placeholder: "UserID",
                validate: this.validation.notEmpty,
                apiParamKey: "username"
            },
            userPassword: {
                label: "パスワード",
                placeholder: "password",
                validate: this.validation.notEmpty,
                apiParamKey: "password"
            }
        };
    }

    componentDidMount() {
        document.title = pageInfo.LOGIN.title;
        // ログイン画面を表示した場合、localStorageに保存している情報は全消しする
        localStorage.clear();
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputText = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** 入力ボックスの入力値をバリデーション */
    handleValidateInputText = e => {
        const target = e.target;
        const param = this.params[target.name];
        param.validate(target.name, target.value);
        this.setState({});  // 再renderする為の空セット
    }

    /**
     * ログイン認証の実施
     *
     * reduxのログイン状態をログイン済みへ変更するのはPrivateRoute内で実施するのでここではやらないこと
     * ※reduxのログイン状態変更が再レンダリングのトリガーになるので、状態変更を１か所に集約させる
     */
    handleLogin = async e => {
        e.preventDefault();

        // 全入力項目のチェックし、エラーメッセージがない場合、後続処理（登録APIの呼出し）をする
        if (!this.validation.validateTotalInputText(this.params, this.state)) {
            // cognito認証
            try {
                this.setState({ loginLoadingIndicator: true });
                const authRes = await Auth.signIn({
                    username: this.state.userId,
                    password: this.state.userPassword,
                });
                // 初回のパスワード変更が行われていない場合、リマインダー画面へ遷移させる
                if (authRes.challengeName === "NEW_PASSWORD_REQUIRED") {
                    this.setState({ loginLoadingIndicator: false });
                    this.props.history.push(pageInfo.PASSWORD_REMINDER.path);
                    return;
                }
            } catch (err) {
                this.setState({ loginError: true });
                this.setState({ loginLoadingIndicator: false });
                if (err.message === 'Password attempts exceeded') {
                    this.setState({ loginErrorAttemptsExceeded: true });
                } else {
                    this.setState({ loginErrorAttemptsExceeded: false });
                }
                return;
            }
            // 一回Redux全消しする
            this.props.reduxAllClear();

            const [isExpired, error] = await awsReq.get(
                AWS.ENDPOINT.PATHS.CHECK_PASSWORD_EXPIRED,
                res => {
                    const camelResponseData = convert.convertToFlatCamelCase(res.data);
                    return [camelResponseData.isExpired, undefined];
                },
                error => [undefined, error],
                {}, {}, AWS.ENDPOINT.REST_API.NAME,
            );

            if (isExpired || error !== undefined) {
                localStorage.clear();
                this.props.reduxAllClear();
                this.setState({ loginLoadingIndicator: false });
                if (error !== undefined) {
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                    return;
                }
                if (isExpired) {
                    this.props.history.push({ pathname: pageInfo.PASSWORD_REMINDER.path, search: "?isExpired" });
                    return;
                }
            }

            this.props.updateToNoAdmin();
            const data = await Auth.currentSession();
            const user_groups = data.idToken.payload["cognito:groups"];
            if (typeof user_groups !== "undefined" && user_groups.find(group => group === "system-admin")) {
                this.props.updateToAdmin();
            }
            this.setState({ loginLoadingIndicator: false });
            // リダイレクト先が指定されている場合、そこへ遷移する
            const parsed = queryString.parse(this.props.location.search);
            // TODO from（リダイレクト先指定のパラメータ）を定数にする
            // TODO リダイレクト先のドメインチェック
            if (parsed.from) {
                this.props.history.push(parsed.from);
            } else {
                this.props.history.push(pageInfo.DASHBOARD.path);
            }
        } else {
            this.setState({});
        }
    };

    createLoginErrorDOM = () => {
        if (this.state.loginError) {
            if (!this.state.loginErrorAttemptsExceeded) {
                return <p className="loginField-error">入力されたユーザーネームはアカウントと一致しません。ユーザーネームをご確認の上、もう一度実行してください</p>;
            } else if (this.state.loginErrorAttemptsExceeded) {
                return <p className="loginField-error">アカウントロック中です。しばらく時間をおいてから再度お試しください。</p>;
            }
        }
        return null;
    }

    // トークン更新
    handleUpdateToken = e => {
        e.preventDefault();
        Auth.currentSession().then(data => { });
    };

    // 一時クレデンシャル更新
    handleUpdateTmpCredential = e => {
        e.preventDefault();
        Auth.currentCredentials().then(data => { });
    };

    render() {

        if (this.state.loginLoadingIndicator) {
            //ローディングの回転.svgの位置設定
            const loadCircleTop = window.innerHeight / 2 - 40 + "px"; // -60はLoading画像サイズの半分ずらし
            const loadCircleLeft = window.innerWidth / 2 - 40 + "px"; // -60はLoading画像サイズの半分ずらし
            //ローディングのテキスト付の白枠の位置設定
            const loadFrameTop = window.innerHeight / 2 - 98 + "px"; // --98はLoading画像サイズの半分ずらし
            const loadFrameLeft = window.innerWidth / 2 - 229 + "px"; // --299はLoading画像サイズの半分ずらし

            // awsRequest.jsのローディングと重なってしまうため、本コンポーネントのローディングを透過しないように重ねることで対応している
            // ローディングを共通化するなど抜本的に解決しようとすると影響範囲が大きくなってしまうため暫定対応
            return (
                <div className="modal_overlay_loading" style={{ zIndex: 120, backgroundColor: 'rgb(127, 127, 127)', position: 'fixed' }}>
                    <img src="/img/loading_frame.png" alt="" style={{ position: "fixed", top: loadFrameTop, left: loadFrameLeft }} />
                    <img src="/img/loading.svg" alt="" style={{ position: "fixed", top: loadCircleTop, left: loadCircleLeft }} />
                </div>
            );
        }

        // Inputレコードに関する、タグの属性情報
        const inputSetting = { onChange: this.handleChangeInputText, onBlur: this.handleValidateInputText };

        const termsUrl = AWS.ENDPOINT_CLOUDFRONT.URL + AWS.ENDPOINT_CLOUDFRONT.PATHS.TERMS;

        return (
            <main>
                <div className="l-loginField">
                    <div className="l-loginField l-left_area"></div>
                    <div className="l-loginField l-right_area">
                        <div className="l-loginField-component">
                            <form>
                                <div className="loginField loginField--main">
                                    <div className="loginField-action">
                                        <FormInputTextRecord
                                            titleName={this.params.userId.label}
                                            inputData={{ name: "userId", value: this.state.userId || "" }}
                                            inputSetting={{ placeholder: this.params.userId.placeholder, ...inputSetting }}
                                            errorMessage={this.validation.errorMessage.userId || ""} />

                                        <FormInputTextRecord password
                                            titleName={this.params.userPassword.label}
                                            inputData={{ name: "userPassword", value: this.state.userPassword || "" }}
                                            inputSetting={{ placeholder: this.params.userPassword.placeholder, ...inputSetting }}
                                            errorMessage={this.validation.errorMessage.userPassword || ""} />
                                    </div>
                                    {this.createLoginErrorDOM()}
                                    <FormLinkRecord to={pageInfo.PASSWORD_REMINDER.path}>パスワードを忘れた方はこちら</FormLinkRecord>
                                    <div className="loginField-terms">
                                        <LinkToOtherDomain to={termsUrl} className="loginField-terms_link" target="_blank">ご利用規約を見る</LinkToOtherDomain>
                                    </div>
                                    <FormButtonRecord onClick={this.handleLogin} isActive>利用規約に同意してログイン</FormButtonRecord>
                                </div>
                            </form >
                        </div>
                        <Footer />
                    </div>
                </div>
            </main>
        );
    }
}

const mapStateToProps = state => {
    return { login: state.loginStatus, publicKey: state.publicKey, admin: state.admin };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(loginActions, dispatch),
        ...bindActionCreators(adminActions, dispatch),
        ...bindActionCreators(cognitoTokenActions, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
