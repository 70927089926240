import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import Input from "components/page/user/contract/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { DISPLAY_TYPES } from "constants/defaultInputValueTypes";
import React from "react";

/** 土地の測量によって得られた面積による売買代金の清算 */
export default class Block8 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021208",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 斜線対応の設定
        let DomDiagonalLine = undefined;
        if (this.onloadFlg
            && this.props.displayTypeID !== DISPLAY_TYPES.SEGMENT_BUILDING.value
            && this.props.pageState.JYU01449) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_JYU01449");
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU01449:AJYU01449-1,AJYU01449-2,AJYU01449-3,AJYU01449-4")}>
                <div className="dataList-header">
                    <div className="dataList-header-title">土地の測量によって得られた面積による売買代金の清算</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td colSpan="4" className="dataTable-body is-emphasis">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01209" name="JYU01209" checked={this.props.pageState.JYU01209} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01449" name="JYU01449" checked={this.props.pageState.JYU01449} {...params} />
                                                        <span className="checkBox-text">無　※入力不要の場合は、斜線を引きます。</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {DomDiagonalLine}
                            <tr id="AJYU01449-1">
                                <th className="dataTable-title dataTable-title__w120" rowSpan="3">清算対象となる土地の範囲</th>
                                <td className="dataTable-body" colSpan="3">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01210" name="JYU01210" checked={this.props.pageState.JYU01210} {...params} />
                                        <span className="checkBox-text">1.私道負担（道路境界線後退部分を含む）のない場合、登記簿面積</span>
                                    </label>
                                </td>
                            </tr>
                            <tr id="AJYU01449-2">
                                <td className="dataTable-body" colSpan="3">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01211" name="JYU01211" checked={this.props.pageState.JYU01211} {...params} />
                                        <span className="checkBox-text">2.私道負担（道路境界線後退部分を含む）のある場合、それを除く有効宅地部分</span>
                                    </label>
                                </td>
                            </tr>
                            <tr id="AJYU01449-3">
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01212" name="JYU01212" checked={this.props.pageState.JYU01212} {...params} />
                                                <span className="checkBox-text"></span>
                                            </label>
                                        </div>
                                        <Input id="ID_JYU01213" name="JYU01213" value={this.props.pageState.JYU01213} {...params} length="w537" />
                                    </div>
                                </td>
                            </tr>
                            <tr id="AJYU01449-4">
                                <th className="dataTable-title dataTable-title__w120">清算基準面積</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU01214" name="JYU01214" value={this.props.pageState.JYU01214} {...params} />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">清算単価<br />（1m<sup>2</sup>当たり）</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU01215" name="JYU01215" value={this.props.pageState.JYU01215} {...params} />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};
