import { ORGANIZATION_POPUP as OP } from "constants/contractEditPageInfo";
import React from "react";
import { Link } from "react-router-dom";
import Input from "../../input/inputNormal";
import InputOrg from "../../input/inputOrg";
import Select from "../../input/inputSelect";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 買主様 */
export default class Block10 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010110",
        };
    }

    /** 削除ボタンのハンドラ */
    handleDeleteTOR00094 = e => {
        this.handleDelete(e, "TOR00094_ARR");
    }

    /** 追加ボタンのハンドラ */
    handleAddTOR00094 = e => {

        const addObj = {
            TOR00094: undefined, // 買主 氏名
            TOR00095: undefined, // 買主 住所
            TOR00098: undefined, // 買主 電話番号
        };

        this.handleAdd(e, "TOR00094_ARR", addObj);
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 動的項目の共通パラメータ
        const arrayName = "TOR00094_ARR"; // 動的項目の代表名
        const paramsForDynamic = {
            ...params,
            arrayName: arrayName,
            mode: "dynamic",
        };

        // ページが持っている動的項目の値を取得する
        const data = this.props.pageState[arrayName];

        let items = [];

        // 2行目以降の繰り返し部分の出力
        data.forEach((obj, idx) => {
            // 1行目は別でDOMを生成しているので飛ばす
            if (idx === 0) return;

            items.push(
                <React.Fragment key={idx}>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 is-emphasis" rowSpan="2">
                            <div className="dataTable-title-vr">{idx + 1}</div>
                            <div className="dataTable-title-vr">
                                <Link to="" id={`LINK_DEL_TOR00094_ARR_${idx}`} aria-label="link" data-idx={idx} data-array-name={arrayName} className="baseAction baseAction--minus baseAction__w40" onClick={this.handleDeleteTOR00094}>
                                    <span data-idx={idx} className="baseAction-text">削除</span>
                                </Link>
                            </div>
                        </th>
                        <th className="dataTable-title dataTable-title__w120">氏名</th>
                        <td className="dataTable-body" colSpan="4">
                            <div className="dataTableUnit">
                                <Input idx={idx} id={`ID_TOR00094_${idx}`} name="TOR00094" value={obj.TOR00094} {...paramsForDynamic} length="w272" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120">住所</th>
                        <td className="dataTable-body dataTable-body__w444" colSpan="2">
                            <div className="dataTableUnit">
                                <Input idx={idx} id={`ID_TOR00095_${idx}`} name="TOR00095" value={obj.TOR00095} {...paramsForDynamic} length="wFull" />
                            </div>
                        </td>
                        <th className="dataTable-title dataTable-title__w120">電話番号</th>
                        <td className="dataTable-body">
                            <div className="dataTableUnit">
                                <Input idx={idx} id={`ID_TOR00098_${idx}`} name="TOR00098" value={obj.TOR00098} {...paramsForDynamic} length="wFull" />
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            );
        });

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">買主様</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 is-emphasis" rowSpan="6">1</th>
                                <th className="dataTable-title dataTable-title__w120">個人/法人</th>
                                <td className="dataTable-body" colSpan="4">
                                    <Select id="ID_TOR00092" name="TOR00092" value={this.props.pageState.TOR00092} items={this.getSelectbox(this.props.selectboxData.MEI00005)} {...params} length="w272" />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">氏名</th>
                                <td className="dataTable-body dataTable-body__w297">
                                    <div className="dataTableUnit">
                                        <Input idx={0} id={`ID_TOR00094_${0}`} name="TOR00094" value={data[0].TOR00094} {...paramsForDynamic} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">ふりがな</th>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00093" name="TOR00093" value={this.props.pageState.TOR00093} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" rowSpan="2">住所</th>
                                <td className="dataTable-body dataTable-body__w444" colSpan="4">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">〒</div>
                                        <Input id="ID_TOR00096_1" name="TOR00096_1" value={this.props.pageState.TOR00096_1} {...params} length="w80" />
                                        <div className="dataTableUnit-text">ー</div>
                                        <Input id="ID_TOR00096_2" name="TOR00096_2" value={this.props.pageState.TOR00096_2} {...params} length="w80" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <Input idx={0} id={`ID_TOR00095_${0}`} name="TOR00095" value={data[0].TOR00095} {...paramsForDynamic} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">電話番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input idx={0} id={`ID_TOR00098_${0}`} name="TOR00098" value={data[0].TOR00098} {...paramsForDynamic} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">転居先住所</th>
                                <td className="dataTable-body dataTable-body__w444" colSpan="2">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00099" name="TOR00099" value={this.props.pageState.TOR00099} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">新電話番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00100" name="TOR00100" value={this.props.pageState.TOR00100} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">法人の場合<br />担当者名</th>
                                <td className="dataTable-body dataTable-body__w292">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00101" name="TOR00101" value={this.props.pageState.TOR00101} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">部署名</th>
                                <td className="dataTable-body dataTable-body__w292" colSpan="2">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00102" name="TOR00102" value={this.props.pageState.TOR00102} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>

                            {items}

                            <tr>
                                <td className="dataTable-body dataTable-body__center" colSpan="6">
                                    <Link to="" id="LINK_ADD_TOR00094_ARR" aria-label="link" data-array-name={arrayName} className="baseAction baseAction--plus baseAction__w40" onClick={this.handleAddTOR00094}>
                                        <span className="baseAction-text">追加</span>
                                    </Link>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="6">仲介者</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">商号</th>
                                <td className="dataTable-body dataTable-body__w356" colSpan="2">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00109" name="TOR00109" value={this.props.pageState.TOR00109} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">支店/組織</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="2">
                                    <div className="dataTableUnit">
                                        <InputOrg id="ID_TOR00110" name="TOR00110" value={this.props.pageState.TOR00110} {...params} from={OP.SIT00002} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title">住所</th>
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00111" name="TOR00111" value={this.props.pageState.TOR00111} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title">主担当者名</th>
                                <td className="dataTable-body dataTable-body__w730" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputOrg id="ID_TOR00112" name="TOR00112" value={this.props.pageState.TOR00112} {...params} from={OP.SIT00004} />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">電話番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00113" name="TOR00113" value={this.props.pageState.TOR00113} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}