import { ERROR_MESSAGE } from "constants/errorMessage";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import * as AWS from "../../../../../../constants/aws";
import * as awsReq from "../../../../../../util/awsRequest";
import Pagination from "../../../../../common/parts/pagination";
import PopupBase from "./popupBase";

/** ポップアップ画面：保証協会情報一覧 */
class PopupGuaranteeAssociationList extends PopupBase {
    constructor(props) {
        super(props);

        this.apicalling = 0; // APIコール中のページを保持（0はコール無し。連続API防止措置なので簡易）
    }

    // キーワード入力時のハンドラ
    handleKeyword = e => {
        e.preventDefault();
        this.setState({ keyword: e.target.value });
    }

    // クリアボタン押下のハンドラ
    handleClear = e => {
        e.preventDefault();
        this.setState({ keyword: "", data: undefined, activePage: 1 });
    }

    // ポップアップの中身を表示するためのAPIリクエスト
    RequestAPI = () => {
        if (this.apicalling !== this.state.activePage) {
            this.apicalling = this.state.activePage;

            /** 保証協会一覧取得 */
            let query = { page: this.state.activePage };

            // キーワードがある時はキーワードをクエリに入れる
            if (this.state.keyword !== "") {
                query.keyword = this.state.keyword;
            }

            awsReq.post(
                AWS.ENDPOINT.PATHS.GET_POPUP_GUARANTEE_ASSOCIATION_LIST,
                res => {
                    this.apicalling = 0;
                    // 現在表示しているページに対応しているレスポンスの場合、レスポンスをセットする
                    // ※レスポンスを全て画面反映させるとページ送りした分の全ての結果が順番に表示されてしまうため
                    // ※1ページ目を表示している時に8ページ目に行くためには、1,5,8と遷移する必要があるが（この時、5は中継地点なので結果表示の必要がない）、
                    //   レスポンスを全て画面表示すると、8ページ目を表示する前に5ページ目の内容を一瞬表示してしまうことの対策
                    if (this.state.activePage === res.data.page_number) {
                        this.setState({
                            data: res.data.guarantee_associations,
                            itemsCountPerPage: res.data.per_page,
                            totalItemsCount: res.data.total_records,
                        });
                    }
                },
                error => {
                    this.apicalling = 0;
                    this.setState({ data: [] });
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                },
                query,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }

    handlePickup = e => {
        e.preventDefault();
        this.setState({ data: undefined, activePage: 1 });
    }

    // 一覧表示のテーブル部分のDOMを生成
    createTable = list => {
        const ret = (
            <div className="dataList-body dataList-body__modalScroll">
                <table className="dataTable">
                    <tbody>
                        <tr>
                            <th className="dataTable-title dataTable-title__w120 is-emphasis dataTable-title__top">保証協会ID<br />（供託所ID）</th>
                            <th className="dataTable-title dataTable-title__w480 is-emphasis is-leftJustified dataTable-title__top">保証協会名称<br />（供託所名称）</th>
                            <th className="dataTable-title is-emphasis is-leftJustified dataTable-title__top">事務所名称</th>
                        </tr>
                        {list}
                    </tbody>
                </table>
            </div>
        );
        return ret;
    }

    // リスト表示用のDOMを生成
    createItems = data => {
        let list = [];
        data.forEach((element, idx) => {
            const isActive = this.selectedIdx === idx ? "is-active" : null;
            const item = (
                <tr key={idx} onClick={e => { this.selectedIdx = idx; this.setState({}); }} className={isActive}>
                    <td className="dataTable-title">{element.display_id}</td>
                    <td className="dataTable-body">{element.association_name}</td>
                    <td className="dataTable-body">{element.office_name}</td>
                </tr>
            );
            list.push(item);
        });
        return this.createTable(list);
    }

    // Loading中のDOMを生成
    createEmptyItems = () => {
        return this.createTable(null);
    }

    // ポップアップ表示用のDOMを生成
    createFrame = (disp, table, pageSettings) => {

        // APIから値を取得している間は絞り込むボタンを押下させないようにする
        const pickupParams = this.state.data !== undefined ?
            { className: "baseAction", onClick: this.handlePickup }
            : { className: "baseAction baseAction-inactive", onClick: e => e.preventDefault() };

        return (
            <React.Fragment>
                <div className="l-modal_overlay is-active">
                    <div className="modal_overlay"></div>
                </div>
                <div className="l-modalBox">
                    <div className="modalBox">
                        <div className="dataList dataList__accordion is-open">
                            <div className="dataList-header">絞り込み条件</div>
                            <div className="dataList-body">
                                <table className="dataTable">
                                    <tbody>
                                        <tr>
                                            <th className="dataTable-title dataTable-title__w120">キーワード</th>
                                            <td className="dataTable-body dataTable-body__w355"><input type="text" aria-label="text" onChange={this.handleKeyword} value={this.state.keyword} /></td>
                                        </tr>
                                        <tr>
                                            <th className="dataTable-title dataTable-title__w120">&nbsp;</th>
                                            <td className="dataTable-body dataTable-body__w355">
                                                <ul className="l-baseAction l-baseAction__rightTwo">
                                                    <li><Link to="" aria-label="link" {...pickupParams}>絞り込み</Link></li>
                                                    <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleClear}>クリア</Link></li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Pagination pageSettings={pageSettings} />
                        <div className="dataList dataList__accordion dataList__modalScroll is-open">
                            <div className="dataList-header">
                                <div className="dataList-header-title">保証協会一覧　※（）内は供託所の場合</div>
                            </div>
                            {table}
                        </div>
                        <Pagination pageSettings={pageSettings} />
                        <ul className="l-baseAction l-baseAction__rightTwo">
                            <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleSelect}>選択</Link></li>
                            <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleCancel}>キャンセル</Link></li>
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(PopupGuaranteeAssociationList);