import InputTextArea from "components/page/user/contract/edit/input/inputTextArea";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import React from "react";
import Select from "../../input/inputSelect";

/** 代理人の署名による買主／売主の印刷 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030301",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 買主のDOMを作成
        const TOR00094_ARR = this.props.pageState.TOR00094_ARR;
        let buyerDOM = null;
        if (!(TOR00094_ARR === undefined || TOR00094_ARR.length === 0)) {

            buyerDOM = TOR00094_ARR.map((item, idx) => {

                return (
                    <tr key={idx}>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">{idx + 1}</th>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">氏名</th>
                        <td className="dataTable-body">
                            <div className="dataTableUnit">{this.excludeBlanks(item.TOR00094)}</div>
                        </td>
                    </tr>
                );
            });
        }

        // 売主のDOMを作成
        const TOR00072_ARR = this.props.pageState.TOR00072_ARR;
        let sellerDOM = null;
        if (!(TOR00072_ARR === undefined || TOR00072_ARR.length === 0)) {

            sellerDOM = TOR00072_ARR.map((item, idx) => {

                return (
                    <tr key={idx}>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">{idx + 1}</th>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">氏名</th>
                        <td className="dataTable-body">
                            <div className="dataTableUnit">{this.excludeBlanks(item.TOR00072)}</div>
                        </td>
                    </tr>
                );
            });
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">代理人の署名による買主／売主の印刷</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis_single" colSpan="3">買主</th>
                            </tr>
                            {buyerDOM}
                            <tr>
                                <th className="dataTable-title" colSpan="2">
                                    <div className="dataTableUnit-text">住所署名欄</div>
                                    <div className="dataTableUnit-input dataTable-title-vr">
                                        <Select id="ID_FUD00386" name="FUD00386" value={this.props.pageState.FUD00386} items={this.getSelectboxNoEmpty(this.props.selectboxData.MEI00011)} {...params} />
                                    </div>
                                </th>
                                <td className="dataTable-body">
                                    <InputTextArea id="ID_TOR00354" name="TOR00354" cols={70} rows={3} height="h92" value={this.props.pageState.TOR00354} {...params} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis_single" colSpan="3">売主</th>
                            </tr>
                            {sellerDOM}
                            <tr>
                                <th className="dataTable-title" colSpan="2">
                                    <div className="dataTableUnit-text">住所署名欄</div>
                                    <div className="dataTableUnit-input dataTable-title-vr">
                                        <Select id="ID_FUD00388" name="FUD00388" value={this.props.pageState.FUD00388} items={this.getSelectboxNoEmpty(this.props.selectboxData.MEI00011)} {...params} />
                                    </div>
                                </th>
                                <td className="dataTable-body">
                                    <InputTextArea id="ID_TOR00351" name="TOR00351" cols={70} rows={3} height="h92" value={this.props.pageState.TOR00351} {...params} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}