import React from "react";
import * as dateUtil from "util/dateUtil";
import InputCheckbox from "../../input/inputCheckbox";
import Input from "../../input/inputNormal";
import Select from "../../input/inputSelect";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 区分所有建物 */
export default class Block5 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010105",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">区分所有建物</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="4">一棟の建物の表示</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="2">建物の名称</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00205" name="TOR00205" value={this.props.pageState.TOR00205} {...params} length="wFlexFull" />
                                        <Input id="ID_TOR00300" name="TOR00300" value={this.props.pageState.TOR00300} {...params} length="w80" />
                                        <div className="dataTableUnit-text">棟</div>
                                        <Input id="ID_TOR00301" name="TOR00301" value={this.props.pageState.TOR00301} {...params} length="w80" />
                                        <div className="dataTableUnit-text">階</div>
                                        <Input id="ID_TOR00302" name="TOR00302" value={this.props.pageState.TOR00302} {...params} length="w80" />
                                        <div className="dataTableUnit-text">号室</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="3">
                                    <Input id="ID_TOR00356" name="TOR00356" value={this.props.pageState.TOR00356} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住居表示</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00206" name="TOR00206" value={this.props.pageState.TOR00206} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00207" name="TOR00207" value={this.props.pageState.TOR00207} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">構造</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00208" name="TOR00208" value={this.props.pageState.TOR00208} {...params} length="w391" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">延床面積</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00209" name="TOR00209" value={this.props.pageState.TOR00209} {...params} length="w112" />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="4">専有部分の建物の表示</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">家屋番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00210" name="TOR00210" value={this.props.pageState.TOR00210} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">建物の名称</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00211" name="TOR00211" value={this.props.pageState.TOR00211} {...params} />
                                        <div className="dataTableUnit-text"></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">種類</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00212" name="TOR00212" value={this.props.pageState.TOR00212} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">構造</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00213" name="TOR00213" value={this.props.pageState.TOR00213} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">床面積</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00214" name="TOR00214" value={this.props.pageState.TOR00214} {...params} length="w120" />
                                        <div className="dataTableUnit-text">階部分</div>
                                        <div className="dataTableUnit-text">登記簿</div>
                                        <Input id="ID_TOR00215" name="TOR00215" value={this.props.pageState.TOR00215} {...params} length="w120" />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                        <div className="dataTableUnit-text">壁芯</div>
                                        <Input id="ID_TOR00216" name="TOR00216" value={this.props.pageState.TOR00216} {...params} length="w120" />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center">建築時期</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Select id="ID_TOR00306" name="TOR00306" value={this.props.pageState.TOR00306} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} />
                                        <Select id="ID_TOR00307" name="TOR00307" value={this.props.pageState.TOR00307} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} />
                                        <div className="dataTableUnit-text">年</div>
                                        <Select id="ID_TOR00308" name="TOR00308" value={this.props.pageState.TOR00308} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} />
                                        <div className="dataTableUnit-text">月</div>
                                        <div className="dataTableUnit-text">新築</div>
                                        <Select id="ID_TOR00309" name="TOR00309" value={this.props.pageState.TOR00309} items={this.getSelectbox(this.props.selectboxData.MEI00012)} {...params} length="w120" />
                                        <Input id="ID_TOR00310" name="TOR00310" value={this.props.pageState.TOR00310} {...params} length="wFlexFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center" rowSpan="2">付属建物</th>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_TOR00311" name="TOR00311" checked={this.props.pageState.TOR00311} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_TOR00312" name="TOR00312" checked={this.props.pageState.TOR00312} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="4">
                                    <Input id="ID_TOR00314" name="TOR00314" value={this.props.pageState.TOR00314} {...params} length="wFull" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >
        );
    }
}