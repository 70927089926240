import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import React from "react";
import InputCheckbox from "../../input/inputCheckbox";
import Input from "../../input/inputNormal";

/** 貸主の表示 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021701",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">貸主の表示</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="2">貸主</th>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住所</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU01481" name="JYU01481" value={this.props.pageState.JYU01481} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">氏名</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU01482" name="JYU01482" value={this.props.pageState.JYU01482} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240 dataTable-title__center" rowSpan="2">登記簿に記載された<br></br>土地所有者と貸主が</th>
                                <td className="dataTable-body" colSpan="3">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01483" name="JYU01483" checked={this.props.pageState.JYU01483} {...params} />
                                                <span className="checkBox-text">同じ</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="3">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01484" name="JYU01484" checked={this.props.pageState.JYU01484} {...params} />
                                                <span className="checkBox-text">異なる　→　理由：</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01485" name="JYU01485" checked={this.props.pageState.JYU01485} {...params} />
                                                <span className="checkBox-text">転貸借</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01486" name="JYU01486" checked={this.props.pageState.JYU01486} {...params} />
                                                <span className="checkBox-text">相続（登記手続き未完了）</span>
                                            </label>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01765" name="JYU01765" checked={this.props.pageState.JYU01765} {...params} />
                                                        <span className="checkBox-text"></span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01766" name="JYU01766" value={this.props.pageState.JYU01766} {...params} length="w483" />
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}