import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";

/** 割賦販売に係る事項 */
export default class Block7 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021207",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">割賦販売に係る事項</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">割賦販売</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01200" name="JYU01200" checked={this.props.pageState.JYU01200} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </div>
                                        <div className="dataTableUnit-input">
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01442" name="JYU01442" checked={this.props.pageState.JYU01442} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}