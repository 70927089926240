import { Footer } from "components/common/parts/footerUnauth";
import { FormLinkRecord } from "components/common/parts/formRecord";
import * as pageInfo from "constants/pageInfo";
import React from "react";

/** IPアドレス制限画面 */
export default function IpAddressNG(props) {
    const ipAddress = sessionStorage.getItem('denied_ip_address');
    document.title = pageInfo.IP_ADDRESS_NG.title;

    return (
        <main>
            <div className="l-loginField">
                <div className="l-loginField l-left_area"></div>
                <div className="l-loginField l-right_area">
                    <div className="l-loginField-component">
                        <form>
                            <div className="loginField loginField--main">
                                <div className="loginField-read">
                                    御使用のIPアドレスからの接続は<br />許可されていません。<br />
                                    IPアドレス：{ipAddress}
                                </div>
                                <FormLinkRecord isCenter to={pageInfo.LOGIN.path}>ログイン画面へ戻る</FormLinkRecord>
                            </div>
                        </form>
                    </div>
                    <Footer />
                </div>
            </div>
        </main>
    );
}
