import BranchAndOrganizationInfoList from "components/page/admin/branchandOrganizationList/branchandOrganizationList";
import BranchandOrganizationMaintenance from "components/page/admin/branchandOrganizationMaintenance/branchandOrganizationMaintenance";
import ClauseAndDocumentMaintenance from "components/page/admin/clauseandDocumentMaintenance/clauseandDocumentMaintenance";
import ClauseAndDocumentMaintenanceFrk from "components/page/admin/clauseandDocumentMaintenanceFrk/clauseandDocumentMaintenanceFrk";
import DefaultInputValueMaintenance from "components/page/admin/defaultInputValueMaintenance/defaultInputValueMaintenance";
import EmployeeInfoList from "components/page/admin/employeeList/employeeList";
import EmployeeInfoListFrk from "components/page/admin/employeeListFrk/employeeList";
import EmployeeInfoMaintenance from "components/page/admin/employeeMaintenance/employeeMaintenance";
import EmployeeInfoMaintenanceFrk from "components/page/admin/employeeMaintenanceFrk/employeeMaintenance";
import GuaranteeList from "components/page/admin/guaranteeList/guaranteeList";
import GuaranteeMaintenance from "components/page/admin/guaranteeMaintenance/guaranteeMaintenance";
import InputItemList from "components/page/admin/inputItemList/inputItemList";
import InputItemMaintenance from "components/page/admin/inputItemMaintenance/inputItemMaintenance";
import MyCompanyInfoMaintenance from "components/page/admin/myCompanyMaintenance/myCompanyMaintenance";
import TemplateList from "components/page/admin/templateList/templateList";
import TemplateMaintenance from "components/page/admin/templateMaintenance/templateMaintenance";
import IpAddressNG from "components/page/public/ipAddressNG/ipAddressNG";
import Login from "components/page/public/login/login";
import Logout from "components/page/public/logout/logout";
import NoMatch from "components/page/public/nomatch/nomatch";
import PasswordReminder from "components/page/public/passwordReminder/passwordReminder";
import PasswordReset from "components/page/public/passwordReset/passwordReset";
import SessionTimeout from "components/page/public/sessionTimeout/sessionTimeout";
import Successful from "components/page/public/successful/successful";
import Timeout from "components/page/public/timeout/timeout";
import ContractEdit from "components/page/user/contract/edit/contractEdit";
import ContractEditPreview from "components/page/user/contract/edit/printPreview";
import ContractRegister from "components/page/user/contract/register/register";
import ContractEditFrk from "components/page/user/contractFrk/edit/contractEdit";
import ContractEditPreviewFrk from "components/page/user/contractFrk/edit/printPreview";
import ContractRegisterFrk from "components/page/user/contractFrk/register/register";
import ContractList from "components/page/user/contractList/contractList";
import ContractListFrk from "components/page/user/contractListFrk/contractList";
import Dashboard from "components/page/user/dashboard/dashboard";
import PasswordChange from "components/page/user/passwordChange/passwordChange";
import PasswordChangeComplete from "components/page/user/passwordChangeComplete/passwordChangeComplete";
import RefDocument from "components/page/user/refDocument/refDocument";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import AdminRoute from "route/adminRoute";
import LoginRoute from "route/loginRoute";
import PrivateRoute from "route/privateRoute";


function App() {

    return (
        <Router>
            <Switch>
                {/* ログイン不要画面 */}
                <Route exact path="/"><Redirect to={pageInfo.LOGIN.path} /></Route>
                <LoginRoute exact path={pageInfo.LOGIN.path}><Login /></LoginRoute>
                <Route exact path={pageInfo.PASSWORD_REMINDER.path}><PasswordReminder /></Route>
                <Route exact path={pageInfo.PASSWORD_RESET.path}><PasswordReset /></Route>
                <Route exact path={pageInfo.SUCCESSFUL.path}><Successful /></Route>
                <Route exact path={pageInfo.TIMEOUT.path}><Timeout /></Route>
                <Route exact path={pageInfo.LOGOUT.path}><Logout /></Route>
                <Route exact path={pageInfo.SESSION_TIMEOUT.path}><SessionTimeout /></Route>
                <Route exact path={pageInfo.IP_ADDRESS_NG.path}><IpAddressNG /></Route>

                {/* ログイン後画面 */}
                <PrivateRoute exact path={pageInfo.DASHBOARD.path}><Dashboard /></PrivateRoute>
                <PrivateRoute exact path={pageInfo.PASSWORD_CHANGE.path}><PasswordChange /></PrivateRoute>
                <PrivateRoute exact path={pageInfo.PASSWORD_CHANGE_COMPLETE.path}><PasswordChangeComplete /></PrivateRoute>
                <PrivateRoute exact path={pageInfo.CONTRACT_REGISTER.path}><ContractRegister /></PrivateRoute>
                <PrivateRoute exact path={pageInfo.CONTRACT_EDIT.path}><ContractEdit /></PrivateRoute>
                <PrivateRoute exact path={pageInfo.CONTRACT_PREVIEW.path}><ContractEditPreview /></PrivateRoute>
                <PrivateRoute exact path={pageInfo.CONTRACT_LIST.path}><ContractList /></PrivateRoute>
                <PrivateRoute exact path={pageInfo.SELECT_REF_DOCUMENT.path}><RefDocument /></PrivateRoute>
                <PrivateRoute exact path={pageInfo.CONTRACT_REGISTER_FRK.path}><ContractRegisterFrk /></PrivateRoute>
                <PrivateRoute exact path={pageInfo.CONTRACT_EDIT_FRK.path}><ContractEditFrk /></PrivateRoute>
                <PrivateRoute exact path={pageInfo.CONTRACT_LIST_FRK.path}><ContractListFrk /></PrivateRoute>
                <PrivateRoute exact path={pageInfo.CONTRACT_PREVIEW_FRK.path}><ContractEditPreviewFrk /></PrivateRoute>

                {/* 管理者画面 */}
                <AdminRoute exact path={pageInfo.MY_COMPANY_INFO_MAINTENANCE.path}><MyCompanyInfoMaintenance /></AdminRoute>
                <AdminRoute exact path={pageInfo.BRANCH_AND_ORGANIZATION_INFO_LIST.path}><BranchAndOrganizationInfoList /></AdminRoute>
                <AdminRoute exact path={pageInfo.BRANCH_AND_ORGANIZATION_INFO_MAINTENANCE.path}><BranchandOrganizationMaintenance /></AdminRoute>
                <AdminRoute exact path={pageInfo.BRANCH_AND_ORGANIZATION_INFO_MAINTENANCE_UPDATE.path}><BranchandOrganizationMaintenance /></AdminRoute>
                <AdminRoute exact path={pageInfo.EMPLOYEE_INFO_LIST.path}><EmployeeInfoList /></AdminRoute>
                <AdminRoute exact path={pageInfo.EMPLOYEE_INFO_LIST_FRK.path}><EmployeeInfoListFrk /></AdminRoute>
                <AdminRoute exact path={pageInfo.EMPLOYEE_INFO_MAINTENANCE.path}><EmployeeInfoMaintenance /></AdminRoute>
                <AdminRoute exact path={pageInfo.EMPLOYEE_INFO_MAINTENANCE_FRK.path}><EmployeeInfoMaintenanceFrk /></AdminRoute>
                <AdminRoute exact path={pageInfo.EMPLOYEE_INFO_MAINTENANCE_UPDATE.path}><EmployeeInfoMaintenance /></AdminRoute>
                <AdminRoute exact path={pageInfo.EMPLOYEE_INFO_MAINTENANCE_UPDATE_FRK.path}><EmployeeInfoMaintenanceFrk /></AdminRoute>
                <AdminRoute exact path={pageInfo.GUARANTEE_INFO_LIST.path}><GuaranteeList /></AdminRoute>
                <AdminRoute exact path={pageInfo.GUARANTEE_INFO_MAINTENANCE.path}><GuaranteeMaintenance /></AdminRoute>
                <AdminRoute exact path={pageInfo.GUARANTEE_INFO_MAINTENANCE_UPDATE.path}><GuaranteeMaintenance /></AdminRoute>
                <AdminRoute exact path={pageInfo.INPUT_ITEM_LIST.path}><InputItemList /></AdminRoute>
                <AdminRoute exact path={pageInfo.INPUT_ITEM_MAINTENANCE.path}><InputItemMaintenance /></AdminRoute>
                <AdminRoute exact path={pageInfo.INPUT_ITEM_MAINTENANCE_UPDATE.path}><InputItemMaintenance /></AdminRoute>
                <AdminRoute exact path={pageInfo.DEFAULT_INPUT_VALUE_MAINTENANCE_UPDATE.path}><DefaultInputValueMaintenance /></AdminRoute>
                <AdminRoute exact path={pageInfo.TEMPLATE_LIST.path}><TemplateList /></AdminRoute>
                <AdminRoute exact path={pageInfo.TEMPLATE_MAINTENANCE.path}><TemplateMaintenance /></AdminRoute>
                <AdminRoute exact path={pageInfo.CLAUSE_AND_DOCUMENT_MAINTENANCE.path}><ClauseAndDocumentMaintenance /></AdminRoute>
                <AdminRoute exact path={pageInfo.CLAUSE_AND_DOCUMENT_MAINTENANCE_FRK.path}><ClauseAndDocumentMaintenanceFrk /></AdminRoute>

                {/* 404 Not Found */}
                <Route path="*"><NoMatch /></Route>

                {/* 404 Not Found */}
                <Route path="*"><NoMatch /></Route>

                {/* 5xx サーバエラー */}
                {/* <Route path="/servererror"><ServerError /></Route> */}
            </Switch>
        </Router >
    );
}

export default App;
