import { PageBase } from "components/page/user/contract/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";

class BasPage2 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     * ※チェックボックスの場合、falseにする
     */
    getPageItems = () => {
        return {
            // 支払内容
            TOR00114: undefined, // 手付金 支払予定日
            TOR00115: undefined, // 手付金 支払予定額
            TOR00120: undefined, // 手付金 支払日
            TOR00121: undefined, // 手付金 支払金額
            TOR00116: undefined, // 内金 支払予定日１
            TOR00117: undefined, // 内金 支払予定額１
            TOR00122: undefined, // 内金 支払日１
            TOR00123: undefined, // 内金 支払金額１
            TOR00264: undefined, // 内金 支払予定日２
            TOR00265: undefined, // 内金 支払予定額２
            TOR00266: undefined, // 内金 支払日２
            TOR00267: undefined, // 内金 支払金額２
            TOR00118: undefined, // 残代金 支払予定日
            TOR00119: undefined, // 残代金 支払予定額
            TOR00124: undefined, // 残代金 支払日
            TOR00125: undefined, // 残代金 支払金額

            // ローン情報
            TOR00022: undefined, // 債務超過の有無
            TOR00023: undefined, // 残債務
            TOR00024: undefined, // 手付預かりの有無
            TOR00025: undefined, // 預かり理由
            TOR00030: undefined, // ローン特約の有無
            TOR00031: undefined, // 金融機関名１
            TOR00032: undefined, // 承認取得期日１
            TOR00033: undefined, // ローン解除期日１
            TOR00268: undefined, // 金融機関名２
            TOR00269: undefined, // 承認取得期日２
            TOR00270: undefined, // ローン解除期日２
            TOR00271: undefined, // 金融機関名３
            TOR00272: undefined, // 承認取得期日３
            TOR00273: undefined, // ローン解除期日３
            TOR00298: undefined, // 金融機関名４
            TOR00299: undefined, // 承認取得期日４
            TOR00034: undefined, // 停止条件･解除条件
            TOR00035: undefined, // 条件内容
            TOR00037: undefined, // 買い替え特約
            TOR00038: undefined, // 特約内容

            // 媒介情報
            TOR00040: undefined, // 媒介種別
            TOR00041: undefined, // 媒介締結日
            TOR00042: undefined, // 売主/媒介番号
            TOR00043: undefined, // 売主/領収書番号
            TOR00044: undefined, // 買主/媒介番号
            TOR00045: undefined, // 買主/領収書番号
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 支払内容 */}
                    <li><Block2 {...params} /></li> {/** ローン情報 */}
                    <li><Block3 {...params} /></li> {/** 媒介情報 */}
                </ul>
            </React.Fragment>
        );
    }
}

export default withRouter(BasPage2);