import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 特記事項　パターン２ */
export default class Block10 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030110",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 文字数制限定義
        const FUD00135LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "FUD00135",
                this.props.pageState.FUD00135);

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header" colSpan="7">
                    <div className="dataList-header-title">特記事項</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">特記事項</th>
                                <td className="dataTable-body dataTable-body__w360">
                                    <div className="dataList-body">
                                        <table className="sabTable dataTable__fullBorder">
                                            <tbody>
                                                <tr>
                                                    <th className="dataTable-title dataTable-title__center" colSpan="2">売買代金の内訳</th>
                                                </tr>
                                                <tr>
                                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">土地</th>
                                                    <td className="dataSubTable-body">
                                                        <div className="dataTableUnit">
                                                            <Input id="ID_FUD00380" name="FUD00380" value={this.props.pageState.FUD00380} {...params} />
                                                            <div className="dataTableUnit-text">円</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">建物</th>
                                                    <td className="dataSubTable-body">
                                                        <div className="dataTableUnit">
                                                            <Input id="ID_FUD00381" name="FUD00381" value={this.props.pageState.FUD00381} {...params} />
                                                            <div className="dataTableUnit-text">円</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">本体価格</th>
                                                    <td className="dataSubTable-body">
                                                        <div className="dataTableUnit">
                                                            <Input id="ID_FUD00382" name="FUD00382" value={this.props.pageState.FUD00382} {...params} />
                                                            <div className="dataTableUnit-text">円</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">消費税額</th>
                                                    <td className="dataSubTable-body">
                                                        <div className="dataTableUnit">
                                                            <Input id="ID_FUD00383" name="FUD00383" value={this.props.pageState.FUD00383} {...params} />
                                                            <div className="dataTableUnit-text">円</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">売買代金</th>
                                                    <td className="dataSubTable-body">
                                                        <div className="dataTableUnit">
                                                            <Input id="ID_FUD00384" name="FUD00384" value={this.props.pageState.FUD00384} {...params} />
                                                            <div className="dataTableUnit-text">円</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_FUD00135" name="FUD00135" value={this.props.pageState.FUD00135} text={SEND_OTHER_PAPER.FUD00135} placeholder={FUD00135LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{FUD00135LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}