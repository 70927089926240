import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import InputDate from "components/page/user/contract/edit/input/inputDate";
import Input from "components/page/user/contract/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 定期借地権 */
export default class Block8 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030108",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        //FUD00202の入力上限判定
        const FUD00202LimitErrorMessage = this.validation.validateTextAreaWordCount(`FUD00202_${this.props.displayTypeID}`, this.props.pageState.FUD00202);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <React.Fragment>
                <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">定期借地権</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120">定期借地権の種類</th>
                                    <td className="dataTable-body" colSpan="4">
                                        <ul className="l-checkBox">
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00064" name="FUD00064" checked={this.props.pageState.FUD00064} {...params} />
                                                    <span className="checkBox-text">1.地上権</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00065" name="FUD00065" checked={this.props.pageState.FUD00065} {...params} />
                                                    <span className="checkBox-text">2.賃貸権</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title dataTable-title__center is-emphasis" colSpan="2">土地の所有者</th>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120">住所</th>
                                    <td className="dataTable-body" colSpan="4">
                                        <Input id="ID_FUD00175" name="FUD00175" value={this.props.pageState.FUD00175} {...params} />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120">氏名</th>
                                    <td className="dataTable-body" colSpan="4">
                                        <Input id="ID_FUD00176" name="FUD00176" value={this.props.pageState.FUD00176} {...params} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w320 is-emphasis" rowSpan="2">定期借地権の種類</th>
                                    <th className="dataTable-title is-emphasis" colSpan="2">借地借家法第22条に基づく定期借地権（転貸借権の準共有）</th>
                                </tr>
                                <tr>
                                    <th className="dataTable-title is-emphasis" colSpan="2">定期借地権に関する書面</th>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w320 is-emphasis" rowSpan="5">売主が有する定期借地権の内容</th>
                                    <td className="dataTable-body" colSpan="2">
                                        <div className="dataTableUnit_vr">
                                            <div className="dataTableUnit__item">
                                                <div className="dataTableUnit">
                                                    <div className="dataTableUnit-input">
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_FUD00183" name="FUD00183" checked={this.props.pageState.FUD00183} {...params} />
                                                            <span className="checkBox-text">1.定期借地権設定契約書</span>
                                                        </label>
                                                    </div>
                                                    <InputDate eraOnly with id="ID_FUD00357" name="FUD00357" value={this.props.pageState.FUD00357} {...params} length="w108" addClassUnitInput="is-flex_leftauto" />
                                                </div>
                                            </div>

                                            <div className="dataTableUnit__item">
                                                <div className="dataTableUnit">
                                                    <div className="dataTableUnit-input">
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_FUD00185" name="FUD00185" checked={this.props.pageState.FUD00185} {...params} />
                                                            <span className="checkBox-text">2.定期借地権確認合意書</span>
                                                        </label>
                                                    </div>
                                                    <InputDate eraOnly with id="ID_FUD00358" name="FUD00358" value={this.props.pageState.FUD00358} {...params} length="w108" addClassUnitInput="is-flex_leftauto" />
                                                </div>
                                            </div>

                                            <div className="dataTableUnit__item">
                                                <div className="dataTableUnit">
                                                    <div className="dataTableUnit-input">
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_FUD00187" name="FUD00187" checked={this.props.pageState.FUD00187} {...params} />
                                                            <span className="checkBox-text">3.定期借地権承継合意書</span>
                                                        </label>
                                                    </div>
                                                    <InputDate eraOnly with id="ID_FUD00359" name="FUD00359" value={this.props.pageState.FUD00359} {...params} length="w108" addClassUnitInput="is-flex_leftauto" />
                                                </div>
                                            </div>

                                            <div className="dataTableUnit__item">
                                                <div className="dataTableUnit">
                                                    <div className="dataTableUnit-input">
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_FUD00189" name="FUD00189" checked={this.props.pageState.FUD00189} {...params} />
                                                            <span className="checkBox-text">4.定期借地権に関する公正証書</span>
                                                        </label>
                                                    </div>
                                                    <InputDate eraOnly with id="ID_FUD00360" name="FUD00360" value={this.props.pageState.FUD00360} {...params} length="w108" addClassUnitInput="is-flex_leftauto" />
                                                </div>
                                            </div>

                                            <div className="dataTableUnit__item">
                                                <div className="dataTableUnit">
                                                    <div className="dataTableUnit-input">
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_FUD00191" name="FUD00191" checked={this.props.pageState.FUD00191} {...params} />
                                                            <span className="checkBox-text">5.</span>
                                                        </label>
                                                    </div>
                                                    <Input id="ID_FUD00192" name="FUD00192" value={this.props.pageState.FUD00192} {...params} length="wFull" />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">目的</th>
                                    <td className="dataTable-body">
                                        <ul className="l-checkBox">
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00193" name="FUD00193" checked={this.props.pageState.FUD00193} {...params} />
                                                    <span className="checkBox-text">1.住居用</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00194" name="FUD00194" checked={this.props.pageState.FUD00194} {...params} />
                                                    <span className="checkBox-text">2.事業用</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="2">地代（賃料）</th>
                                    <td className="dataTable-body">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-input">
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00195" name="FUD00195" checked={this.props.pageState.FUD00195} {...params} />
                                                    <span className="checkBox-text">1.有</span>
                                                </label>
                                            </div>
                                            <div className="dataTableUnit-text">月額</div>
                                            <div className="dataTableUnit-text">金</div>
                                            <Input id="ID_FUD00196" name="FUD00196" value={this.props.pageState.FUD00196} {...params} length="w138" />
                                            <div className="dataTableUnit-text">円</div>
                                            <div className="dataTableUnit-input">
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00198" name="FUD00198" checked={this.props.pageState.FUD00198} {...params} />
                                                    <span className="checkBox-text">2.無</span>
                                                </label>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dataTable-body" colSpan="2">
                                        <div className="dataTableUnit">
                                            <InputDate oneline id="ID_FUD00197" name="FUD00197" value={this.props.pageState.FUD00197} {...params} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">期限</th>
                                    <td className="dataTable-body">
                                        <div className="dataTableUnit">
                                            <InputDate oneline id="ID_FUD00199" name="FUD00199" value={this.props.pageState.FUD00199} {...params} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w320 is-emphasis" rowSpan="2">保証金（土地所有者に預託した保証金）</th>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">授受の有無</th>
                                    <td className="dataTable-body">
                                        <ul className="l-checkBox">
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00200" name="FUD00200" checked={this.props.pageState.FUD00200} {...params} />
                                                    <span className="checkBox-text">1.有</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_FUD00338" name="FUD00338" checked={this.props.pageState.FUD00338} {...params} />
                                                    <span className="checkBox-text">2.無</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">保証金の額</th>
                                    <td className="dataTable-body">
                                        <div className="dataTableUnit">
                                            <Input id="ID_FUD00201" name="FUD00201" value={this.props.pageState.FUD00201} {...params} />
                                            <div className="dataTableUnit-text">円</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="dataTable dataTable__border">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                    <td className="dataTable-body" colSpan="3">
                                        <div className="dataTableTextUnit">
                                            <InputTextRemark id="ID_FUD00202" name="FUD00202" value={this.props.pageState.FUD00202} text={SEND_OTHER_PAPER.FUD00202} placeholder={FUD00202LimitErrorMessage.inputLimitGuide} {...params} />
                                            <p className="LengthOver-error">{FUD00202LimitErrorMessage.inputLimitErrorMessage}</p>

                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}