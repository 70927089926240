import React from "react";
import { useLocation } from "react-router-dom";

export default function NoMatch(props) {
    let location = useLocation();

    return (
        <>
            <div style={{ width: "700px" }}>
                <h1>404画面</h1>
                <div>couldn't match this path → {location.pathname}</div>
            </div>
        </>
    );
}
