/** システム告知情報 */
export function systemAnnouncement(state = null, action) {
    switch (action.type) {
        case "SAVE_SYSTEM_ANNOUNCEMENT":
            state = action.systemAnnouncement;
            return state;

        default:
            return state;
    }
}

/** ユーザID */
export function agencyUserId(state = null, action) {
    switch (action.type) {
        case "SAVE_AGENCY_USER_ID":
            state = action.agencyUserId;
            return state;

        default:
            return state;
    }
}

/** 氏名 */
export function userName(state = null, action) {
    switch (action.type) {
        case "SAVE_USER_NAME":
            state = action.userName;
            return state;

        default:
            return state;
    }
}

/** 仲介業者会社ID */
export function agencyId(state = null, action) {
    switch (action.type) {
        case "SAVE_AGENCY_ID":
            state = action.agencyId;
            return state;

        default:
            return state;
    }
}

/** 仲介業者支店・組織ID */
export function agencyBranchId(state = null, action) {
    switch (action.type) {
        case "SAVE_AGENCY_BRANCH_ID":
            state = action.agencyBranchId;
            return state;

        default:
            return state;
    }
}

/** 権限 */
export function authority(state = null, action) {
    switch (action.type) {
        case "SAVE_AUTHORITY":
            state = action.authority;
            return state;

        default:
            return state;
    }
}

/** 利用フォーマット */
export function useFormat(state = null, action) {
    switch (action.type) {
        case "SAVE_USE_FORMAT":
            state = action.useFormat;
            return state;

        default:
            return state;
    }
}
