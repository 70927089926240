import React from "react";

export const LinkToOtherDomain = props => {

    const { to, children, ...other } = props;
    return (
        <a href={to} {...other} rel="noopener noreferrer">
            {children}
        </a>
    );
};