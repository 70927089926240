import { PageBase } from "components/page/user/contract/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import Block1 from "./block1";
import Block2 from "./block2";

class ImpPage14 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     */
    getPageItems = () => {
        return {
            // 付属書類(契約不適合責任（瑕疵担保責任）の履行に関する措置の概要)
            JYU01216: false, // 講じる
            JYU01217: false, // 講じない
            JYU01478: undefined, // 備考

            // 契約不適合の修補請求（借地権または定借の場合）
            JYU01479: false, // 契約不適合による修補請求・代金減額請求および損害賠償請求 有
            JYU01480: false, // 契約不適合による修補請求・代金減額請求および損害賠償請求 無
            JYU01586: false, // 契約不適合による修補請求・代金減額請求および損害賠償請求 該当なし
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 付属書類(契約不適合責任（瑕疵担保責任）の履行に関する措置の概要) */}
                    <li><Block2 {...params} /></li> {/** 契約不適合の修補請求（借地権または定借の場合） */}
                </ul>
            </React.Fragment>
        );
    }
}

export default withRouter(ImpPage14);