/** 住所の都道府県 */
export const PREFECTURES = [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県"
];

/** 宅地建物取引業者の登録エリア */
export const REAL_ESTATE_BROKER_AREA = [
    "国土交通大臣",
    "青森県知事",
    "岩手県知事",
    "宮城県知事",
    "秋田県知事",
    "山形県知事",
    "福島県知事",
    "茨城県知事",
    "栃木県知事",
    "群馬県知事",
    "埼玉県知事",
    "千葉県知事",
    "東京都知事",
    "神奈川県知事",
    "新潟県知事",
    "富山県知事",
    "石川県知事",
    "福井県知事",
    "山梨県知事",
    "長野県知事",
    "岐阜県知事",
    "静岡県知事",
    "愛知県知事",
    "三重県知事",
    "滋賀県知事",
    "京都府知事",
    "大阪府知事",
    "兵庫県知事",
    "奈良県知事",
    "和歌山県知事",
    "鳥取県知事",
    "島根県知事",
    "岡山県知事",
    "広島県知事",
    "山口県知事",
    "徳島県知事",
    "香川県知事",
    "愛媛県知事",
    "高知県知事",
    "福岡県知事",
    "佐賀県知事",
    "長崎県知事",
    "熊本県知事",
    "大分県知事",
    "宮崎県知事",
    "鹿児島県知事",
    "沖縄県知事",
    "北海道知事（石狩）",
    "北海道知事（渡島）",
    "北海道知事（檜山）",
    "北海道知事（後志）",
    "北海道知事（空知）",
    "北海道知事（上川）",
    "北海道知事（留萌）",
    "北海道知事（宗谷）",
    "北海道知事（網走）",
    "北海道知事（胆振）",
    "北海道知事（日高）",
    "北海道知事（十勝）",
    "北海道知事（釧路）",
    "北海道知事（根室）"
];

/** 宅地建物取引士の登録エリア */
export const BUILDING_TRADER_AREA = [
    "青森",
    "岩手",
    "宮城",
    "秋田",
    "山形",
    "福島",
    "茨城",
    "栃木",
    "群馬",
    "埼玉",
    "千葉",
    "東京",
    "神奈川",
    "新潟",
    "富山",
    "石川",
    "福井",
    "山梨",
    "長野",
    "岐阜",
    "静岡",
    "愛知",
    "愛知県",
    "三重",
    "滋賀",
    "京都",
    "大阪",
    "兵庫",
    "奈良",
    "和歌山",
    "鳥取",
    "島根",
    "岡山",
    "広島",
    "山口",
    "徳島",
    "香川",
    "愛媛",
    "高知",
    "福岡",
    "佐賀",
    "長崎",
    "熊本",
    "大分",
    "宮崎",
    "鹿児島",
    "沖縄",
    "石狩",
    "渡島",
    "檜山",
    "後志",
    "空知",
    "上川",
    "留萌",
    "宗谷",
    "網走",
    "胆振",
    "日高",
    "十勝",
    "釧路",
    "根室"
];
