// フォーマット設定の定数
export const FORMAT = {
    OUCHI_KURABERU: {
        value: "01",
        display: "おうちクラベル"
    },
    FRK: {
        value: "02",
        display: "FRK"
    },
};

// 利用約款の定数
export const USE_CLAUSE = {
    KANTOU_LOCAL: {
        value: 1,
        display: "関東地方"
    },
    KANSAI_LOCAL: {
        value: 2,
        display: "関西地方"
    },
};
