import PopupBase from "./popupBase";

export default class PopupBaseMulti extends PopupBase {
    constructor(props) {
        super(props);

        this.selectedIdx = []; // 選択したチェックボックスのインデックス
    }

    // 選択ボタンのハンドラ
    handleSelect = e => {
        e.preventDefault();
        let tmp = {};

        // 現入力がundefined（入力値がない場合の初期値）だった場合、空文字列へ変換
        let now = this.props.pageState[this.props.to];
        now = now === undefined ? "" : now;
        tmp[this.props.to] = now + this.selectString().join("\n\n") + "\n";

        // ページ側の契約情報を更新する（＆文字列に変換する）
        // ページ側のポップアップ表示管理のフラグをfalseにする
        tmp[this.props.popupFlagName] = false;
        this.props.updatePageState({ ...tmp }, true);
        this.setState({ data: undefined, totalItemsCount: 0 }); // コンポーネントが持っているAPIからの取得値をクリア
        this.selectedIdx = []; // 選択のリセット
    }

    // 選択されたチェックボックスの文字化
    selectString() {

        // 選択されたチェックボックスのインデックスを昇順ソートする
        // ※選択した順番によって、[3, 2, 0] の様になっているので [0, 2, 3] に直す
        this.selectedIdx.sort((a, b) => (a < b ? -1 : 1));

        // 選択されたチェックボックスを1つの文字列に結合する
        let array = [];
        this.selectedIdx.forEach(idx => {
            array.push(this.state.data[idx][this.props.from] + "");
        });

        return (array);

    }

    // キャンセルボタンのハンドラ
    handleCancel = e => {
        e.preventDefault();
        let tmp = {};
        tmp[this.props.popupFlagName] = false;
        this.props.updatePageState({ ...tmp }, false); // ページ側のポップアップ表示管理のフラグをfalseにする
        this.setState({ data: undefined, totalItemsCount: 0 }); // コンポーネントが持っているAPIからの取得値をクリア
        this.selectedIdx = []; // 選択のリセット
    }
}