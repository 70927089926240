import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import React from "react";

/** 損害賠償額の予定または違約金に関する事項 */
export default class Block3 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021203",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">損害賠償額の予定または違約金に関する事項</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">違約金</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01124" name="JYU01124" checked={this.props.pageState.JYU01124} {...params} />
                                                <span className="checkBox-text">1.手付金の額</span>
                                            </label>
                                        </div>
                                        <div className="dataTableUnit-input">
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01125" name="JYU01125" checked={this.props.pageState.JYU01125} {...params} />
                                                <span className="checkBox-text">2.売買代金の</span>
                                            </label>
                                        </div>
                                        <Input id="ID_JYU01126" name="JYU01126" value={this.props.pageState.JYU01126} {...params} />
                                        <div className="dataTableUnit-text">％相当額</div>
                                        <div className="dataTableUnit-input">
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01127" name="JYU01127" checked={this.props.pageState.JYU01127} {...params} />
                                                <span className="checkBox-text">3.その他</span>
                                            </label>
                                        </div>
                                        <Input id="ID_JYU01128" name="JYU01128" value={this.props.pageState.JYU01128} {...params} maxlength="9" />
                                        {/* 下記、お客様要求にはあったが帳票に出力欄がないため、一旦コメントアウト対応 */}
                                        {/* <Input id="ID_JYU01719" name="JYU01719" value={this.props.pageState.JYU01719} {...params} length="wFlexFull" /> */}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}