import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import Input from "components/page/user/contract/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 建物の建築及び維持保全の状況に関する書類の保存の状況 */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021102",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        //JYU01013の入力上限判定
        const JYU01013LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU01013_${this.props.displayTypeID}`, this.props.pageState.JYU01013);

        // 斜線対応の設定
        let DomDiagonalLine = undefined;
        if (this.onloadFlg && this.props.pageState.JYU01284) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_JYU01284");
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU01284:AR_JYU01284-1,AR_JYU01284-2,AR_JYU01284-3,AR_JYU01284-4,AR_JYU01284-5,AR_JYU01284-6,AR_JYU01284-7,AR_JYU01284-8,AR_JYU01284-9,AR_JYU01284-10,AR_JYU01284-11,AR_JYU01284-12,AR_JYU01284-13,AR_JYU01284-14,AR_JYU01284-15,AR_JYU01284-16")}>
                <div className="dataList-header">
                    <div className="dataList-header-title">建物の建築及び維持保全の状況に関する書類の保存の状況（既存建物のとき）</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-body is-emphasis" colSpan="3">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox l-checkBox--center">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00987" name="JYU00987" checked={this.props.pageState.JYU00987} {...params} />
                                                        <span className="checkBox-text">該当する</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01284" name="JYU01284" checked={this.props.pageState.JYU01284} {...params} />
                                                        <span className="checkBox-text">該当しないので、説明を省略します。　※入力不要の場合は、斜線を引きます。</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {DomDiagonalLine}
                            <tr id="AR_JYU01284-1">
                                <th className="dataTable-title is-emphasis" colSpan="2">書類の種類等</th>
                                <th className="dataTable-title dataTable-title__w270 is-emphasis">保存の状況</th>
                            </tr>
                            <tr id="AR_JYU01284-2">
                                <th className="dataTable-title dataTable-title__left dataTable-title__w700" colSpan="2">確認の申請書及び添付図書並びに確認済証（新築時のもの）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00988" name="JYU00988" checked={this.props.pageState.JYU00988} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01286" name="JYU01286" checked={this.props.pageState.JYU01286} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-3">
                                <th className="dataTable-title dataTable-title__left dataTable-title__w700" colSpan="2">検査済証（新築時のもの）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00991" name="JYU00991" checked={this.props.pageState.JYU00991} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01285" name="JYU01285" checked={this.props.pageState.JYU01285} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-4">
                                <th className="dataTable-title dataTable-title__left dataTable-title__w340" rowSpan="2">増改築等を行った物件である場合</th>
                                <th className="dataTable-title dataTable-title__left dataTable-title__w340">確認の申請及び添付図書並びに確認済証<br />（増改築等のときのもの）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00994" name="JYU00994" checked={this.props.pageState.JYU00994} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01287" name="JYU01287" checked={this.props.pageState.JYU01287} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-5">
                                <th className="dataTable-title dataTable-title__left">検査済証（増改築等のときのもの）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00995" name="JYU00995" checked={this.props.pageState.JYU00995} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01288" name="JYU01288" checked={this.props.pageState.JYU01288} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-6">
                                <th className="dataTable-title dataTable-title__left">※建物状況調査を実施した住宅である場合</th>
                                <th className="dataTable-title dataTable-title__left">建物状況調査結果報告書<br />（1年以内に限らない）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00996" name="JYU00996" checked={this.props.pageState.JYU00996} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01289" name="JYU01289" checked={this.props.pageState.JYU01289} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-7">
                                <th className="dataTable-title dataTable-title__left">
                                    <div className="dataTableUnit_vr dataTableUnit_vr__equallySpaced">
                                        <div className="dataTableUnit-text">※建築基準法第１２条の規定による<br />定期調査報告の対象である場合</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00997" name="JYU00997" checked={this.props.pageState.JYU00997} {...params} />
                                                        <span className="checkBox-text">該当する</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01290" name="JYU01290" checked={this.props.pageState.JYU01290} {...params} />
                                                        <span className="checkBox-text">該当しない</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <th className="dataTable-title dataTable-title__left">定期調査報告書<br />定期検査報告書（昇降機等）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00998" name="JYU00998" checked={this.props.pageState.JYU00998} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01291" name="JYU01291" checked={this.props.pageState.JYU01291} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-8">
                                <th className="dataTable-title dataTable-title__left">※既存住宅性能評価を受けた住宅である場合</th>
                                <th className="dataTable-title dataTable-title__left">既存住宅性能評価書（現況検査・評価書）</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00999" name="JYU00999" checked={this.props.pageState.JYU00999} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01292" name="JYU01292" checked={this.props.pageState.JYU01292} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-9">
                                <td className="dataTable-title dataTable-title__left dataTable-title__top dataTable-title__rightBorder" rowSpan="7">
                                    <div className="dataTableUnit_vr dataTableUnit_vr__equallySpaced">
                                        <div className="dataTableUnit-text">昭和５６年５月３１日以前に新築の工事に<br />着手した住宅である場合<br />（地震に対する安全性に関する書類）</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01000" name="JYU01000" checked={this.props.pageState.JYU01000} {...params} />
                                                        <span className="checkBox-text">該当する</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01293" name="JYU01293" checked={this.props.pageState.JYU01293} {...params} />
                                                        <span className="checkBox-text">該当しない</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-10">
                                <th className="dataTable-title dataTable-title__left">※既存住宅性能評価書（現況検査・評価書）<br />記載中、１-１. 耐震等級の区分</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01001" name="JYU01001" checked={this.props.pageState.JYU01001} {...params} />
                                                <span className="checkBox-text">1以上（適）</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01002" name="JYU01002" checked={this.props.pageState.JYU01002} {...params} />
                                                <span className="checkBox-text">0（不適）</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-11">
                                <th className="dataTable-title dataTable-title__left dataTable-title__noborder">耐震診断結果報告書　有の場合、その概要<br />は別添耐震診断結果報告書の通り</th>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01003" name="JYU01003" checked={this.props.pageState.JYU01003} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01294" name="JYU01294" checked={this.props.pageState.JYU01294} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-12">
                                <th className="dataTable-title dataTable-title__left dataTable-title__noborder">報告書の記載中、新耐震基準の適合性を証<br />する記載</th>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01004" name="JYU01004" checked={this.props.pageState.JYU01004} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01295" name="JYU01295" checked={this.props.pageState.JYU01295} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-13">
                                <th className="dataTable-title dataTable-title__left dataTable-title__noborder">※既存住宅売買瑕疵保険の付保証明書</th>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01005" name="JYU01005" checked={this.props.pageState.JYU01005} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01296" name="JYU01296" checked={this.props.pageState.JYU01296} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-14">
                                <th className="dataTable-title dataTable-title__left dataTable-title__noborder">※耐震基準適合証明書</th>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01006" name="JYU01006" checked={this.props.pageState.JYU01006} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01297" name="JYU01297" checked={this.props.pageState.JYU01297} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01284-15">
                                <th className="dataTable-title dataTable-title__left dataTable-title__noborder">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">※その他（</div>
                                        <Input id="ID_JYU01007" name="JYU01007" value={this.props.pageState.JYU01007} {...params} length="w160" />
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </th>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <ul className="l-checkBox l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01008" name="JYU01008" checked={this.props.pageState.JYU01008} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01298" name="JYU01298" checked={this.props.pageState.JYU01298} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr id="AR_JYU01284-16">
                                <th className="dataTable-title dataTable-title__left dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU01013" name="JYU01013" value={this.props.pageState.JYU01013} text={SEND_OTHER_PAPER.JYU01013} placeholder={JYU01013LimitErrorMessage.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU01013LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};
