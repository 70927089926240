import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";
import InputCheckbox from "../../input/inputCheckbox";
import Input from "../../input/inputNormal";

/** 管理組合の名称および管理の委託先 */
export default class Block3 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021003",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">管理組合の名称および管理の委託先</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">管理組合<br />の名称</th>
                                <td className="dataTable-body">
                                    <Input id="ID_JYU00967" name="JYU00967" value={this.props.pageState.JYU00967} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">管理の<br />形態</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00968" name="JYU00968" checked={this.props.pageState.JYU00968} {...params} />
                                                <span className="checkBox-text">1.全部委託管理</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00969" name="JYU00969" checked={this.props.pageState.JYU00969} {...params} />
                                                <span className="checkBox-text">2.一部委託管理</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00970" name="JYU00970" checked={this.props.pageState.JYU00970} {...params} />
                                                <span className="checkBox-text">3.自主管理</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="3">管理<br />委託先</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">名称</div>
                                        <Input id="ID_JYU00971" name="JYU00971" value={this.props.pageState.JYU00971} {...params} length="wFlexFull" />
                                        <div className="dataTableUnit-text">電話</div>
                                        <Input id="ID_JYU00972" name="JYU00972" value={this.props.pageState.JYU00972} {...params} length="w200" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">所在</div>
                                        <Input id="ID_JYU00973" name="JYU00973" value={this.props.pageState.JYU00973} {...params} length="wFlexFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">マンション管理適正化法による登録番号</div>
                                        <div className="dataTableUnit-text">国土交通大臣</div>
                                        <div className="dataTableUnit-text">（</div>
                                        <Input id="ID_JYU01306" name="JYU01306" value={this.props.pageState.JYU01306} {...params} length="w80" />
                                        <div className="dataTableUnit-text">）</div>
                                        <div className="dataTableUnit-text">第</div>
                                        <Input id="ID_JYU01307" name="JYU01307" value={this.props.pageState.JYU01307} {...params} length="w120" />
                                        <div className="dataTableUnit-text">号</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}