import { PageBase } from "components/page/user/contract/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";
import Block4 from "./block4";
import Block5 from "./block5";

class EstPage2 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     * ※チェックボックスの場合、falseにする
     */
    getPageItems = () => {
        return {
            // 売買代金及び支払方法等
            TOR00009: undefined, // 消費税等
            TOR00010: undefined, // 売買価格（税込）
            TOR00011: undefined, // 手付金
            TOR00116: undefined, // 内金　支払予定日１
            TOR00117: undefined, // 内金　支払予定額１
            TOR00264: undefined, // 内金　支払予定日２
            TOR00265: undefined, // 内金　支払予定額２
            TOR00118: undefined, // 残代金　支払予定日
            TOR00119: undefined, // 残代金　支払予定額
            FUD00225: false, // 売買代金金額受領日
            FUD00226: false, // その他
            FUD00227: undefined, // その他　内容
            JYU01102: undefined, // 手付解除期日
            JYU01124: false, // 違約金　手付金の額
            JYU01125: false, // 違約金　売買代金
            JYU01126: undefined, // 違約金　売買代金　％相当額
            JYU01127: false, // 違約金　その他　チェック
            JYU01128: undefined, // 違約金　その他　金額
            // 融資
            JYU01110: false, // 融資利用の特約による解除　有
            JYU01275: false, // 融資利用の特約による解除　無
            FUD00251: undefined, // 融資金額１
            FUD00252: undefined, // 申込先２
            FUD00254: undefined, // 融資金額２
            FUD00253: undefined, // 融資了承取得期日２
            TOR00033: undefined, // ローン解除期日１
            JYU01118: undefined, // 賃借権譲渡承諾の特約に基づく契約解除期日

            // 清算の対象となる土地
            JYU01210: false, // 清算対象　私道負担のない場合、登記簿面積
            JYU01211: false, // 清算対象　私道負担のある場合、それを除く有効宅地部分
            JYU01212: false, // 清算対象　その他　チェック
            JYU01213: undefined, // 清算対象　その他　内容
            JYU01214: undefined, // 清算基準面積（㎡）
            JYU01215: undefined, // 清算単価（１㎡当たり）

            // 契約不適合の責任期間起算の時
            FUD00368: false, // 第7条第2項の引渡完了日
            FUD00369: false, // 住宅新築請負契約の請負人から売主への引渡しの時
            FUD00370: false, // 契約不適合の責任期間起算　その他　チェック
            FUD00371: undefined, // 契約不適合の責任期間起算　その他　内容

            // 保証金返還請求権引継対価
            FUD00215: undefined, // 保証金返還請求権引継対価　金額
            FUD00365: false, // 住宅瑕疵担保保証金の供託
            FUD00366: false, // 住宅瑕疵担保責任保険の付保
            FUD00367: undefined, // 契約不適合責任の履行に関する措置　備考
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [
            // 売買代金及び支払方法等
            "TOR00009", "TOR00010", "TOR00011", "TOR00116", "TOR00117", "TOR00264", "TOR00265",
            "TOR00118", "TOR00119", "JYU01124", "JYU01125", "JYU01126", "JYU01127", "JYU01128",
            "JYU01102",
            // 融資
            "JYU01110", "JYU01275", "TOR00033", "JYU01118",

            // 清算の対象となる土地
            "JYU01210", "JYU01211", "JYU01212", "JYU01213", "JYU01214", "JYU01215",
        ];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 売買代金及び支払方法等 */}
                    <li><Block2 {...params} /></li> {/** 清算の対象となる土地 */}
                    <li><Block5 {...params} /></li> {/** 契約不適合の責任期間起算の時 */}
                    <li><Block3 {...params} /></li> {/** 保証金返還請求権引継対価 */}
                    <li><Block4 {...params} /></li> {/** 契約不適合責任の履行に関する措置 */}
                </ul>
            </React.Fragment>
        );
    }
}

export default withRouter(EstPage2);