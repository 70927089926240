import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import InputDate from "components/page/user/contractFrk/edit/input/inputDate";
import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 都市計画法、建築基準法以外の法令に基づく制限 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020501",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 文字数制限定義
        const JYU00693LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00693",
                this.props.pageState.JYU00693);

        // 斜線対応の設定
        let DomDiagonalLine = undefined;
        if (this.onloadFlg && this.props.pageState.JYU01377) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_JYU01377");
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU01377:AR_JYU01377-1,AR_JYU01377-2,AR_JYU01377-3,AR_JYU01377-4,AR_JYU01377-5,AR_JYU01377-6,AR_JYU01377-7,AR_JYU01377-8,AR_JYU01377-9,AR_JYU01377-10")}>
                <div className="dataList-header">
                    <div className="dataList-header-title">都市計画法、建築基準法以外の法令に基づく制限</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w300" colSpan="2">1.土地区画整理法に基づく制限</th>
                                <td className="dataTable-body" colSpan="5">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00596" name="JYU00596" checked={this.props.pageState.JYU00596} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01377" name="JYU01377" checked={this.props.pageState.JYU01377} {...params} />
                                                <span className="checkBox-text">無　※チェックした場合は、斜線を引きます。</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            {DomDiagonalLine}
                            <tr id="AR_JYU01377-1">
                                <th className="dataTable-title dataTable-title__w240" rowSpan="2">①土地区画整理事業</th>
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00597" name="JYU00597" checked={this.props.pageState.JYU00597} {...params} />
                                                        <span className="checkBox-text">計画有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00598" name="JYU00598" checked={this.props.pageState.JYU00598} {...params} />
                                                        <span className="checkBox-text">施行中</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">（</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00599" name="JYU00599" checked={this.props.pageState.JYU00599} {...params} />
                                                        <span className="checkBox-text">仮換地</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00600" name="JYU00600" checked={this.props.pageState.JYU00600} {...params} />
                                                        <span className="checkBox-text">換地</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00601" name="JYU00601" checked={this.props.pageState.JYU00601} {...params} />
                                                        <span className="checkBox-text">保留地</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </td>
                            </tr>
                            <tr id="AR_JYU01377-2">
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00602" name="JYU00602" value={this.props.pageState.JYU00602} {...params} prefix="名称" maxlength="44" />
                                    </div>
                                </td>
                            </tr>
                            <tr id="AR_JYU01377-3">
                                <th className="dataTable-title dataTable-title__w240">②仮換地指定</th>
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00603" name="JYU00603" checked={this.props.pageState.JYU00603} {...params} />
                                                        <span className="checkBox-text">未</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00604" name="JYU00604" checked={this.props.pageState.JYU00604} {...params} />
                                                        <span className="checkBox-text">済</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <InputDate id="ID_JYU00605" name="JYU00605" value={this.props.pageState.JYU00605} {...params} length="w108" />
                                        <Input id="ID_JYU00606" name="JYU00606" value={this.props.pageState.JYU00606} {...params} length="w242" />
                                        <div className="dataTableUnit-text">号</div>
                                    </div>
                                </td>
                            </tr>
                            <tr id="AR_JYU01377-4">
                                <th className="dataTable-title dataTable-title__w300" colSpan="2">③（仮）換地・保留地の街区番号など</th>
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00607" name="JYU00607" value={this.props.pageState.JYU00607} {...params} length="w242" />
                                    </div>
                                </td>
                            </tr>
                            <tr id="AR_JYU01377-5">
                                <th className="dataTable-title dataTable-title__w240">④換地処分の公告</th>
                                <td className="dataTable-body dataTable-body__w360" colSpan="2">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_JYU00608" name="JYU00608" value={this.props.pageState.JYU00608} {...params} length="w108" />
                                        <div className="dataTableUnit-text">（予定）</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w240">⑤仮換地図等</th>
                                <td className="dataTable-body dataTable-body__w260" colSpan="2">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00609" name="JYU00609" checked={this.props.pageState.JYU00609} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01378" name="JYU01378" checked={this.props.pageState.JYU01378} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01377-6">
                                <th className="dataTable-title dataTable-title__w240">⑥清算金の徴収・交付</th>
                                <td className="dataTable-body" colSpan="5">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00610" name="JYU00610" checked={this.props.pageState.JYU00610} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01379" name="JYU01379" checked={this.props.pageState.JYU01379} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01380" name="JYU01380" checked={this.props.pageState.JYU01380} {...params} />
                                                <span className="checkBox-text">未定</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr id="AR_JYU01377-7">
                                <th className="dataTable-title dataTable-title__w240" rowSpan="2">→有の場合</th>
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">（</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00611" name="JYU00611" checked={this.props.pageState.JYU00611} {...params} />
                                                        <span className="checkBox-text">徴収</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00612" name="JYU00612" checked={this.props.pageState.JYU00612} {...params} />
                                                        <span className="checkBox-text">交付</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">）</div>
                                        <div className="dataTableUnit-text">（金額：</div>
                                        <div className="dataTableUnit-text">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00613" name="JYU00613" checked={this.props.pageState.JYU00613} {...params} />
                                                        <span className="checkBox-text">未定</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00614" name="JYU00614" checked={this.props.pageState.JYU00614} {...params} />
                                                        <span className="checkBox-text">確定</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">
                                            <div className="dataTableUnit">
                                                <Input id="ID_JYU00615" name="JYU00615" value={this.props.pageState.JYU00615} {...params} />
                                            </div>
                                        </div>
                                        <div className="dataTableUnit-text">円）</div>
                                    </div>
                                </td>
                            </tr>
                            <tr id="AR_JYU01377-8">
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">帰属先：</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00616" name="JYU00616" checked={this.props.pageState.JYU00616} {...params} />
                                                        <span className="checkBox-text">売主</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00617" name="JYU00617" checked={this.props.pageState.JYU00617} {...params} />
                                                        <span className="checkBox-text">買主</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr id="AR_JYU01377-9">
                                <th className="dataTable-title dataTable-title__w240" rowSpan="2">⑦建築等の制限</th>
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableUnit">
                                        <ul className="l-checkBox">
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_JYU00623" name="JYU00623" checked={this.props.pageState.JYU00623} {...params} />
                                                    <span className="checkBox-text">有</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_JYU01381" name="JYU01381" checked={this.props.pageState.JYU01381} {...params} />
                                                    <span className="checkBox-text">無</span>
                                                </label>
                                            </li>
                                        </ul>
                                        <Input id="ID_JYU01651" name="JYU01651" value={this.props.pageState.JYU01651} {...params} length="wFlexFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr id="AR_JYU01377-10">
                                <td className="dataTable-body" colSpan="5">
                                    <Input id="ID_JYU01652" name="JYU01652" value={this.props.pageState.JYU01652} {...params} length="wFull" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__list">
                        <tbody>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00625" name="JYU00625" checked={this.props.pageState.JYU00625} {...params} />
                                        <span className="checkBox-text">2.古都保存法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00637" name="JYU00637" checked={this.props.pageState.JYU00637} {...params} />
                                        <span className="checkBox-text">14.近畿圏の近郊整備区域及び都市開発区域の整備及び開発に関する法律</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00649" name="JYU00649" checked={this.props.pageState.JYU00649} {...params} />
                                        <span className="checkBox-text">26.マンションの建替え等の円滑化に関する法律</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00660" name="JYU00660" checked={this.props.pageState.JYU00660} {...params} />
                                        <span className="checkBox-text">38.津波防災地域づくりに関する法律</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00672" name="JYU00672" checked={this.props.pageState.JYU00672} {...params} />
                                        <span className="checkBox-text">51.国土利用計画法</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00626" name="JYU00626" checked={this.props.pageState.JYU00626} {...params} />
                                        <span className="checkBox-text">3.都市緑地法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00673" name="JYU00673" checked={this.props.pageState.JYU00673} {...params} />
                                        <span className="checkBox-text">52.核原料物質､核燃料物質及び原子炉の規制に関する法律</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00627" name="JYU00627" checked={this.props.pageState.JYU00627} {...params} />
                                        <span className="checkBox-text">4.生産緑地法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00638" name="JYU00638" checked={this.props.pageState.JYU00638} {...params} />
                                        <span className="checkBox-text">15.流通業務市街地整備法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01770" name="JYU01770" checked={this.props.pageState.JYU01770} {...params} />
                                        <span className="checkBox-text">27.長期優良住宅の普及の促進に関する法律</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00661" name="JYU00661" checked={this.props.pageState.JYU00661} {...params} />
                                        <span className="checkBox-text">39.砂防法</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00628" name="JYU00628" checked={this.props.pageState.JYU00628} {...params} />
                                        <span className="checkBox-text">5.特定空港周辺特別措置法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00662" name="JYU00662" checked={this.props.pageState.JYU00662} {...params} />
                                        <span className="checkBox-text">40.地すべり等防止法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00674" name="JYU00674" checked={this.props.pageState.JYU00674} {...params} />
                                        <span className="checkBox-text">53.廃棄物の処理及び清掃に関する法律</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00629" name="JYU00629" checked={this.props.pageState.JYU00629} {...params} />
                                        <span className="checkBox-text">6.景観法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00639" name="JYU00639" checked={this.props.pageState.JYU00639} {...params} />
                                        <span className="checkBox-text">16.都市再開発法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00650" name="JYU00650" checked={this.props.pageState.JYU00650} {...params} />
                                        <span className="checkBox-text">28.都市公園法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00663" name="JYU00663" checked={this.props.pageState.JYU00663} {...params} />
                                        <span className="checkBox-text">41.急傾斜地法</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00630" name="JYU00630" checked={this.props.pageState.JYU00630} {...params} />
                                        <span className="checkBox-text">7.大都市地域における住宅及び住宅地の供給の促進に関する特別措置法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00640" name="JYU00640" checked={this.props.pageState.JYU00640} {...params} />
                                        <span className="checkBox-text">17.沿道整備法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00651" name="JYU00651" checked={this.props.pageState.JYU00651} {...params} />
                                        <span className="checkBox-text">29.自然公園法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00664" name="JYU00664" checked={this.props.pageState.JYU00664} {...params} />
                                        <span className="checkBox-text">42.土砂災害防止対策推進法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00675" name="JYU00675" checked={this.props.pageState.JYU00675} {...params} />
                                        <span className="checkBox-text">54.土壌汚染対策法</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190 is-dispborder">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00641" name="JYU00641" checked={this.props.pageState.JYU00641} {...params} />
                                        <span className="checkBox-text">18.集落地域整備法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00652" name="JYU00652" checked={this.props.pageState.JYU00652} {...params} />
                                        <span className="checkBox-text">30.首都圏近郊緑地保全法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00676" name="JYU00676" checked={this.props.pageState.JYU00676} {...params} />
                                        <span className="checkBox-text">55.都市再生特別措置法</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00631" name="JYU00631" checked={this.props.pageState.JYU00631} {...params} />
                                        <span className="checkBox-text">8.地方拠点都市地域の整備及び産業業務施設の再配置の促進に関する法律</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00642" name="JYU00642" checked={this.props.pageState.JYU00642} {...params} />
                                        <span className="checkBox-text">19.密集市街地における防災街区の整備の促進に関する法律</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00653" name="JYU00653" checked={this.props.pageState.JYU00653} {...params} />
                                        <span className="checkBox-text">31.近畿圏の保全区域の整備に関する法律</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00665" name="JYU00665" checked={this.props.pageState.JYU00665} {...params} />
                                        <span className="checkBox-text">43.森林法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00677" name="JYU00677" checked={this.props.pageState.JYU00677} {...params} />
                                        <span className="checkBox-text">56.地域再生法</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190 is-dispborder">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00666" name="JYU00666" checked={this.props.pageState.JYU00666} {...params} />
                                        <span className="checkBox-text">44.森林経営管理法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00678" name="JYU00678" checked={this.props.pageState.JYU00678} {...params} />
                                        <span className="checkBox-text">57.高齢者、障害者等の移動等の円滑化の促進に関する法律</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00632" name="JYU00632" checked={this.props.pageState.JYU00632} {...params} />
                                        <span className="checkBox-text">9.被災市街地復興特別措置法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00643" name="JYU00643" checked={this.props.pageState.JYU00643} {...params} />
                                        <span className="checkBox-text">20.地域における歴史的風致の維持及び向上に関する法律</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00654" name="JYU00654" checked={this.props.pageState.JYU00654} {...params} />
                                        <span className="checkBox-text">32.都市の低炭素化の促進に関する法律</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00667" name="JYU00667" checked={this.props.pageState.JYU00667} {...params} />
                                        <span className="checkBox-text">45.道路法</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190 is-dispborder">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00633" name="JYU00633" checked={this.props.pageState.JYU00633} {...params} />
                                        <span className="checkBox-text">10.新住宅市街地開発法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01650" name="JYU01650" checked={this.props.pageState.JYU01650} {...params} />
                                        <span className="checkBox-text">46.踏切道改良促進法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00679" name="JYU00679" checked={this.props.pageState.JYU00679} {...params} />
                                        <span className="checkBox-text">58.災害対策基本法</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00634" name="JYU00634" checked={this.props.pageState.JYU00634} {...params} />
                                        <span className="checkBox-text">11.新都市基盤整備法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00644" name="JYU00644" checked={this.props.pageState.JYU00644} {...params} />
                                        <span className="checkBox-text">21.港湾法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00655" name="JYU00655" checked={this.props.pageState.JYU00655} {...params} />
                                        <span className="checkBox-text">33.水防法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00668" name="JYU00668" checked={this.props.pageState.JYU00668} {...params} />
                                        <span className="checkBox-text">47.全国新幹線鉄道整備法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00680" name="JYU00680" checked={this.props.pageState.JYU00680} {...params} />
                                        <span className="checkBox-text">59.東日本大震災復興特別区域法</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00635" name="JYU00635" checked={this.props.pageState.JYU00635} {...params} />
                                        <span className="checkBox-text">12.旧市街地改造法（旧防災建築街区造成法において準用する場合に限る。）</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00645" name="JYU00645" checked={this.props.pageState.JYU00645} {...params} />
                                        <span className="checkBox-text">22.住宅地区改良法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00656" name="JYU00656" checked={this.props.pageState.JYU00656} {...params} />
                                        <span className="checkBox-text">34.下水道法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00669" name="JYU00669" checked={this.props.pageState.JYU00669} {...params} />
                                        <span className="checkBox-text">48.土地収用法</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190 is-dispborder">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00646" name="JYU00646" checked={this.props.pageState.JYU00646} {...params} />
                                        <span className="checkBox-text">23.公有地拡大推進法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190 is-dispborder">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00657" name="JYU00657" checked={this.props.pageState.JYU00657} {...params} />
                                        <span className="checkBox-text">35.河川法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00670" name="JYU00670" checked={this.props.pageState.JYU00670} {...params} />
                                        <span className="checkBox-text">49.文化財保護法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00681" name="JYU00681" checked={this.props.pageState.JYU00681} {...params} />
                                        <span className="checkBox-text">60.大規模災害からの復興に関する法律</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190 is-dispborder" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00636" name="JYU00636" checked={this.props.pageState.JYU00636} {...params} />
                                        <span className="checkBox-text">13.首都圏の近郊整備地帯及び都市開発区域の整備に関する法律</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00647" name="JYU00647" checked={this.props.pageState.JYU00647} {...params} />
                                        <span className="checkBox-text">24.農地法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00658" name="JYU00658" checked={this.props.pageState.JYU00658} {...params} />
                                        <span className="checkBox-text">36.特定都市河川浸水被害対策法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00671" name="JYU00671" checked={this.props.pageState.JYU00671} {...params} />
                                        <span className="checkBox-text">50.航空法(自衛隊法において準用する場合を含む。)</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w190 is-dispborder" rowSpan="2">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00648" name="JYU00648" checked={this.props.pageState.JYU00648} {...params} />
                                        <span className="checkBox-text">25.宅地造成等規制法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00659" name="JYU00659" checked={this.props.pageState.JYU00659} {...params} />
                                        <span className="checkBox-text">37.海岸法</span>
                                    </label>
                                </td>
                                <td className="dataTable-body dataTable-body__w190">
                                    <div className="dataTableUnit">
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU01653" name="JYU01653" checked={this.props.pageState.JYU01653} {...params} />
                                            <span className="checkBox-text">61.</span>
                                        </label>
                                        <Input id="ID_JYU01654" name="JYU01654" value={this.props.pageState.JYU01654} {...params} length="wFlexFull" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">制限の概要</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00693" name="JYU00693" value={this.props.pageState.JYU00693} text={SEND_OTHER_PAPER.JYU00693} placeholder={JYU00693LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00693LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
