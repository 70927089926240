import React from "react";
import * as helper from "../helper";
import { InputBase } from "./inputBase";

export default class InputCheckbox extends InputBase {

    /** 通常項目用の入力欄更新時のハンドラ */
    handleUpdate = e => {
        let inputValue = e.target.checked;

        // 値の更新（先祖コンポーネントから渡されたstateの更新）
        let tmp = {};
        tmp[this.props.name] = inputValue; // 値
        tmp[helper.genValidKey(this.props.name)] = true; // チェックボックスなので常にtrue
        this.props.updatePageState(tmp); // 先祖のstateを更新するのでレンダリングが走る
    }

    render() {
        const disabled = this.props.disabled === true ? this.props.disabled : this.isDisabled(this.props.pageState.disableItem);

        return (
            <input type="checkbox" id={this.props.id} name={this.props.name} className="checkBox-input" checked={this.props.checked} onChange={this.handleUpdate} disabled={disabled} />
        );
    }
}