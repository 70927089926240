import { PageBase } from "components/page/user/contract/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import Block1 from "./block1";

class ImpPage15 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     * ※チェックボックスの場合、falseにする
     */
    getPageItems = () => {
        return {
            // 付属書類
            JYU01223: false, // チェック１
            JYU01224: undefined, // 名称１
            JYU01225: false, // チェック２
            JYU01226: undefined, // 名称２
            JYU01227: false, // チェック３
            JYU01228: undefined, // 名称３
            JYU01229: false, // チェック４
            JYU01230: undefined, // 名称４
            JYU01231: false, // チェック５
            JYU01232: undefined, // 名称５
            JYU01233: false, // チェック６
            JYU01234: undefined, // 名称６
            JYU01235: false, // チェック７
            JYU01236: undefined, // 名称７
            JYU01237: false, // チェック８
            JYU01238: undefined, // 名称８
            JYU01239: false, // チェック９
            JYU01240: undefined, // 名称９
            JYU01241: false, // チェック１０
            JYU01242: undefined, // 名称１０
            JYU01243: false, // チェック１１
            JYU01244: undefined, // 名称１１
            JYU01245: false, // チェック１２
            JYU01246: undefined, // 名称１２
            JYU01247: false, // チェック１３
            JYU01248: undefined, // 名称１３
            JYU01249: false, // チェック１４
            JYU01250: undefined, // 名称１４
            JYU01251: false, // チェック１５
            JYU01252: undefined, // 名称１５
            JYU01253: false, // チェック１６
            JYU01254: undefined, // 名称１６
            JYU01255: false, // チェック１７
            JYU01256: undefined, // 名称１７
            JYU01257: false, // チェック１８
            JYU01258: undefined, // 名称１８
            JYU01259: false, // チェック１９
            JYU01260: undefined, // 名称１９
            JYU01261: false, // チェック２０
            JYU01262: undefined, // 名称２０
            JYU01453: false, // チェック２１
            JYU01454: undefined, // 項目名２１
            JYU01266: false, // チェック２２
            JYU01267: undefined, // 項目名２２
            JYU01268: false, // チェック２３
            JYU01269: undefined, // 項目名２３
            JYU01270: false, // チェック２４
            JYU01271: undefined, // 項目名２４
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 付属書類 */}
                </ul>
            </React.Fragment>
        );
    }
}

export default withRouter(ImpPage15);