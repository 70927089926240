import { Auth } from "aws-amplify";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect, Route } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as awsReq from "util/awsRequest";
import { LOGGING_OUT_KEY } from "../config/localStorage";
import * as pageInfo from "../constants/pageInfo";
import * as AdminActions from "../redux/actions/authority";
import * as cognitoTokenActions from "../redux/actions/cognitoToken";
import * as loginActions from "../redux/actions/login";
import * as idTokenUtils from "../util/idTokenUtils";
import * as sessionUtils from "../util/sessionUtils";

/**
 * ログイン状態でのみアクセス可能な画面へのルーティング用
 * ログインしていない場合、ログイン画面にリダイレクトする
 */
export class PrivateRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            doRender: false,
            isIpAddressOK: undefined, // undefined: 未チェック, true: OK, false: NG
        };

        // reduxがログイン状態になっている場合、普通にレンダリングを開始する
        // reduxがログイン状態になっていない場合、localStorageを確認する
        if (this.props.login) {
            this.state = { doRender: true };
        } else {
            this.loginCheckByLocalStorage().finally(() => {
                // 認証情報の取得成否にかかわらずレンダリングを開始する
                this.setState({ doRender: true });
            });
        }
    }

    /**
     * reduxがログイン状態になっていない場合のlocalStorageの認証情報チェック
     *
     * ・ログイン時、ログイン状態の変更タイミングを１か所に集約させるため、
     *   ログイン画面のログインボタンハンドラ内でログイン状態に変えるのではなく、
     *   このタイミングでログイン状態にする
     *
     * ・リロード時、reduxがリセットされてログイン状態でなくなるが、
     *   ログインした際の情報がLocalStorageに残っている場合、ログイン状態にする
     */
    loginCheckByLocalStorage = async () => {
        try {
            // ログアウト処理実行中のフラグがたっている場合、強制的に未認証
            if (localStorage.getItem(LOGGING_OUT_KEY)) {
                return;
            }
            // セッションタイムアウト時
            if (sessionUtils.sessionTimeouted()) {
                return;
            }

            const data = await Auth.currentSession();
            const idToken = data.idToken.jwtToken;

            // IDトークンからJWKと公開鍵（PEM形式）を取得する
            const { jwk, pem } = await idTokenUtils.generateJwkPemForIdToken(idToken);

            // IDトークンの検証
            if (!this.checkIdToken(idToken, jwk.alg, pem)) {
                return;
            }

            sessionUtils.setNextSessionTimeout(); // セッションタイムアウト値の設定
            this.props.updateToAuthenticated(); // reduxを認証済み状態へ
            this.props.saveJwkInfo(jwk, pem);
            this.props.saveIdToken(idToken);

            // IDトークンの所属グループがシステム管理者の場合、 システム管理者、確定 を付与
            this.props.updateToNoAdmin();
            const user_groups = data.idToken.payload["cognito:groups"];
            if (typeof user_groups !== "undefined" && user_groups.find(group => group === "system-admin")) {
                this.props.updateToAdmin();
                this.props.updateToConfirm();
            }

            // IDトークンの所属グループが確定の場合、 確定 を付与
            if (typeof user_groups !== "undefined" && user_groups.find(group => group === "confirm")) {
                this.props.updateToConfirm();
            }
        } catch (err) {
            // LocalStorageからの認証プロセスに失敗したらreturn
            return;
        }
    };

    static getDerivedStateFromProps(props, state) {
        // URLの変更があった場合、isIpAddressOK を undefined（未チェック） に設定する

        // ※ PrivateRoute と AdminRoute を横断するような画面遷移をしない場合、
        //    画面遷移後も isIpAddressOK の値が保持され続ける（PrivateRoute が unmount されない）ため、
        //    このタイミングでURLの変更を検知し、能動的に isIpAddressOK を undefined にする。

        // ※ App.js の <XxxxRoute> に対して <XxxxRoute key="xxxx"> を指定することで、 PrivateRoute を常時 unmount → mount することができるが、
        //    key 指定漏れしてしまった時が怖いので、ここで一括管理する

        // URLのパスが変わった場合（state内で変更対象のものだけ指定する形でOK（未指定のモノは現状維持される））
        if (state.prevPath !== props.location.pathname) {
            return {
                prevPath: props.location.pathname,
                isIpAddressOK: undefined,
            };
        }

        // URLが変わらない場合は変更なし
        return null;
    }

    componentDidUpdate(prevProps) {
        // 未ログインであればIPアドレス制限チェックAPIをコールしない
        if (!this.loggedIn()) {
            return;
        }

        // URLのパスが変わっている場合、IPアドレス制限チェックAPIのコール
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.requestIPAdressCheckAPI();
        }
    }

    /**
     * IPアドレス制限チェックAPIをコールし、その結果に基づいてstate, sessionStorageを更新する
     */
    requestIPAdressCheckAPI = () => {
        awsReq.get(
            AWS.ENDPOINT.PATHS.CHECK_IP_LIMITATION,
            (res) => {
                // NGだった場合、IPアドレス制限画面の表示用にIPアドレスを確保
                if (res.data.is_permitted === false) {
                    sessionStorage.setItem("denied_ip_address", res.data.client_ip_address);
                }
                this.setState({ isIpAddressOK: res.data.is_permitted });
            },
            (error) => {
                console.log(error);
                alert(ERROR_MESSAGE.SERVER_ERROR);
            },
            null,
            {},
            AWS.ENDPOINT.REST_API.NAME
        );
    };

    /**
     * 通常の画面遷移時にログイン状態であるかどうかの確認
     * 
     * @return {boolean} true: ログイン状態である、false: ログイン状態でない
     */
    loggedIn = () => {
        try {
            // ログアウト処理実行中のフラグがたっている場合、強制的に未認証
            if (localStorage.getItem(LOGGING_OUT_KEY)) {
                return false;
            }

            // LocalStorageのIDトークンの存在チェック
            if (!idTokenUtils.existLocalStorage()) {
                return false;
            }

            // redux内部状態が未ログインの場合はログイン状態にしない
            if (this.props.login === false) {
                throw new Error("unauthenticated");
            }

            // IDトークンがreduxに保持されていない場合は検証NG
            if (!this.props.idToken) {
                throw new Error("token not found");
            }

            // IDトークンの検証
            const kid = idTokenUtils.getKid(this.props.idToken);
            const idToken = this.props.idToken;
            const jwk = this.props.jwkInfo[kid].jwk;
            const pem = this.props.jwkInfo[kid].pem;
            if (!this.checkIdToken(idToken, jwk.alg, pem)) {
                throw new Error("invalidated token");
            }
        } catch (err) {
            // サインアウトの処理を共通化させる
            return false;
        }
        return true;
    };

    /**
     * IDトークンの検証
     * 失敗した場合、reduxを未ログイン状態にする
     * 
     * @return {boolean} true:成功、false:失敗
     */
    checkIdToken = (idToken, alg, pem) => {
        return idTokenUtils.checkIdToken(idToken, alg, pem);
    };

    render() {
        // ログインチェックが完了するまで空のレンダリングを行う
        // ※LocalStorage側のチェックが非同期処理となっており、認証チェック終了より先にrender()に来るため、
        //   チェックが終了するまで正式画面のレンダリング開始をさせないための救済if文
        if (this.state.doRender === false) {
            return null;
        }

        // セッションタイムアウト時
        if (sessionUtils.sessionTimeouted()) {
            // サインアウト処理を実行する
            this.props.updateToUnauthenticated();
            return <Redirect to={pageInfo.SESSION_TIMEOUT.path} />;
        }

        // ログインしていない場合、ログイン画面へリダイレクトする
        if (!this.loggedIn()) {
            // サインアウト処理を実行する
            this.props.updateToUnauthenticated();
            return <Redirect to={`${pageInfo.LOGIN.path}?${pageInfo.LOGIN.QUERY.from}=${this.props.location.pathname}`} />;
        }

        // IPアドレス制限チェックが完了するまで空のレンダリングを行う
        if (this.state.isIpAddressOK === undefined) {
            this.requestIPAdressCheckAPI();
            return null;
        }

        // IPアドレスチェックがNGの場合
        if (this.state.isIpAddressOK === false) {
            // サインアウト処理を実行する
            this.props.updateToUnauthenticated();
            return <Redirect to={pageInfo.IP_ADDRESS_NG.path} />;
        }

        // セッションタイムアウト値を更新する
        sessionUtils.setNextSessionTimeout();

        // ReduxのidTokenを最新状態に更新
        Auth.currentSession().then(data => {
            this.props.saveIdToken(data.idToken.jwtToken);
        });

        // PrivateRoute の attribute を Routeのattribute へ
        // children の attribute を childrenのattribute へマッピングする
        const Component = this.props.children;
        return (
            <Route {...this.props}>
                <Component.type {...Component.props} />
            </Route>
        );
    }
}

const mapStateToProps = state => {
    return {
        login: state.loginStatus,
        idToken: state.idToken,
        jwkInfo: state.jwkInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(loginActions, dispatch),
        ...bindActionCreators(cognitoTokenActions, dispatch),
        ...bindActionCreators(AdminActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));
