export const MESSAGES = {
    CONTRACT_EDIT: {
        PAGE_CHANGE: "入力画面を切り替えると入力／変更された情報が保存されません。\n保存してよろしいですか？",
        COMMIT_1ST: "確定すると契約情報は変更・削除できなくなり、過去物件参照の対象となります。確定してよろしいですか？",
        COMMIT_2ND: "間違いございませんか？本当に確定してよろしいですか？",
        DELETE_1ST: "削除すると契約情報は復活できません。削除してよろしいですか？",
        DELETE_2ND: "間違いございませんか？本当に削除してよろしいですか？",
        CONFIRM_SAVE: "入力済みで宜しいでしょうか？",
        NOTIFY_SAVE: "一定時間、情報が保存されません。保存してよろしいですか？",
        ERROR_SAVE: "保存に失敗しました。入力内容をご確認ください。",
        MAPFILE_UPLOADED: ":message: をアップロード済み",
        RELEASE_1ST: "確定解除すると契約情報の変更・削除が可能となります。確定解除してよろしいですか？",
        RELEASE_2ND: "間違いございませんか？本当に確定解除してよろしいですか？",
    },
};

/** 別紙送り用文言 */
export const SEND_OTHER_PAPER = {
    TOR00199: "以下、別紙に続く\n-----\n備考の続き\n",
    JYU00147: "以下、別紙に続く\n-----\n区分所有建物の備考の続き\n",
    JYU00196: "以下、別紙に続く\n-----\n敷地権の目的たる土地の備考の続き\n",
    JYU00296: "以下、別紙に続く\n-----\n土地の備考の続き\n",
    JYU00322: "以下、別紙に続く\n-----\n建物の備考の続き\n",
    JYU00327: "以下、別紙に続く\n-----\n売主の表示の続き\n",
    JYU00331: "以下、別紙に続く\n-----\n占有に関する事項の続き\n",
    JYU01423: "以下、別紙に続く\n-----\n登記記録に記録された事項の続き\n",
    JYU00412: "以下、別紙に続く\n-----\n都市計画法、建築基準法の法令に基づく制限の続き\n",
    JYU00513: "以下、別紙に続く\n-----\n建築基準法に基づく制限の続き\n",
    JYU00558: "以下、別紙に続く\n-----\n前面道路の続き\n",
    JYU00587: "以下、別紙に続く\n-----\n地方公共団体の条例等による制限の続き\n",
    JYU00693: "以下、別紙に続く\n-----\n都市計画法、建築基準法以外の法令に基づく制限の続き\n",
    JYU00701: "以下、別紙に続く\n-----\n対象不動産に含まれる私道に関する負担の内容の続き\n",
    JYU00705: "以下、別紙に続く\n-----\n対象不動産に含まれない私道に関する事項の続き\n",
    JYU00785: "以下、別紙に続く\n-----\n飲用水・電気・ガスの供給施設および排水施設の整備状況の続き\n",
    JYU00804: "以下、別紙に続く\n-----\n一棟の建物またはその敷地に関する権利およびこれからの管理・使用に関する事項の続き\n",
    JYU00813: "以下、別紙に続く\n-----\n共有部分に関する規約等の続き\n",
    JYU00833: "以下、別紙に続く\n-----\n専用部分の用途その他の制限に関する規約等の続き\n",
    JYU00918: "以下、別紙に続く\n-----\n一棟の建物およびその敷地の専用使用権についての続き\n",
    JYU00934: "以下、別紙に続く\n-----\n対象不動産に付随する専用使用権の備考の続き\n",
    JYU01222: "以下、別紙に続く\n-----\nその他重要な事項の続き\n",
    JYU01748: "以下、別紙に続く\n-----\n備考の続き\n",
    JYU01776: "以下、別紙に続く\n-----\n備考の続き\n",
    JYU00979: "以下、別紙に続く\n-----\nその他の続き\n",
    JYU00982: "以下、別紙に続く\n-----\n建物状況調査の結果の概要の続き\n",
    JYU01013: "以下、別紙に続く\n-----\n建物の建築及び維持保全の状況に関する書類の保存の状況の続き\n",
    JYU01020: "以下、別紙に続く\n-----\n建築確認済証等の発行年月日・番号の続き\n",
    JYU01100: "以下、別紙に続く\n-----\n売買代金および交換差金以外に売主・買主間で授受される金銭の額の備考の続き\n",
    JYU01121: "以下、別紙に続く\n-----\n契約の解除に関する事項の続き\n",
    JYU01457: "以下、別紙に続く\n-----\n宅地または建物に関する事項の続き\n",
    FUD00336: "以下、別紙に続く\n-----\n特記事項の続き\n",
    FUD00109: "以下、別紙に続く\n-----\n特記事項の続き\n",
    FUD00135: "以下、別紙に続く\n-----\n特記事項の続き\n",
    FUD00202: "以下、別紙に続く\n-----\n定期借地権の備考の続き\n",
    FUD00333: "以下、別紙に続く\n-----\n特約の続き\n",
    JYU01478: "以下、別紙に続く\n-----\n契約不適合責任（瑕疵担保責任）の履行に関する措置の概要の続き\n",
    JYU00371: "以下、別紙に続く\n-----\n土地の権利部（甲区）の続き\n",
    JYU00375: "以下、別紙に続く\n-----\n土地の権利部（乙区）の続き\n",
    JYU00352: "以下、別紙に続く\n-----\n建物の権利部（甲区）の続き\n",
    JYU00359: "以下、別紙に続く\n-----\n建物の権利部（乙区）の続き\n",
    JYU01783: "以下、別紙に続く\n-----\n区分所有建物の権利部（甲区）の続き\n",
    JYU01786: "以下、別紙に続く\n-----\n区分所有建物の権利部（乙区）の続き\n",
};

/** 約款・別表アップロード */
export const DOCUMENT_UPLOAD = {
    UPLOAD_COMPLETE: "PDFファイルをアップロードしました", // TODO: メッセージを決めてもらう
};