import BaseGrid from "components/common/frame/baseGrid";
import { InputSelectBoxRecord, InputText, InputTextRecord } from "components/common/parts/inputRecord";
import Pagination from "components/common/parts/pagination";
import * as AWS from "constants/aws";
import { CONTRACT_EDIT_INFO_FRK, CONTRACT_TYPE_FRK } from "constants/contractEditPageInfo";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as loginUserAction from "redux/actions/loginUserAction";
import * as sidemenuAction from "redux/actions/sidemenuAction";
import * as awsReq from "util/awsRequest";
import * as dateUtil from "util/dateUtil";

/** 契約書情報一覧画面 */
class ContractList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: undefined, // 表示するべきデータを保持する（APIからのレスポンスが入る）
            keyword: "", // キーワード
            sale: "", // 担当営業
            org: "", // 支店／組織
            updatedOnFrom: "", // 最終更新日（から）
            updatedOnTo: "", // 最終更新日（まで）
            isCondo: false, // 区分所有建物
            isLand: false, // 土地／土地建物
            isConfirm: false, // 確定済
            confirmFrom: "", // 確定日（から）
            confirmTo: "", // 確定日（まで）
            location: "", // 所在地
            contractTypeId: "", // 契約書式
            // useFormat: "",  // ログインユーザ権限

            activePage: 1, // 現ページ
            itemsCountPerPage: 10, // 1ページに何件表示するか
            totalItemsCount: 0, // 総件数
            pageRangeDisplayed: 5, // ページネーションで表示するページ数
        };
        this.apicallingPast = 0; // APIコール中のページを保持（0はコール無し。連続API防止措置なので簡易）
    }

    componentDidMount() {
        document.title = pageInfo.CONTRACT_LIST_FRK.title;
        this.RequestAPI();
    }

    componentDidUpdate(prevProps) {
        // 保持しているAPIからの情報がクリアされた場合、再度APIコールする
        if (this.state.data === undefined) {
            this.RequestAPI();
            return;
        }
    }

    // 契約書情報一覧取得 APIリクエスト
    RequestAPI = () => {

        if (this.props.authority !== null) {
            if (this.apicallingPast !== this.state.activePage) {
                this.apicallingPast = this.state.activePage;

                let query = { page: this.state.activePage, };
                if (this.state.selectedOrg !== undefined && this.state.selectedOrg !== "") {
                    query.agency_branch_id = this.state.selectedOrg;
                }

                // キーワードがある時はキーワードをクエリに入れる
                if (this.state.keyword !== "") {
                    query.keyword = this.state.keyword;
                }

                // 担当営業がある時はキーワードをクエリに入れる
                if (this.state.sale !== "") {
                    query.staff_name = this.state.sale;
                }

                // 支店／組織がある時はキーワードをクエリに入れる
                if (this.state.org !== "") {
                    query.branch_name = this.state.org;
                }

                // 最終更新日（開始）がある時はクエリに入れる
                if (this.state.updatedOnFrom !== "") {
                    query.updated_on_from = this.state.updatedOnFrom.replace(/\//g, "-");
                }
                // 最終更新日（終了）がある時はクエリに入れる
                if (this.state.updatedOnTo !== "") {
                    query.updated_on_to = this.state.updatedOnTo.replace(/\//g, "-");
                }

                query.is_condo = this.state.isCondo; // 区分所有建物
                query.is_land = this.state.isLand; // 土地／土地建物

                query.is_confirm = this.state.isConfirm; // 確定日の確定済
                // 確定日（開始）がある時はクエリに入れる
                if (this.state.confirmFrom !== "") {
                    query.confirm_on_from = this.state.confirmFrom.replace(/\//g, "-");
                }
                // 確定日（終了）がある時はクエリに入れる
                if (this.state.confirmTo !== "") {
                    query.confirm_on_to = this.state.confirmTo.replace(/\//g, "-");
                }

                // 所在地がある時はキーワードをクエリに入れる
                if (this.state.location !== "") {
                    query.location = this.state.location;
                }

                // 契約書式がある時はキーワードをクエリに入れる
                if (this.state.contractTypeId !== "") {
                    query.contract_type_id = this.state.contractTypeId;
                }

                query.input_format = "02"; // 入力書式

                awsReq.post(
                    AWS.ENDPOINT.PATHS.CONTRACT_LIST,
                    res => {
                        this.apicallingPast = 0;
                        // 現在表示しているページに対応しているレスポンスの場合、レスポンスをセットする
                        // ※レスポンスを全て画面反映させるとページ送りした分の全ての結果が順番に表示されてしまうため
                        // ※1ページ目を表示している時に8ページ目に行くためには、1,5,8と遷移する必要があるが（この時、5は中継地点なので結果表示の必要がない）、
                        //   レスポンスを全て画面表示すると、8ページ目を表示する前に5ページ目の内容を一瞬表示してしまうことの対策
                        if (this.state.activePage === res.data.page_number) {
                            this.setState({
                                data: res.data.contracts,
                                itemsCountPerPage: res.data.per_page,
                                totalItemsCount: res.data.total_records,
                            });
                        }
                    },
                    error => {
                        this.apicallingPast = 0;
                        this.setState({ data: [] });
                        alert(ERROR_MESSAGE.SERVER_ERROR);
                    },
                    query,
                    {}, AWS.ENDPOINT.REST_API.NAME,
                );
            }
        }
    }

    /** キーワード入力時のハンドラ */
    handleKeywordInput = e => {
        e.preventDefault();
        this.setState({ keyword: e.target.value });
    }

    /** 担当営業入力時のハンドラ */
    handleSaleInput = e => {
        e.preventDefault();
        this.setState({ sale: e.target.value });
    }

    /** 支店／組織入力時のハンドラ */
    handleOrgInput = e => {
        e.preventDefault();
        this.setState({ org: e.target.value });
    }

    /** 最終更新日（開始）入力時のハンドラ */
    handleUpdatedOnFromInput = e => {
        e.preventDefault();
        this.setState({ updatedOnFrom: e.target.value });
    }

    /** 最終更新日（開始）入力時のハンドラ（blurした時に強制的に日付に戻す） */
    blurUpdatedOnFromInput = e => {
        e.preventDefault();
        if (e.target.value === "") { return; }

        let st = "";
        const date = new Date(e.target.value);
        if (date.toString() === "Invalid Date") { st = "1980/01/01"; }
        else { st = date.toLocaleDateString("ja-JP", { timezone: "JST", year: "numeric", month: "2-digit", day: "2-digit", }); }
        this.setState({ updatedOnFrom: st });
    }

    /** 最終更新日（終了）入力時のハンドラ */
    handleUpdatedOnToInput = e => {
        e.preventDefault();
        this.setState({ updatedOnTo: e.target.value });
    }

    /** 最終更新日（終了）入力時のハンドラ（blurした時に強制的に日付に戻す） */
    blurUpdatedOnToInput = e => {
        e.preventDefault();
        if (e.target.value === "") { return; }

        let st = "";
        const date = new Date(e.target.value);
        if (date.toString() === "Invalid Date") { st = "2199/12/31"; }
        else { st = date.toLocaleDateString("ja-JP", { timezone: "JST", year: "numeric", month: "2-digit", day: "2-digit", }); }
        this.setState({ updatedOnTo: st });
    }

    /** 区分所有建物のハンドラ */
    handleIsCondoCheck = e => {
        this.setState({ isCondo: !this.state.isCondo });
    }

    /** 土地／土地建物のハンドラ */
    handleIsLandCheck = e => {
        this.setState({ isLand: !this.state.isLand });
    }

    /** 確定済チェック時のハンドラ */
    handleisConfirmCheck = e => {
        this.setState({ isConfirm: !this.state.isConfirm, confirmFrom: "", confirmTo: "" });
    }

    /** 確定日（開始）入力時のハンドラ */
    handleconfirmFromInput = e => {
        e.preventDefault();
        this.setState({ confirmFrom: e.target.value });
    }

    /** 確定日（開始）入力時のハンドラ（blurした時に強制的に日付に戻す） */
    blurconfirmFromInput = e => {
        e.preventDefault();
        if (e.target.value === "") { return; }

        let st = "";
        const date = new Date(e.target.value);
        if (date.toString() === "Invalid Date") { st = "1980/01/01"; }
        else { st = date.toLocaleDateString("ja-JP", { timezone: "JST", year: "numeric", month: "2-digit", day: "2-digit", }); }
        this.setState({ confirmFrom: st });
    }

    /** 確定日（終了）入力時のハンドラ */
    handleconfirmToInput = e => {
        e.preventDefault();
        this.setState({ confirmTo: e.target.value });
    }

    /** 確定日（終了）入力時のハンドラ（blurした時に強制的に日付に戻す） */
    blurconfirmToInput = e => {
        e.preventDefault();
        if (e.target.value === "") { return; }

        let st = "";
        const date = new Date(e.target.value);
        if (date.toString() === "Invalid Date") { st = "2199/12/31"; }
        else { st = date.toLocaleDateString("ja-JP", { timezone: "JST", year: "numeric", month: "2-digit", day: "2-digit", }); }
        this.setState({ confirmTo: st });
    }

    /** 所在地のハンドラ */
    handleLocation = e => {
        e.preventDefault();
        this.setState({ location: e.target.value });
    }

    /** セレクトボックスの入力値をstateに格納 */
    handleChangeInputSelectBox = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** キャンセルボタン押下のハンドラ */
    handleCancelBtn = e => {
        e.preventDefault();
        this.props.onClose();
    }

    /** 絞り込みボタン用のハンドラ */
    handlePickupBtn = e => {
        e.preventDefault();
        this.setState({ data: undefined, activePage: 1 });
    }

    // クリアボタン押下のハンドラ
    handleClearBtn = e => {
        e.preventDefault();
        const clear = {
            data: undefined, // APIコールを再実行させる
            activePage: 1,

            keyword: "", // キーワード
            sale: "", // 担当営業
            org: "", // 支店／組織
            updatedOnFrom: "", // 最終更新日（から）
            updatedOnTo: "", // 最終更新日（まで）
            isConfirm: false, // 確定済
            confirmFrom: "", // 確定日（から）
            confirmTo: "", // 確定日（まで）
            isCondo: false, // 区分所有建物
            isLand: false, // 土地／土地建物
            location: "", // 所在地
            contractTypeId: "", // 契約書式
        };
        this.setState(clear);
    }

    handleEditBtn = (e, idx) => {
        e.preventDefault();
        this.props.history.push(
            pageInfo.CONTRACT_EDIT_FRK.path
                .replace(":contractId", this.state.data[idx].id)
                .replace(":pageId", CONTRACT_EDIT_INFO_FRK.PAGE_0101.id)
            + '?param=' + dateUtil.tzStrToParm()
        );
    }

    // ページネーションのハンドラ
    handlePagination = page => {
        this.setState({ data: undefined, activePage: page });
    }

    render() {

        // APIから値を取得している間は絞り込むボタンを押下させないようにする
        const pickupParams = (this.state.data !== undefined)
            ? { className: "baseAction", onClick: this.handlePickupBtn }
            : { className: "baseAction baseAction--inactive", onClick: e => e.preventDefault() };

        // ページネーションの設定
        const pageSettings = {
            activePage: this.state.activePage,
            itemsCountPerPage: this.state.itemsCountPerPage,
            totalItemsCount: this.state.totalItemsCount,
            pageRangeDisplayed: this.state.pageRangeDisplayed,
            onChange: this.handlePagination,
        };

        // セレクトリストの生成
        const contractTypes = CONTRACT_TYPE_FRK;
        let contractTypeList = contractTypes
            ? contractTypes.map(contractType => ({ value: contractType.contract_type_id, display: contractType.name }))
            : [];
        contractTypeList.unshift({ value: '', display: '' });

        const titleSetting = { className: "dataTable-title dataTable-title__w120" };
        const bodysetting = { className: "dataTable-body dataTable-body__w355" };

        // 確定済みにチェックを入れた場合、確定日の入力を非活性にする
        const disabledRelateIsConfirmed = this.state.isConfirm;

        return (
            <BaseGrid>
                <div className="dataList dataList__accordion is-open">
                    <div className="dataList-header">絞り込み条件</div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <InputTextRecord titleName="キーワード"
                                        inputData={{ name: "keyword", value: this.state.keyword || "" }}
                                        titleSetting={titleSetting}
                                        bodySetting={{ bodysetting, colSpan: "3" }}
                                        inputSetting={{ onChange: this.handleKeywordInput }} />
                                </tr>
                                <tr>
                                    <InputTextRecord titleName="担当営業"
                                        inputData={{ name: "sale", value: this.state.sale || "" }}
                                        titleSetting={titleSetting}
                                        bodySetting={bodysetting}
                                        inputSetting={{ onChange: this.handleSaleInput }} />
                                    <InputTextRecord titleName="支店/組織"
                                        inputData={{ name: "org", value: this.state.org || "" }}
                                        titleSetting={titleSetting}
                                        bodySetting={bodysetting}
                                        inputSetting={{ onChange: this.handleOrgInput }} />
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120">最終更新日</th>
                                    <td className="dataTable-body">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-input">
                                                <InputText
                                                    inputData={{ name: "updatedOnFrom", value: this.state.updatedOnFrom || "" }}
                                                    inputSetting={{ onChange: this.handleUpdatedOnFromInput, onBlur: this.blurUpdatedOnFromInput }} />
                                            </div>
                                            <div className="dataTableUnit-text">〜</div>
                                            <div className="dataTableUnit-input">
                                                <InputText
                                                    inputData={{ name: "updatedOnTo", value: this.state.updatedOnTo || "" }}
                                                    inputSetting={{ onChange: this.handleUpdatedOnToInput, onBlur: this.blurUpdatedOnToInput }} />
                                            </div>
                                        </div>
                                    </td>
                                    <td className="dataTable-body" colSpan="2">
                                        <ul className="l-checkBox">
                                            <li>
                                                <label className="checkBox">
                                                    <input type="checkbox" className="checkBox-input" checked={this.state.isCondo} onChange={this.handleIsCondoCheck} />
                                                    <span className="checkBox-text">区分所有建物 </span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <input type="checkbox" className="checkBox-input" checked={this.state.isLand} onChange={this.handleIsLandCheck} />
                                                    <span className="checkBox-text">土地／土地建物</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120">確定日</th>
                                    <td className="dataTable-body" colSpan="3">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-input">
                                                <input type="text" aria-label="text" value={this.state.confirmFrom}
                                                    onChange={this.handleconfirmFromInput}
                                                    onBlur={this.blurconfirmFromInput}
                                                    disabled={disabledRelateIsConfirmed}
                                                />
                                            </div>
                                            <div className="dataTableUnit-text">〜</div>
                                            <div className="dataTableUnit-input">
                                                <input type="text" aria-label="text" value={this.state.confirmTo}
                                                    onChange={this.handleconfirmToInput}
                                                    onBlur={this.blurconfirmToInput}
                                                    disabled={disabledRelateIsConfirmed}
                                                />
                                            </div>
                                            <div className="dataTableUnit-text"></div>
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <input type="checkbox" className="checkBox-input" checked={this.state.isConfirm} onChange={this.handleisConfirmCheck} />
                                                        <span className="checkBox-text">確定済 </span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <InputTextRecord titleName="所在地"
                                        inputData={{ name: "location", value: this.state.location || "" }}
                                        titleSetting={titleSetting}
                                        bodySetting={bodysetting}
                                        inputSetting={{ onChange: this.handleLocation }} />
                                    <InputSelectBoxRecord titleName="契約書式"
                                        inputData={{ name: "contractTypeId", value: this.state.contractTypeId || "" }}
                                        optionsDataList={contractTypeList} titleSetting={titleSetting}
                                        inputSetting={{ onChange: this.handleChangeInputSelectBox }} />
                                </tr>
                                <tr>
                                    <td className="dataTable-body" colSpan="4">
                                        <ul className="l-baseAction l-baseAction__rightTwo">
                                            <li><Link to="" aria-label="link" className="baseAction" {...pickupParams}>絞り込み</Link></li>
                                            <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleClearBtn}>クリア</Link></li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div >
                </div>
                <Pagination pageSettings={pageSettings} />
                <div className="dataList dataList__accordion is-open">
                    <div className="dataList-header">
                        <div className="dataList-header-title">契約情報一覧</div>
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title">稟議書No.</th>
                                    <th className="dataTable-title">物件名</th>
                                    <th className="dataTable-title">書式</th>
                                    <th className="dataTable-title">担当営業</th>
                                    <th className="dataTable-title">支店／組織</th>
                                    <th className="dataTable-title">最終更新日時</th>
                                    <th className="dataTable-title">&nbsp;</th>
                                </tr>
                                {this.createListDOM()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination pageSettings={pageSettings} />
            </BaseGrid >
        );
    }

    createListDOM = () => {
        const array = this.state.data;

        // arrayが空の場合、null
        if (array === undefined || array == null) {
            return null;
        }

        return array.map((element, idx) => {
            const datetime = dateUtil.tzStrToDateTime(element.updated_at);
            return (
                <React.Fragment key={element.id}>
                    <tr>
                        <th className="dataTable-title">{element.document_no}</th>
                        <td className="dataTable-body">{element.building_name}</td>
                        <td className="dataTable-body">{element.contract_type_name_frk}</td>
                        <td className="dataTable-body">{element.staff_name}</td>
                        <td className="dataTable-body">{element.branch_name}</td>
                        <td className="dataTable-body">{datetime}</td>
                        <td className="dataTable-body">
                            <Link to="" aria-label="link" className="baseAction" onClick={e => { this.handleEditBtn(e, idx); }}>編集</Link>
                        </td>
                    </tr>
                </React.Fragment>
            );
        });
    }
}

const mapStateToProps = state => {
    return {
        useFormat: state.useFormat,
        authority: state.authority
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(sidemenuAction),
        ...bindActionCreators(loginUserAction, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractList));
