export function updateToAdmin() {
    return {
        type: "ADMIN"
    };
}

export function updateToNoAdmin() {
    return {
        type: "NO_ADMIN"
    };
}

export function updateToConfirm() {
    return {
        type: "CONFIRM"
    };
}

export function updateToNotConfirm() {
    return {
        type: "NOT_CONFIRM"
    };
}
