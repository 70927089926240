import React from "react";
import InputDate from "../../input/inputDate";
import Input from "../../input/inputNormal";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 宅建業者が自ら売主となる新築住宅の場合にあっては、施行規則第18条第1項第8号に掲げる事項 */
export default class Block11 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010111",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">宅建業者が自ら売主となる新築住宅の場合にあっては、施行規則第18条第1項第8号に掲げる事項</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">引き渡した<br />年月日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00286" name="TOR00286" value={this.props.pageState.TOR00286} {...params} />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">床面積</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00287" name="TOR00287" value={this.props.pageState.TOR00287} {...params} />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w597" colSpan="3">住宅瑕疵担保責任保険法人の名称</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00292" name="TOR00292" value={this.props.pageState.TOR00292} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="5">２以上の宅地建物取引業者それぞれの販売瑕疵負担割合の合計に対する当該宅地建物取引業者の販売瑕疵負担割合の割合</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">A</th>
                                <th className="dataTable-title dataTable-title__w120">商号</th>
                                <td className="dataTable-body dataTable-body__w444">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00288" name="TOR00288" value={this.props.pageState.TOR00288} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">負担割合</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00289" name="TOR00289" value={this.props.pageState.TOR00289} {...params} />
                                        <div className="dataTableUnit-text">%</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">B</th>
                                <th className="dataTable-title dataTable-title__w120">商号</th>
                                <td className="dataTable-body dataTable-body__w444">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00290" name="TOR00290" value={this.props.pageState.TOR00290} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">負担割合</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00291" name="TOR00291" value={this.props.pageState.TOR00291} {...params} />
                                        <div className="dataTableUnit-text">%</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}