import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";
import Select from "../../input/inputSelect";

/** 代理人の署名による買主／売主の印刷 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030301",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // FUD00372の設定値がない場合、デフォルト値（氏名／住所を印刷する）を追加する
        if (this.props.pageState.FUD00372 === undefined) {
            this.props.pageState.FUD00372 = "氏名／住所を印刷する";
        }

        const buyerDOM = this.createBuyerDOM(this.props.pageState.TOR00094_ARR, this.props.pageState.FUD00348_ARR);
        const sellerDOM = this.createSellerDOM(this.props.pageState.TOR00072_ARR, this.props.pageState.FUD00345_ARR);

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">37条書面及び代理人の署名による買主／売主の印刷</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240">37条書面への印刷</th>
                                <td className="dataTableUnit">
                                    <Select id="ID_FUD00372" name="FUD00372" value={this.props.pageState.FUD00372} items={this.getPrintSelectbox(this.props.selectboxData.MEI00008)} {...params} length="w250" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis_single" colSpan="3">買主</th>
                            </tr>
                            {buyerDOM}
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis_single" colSpan="3">売主</th>
                            </tr>
                            {sellerDOM}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    /** 買主のリストDOM生成 */
    createBuyerDOM = (TOR00094_ARR, FUD00348_ARR) => {
        // 動的項目の代表名
        // ※ループの対象は TOR00094_ARR だけど、入力項目としては FUD00348_ARR なので FUD00348_ARR を指定。。
        const arrayName = "FUD00348_ARR";
        const params = {
            arrayName: arrayName,
            pageState: this.props.pageState,
            updatePageState: this.props.updatePageState,
        };

        return TOR00094_ARR.map((item, idx) => {

            return (
                <React.Fragment key={idx}>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">{idx + 1}</th>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">氏名</th>
                        <td className="dataTable-body">
                            <div className="dataTableUnit dataTableUnit--between">
                                <div className="dataTableUnit-moretext">{item.TOR00094}</div>
                                <Select idx={idx} mode="dynamic" id={`ID_FUD00348_${idx}`} name="FUD00348" value={FUD00348_ARR[idx].FUD00348} items={this.getPrintSelectbox(this.props.selectboxData.MEI00008)} {...params} length="w250" />
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            );
        });
    }

    /** 売主のリストDOM生成 */
    createSellerDOM = (TOR00072_ARR, FUD00345_ARR) => {
        // 動的項目の代表名
        // ※ループの対象は TOR00072_ARR だけど、入力項目としては FUD00345_ARR なので FUD00345_ARR を指定。。
        const arrayName = "FUD00345_ARR";
        const params = {
            arrayName: arrayName,
            pageState: this.props.pageState,
            updatePageState: this.props.updatePageState,
        };

        return TOR00072_ARR.map((item, idx) => {

            return (
                <React.Fragment key={idx}>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">{idx + 1}</th>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">氏名</th>
                        <td className="dataTable-body">
                            <div className="dataTableUnit dataTableUnit--between">
                                <div className="dataTableUnit-moretext">{item.TOR00072}</div>
                                <Select idx={idx} mode="dynamic" id={`ID_FUD00345_${idx}`} name="FUD00345" value={FUD00345_ARR[idx].FUD00345} items={this.getPrintSelectbox(this.props.selectboxData.MEI00008)} {...params} length="w250" />
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            );
        });
    }
}