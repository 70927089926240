import BaseGrid from "components/common/frame/baseGrid";
import PaginationPage from "components/common/frame/paginationPage";
import { InputSelectBoxRecord, InputTextRecord } from "components/common/parts/inputRecord";
import { OutputRecord } from "components/common/parts/outputRecord";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";

/** 定型文一覧画面 */
class TemplateList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: undefined,  // 定型文一覧
            templateHeadlineId: undefined, // 今選択されている見出しのID
            page: 1, // 現ページ
            totalRecords: 0, // 総件数
        };
        this.apicalling = 0; // APIコール中のページを保持（0はコール無し。連続API防止措置なので簡易）
    }

    componentDidMount() {
        document.title = pageInfo.TEMPLATE_LIST.title;
        this.requestTemplateHeadlines();
    }

    componentDidUpdate() {
        // 見出し一覧の読み込みが終わっていて定型文一覧の読込がまだの場合にAPIコール
        if (this.state.templates === undefined && this.state.templateHeadlineId !== undefined) {
            this.requestTemplateList();
        }
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputText = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** 絞り込みボタンのハンドラ */
    handleSerch = e => {
        e.preventDefault();
        this.setState({ templates: undefined, page: 1 });
    }

    /** クリアボタンのハンドラ */
    handleClear = async e => {
        e.preventDefault();
        this.setState({ page: 1, keyword: "" });
        this.setState({ templates: undefined });
    }

    /** ページネーションのハンドラ */
    handlePageNumber = page => {
        this.setState({ page: page });
        this.setState({ templates: undefined });
    }

    /** 見出しセレクトボックスのハンドラ */
    handleChangeSelectedHeadlineAndSerch = e => {
        this.setState({ templateHeadlineId: e.target.value });
        this.setState({ templates: undefined, page: 1 });
    }

    render() {
        // 見出し一覧未取得時は画面非表示
        if (this.state.templateHeadlines === undefined) {
            return null;
        }

        // 定型文一覧Dom
        const templatesList = this.state.templates === undefined ? [] : this.state.templates;
        const templatesListDom = templatesList
            ? templatesList.map(item => {
                const outputList = [
                    item.templateItemId,
                    item.templateItem,
                    () => (<Link to={pageInfo.TEMPLATE_MAINTENANCE.path.replace(":templateHeadlineId", this.state.templateHeadlineId).replace(":type", item.id)} aria-label="link" className="baseAction">詳細</Link>)
                ];
                return (<OutputRecord outputList={outputList} key={item.id} />);
            })
            : null;

        // 見出しリスト
        const templateHeadlines = this.state.templateHeadlines;
        const templateHeadlinesList = templateHeadlines.map(item => ({ value: item.headlineId, display: item.headline }));


        // APIから値を取得している間は絞り込むボタンを押下させないようにする
        const pickupParams = (this.state.templates !== undefined)
            ? { className: "baseAction", onClick: this.handleSerch }
            : { className: "baseAction baseAction--inactive", onClick: e => e.preventDefault() };

        return (
            <BaseGrid>
                <div className="dataList dataList__accordion is-open">
                    <div className="dataList-header">絞り込み条件</div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <InputTextRecord titleName="キーワード"
                                        inputData={{ name: "keyword", value: this.state.keyword || "" }}
                                        titleSetting={{ className: "dataTable-title dataTable-title__w120" }}
                                        bodySetting={null} inputSetting={{ onChange: this.handleChangeInputText }} />
                                </tr>

                                <tr>
                                    <td className="dataTable-body" colSpan="4">
                                        <ul className="l-baseAction l-baseAction__rightTwo">
                                            <li><Link to="" aria-label="link" {...pickupParams}>絞り込み</Link></li>
                                            <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleClear}>クリア</Link></li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div >
                </div >

                <div className="l-dataTable">
                    <table className="dataTable dataTable__fullBorder">
                        <tbody>
                            <tr>
                                <InputSelectBoxRecord titleName="見出し"
                                    inputData={{ name: "templateHeadlineId", value: this.state.templateHeadlineId || "" }}
                                    optionsDataList={templateHeadlinesList}
                                    titleSetting={{ className: "dataTable-title dataTable-title__w120" }}
                                    bodySetting={null} inputSetting={{ onChange: this.handleChangeSelectedHeadlineAndSerch }} />
                            </tr>
                        </tbody>
                    </table>
                </div>

                <PaginationPage
                    activePage={this.state.page}
                    totalItemsCount={this.state.totalRecords}
                    onChange={this.handlePageNumber}
                    toCreate={
                        pageInfo.TEMPLATE_MAINTENANCE.path
                            .replace(":templateHeadlineId", this.state.templateHeadlineId)
                            .replace(":type", "create")
                    }>

                    <div className="dataList dataList__accordion is-open">
                        <div className="dataList-header"><div className="dataList-header-title">定型文一覧</div></div>
                        <div className="dataList-body">
                            <table className="dataTable">
                                <tbody>
                                    <tr>
                                        <th className="dataTable-title is-emphasis dataTable-title__w120">項目ID</th>
                                        <th className="dataTable-title is-emphasis">項目</th>
                                        <th className="dataTable-title is-emphasis dataTable-title__w110"></th>
                                    </tr>

                                    {templatesListDom}
                                </tbody>
                            </table>
                        </div >
                    </div >
                </PaginationPage >
            </BaseGrid >
        );
    }

    /** 見出し一覧のリクエスト */
    requestTemplateHeadlines = () => {
        // 取得API呼出し
        awsReq.get(
            AWS.ENDPOINT.PATHS.TEMPLATE_HEADLINES,
            response => {
                const res = convert.convertToFlatCamelCase(response.data);
                this.setState({ templateHeadlines: res });
                this.setState({ templateHeadlineId: res[0].headlineId });
            },
            awsReq.defaultErrorHandler,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    /** 定型文一覧のリクエスト */
    requestTemplateList = () => {
        if (this.apicalling !== this.state.page) {
            this.apicalling = this.state.page;

            const requestBody = {
                page: this.state.page,
                headline_id: this.state.templateHeadlineId,
                keyword: this.state.keyword ? this.state.keyword : undefined
            };

            // 取得API呼出し
            awsReq.post(
                AWS.ENDPOINT.PATHS.TEMPLATE_LIST,
                response => {
                    this.apicalling = 0;
                    // 現在表示しているページとページ番号が同じレスポンスだった場合に画面へ反映させる
                    // ※古いリクエストが返ってきても無視する
                    if (this.state.page === response.data.page_number) {
                        this.setState(convert.convertToFlatCamelCase(response.data));
                    }
                },
                error => {
                    this.apicalling = 0;
                    this.setState({ templates: [] });
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                },
                requestBody,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }
}

export default withRouter(TemplateList);
