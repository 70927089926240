import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 瑕疵担保責任の履行に関する措置の概要 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021401",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        //JYU01478の入力上限判定
        const JYU01478LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU01478_${this.props.displayTypeID}`, this.props.pageState.JYU01478);

        // 斜線対応の設定
        let DomDiagonalLine = undefined;
        if (this.onloadFlg && this.props.pageState.JYU01217) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_JYU01217");
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU01217:AJYU01217-1")}>
                <div className="dataList-header">
                    <div className="dataList-header-title">契約不適合責任（瑕疵担保責任）の履行に関する措置の概要</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w280">瑕疵担保責任の履行に関する措置</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01216" name="JYU01216" checked={this.props.pageState.JYU01216} {...params} />
                                                <span className="checkBox-text">1.講じる</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01217" name="JYU01217" checked={this.props.pageState.JYU01217} {...params} />
                                                <span className="checkBox-text">2.講じない　※入力不要の場合は、斜線を引きます。</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            {DomDiagonalLine}
                            <tr id="AJYU01217-1">
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope id="ID_JYU01478" name="JYU01478" value={this.props.pageState.JYU01478} text={SEND_OTHER_PAPER.JYU01478} placeholder={JYU01478LimitErrorMessage.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU01478LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};
