import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect, Route } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as pageInfo from "../constants/pageInfo";
import * as AdminActions from "../redux/actions/authority";
import * as cognitoTokenActions from "../redux/actions/cognitoToken";
import * as loginActions from "../redux/actions/login";
import { PrivateRoute } from "./privateRoute";

/**
 * ログイン画面のルーティング用
 * ログインしていない場合、ログイン画面を表示
 * ログインしている場合、ログイン画面後にリダイレクトする
 */
class LoginRoute extends PrivateRoute {

    // ログイン画面ではIPアドレスチェック不要なので、componentDidMount, componentDidUpdate を空定義する
    componentDidMount() { }
    componentDidUpdate() { }

    render() {
        // 大まかな処理の詳細はPrivateRoute側参照
        let dom;
        const Component = this.props.children;
        if (this.state.doRender === false) {
            return null;
        }

        // ログイン済みの場合、ログイン後画面へリダイレクトする
        // ログインしていない場合、ログイン画面を表示する
        if (this.loggedIn()) {
            // ログイン済みの状態でログインのURLを直打ちしてきた人用のルート
            dom = <Redirect to={pageInfo.DASHBOARD.path}></Redirect>;
        } else {
            dom = (
                <Route {...this.props}>
                    <Component.type {...Component.props} />
                </Route>
            );
        }

        return dom;
    }
}

const mapStateToProps = state => {
    return { login: state.loginStatus, idToken: state.idToken, jwkInfo: state.jwkInfo };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(loginActions, dispatch),
        ...bindActionCreators(cognitoTokenActions, dispatch),
        ...bindActionCreators(AdminActions, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginRoute));
