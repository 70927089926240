import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import InputDate from "components/page/user/contract/edit/input/inputDate";
import Input from "components/page/user/contract/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { CONTRACT_EDIT_INFO } from "constants/contractEditPageInfo";
import React from "react";
import { Link } from "react-router-dom";
import Validation from "util/validate";

/** 敷地権の目的たる土地 */
export default class Block3 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030103",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    handleTo0101 = e => {
        e.preventDefault();
        // リロードをかける
        this.props.pageSwitch(CONTRACT_EDIT_INFO.PAGE_0101.id);
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        const itemsDOM = this.createDOMTOR00217_ARR(this.props.pageState);

        return (
            <React.Fragment>
                <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">敷地権の目的たる土地の表示</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                {itemsDOM}
                                <tr>
                                    <th className="dataTable-title is-emphasis" colSpan="5">敷地権の種類が借地権の場合</th>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">土地所有者</th>
                                    <th className="dataTable-title dataTable-title__w120">住所</th>
                                    <td className="dataTable-body dataTable-body__w295">
                                        <div className="dataTableUnit">
                                            <Input id="ID_FUD00066" name="FUD00066" value={this.props.pageState.FUD00066} {...params} length="wFull" />
                                        </div>
                                    </td>
                                    <th className="dataTable-title dataTable-title__w120">氏名</th>
                                    <td className="dataTable-body dataTable-body__w295">
                                        <div className="dataTableUnit">
                                            <Input id="ID_FUD00067" name="FUD00067" value={this.props.pageState.FUD00067} {...params} length="wFull" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">期限</th>
                                    <td className="dataTable-body dataTable-body" colSpan="4">
                                        <div className="dataTableUnit">
                                            <InputDate until oneline id="ID_FUD00070" name="FUD00070" value={this.props.pageState.FUD00070} {...params} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">種類</th>
                                    <td className="dataTable-body" colSpan="4">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-input">
                                                <ul className="l-checkBox">
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_FUD00072" name="FUD00072" checked={this.props.pageState.FUD00072} {...params} />
                                                            <span className="checkBox-text">1.旧法による借地権</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_FUD00073" name="FUD00073" checked={this.props.pageState.FUD00073} {...params} />
                                                            <span className="checkBox-text">2.普通借地権</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="dataTableUnit-text">地代</div>
                                            <div className="dataTableUnit-text">月額</div>
                                            <Input id="ID_FUD00075" name="FUD00075" value={this.props.pageState.FUD00075} {...params} />
                                            <div className="dataTableUnit-text">円</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="l-dataListMore">
                    <Link to="" aria-label="link" className="dataListMore" onClick={this.handleTo0101}>上記内容を修正したい場合は、こちらをクリックしてください</Link>
                </div>
            </React.Fragment>
        );
    }

    createDOMTOR00217_ARR = pageState => {

        const arrays = pageState.TOR00217_ARR;

        if (!arrays) {
            return null;
        }

        const arraysDOM = arrays.map((item, idx) => {

            return (
                <React.Fragment key={idx}>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="5">{idx + 1}</th>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在および<br />地番</th>
                        <td className="dataTable-body" colSpan="3">{item.TOR00217}</td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">地目</th>
                        <td className="dataTable-body" colSpan="3">{item.TOR00218}</td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">地積</th>
                        <td className="dataTable-body" colSpan="3">公簿　{item.TOR00219}m<sup>2</sup> 実測　{item.TOR00220}m<sup>2</sup></td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">敷地権の種類</th>
                        <td className="dataTable-body" colSpan="3">{item.TOR00221}</td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">持分</th>
                        <td className="dataTable-body" colSpan="3">{item.TOR00222}</td>
                    </tr>
                </React.Fragment>
            );
        });

        return (
            <React.Fragment>
                {arraysDOM}
                <tr>
                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">合計</th>
                    <td className="dataTable-body" colSpan="4">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-moretext">{pageState.TOR00235}</div>
                            <div className="dataTableUnit-moretext">筆</div>
                            <div className="dataTableUnit-moretext">地積（登記簿）{pageState.TOR00236}m<sup>2</sup></div>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}