import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import InputDate from "components/page/user/contractFrk/edit/input/inputDate";
import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import { CONTRACT_EDIT_INFO_FRK } from "constants/contractEditPageInfo";
import React from "react";
import { Link } from "react-router-dom";
import Validation from "util/validate";

/** 土地 */
export default class Block4 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030104",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    handleTo0101 = e => {
        e.preventDefault();
        // リロードをかける
        this.props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0101.id);
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        const itemsDOM = this.createDOMTOR00237_ARR(this.props.pageState);

        // 非敷地権の時、表示項目が追加される
        let nonRightOfSite = undefined;

        // 斜線
        let DomDiagonalLine = undefined;
        if (this.onloadFlg && this.props.pageState.FUD00379) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_FUD00379");
        }

        if (this.props.contractType.indexOf("非敷地権") !== -1) {
            nonRightOfSite = (<React.Fragment>
                <tr>
                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">権利の種類</th>
                    <td className="dataTable-body" colSpan="4">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-input">
                                <ul className="l-checkBox">
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox disabled id="ID_JYU00291" name="JYU00291" checked={this.props.pageState.JYU00291} {...params} />
                                            <span className="checkBox-text">1.所有権</span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox disabled id="ID_JYU00292" name="JYU00292" checked={this.props.pageState.JYU00292} {...params} />
                                            <span className="checkBox-text">2.借地権</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div className="dataTableUnit-text">（</div>
                            <div className="dataTableUnit-input">
                                <ul className="l-checkBox">
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox disabled id="ID_JYU00293" name="JYU00293" checked={this.props.pageState.JYU00293} {...params} />
                                            <span className="checkBox-text">地上権</span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox disabled id="ID_JYU00294" name="JYU00294" checked={this.props.pageState.JYU00294} {...params} />
                                            <span className="checkBox-text">賃借権</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div className="dataTableUnit-text">）</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th className="dataTable-title is-emphasis" colSpan="5">
                        <div className="dataTableUnit dataTableUnit--center">
                            <div className="dataTableUnit-text">権利の種類が借地権の場合</div>
                            <div className="dataTableUnit-text">(</div>
                            <div className="dataTableUnit-input">
                                <ul className="l-checkBox">
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_FUD00379" name="FUD00379" checked={this.props.pageState.FUD00379} {...params} />
                                            <span className="checkBox-text">無　※チェックした場合は、斜線を引きます。</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div className="dataTableUnit-text">)</div>
                        </div>
                    </th>
                </tr>
                {DomDiagonalLine}
                <tr id="AR_FUD00379-1">
                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">土地所有者</th>
                    <th className="dataTable-title dataTable-title__w120">住所</th>
                    <td className="dataTable-body dataTable-body__w295">
                        <div className="dataTableUnit">
                            <Input id="ID_FUD00066" name="FUD00066" value={this.props.pageState.FUD00066} {...params} length="wFull" />
                        </div>
                    </td>
                    <th className="dataTable-title dataTable-title__w120">氏名</th>
                    <td className="dataTable-body dataTable-body__w295">
                        <div className="dataTableUnit">
                            <Input id="ID_FUD00067" name="FUD00067" value={this.props.pageState.FUD00067} {...params} length="wFull" />
                        </div>
                    </td>
                </tr>
                <tr id="AR_FUD00379-2">
                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">登記の有無</th>
                    <td className="dataTable-body dataTable-body" colSpan="4">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-input">
                                <ul className="l-checkBox">
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_FUD00158" name="FUD00158" checked={this.props.pageState.FUD00158} {...params} />
                                            <span className="checkBox-text">1.有</span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_FUD00353" name="FUD00353" checked={this.props.pageState.FUD00353} {...params} />
                                            <span className="checkBox-text">2.無</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr id="AR_FUD00379-3">
                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">期限</th>
                    <td className="dataTable-body dataTable-body" colSpan="4">
                        <div className="dataTableUnit">
                            <InputDate until oneline id="ID_FUD00070" name="FUD00070" value={this.props.pageState.FUD00070} {...params} />
                        </div>
                    </td>
                </tr>
                <tr id="AR_FUD00379-4">
                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">種類</th>
                    <td className="dataTable-body" colSpan="4">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-input">
                                <ul className="l-checkBox">
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_FUD00072" name="FUD00072" checked={this.props.pageState.FUD00072} {...params} />
                                            <span className="checkBox-text">1.旧法による借地権</span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_FUD00073" name="FUD00073" checked={this.props.pageState.FUD00073} {...params} />
                                            <span className="checkBox-text">2.普通借地権</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div className="dataTableUnit-text">地代</div>
                            <div className="dataTableUnit-text">月額</div>
                            <Input id="ID_FUD00075" name="FUD00075" value={this.props.pageState.FUD00075} {...params} />
                            <div className="dataTableUnit-text">円</div>
                        </div>
                    </td>
                </tr>
            </React.Fragment>);
        }

        return (
            <React.Fragment>
                <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_FUD00379:AR_FUD00379-1,AR_FUD00379-2,AR_FUD00379-3,AR_FUD00379-4")}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">土地</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                {itemsDOM}
                                {nonRightOfSite}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="l-dataListMore">
                    <Link to="" aria-label="link" className="dataListMore" onClick={this.handleTo0101}>上記内容を修正したい場合は、こちらをクリックしてください</Link>
                </div>
            </React.Fragment>
        );
    }


    createDOMTOR00237_ARR = pageState => {

        const arrays = pageState.TOR00237_ARR;

        if (!arrays) {
            return null;
        }

        const arraysDOM = arrays.map((item, idx) => {

            const tibanDisp = idx === 0 ? "地番（代表）" : "地番";

            // 非敷地権の場合は4件までしか表示しない
            if (this.props.contractType.indexOf("非敷地権") !== -1 && idx >= 4) {
                return <></>;
            }

            return (
                <React.Fragment key={idx}>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="5">{idx + 1}</th>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在</th>
                        <td className="dataTable-body" colSpan="3">{item.TOR00237}</td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">{tibanDisp}</th>
                        <td className="dataTable-body" colSpan="3">{item.TOR00238}　番　{item.TOR00239}</td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">地目</th>
                        <td className="dataTable-body" colSpan="3">{item.TOR00240}</td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">地積</th>
                        <td className="dataTable-body" colSpan="3">公簿　{item.TOR00241}m<sup>2</sup></td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">持分</th>
                        <td className="dataTable-body" colSpan="3">{item.TOR00243}</td>
                    </tr>
                </React.Fragment>
            );
        });

        // 借地面積の表示／非表示
        let leasedLandArea = undefined;

        if (this.props.contractType.indexOf("借地権付建物") !== -1) {
            leasedLandArea = (<tr>
                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">借地面積</th>
                <td className="dataTable-body" colSpan="4">
                    <div className="dataTableUnit">
                        <div className="dataTableUnit-moretext">{pageState.JYU00295}m<sup>2</sup></div>
                    </div>
                </td>
            </tr>);
        }

        // 新築・土地建物の時項目が追加される
        let newLandAndBuilding = undefined;

        if (this.props.contractType.indexOf("新築・土地建物") !== -1) {
            newLandAndBuilding = (<tr>
                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">実測面積</th>
                <td className="dataTable-body" colSpan="4">
                    <div className="dataTableUnit">
                        <div className="dataTableUnit-moretext">{pageState.JYU00251}m<sup>2</sup></div>
                        <div className="dataTableUnit-moretext">{pageState.JYU01788}</div>
                    </div>
                </td>
            </tr>);
        }

        return (
            <React.Fragment>
                {arraysDOM}
                <tr>
                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">合計</th>
                    <td className="dataTable-body" colSpan="4">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-moretext">{pageState.TOR00256}</div>
                            <div className="dataTableUnit-moretext">筆</div>
                            <div className="dataTableUnit-moretext">地積（登記簿）{pageState.TOR00257}m<sup>2</sup></div>
                        </div>
                    </td>
                </tr>
                {leasedLandArea}
                {newLandAndBuilding}
            </React.Fragment>
        );
    }
}