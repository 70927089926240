import { AES, enc } from "crypto-js";
import { SESSION_TIMEOUT, SESSION_TIMEOUT_KEY, SESSION_TIMEOUT_SECRET } from "../config/localStorage";


/**
 * 次回タイムアウトまでのunixミリ秒を設定
 */
export const setNextSessionTimeout = () => {
    var date = new Date();
    var next = parseInt(date.getTime()) + parseInt(SESSION_TIMEOUT);
    // 数字のままだと直接操作されるので暗号化する
    var crypt = AES.encrypt(next + "", SESSION_TIMEOUT_SECRET);
    localStorage.setItem(SESSION_TIMEOUT_KEY, crypt.toString());
};

/**
 * 次回タイムアウトまでのunixミリ秒取得
 */
export const getNextSessionTimeout = () => {
    var decypt = AES.decrypt(localStorage.sessionTimeout + "", SESSION_TIMEOUT_SECRET);
    return parseInt(decypt.toString(enc.Utf8));
};

/**
 * タイムアウトしているかどうかの判定
 * ※基本的に new Date().getTime() が nowへ、getNextSessionTimeout() がexpireへ入るのを想定
 *
 * true: している、false: していない
 */
export const sessionTimeouted = (now = new Date().getTime(), expire = getNextSessionTimeout()) => {
    if (now > expire) {
        return true;
    }
    return false;
};