import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import React from "react";

/** 宅地または建物に関する事項そのほか */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021301",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">宅地造成または建物建築の工事完了時における形状・構造等（対象不動産が未完成物件または新規物件のとき）</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w480 dataTable-title__left">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00787" name="JYU00787" checked={this.props.pageState.JYU00787} {...params} />
                                        <span className="checkBox-text">別紙にて説明します。</span>
                                    </label>
                                </th>
                                <td className="dataTable-body">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00788" name="JYU00788" checked={this.props.pageState.JYU00788} {...params} />
                                        <span className="checkBox-text">未完成物件または新規物件に該当しないので、説明を省略します。</span>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}