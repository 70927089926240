import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import React from "react";
import Validation from "util/validate";
import Input from "../../input/inputNormal";
import InputTextArea from "../../input/inputTextArea";

/** 当該宅地建物　区域 */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021302",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 文字数制限定義
        const JYU01554LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU01554",
                this.props.pageState.JYU01554);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">当該宅地建物　区域</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w160 dataTable-title__left" colSpan="2">石綿使用調査結果の記録の有無</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01039" name="JYU01039" checked={this.props.pageState.JYU01039} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01281" name="JYU01281" checked={this.props.pageState.JYU01281} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w160 dataTable-title__left" colSpan="2">宅地造成等規制法に基づく造成宅地防災区域</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01027" name="JYU01027" checked={this.props.pageState.JYU01027} {...params} />
                                                <span className="checkBox-text">内</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01028" name="JYU01028" checked={this.props.pageState.JYU01028} {...params} />
                                                <span className="checkBox-text">外</span>
                                            </label>
                                        </li>
                                        <li>
                                            <Input id="ID_JYU01730" name="JYU01730" value={this.props.pageState.JYU01730} {...params} length="w483" />
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w160 dataTable-title__left" colSpan="2">土砂災害防止対策推進法に基づく土砂災害警戒区域</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01029" name="JYU01029" checked={this.props.pageState.JYU01029} {...params} />
                                                <span className="checkBox-text">内</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01030" name="JYU01030" checked={this.props.pageState.JYU01030} {...params} />
                                                <span className="checkBox-text">外</span>
                                            </label>
                                        </li>
                                        <li>
                                            <Input id="ID_JYU01731" name="JYU01731" value={this.props.pageState.JYU01731} {...params} length="w483" />
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w160 dataTable-title__left" colSpan="2">津波防災地域づくりに関する法律に基づく津波災害警戒区域</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01033" name="JYU01033" checked={this.props.pageState.JYU01033} {...params} />
                                                <span className="checkBox-text">内</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01034" name="JYU01034" checked={this.props.pageState.JYU01034} {...params} />
                                                <span className="checkBox-text">外</span>
                                            </label>
                                        </li>
                                        <li>
                                            <Input id="ID_JYU01732" name="JYU01732" value={this.props.pageState.JYU01732} {...params} length="w483" />
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w100 dataTable-title__left" rowSpan="3">水害ハザードマップ</th>
                                <th className="dataTable-title dataTable-title__w140 dataTable-title__left">洪水</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01548" name="JYU01548" checked={this.props.pageState.JYU01548} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01549" name="JYU01549" checked={this.props.pageState.JYU01549} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w140 dataTable-title__left">雨水出水(内水)</th>
                                <th className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01550" name="JYU01550" checked={this.props.pageState.JYU01550} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01551" name="JYU01551" checked={this.props.pageState.JYU01551} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w140 dataTable-title__left">高潮</th>
                                <th className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01552" name="JYU01552" checked={this.props.pageState.JYU01552} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01553" name="JYU01553" checked={this.props.pageState.JYU01553} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w160 dataTable-title__left">水害ハザードマップにおける宅地建物の所在地</th>
                                <td className="dataTable-body" colSpan="3">
                                    <InputTextArea id="ID_JYU01554" name="JYU01554" value={this.props.pageState.JYU01554} {...params} placeholder={JYU01554LimitMsg.inputLimitGuide} {...params} />
                                    <p className="LengthOver-error">{JYU01554LimitMsg.inputLimitMsg}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}