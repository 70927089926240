
import { Auth } from "aws-amplify";
import { LOGGING_OUT_KEY, SESSION_TIMEOUT_KEY } from "../../config/localStorage";

export function updateToAuthenticated() {
    return {
        type: "AUTH"
    };
}

export function updateToUnauthenticated() {

    // セッションタイムアウトの設定値を削除
    localStorage.removeItem(SESSION_TIMEOUT_KEY);
    // ログアウト実行中のフラグを立てる
    localStorage.setItem(LOGGING_OUT_KEY, true);
    // cognitoのサインアウト実行
    Auth.signOut({ global: true }).finally(() => {
        localStorage.clear();
    });

    return {
        type: "UNAUTH"
    };
}

export function reduxAllClear() {

    return {
        type: "ALL_CLEAR"
    };
}