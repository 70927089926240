import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import InputDate from "components/page/user/contract/edit/input/inputDate";
import Input from "components/page/user/contract/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 一棟の建物またはその敷地に関する権利およびこれらの管理・使用に関する事項 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020801",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        //JYU00804の入力上限判定
        const JYU00804LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00804_${this.props.displayTypeID}`, this.props.pageState.JYU00804);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">一棟の建物またはその敷地に関する権利およびこれらの管理・使用に関する事項</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-body is-emphasis" colSpan="4">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-text is-emphasis">敷地に関する権利の種類および内容</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center">敷地面積</th>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">実測面積</div>
                                        <Input id="ID_JYU00790" name="JYU00790" value={this.props.pageState.JYU00790} {...params} length="w112" />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                        <div className="dataTableUnit-text">登記簿面積</div>
                                        <Input id="ID_JYU00791" name="JYU00791" value={this.props.pageState.JYU00791} {...params} length="w112" />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                        <div className="dataTableUnit-text">建築確認の対象面積</div>
                                        <Input id="ID_JYU00792" name="JYU00792" value={this.props.pageState.JYU00792} {...params} length="w112" />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center">権利の種類</th>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00793" name="JYU00793" checked={this.props.pageState.JYU00793} {...params} />
                                                        <span className="checkBox-text">1.所有権</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00794" name="JYU00794" checked={this.props.pageState.JYU00794} {...params} />
                                                        <span className="checkBox-text">2.借地権</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">（</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00795" name="JYU00795" checked={this.props.pageState.JYU00795} {...params} />
                                                        <span className="checkBox-text">地上権</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00796" name="JYU00796" checked={this.props.pageState.JYU00796} {...params} />
                                                        <span className="checkBox-text">貸借権</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center">借地権の場合</th>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">対象面積</div>
                                        <Input id="ID_JYU00797" name="JYU00797" value={this.props.pageState.JYU00797} {...params} length="w112" />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                        <div className="dataTableUnit-text">（</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00798" name="JYU00798" checked={this.props.pageState.JYU00798} {...params} />
                                                        <span className="checkBox-text">登記簿</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00799" name="JYU00799" checked={this.props.pageState.JYU00799} {...params} />
                                                        <span className="checkBox-text">実測</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center">借地権の<br />存続期間</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate noEra id="ID_JYU00800" name="JYU00800" value={this.props.pageState.JYU00800} {...params} length="w112" />
                                        <div className="dataTableUnit-text">まで</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__center">区分所有者の負担額<br />（地代）：月額</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00801" name="JYU00801" value={this.props.pageState.JYU00801} {...params} length="w145" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00804" name="JYU00804" value={this.props.pageState.JYU00804} text={SEND_OTHER_PAPER.JYU00804} placeholder={JYU00804LimitErrorMessage.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00804LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}