import { Footer } from "components/common/parts/footerAuth";
import Header from "components/common/parts/header";
import Sidemenu from "components/common/parts/sidemenu";
import React from "react";

/**
 * ログイン後画面において、ベースとなるコンポーネント
 * ヘッダーやサイドメニューがある
 */
export default function BaseGrid(props) {
    return (
        <div className="l-baseGrid">
            <div className="l-baseGrid-menu">
                <Sidemenu />
            </div>
            <main className="l-baseGrid-main">
                <div className="l-baseGrid-main-hed">
                    <div className="mainTopCard-hed">
                        <Header />
                    </div>
                </div>
                {props.noBody ?
                    props.children
                    : (<div className="l-baseGrid-main-body">{props.children}</div>)
                }
                <Footer />
            </main>
        </div>
    );
}
