import BaseGrid from "components/common/frame/baseGrid";
import PaginationPage from "components/common/frame/paginationPage";
import { InputSelectBoxRecord } from "components/common/parts/inputRecord";
import { OutputRecord } from "components/common/parts/outputRecord";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";

/** 名称マスタ一覧画面 */
class InputItemList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1, // 現ページ
            totalRecords: 0, // 総件数
            inputItemType: undefined, // 表示対象の名称区分ID
            inputItemTypes: undefined, // 名称マスタ名称区分一覧取得APIから取得した名称区分ID
        };
        this.apicalling = 0; // APIコール中のページを保持（0はコール無し。連続API防止措置なので簡易）
    }

    componentDidMount() {
        document.title = pageInfo.INPUT_ITEM_LIST.title;
        this.requestInputItemTypes();
    }

    componentDidUpdate() {
        if (this.state.inputItems === undefined) {
            this.requestInputItemList();
        }
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputText = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** 名称区分変更時のハンドラ */
    handleChangeSelectedInputItemAndSerch = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
        this.setState({ inputItems: undefined, page: 1 });
    }

    handlePageNumber = page => {
        this.setState({ page: page });
        this.setState({ inputItems: undefined });
    }

    render() {
        if (!this.state.inputItemTypes) {
            return null;
        }

        // 名称区分リスト
        const inputItemTypes = this.state.inputItemTypes;
        const inputItemTypesList = inputItemTypes
            ? inputItemTypes.map(inputItemType => ({ value: inputItemType.value, display: inputItemType.name }))
            : [];

        // 名称マスタ一覧Dom
        const inputItemList = this.state.inputItems;
        const inputItemListDom = inputItemList
            ? inputItemList.map(inputItem => {
                const outputList = [
                    inputItem.displayId,
                    inputItem.inputItemName,
                    () => (<Link to={pageInfo.INPUT_ITEM_MAINTENANCE_UPDATE.path.replace(":type", this.state.inputItemType).replace(":id", inputItem.id)} aria-label="link" className="baseAction">詳細</Link>)
                ];
                return (<OutputRecord outputList={outputList} key={inputItem.id} />);
            })
            : null;

        return (
            <BaseGrid>
                <div className="dataList dataList__accordion is-open">
                    <div className="dataList-header">絞り込み条件</div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <InputSelectBoxRecord titleName="名称区分"
                                        inputData={{ name: "inputItemType", value: this.state.inputItemType || "" }}
                                        optionsDataList={inputItemTypesList}
                                        titleSetting={{ className: "dataTable-title dataTable-title__w120" }}
                                        inputSetting={{ onChange: this.handleChangeSelectedInputItemAndSerch }} />
                                </tr>
                            </tbody>
                        </table>
                    </div >
                </div >

                {
                    this.state.inputItemType && (
                        <PaginationPage activePage={this.state.page} totalItemsCount={this.state.totalRecords} onChange={this.handlePageNumber}
                            toCreate={pageInfo.INPUT_ITEM_MAINTENANCE.path.replace(":type", this.state.inputItemType)}>
                            <div className="dataList dataList__accordion is-open">
                                <div className="dataList-header"><div className="dataList-header-title">名称マスタ一覧</div></div>
                                <div className="dataList-body">
                                    <table className="dataTable">
                                        <tbody>
                                            <tr>
                                                <th className="dataTable-title is-emphasis dataTable-title__w120">名称ID</th>
                                                <th className="dataTable-title is-emphasis">名称</th>
                                                <th className="dataTable-title is-emphasis dataTable-title__w110"></th>
                                            </tr>

                                            {inputItemListDom}
                                        </tbody>
                                    </table>
                                </div>
                            </div >
                        </PaginationPage >
                    )
                }
            </BaseGrid>
        );
    }

    requestInputItemTypes = () => {
        awsReq.get(
            AWS.ENDPOINT.PATHS.INPUT_ITEM_TYPES,
            response => {
                this.setState({
                    inputItemTypes: response.data,
                    inputItemType: response.data[0].value,
                });
            },
            awsReq.defaultErrorHandler,
            {}, {}, AWS.ENDPOINT.REST_API.NAME
        );
    }

    requestInputItemList = () => {
        if (this.apicalling !== this.state.page) {
            this.apicalling = this.state.page;

            awsReq.post(
                AWS.ENDPOINT.PATHS.INPUT_ITEM_LIST,
                response => {
                    this.apicalling = 0;
                    // 現在表示しているページとページ番号が同じレスポンスだった場合に画面へ反映させる
                    // ※古いリクエストが返ってきても無視する
                    if (this.state.page === response.data.page_number) {
                        this.setState(convert.convertToFlatCamelCase(response.data));
                    }
                },
                error => {
                    this.apicalling = 0;
                    this.setState({ inputItems: [] });
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                },
                { page: this.state.page, input_item_type: this.state.inputItemType },
                {}, AWS.ENDPOINT.REST_API.NAME
            );
        }
    }
}

export default withRouter(InputItemList);
