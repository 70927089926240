import Pagination from "components/common/parts/pagination";
import * as AWS from "constants/aws";
import { EDIT_AUTHORITY } from "constants/contractEditPageInfo";
import { ERROR_MESSAGE } from "constants/errorMessage";
import React from "react";
import { Link } from "react-router-dom";
import * as awsReq from "util/awsRequest";

/** システム管理画面：従業員情報一覧 */
export default class EmployeeList extends React.Component {
    constructor(props) {
        super(props);

        // this.props.active: ポップアップがアクティブかどうか
        // this.props.shareList: 現在選択されている共有先として従業員のリスト
        // ※[{ "agency_user_id":"sampleuser001", "sharing_authority_id":"01" }, ...]

        this.state = {
            data: undefined, // 表示するべきデータを保持する（APIからのレスポンスが入る）
            keyword: "", // キーワード
            activePage: 1, // 現ページ
            itemsCountPerPage: 10, // 1ページに何件表示するか
            totalItemsCount: 0, // 総件数
            pageRangeDisplayed: 5, // ページネーションで表示するページ数
            selectbox: undefined, // セレクトボックスに表示する項目リスト
            selectedOrg: undefined, // 組織／支店のセレクトボックスで、選択されている項目のvalue

            // ポップアップ内で選択されている従業員リストとして使用する（オブジェクトのディープコピーを行う）
            // ※確定 or キャンセルが押下されるまでのテンポラリとして使用
            selectedShareList: JSON.parse(JSON.stringify(this.props.shareList)),
        };
        this.apicallingEmp = 0; // 従業員一覧取得のAPIコール中のページを保持（連続API防止措置なので簡易）
    }

    componentDidMount() {
        // セレクトボックスに表示するための情報取得
        awsReq.get(
            AWS.ENDPOINT.PATHS.BRANCHAND_LIST,
            res => {
                this.setState({ selectbox: [""].concat(res.data), });
            },
            awsReq.defaultErrorHandler,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    componentDidUpdate(prevProps) {
        // ポップアップが表示されるタイミングでリロードする
        if (prevProps.active === false && this.props.active === true) {
            this.setState({
                data: undefined, // APIレスポンス保持情報のクリア
                selectedShareList: JSON.parse(JSON.stringify(this.props.shareList)), // チェック済みリストをテンポラリの最新状態に戻す
            });
        }

        // 保持しているAPIからの情報がクリアされた場合、再度APIコールする
        if (this.state.data === undefined && this.props.active === true) {
            this.RequestAPI();
            return;
        }
    }

    // キーワード入力時のハンドラ
    handleKeywordInput = e => {
        e.preventDefault();
        this.setState({ keyword: e.target.value });
    }

    // 確定ボタン押下のハンドラ
    handleCommitBtn = e => {
        e.preventDefault();
        this.props.onCommit(this.state.selectedShareList);
        this.props.onClose();
    }

    // クリアボタン押下のハンドラ
    handleClearBtn = e => {
        e.preventDefault();
        // selectedOrg は null や undefinedにしても、選択状態がクリアされないので、"" を指定する
        this.setState({ keyword: "", selectedOrg: "", data: undefined, activePage: 1 });
    }

    // 選択済みのチェックボックスの制御
    handleCheck = i => {
        const apiItem = this.state.data[i];

        // 選択済みチェックのため、現在選択済みのIDのリストを生成する
        const shareIdList = this.state.selectedShareList.map((item, idx) => {
            return item.agency_user_id;
        });

        // 選択済みの場合、除外する
        // ※this.state.data[i]に該当するIDが既に選択済みリストに含まれている場合、チェックを外そうとしているという判断
        if (shareIdList.includes(apiItem.agency_user_id)) {
            const newData = this.state.selectedShareList.filter(item => { return item.agency_user_id !== apiItem.agency_user_id; });
            this.setState({ selectedShareList: newData });
        } else {
            // 未選択の場合、追加する
            let newData = JSON.parse(JSON.stringify(this.state.selectedShareList));

            // テンポラリに既に権限設定が存在している場合、その権限を使用する
            // 設定がない場合は、"閲覧／印刷のみ" を使用する
            // ※従業員一覧ポップアップを開いた時点で選択済みとなっている従業員のチェックボックスを "外す"→"付ける"をやった時、最初の状態に戻すための対処
            const now = this.props.shareList.filter(item => { return item.agency_user_id === apiItem.agency_user_id; });
            const auth = now.length !== 0 ? now[0].sharing_authority_id : EDIT_AUTHORITY.READ_ONLY;
            apiItem.sharing_authority_id = auth;

            newData.push(apiItem);
            this.setState({ selectedShareList: newData });
        }
    }

    // ポップアップの中身を表示するためのAPIリクエスト
    RequestAPI = () => {
        /** 組織一覧取得 */
        if (this.apicallingEmp !== this.state.activePage) {
            this.apicallingEmp = this.state.activePage;

            let query = { page: this.state.activePage, };
            if (this.state.selectedOrg !== undefined && this.state.selectedOrg !== "") {
                query.agency_branch_id = Number(this.state.selectedOrg);
            }

            // キーワードがある時はキーワードをクエリに入れる
            if (this.state.keyword !== "") {
                query.keyword = this.state.keyword;
            }

            awsReq.post(
                AWS.ENDPOINT.PATHS.GET_POPUP_AGENCY_USER_LIST,
                res => {
                    this.apicallingEmp = 0;
                    // 現在表示しているページに対応しているレスポンスの場合、レスポンスをセットする
                    // ※レスポンスを全て画面反映させるとページ送りした分の全ての結果が順番に表示されてしまうため
                    // ※1ページ目を表示している時に8ページ目に行くためには、1,5,8と遷移する必要があるが（この時、5は中継地点なので結果表示の必要がない）、
                    //   レスポンスを全て画面表示すると、8ページ目を表示する前に5ページ目の内容を一瞬表示してしまうことの対策
                    if (this.state.activePage === res.data.page_number) {
                        this.setState({
                            data: res.data.agency_users,
                            itemsCountPerPage: res.data.per_page,
                            totalItemsCount: res.data.total_records,
                        });
                    }
                },
                error => {
                    this.apicallingEmp = 0;
                    this.setState({ data: [] });
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                },
                query,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }

    /** 絞り込みボタン用のハンドラ */
    handlePickupBtn = e => {
        e.preventDefault();
        this.setState({ data: undefined, activePage: 1 });
    }

    /** セレクトボックス用のハンドラ */
    handleSelectbox = e => {
        e.preventDefault();
        this.setState({ selectedOrg: e.target.value });
    }

    /** キャンセルボタン用のハンドラ */
    handleCancelBtn = e => {
        e.preventDefault();
        this.props.onClose();
    }

    // ページネーションのハンドラ
    handlePagination = page => {
        this.setState({ data: undefined, activePage: page });
    }

    render() {
        const displayActive = this.props.active ? "block" : "none";

        // ページネーションの設定
        const pageSettings = {
            activePage: this.state.activePage,
            itemsCountPerPage: this.state.itemsCountPerPage,
            totalItemsCount: this.state.totalItemsCount,
            pageRangeDisplayed: this.state.pageRangeDisplayed,
            onChange: this.handlePagination,
        };

        // APIから値を取得している間は絞り込むボタンを押下させないようにする
        // ※それぞれ、this.state.data が従業員一覧で、this.state.selectbox がセレクトボックスに使う値
        const pickupParams = (this.state.data !== undefined && this.state.selectbox !== undefined)
            ? { className: "baseAction", onClick: this.handlePickupBtn }
            : { className: "baseAction baseAction--inactive", onClick: e => e.preventDefault() };

        return (
            <React.Fragment>
                <div style={{ display: displayActive }}>
                    <main className="l-modalBox">
                        <div className="modalBox">
                            <div className="dataList dataList__accordion is-open">
                                <div className="dataList-header">絞り込み条件</div>
                                <div className="dataList-body">
                                    <table className="dataTable">
                                        <tbody>
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w120">所属支店／組織</th>
                                                <td className="dataTable-body dataTable-body__w355">
                                                    <div className="selectBox">
                                                        <select name="" id="" className="selectBox-select" aria-label="select" value={this.state.selectedOrg} onChange={this.handleSelectbox}>
                                                            {/* セレクトボックスの値部分 */}
                                                            {generateSelectbox(this.state.selectbox)}
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w120">キーワード</th>
                                                <td className="dataTable-body dataTable-body__w355">
                                                    <input type="text" aria-label="text" value={this.state.keyword} onChange={this.handleKeywordInput} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w120">&nbsp;</th>
                                                <td className="dataTable-body dataTable-body__w355">
                                                    <ul className="l-baseAction l-baseAction__rightTwo">
                                                        <li><Link to="" aria-label="link" className="baseAction" {...pickupParams}>絞り込み</Link></li>
                                                        <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleClearBtn}>クリア</Link></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Pagination pageSettings={pageSettings} />
                            <div className="dataList dataList__accordion dataList__modalScroll is-open">
                                <div className="dataList-header">
                                    <div className="dataList-header-title">従業員一覧</div>
                                </div>
                                <div className="dataList-body dataList-body__modalScroll" tabIndex="0">
                                    <table className="dataTable">
                                        <tbody>
                                            <tr>
                                                <th className="dataTable-title">&nbsp;</th>
                                                <th className="dataTable-title">氏名</th>
                                                <th className="dataTable-title">所属支店／組織</th>
                                            </tr>
                                            {/* 従業員一覧のリスト部分 */}
                                            {this.createListDOM()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Pagination pageSettings={pageSettings} />
                            <ul className="l-baseAction l-baseAction__rightTwo">
                                <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleCommitBtn}>選択</Link></li>
                                <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleCancelBtn}>キャンセル</Link></li>
                            </ul>
                        </div>
                    </main>
                </div>
            </React.Fragment>
        );
    }

    /** リスト部分のDOM生成 */
    createListDOM = () => {
        const array = this.state.data;

        // arrayが空の場合、null
        if (array === undefined || array == null) {
            return null;
        }

        const shareIdList = this.state.selectedShareList.map((item, idx) => {
            return item.agency_user_id;
        });

        return array.map((element, idx) => {
            const checked = shareIdList.includes(element.agency_user_id);
            return (
                <tr key={element.agency_user_id}>
                    <td className="dataTable-title">
                        <label className="checkBox checkBox__notext">
                            <input type="checkbox" className="checkBox-input" aria-label="text" onChange={e => { this.handleCheck(idx); }} checked={checked} />
                            <span className="checkBox-text"></span>
                        </label>
                    </td>
                    <td className="dataTable-body">{element.user_name}</td>
                    <td className="dataTable-body">{element.branch_name}</td>
                </tr>
            );
        });
    }
}


/**
 * objは下記の想定（ポップアップ内のセレクトボックス表示用のAPIからのレスポンス）
 * array : [ { id: ... , branch_name: .... , } ]
 */
const generateSelectbox = array => {
    if (array === undefined || array == null) {
        return null;
    }

    return array.map((item, idx) => {
        return <option key={idx} value={item.id}>{item.branch_name}</option>;
    });
};
