import InputDate from "components/page/user/contract/edit/input/inputDate";
import Input from "components/page/user/contract/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";
import { Link } from "react-router-dom";

/** 金銭の貸借のあっせん */
export default class Block6 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            rendering: false,       // 再レンダリング用
            headlineId: "021206",
        };
    }

    /** ローン情報の反映ハンドラ（あっせん有） */
    handleReflectJYU01148ARR = e => {
        e.preventDefault();

        // 金融機関１、融資承認取得日１から金融機関２、融資承認取得日２に設定
        if (this.props.pageState["TOR00031"] !== undefined && this.props.pageState["TOR00031"] !== "") {
            this.props.pageState.JYU01148_ARR[0].JYU01148 = this.props.pageState["TOR00031"];
        }
        if (this.props.pageState["TOR00032"] !== undefined && this.props.pageState["TOR00032"] !== "") {
            this.props.pageState.JYU01148_ARR[0].JYU01150 = this.props.pageState["TOR00032"];
        }
        if (this.props.pageState["TOR00268"] !== undefined && this.props.pageState["TOR00268"] !== "") {
            this.props.pageState.JYU01148_ARR[1].JYU01148 = this.props.pageState["TOR00268"];
        }
        if (this.props.pageState["TOR00269"] !== undefined && this.props.pageState["TOR00269"] !== "") {
            this.props.pageState.JYU01148_ARR[1].JYU01150 = this.props.pageState["TOR00269"];
        }
        // 再描画
        this.setState({ rendering: true });
        this.props.updatePageState({ "JYU01148_ARR": this.props.pageState["JYU01148_ARR"] });
    }

    /** ローン情報の反映ハンドラ（あっせん無） */
    handleReflectJYU01168ARR = e => {
        e.preventDefault();

        // 金融機関１、融資承認取得日１から金融機関４、融資承認取得日４に設定
        if (this.props.pageState["TOR00031"] !== undefined && this.props.pageState["TOR00031"] !== "") {
            this.props.pageState.JYU01168_ARR[0].JYU01168 = this.props.pageState["TOR00031"];
        }
        if (this.props.pageState["TOR00032"] !== undefined && this.props.pageState["TOR00032"] !== "") {
            this.props.pageState.JYU01168_ARR[0].JYU01170 = this.props.pageState["TOR00032"];
        }
        if (this.props.pageState["TOR00268"] !== undefined && this.props.pageState["TOR00268"] !== "") {
            this.props.pageState.JYU01168_ARR[1].JYU01168 = this.props.pageState["TOR00268"];
        }
        if (this.props.pageState["TOR00269"] !== undefined && this.props.pageState["TOR00269"] !== "") {
            this.props.pageState.JYU01168_ARR[1].JYU01170 = this.props.pageState["TOR00269"];
        }
        if (this.props.pageState["TOR00271"] !== undefined && this.props.pageState["TOR00271"] !== "") {
            this.props.pageState.JYU01168_ARR[2].JYU01168 = this.props.pageState["TOR00271"];
        }
        if (this.props.pageState["TOR00272"] !== undefined && this.props.pageState["TOR00272"] !== "") {
            this.props.pageState.JYU01168_ARR[2].JYU01170 = this.props.pageState["TOR00272"];
        }
        if (this.props.pageState["TOR00298"] !== undefined && this.props.pageState["TOR00298"] !== "") {
            this.props.pageState.JYU01168_ARR[3].JYU01168 = this.props.pageState["TOR00298"];
        }
        if (this.props.pageState["TOR00299"] !== undefined && this.props.pageState["TOR00299"] !== "") {
            this.props.pageState.JYU01168_ARR[3].JYU01170 = this.props.pageState["TOR00299"];
        }
        // 再描画
        this.setState({ rendering: true })
        this.props.updatePageState({ "JYU01168_ARR": this.props.pageState["JYU01168_ARR"] });
    }

    render() {
        const { hideBlockClass } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        const arrayDOMItemsJYU01148_ARR = this.createDOMItemsJYU01148_ARR(this.props.pageState.JYU01148_ARR);
        const arrayDOMItemsJYU01168_ARR = this.createDOMItemsJYU01168_ARR(this.props.pageState.JYU01168_ARR);

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} >
                <div className="dataList-header">
                    <div className="dataList-header-title">金銭の貸借のあっせん</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="6">
                                    <div className="mainTopCardDetail">
                                        <div className="mainTopCardDetail-auto">あっせん有</div>
                                        <div className="mainTopCardDetail-registered">
                                            <Link to="" aria-label="link" className="baseAction-reflect" onClick={this.handleReflectJYU01148ARR}>基本情報内「ローン情報」反映</Link>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            {arrayDOMItemsJYU01148_ARR}
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="6">
                                    <div className="mainTopCardDetail">
                                        <div className="mainTopCardDetail-auto">あっせん無</div>
                                        <div className="mainTopCardDetail-registered">
                                            <Link to="" aria-label="link" className="baseAction-reflect" onClick={this.handleReflectJYU01168ARR}>基本情報内「ローン情報」反映</Link>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            {arrayDOMItemsJYU01168_ARR}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    createDOMItemsJYU01148_ARR = arrays => {
        // 動的項目の共通パラメータ
        const arrayName = "JYU01148_ARR"; // 動的項目の代表名
        const params = {
            arrayName: arrayName,
            pageState: this.props.pageState,
            updatePageState: this.props.updatePageState,
        };

        const arrayItemsDOM = arrays.map((item, idx) => {

            // "通番"部分生成
            let head = null;
            head = (
                <th rowSpan="6" className="dataTable-title dataTable-title__w120 dataTable-title__center">
                    <div className="dataTable-title-vr">{idx + 1}</div>
                </th>
            );

            return (
                <React.Fragment key={idx} >
                    <tr>
                        {head}
                        <th className="dataTable-title dataTable-title__center">金融機関</th>
                        <th className="dataTable-title dataTable-title__center">金額</th>
                        <th className="dataTable-title dataTable-title__center">融資承認取得日</th>
                    </tr>
                    <tr>
                        <td className="dataTable-body dataTable-body__w277">
                            <Input idx={idx} mode="dynamic" id={`ID_JYU01148_${idx}`} name="JYU01148" value={item.JYU01148} {...params} />
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01149_${idx}`} name="JYU01149" value={item.JYU01149} {...params} />
                                <div className="dataTableUnit-text">万円</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <InputDate until idx={idx} mode="dynamic" id={`ID_JYU01150_${idx}`} name="JYU01150" value={item.JYU01150} {...params} />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__center">金利</th>
                        <th className="dataTable-title dataTable-title__center">借入期間</th>
                        <th className="dataTable-title dataTable-title__center">返済方法</th>
                    </tr>
                    <tr>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01151_${idx}`} name="JYU01151" value={item.JYU01151} {...params} />
                                <div className="dataTableUnit-text">%</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01153_${idx}`} name="JYU01153" value={item.JYU01153} {...params} />
                                <div className="dataTableUnit-text">年</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <Input idx={idx} mode="dynamic" id={`ID_JYU01154_${idx}`} name="JYU01154" value={item.JYU01154} {...params} />
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__center">保証料</th>
                        <th className="dataTable-title dataTable-title__center">ローン事務手数料（税込）</th>
                        <th className="dataTable-title dataTable-title__center">&nbsp;</th>
                    </tr>
                    <tr>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01155_${idx}`} name="JYU01155" value={item.JYU01155} {...params} />
                                <div className="dataTableUnit-text">円</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01156_${idx}`} name="JYU01156" value={item.JYU01156} {...params} />
                                <div className="dataTableUnit-text">円</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277"></td>
                    </tr>
                </React.Fragment>
            );
        });

        return (
            <React.Fragment>
                {arrayItemsDOM}
            </React.Fragment>
        );
    }

    createDOMItemsJYU01168_ARR = arrays => {
        // 動的項目の共通パラメータ
        const arrayName = "JYU01168_ARR"; // 動的項目の代表名
        const params = {
            arrayName: arrayName,
            pageState: this.props.pageState,
            updatePageState: this.props.updatePageState,
        };

        const arrayItemsDOM = arrays.map((item, idx) => {

            // "通番"部分生成
            let head = null;
            head = (
                <th rowSpan="4" className="dataTable-title dataTable-title__w120 dataTable-title__center">
                    <div className="dataTable-title-vr">{idx + 1}</div>
                </th>
            );

            return (
                <React.Fragment key={idx} >
                    <tr>
                        {head}
                        <th className="dataTable-title dataTable-title__center">金融機関</th>
                        <th className="dataTable-title dataTable-title__center">金額</th>
                        <th className="dataTable-title dataTable-title__center">融資承認取得日</th>
                    </tr>
                    <tr>
                        <td className="dataTable-body dataTable-body__w277">
                            <Input idx={idx} mode="dynamic" id={`ID_JYU01168_${idx}`} name="JYU01168" value={item.JYU01168} {...params} />
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01169_${idx}`} name="JYU01169" value={item.JYU01169} {...params} />
                                <div className="dataTableUnit-text">万円</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <InputDate until idx={idx} mode="dynamic" id={`ID_JYU01170_${idx}`} name="JYU01170" value={item.JYU01170} {...params} />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__center">金利</th>
                        <th className="dataTable-title dataTable-title__center">借入期間</th>
                        <th className="dataTable-title">&nbsp;</th>
                    </tr>
                    <tr>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01171_${idx}`} name="JYU01171" value={item.JYU01171} {...params} />
                                <div className="dataTableUnit-text">%</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01173_${idx}`} name="JYU01173" value={item.JYU01173} {...params} />
                                <div className="dataTableUnit-text">年</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">&nbsp;</td>
                    </tr>
                </React.Fragment>
            );
        });

        return (
            <React.Fragment>
                {arrayItemsDOM}
            </React.Fragment>
        );
    }
};

