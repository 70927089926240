import InputTextArea from "components/page/user/contract/edit/input/inputTextArea";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { CONTRACT_EDIT_INFO_FRK } from "../../../../../../../constants/contractEditPageInfo";
import Select from "../../input/inputSelect";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 買主 */
class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020101",
        };
    }

    handleTo0101 = e => {
        e.preventDefault();
        // リロードをかける
        this.props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0101.id);
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        const TOR00094_ARR = this.props.pageState.TOR00094_ARR;

        let dom = null;
        if (!(TOR00094_ARR === undefined || TOR00094_ARR.length === 0)) {

            dom = TOR00094_ARR.map((item, idx) => {
                return (
                    <tr key={idx}>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">{idx + 1}</th>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">氏名</th>
                        <td className="dataTable-body">
                            <div className="dataTableUnit">{this.excludeBlanks(item.TOR00094)}　{this.excludeBlanks(item.TOR00334)}</div>
                        </td>
                    </tr>
                );
            });
        }

        return (
            <React.Fragment>
                <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">買主</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                {dom}
                                <tr>
                                    <th className="dataTable-title" colSpan="2">
                                        <div className="dataTableUnit-text">住所署名欄</div>
                                        <div className="dataTableUnit-input dataTable-title-vr">
                                            <Select id="ID_JYU01762" name="JYU01762" value={this.props.pageState.JYU01762} items={this.getSelectboxNoEmpty(this.props.selectboxData.MEI00011)} {...params} />
                                        </div>
                                    </th>
                                    <td className="dataTable-body">
                                        <InputTextArea id="ID_TOR00352" name="TOR00352" cols={70} rows={3} height="h92" value={this.props.pageState.TOR00352} {...params} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="l-dataListMore">
                    <Link to="" aria-label="link" className="dataListMore" onClick={this.handleTo0101}>上記内容を修正したい場合は、こちらをクリックしてください</Link>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Block1);