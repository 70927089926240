import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 一棟の建物またはその敷地に関する権利およびこれらの管理・使用に関する事項 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020901",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 文字数制限定義
        const JYU00918LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00918",
                this.props.pageState.JYU00918);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">一棟の建物またはその敷地に関する権利およびこれらの管理・使用に関する事項</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis_single" colSpan="6">専用使用権に関する規約等の定め</th>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__noborder is-emphasis" colSpan="6">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-text">（別添管理規約</div>
                                        <Input id="ID_JYU00835" name="JYU00835" value={this.props.pageState.JYU00835} {...params} />
                                        <div className="dataTableUnit-text">使用細則</div>
                                        <Input id="ID_JYU00836" name="JYU00836" value={this.props.pageState.JYU00836} {...params} />
                                        <div className="dataTableUnit-text">に記載されています。）</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title is-emphasis_single" colSpan="6">①一棟の建物およびその敷地の専用使用権について</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center" colSpan="2">名称</th>
                                <th className="dataTable-title dataTable-title__center">専用使用をなしうる者</th>
                                <td className="dataTable-title dataTable-title__center" colSpan="2">専用使用料の有無とその帰属先</td>
                                <th className="dataTable-title dataTable-title__center">備考</th>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w110 dataTable-body__center is-emphasis is-rightborder">バルコニー</td>
                                <td className="dataTable-body dataTable-body__w85 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00838" name="JYU00838" checked={this.props.pageState.JYU00838} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01308" name="JYU01308" checked={this.props.pageState.JYU01308} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">区分所有者</div>
                                        <Input id="ID_JYU00839" name="JYU00839" value={this.props.pageState.JYU00839} {...params} />
                                    </div>
                                </td>
                                <td className="dataTable-body dataTable-body__w75 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00840" name="JYU00840" checked={this.props.pageState.JYU00840} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01309" name="JYU01309" checked={this.props.pageState.JYU01309} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body dataTable-body__w205 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00841" name="JYU00841" checked={this.props.pageState.JYU00841} {...params} />
                                                <span className="checkBox-text">管理組合</span>
                                            </label>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00843" name="JYU00843" checked={this.props.pageState.JYU00843} {...params} />
                                                        <span className="checkBox-text"></span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00844" name="JYU00844" value={this.props.pageState.JYU00844} {...params} />
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <Input id="ID_JYU00845" name="JYU00845" value={this.props.pageState.JYU00845} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w110 dataTable-body__center is-emphasis is-rightborder">玄関扉、窓枠、窓ガラス</td>
                                <td className="dataTable-body dataTable-body__w85 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00847" name="JYU00847" checked={this.props.pageState.JYU00847} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01310" name="JYU01310" checked={this.props.pageState.JYU01310} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">区分所有者</div>
                                        <Input id="ID_JYU00848" name="JYU00848" value={this.props.pageState.JYU00848} {...params} />
                                    </div>
                                </td>
                                <td className="dataTable-body dataTable-body__w75 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00849" name="JYU00849" checked={this.props.pageState.JYU00849} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01311" name="JYU01311" checked={this.props.pageState.JYU01311} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body dataTable-body__w205 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00850" name="JYU00850" checked={this.props.pageState.JYU00850} {...params} />
                                                <span className="checkBox-text">管理組合</span>
                                            </label>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00852" name="JYU00852" checked={this.props.pageState.JYU00852} {...params} />
                                                        <span className="checkBox-text"></span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00853" name="JYU00853" value={this.props.pageState.JYU00853} {...params} />
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <Input id="ID_JYU00854" name="JYU00854" value={this.props.pageState.JYU00854} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w110 dataTable-body__center is-emphasis is-rightborder">専用庭</td>
                                <td className="dataTable-body dataTable-body__w85 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00856" name="JYU00856" checked={this.props.pageState.JYU00856} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01312" name="JYU01312" checked={this.props.pageState.JYU01312} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">区分所有者</div>
                                        <Input id="ID_JYU00857" name="JYU00857" value={this.props.pageState.JYU00857} {...params} />
                                    </div>
                                </td>
                                <td className="dataTable-body dataTable-body__w75 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00858" name="JYU00858" checked={this.props.pageState.JYU00858} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01313" name="JYU01313" checked={this.props.pageState.JYU01313} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body dataTable-body__w205 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00859" name="JYU00859" checked={this.props.pageState.JYU00859} {...params} />
                                                <span className="checkBox-text">管理組合</span>
                                            </label>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00861" name="JYU00861" checked={this.props.pageState.JYU00861} {...params} />
                                                        <span className="checkBox-text"></span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00862" name="JYU00862" value={this.props.pageState.JYU00862} {...params} />
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <Input id="ID_JYU00863" name="JYU00863" value={this.props.pageState.JYU00863} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w110 dataTable-body__center is-emphasis is-rightborder">ルーフバルコニー</td>
                                <td className="dataTable-body dataTable-body__w85 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00865" name="JYU00865" checked={this.props.pageState.JYU00865} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01314" name="JYU01314" checked={this.props.pageState.JYU01314} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">区分所有者</div>
                                        <Input id="ID_JYU00866" name="JYU00866" value={this.props.pageState.JYU00866} {...params} />
                                    </div>
                                </td>
                                <td className="dataTable-body dataTable-body__w75 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00867" name="JYU00867" checked={this.props.pageState.JYU00867} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01315" name="JYU01315" checked={this.props.pageState.JYU01315} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body dataTable-body__w205 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00868" name="JYU00868" checked={this.props.pageState.JYU00868} {...params} />
                                                <span className="checkBox-text">管理組合</span>
                                            </label>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00870" name="JYU00870" checked={this.props.pageState.JYU00870} {...params} />
                                                        <span className="checkBox-text"></span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00871" name="JYU00871" value={this.props.pageState.JYU00871} {...params} />
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <Input id="ID_JYU00872" name="JYU00872" value={this.props.pageState.JYU00872} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w110 dataTable-body__center is-emphasis is-rightborder">トランクルーム</td>
                                <td className="dataTable-body dataTable-body__w85 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00874" name="JYU00874" checked={this.props.pageState.JYU00874} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01316" name="JYU01316" checked={this.props.pageState.JYU01316} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">区分所有者</div>
                                        <Input id="ID_JYU00875" name="JYU00875" value={this.props.pageState.JYU00875} {...params} />
                                    </div>
                                </td>
                                <td className="dataTable-body dataTable-body__w75 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00876" name="JYU00876" checked={this.props.pageState.JYU00876} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01317" name="JYU01317" checked={this.props.pageState.JYU01317} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body dataTable-body__w205 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00877" name="JYU00877" checked={this.props.pageState.JYU00877} {...params} />
                                                <span className="checkBox-text">管理組合</span>
                                            </label>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00879" name="JYU00879" checked={this.props.pageState.JYU00879} {...params} />
                                                        <span className="checkBox-text"></span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00880" name="JYU00880" value={this.props.pageState.JYU00880} {...params} />
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <Input id="ID_JYU00881" name="JYU00881" value={this.props.pageState.JYU00881} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w110 dataTable-body__center is-emphasis is-rightborder">駐車場</td>
                                <td className="dataTable-body dataTable-body__w85 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00883" name="JYU00883" checked={this.props.pageState.JYU00883} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01318" name="JYU01318" checked={this.props.pageState.JYU01318} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">区分所有者</div>
                                        <Input id="ID_JYU00884" name="JYU00884" value={this.props.pageState.JYU00884} {...params} />
                                    </div>
                                </td>
                                <td className="dataTable-body dataTable-body__w75 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00885" name="JYU00885" checked={this.props.pageState.JYU00885} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01319" name="JYU01319" checked={this.props.pageState.JYU01319} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body dataTable-body__w205 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00886" name="JYU00886" checked={this.props.pageState.JYU00886} {...params} />
                                                <span className="checkBox-text">管理組合</span>
                                            </label>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00888" name="JYU00888" checked={this.props.pageState.JYU00888} {...params} />
                                                        <span className="checkBox-text"></span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00889" name="JYU00889" value={this.props.pageState.JYU00889} {...params} />
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <Input id="ID_JYU00890" name="JYU00890" value={this.props.pageState.JYU00890} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w110 dataTable-body__center is-emphasis is-rightborder">駐輪場</td>
                                <td className="dataTable-body dataTable-body__w85 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00892" name="JYU00892" checked={this.props.pageState.JYU00892} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01320" name="JYU01320" checked={this.props.pageState.JYU01320} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">区分所有者</div>
                                        <Input id="ID_JYU00893" name="JYU00893" value={this.props.pageState.JYU00893} {...params} />
                                    </div>
                                </td>
                                <td className="dataTable-body dataTable-body__w75 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00894" name="JYU00894" checked={this.props.pageState.JYU00894} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01321" name="JYU01321" checked={this.props.pageState.JYU01321} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body dataTable-body__w205 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00895" name="JYU00895" checked={this.props.pageState.JYU00895} {...params} />
                                                <span className="checkBox-text">管理組合</span>
                                            </label>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00897" name="JYU00897" checked={this.props.pageState.JYU00897} {...params} />
                                                        <span className="checkBox-text"></span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00898" name="JYU00898" value={this.props.pageState.JYU00898} {...params} />
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <Input id="ID_JYU00899" name="JYU00899" value={this.props.pageState.JYU00899} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w110 dataTable-body__center is-emphasis is-rightborder">
                                    <Input id="ID_JYU01663" name="JYU01663" value={this.props.pageState.JYU01663} {...params} />
                                </td>
                                <td className="dataTable-body dataTable-body__w85 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00901" name="JYU00901" checked={this.props.pageState.JYU00901} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01322" name="JYU01322" checked={this.props.pageState.JYU01322} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">区分所有者</div>
                                        <Input id="ID_JYU00902" name="JYU00902" value={this.props.pageState.JYU00902} {...params} />
                                    </div>
                                </td>
                                <td className="dataTable-body dataTable-body__w75 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00903" name="JYU00903" checked={this.props.pageState.JYU00903} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01323" name="JYU01323" checked={this.props.pageState.JYU01323} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body dataTable-body__w205 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00904" name="JYU00904" checked={this.props.pageState.JYU00904} {...params} />
                                                <span className="checkBox-text">管理組合</span>
                                            </label>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00906" name="JYU00906" checked={this.props.pageState.JYU00906} {...params} />
                                                        <span className="checkBox-text"></span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00907" name="JYU00907" value={this.props.pageState.JYU00907} {...params} />
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <Input id="ID_JYU00908" name="JYU00908" value={this.props.pageState.JYU00908} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <td className="dataTable-body dataTable-body__w110 dataTable-body__center is-emphasis is-rightborder">
                                    <Input id="ID_JYU00909" name="JYU00909" value={this.props.pageState.JYU00909} {...params} />
                                </td>
                                <td className="dataTable-body dataTable-body__w85 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01324" name="JYU01324" checked={this.props.pageState.JYU01324} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00910" name="JYU00910" checked={this.props.pageState.JYU00910} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">区分所有者</div>
                                        <Input id="ID_JYU00911" name="JYU00911" value={this.props.pageState.JYU00911} {...params} />
                                    </div>
                                </td>
                                <td className="dataTable-body dataTable-body__w75 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical l-checkBox--center">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00912" name="JYU00912" checked={this.props.pageState.JYU00912} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01325" name="JYU01325" checked={this.props.pageState.JYU01325} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body dataTable-body__w205 is-emphasis">
                                    <ul className="l-checkBox l-checkBox--vertical">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00913" name="JYU00913" checked={this.props.pageState.JYU00913} {...params} />
                                                <span className="checkBox-text">管理組合</span>
                                            </label>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00915" name="JYU00915" checked={this.props.pageState.JYU00915} {...params} />
                                                        <span className="checkBox-text"></span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00916" name="JYU00916" value={this.props.pageState.JYU00916} {...params} />
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body is-emphasis">
                                    <Input id="ID_JYU00917" name="JYU00917" value={this.props.pageState.JYU00917} {...params} />
                                </td>
                            </tr>

                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00918" name="JYU00918" value={this.props.pageState.JYU00918} text={SEND_OTHER_PAPER.JYU00918} placeholder={JYU00918LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00918LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}