import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import InputDate from "components/page/user/contractFrk/edit/input/inputDate";
import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import React from "react";

/** 金銭の貸借のあっせん */
export default class Block6 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            rendering: false,       // 再レンダリング用
            headlineId: "021206",
        };

        // 入力値のチェック用オブジェクトを生成
        this.isSameJYU01148_ARR = this.props.pageState.JYU01755;
        this.isSameJYU01168_ARR_2 = this.props.pageState.JYU01756;
        this.isSameJYU01168_ARR_3 = this.props.pageState.JYU01759;

        // 固定値の設定
        this.HYPHEN_TEXT_04 = "----";
        this.HYPHEN_TEXT_08 = "--------";
        this.HYPHEN_TEXT_10 = "----------";
        this.HYPHEN_TEXT_12 = "------------";
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        const arrayDOMItemsJYU01148_ARR = this.createDOMItemsJYU01148_ARR(this.props.pageState.JYU01148_ARR);
        const arrayDOMItemsJYU01168_ARR = this.createDOMItemsJYU01168_ARR(this.props.pageState.JYU01168_ARR);

        // 斜線対応の設定（あっせん有）
        let DomDiagonalLine1 = undefined;
        if (this.onloadFlg && this.props.pageState.JYU01720) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine1 = this.DiagonalLine("AR_JYU01148");
        }

        // 斜線対応の設定（あっせん無）
        let DomDiagonalLine2 = undefined;
        if (this.onloadFlg && this.props.pageState.JYU01726) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine2 = this.DiagonalLine("AR_JYU01168");
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU01148:AR_JYU01148_0_1,AR_JYU01148_0_2,AR_JYU01148_0_3,AR_JYU01148_0_4,AR_JYU01148_0_5,AR_JYU01148_0_6,AR_JYU01148_1_1,AR_JYU01148_1_2,AR_JYU01148_1_3,AR_JYU01148_1_4,AR_JYU01148_1_5,AR_JYU01148_1_6/AR_JYU01168:AR_JYU01168_0_1,AR_JYU01168_0_2,AR_JYU01168_0_3,AR_JYU01168_0_4,AR_JYU01168_1_1,AR_JYU01168_1_2,AR_JYU01168_1_3,AR_JYU01168_1_4,AR_JYU01168_2_1,AR_JYU01168_2_2,AR_JYU01168_2_3,AR_JYU01168_2_4")}>
                <div className="dataList-header">
                    <div className="dataList-header-title">金銭の貸借のあっせん</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="6">
                                    <div className="mainTopCardDetail">
                                        <label className="checkBox mainTopCardDetail-registered">
                                            <InputCheckbox id="ID_JYU01720" name="JYU01720" checked={this.props.pageState.JYU01720} {...params} />
                                            <span className="checkBox-text">斜線</span>
                                        </label>
                                        <div className="mainTopCardDetail-auto">あっせん有</div>
                                    </div>
                                </th>
                            </tr>
                            {DomDiagonalLine1}
                            {arrayDOMItemsJYU01148_ARR}
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="6">
                                    <div className="mainTopCardDetail">
                                        <label className="checkBox mainTopCardDetail-registered">
                                            <InputCheckbox id="ID_JYU01726" name="JYU01726" checked={this.props.pageState.JYU01726} {...params} />
                                            <span className="checkBox-text">斜線</span>
                                        </label>
                                        <div className="mainTopCardDetail-auto">あっせん無</div>
                                    </div>
                                </th>
                            </tr>
                            {DomDiagonalLine2}
                            {arrayDOMItemsJYU01168_ARR}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    // あっせん有のDOM生成
    createDOMItemsJYU01148_ARR = arrays => {
        // 動的項目の共通パラメータ
        const arrayName = "JYU01148_ARR"; // 動的項目の代表名
        const params = {
            arrayName: arrayName,
            pageState: this.props.pageState,
            updatePageState: this.props.updatePageState,
        };

        const arrayItemsDOM = arrays.map((item, idx) => {

            if (idx >= 2) {
                return <></>;
            }

            // チェックボックス作成
            let inactiveCheckbox = null;
            if (idx === 1) {
                inactiveCheckbox = (
                    <div className="dataTableUnit-input">
                        <label className="checkBox mainTopCardDetail-registered">
                            <InputCheckbox id="ID_JYU01755" name="JYU01755" checked={this.props.pageState.JYU01755} {...params} />
                            <span className="checkBox-text">無</span>
                        </label>
                    </div>
                );
            }

            // 非活性の設定（あっせん有）
            let JYU01148_ARR_disabled = undefined;
            if (this.props.pageState.JYU01755 && idx === 1) {
                JYU01148_ARR_disabled = true;
            }

            // ハイフン挿入の設定

            if (!this.isSameJYU01148_ARR && this.props.pageState.JYU01755 && idx === 1) {
                this.isSameJYU01148_ARR = true;
                this.props.pageState.JYU01148_ARR[idx].JYU01148 = this.HYPHEN_TEXT_10;
                this.props.pageState.JYU01148_ARR[idx].JYU01793 = this.HYPHEN_TEXT_10;
                this.props.pageState.JYU01148_ARR[idx].JYU01721 = this.HYPHEN_TEXT_12;
                this.props.pageState.JYU01148_ARR[idx].JYU01149 = this.HYPHEN_TEXT_08;
                this.props.pageState.JYU01148_ARR[idx].JYU01150 = this.HYPHEN_TEXT_10;
                this.props.pageState.JYU01148_ARR[idx].JYU01722 = this.HYPHEN_TEXT_12;
                this.props.pageState.JYU01148_ARR[idx].JYU01151 = this.HYPHEN_TEXT_10;
                this.props.pageState.JYU01148_ARR[idx].JYU01723 = this.HYPHEN_TEXT_12;
                this.props.pageState.JYU01148_ARR[idx].JYU01153 = this.HYPHEN_TEXT_10;
                this.props.pageState.JYU01148_ARR[idx].JYU01154 = this.HYPHEN_TEXT_04;
                this.props.pageState.JYU01148_ARR[idx].JYU01724 = this.HYPHEN_TEXT_12;
                this.props.pageState.JYU01148_ARR[idx].JYU01155 = this.HYPHEN_TEXT_08;
                this.props.pageState.JYU01148_ARR[idx].JYU01725 = this.HYPHEN_TEXT_12;
                this.props.pageState.JYU01148_ARR[idx].JYU01156 = this.HYPHEN_TEXT_08;
                this.props.updatePageState(this.genUpdateInformation("JYU01148_ARR", this.props.pageState.JYU01148_ARR));
            }

            if (this.isSameJYU01148_ARR && !this.props.pageState.JYU01755 && idx === 1) {
                this.isSameJYU01148_ARR = false;
                this.props.pageState.JYU01148_ARR[idx].JYU01148 = "";
                this.props.pageState.JYU01148_ARR[idx].JYU01793 = "";
                this.props.pageState.JYU01148_ARR[idx].JYU01721 = "";
                this.props.pageState.JYU01148_ARR[idx].JYU01149 = "";
                this.props.pageState.JYU01148_ARR[idx].JYU01150 = "";
                this.props.pageState.JYU01148_ARR[idx].JYU01722 = "";
                this.props.pageState.JYU01148_ARR[idx].JYU01151 = "";
                this.props.pageState.JYU01148_ARR[idx].JYU01723 = "";
                this.props.pageState.JYU01148_ARR[idx].JYU01153 = "";
                this.props.pageState.JYU01148_ARR[idx].JYU01154 = "";
                this.props.pageState.JYU01148_ARR[idx].JYU01724 = "";
                this.props.pageState.JYU01148_ARR[idx].JYU01155 = "";
                this.props.pageState.JYU01148_ARR[idx].JYU01725 = "";
                this.props.pageState.JYU01148_ARR[idx].JYU01156 = "";
                this.props.updatePageState(this.genUpdateInformation("JYU01148_ARR", this.props.pageState.JYU01148_ARR));
            }

            // "通番"部分生成
            let head = null;
            head = (
                <th rowSpan="6" className="dataTable-title dataTable-title__w120 dataTable-title__center">
                    <div className="dataTable-title-vr">{idx + 1}</div>
                    {inactiveCheckbox}
                </th>
            );


            return (
                <React.Fragment key={idx}>
                    <tr id={`AR_JYU01148_${idx}_1`}>
                        {head}
                        <th className="dataTable-title dataTable-title__center">金融機関</th>
                        <th className="dataTable-title dataTable-title__center">金額</th>
                        <th className="dataTable-title dataTable-title__center">融資承認取得期日</th>
                    </tr>
                    <tr id={`AR_JYU01148_${idx}_2`}>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01148_${idx}`} name="JYU01148" value={item.JYU01148} {...params} disabled={JYU01148_ARR_disabled} />
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01793_${idx}`} name="JYU01793" value={item.JYU01793} {...params} disabled={JYU01148_ARR_disabled} />
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01721_${idx}`} name="JYU01721" value={item.JYU01721} {...params} disabled={JYU01148_ARR_disabled} />
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01149_${idx}`} name="JYU01149" value={item.JYU01149} {...params} disabled={JYU01148_ARR_disabled} />
                                <div className="dataTableUnit-text">万円</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <InputDate until idx={idx} mode="dynamic" id={`ID_JYU01150_${idx}`} name="JYU01150" value={item.JYU01150} {...params} disabled={JYU01148_ARR_disabled} />
                            </div>
                        </td>
                    </tr>
                    <tr id={`AR_JYU01148_${idx}_3`}>
                        <th className="dataTable-title dataTable-title__center">金利</th>
                        <th className="dataTable-title dataTable-title__center">借入期間</th>
                        <th className="dataTable-title dataTable-title__center">返済方法</th>
                    </tr>
                    <tr id={`AR_JYU01148_${idx}_4`}>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01722_${idx}`} name="JYU01722" value={item.JYU01722} {...params} disabled={JYU01148_ARR_disabled} />
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01151_${idx}`} name="JYU01151" value={item.JYU01151} {...params} disabled={JYU01148_ARR_disabled} />
                                <div className="dataTableUnit-text">%</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01723_${idx}`} name="JYU01723" value={item.JYU01723} {...params} disabled={JYU01148_ARR_disabled} />
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01153_${idx}`} name="JYU01153" value={item.JYU01153} {...params} disabled={JYU01148_ARR_disabled} />
                                <div className="dataTableUnit-text">年</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <Input idx={idx} mode="dynamic" id={`ID_JYU01154_${idx}`} name="JYU01154" value={item.JYU01154} {...params} disabled={JYU01148_ARR_disabled} />
                        </td>
                    </tr>
                    <tr id={`AR_JYU01148_${idx}_5`}>
                        <th className="dataTable-title dataTable-title__center">保証料</th>
                        <th className="dataTable-title dataTable-title__center">ローン事務手数料（税込）</th>
                        <th className="dataTable-title dataTable-title__center">&nbsp;</th>
                    </tr>
                    <tr id={`AR_JYU01148_${idx}_6`}>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01724_${idx}`} name="JYU01724" value={item.JYU01724} {...params} disabled={JYU01148_ARR_disabled} />
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01155_${idx}`} name="JYU01155" value={item.JYU01155} {...params} disabled={JYU01148_ARR_disabled} />
                                <div className="dataTableUnit-text">円</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01725_${idx}`} name="JYU01725" value={item.JYU01725} {...params} disabled={JYU01148_ARR_disabled} />
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01156_${idx}`} name="JYU01156" value={item.JYU01156} {...params} disabled={JYU01148_ARR_disabled} />
                                <div className="dataTableUnit-text">円</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277"></td>
                    </tr>
                </React.Fragment>
            );
        });

        return (
            <React.Fragment>
                {arrayItemsDOM}
            </React.Fragment>
        );
    }

    // あっせん無のDOM生成
    createDOMItemsJYU01168_ARR = arrays => {
        // 動的項目の共通パラメータ
        const arrayName = "JYU01168_ARR"; // 動的項目の代表名
        const params = {
            arrayName: arrayName,
            pageState: this.props.pageState,
            updatePageState: this.props.updatePageState,
        };

        const arrayItemsDOM = arrays.map((item, idx) => {

            if (idx >= 3) {
                return <></>;
            }

            // チェックボックス作成
            let inactiveCheckbox = null;
            if (idx === 1) {
                inactiveCheckbox = (
                    <div className="dataTableUnit-input">
                        <label className="checkBox mainTopCardDetail-registered">
                            <InputCheckbox id="ID_JYU01756" name="JYU01756" checked={this.props.pageState.JYU01756} {...params} />
                            <span className="checkBox-text">無</span>
                        </label>
                    </div>
                );
            } else if (idx === 2) {
                inactiveCheckbox = (
                    <div className="dataTableUnit-input">
                        <label className="checkBox mainTopCardDetail-registered">
                            <InputCheckbox id="ID_JYU01759" name="JYU01759" checked={this.props.pageState.JYU01759} {...params} />
                            <span className="checkBox-text">無</span>
                        </label>
                    </div>
                );
            }

            // 非活性の設定（あっせん無）
            let JYU01168_ARR_disabled = undefined;

            if (this.props.pageState.JYU01756 && idx === 1) {
                JYU01168_ARR_disabled = true;
            }

            if (this.props.pageState.JYU01759 && idx === 2) {
                JYU01168_ARR_disabled = true;
            }

            // ハイフン挿入の設定
            if (!this.isSameJYU01168_ARR_2 && this.props.pageState.JYU01756 && idx === 1) {
                this.isSameJYU01168_ARR_2 = true;
                this.props.pageState.JYU01168_ARR[idx].JYU01168 = this.HYPHEN_TEXT_10;
                this.props.pageState.JYU01168_ARR[idx].JYU01794 = this.HYPHEN_TEXT_10;
                this.props.pageState.JYU01168_ARR[idx].JYU01727 = this.HYPHEN_TEXT_12;
                this.props.pageState.JYU01168_ARR[idx].JYU01169 = this.HYPHEN_TEXT_08;
                this.props.pageState.JYU01168_ARR[idx].JYU01170 = this.HYPHEN_TEXT_10;
                this.props.pageState.JYU01168_ARR[idx].JYU01728 = this.HYPHEN_TEXT_12;
                this.props.pageState.JYU01168_ARR[idx].JYU01171 = this.HYPHEN_TEXT_10;
                this.props.pageState.JYU01168_ARR[idx].JYU01729 = this.HYPHEN_TEXT_12;
                this.props.pageState.JYU01168_ARR[idx].JYU01173 = this.HYPHEN_TEXT_10;

                this.props.updatePageState(this.genUpdateInformation("JYU01168_ARR", this.props.pageState.JYU01168_ARR));
            }

            if (this.isSameJYU01168_ARR_2 && !this.props.pageState.JYU01756 && idx === 1) {
                this.isSameJYU01168_ARR_2 = false;
                this.props.pageState.JYU01168_ARR[idx].JYU01168 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01794 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01727 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01169 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01170 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01728 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01171 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01729 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01173 = "";

                this.props.updatePageState(this.genUpdateInformation("JYU01168_ARR", this.props.pageState.JYU01168_ARR));
            }

            if (!this.isSameJYU01168_ARR_3 && this.props.pageState.JYU01759 && idx === 2) {
                this.isSameJYU01168_ARR_3 = true;
                this.props.pageState.JYU01168_ARR[idx].JYU01168 = this.HYPHEN_TEXT_10;
                this.props.pageState.JYU01168_ARR[idx].JYU01794 = this.HYPHEN_TEXT_10;
                this.props.pageState.JYU01168_ARR[idx].JYU01727 = this.HYPHEN_TEXT_12;
                this.props.pageState.JYU01168_ARR[idx].JYU01169 = this.HYPHEN_TEXT_08;
                this.props.pageState.JYU01168_ARR[idx].JYU01170 = this.HYPHEN_TEXT_10;
                this.props.pageState.JYU01168_ARR[idx].JYU01728 = this.HYPHEN_TEXT_12;
                this.props.pageState.JYU01168_ARR[idx].JYU01171 = this.HYPHEN_TEXT_10;
                this.props.pageState.JYU01168_ARR[idx].JYU01729 = this.HYPHEN_TEXT_12;
                this.props.pageState.JYU01168_ARR[idx].JYU01173 = this.HYPHEN_TEXT_10;

                this.props.updatePageState(this.genUpdateInformation("JYU01168_ARR", this.props.pageState.JYU01168_ARR));
            }

            if (this.isSameJYU01168_ARR_3 && !this.props.pageState.JYU01759 && idx === 2) {
                this.isSameJYU01168_ARR_3 = false;
                this.props.pageState.JYU01168_ARR[idx].JYU01168 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01794 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01727 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01169 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01170 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01728 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01171 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01729 = "";
                this.props.pageState.JYU01168_ARR[idx].JYU01173 = "";

                this.props.updatePageState(this.genUpdateInformation("JYU01168_ARR", this.props.pageState.JYU01168_ARR));

            }

            // "通番"部分生成
            let head = null;
            head = (
                <th rowSpan="4" className="dataTable-title dataTable-title__w120 dataTable-title__center">
                    <div className="dataTable-title-vr">{idx + 1}</div>
                    {inactiveCheckbox}
                </th>
            );

            return (
                <React.Fragment key={idx} >
                    <tr id={`AR_JYU01168_${idx}_1`}>
                        {head}
                        <th className="dataTable-title dataTable-title__center">金融機関</th>
                        <th className="dataTable-title dataTable-title__center">金額</th>
                        <th className="dataTable-title dataTable-title__center">融資承認取得期日</th>
                    </tr>
                    <tr id={`AR_JYU01168_${idx}_2`}>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01168_${idx}`} name="JYU01168" value={item.JYU01168} {...params} disabled={JYU01168_ARR_disabled} />
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01794_${idx}`} name="JYU01794" value={item.JYU01794} {...params} disabled={JYU01168_ARR_disabled} />
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01727_${idx}`} name="JYU01727" value={item.JYU01727} {...params} disabled={JYU01168_ARR_disabled} />
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01169_${idx}`} name="JYU01169" value={item.JYU01169} {...params} disabled={JYU01168_ARR_disabled} />
                                <div className="dataTableUnit-text">万円</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <InputDate until idx={idx} mode="dynamic" id={`ID_JYU01170_${idx}`} name="JYU01170" value={item.JYU01170} {...params} disabled={JYU01168_ARR_disabled} />
                            </div>
                        </td>
                    </tr>
                    <tr id={`AR_JYU01168_${idx}_3`}>
                        <th className="dataTable-title dataTable-title__center">金利</th>
                        <th className="dataTable-title dataTable-title__center">借入期間</th>
                        <th className="dataTable-title">&nbsp;</th>
                    </tr>
                    <tr id={`AR_JYU01168_${idx}_4`}>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01728_${idx}`} name="JYU01728" value={item.JYU01728} {...params} disabled={JYU01168_ARR_disabled} />
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01171_${idx}`} name="JYU01171" value={item.JYU01171} {...params} disabled={JYU01168_ARR_disabled} />
                                <div className="dataTableUnit-text">%</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">
                            <div className="dataTableUnit">
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01729_${idx}`} name="JYU01729" value={item.JYU01729} {...params} disabled={JYU01168_ARR_disabled} />
                                <Input idx={idx} mode="dynamic" id={`ID_JYU01173_${idx}`} name="JYU01173" value={item.JYU01173} {...params} disabled={JYU01168_ARR_disabled} />
                                <div className="dataTableUnit-text">年</div>
                            </div>
                        </td>
                        <td className="dataTable-body dataTable-body__w277">&nbsp;</td>
                    </tr>
                </React.Fragment>
            );
        });

        return (
            <React.Fragment>
                {arrayItemsDOM}
            </React.Fragment>
        );
    }
}

