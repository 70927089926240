import { PageBase } from "components/page/user/contract/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import PopupOrganizationList from "../../popup/popupOrgList";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";
import Block10 from "./block10";
import Block11 from "./block11";
import Block12 from "./block12";
import Block2 from "./block2";
import Block3 from "./block3";
import Block4 from "./block4";
import Block5 from "./block5";
import Block6 from "./block6";
import Block7 from "./block7";
import Block8 from "./block8";
import Block9 from "./block9";

class BasPage1 extends PageBase {
    constructor(props) {
        super(props);

        // データ取得時点で値が空になっている項目はundefinedにする
        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     */
    getPageItems = () => {
        return {
            // ここから契約情報の項目値
            // 物件名と書類No.
            TOR00200: undefined, // 物件名
            TOR00201: undefined, // 書類No.

            // 台帳情報
            TOR00001: undefined, // 台帳作成日（変更日）
            TOR00002: undefined, // 成立台帳バージョン
            TOR00003: undefined, // 変更理由
            TOR00004: undefined, // 物件種別
            TOR00204: undefined, // 支店／組織

            // 売買情報
            TOR00008: undefined, // 売買価格（税抜）
            TOR00009: undefined, // 消費税等
            TOR00010: undefined, // 売買価格（税込）
            TOR00011: undefined, // 手付金

            // 契約情報
            TOR00014: undefined, // 契約年月日
            TOR00015: undefined, // 契約時間
            TOR00016: undefined, // 契約場所
            TOR00017: undefined, // 契約書上の決済日
            TOR00018: undefined, // 確定決済日
            TOR00019: undefined, // 引渡日予定日
            TOR00020: undefined, // 引渡し確定日
            TOR00021: undefined, // 引渡猶予理由

            // 区分所有建物
            TOR00205: undefined, // 建物の名称
            TOR00206: undefined, // 住居表示
            TOR00207: undefined, // 所在
            TOR00208: undefined, // 構造
            TOR00209: undefined, // 延床面積
            TOR00210: undefined, // 家屋番号
            TOR00211: undefined, // 建物の名称
            TOR00212: undefined, // 種類
            TOR00213: undefined, // 構造
            TOR00214: undefined, // 床面積　階部分
            TOR00215: undefined, // 床面積　登記簿
            TOR00216: undefined, // 床面積　壁芯

            // 敷地権の目的たる土地
            TOR00217_ARR: [{
                TOR00217: undefined, // 所在および地番
                TOR00218: undefined, // 地目
                TOR00219: undefined, // 地積　公簿
                TOR00220: undefined, // 地積　実測
                TOR00221: undefined, // 敷地権の種類
                TOR00222: undefined, // 持分
            },],
            TOR00293: undefined, // 他筆
            TOR00235: undefined, // 合筆数
            TOR00236: undefined, // 登記簿合計面積
            TOR00294: undefined, // 敷地の形状
            TOR00295: undefined, // 道路の種別
            TOR00296: undefined, // 接道方向
            TOR00297: undefined, // 幅員

            // 土地
            TOR00237_ARR: [{
                TOR00237: undefined, // 所在
                TOR00238: undefined, // 地番
                TOR00239: undefined, // 他筆（2件目以降は存在しない項目）
                TOR00240: undefined, // 地目
                TOR00241: undefined, // 地積　公簿
                TOR00242: undefined, // 地積　実測
                TOR00243: undefined, // 持分
            },],
            TOR00256: undefined, // 合筆数
            TOR00257: undefined, // 登記簿合計面積
            TOR00056: undefined, // 敷地の形状
            TOR00057: undefined, // 道路の種別
            TOR00058: undefined, // 接道方向
            TOR00059: undefined, // 幅員

            // 建物
            TOR00258: undefined, // 住居表示
            TOR00259: undefined, // 所在
            TOR00260: undefined, // 家屋番号
            TOR00261: undefined, // 種類
            TOR00262: undefined, // 構造
            TOR00263: undefined, // 延床面積

            // 売主様
            TOR00070: undefined, // 売主 個人
            TOR00072_ARR: [{
                TOR00072: undefined, // 売主 氏名
                TOR00073: undefined, // 売主 住所
                TOR00076: undefined, // 売主 電話番号
            },],
            TOR00071: undefined, // 売主 ふりがな
            TOR00074: undefined, // 売主 郵便番号
            TOR00074_1: undefined, // 売主 郵便番号の前3桁
            TOR00074_2: undefined, // 売主 郵便番号の後4桁
            TOR00077: undefined, // 売主 転居先住所
            TOR00078: undefined, // 売主 新電話番号
            TOR00079: undefined, // 売主 法人の場合担当者
            TOR00080: undefined, // 売主 部署名
            TOR00087: undefined, // 売主仲介者 商号
            TOR00088: undefined, // 売主仲介者 支店／組織
            TOR00089: undefined, // 売主仲介者 住所
            TOR00090: undefined, // 売主仲介者 主担当者名
            TOR00091: undefined, // 売主仲介者 電話番号

            // 買主様
            TOR00092: undefined, // 買主 個人
            TOR00094_ARR: [{
                TOR00094: undefined, // 買主 氏名
                TOR00095: undefined, // 買主 住所
                TOR00098: undefined, // 買主 電話番号
            },],
            TOR00093: undefined, // 買主 ふりがな
            TOR00096: undefined, // 買主 郵便番号
            TOR00096_1: undefined, // 買主 郵便番号の前3桁
            TOR00096_2: undefined, // 買主 郵便番号の後4桁
            TOR00099: undefined, // 買主 転居先住所
            TOR00100: undefined, // 買主 新電話番号
            TOR00101: undefined, // 買主 法人の場合担当者
            TOR00102: undefined, // 買主 部署名
            TOR00109: undefined, // 買主仲介者 商号
            TOR00110: undefined, // 買主仲介者 支店／組織
            TOR00111: undefined, // 買主仲介者 住所
            TOR00112: undefined, // 買主仲介者 主担当者名
            TOR00113: undefined, // 買主仲介者 電話番号

            // 宅建業者が自ら売主となる新築住宅の場合にあっては、施行規則第18条第1項第8号に掲げる事項
            TOR00286: undefined, // 当該新築住宅を引き渡した年月日
            TOR00287: undefined, // 当該新築住宅の床面積
            TOR00288: undefined, // 瑕疵負担割合１ 商号
            TOR00289: undefined, // 瑕疵負担割合１ 割合
            TOR00290: undefined, // 瑕疵負担割合２ 商号
            TOR00291: undefined, // 瑕疵負担割合２ 割合
            TOR00292: undefined, // 当該住宅瑕疵担保責任保険法人の名称

            // 備考
            TOR00199: undefined, // 備考
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する ※参照のみの場合は不要 */
    getDynamicItemKeys = () => {
        return ["TOR00072_ARR", "TOR00094_ARR", "TOR00217_ARR", "TOR00237_ARR"];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /**
     *  郵便番号の3桁、4桁が別々の入力欄になっているので、
     * 項目名を指定して、7桁の文字列に変換する
     **/
    combineZip = (zip3Key, zip4Key) => {
        // 郵便番号入力部分の3桁と4桁の入力欄を合わせて1つにする

        // 入力がない場合、undefined（リクエストに含めないようにするため）
        if (this.state[zip3Key] === undefined && this.state[zip4Key] === undefined) {
            return undefined;
        }

        // 入力したけど消した場合、""（空文字列で規定値を上書きするため）
        if (this.state[zip3Key] === undefined && this.state[zip4Key] === "") {
            return "";
        }
        if (this.state[zip3Key] === "" && this.state[zip4Key] === undefined) {
            return "";
        }
        if (this.state[zip3Key] === "" && this.state[zip4Key] === "") {
            return "";
        }

        // 入力があった場合
        const zip3 = this.state[zip3Key] === undefined ? "" : this.state[zip3Key];
        const zip4 = this.state[zip4Key] === undefined ? "" : this.state[zip4Key];
        return zip3 + "-" + zip4;
    }

    /** this.stateの中から契約情報項目のみを抽出する */
    pickupContractInfo = () => {
        let tmp = {};
        this.contractInfoKeys.forEach(val => {
            tmp[val] = this.state[val];
        });

        // 郵便番号入力部分の3桁と4桁の入力欄を合わせて1つにする
        // 売主様 > 住所 > 郵便番号
        tmp["TOR00074"] = this.combineZip("TOR00074_1", "TOR00074_2");
        delete tmp["TOR00074_1"];
        delete tmp["TOR00074_2"];

        // 郵便番号入力部分の3桁と4桁の入力欄を合わせて1つにする
        // 買主様 > 住所 > 郵便番号
        tmp["TOR00096"] = this.combineZip("TOR00096_1", "TOR00096_2");
        delete tmp["TOR00096_1"];
        delete tmp["TOR00096_2"];

        // オブジェクトの値が全て空の場合、false
        const hasObjectValue = (obj) => {
            for (const item in obj) {
                const tmp = obj[item];
                if (!(tmp === undefined || tmp === null || tmp === "")) {
                    return true;
                }
            }
            return false;
        };

        // 動的に数が変動する項目に関して値が入っていない場合、項目値をundefinedにする
        // ※Amplifyの仕様でundefinedになっている項目はAPIリクエストパラメータから除外されるため
        this.arrayKeys.forEach(name => {
            let _ = []; // 値が入っているオブジェクトを暫定で格納するための配列

            // 動的項目の中身をチェックして、オブジェクトの値が全て空の場合、該当のオブジェクトを除外する
            // ※リクエストの対象から外すため
            const arrayItem = tmp[name];
            arrayItem.forEach(obj => {

                // オブジェクトがキーを持っている場合 かつ
                // オブジェクトのキー値が空でない場合
                if (Object.keys(obj).length !== 0 && hasObjectValue(obj)) {
                    _.push(obj);
                }
            });

            // 全ての動的項目の値が空だった場合、undefinedにする
            tmp[name] = _.length !== 0 ? _ : undefined;
        });
        return tmp;
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = { pageState: this.state, updatePageState: this.updatePageState, selectboxData: this.props.selectboxData };
        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 物件名と書類No. */}
                    <li><Block2 {...params} /></li> {/** 台帳情報 */}
                    <li><Block3 {...params} /></li> {/** 売買情報 */}
                    <li><Block4 {...params} /></li> {/** 契約情報 */}
                    <li><Block5 {...params} /></li> {/** 区分所有建物 */}
                    <li><Block6 {...params} /></li> {/** 敷地権の目的たる土地 */}
                    <li><Block7 {...params} /></li> {/** 土地 */}
                    <li><Block8 {...params} /></li> {/** 建物 */}
                    <li><Block9 {...params} /></li> {/** 売主様 */}
                    <li><Block10 {...params} /></li> {/** 買主様 */}
                    <li><Block11 {...params} /></li> {/** 宅建業者が自ら売主となる新築住宅の場合にあっては、施行規則第18条第1項第8号に掲げる事項 */}
                    <li><Block12 {...params} /></li> {/** 備考 */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
                <PopupOrganizationList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupOrg" />
            </React.Fragment>
        );
    }
}

export default withRouter(BasPage1);