import React from "react";
import * as helper from "../helper";
import { InputBase } from "./inputBase";

export default class InputNormal extends InputBase {

    constructor(props) {
        super(props);

        this.state = {
            unit: this.props.unit, // 表示する単位
            length: this.props.length, // 表示幅
            errorDisp: false // true: 表示する
        };

        // 通常項目用の初期化、更新ハンドラを設定する
        let init = this.initStandard;
        this.handleUpdate = this.handleUpdateStandard;

        // 動的項目の場合、動的項目用の初期化、更新ハンドラを使用する
        if (this.props.mode === "dynamic") {
            init = this.initDynamic;
            this.handleUpdate = this.handleUpdateDynamic;
        }

        // 初期化
        init();
    }

    /** 通常項目用の初期化処理 */
    initStandard = () => {
        // 先祖から設定された値の検証
        let tmp = {};
        tmp[helper.genValidKey(this.props.name)] = this.validate(this.props.value); // 有効化かどうか（true:有効）
        this.props.updatePageState(tmp, false); // 先祖のstateを更新するのでレンダリングが走る
    }

    /** 動的項目用の初期化処理 */
    initDynamic = () => {
        // 先祖から設定された値の検証
        const arrayNameValid = helper.genValidKey(this.props.arrayName);  // 動的項目の代表名（バリデート結果格納用）
        const idx = this.props.idx; // 動的項目のインデックス
        const name = this.props.name; //  実際の操作対象の項目名

        let objValid = Object.assign([], this.props.pageState[arrayNameValid]);
        objValid[idx][name] = this.validate(this.props.value);

        this.props.updatePageState({ [arrayNameValid]: objValid }, false); // 先祖のstateを更新するのでレンダリングが走る
    }

    /** 入力可能な文字列を限定させたい時はここで、入力文字列を限定後の状態へ変換する */
    inputConvert = value => {
        return value;
    }

    /** 通常項目用の入力欄更新時のハンドラ */
    handleUpdateStandard = e => {
        let inputValue = this.inputConvert(e.target.value);

        // 値の更新（先祖コンポーネントから渡されたstateの更新）
        let tmp = {};
        tmp[this.props.name] = inputValue; // 値
        tmp[helper.genValidKey(this.props.name)] = this.validate(inputValue); // 有効化かどうか（true:有効）
        this.props.updatePageState(tmp); // 先祖のstateを更新するのでレンダリングが走る
    }

    /** 動的項目用の入力欄更新時のハンドラ */
    handleUpdateDynamic = e => {
        const inputValue = this.inputConvert(e.target.value);
        const arrayName = this.props.arrayName;
        const arrayNameValid = helper.genValidKey(this.props.arrayName);
        const idx = this.props.idx;
        const name = this.props.name;

        // 以降の処理でページが保持している値の更新（先祖コンポーネントから渡されたstateの更新）

        // 更新用の配列をページが保持している値からコピーする
        let obj = Object.assign([], this.props.pageState[arrayName]);

        // 更新用の配列をページが保持している値からコピーする（バリデートチェック用）
        let objValid = Object.assign([], this.props.pageState[arrayNameValid]);

        // 入力された対象を更新
        obj[idx][name] = inputValue;
        objValid[idx][name] = this.validate(inputValue);

        // 先祖のstateを更新するのでレンダリングが走る
        this.props.updatePageState({ [arrayName]: obj, [arrayNameValid]: objValid });
    }

    /** 初期表示用の処理 */
    componentDidMount() {
        /** 初期表示時に空の場合はエラーメッセージを出さない */
        if (this.props.value === undefined) { return; }

        /** 初期表示時に異常値の場合はエラーメッセージを出す */
        if (!this.validate(this.props.value)) {
            this.setState({ errorDisp: true });
        }
    }

    /**
     * フォーカスアウト時のハンドラ
     * ※フォーカスアウトした際にエラーメッセージを出すかどうかを決める
     **/
    handlerBlur = e => {
        if (!this.validate(e.target.value)) {
            this.setState({ errorDisp: true });
        } else {
            this.setState({ errorDisp: false });
        }
    }

    /** 表示する際に整形が必要な場合、表示用の変換形式をここで定義 */
    formatter = (str) => {
        return str;
    }

    /**
     * バリデートロジックを定義する。
     * true: エラー無し false: エラー有り
     **/
    validate = (val) => {
        // 入力無しは許容
        //
        // ※valの状態は下記を前提とする
        // 最初から入力欄が空だった場合はundefined
        // 変更して空にした場合は空文字列
        if (val === undefined) {
            return undefined;
        }

        // バリデータが未指定の場合、常にtrue
        if (this.props.validate === undefined) {
            return true;

            // 指定されている場合、バリデートする
            // ※バリデータは、エラーなしの場合はnull、エラー有りの場合、エラーメッセージを返すこと
        } else {
            const result = this.props.validate(val);
            if (result !== null) {
                this.errorMessage = result;
                return false;
            }
        }
        return true;
    }

    render() {
        const disabled = this.isDisabled(this.props.pageState.disableItem);

        // バリデートエラーの場合、エラー用のクラスを付与
        // ※初期表示時の未入力部分にはエラーを出さない
        let dataTableUnitInputClass = "dataTableUnit-input";
        let style = {};
        let inputClass = "";
        if (this.state.errorDisp) {
            dataTableUnitInputClass = "dataTableUnit-input is-error";
        }

        // 指定された長さに応じて幅を変える（クラスとして定義されていない幅は仕方ないのでスタイル直指定する）
        if (this.state.length === "wFull") { dataTableUnitInputClass += " dataTableUnit-input__wFull"; }
        if (this.state.length === "wFlexFull") { dataTableUnitInputClass += " is-flex_full"; }
        if (this.state.length === "wFlexLeftauto") { dataTableUnitInputClass += " is-flex_leftauto"; }
        if (this.state.length === "w30") { inputClass = "input__w30"; }
        if (this.state.length === "w40") { inputClass = "input__w40"; }
        if (this.state.length === "w42") { inputClass = "input__w42"; }
        if (this.state.length === "w48") { inputClass = "input__w48"; }
        if (this.state.length === "w50") { inputClass = "input__w50"; }
        if (this.state.length === "w60") { inputClass = "input__w60"; }
        if (this.state.length === "w70") { style = { ...style, width: "70px" }; }
        if (this.state.length === "w80") { inputClass = "input__w80"; }
        if (this.state.length === "w108") { inputClass = "input__w108"; }
        if (this.state.length === "w112") { inputClass = "input__w112"; }
        if (this.state.length === "w113") { inputClass = "input__w113"; }
        if (this.state.length === "w120") { style = { ...style, width: "120px" }; }
        if (this.state.length === "w135") { inputClass = "input__w135"; }
        if (this.state.length === "w138") { inputClass = "input__w138"; }
        if (this.state.length === "w139") { inputClass = "input__w139"; }
        if (this.state.length === "w142") { inputClass = "input__w142"; }
        if (this.state.length === "w143") { inputClass = "input__w143"; }
        if (this.state.length === "w145") { inputClass = "input__w145"; }
        if (this.state.length === "w148") { inputClass = "input__w148"; }
        if (this.state.length === "w160") { inputClass = "input__w160"; }
        if (this.state.length === "w200") { inputClass = "input__w200"; }
        if (this.state.length === "w201") { inputClass = "input__w201"; }
        if (this.state.length === "w242") { inputClass = "input__w242"; }
        if (this.state.length === "w253") { inputClass = "input__w253"; }
        if (this.state.length === "w270") { inputClass = "input__w270"; }
        if (this.state.length === "w272") { inputClass = "input__w272"; }
        if (this.state.length === "w329") { inputClass = "input__w329"; }
        if (this.state.length === "w331") { style = { ...style, width: "331px" }; }
        if (this.state.length === "w333") { inputClass = "input__w333"; }
        if (this.state.length === "w391") { inputClass = "input__w391"; }
        if (this.state.length === "w444") { inputClass = "input__w444"; }
        if (this.state.length === "w483") { inputClass = "input__w483"; }
        if (this.state.length === "w537") { inputClass = "input__w537"; }
        if (this.state.length === "w575") { inputClass = "input__w575"; }
        if (this.state.length === "w614") { inputClass = "input__w614"; }

        // プレフィックス
        let prefixDOM = null;
        if (this.props.prefix) {
            prefixDOM = <div className="dataTableUnit-text">{this.props.prefix}</div>;
        }

        // propsとしてvalueが設定がされていない場合、空文字列
        let dispValue = this.props.value === undefined ? "" : this.props.value + "";

        // propsとしてplaceholderが設定がされていない場合、空文字列
        let defText = this.props.placeholder === undefined ? "" : this.props.placeholder + "";

        // バリデートエラーの場合、入力値をそのまま出力する
        // バリデートエラーでない場合、formatterをかまして画面表示
        dispValue = this.validate(dispValue) ? this.formatter(dispValue) : dispValue;

        // 最大文字数の制約
        let maxlengthValue = undefined;
        if (this.props.maxlength) {
            maxlengthValue = this.props.maxlength;
        }

        // ブロック無し
        if (this.props.noBlock) {
            return (
                <input type="text" aria-label="text" id={this.props.id} name={this.props.name} className={inputClass} onChange={this.handleUpdate} value={dispValue} maxLength={maxlengthValue} />
            );
        }

        let dom = (
            <React.Fragment>
                {prefixDOM}
                <div className={dataTableUnitInputClass} style={style}>
                    <input type="text" aria-label="text" id={this.props.id} name={this.props.name}
                        className={inputClass} onChange={this.handleUpdate} onBlur={this.handlerBlur} value={dispValue} disabled={disabled} placeholder={defText} maxLength={maxlengthValue} />
                    <span className="dataTableUnit-input-error">
                        <span className="dataTableUnit-input-error_text">{this.errorMessage}</span>
                    </span>
                </div>
            </React.Fragment>
        );

        return dom;
    }
}