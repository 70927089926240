import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import InputDate from "components/page/user/contract/edit/input/inputDate";
import Input from "components/page/user/contract/edit/input/inputNormal";
import Select from "components/page/user/contract/edit/input/inputSelect";
import InputTextArea from "components/page/user/contract/edit/input/inputTextArea";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { DISPLAY_TYPES } from "constants/defaultInputValueTypes";
import { MESSAGES, SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 土地（借地権の場合、借地権の対象となるべき土地） */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020401",
        };
        this.reader = new FileReader(); // アップロード対象ファイル読込用のReader
        this.uploadFileName = undefined;
        // アップロードするファイル名// 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    /** ファイルアップロードの選択がされた際のハンドラ */
    handleUploadMap = e => {
        const target = e.target.files[0];

        // APIコール失敗→ファイル選択→キャンセル押下でundefinedがくるのでその対応
        if (target === undefined) { return; }

        this.uploadFileName = target.name;
        this.reader.readAsArrayBuffer(target);

        // ファイルの読み込みが完了した時に発火されるイベント用のリスナーを登録
        const event = e => {
            this.reader.removeEventListener('load', event);
            this.props.updatePageState({ upload: { pageId: "0204", itemId: "JYU00595", data: this.reader.result, name: this.uploadFileName } });
        };
        this.reader.addEventListener('load', event);
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        //JYU00412の入力上限判定
        const JYU00412LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00412_${this.props.displayTypeID}`, this.props.pageState.JYU00412);

        //JYU00513の入力上限判定
        const JYU00513LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00513_${this.props.displayTypeID}`, this.props.pageState.JYU00513);

        //JYU00558の入力上限判定
        const JYU00558LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00558_${this.props.displayTypeID}`, this.props.pageState.JYU00558);

        //JYU00587の入力上限判定
        const JYU00587LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00587_${this.props.displayTypeID}`, this.props.pageState.JYU00587);

        // 斜線対応の設定
        let DomDiagonalLine1 = undefined;
        let DomDiagonalLine2 = undefined;
        if (this.onloadFlg
            && this.props.displayTypeID !== DISPLAY_TYPES.SEGMENT_BUILDING.value
            && this.props.pageState.JYU00530) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine1 = this.DiagonalLine("AR_JYU005301");
            DomDiagonalLine2 = this.DiagonalLine("AR_JYU005302");
        }

        // アップロード済みのファイルがある場合、ファイル名を表示する
        const uploadedMessage = this.props.pageState.JYU00595
            ? MESSAGES.CONTRACT_EDIT.MAPFILE_UPLOADED.replace(`:message:_${this.props.displayTypeID}`, this.props.pageState.JYU00595)
            : null;

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU005301:AJYU005301-1/AR_JYU005302:AJYU005302-1")}>
                <div className="dataList-header">
                    <div className="dataList-header-title">都市計画法に基づく制限（制限の内容等については、補足資料に記載されています。）</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">①区域区分</th>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00388" name="JYU00388" checked={this.props.pageState.JYU00388} {...params} />
                                                        <span className="checkBox-text">1.都市計画区域</span>
                                                    </label>
                                                </div>
                                                <div className="dataTableUnit-text">（</div>
                                                <div className="dataTableUnit-input">
                                                    <ul className="l-checkBox">
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU00389" name="JYU00389" checked={this.props.pageState.JYU00389} {...params} />
                                                                <span className="checkBox-text">1.市街化区域</span>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU00390" name="JYU00390" checked={this.props.pageState.JYU00390} {...params} />
                                                                <span className="checkBox-text">2.市街化調整区域</span>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU00391" name="JYU00391" checked={this.props.pageState.JYU00391} {...params} />
                                                                <span className="checkBox-text">3.区域区分のされていない区域</span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="dataTableUnit-text">）</div>
                                            </div>
                                        </div>
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00392" name="JYU00392" checked={this.props.pageState.JYU00392} {...params} />
                                                        <span className="checkBox-text">2.都市計画区域外</span>
                                                    </label>
                                                </div>
                                                <div className="dataTableUnit-text">（</div>
                                                <div className="dataTableUnit-text">準都市計画区域の指定：</div>
                                                <div className="dataTableUnit-input">
                                                    <ul className="l-checkBox">
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU00393" name="JYU00393" checked={this.props.pageState.JYU00393} {...params} />
                                                                <span className="checkBox-text">有</span>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU01329" name="JYU01329" checked={this.props.pageState.JYU01329} {...params} />
                                                                <span className="checkBox-text">無</span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="dataTableUnit-text">）</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120" rowSpan="4">②市街化調整区域の場合開発行為・旧住宅地造成事業法の許可等</th>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">既存宅地番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_JYU00395" name="JYU00395" value={this.props.pageState.JYU00395} {...params} length="w112" />
                                        <Input id="ID_JYU00396" name="JYU00396" value={this.props.pageState.JYU00396} {...params} length="w318" />
                                        <div className="dataTableUnit-text">号</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">許可番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_JYU00397" name="JYU00397" value={this.props.pageState.JYU00397} {...params} length="w112" />
                                        <Input id="ID_JYU00398" name="JYU00398" value={this.props.pageState.JYU00398} {...params} length="w318" />
                                        <div className="dataTableUnit-text">号</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">検査済番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_JYU00399" name="JYU00399" value={this.props.pageState.JYU00399} {...params} length="w112" />
                                        <Input id="ID_JYU00400" name="JYU00400" value={this.props.pageState.JYU00400} {...params} length="w318" />
                                        <div className="dataTableUnit-text">号</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">公告</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_JYU00401" name="JYU00401" value={this.props.pageState.JYU00401} {...params} length="w112" />
                                        <Input id="ID_JYU00402" name="JYU00402" value={this.props.pageState.JYU00402} {...params} length="w318" />
                                        <div className="dataTableUnit-text">号</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center" rowSpan="4">③都市計画<br />施設</th>
                                <td className="dataTable-body is-rightborder" rowSpan="4">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00403" name="JYU00403" checked={this.props.pageState.JYU00403} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01330" name="JYU01330" checked={this.props.pageState.JYU01330} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00404" name="JYU00404" checked={this.props.pageState.JYU00404} {...params} />
                                        <span className="checkBox-text">1.都市計画道路</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">（</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00405" name="JYU00405" checked={this.props.pageState.JYU00405} {...params} />
                                                        <span className="checkBox-text">1.計画決定</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00406" name="JYU00406" checked={this.props.pageState.JYU00406} {...params} />
                                                        <span className="checkBox-text">2.事業決定 </span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-tex6">名称</div>
                                        <Input id="ID_JYU00407" name="JYU00407" value={this.props.pageState.JYU00407} {...params} length="w201" />
                                        <div className="dataTableUnit-text">幅員</div>
                                        <Input id="ID_JYU00408" name="JYU00408" value={this.props.pageState.JYU00408} {...params} length="w60" />
                                        <div className="dataTableUnit-text">m</div>
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00409" name="JYU00409" checked={this.props.pageState.JYU00409} {...params} />
                                        <span className="checkBox-text">2.その他の都市計画施設</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">（</div>
                                        <Input id="ID_JYU00410" name="JYU00410" value={this.props.pageState.JYU00410} {...params} length="w483" />
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center">④市街地<br />開発事業</th>
                                <td className="dataTable-body" colSpan="2">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00411" name="JYU00411" checked={this.props.pageState.JYU00411} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01331" name="JYU01331" checked={this.props.pageState.JYU01331} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00412" name="JYU00412" value={this.props.pageState.JYU00412} text={SEND_OTHER_PAPER.JYU00412} placeholder={JYU00412LimitErrorMessage.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00412LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="2">建築基準法に基づく制限（制限の内容等については、補足資料に記載されています。）</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">①用途地域</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--adjColumnWrap l-checkBox--adjColumnWrap__6row">
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00444" name="JYU00444" checked={this.props.pageState.JYU00444} {...params} />
                                                        <span className="checkBox-text">1.第1種低層住居専用地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01344" name="JYU01344" value={this.props.pageState.JYU01344} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00445" name="JYU00445" checked={this.props.pageState.JYU00445} {...params} />
                                                        <span className="checkBox-text">2.第2種低層住居専用地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01345" name="JYU01345" value={this.props.pageState.JYU01345} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00446" name="JYU00446" checked={this.props.pageState.JYU00446} {...params} />
                                                        <span className="checkBox-text">3.第1種中高層住居専用地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01346" name="JYU01346" value={this.props.pageState.JYU01346} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00447" name="JYU00447" checked={this.props.pageState.JYU00447} {...params} />
                                                        <span className="checkBox-text">4.第2種中高層住居専用地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01347" name="JYU01347" value={this.props.pageState.JYU01347} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00448" name="JYU00448" checked={this.props.pageState.JYU00448} {...params} />
                                                        <span className="checkBox-text">5.第1種住居地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01348" name="JYU01348" value={this.props.pageState.JYU01348} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00449" name="JYU00449" checked={this.props.pageState.JYU00449} {...params} />
                                                        <span className="checkBox-text">6.第2種住居地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01349" name="JYU01349" value={this.props.pageState.JYU01349} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00450" name="JYU00450" checked={this.props.pageState.JYU00450} {...params} />
                                                        <span className="checkBox-text">7.田園住居地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01467" name="JYU01467" value={this.props.pageState.JYU01467} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00451" name="JYU00451" checked={this.props.pageState.JYU00451} {...params} />
                                                        <span className="checkBox-text">8.準住居地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01350" name="JYU01350" value={this.props.pageState.JYU01350} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00452" name="JYU00452" checked={this.props.pageState.JYU00452} {...params} />
                                                        <span className="checkBox-text">9.近隣商業地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01351" name="JYU01351" value={this.props.pageState.JYU01351} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00453" name="JYU00453" checked={this.props.pageState.JYU00453} {...params} />
                                                        <span className="checkBox-text">10.商業地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01352" name="JYU01352" value={this.props.pageState.JYU01352} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00454" name="JYU00454" checked={this.props.pageState.JYU00454} {...params} />
                                                        <span className="checkBox-text">11.準工業地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01353" name="JYU01353" value={this.props.pageState.JYU01353} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00455" name="JYU00455" checked={this.props.pageState.JYU00455} {...params} />
                                                        <span className="checkBox-text">12.工業地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01354" name="JYU01354" value={this.props.pageState.JYU01354} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00456" name="JYU00456" checked={this.props.pageState.JYU00456} {...params} />
                                                        <span className="checkBox-text">13.工業専用地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01355" name="JYU01355" value={this.props.pageState.JYU01355} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00457" name="JYU00457" checked={this.props.pageState.JYU00457} {...params} />
                                                        <span className="checkBox-text">14.用途地域の指定なし</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01356" name="JYU01356" value={this.props.pageState.JYU01356} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00458" name="JYU00458" checked={this.props.pageState.JYU00458} {...params} />
                                                        <span className="checkBox-text">15.</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00459" name="JYU00459" value={this.props.pageState.JYU00459} {...params} length="w120" />
                                                <Input id="ID_JYU01357" name="JYU01357" value={this.props.pageState.JYU01357} {...params} length="w40" />
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center" rowSpan="2">②地域・<br />地区・街区</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00463" name="JYU00463" checked={this.props.pageState.JYU00463} {...params} />
                                                <span className="checkBox-text">特別用途地区</span>
                                            </label>
                                        </div>
                                        <Input id="ID_JYU00464" name="JYU00464" value={this.props.pageState.JYU00464} {...params} length="wFlexFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--adjColumnWrap l-checkBox--adjColumnWrap__7row">
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00465" name="JYU00465" checked={this.props.pageState.JYU00465} {...params} />
                                                        <span className="checkBox-text">1.防火地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01359" name="JYU01359" value={this.props.pageState.JYU01359} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00466" name="JYU00466" checked={this.props.pageState.JYU00466} {...params} />
                                                        <span className="checkBox-text">2.準防火地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01360" name="JYU01360" value={this.props.pageState.JYU01360} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00467" name="JYU00467" checked={this.props.pageState.JYU00467} {...params} />
                                                        <span className="checkBox-text">3.高度地区</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01361" name="JYU01361" value={this.props.pageState.JYU01361} {...params} length="wFlexFull" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00469" name="JYU00469" checked={this.props.pageState.JYU00469} {...params} />
                                                        <span className="checkBox-text">4.高度利用地区</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01362" name="JYU01362" value={this.props.pageState.JYU01362} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00470" name="JYU00470" checked={this.props.pageState.JYU00470} {...params} />
                                                        <span className="checkBox-text">5.特定街区</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01363" name="JYU01363" value={this.props.pageState.JYU01363} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00471" name="JYU00471" checked={this.props.pageState.JYU00471} {...params} />
                                                        <span className="checkBox-text">6.景観地区</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01364" name="JYU01364" value={this.props.pageState.JYU01364} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00472" name="JYU00472" checked={this.props.pageState.JYU00472} {...params} />
                                                        <span className="checkBox-text">7.風致地区</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01365" name="JYU01365" value={this.props.pageState.JYU01365} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00473" name="JYU00473" checked={this.props.pageState.JYU00473} {...params} />
                                                        <span className="checkBox-text">8.災害危険区域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01366" name="JYU01366" value={this.props.pageState.JYU01366} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00474" name="JYU00474" checked={this.props.pageState.JYU00474} {...params} />
                                                        <span className="checkBox-text">9.地区計画区域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01367" name="JYU01367" value={this.props.pageState.JYU01367} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00475" name="JYU00475" checked={this.props.pageState.JYU00475} {...params} />
                                                        <span className="checkBox-text">10.特例容積率適用地区</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01368" name="JYU01368" value={this.props.pageState.JYU01368} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00476" name="JYU00476" checked={this.props.pageState.JYU00476} {...params} />
                                                        <span className="checkBox-text">11.特定用途制限地域</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01369" name="JYU01369" value={this.props.pageState.JYU01369} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00478" name="JYU00478" checked={this.props.pageState.JYU00478} {...params} />
                                                        <span className="checkBox-text">12.高層住居誘導地区</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01370" name="JYU01370" value={this.props.pageState.JYU01370} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00479" name="JYU00479" checked={this.props.pageState.JYU00479} {...params} />
                                                        <span className="checkBox-text">13.駐車条整備地区</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01371" name="JYU01371" value={this.props.pageState.JYU01371} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00480" name="JYU00480" checked={this.props.pageState.JYU00480} {...params} />
                                                        <span className="checkBox-text">14.都市再生特別地区</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01372" name="JYU01372" value={this.props.pageState.JYU01372} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00481" name="JYU00481" checked={this.props.pageState.JYU00481} {...params} />
                                                        <span className="checkBox-text">15.特定防災街区整備地区</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU01373" name="JYU01373" value={this.props.pageState.JYU01373} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00482" name="JYU00482" checked={this.props.pageState.JYU00482} {...params} />
                                                        <span className="checkBox-text">16</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00483" name="JYU00483" value={this.props.pageState.JYU00483} {...params} length="w120" />
                                                <Input id="ID_JYU01374" name="JYU01374" value={this.props.pageState.JYU01374} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00484" name="JYU00484" checked={this.props.pageState.JYU00484} {...params} />
                                                        <span className="checkBox-text">17</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00485" name="JYU00485" value={this.props.pageState.JYU00485} {...params} length="w120" />
                                                <Input id="ID_JYU01375" name="JYU01375" value={this.props.pageState.JYU01375} {...params} length="w40" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit dataTableUnit--between">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00486" name="JYU00486" checked={this.props.pageState.JYU00486} {...params} />
                                                        <span className="checkBox-text">18</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00487" name="JYU00487" value={this.props.pageState.JYU00487} {...params} length="w120" />
                                                <Input id="ID_JYU01376" name="JYU01376" value={this.props.pageState.JYU01376} {...params} length="w40" />
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title" rowSpan="2">③建築面積の敷地面積に対する<br />割合の限度（建ぺい率）</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--rightend">
                                        <Input id="ID_JYU00489" name="JYU00489" value={this.props.pageState.JYU00489} {...params} length="w120" prefix="指定" />
                                        <div className="dataTableUnit-text">%</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <InputTextArea id="ID_JYU00490" name="JYU00490" cols={30} rows={10} height="h92" value={this.props.pageState.JYU00490} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title" rowSpan="3">④建築物の延べ面積の敷地面積に<br />対する割合の限度（容積率）</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--rightend">
                                        <Input id="ID_JYU00501" name="JYU00501" value={this.props.pageState.JYU00501} {...params} length="w120" prefix="指定" />
                                        <div className="dataTableUnit-text">%</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <InputTextArea id="ID_JYU00502" name="JYU00502" cols={30} rows={10} height="h92" value={this.props.pageState.JYU00502} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">ただし前面道路により上記容積率がさらに制限されます。</div>
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-text">→道路幅員約</div>
                                                <Input id="ID_JYU00507" name="JYU00507" value={this.props.pageState.JYU00507} {...params} length="w120" />
                                                <div className="dataTableUnit-text">（m）×</div>
                                                <Input id="ID_JYU00509" name="JYU00509" value={this.props.pageState.JYU00509} {...params} length="w120" />
                                                <div className="dataTableUnit-text">/10×100%</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body dataTable-body__top" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00513" name="JYU00513" value={this.props.pageState.JYU00513} text={SEND_OTHER_PAPER.JYU00513} placeholder={JYU00513LimitErrorMessage.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00513LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-title dataTable-title__left" colSpan="2">⑤敷地等と道路との関係</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="dataUpTable">
                        <div className="dataUpTable-file">
                            <div className="dataUpTable-file-title">敷地等と道路との関係（概略図）</div>
                            {uploadedMessage}
                            <div className="dataUpTable-file-body">
                                <input type="file" id="ID_JYU00595" name="JYU00595" accept="image/*" onChange={this.handleUploadMap} />
                            </div>
                        </div>
                        <div className="dataUpTable-table">
                            <table className="dataTable">
                                <tbody>
                                    <tr>
                                        <th className="dataTable-title dataTable-title__leftNoborder dataTable-title__w30 is-emphasis">道路の種類</th>
                                        <td className="dataTable-body">
                                            <ul className="l-checkBox l-checkBox--vertical">
                                                <li>
                                                    <label className="checkBox checkbox__topLine">
                                                        <InputCheckbox id="ID_JYU00524" name="JYU00524" checked={this.props.pageState.JYU00524} {...params} />
                                                        <span className="checkBox-text">1.道路法による道路</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox checkbox__topLine">
                                                        <InputCheckbox id="ID_JYU00525" name="JYU00525" checked={this.props.pageState.JYU00525} {...params} />
                                                        <span className="checkBox-text">2.都市計画法、土地区画整理法、旧住宅地造成事業法、都市再開発法、新都市基盤整備法、大都市法、密集市街地整備法による道路</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox checkbox__topLine">
                                                        <InputCheckbox id="ID_JYU00526" name="JYU00526" checked={this.props.pageState.JYU00526} {...params} />
                                                        <span className="checkBox-text">3.既存道（建築基準法第3章適用の際、現に存するもの）</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox checkbox__topLine">
                                                        <InputCheckbox id="ID_JYU00527" name="JYU00527" checked={this.props.pageState.JYU00527} {...params} />
                                                        <span className="checkBox-text">4.道路法、都市計画法、土地区画整理法、都市再開発法、新都市基盤整備法、大都市法、密集市街地整備法の事業による計画道路（2年以内に事業が執行予定、特定行政庁の指定あり）</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox checkbox__topLine">
                                                        <InputCheckbox id="ID_JYU00528" name="JYU00528" checked={this.props.pageState.JYU00528} {...params} />
                                                        <span className="checkBox-text">5.土地を建築物の敷地として利用するため、上記1〜4の法によらないで道を築造しようとする者が特定行政庁から指定を受けたもの（位置指定道路）</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox checkbox__topLine">
                                                        <InputCheckbox id="ID_JYU00529" name="JYU00529" checked={this.props.pageState.JYU00529} {...params} />
                                                        <span className="checkBox-text">6.上記3の既存道のうち、幅員が4m（6m）未満のもので特定行政庁が指定したもの（2項道路）</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="dataTable-body">&nbsp;</td>
                                        <td className="dataTable-body" colSpan="2">
                                            <label className="checkBox checkbox__topLine">
                                                <InputCheckbox id="ID_JYU00530" name="JYU00530" checked={this.props.pageState.JYU00530} {...params} />
                                                <span className="checkBox-text">7.建築基準法上の道路に該当しない通路（建築確認不可）<br />※入力不要の場合は、斜線を引きます。</span>
                                            </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="dataTable-body">&nbsp;</td>
                                        <td className="dataTable-body" colSpan="2">都市計画区域、準都市計画区域内では、原則として上記道路の種類1〜6の建築基準法上の道路に2m以上接していないと、建築できません。（建築確認不可）</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">道路境界線後退（セットバック）による建築確認対象面積の減少</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00531" name="JYU00531" checked={this.props.pageState.JYU00531} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01332" name="JYU01332" checked={this.props.pageState.JYU01332} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        {DomDiagonalLine1}
                        <tbody id="AJYU005301-1">
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="6">前面道路</td>
                            </tr>
                            <tr>
                                <td className="dataTable-title dataTable-title__w40">&nbsp;</td>
                                <th className="dataTable-title">公道・私道の別</th>
                                <th className="dataTable-title dataTable-title__w152">種類</th>
                                <th className="dataTable-title dataTable-title__w152">接道方向</th>
                                <th className="dataTable-title dataTable-title__w211">幅員</th>
                                <th className="dataTable-title dataTable-title__w211">接道の長さ</th>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__center is-rightborder">1</td>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00534" name="JYU00534" checked={this.props.pageState.JYU00534} {...params} />
                                                <span className="checkBox-text">公道</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00535" name="JYU00535" checked={this.props.pageState.JYU00535} {...params} />
                                                <span className="checkBox-text">私道</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00536" name="JYU00536" value={this.props.pageState.JYU00536} {...params} length="w80" />
                                        <div className="dataTableUnit-text">番</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00537" name="JYU00537" value={this.props.pageState.JYU00537} {...params} length="w80" />
                                        <div className="dataTableUnit-text">側</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00538" name="JYU00538" value={this.props.pageState.JYU00538} {...params} length="w80" />
                                        <Select id="ID_JYU01433" name="JYU01433" value={this.props.pageState.JYU01433} items={this.getSelectbox(this.props.selectboxData.MEI00006)} {...params} length="w95" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00539" name="JYU00539" value={this.props.pageState.JYU00539} {...params} length="w80" />
                                        <Select id="ID_JYU01437" name="JYU01437" value={this.props.pageState.JYU01437} items={this.getSelectbox(this.props.selectboxData.MEI00006)} {...params} length="w95" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__center is-rightborder">2</td>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00540" name="JYU00540" checked={this.props.pageState.JYU00540} {...params} />
                                                <span className="checkBox-text">公道</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00541" name="JYU00541" checked={this.props.pageState.JYU00541} {...params} />
                                                <span className="checkBox-text">私道</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00542" name="JYU00542" value={this.props.pageState.JYU00542} {...params} length="w80" />
                                        <div className="dataTableUnit-text">番</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00543" name="JYU00543" value={this.props.pageState.JYU00543} {...params} length="w80" />
                                        <div className="dataTableUnit-text">側</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00544" name="JYU00544" value={this.props.pageState.JYU00544} {...params} length="w80" />
                                        <Select id="ID_JYU01434" name="JYU01434" value={this.props.pageState.JYU01434} items={this.getSelectbox(this.props.selectboxData.MEI00006)} {...params} length="w95" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00545" name="JYU00545" value={this.props.pageState.JYU00545} {...params} length="w80" />
                                        <Select id="ID_JYU01438" name="JYU01438" value={this.props.pageState.JYU01438} items={this.getSelectbox(this.props.selectboxData.MEI00006)} {...params} length="w95" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__center is-rightborder">3</td>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00546" name="JYU00546" checked={this.props.pageState.JYU00546} {...params} />
                                                <span className="checkBox-text">公道</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00547" name="JYU00547" checked={this.props.pageState.JYU00547} {...params} />
                                                <span className="checkBox-text">私道</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00548" name="JYU00548" value={this.props.pageState.JYU00548} {...params} length="w80" />
                                        <div className="dataTableUnit-text">番</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00549" name="JYU00549" value={this.props.pageState.JYU00549} {...params} length="w80" />
                                        <div className="dataTableUnit-text">側</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00550" name="JYU00550" value={this.props.pageState.JYU00550} {...params} length="w80" />
                                        <Select id="ID_JYU01435" name="JYU01435" value={this.props.pageState.JYU01435} items={this.getSelectbox(this.props.selectboxData.MEI00006)} {...params} length="w95" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00551" name="JYU00551" value={this.props.pageState.JYU00551} {...params} length="w80" />
                                        <Select id="ID_JYU01439" name="JYU01439" value={this.props.pageState.JYU01439} items={this.getSelectbox(this.props.selectboxData.MEI00006)} {...params} length="w95" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__center is-rightborder">4</td>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00552" name="JYU00552" checked={this.props.pageState.JYU00552} {...params} />
                                                <span className="checkBox-text">公道</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00553" name="JYU00553" checked={this.props.pageState.JYU00553} {...params} />
                                                <span className="checkBox-text">私道</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00554" name="JYU00554" value={this.props.pageState.JYU00554} {...params} length="w80" />
                                        <div className="dataTableUnit-text">番</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00555" name="JYU00555" value={this.props.pageState.JYU00555} {...params} length="w80" />
                                        <div className="dataTableUnit-text">側</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00556" name="JYU00556" value={this.props.pageState.JYU00556} {...params} length="w80" />
                                        <Select id="ID_JYU01436" name="JYU01436" value={this.props.pageState.JYU01436} items={this.getSelectbox(this.props.selectboxData.MEI00006)} {...params} length="w95" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00557" name="JYU00557" value={this.props.pageState.JYU00557} {...params} length="w80" />
                                        <Select id="ID_JYU01440" name="JYU01440" value={this.props.pageState.JYU01440} items={this.getSelectbox(this.props.selectboxData.MEI00006)} {...params} length="w95" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body dataTable-body__top" colSpan="5">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00558" name="JYU00558" value={this.props.pageState.JYU00558} text={SEND_OTHER_PAPER.JYU00558} placeholder={JYU00558LimitErrorMessage.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00558LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            {DomDiagonalLine2}
                            <tr id="AJYU005302-1">
                                <td className="dataTable-title" colSpan="6">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">道路位置指定：</div>
                                        <div className="dataTableUnit-text">（道路の種類5番）</div>
                                        <Input id="ID_JYU00561" name="JYU00561" value={this.props.pageState.JYU00561} {...params} length="w180" />
                                        <div className="dataTableUnit-text">第</div>
                                        <Input id="ID_JYU00562" name="JYU00562" value={this.props.pageState.JYU00562} {...params} length="w60" />
                                        <div className="dataTableUnit-text">号</div>
                                        <div className="dataTableUnit-text">道路内では建築等の利用はできません。</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title">条例による<br />制限</th>
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00563" name="JYU00563" checked={this.props.pageState.JYU00563} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01333" name="JYU01333" checked={this.props.pageState.JYU01333} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-input dataTableUnit-input__wFull">
                                            <InputTextArea id="ID_JYU00564" name="JYU00564" cols={30} rows={10} height="h92" value={this.props.pageState.JYU00564} {...params} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w300">⑥私道の変更または廃止の制限</th>
                                <td className="dataTable-body dataTable-body__w279">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00566" name="JYU00566" checked={this.props.pageState.JYU00566} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01334" name="JYU01334" checked={this.props.pageState.JYU01334} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <th className="dataTable-title dataTable-title__w181">⑦壁面線の制限</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00567" name="JYU00567" checked={this.props.pageState.JYU00567} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01335" name="JYU01335" checked={this.props.pageState.JYU01335} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w300">⑧敷地面積の最低限度</th>
                                <td className="dataTable-body dataTable-body__w279">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00568" name="JYU00568" checked={this.props.pageState.JYU00568} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01336" name="JYU01336" checked={this.props.pageState.JYU01336} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <Input id="ID_JYU00569" name="JYU00569" value={this.props.pageState.JYU00569} {...params} length="w60" />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w181">⑨外壁後退</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00572" name="JYU00572" checked={this.props.pageState.JYU00572} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01337" name="JYU01337" checked={this.props.pageState.JYU01337} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="2">⑩建物の<br />高さの制限</th>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">1.絶対高さ<br />制限</th>
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00574" name="JYU00574" checked={this.props.pageState.JYU00574} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01338" name="JYU01338" checked={this.props.pageState.JYU01338} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">（</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00575" name="JYU00575" checked={this.props.pageState.JYU00575} {...params} />
                                                        <span className="checkBox-text">10m</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00576" name="JYU00576" checked={this.props.pageState.JYU00576} {...params} />
                                                        <span className="checkBox-text">12m</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">）</div>
                                        <div className="dataTableUnit-subnote">※第1種・第2種低層住居専用地域、田園住居地域の場合</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center">2.道路斜線<br />制限</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00577" name="JYU00577" checked={this.props.pageState.JYU00577} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01339" name="JYU01339" checked={this.props.pageState.JYU01339} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <th className="dataTable-title dataTable-title__center">3.隣地斜線<br />制限</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00578" name="JYU00578" checked={this.props.pageState.JYU00578} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01340" name="JYU01340" checked={this.props.pageState.JYU01340} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <th className="dataTable-title dataTable-title__center">4.北側斜線<br />制限</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00579" name="JYU00579" checked={this.props.pageState.JYU00579} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01341" name="JYU01341" checked={this.props.pageState.JYU01341} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w300">⑪日影による中高層の建築物の制限</th>
                                <td className="dataTable-body dataTable-body__w400">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00580" name="JYU00580" checked={this.props.pageState.JYU00580} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01342" name="JYU01342" checked={this.props.pageState.JYU01342} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">（</div>
                                        <Input id="ID_JYU01432" name="JYU01432" value={this.props.pageState.JYU01432} {...params} length="wFlexFull" />
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">⑫建築協定</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00585" name="JYU00585" checked={this.props.pageState.JYU00585} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01343" name="JYU01343" checked={this.props.pageState.JYU01343} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w300">⑬地方公共団体の条例等による制限：</th>
                                <td className="dataTable-body dataTable-body__w325" colSpan="3">
                                    <Input id="ID_JYU00586" name="JYU00586" value={this.props.pageState.JYU00586} {...params} length="w614" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body dataTable-body__top" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00587" name="JYU00587" value={this.props.pageState.JYU00587} text={SEND_OTHER_PAPER.JYU00587} placeholder={JYU00587LimitErrorMessage.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00587LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};
