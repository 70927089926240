import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";
import InputCheckbox from "../../input/inputCheckbox";
import Input from "../../input/inputNormal";

/** 建物の維持修繕の実施状況の記録 */
export default class Block4 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021004",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">建物の維持修繕の実施状況の記録</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">共用部分</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00975" name="JYU00975" checked={this.props.pageState.JYU00975} {...params} />
                                                        <span className="checkBox-text">1.有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01451" name="JYU01451" checked={this.props.pageState.JYU01451} {...params} />
                                                        <span className="checkBox-text">2.無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <Input id="ID_JYU00976" name="JYU00976" value={this.props.pageState.JYU00976} {...params} length="wFlexFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">専有部分<br />（売買対象部分）</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00977" name="JYU00977" checked={this.props.pageState.JYU00977} {...params} />
                                                        <span className="checkBox-text">1.有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01452" name="JYU01452" checked={this.props.pageState.JYU01452} {...params} />
                                                        <span className="checkBox-text">2.無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-input is-flex_full">
                                            <Input id="ID_JYU00978" name="JYU00978" value={this.props.pageState.JYU00978} {...params} />
                                            <span className="dataTableUnit-input-error"><span className="dataTableUnit-input-error_text">入力エラー入力エラー入力エラー入力エラー入力エラー入力エラー入力エラー入力エラー入力エラー入力エラー</span></span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}