export function contractPullDown(state = false, action) {
    switch (action.type) {
        case "OPEN_CONTRACT":
            return true;

        case "CLOSE_CONTRACT":
            return false;

        default:
            return state;
    }
}

export function systemManagePullDown(state = false, action) {
    switch (action.type) {
        case "OPEN_SYSTEM_MANAGE":
            return true;

        case "CLOSE_SYSTEM_MANAGE":
            return false;

        default:
            return state;
    }
}

export function format(state = "", action) {
    switch (action.type) {
        case "FORMAT":
            state = action.format
            return state;

        default:
            return state;
    }
}
