import { PageBase } from "components/page/user/contractFrk/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";
import Block3 from "./block3";
import Block4 from "./block4";
import Block5 from "./block5";

class ImpPage10 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     * ※チェックボックスの場合、falseにする
     */
    getPageItems = () => {
        return {
            // 計画修繕積立金等に関する事項
            JYU00938: false, // 計画修繕積立金制度 有
            JYU01301: false, // 計画修繕積立金制度 無
            JYU00939: undefined, // 別添管理規約
            JYU00940: undefined, // 修繕積立金 月額
            JYU00941: undefined, // 修繕積立金 現在日付
            JYU01664: undefined, // 修繕積立金　フリー
            JYU00942: false, // 当該専有部分に係る滞納 有
            JYU01302: false, // 当該専有部分に係る滞納 無
            JYU00943: undefined, // 当該専有部分に係る滞納 金額
            JYU00951: undefined, // 当該専有部分に係る滞納 現在日付
            JYU00952: undefined, // すでに積み立てられている額 金額
            JYU00953: undefined, // すでに積み立てられている額 現在日付
            JYU00954: false, // 一棟の建物に係る滞納 情報の表示 有
            JYU01303: false, // 一棟の建物に係る滞納 情報の表示 無
            JYU00955: undefined, // 一棟の建物に係る滞納 金額
            JYU00956: undefined, // 一棟の建物に係る滞納 現在日付

            // 通常の管理費用の額
            JYU00958: undefined, // 通常の管理費 月額
            JYU00959: undefined, // 通常の管理費 現在日付
            JYU01665: undefined, // 通常の管理費　フリー
            JYU00960: false, // 当該専有部分に係る滞納 有
            JYU01304: false, // 当該専有部分に係る滞納 無
            JYU00961: undefined, // 当該専有部分に係る滞納 金額
            JYU00962: undefined, // 当該専有部分に係る滞納 現在日付
            JYU00963: false, // 当該専有部分に係る滞納 情報の表示 有
            JYU01305: false, // 当該専有部分に係る滞納 情報の表示 無
            JYU00964: undefined, // 一連の建物に係る滞納 金額
            JYU00965: undefined, // 一連の建物に係る滞納 現在日付

            // 管理組合の名称および管理の委託先
            JYU00967: undefined, // 管理組合の名称
            JYU00968: false, // 管理の形態 全部委託管理
            JYU00969: false, // 管理の形態 一部委託管理
            JYU00970: false, // 管理の形態 自主管理
            JYU00971: undefined, // 管理委託先 名称
            JYU00972: undefined, // 管理委託先 電話番号
            JYU00973: undefined, // 管理委託先 所在
            JYU01666: undefined, // マンション管理適正化法による登録番号　フリー

            // 建物の維持修繕の実施状況の記録
            JYU00975: false, // 共有部分 有
            JYU01451: false, // 共有部分 無
            JYU00976: undefined, // 共有部分 備考
            JYU00977: false, // 専有部分 有
            JYU01452: false, // 専有部分 無
            JYU00978: undefined, // 専有部分 備考

            // その他
            JYU00979: undefined, // 備考
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            contractTypeId: this.props.contractTypeId,  // 契約種別ID
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 計画修繕積立金等に関する事項 */}
                    {/* Block2の内容をBlock1に入れることになったのでBlock2は欠番 */}
                    <li><Block3 {...params} /></li> {/** 管理組合の名称および管理の委託先 */}
                    <li><Block4 {...params} /></li> {/** 建物の維持修繕の実施状況の記録 */}
                    <li><Block5 {...params} /></li> {/** その他 */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
            </React.Fragment>
        );
    }
}

export default withRouter(ImpPage10);