import { Auth } from "aws-amplify";
import { LinkToOtherDomain } from "components/common/parts/link";
import * as APP from "config/app";
import * as AWS from "constants/aws";
import { FORMAT } from "constants/format";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as headerAction from "redux/actions/headerAction";
import * as loginActions from "redux/actions/login";
import * as loginUserAction from "redux/actions/loginUserAction";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";

/** ヘッダー */
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (!this.props.systemAnnouncement) {
            this.requestSystemAnnoucement();
        }
    }

    /** ログアウトハンドラ */
    handleLogout = e => {
        e.preventDefault();
        this.props.updateToUnauthenticated();
        Auth.signOut({ global: true });
        this.props.history.push(pageInfo.LOGOUT.path);
    };

    render() {

        if (this.props.login) {
            return (
                <div className="noticeTop">
                    <SystemAnnouncementBar>{this.props.systemAnnouncement || ""}</SystemAnnouncementBar>
                    <ManualLinkBar>{this.props}</ManualLinkBar>
                    <PullDownAccountMenu userName={this.props.userName || ""} onClickLogout={this.handleLogout} />
                </div>
            );
        } else {
            return (<div>logout@header</div>);
        }
    }

    /** 取得APIを呼出し、stateを更新する */
    requestSystemAnnoucement = () => {
        awsReq.get(
            AWS.ENDPOINT.PATHS.SYSTEM_ANNOUCEMENT,
            response => {
                const camelResponseData = convert.convertToFlatCamelCase(response.data);
                this.props.saveSystemAnnouncement(camelResponseData.message);
            },
            awsReq.defaultErrorHandler,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }
}

const mapStateToProps = state => {
    return {
        login: state.loginStatus,
        systemAnnouncement: state.systemAnnouncement,
        userName: state.userName,
        useFormat: state.useFormat,
        agencyId: state.agencyId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(loginActions, dispatch),
        ...bindActionCreators(loginUserAction, dispatch),
        ...bindActionCreators(headerAction, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

/**
 * システム告知バー
 */
function SystemAnnouncementBar(props) {

    return (
        <div className="noticeTop-notice">
            <div className="notice">
                <div className="notice-title">
                    <span className="notice-title-bar">お知らせ</span>
                </div>
                <div className="notice-text">
                    {/* システム告知文には、htmlタグが含まれる可能性があるため、htmlに変換を行う */}
                    <span dangerouslySetInnerHTML={{ __html: props.children }} />
                </div>
            </div>
        </div>
    );
}

/**
 * マニュアルのリンクバー
 * props.children: リンクバー
 */
function ManualLinkBar(props) {

    // マニュアルの切替え
    let manualUrl = AWS.ENDPOINT_CLOUDFRONT.URL + AWS.ENDPOINT_CLOUDFRONT.PATHS.MANUAL_OK;
    if (props.children.useFormat === FORMAT.FRK.value) {
        manualUrl = AWS.ENDPOINT_CLOUDFRONT.URL + AWS.ENDPOINT_CLOUDFRONT.PATHS.MANUAL_FRK;
        // MUFG様向けと他社様向けの表示分岐
        if (APP.OPERATOR_AGENCY_MUFG.includes(props.children.agencyId.toString())) {
            manualUrl = AWS.ENDPOINT_CLOUDFRONT.URL + AWS.ENDPOINT_CLOUDFRONT.PATHS.MANUAL_MUFG;
        }
    }

    return (
        <div className="noticeTop-manual">
            <div className="notice-manual">
                <LinkToOtherDomain to={manualUrl} className="notice-manual__action" target="_blank">
                    <span className="notice-manual__text">マニュアル</span>
                </LinkToOtherDomain>
            </div>
        </div >
    );
}

/**
 * プルダウンのアカウントメニュー
 * props.userName: ログイン中のユーザー名
 * onClickLogout: ログアウトボタン押下時のハンドラ
 */
class PullDownAccountMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = { accountMenuActive: false };
        // プルダウンメニュー押下時の非アクティブ防止用
        this.timeOutId = null;
    }

    // ユーザーアカウントメニューのアクティブ制御
    handleChangeAccountMenuActive = e => {
        e.preventDefault();
        this.setState(state => ({ accountMenuActive: !state.accountMenuActive }));
    };

    /** カーソルが外れた際に、アカウントメニューを非表示にする */
    handleCloseAccountMenu = () => {
        this.timeOutId = setTimeout(() => {
            this.setState({ accountMenuActive: false });
        });
    }

    /** フォーカスがある場合、アカウントメニューを非表示にするのを防止する */
    handleCancelCloseAccountMenu = () => {
        clearTimeout(this.timeOutId);
    }

    render() {
        return (
            <div className="noticeTop-userAccount">
                <div className="userAccount" onBlur={this.handleCloseAccountMenu} onFocus={this.handleCancelCloseAccountMenu}>
                    <button className="userAccount-name" onClick={this.handleChangeAccountMenuActive}
                        // デフォルトのボタンCSSを無効化
                        style={{
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                            outline: "none",
                            padding: "0",
                            appearance: "none"
                        }}
                    >
                        {this.props.userName}
                    </button>
                    {/* プルダウンメニューの表示制御 */}
                    <div className={this.state.accountMenuActive ? "userAccount-menu is-active" : "userAccount-menu"}>
                        <ul className="userAccount-menu-list">
                            <li>
                                <Link className="userAccount-menu-list__action" to={pageInfo.PASSWORD_CHANGE.path}>パスワード変更</Link>
                            </li>
                            <li>
                                <Link className="userAccount-menu-list__action" to={pageInfo.LOGOUT.path} onClick={this.props.onClickLogout}>ログアウト</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
