/**
 * 最大入力可能文字数のエラーメッセージ、プレースホルダー表示用
 * 
 * 項目ID_帳票区分：[入力可能行数, 1行あたりの入力可能文字数]
 * TEXTAREAの表示可能行数及び１行の文字数を定義する。
 * また、定義の内容をプレースホルダーに表示及びエラーチェックで利用する。
 * 帳票ID:{
 *  ページID:{
 *      項目ID:{numLine:行数,wordCnt:文字数}
 *  }
 * }
 */
export const INPUT_LIMITS_FRK = {
    // 0101_FRK一般仲介用・土地（実測・清算）
    '0101': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 12, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 23, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 13, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 19, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0212': {
            'JYU01100': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 22, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
            'JYU01457': { 'numLine': 9, 'wordCnt': 49, },
        },
        '0216': {
            'JYU01222': { 'numLine': 33, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 43, 'wordCnt': 49, },
            'JYU01776': { 'numLine': 63, 'wordCnt': 48, },
        },
        '0301': {
            'FUD00135': { 'numLine': 18, 'wordCnt': 47, },
        },
        '0304': {
            'FUD00333': { 'numLine': 16, 'wordCnt': 49, },
        },
    },
    // 0102_FRK一般仲介用・土地（確定測量・清算）
    '0102': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 12, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 23, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 13, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 19, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0212': {
            'JYU01100': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 22, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
            'JYU01457': { 'numLine': 9, 'wordCnt': 49, },
        },
        '0216': {
            'JYU01222': { 'numLine': 33, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 43, 'wordCnt': 49, },
            'JYU01776': { 'numLine': 63, 'wordCnt': 48, },
        },
        '0301': {
            'FUD00135': { 'numLine': 18, 'wordCnt': 47, },
        },
        '0304': {
            'FUD00333': { 'numLine': 16, 'wordCnt': 49, },
        },
    },
    // 0103_FRK一般仲介用・土地（売買代金固定）
    '0103': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 12, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 23, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 13, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 19, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0212': {
            'JYU01100': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 22, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
            'JYU01457': { 'numLine': 9, 'wordCnt': 49, },
        },
        '0216': {
            'JYU01222': { 'numLine': 33, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 43, 'wordCnt': 49, },
            'JYU01776': { 'numLine': 63, 'wordCnt': 48, },
        },
        '0301': {
            'FUD00135': { 'numLine': 23, 'wordCnt': 47, },
        },
        '0304': {
            'FUD00333': { 'numLine': 16, 'wordCnt': 49, },
        },
    },
    // 0104_FRK一般仲介用・土地建物（実測・清算）
    '0104': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00322': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU00352': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00359': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 11, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 15, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 24, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 44, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 11, 'wordCnt': 47, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0105_FRK一般仲介用・土地建物（確定測量・清算）
    '0105': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00322': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU00352': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00359': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 11, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 15, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 24, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 44, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 11, 'wordCnt': 47, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0106_FRK一般仲介用・土地建物（売買代金固定）
    '0106': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00322': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU00352': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00359': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 11, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 15, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 24, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 44, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 17, 'wordCnt': 47, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0107_FRK一般仲介用・区分所有建物（敷地権）
    '0107': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00147': { 'numLine': 9, 'wordCnt': 49, },
            'JYU00196': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU01783': { 'numLine': 4, 'wordCnt': 40, },
            'JYU01786': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 15, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 6, 'wordCnt': 23, },
            'JYU00587': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 13, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 5, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01618': { 'numLine': 2, 'wordCnt': 17, },
            'JYU01622': { 'numLine': 6, 'wordCnt': 14, },
            'JYU01623': { 'numLine': 6, 'wordCnt': 17, },
            'JYU01628': { 'numLine': 2, 'wordCnt': 17, },
            'JYU01634': { 'numLine': 2, 'wordCnt': 17, },
        },
        '0208': {
            'JYU00804': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00813': { 'numLine': 7, 'wordCnt': 49, },
            'JYU00816': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00820': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00824': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00828': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00833': { 'numLine': 10, 'wordCnt': 49, },
        },
        '0209': {
            'JYU00918': { 'numLine': 10, 'wordCnt': 49, },
            'JYU00934': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0210': {
            'JYU00979': { 'numLine': 10, 'wordCnt': 49, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 9, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 12, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 25, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 6, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 29, 'wordCnt': 49, },
        },
        '0217': {
            'JYU01547': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 42, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 6, 'wordCnt': 47, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0108_FRK一般仲介用・区分所有建物（非敷地権）
    '0108': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00147': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00296': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU01783': { 'numLine': 4, 'wordCnt': 40, },
            'JYU01786': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 15, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 6, 'wordCnt': 23, },
            'JYU00587': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 13, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 5, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01618': { 'numLine': 2, 'wordCnt': 17, },
            'JYU01622': { 'numLine': 6, 'wordCnt': 14, },
            'JYU01623': { 'numLine': 6, 'wordCnt': 17, },
            'JYU01628': { 'numLine': 2, 'wordCnt': 17, },
            'JYU01634': { 'numLine': 2, 'wordCnt': 17, },
        },
        '0208': {
            'JYU00804': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00813': { 'numLine': 7, 'wordCnt': 49, },
            'JYU00816': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00820': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00824': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00828': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00833': { 'numLine': 10, 'wordCnt': 49, },
        },
        '0209': {
            'JYU00918': { 'numLine': 10, 'wordCnt': 49, },
            'JYU00934': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0210': {
            'JYU00979': { 'numLine': 10, 'wordCnt': 49, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 9, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 12, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 26, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 6, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 29, 'wordCnt': 49, },
        },
        '0217': {
            'JYU01547': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 42, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 6, 'wordCnt': 47, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0109_FRK一般仲介用・借地権付建物
    '0109': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00322': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU00352': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00359': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 11, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 15, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 24, 'wordCnt': 49, },
        },
        '0217': {
            'JYU01547': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 44, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 11, 'wordCnt': 48, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0110_FRK一般仲介用・定期借地権付建物
    '0110': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00322': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU00352': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00359': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 11, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 15, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 24, 'wordCnt': 49, },
        },
        '0217': {
            'JYU01767': { 'numLine': 17, 'wordCnt': 48, },
        },
        '0218': {
            'JYU01748': { 'numLine': 44, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00202': { 'numLine': 4, 'wordCnt': 47, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0111_FRK一般仲介用・定期借地権付区分所有建物
    '0111': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00147': { 'numLine': 9, 'wordCnt': 49, },
            'JYU00196': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU01783': { 'numLine': 4, 'wordCnt': 40, },
            'JYU01786': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 15, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 6, 'wordCnt': 23, },
            'JYU00587': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 13, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 5, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01618': { 'numLine': 2, 'wordCnt': 17, },
            'JYU01622': { 'numLine': 6, 'wordCnt': 14, },
            'JYU01623': { 'numLine': 6, 'wordCnt': 17, },
            'JYU01628': { 'numLine': 2, 'wordCnt': 17, },
            'JYU01634': { 'numLine': 2, 'wordCnt': 17, },
        },
        '0208': {
            'JYU00804': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00813': { 'numLine': 7, 'wordCnt': 49, },
            'JYU00816': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00820': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00824': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00828': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00833': { 'numLine': 10, 'wordCnt': 49, },
        },
        '0209': {
            'JYU00918': { 'numLine': 10, 'wordCnt': 49, },
            'JYU00934': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0210': {
            'JYU00979': { 'numLine': 10, 'wordCnt': 49, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 9, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 12, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 25, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 6, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 29, 'wordCnt': 49, },
        },
        '0217': {
            'JYU01767': { 'numLine': 17, 'wordCnt': 48, },
        },
        '0218': {
            'JYU01748': { 'numLine': 42, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00202': { 'numLine': 4, 'wordCnt': 47, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0201_FRK消費者契約用・土地（実測・清算）
    '0201': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 12, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 23, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 13, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 20, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0212': {
            'JYU01100': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 28, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 6, 'wordCnt': 37, },
            'JYU01457': { 'numLine': 9, 'wordCnt': 49, },
        },
        '0216': {
            'JYU01222': { 'numLine': 19, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 43, 'wordCnt': 49, },
            'JYU01776': { 'numLine': 63, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00135': { 'numLine': 18, 'wordCnt': 47, },
        },
        '0304': {
            'FUD00333': { 'numLine': 16, 'wordCnt': 49, },
        },
    },
    // 0202_FRK消費者契約用・土地（確定測量・清算）
    '0202': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 12, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 23, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 13, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 20, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0212': {
            'JYU01100': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 28, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 6, 'wordCnt': 37, },
            'JYU01457': { 'numLine': 9, 'wordCnt': 49, },
        },
        '0216': {
            'JYU01222': { 'numLine': 19, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 43, 'wordCnt': 49, },
            'JYU01776': { 'numLine': 63, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00135': { 'numLine': 18, 'wordCnt': 47, },
        },
        '0304': {
            'FUD00333': { 'numLine': 16, 'wordCnt': 49, },
        },
    },
    // 0203_FRK消費者契約用・土地（売買代金固定）
    '0203': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 12, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 23, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 13, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 20, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0212': {
            'JYU01100': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 28, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 6, 'wordCnt': 37, },
            'JYU01457': { 'numLine': 9, 'wordCnt': 49, },
        },
        '0216': {
            'JYU01222': { 'numLine': 19, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 43, 'wordCnt': 49, },
            'JYU01776': { 'numLine': 63, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00135': { 'numLine': 23, 'wordCnt': 47, },
        },
        '0304': {
            'FUD00333': { 'numLine': 16, 'wordCnt': 49, },
        },
    },
    // 0204_FRK消費者契約用・土地建物（実測・清算）
    '0204': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00322': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU00352': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00359': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 11, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 23, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 16, 'wordCnt': 48, },
        },
        '0218': {
            'JYU01748': { 'numLine': 44, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 11, 'wordCnt': 26, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0205_FRK消費者契約用・土地建物（確定測量・清算）
    '0205': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00322': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU00352': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00359': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 11, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 23, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 16, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 44, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 11, 'wordCnt': 26, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0206_FRK消費者契約用・土地建物（売買代金固定）
    '0206': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00322': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU00352': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00359': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 11, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 23, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 16, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 44, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 16, 'wordCnt': 26, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0207_FRK消費者契約用・区分所有建物（敷地権）
    '0207': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00147': { 'numLine': 9, 'wordCnt': 49, },
            'JYU00196': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU01783': { 'numLine': 4, 'wordCnt': 40, },
            'JYU01786': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 15, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 6, 'wordCnt': 23, },
            'JYU00587': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 13, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01618': { 'numLine': 2, 'wordCnt': 17, },
            'JYU01622': { 'numLine': 6, 'wordCnt': 14, },
            'JYU01623': { 'numLine': 6, 'wordCnt': 17, },
            'JYU01628': { 'numLine': 2, 'wordCnt': 17, },
            'JYU01634': { 'numLine': 2, 'wordCnt': 17, },
        },
        '0208': {
            'JYU00804': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00813': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00816': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00820': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00824': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00828': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00833': { 'numLine': 10, 'wordCnt': 49, },
        },
        '0209': {
            'JYU00918': { 'numLine': 10, 'wordCnt': 49, },
            'JYU00934': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0210': {
            'JYU00979': { 'numLine': 10, 'wordCnt': 49, },
        },
        '0211': {
            'JYU00982': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 12, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 23, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 6, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 24, 'wordCnt': 49, },
        },
        '0217': {
            'JYU01547': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 43, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 7, 'wordCnt': 26, },
        },
        '0304': {
            'FUD00333': { 'numLine': 10, 'wordCnt': 49, },
        },
    },
    // 0208_FRK消費者契約用・区分所有建物（非敷地権）
    '0208': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 21, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 21, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00147': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00296': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU01783': { 'numLine': 4, 'wordCnt': 40, },
            'JYU01786': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 15, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 6, 'wordCnt': 23, },
            'JYU00587': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 13, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01618': { 'numLine': 2, 'wordCnt': 17, },
            'JYU01622': { 'numLine': 6, 'wordCnt': 14, },
            'JYU01623': { 'numLine': 6, 'wordCnt': 17, },
            'JYU01628': { 'numLine': 2, 'wordCnt': 17, },
            'JYU01634': { 'numLine': 2, 'wordCnt': 17, },
        },
        '0208': {
            'JYU00804': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00813': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00816': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00820': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00824': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00828': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00833': { 'numLine': 10, 'wordCnt': 49, },
        },
        '0209': {
            'JYU00918': { 'numLine': 10, 'wordCnt': 49, },
            'JYU00934': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0210': {
            'JYU00979': { 'numLine': 10, 'wordCnt': 49, },
        },
        '0211': {
            'JYU00982': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 12, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 23, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 6, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 24, 'wordCnt': 49, },
        },
        '0217': {
            'JYU01547': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 43, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 7, 'wordCnt': 26, },
        },
        '0304': {
            'FUD00333': { 'numLine': 10, 'wordCnt': 49, },
        },
    },
    // 0209_FRK消費者契約用・借地権付建物
    '0209': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 33, },
            'TOR00352': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 33, },
        },
        '0201': {
            'JYU00011': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00322': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU00352': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00359': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 11, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 23, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 16, 'wordCnt': 49, },
        },
        '0217': {
            'JYU01547': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 44, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 12, 'wordCnt': 26, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0301_FRK売主宅建業者用・土地（実測・清算）
    '0301': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 21, },
            'TOR00352': { 'numLine': 2, 'wordCnt': 18, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 21, },
        },
        '0201': {
            'JYU01461': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 10, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 23, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 13, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 19, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0212': {
            'JYU01100': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 28, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
            'JYU01457': { 'numLine': 9, 'wordCnt': 49, },
        },
        '0216': {
            'JYU01222': { 'numLine': 19, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 43, 'wordCnt': 49, },
            'JYU01776': { 'numLine': 63, 'wordCnt': 48, },
        },
        '0301': {
            'FUD00135': { 'numLine': 19, 'wordCnt': 47, },
        },
        '0303': {
            'FUD00389': { 'numLine': 2, 'wordCnt': 42, },
        },
        '0304': {
            'FUD00333': { 'numLine': 16, 'wordCnt': 49, },
        },
    },
    // 0302_FRK売主宅建業者用・土地（確定測量・清算）
    '0302': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 21, },
            'TOR00352': { 'numLine': 2, 'wordCnt': 18, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 21, },
        },
        '0201': {
            'JYU01461': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 10, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 23, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 13, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 19, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0212': {
            'JYU01100': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 28, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
            'JYU01457': { 'numLine': 9, 'wordCnt': 49, },
        },
        '0216': {
            'JYU01222': { 'numLine': 19, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 43, 'wordCnt': 49, },
            'JYU01776': { 'numLine': 63, 'wordCnt': 48, },
        },
        '0301': {
            'FUD00135': { 'numLine': 19, 'wordCnt': 47, },
        },
        '0303': {
            'FUD00389': { 'numLine': 2, 'wordCnt': 42, },
        },
        '0304': {
            'FUD00333': { 'numLine': 16, 'wordCnt': 49, },
        },
    },
    // 0303_FRK売主宅建業者用・土地（売買代金固定）
    '0303': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 21, },
            'TOR00352': { 'numLine': 2, 'wordCnt': 18, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 21, },
        },
        '0201': {
            'JYU01461': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 10, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 23, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 13, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 19, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0212': {
            'JYU01100': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 28, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
            'JYU01457': { 'numLine': 9, 'wordCnt': 49, },
        },
        '0216': {
            'JYU01222': { 'numLine': 19, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 43, 'wordCnt': 49, },
            'JYU01776': { 'numLine': 63, 'wordCnt': 48, },
        },
        '0301': {
            'FUD00135': { 'numLine': 24, 'wordCnt': 47, },
        },
        '0303': {
            'FUD00389': { 'numLine': 2, 'wordCnt': 42, },
        },
        '0304': {
            'FUD00333': { 'numLine': 16, 'wordCnt': 49, },
        },
    },
    // 0304_FRK売主宅建業者用・土地建物（実測・清算）
    '0304': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 21, },
            'TOR00352': { 'numLine': 2, 'wordCnt': 18, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 21, },
        },
        '0201': {
            'JYU01461': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00011': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00322': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU00352': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00359': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 14, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 5, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 27, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 44, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 12, 'wordCnt': 26, },
        },
        '0303': {
            'FUD00389': { 'numLine': 2, 'wordCnt': 42, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0305_FRK売主宅建業者用・土地建物（確定測量・清算）
    '0305': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 21, },
            'TOR00352': { 'numLine': 2, 'wordCnt': 18, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 21, },
        },
        '0201': {
            'JYU01461': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00011': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00322': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU00352': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00359': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 14, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 5, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 27, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 44, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 13, 'wordCnt': 26, },
        },
        '0303': {
            'FUD00389': { 'numLine': 2, 'wordCnt': 42, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0306_FRK売主宅建業者用・土地建物（売買代金固定）
    '0306': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 21, },
            'TOR00352': { 'numLine': 2, 'wordCnt': 18, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 21, },
        },
        '0201': {
            'JYU01461': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00011': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00322': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU00352': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00359': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 14, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 5, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 27, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 44, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 17, 'wordCnt': 26, },
        },
        '0303': {
            'FUD00389': { 'numLine': 2, 'wordCnt': 42, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0307_FRK売主宅建業者用・区分所有建物（敷地権）
    '0307': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 21, },
            'TOR00352': { 'numLine': 2, 'wordCnt': 18, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 21, },
        },
        '0201': {
            'JYU01461': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00147': { 'numLine': 9, 'wordCnt': 49, },
            'JYU00196': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU01783': { 'numLine': 4, 'wordCnt': 40, },
            'JYU01786': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 15, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 6, 'wordCnt': 23, },
            'JYU00587': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 13, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01618': { 'numLine': 2, 'wordCnt': 17, },
            'JYU01622': { 'numLine': 6, 'wordCnt': 14, },
            'JYU01623': { 'numLine': 6, 'wordCnt': 17, },
            'JYU01628': { 'numLine': 2, 'wordCnt': 17, },
            'JYU01634': { 'numLine': 2, 'wordCnt': 17, },
        },
        '0208': {
            'JYU00804': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00813': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00816': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00820': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00824': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00828': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00833': { 'numLine': 10, 'wordCnt': 49, },
        },
        '0209': {
            'JYU00918': { 'numLine': 10, 'wordCnt': 49, },
            'JYU00934': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0210': {
            'JYU00979': { 'numLine': 10, 'wordCnt': 49, },
        },
        '0211': {
            'JYU00982': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 12, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 22, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 7, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 24, 'wordCnt': 49, },
        },
        '0217': {
            'JYU01547': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 42, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 7, 'wordCnt': 26, },
        },
        '0303': {
            'FUD00389': { 'numLine': 2, 'wordCnt': 42, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0308_FRK売主宅建業者用・区分所有建物（非敷地権）
    '0308': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 21, },
            'TOR00352': { 'numLine': 2, 'wordCnt': 18, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 21, },
        },
        '0201': {
            'JYU01461': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00011': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00147': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00296': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU01783': { 'numLine': 4, 'wordCnt': 40, },
            'JYU01786': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 15, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 6, 'wordCnt': 23, },
            'JYU00587': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 13, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01618': { 'numLine': 2, 'wordCnt': 17, },
            'JYU01622': { 'numLine': 6, 'wordCnt': 14, },
            'JYU01623': { 'numLine': 6, 'wordCnt': 17, },
            'JYU01628': { 'numLine': 2, 'wordCnt': 17, },
            'JYU01634': { 'numLine': 2, 'wordCnt': 17, },
        },
        '0208': {
            'JYU00804': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00813': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00816': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00820': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00824': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00828': { 'numLine': 3, 'wordCnt': 33, },
            'JYU00833': { 'numLine': 10, 'wordCnt': 49, },
        },
        '0209': {
            'JYU00918': { 'numLine': 10, 'wordCnt': 49, },
            'JYU00934': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0210': {
            'JYU00979': { 'numLine': 10, 'wordCnt': 49, },
        },
        '0211': {
            'JYU00982': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 13, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 23, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 6, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 24, 'wordCnt': 49, },
        },
        '0217': {
            'JYU01547': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 42, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 7, 'wordCnt': 26, },
        },
        '0303': {
            'FUD00389': { 'numLine': 2, 'wordCnt': 42, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0309_FRK売主宅建業者用・新築・土地建物
    '0309': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 21, },
            'TOR00352': { 'numLine': 2, 'wordCnt': 18, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 21, },
        },
        '0201': {
            'JYU01461': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00011': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00322': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU00352': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00359': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 14, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 5, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 27, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 44, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00135': { 'numLine': 14, 'wordCnt': 26, },
        },
        '0302': {
            'FUD00367': { 'numLine': 6, 'wordCnt': 49, },
        },
        '0303': {
            'FUD00389': { 'numLine': 2, 'wordCnt': 42, },
        },
        '0304': {
            'FUD00333': { 'numLine': 7, 'wordCnt': 49, },
        },
    },
    // 0310_FRK売主宅建業者用・借地権付建物
    '0310': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 21, },
            'TOR00352': { 'numLine': 2, 'wordCnt': 17, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 21, },
        },
        '0201': {
            'JYU01461': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00011': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00322': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU00352': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00359': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 14, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 5, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 27, 'wordCnt': 49, },
        },
        '0217': {
            'JYU01547': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 44, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00373': { 'numLine': 3, 'wordCnt': 49, },
            'FUD00135': { 'numLine': 13, 'wordCnt': 26, },
        },
        '0303': {
            'FUD00389': { 'numLine': 2, 'wordCnt': 42, },
        },
        '0304': {
            'FUD00333': { 'numLine': 11, 'wordCnt': 49, },
        },
    },
    // 0311_FRK売主宅建業者用・新築・借地権付建物
    '0311': {
        '0101': {
            'TOR00349': { 'numLine': 3, 'wordCnt': 21, },
            'TOR00351': { 'numLine': 2, 'wordCnt': 21, },
            'TOR00352': { 'numLine': 2, 'wordCnt': 17, },
            'TOR00354': { 'numLine': 2, 'wordCnt': 21, },
        },
        '0201': {
            'JYU01461': { 'numLine': 2, 'wordCnt': 17, },
            'JYU00011': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00016': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00017': { 'numLine': 2, 'wordCnt': 16, },
            'JYU00023': { 'numLine': 3, 'wordCnt': 17, },
            'JYU00054': { 'numLine': 2, 'wordCnt': 20, },
            'JYU01768': { 'numLine': 5, 'wordCnt': 20, },
            'JYU01769': { 'numLine': 5, 'wordCnt': 23, },
        },
        '0202': {
            'JYU00296': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00322': { 'numLine': 5, 'wordCnt': 49, },
            'JYU00327': { 'numLine': 3, 'wordCnt': 49, },
            'JYU00331': { 'numLine': 3, 'wordCnt': 45, },
        },
        '0203': {
            'JYU00371': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00375': { 'numLine': 10, 'wordCnt': 40, },
            'JYU00352': { 'numLine': 4, 'wordCnt': 40, },
            'JYU00359': { 'numLine': 10, 'wordCnt': 40, },
        },
        '0204': {
            'JYU00412': { 'numLine': 4, 'wordCnt': 49, },
            'JYU00513': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00558': { 'numLine': 8, 'wordCnt': 22, },
            'JYU00587': { 'numLine': 7, 'wordCnt': 49, },
        },
        '0205': {
            'JYU00693': { 'numLine': 9, 'wordCnt': 47, },
        },
        '0206': {
            'JYU00701': { 'numLine': 8, 'wordCnt': 49, },
            'JYU00705': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0207': {
            'JYU00785': { 'numLine': 6, 'wordCnt': 49, },
            'JYU01622': { 'numLine': 2, 'wordCnt': 18, },
        },
        '0211': {
            'JYU00982': { 'numLine': 5, 'wordCnt': 49, },
            'JYU01013': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01020': { 'numLine': 8, 'wordCnt': 49, },
        },
        '0212': {
            'JYU01100': { 'numLine': 8, 'wordCnt': 49, },
            'JYU01121': { 'numLine': 14, 'wordCnt': 49, },
        },
        '0213': {
            'JYU01554': { 'numLine': 5, 'wordCnt': 37, },
        },
        '0216': {
            'JYU01222': { 'numLine': 27, 'wordCnt': 49, },
        },
        '0217': {
            'JYU01547': { 'numLine': 4, 'wordCnt': 49, },
        },
        '0218': {
            'JYU01748': { 'numLine': 44, 'wordCnt': 49, },
        },
        '0301': {
            'FUD00135': { 'numLine': 10, 'wordCnt': 26, },
        },
        '0302': {
            'FUD00367': { 'numLine': 6, 'wordCnt': 49, },
        },
        '0303': {
            'FUD00389': { 'numLine': 2, 'wordCnt': 42, },
        },
        '0304': {
            'FUD00333': { 'numLine': 7, 'wordCnt': 49, },
        },
    },
};