import { PageBase } from "components/page/user/contractFrk/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";

class ImpPage4 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     */
    getPageItems = () => {
        return {
            // 都市計画法、建築基準法の法令に基づく制限
            JYU00388: false, // 都市計画区域
            JYU00389: false, // 市街化区域
            JYU00390: false, // 市街化調整区域
            JYU00391: false, // 区域区分のされていない区域
            JYU00392: false, // 都市計画区域外
            JYU00393: false, // 都市計画区域外 準都市計画区域の指定 有
            JYU01329: false, // 都市計画区域外 準都市計画区域の指定 無
            JYU01588: undefined, // 既存宅地番号　元号
            JYU01589: undefined, // 既存宅地番号　年
            JYU01590: undefined, // 既存宅地番号　月
            JYU01591: undefined, // 既存宅地番号　日
            JYU00396: undefined, // 既存宅地番号 号
            JYU01592: undefined, // 許可番号　元号
            JYU01593: undefined, // 許可番号　年
            JYU01594: undefined, // 許可番号　月
            JYU01595: undefined, // 許可番号　日
            JYU00398: undefined, // 許可番号 号
            JYU01596: undefined, // 検査済番号　元号
            JYU01597: undefined, // 検査済番号　年
            JYU01598: undefined, // 検査済番号　月
            JYU01599: undefined, // 検査済番号　日
            JYU00400: undefined, // 検査済番号 号
            JYU01600: undefined, // 公告　元号
            JYU01601: undefined, // 公告　年
            JYU01602: undefined, // 公告　月
            JYU01603: undefined, // 公告　日
            JYU00402: undefined, // 公告 号
            JYU00403: false, // 都市計画施設 有
            JYU01330: false, // 都市計画施設 無
            JYU00404: undefined, // 都市計画道路
            JYU00405: false, // 都市計画道路 計画決定
            JYU00406: false, // 都市計画道路 事業決定
            JYU00407: undefined, // 都市計画道路 事業決定 名称
            JYU00408: undefined, // 都市計画道路 幅員
            JYU00409: false, // その他の都市計画施設
            JYU00410: undefined, // その他の都市計画施設 内容
            JYU00411: false, // 市街地開発事業 有
            JYU01331: false, // 市街地開発事業 無
            JYU01604: undefined, // 市街地開発事業　内容
            JYU00412: undefined, // 備考
            // 建築基準法に基づく制限
            JYU00444: false, // 第１種低層住居専用地域
            JYU00445: false, // 第２種低層住居専用地域
            JYU00446: false, // 第１種中高層住居専用地域
            JYU00447: false, // 第２種中高層住居専用地域
            JYU00448: false, // 第１種住居地域
            JYU00449: false, // 第２種住居地域
            JYU00450: false, // 田園住居地域
            JYU00451: false, // 準住居地域
            JYU00452: false, // 近隣商業地域
            JYU00453: false, // 商業地域
            JYU00454: false, // 準工業地域
            JYU00455: false, // 工業地域
            JYU00456: false, // 工業専用地域
            JYU00457: false, // 用途地域の指定なし
            JYU00458: false, // その他１ チェック
            JYU00459: undefined, // その他１ 内容
            JYU00463: false, // 特別用途地区
            JYU00464: undefined, // 特別用途地区 名称
            JYU00465: false, // 防火地域
            JYU00466: false, // 準防火地域
            JYU00467: false, // 高度地区
            JYU00468: undefined, // 高度地区　内容
            JYU00469: false, // 高度利用地区
            JYU00470: false, // 特定街区
            JYU00471: false, // 景観地区
            JYU00472: false, // 風致地区
            JYU00473: false, // 災害危険区域
            JYU00474: false, // 地区計画区域
            JYU00475: false, // 特例容積率適用地区
            JYU00476: false, // 特定用途制限地域
            JYU00478: false, // 高層住居誘導地区
            JYU00479: false, // 駐車場整備地区
            JYU00480: false, // 都市再生特別地区
            JYU00481: false, // 特定防災街区整備地区
            JYU01605: false, // 特定用途誘導地区
            JYU00482: false, // その他１ チェック
            JYU00483: undefined, // その他１ 内容
            JYU00484: false, // その他２ チェック
            JYU00485: undefined, // その他２ 内容
            JYU00489: undefined, // 建ぺい率 指定（%）
            JYU01607: undefined, // 建ぺい率　内容
            JYU00490: undefined, // 建ぺい率 備考
            JYU00501: undefined, // 容積率 指定（%）
            JYU01608: undefined, // 容積率　内容
            JYU00502: undefined, // 容積率 備考
            JYU00507: undefined, // 道路幅員制限（m）
            JYU00509: undefined, // 道路幅員制限 特定道路による緩和 係数
            JYU00513: undefined, // 備考
            // 敷地等と道路の関係（概略図）
            JYU00595: undefined, // 概略図
            // 道路の種類
            JYU00524: false, // 道路の種類 道路法による道路
            JYU00525: false, // 道路の種類 都市計画法等による道路
            JYU00526: false, // 道路の種類 既存道
            JYU00527: false, // 道路の種類 道路法等による計画道
            JYU00528: false, // 道路の種類 特定行政庁から指定を受けたもの
            JYU00529: false, // 道路の種類 特定行政庁が指定したもの
            JYU00530: false, // 道路の種類 建築基準法上の道路に該当しない通路
            JYU00531: false, // 道路境界線後退 有
            JYU01332: false, // 道路境界線後退 無
            // 前面道路
            JYU00534: false, // 前面道路１ 公道
            JYU00535: false, // 前面道路１ 私道
            JYU01646: false, // 前面道路１　無
            JYU00536: undefined, // 前面道路１ 種類
            JYU00537: undefined, // 前面道路１ 接道方向
            JYU00538: undefined, // 前面道路１ 幅員
            JYU00539: undefined, // 前面道路１ 幅員 接道の長さ
            JYU00540: false, // 前面道路２ 公道
            JYU00541: false, // 前面道路２ 私道
            JYU01647: false, // 前面道路２　無
            JYU00542: undefined, // 前面道路２ 種類
            JYU00543: undefined, // 前面道路２ 接道方向
            JYU00544: undefined, // 前面道路２ 幅員
            JYU00545: undefined, // 前面道路２ 幅員 接道の長さ
            JYU00546: false, // 前面道路３ 公道
            JYU00547: false, // 前面道路３ 私道
            JYU01648: false, // 前面道路３　無
            JYU00548: undefined, // 前面道路３ 種類
            JYU00549: undefined, // 前面道路３ 接道方向
            JYU00550: undefined, // 前面道路３ 幅員
            JYU00551: undefined, // 前面道路３ 幅員 接道の長さ
            JYU00552: false, // 前面道路４ 公道
            JYU00553: false, // 前面道路４ 私道
            JYU01649: false, // 前面道路４　無
            JYU00554: undefined, // 前面道路４ 種類
            JYU00555: undefined, // 前面道路４ 接道方向
            JYU00556: undefined, // 前面道路４ 幅員
            JYU00557: undefined, // 前面道路４ 幅員 接道の長さ
            JYU00558: undefined, // 前面道路 備考
            JYU01609: undefined, // 道路位置指定　元号
            JYU01610: undefined, // 道路位置指定　年
            JYU01611: undefined, // 道路位置指定　月
            JYU01612: undefined, // 道路位置指定　日
            JYU00562: undefined, // 道路位置指定 号数
            JYU00563: false, // 条例による制限 有
            JYU01333: false, // 条例による制限 無
            JYU00564: undefined, // 条例による制限 備考
            JYU01790: undefined, // 条例による制限 備考フリー
            JYU00566: false, // 私道の変更 または廃止の制限 有
            JYU01334: false, // 私道の変更 または廃止の制限 無
            JYU00567: false, // 壁面線 有
            JYU01335: false, // 壁面線 無
            JYU00568: false, // 敷地面積の最低限度 有
            JYU01336: false, // 敷地面積の最低限度 無
            JYU00569: undefined, // 面積（㎡）
            JYU00572: false, // 外壁後退 有
            JYU01337: false, // 外壁後退 無
            JYU00574: false, // 絶対高さ制限 有
            JYU01338: false, // 絶対高さ制限 無
            JYU00575: false, // 絶対高さ制限 １０m
            JYU00576: false, // 絶対高さ制限 １２m
            JYU00577: false, // 道路斜線制限 有
            JYU01339: false, // 道路斜線制限 無
            JYU00578: false, // 隣地斜線制限 有
            JYU01340: false, // 隣地斜線制限 無
            JYU00579: false, // 北側斜線制限 有
            JYU01341: false, // 北側斜線制限 無
            JYU00580: false, // 日影による中高層の建築物の制限 有
            JYU01342: false, // 日影による中高層の建築物の制限 無
            JYU01432: undefined, // 日影時間
            JYU00585: false, // 建築協定 有
            JYU01343: false, // 建築協定 無
            JYU01613: undefined, // 建築協定　内容
            JYU00586: undefined, // 地方公共団体の条例等による制限 内容
            JYU01614: undefined, // 地方公共団体の条例等による制限　内容２
            JYU00587: undefined, // 地方公共団体の条例等による制限 備考
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報、画像情報を大元に同期する
        this.props.sync(this.pickupContractInfo(), this.state.input_image);
        // ファイルアップロード情報の同期
        this.props.syncUpload(this.state.upload);

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            contractTypeId: this.props.contractTypeId,  // 契約種別ID
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 都市計画法に基づく制限（制限の内容等については、補足資料に記載されています。） */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
            </React.Fragment>
        );
    }
}

export default withRouter(ImpPage4);