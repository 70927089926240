import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { CONTRACT_EDIT_INFO } from "../../../../../../../constants/contractEditPageInfo";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 買主 */
class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020101",
        };
    }

    handleTo0101 = e => {
        e.preventDefault();
        // リロードをかける
        this.props.pageSwitch(CONTRACT_EDIT_INFO.PAGE_0101.id);
    }

    render() {
        const { hideBlockClass } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        const TOR00094_ARR = this.props.pageState.TOR00094_ARR;

        let dom = null;
        if (!(TOR00094_ARR === undefined || TOR00094_ARR.length === 0)) {

            dom = TOR00094_ARR.map((item, idx) => {

                return (
                    <tr key={idx}>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">{idx + 1}</th>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">氏名</th>
                        <td className="dataTable-body">
                            <div className="dataTableUnit">{item.TOR00094}</div>
                        </td>
                    </tr>
                );
            });
        }

        return (
            <React.Fragment>
                <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">買主</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                {dom}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="l-dataListMore">
                    <Link to="" aria-label="link" className="dataListMore" onClick={this.handleTo0101}>上記内容を修正したい場合は、こちらをクリックしてください</Link>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Block1);