// ローカルストレージで管理している項目の設定項目

// ブラウザのセッションタイムアウト判定用
export const SESSION_TIMEOUT_KEY = "sessionTimeout";
export const SESSION_TIMEOUT = process.env.REACT_APP_BROWSER_SESSION_TIMEOUT * 60 * 1000;
export const SESSION_TIMEOUT_SECRET = process.env.REACT_APP_BROWSER_SESSION_TIMEOUT_SECRET;

// ログアウト処理実行中フラグ
// ※説明
// Auth.signOut()でlocalStorageの認証情報を削除するが、非同期処理であるため
// localStorageの削除が実行される前にlocalStorageベースのログイン判定（loginCheckBylocalStorage()）まで処理が抜けてしまう。
// この場合、画面遷移時にログアウト状態にならないので、localStorage.logoutingでフラグ管理し、
// ログアウト処理実行中の場合、認証処理を飛ばすようにする
export const LOGGING_OUT_KEY = "loggingout";
