export const CONTRACT_EDIT_INFO = {
    /** 管理IDの生成 */
    get: (pageId) => CONTRACT_EDIT_INFO[`PAGE_${pageId}`],
    TYPE: {
        BASIC: "01",
        IMPORTANT: "02",
        ESTATE: "03",
    },
    /** 基本情報 */
    PAGE_0101: { id: "0101", page: "物件/売主/買主", title: "物件／売主／買主" },
    PAGE_0102: { id: "0102", page: "取引情報", title: "取引情報" },
    PAGE_0103: { id: "0103", page: "報酬.手数料", title: "報酬・手数料" },

    /** 重要事項説明書 */
    PAGE_0201: { id: "0201", page: "買主/売主", title: "買主／売主" },
    PAGE_0202: { id: "0202", page: "不動産の表示", title: "不動産の表示" },
    PAGE_0203: { id: "0203", page: "登記記録", title: "登記記録に記録された事項" },
    PAGE_0204: { id: "0204", page: "法令制限", title: "都市計画法、建築基準法等の法令に基づく制限" },
    PAGE_0205: { id: "0205", page: "その他法令", title: "都市計画法、建築基準法以外の法令に基づく制限" },
    PAGE_0206: { id: "0206", page: "私道負担", title: "私道負担に関する負担等に関する事項" },
    PAGE_0207: { id: "0207", page: "施設整備状況", title: "飲用水・電気・ガスの供給施設および排水施設の整備状況" },
    PAGE_0208: { id: "0208", page: "権利.管理.使用1", title: "一棟の建物・敷地に関する権利・管理・使用に関する事項_1" },
    PAGE_0209: { id: "0209", page: "権利.管理.使用2", title: "一棟の建物・敷地に関する権利・管理・使用に関する事項_2" },
    PAGE_0210: { id: "0210", page: "権利.管理.使用3", title: "一棟の建物・敷地に関する権利・管理・使用に関する事項_3" },
    PAGE_0211: { id: "0211", page: "建物調査等", title: "建物状況調査/建築及び維持保全の書類の保存/建物確認済証等" },
    PAGE_0213: { id: "0213", page: "宅地.建物事項", title: "宅地または建物に関する事項そのほか" },
    PAGE_0212: { id: "0212", page: "取引条件", title: "取引条件" },
    PAGE_0214: { id: "0214", page: "契約不適合", title: "契約不適合の履行に関する措置" },
    PAGE_0215: { id: "0215", page: "付属書類", title: "付属書類" },
    PAGE_0216: { id: "0216", page: "その他", title: "その他重要な事項" },
    PAGE_0217: { id: "0217", page: "借地説明書", title: "借地説明書" },
    PAGE_0298: { id: "0298", page: "重要事項(事前説明)", title: "重要事項（事前説明）" },
    PAGE_0299: { id: "0299", page: "反社条項", title: "反社会的勢力の排除に関する条項" },

    /** 不動産売買契約書 */
    PAGE_0301: { id: "0301", page: "不動産", title: "不動産" },
    PAGE_0302: { id: "0302", page: "売買代金等", title: "売買代金および支払い方法等" },
    PAGE_0303: { id: "0303", page: "売主.買主等", title: "売主／買主／宅地建物取引業者・宅地建物取引士" },
    PAGE_0304: { id: "0304", page: "特約", title: "特約" },
};

export const CONTRACT_EDIT_INFO_FRK = {
    /** 管理IDの生成 */
    get: (pageId) => CONTRACT_EDIT_INFO_FRK[`PAGE_${pageId}`],
    TYPE: {
        BASIC: "01",
        IMPORTANT: "02",
        ESTATE: "03",
    },
    /** 基本情報 */
    PAGE_0101: { id: "0101", page: "物件/売主/買主", title: "物件／売主／買主" },
    PAGE_0102: { id: "0102", page: "取引情報", title: "取引情報" },

    /** 重要事項説明書 */
    PAGE_0201: { id: "0201", page: "買主/売主", title: "買主／売主" },
    PAGE_0202: { id: "0202", page: "不動産の表示", title: "不動産の表示" },
    PAGE_0203: { id: "0203", page: "登記記録", title: "登記記録に記録された事項" },
    PAGE_0204: { id: "0204", page: "法令制限.道路", title: "都市計画法、建築基準法等の法令に基づく制限" },
    PAGE_0205: { id: "0205", page: "その他法令", title: "都市計画法、建築基準法以外の法令に基づく制限" },
    PAGE_0206: { id: "0206", page: "私道の負担等", title: "私道に関する負担等に関する事項" },
    PAGE_0207: { id: "0207", page: "施設整備状況", title: "飲用水・電気・ガスの供給施設および排水施設の整備状況" },
    PAGE_0208: { id: "0208", page: "敷地権利.共有.専有", title: "一棟の建物・敷地に関する権利・管理・使用に関する事項1" },
    PAGE_0209: { id: "0209", page: "専用使用権", title: "一棟の建物・敷地に関する権利・管理・使用に関する事項2" },
    PAGE_0210: { id: "0210", page: "修繕.管理.委託先", title: "一棟の建物・敷地に関する権利・管理・使用に関する事項3" },
    PAGE_0211: { id: "0211", page: "建物状況.建確", title: "建物状況調査/建築及び維持保全の書類の保存/建物確認済証等" },
    PAGE_0213: { id: "0213", page: "宅地.建物状況等", title: "宅地または建物に関する事項そのほか" },
    PAGE_0212: { id: "0212", page: "売買代金等.解除条件等", title: "取引条件" },
    PAGE_0214: { id: "0214", page: "契約不適合", title: "契約不適合責任（瑕疵担保責任）の履行に関する措置の概要" },
    PAGE_0215: { id: "0215", page: "付属書類", title: "付属書類" },
    PAGE_0216: { id: "0216", page: "その他重要な事項", title: "その他重要な事項" },
    PAGE_0217: { id: "0217", page: "借地説明書", title: "借地説明書" },
    PAGE_0218: { id: "0218", page: "備考", title: "備考" },

    /** 不動産売買契約書 */
    PAGE_0301: { id: "0301", page: "不動産", title: "不動産" },
    PAGE_0302: { id: "0302", page: "売買代金等", title: "売買代金および支払い方法等" },
    PAGE_0303: { id: "0303", page: "売主.買主等", title: "売主／買主／宅地建物取引業者・宅地建物取引士" },
    PAGE_0304: { id: "0304", page: "特約", title: "特約" },
};

/** 消費税率のマスタ */
export const CONSUMPTION_TAX_RATE = ["0", "8", "10"];

/** 編集権限 */
export const EDIT_AUTHORITY = {
    EIGYO: "01",
    CAN_EDIT: "02",
    READ_ONLY: "03",
};

/**
 * 端数処理桁数
 * 01: 小数点以下を計算, 02: 1円の桁を計算
 */
export const FRACTION_DIGIT = {
    DECIMAL_POINT: "01",
    DECIMAL: "02",
};

/**
 * 端数処理の仕方
 * 01: 切上げ, 02: 切捨て, 03: 四捨五入
 */
export const FRACTION_PROCESS = {
    CEIL: "01",
    FLOOR: "02",
    ROUND: "03",
};

/** 保証協会ポップアップ用 */
export const GUARANTEE_ASSOCIATION_POPUP = {
    HOS00001: "association_name",
    HOS00002: "association_address",
    HOS00003: "office_name",
    HOS00004: "office_address",
};

/** 支店／組織ポップアップ用 */
export const ORGANIZATION_POPUP = {
    SIT00001: "display_id",
    SIT00002: "branch_name",
    SIT00003: "address",
    SIT00004: "chief_name",
    SIT00005: "phone_number",
};

/** 定型文ポップアップ用 */
export const TEMPLATE_POPUP = {
    TEXT: "template",
};

/** 従業員ポップアップ用 */
export const EMPLOYEE_POPUP = {
    EMP00001: "user_name",
    EMP00006: "real_estate_notary_number",
};

/** 契約種別ID */
export const CONTRACT_TYPE_ID = {
    ID_0101: "0101", ID_0102: "0102", ID_0103: "0103", ID_0104: "0104", ID_0105: "0105",
    ID_0106: "0106", ID_0107: "0107", ID_0108: "0108", ID_0109: "0109", ID_0110: "0110", ID_0111: "0111",
    ID_0201: "0201", ID_0202: "0202", ID_0203: "0203", ID_0204: "0204", ID_0205: "0205",
    ID_0206: "0206", ID_0207: "0207", ID_0208: "0208", ID_0209: "0209",
    ID_0301: "0301", ID_0302: "0302", ID_0303: "0303", ID_0304: "0304", ID_0305: "0305",
    ID_0306: "0306", ID_0307: "0307", ID_0308: "0308", ID_0309: "0309", ID_0310: "0310", ID_0311: "0311",
};

/** 契約種別テーブルの入力（おうちクラベル） */
export const CONTRACT_TYPE = [
    { contract_type_id: '0101', display_type_id: '01', name: '一般／土地（実測・清算）用' },
    { contract_type_id: '0102', display_type_id: '01', name: '一般／土地（確定測量・清算）用' },
    { contract_type_id: '0103', display_type_id: '01', name: '一般／土地（公簿）用' },
    { contract_type_id: '0104', display_type_id: '02', name: '一般／土地建物（実測・清算）用' },
    { contract_type_id: '0105', display_type_id: '02', name: '一般／土地建物（確定測量・清算）用' },
    { contract_type_id: '0106', display_type_id: '02', name: '一般／土地建物（公簿）用' },
    { contract_type_id: '0107', display_type_id: '03', name: '一般／区分所有建物（敷地権）用' },
    { contract_type_id: '0108', display_type_id: '03', name: '一般／区分所有建物（非敷地権）用' },
    { contract_type_id: '0109', display_type_id: '02', name: '一般／借地権付建物用' },
    { contract_type_id: '0110', display_type_id: '02', name: '一般／定期借地権付建物用' },
    { contract_type_id: '0111', display_type_id: '03', name: '一般／定期借地権付区分所有建物用' },
    { contract_type_id: '0201', display_type_id: '01', name: '消費者契約／土地（実測・清算）用' },
    { contract_type_id: '0202', display_type_id: '01', name: '消費者契約／土地（確定測量・清算）用' },
    { contract_type_id: '0203', display_type_id: '01', name: '消費者契約／土地（公簿）用' },
    { contract_type_id: '0204', display_type_id: '02', name: '消費者契約／土地建物（実測・清算）用' },
    { contract_type_id: '0205', display_type_id: '02', name: '消費者契約／土地建物（確定測量・清算）用' },
    { contract_type_id: '0206', display_type_id: '02', name: '消費者契約／土地建物（公簿）用' },
    { contract_type_id: '0207', display_type_id: '03', name: '消費者契約／区分所有建物（敷地権）用' },
    { contract_type_id: '0208', display_type_id: '03', name: '消費者契約／区分所有建物（非敷地権）用' },
    { contract_type_id: '0209', display_type_id: '02', name: '消費者契約／借地権付建物用' },
    { contract_type_id: '0301', display_type_id: '01', name: '売主宅建業者／土地（実測・清算）用' },
    { contract_type_id: '0302', display_type_id: '01', name: '売主宅建業者／土地（確定測量・清算）用' },
    { contract_type_id: '0303', display_type_id: '01', name: '売主宅建業者／土地（公簿）用' },
    { contract_type_id: '0304', display_type_id: '02', name: '売主宅建業者／土地建物（実測・清算）用' },
    { contract_type_id: '0305', display_type_id: '02', name: '売主宅建業者／土地建物（確定測量・清算）用' },
    { contract_type_id: '0306', display_type_id: '02', name: '売主宅建業者／土地建物（公簿）用' },
    { contract_type_id: '0307', display_type_id: '03', name: '売主宅建業者／区分所有建物（敷地権）用' },
    { contract_type_id: '0308', display_type_id: '03', name: '売主宅建業者／区分所有建物（非敷地権）用' },
    { contract_type_id: '0309', display_type_id: '02', name: '売主宅建業者／土地建物・新築用' },
    { contract_type_id: '0310', display_type_id: '02', name: '売主宅建業者／借地権付建物用' },
    { contract_type_id: '0311', display_type_id: '02', name: '売主宅建業者／借地権付建物・新築用' }
];

/** 契約種別テーブルの入力（FRK） */
export const CONTRACT_TYPE_FRK = [
    { contract_type_id: '0101', display_type_id: '01', name: '一般仲介用・土地（実測・清算）' },
    { contract_type_id: '0102', display_type_id: '01', name: '一般仲介用・土地（確定測量・清算）' },
    { contract_type_id: '0103', display_type_id: '01', name: '一般仲介用・土地（売買代金固定）' },
    { contract_type_id: '0104', display_type_id: '02', name: '一般仲介用・土地建物（実測・清算）' },
    { contract_type_id: '0105', display_type_id: '02', name: '一般仲介用・土地建物（確定測量・清算）' },
    { contract_type_id: '0106', display_type_id: '02', name: '一般仲介用・土地建物（売買代金固定）' },
    { contract_type_id: '0107', display_type_id: '03', name: '一般仲介用・区分所有建物（敷地権）' },
    { contract_type_id: '0108', display_type_id: '03', name: '一般仲介用・区分所有建物（非敷地権）' },
    { contract_type_id: '0109', display_type_id: '02', name: '一般仲介用・借地権付建物' },
    { contract_type_id: '0110', display_type_id: '02', name: '一般仲介用・定期借地権付建物' },
    { contract_type_id: '0111', display_type_id: '03', name: '一般仲介用・定期借地権付区分所有建物' },
    { contract_type_id: '0201', display_type_id: '01', name: '消費者契約用・土地（実測・清算）' },
    { contract_type_id: '0202', display_type_id: '01', name: '消費者契約用・土地（確定測量・清算）' },
    { contract_type_id: '0203', display_type_id: '01', name: '消費者契約用・土地（売買代金固定）' },
    { contract_type_id: '0204', display_type_id: '02', name: '消費者契約用・土地建物（実測・清算）' },
    { contract_type_id: '0205', display_type_id: '02', name: '消費者契約用・土地建物（確定測量・清算）' },
    { contract_type_id: '0206', display_type_id: '02', name: '消費者契約用・土地建物（売買代金固定）' },
    { contract_type_id: '0207', display_type_id: '03', name: '消費者契約用・区分所有建物（敷地権）' },
    { contract_type_id: '0208', display_type_id: '03', name: '消費者契約用・区分所有建物（非敷地権）' },
    { contract_type_id: '0209', display_type_id: '02', name: '消費者契約用・借地権付建物' },
    { contract_type_id: '0301', display_type_id: '01', name: '売主宅建業者用・土地（実測・清算）' },
    { contract_type_id: '0302', display_type_id: '01', name: '売主宅建業者用・土地（確定測量・清算）' },
    { contract_type_id: '0303', display_type_id: '01', name: '売主宅建業者用・土地（売買代金固定）' },
    { contract_type_id: '0304', display_type_id: '02', name: '売主宅建業者用・土地建物（実測・清算）' },
    { contract_type_id: '0305', display_type_id: '02', name: '売主宅建業者用・土地建物（確定測量・清算）' },
    { contract_type_id: '0306', display_type_id: '02', name: '売主宅建業者用・土地建物（売買代金固定）' },
    { contract_type_id: '0307', display_type_id: '03', name: '売主宅建業者用・区分所有建物（敷地権）' },
    { contract_type_id: '0308', display_type_id: '03', name: '売主宅建業者用・区分所有建物（非敷地権）' },
    { contract_type_id: '0309', display_type_id: '02', name: '売主宅建業者用・新築・土地建物' },
    { contract_type_id: '0310', display_type_id: '02', name: '売主宅建業者用・借地権付建物' },
    { contract_type_id: '0311', display_type_id: '02', name: '売主宅建業者用・新築・借地権付建物' }
];
