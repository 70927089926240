import InputDate from "components/page/user/contractFrk/edit/input/inputDate";
import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import Select from "components/page/user/contractFrk/edit/input/inputSelect";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import React from "react";

/** 融資条件 */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010202",
        };
        this.isFirstLoad = true; // 初回ロードかどうか
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        const HYPHEN_TEXT_0102_08 = "--------";
        const HYPHEN_TEXT_0102_12 = "------------";
        const HYPHEN_TEXT_0102_20 = "--------------------";

        if (this.isFirstLoad) {
            if (this.props.pageState.TOR00031 === HYPHEN_TEXT_0102_20) {
                this.props.pageState.TOR00031 = "";
            }
            if (this.props.pageState.TOR00032 === HYPHEN_TEXT_0102_12) {
                this.props.pageState.TOR00032 = "";
            }
            if (this.props.pageState.TOR00336 === HYPHEN_TEXT_0102_08) {
                this.props.pageState.TOR00336 = "";
            }
            if (this.props.pageState.TOR00268 === HYPHEN_TEXT_0102_20) {
                this.props.pageState.TOR00268 = "";
            }
            if (this.props.pageState.TOR00269 === HYPHEN_TEXT_0102_12) {
                this.props.pageState.TOR00269 = "";
            }
            if (this.props.pageState.TOR00337 === HYPHEN_TEXT_0102_08) {
                this.props.pageState.TOR00337 = "";
            }
            if (this.props.pageState.TOR00271 === HYPHEN_TEXT_0102_20) {
                this.props.pageState.TOR00271 = "";
            }
            if (this.props.pageState.TOR00272 === HYPHEN_TEXT_0102_12) {
                this.props.pageState.TOR00272 = "";
            }
            if (this.props.pageState.TOR00338 === HYPHEN_TEXT_0102_08) {
                this.props.pageState.TOR00338 = "";
            }
            this.isFirstLoad = false;
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">融資条件</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="5">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <div className="dataTableUnit-text">ローン特約の有無</div>
                                        <div className="dataTableUnit-input">
                                            <Select id="ID_TOR00030" name="TOR00030" value={this.props.pageState.TOR00030} items={this.getSelectbox(this.props.selectboxData.MEI00001)} {...params} length="w120" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title" colSpan="2">ローン解除期日</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00033" name="TOR00033" value={this.props.pageState.TOR00033} {...params} length="w108" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" rowSpan="2">1</th>
                                <th className="dataTable-title dataTable-title__w120">金融機関名</th>
                                <td className="dataTable-body">
                                    <Input id="ID_TOR00031" name="TOR00031" value={this.props.pageState.TOR00031} {...params} length="w575" />
                                </td>
                                <th className="dataTable-title dataTable-title__w160 dataTable-title__bottomBorder">融資金額</th>
                                <td className="dataTable-body dataTable-body__border">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00336" name="TOR00336" value={this.props.pageState.TOR00336} {...params} length="w272" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">承認取得期日</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00032" name="TOR00032" value={this.props.pageState.TOR00032} {...params} length="w108" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" rowSpan="2">2</th>
                                <th className="dataTable-title dataTable-title__w120">金融機関名</th>
                                <td className="dataTable-body">
                                    <Input id="ID_TOR00268" name="TOR00268" value={this.props.pageState.TOR00268} {...params} length="w575" />
                                </td>
                                <th className="dataTable-title dataTable-title__w160 dataTable-title__bottomBorder">融資金額</th>
                                <td className="dataTable-body dataTable-body__border">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00337" name="TOR00337" value={this.props.pageState.TOR00337} {...params} length="w272" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">承認取得期日</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00269" name="TOR00269" value={this.props.pageState.TOR00269} {...params} length="w108" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" rowSpan="2">3</th>
                                <th className="dataTable-title dataTable-title__w120">金融機関名</th>
                                <td className="dataTable-body">
                                    <Input id="ID_TOR00271" name="TOR00271" value={this.props.pageState.TOR00271} {...params} length="w575" />
                                </td>
                                <th className="dataTable-title dataTable-title__w160 dataTable-title__bottomBorder">融資金額</th>
                                <td className="dataTable-body dataTable-body__border">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00338" name="TOR00338" value={this.props.pageState.TOR00338} {...params} length="w272" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">承認取得期日</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00272" name="TOR00272" value={this.props.pageState.TOR00272} {...params} length="w108" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};
