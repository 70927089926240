import React from "react";
import { Link } from "react-router-dom";
import Input from "../../input/inputNormal";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 敷地権の目的たる土地 */
export default class Block6 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010106",
        };
        this.TOR00219total = 0;
        this.TOR00219totalNew = 0;
        //初期表示の際に手動入力の合計面積を上書きしない制御用
        this.TOR00236update = 0;
    }

    /** 削除ボタンのハンドラ */
    handleDeleteTOR00217 = e => {
        this.handleDelete(e, "TOR00217_ARR");
    }

    /** 追加ボタンのハンドラ */
    handleAddTOR00217 = e => {

        const addObj = {
            TOR00217: undefined, // 所在および地番
            TOR00218: undefined, // 地目
            TOR00219: undefined, // 地積　公簿
            TOR00221: undefined, // 敷地権の種類
            TOR00315: undefined, // 敷地権の割合
        };

        this.handleAdd(e, "TOR00217_ARR", addObj);
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        // アップデートがあった時点で合計値を旧と比較し、差分がある場合は新しい方で更新する
        if (this.TOR00219total !== this.TOR00219totalNew && this.TOR00236update < 3) {
            this.TOR00219total = this.TOR00219totalNew;
            this.TOR00236update += 3;
        } else if (this.TOR00219total !== this.TOR00219totalNew && this.TOR00236update > 2) {
            this.TOR00219total = this.TOR00219totalNew;
            this.props.updatePageState({ TOR00236: this.TOR00219totalNew }, false);
        }
    }

    render() {
        const { hideBlockClass } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 動的項目の共通パラメータ
        const arrayName = "TOR00217_ARR"; // 動的項目の代表名
        const params = {
            arrayName: arrayName,
            pageState: this.props.pageState,
            updatePageState: this.props.updatePageState,
        };

        // ページが持っている動的項目の値を取得する
        const data = this.props.pageState[arrayName];

        // 動的項目は最大4件なので、4件ある場合は追加ボタンを非表示
        const hideAddButtonClass = data.length >= 4 ? "nodisplay" : "";

        let total = 0;
        data.forEach((obj) => {
            // TOR00219に数値が入っている場合、totalに足す
            if (!(isNaN(obj.TOR00219) || obj.TOR00219 === "")) {
                total += parseFloat(obj.TOR00219);
            }
        });
        this.TOR00219totalNew = total.toFixed(2); // 文字列へ変換

        // ページが持っている値分だけ動的部分のブロックを生成する
        const items = data.slice(0, 4).map((obj, idx) => {
            return (
                <React.Fragment key={idx}>
                    <tr>
                        <th className="dataTable-title dataTable-title__w177 dataTable-title__center" rowSpan="5">
                            <div className="dataTable-title-vr">{idx + 1}</div>
                            {/* 最初の項目は削除ボタンを出さない */}
                            {idx !== 0 && (
                                <div className="dataTable-title-vr">
                                    <Link to="" id={`LINK_DEL_TOR00217_ARR_${idx}`} aria-label="link" data-idx={idx} data-array-name={arrayName} className="baseAction baseAction--minus baseAction__w40" onClick={this.handleDeleteTOR00217}>
                                        <span data-idx={idx} className="baseAction-text">削除</span>
                                    </Link>
                                </div>
                            )}

                        </th>
                        <th className="dataTable-title dataTable-title__w177 dataTable-title__center">所在および<br />地番</th>
                        <td className="dataTable-body" colSpan="3">
                            <div className="dataTableUnit">
                                <Input idx={idx} id={`ID_TOR00217_${idx}`} name="TOR00217" value={obj.TOR00217} {...params} mode="dynamic" length="wFull" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w177 dataTable-title__center">地目</th>
                        <td className="dataTable-body" colSpan="3">
                            <div className="dataTableUnit">
                                <Input idx={idx} id={`ID_TOR00218_${idx}`} name="TOR00218" value={obj.TOR00218} {...params} mode="dynamic" length="w270" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w177 dataTable-title__center">地積</th>
                        <td className="dataTable-body" colSpan="3">
                            <div className="dataTableUnit">
                                <div className="dataTableUnit-text">公簿</div>
                                <Input idx={idx} id={`ID_TOR00219_${idx}`} name="TOR00219" value={obj.TOR00219} {...params} mode="dynamic" length="w112" />
                                <div className="dataTableUnit-text">m<sup>2</sup></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w177 dataTable-title__center">敷地権の種類</th>
                        <td className="dataTable-body" colSpan="3">
                            <div className="dataTableUnit">
                                <Input idx={idx} id={`ID_TOR00221_${idx}`} name="TOR00221" value={obj.TOR00221} {...params} mode="dynamic" length="w270" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w177 dataTable-title__center">敷地権の割合<br />（（準）共有部分）</th>
                        <td className="dataTable-body" colSpan="3">
                            <div className="dataTableUnit">
                                <Input idx={idx} id={`ID_TOR00315_${idx}`} name="TOR00315" value={obj.TOR00315} {...params} mode="dynamic" length="w270" />
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            );
        });

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">敷地権の目的たる土地</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            {items}
                            <tr className={hideAddButtonClass}>
                                <td className="dataTable-body dataTable-body__center" colSpan="4">
                                    <Link to="" id="LINK_ADD_TOR00217_ARR" aria-label="link" data-array-name={arrayName} className="baseAction baseAction--plus baseAction__w40" onClick={this.handleAddTOR00217}>
                                        <span className="baseAction-text">追加</span>
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTableUni-title__w120">合計</th>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00235" name="TOR00235" value={this.props.pageState.TOR00235} {...params} length="w112" />
                                        <div className="dataTableUnit-text">筆</div>
                                        <div className="dataTableUnit-text">地積（登記簿）</div>
                                        <Input id="ID_TOR00236" name="TOR00236" value={this.props.pageState.TOR00236} {...params} length="w270" />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}