import { EMPLOYEE_POPUP as EP, GUARANTEE_ASSOCIATION_POPUP as GA, ORGANIZATION_POPUP as OP } from "constants/contractEditPageInfo";
import React from "react";
import InputCheckbox from "../../input/inputCheckbox";
import InputDate from "../../input/inputDate";
import InputEmployee from "../../input/inputEmployee";
import InputGua from "../../input/inputGua";
import Input from "../../input/inputNormal";
import InputOrg from "../../input/inputOrg";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 売主宅建業者 */
export default class Block3 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020103",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">売主宅建業者</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="4">取引態様</td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="4">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00093" name="JYU00093" checked={this.props.pageState.JYU00093} {...params} />
                                                <span className="checkBox-text">売買</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00094" name="JYU00094" checked={this.props.pageState.JYU00094} {...params} />
                                                <span className="checkBox-text">交換</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="4">売主（宅地建物取引業者）</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">免許証番号</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00096" name="JYU00096" value={this.props.pageState.JYU00096} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">免許年月日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_JYU00097" name="JYU00097" value={this.props.pageState.JYU00097} {...params} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">主たる事務所<br />の所在地</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00098" name="JYU00098" value={this.props.pageState.JYU00098} {...params} length="w331" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">商号</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00100" name="JYU00100" value={this.props.pageState.JYU00100} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">代表者氏名</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00101" name="JYU00101" value={this.props.pageState.JYU00101} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">従たる事務所</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU01458" name="JYU01458" value={this.props.pageState.JYU01458} {...params} length="w331" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">部署名</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU01459" name="JYU01459" value={this.props.pageState.JYU01459} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">責任者<br />（役職・氏名）</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU01460" name="JYU01460" value={this.props.pageState.JYU01460} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="4">宅地建物取引士</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">登録番号</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputEmployee id="ID_JYU00102" name="JYU00102" value={this.props.pageState.JYU00102} {...params} from={EP.EMP00006} />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">氏名</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputEmployee id="ID_JYU00103" name="JYU00103" value={this.props.pageState.JYU00103} {...params} from={EP.EMP00001} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">業務に従事<br />する事務所名</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputOrg id="ID_JYU01461" name="JYU01461" value={this.props.pageState.JYU01461} {...params} from={OP.SIT00002} />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住所</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputOrg id="ID_JYU00104" name="JYU00104" value={this.props.pageState.JYU00104} {...params} from={OP.SIT00003} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">電話番号</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00106" name="JYU00106" value={this.props.pageState.JYU00106} {...params} length="w135" from={OP.SIT00003} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="4">供託所等に関する説明</td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="4">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00107" name="JYU00107" checked={this.props.pageState.JYU00107} {...params} />
                                        <span className="checkBox-text">（1）供託所に営業保証金を供託</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">供託所</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00111" name="JYU00111" value={this.props.pageState.JYU00111} {...params} from={GA.HOS00001} />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在地</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU01463" name="JYU01463" value={this.props.pageState.JYU01463} {...params} from={GA.HOS00002} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="4">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00108" name="JYU00108" checked={this.props.pageState.JYU00108} {...params} />
                                        <span className="checkBox-text">（2）宅地建物取引業保証協会の社員</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">保証協会<br />の名称</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00112" name="JYU00112" value={this.props.pageState.JYU00112} {...params} from={GA.HOS00001} />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">保証協会<br />の所在地</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU01464" name="JYU01464" value={this.props.pageState.JYU01464} {...params} from={GA.HOS00002} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">事務所<br />の名称</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00113" name="JYU00113" value={this.props.pageState.JYU00113} {...params} from={GA.HOS00003} />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">事務所<br />の所在地</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU01465" name="JYU01465" value={this.props.pageState.JYU01465} {...params} from={GA.HOS00004} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240 dataTable-title__center">弁済業務保証金<br />の供託所名称</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00115" name="JYU00115" value={this.props.pageState.JYU00115} {...params} from={GA.HOS00001} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240 dataTable-title__center">弁済業務保証金<br />の供託所所在地</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU01466" name="JYU01466" value={this.props.pageState.JYU01466} {...params} from={GA.HOS00002} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}