import React from "react";
import { Link } from "react-router-dom";
import Validation from "util/validate";
import { CONTRACT_EDIT_INFO } from "../../../../../../../constants/contractEditPageInfo";
import { SEND_OTHER_PAPER } from "../../../../../../../constants/messages";
import InputCheckbox from "../../input/inputCheckbox";
import InputDate from "../../input/inputDate";
import Input from "../../input/inputNormal";
import Select from "../../input/inputSelect";
import InputTextRemark from "../../input/inputTextRemark";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 建物 */
export default class Block4 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020204",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    handleTo0101 = e => {
        e.preventDefault();
        // リロードをかける
        this.props.pageSwitch(CONTRACT_EDIT_INFO.PAGE_0101.id);
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        //JYU00322の入力上限判定
        const JYU00322LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00322_${this.props.displayTypeID}`, this.props.pageState.JYU00322);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <React.Fragment>
                <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">建物</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住居表示</th>
                                    <td className="dataTable-body" colSpan="3">{this.props.pageState.TOR00258}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在</th>
                                    <td className="dataTable-body" colSpan="3">{this.props.pageState.TOR00259}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">家屋番号</th>
                                    <td className="dataTable-body dataTable-body__w355">{this.props.pageState.TOR00260}</td>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">種類</th>
                                    <td className="dataTable-body dataTable-body__w355">{this.props.pageState.TOR00261}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">構造</th>
                                    <td className="dataTable-body" colSpan="3">{this.props.pageState.TOR00262}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="3">床面積</th>
                                    <td className="dataTable-body" colSpan="3">
                                        <ul className="l-checkBox">
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_JYU00306" name="JYU00306" checked={this.props.pageState.JYU00306} {...params} />
                                                    <span className="checkBox-text">1.登記簿</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_JYU00307" name="JYU00307" checked={this.props.pageState.JYU00307} {...params} />
                                                    <span className="checkBox-text">2.現況</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dataTable-body" colSpan="3">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-text">1階</div>
                                            <Input id="ID_JYU00308" name="JYU00308" value={this.props.pageState.JYU00308} {...params} />
                                            <div className="dataTableUnit-text">m<sup>2</sup></div>

                                            <div className="dataTableUnit-text">2階</div>
                                            <Input id="ID_JYU00309" name="JYU00309" value={this.props.pageState.JYU00309} {...params} />
                                            <div className="dataTableUnit-text">m<sup>2</sup></div>

                                            <Input id="ID_JYU00310" name="JYU00310" value={this.props.pageState.JYU00310} {...params} length="w270" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dataTable-body" colSpan="3">
                                        <div className="dataTableUnit">
                                            <Input id="ID_JYU00311" name="JYU00311" value={this.props.pageState.JYU00311} {...params} length="w575" />
                                            <div className="dataTableUnit-text">合計</div>
                                            <Input id="ID_JYU00312" name="JYU00312" value={this.props.pageState.JYU00312} {...params} length="w113" />
                                            <div className="dataTableUnit-text">m<sup>2</sup></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__center">建築時期</th>
                                    <td className="dataTable-body" colSpan="3">
                                        <div className="dataTableUnit">
                                            <InputDate id="ID_JYU00313" name="JYU00313" value={this.props.pageState.JYU00313} {...params} length="w112" />
                                            <Select id="ID_JYU00316" name="JYU00316" value={this.props.pageState.JYU00316} items={this.getSelectbox(this.props.selectboxData.MEI00002)} {...params} length="w120" />
                                            <div className="dataTableUnit-text">（</div>
                                            <div className="dataTableUnit-input">
                                                <ul className="l-checkBox">
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_JYU00317" name="JYU00317" checked={this.props.pageState.JYU00317} {...params} />
                                                            <span className="checkBox-text">増築</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_JYU00318" name="JYU00318" checked={this.props.pageState.JYU00318} {...params} />
                                                            <span className="checkBox-text">改築</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <Input id="ID_JYU00319" name="JYU00319" value={this.props.pageState.JYU00319} {...params} />
                                            <div className="dataTableUnit-text">）</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                    <td className="dataTable-body" colSpan="4">
                                        <div className="dataTableTextUnit">
                                            <InputTextRemark id="ID_JYU00322" name="JYU00322" value={this.props.pageState.JYU00322} text={SEND_OTHER_PAPER.JYU00322} placeholder={JYU00322LimitErrorMessage.inputLimitGuide} {...params} />
                                            <p className="LengthOver-error">{JYU00322LimitErrorMessage.inputLimitErrorMessage}</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="l-dataListMore">
                    <Link to="" aria-label="link" className="dataListMore" onClick={this.handleTo0101}>上記内容を修正したい場合は、こちらをクリックしてください</Link>
                </div>
            </React.Fragment>
        );
    }
}