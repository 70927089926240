import React from "react";
import Validation from "util/validate";
import { CONTRACT_EDIT_INFO_FRK } from "../../../../../../../constants/contractEditPageInfo";
import { SEND_OTHER_PAPER } from "../../../../../../../constants/messages";
import InputCheckbox from "../../input/inputCheckbox";
import Input from "../../input/inputNormal";
import InputTextRemark from "../../input/inputTextRemark";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 売主の表示と占有に関する事項 */
export default class Block5 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020205",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    handleTo0101 = e => {
        e.preventDefault();
        // リロードをかける
        this.props.pageSwitch(CONTRACT_EDIT_INFO_FRK.PAGE_0101.id);
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 文字数制限定義
        const JYU00331LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00331",
                this.props.pageState.JYU00331);
        const JYU00327LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00327",
                this.props.pageState.JYU00327);

        // 斜線対応の設定
        let DomDiagonalLine = undefined;
        if (this.onloadFlg && this.props.pageState.JYU01328) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_JYU01328");
        }

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <React.Fragment>
                <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU01328:AR_JYU01328-1,AR_JYU01328-2,AR_JYU01328-3")}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">売主の表示と占有に関する事項</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <td className="dataTable-body is-emphasis" colSpan="4">
                                        <div className="dataTableUnit dataTableUnit--center">
                                            <div className="dataTableUnit-input"><span className="checkBox-text">売主</span></div>
                                            <div className="dataTableUnit-text">（</div>
                                            <div className="dataTableUnit-input">
                                                <ul className="l-checkBox">
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_JYU00323" name="JYU00323" checked={this.props.pageState.JYU00323} {...params} />
                                                            <span className="checkBox-text">1.登記簿記載の所有者と同じ</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_JYU00324" name="JYU00324" checked={this.props.pageState.JYU00324} {...params} />
                                                            <span className="checkBox-text">2.登記簿記載の所有者と異なる</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="dataTableUnit-text">）</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__center">住所</th>
                                    <td className="dataTable-body">
                                        <div className="dataTableUnit">
                                            <Input id="ID_JYU00325" name="JYU00325" value={this.props.pageState.JYU00325} {...params} length="wFull" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__center">氏名</th>
                                    <td className="dataTable-body">
                                        <div className="dataTableUnit">
                                            <Input id="ID_JYU00326" name="JYU00326" value={this.props.pageState.JYU00326} {...params} length="wFull" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                    <td className="dataTable-body">
                                        <div className="dataTableTextUnit">
                                            <InputTextRemark id="ID_JYU00327" name="JYU00327" value={this.props.pageState.JYU00327} text={SEND_OTHER_PAPER.JYU00327} placeholder={JYU00327LimitMsg.inputLimitGuide} {...params} />
                                            <p className="LengthOver-error">{JYU00327LimitMsg.inputLimitMsg}</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title is-emphasis" colSpan="3">
                                        <div className="dataTableUnit dataTableUnit--center">
                                            <div className="dataTableUnit-input"><span className="checkBox-text">売買契約締結時の占有に関する事項　第三者による占有</span></div>
                                            <div className="dataTableUnit-text">（</div>
                                            <div className="dataTableUnit-input">
                                                <ul className="l-checkBox">
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_JYU00328" name="JYU00328" checked={this.props.pageState.JYU00328} {...params} />
                                                            <span className="checkBox-text">有</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox id="ID_JYU01328" name="JYU01328" checked={this.props.pageState.JYU01328} {...params} />
                                                            <span className="checkBox-text">無　※チェックした場合は、斜線を引きます。</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="dataTableUnit-text">）</div>
                                        </div>
                                    </th>
                                </tr>
                                {DomDiagonalLine}
                                <tr id="AR_JYU01328-1">
                                    <th className="dataTable-title dataTable-title__center">住所</th>
                                    <td className="dataTable-body">
                                        <div className="dataTableUnit">
                                            <Input id="ID_JYU00329" name="JYU00329" value={this.props.pageState.JYU00329} {...params} length="wFull" />
                                        </div>
                                    </td>
                                </tr>
                                <tr id="AR_JYU01328-2">
                                    <th className="dataTable-title dataTable-title__center">氏名</th>
                                    <td className="dataTable-body">
                                        <div className="dataTableUnit">
                                            <Input id="ID_JYU00330" name="JYU00330" value={this.props.pageState.JYU00330} {...params} length="wFull" />
                                        </div>
                                    </td>
                                </tr>
                                <tr id="AR_JYU01328-3">
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">占有に<br />関する<br />権利</th>
                                    <td className="dataTable-body">
                                        <div className="dataTableTextUnit">
                                            <InputTextRemark id="ID_JYU00331" name="JYU00331" value={this.props.pageState.JYU00331} text={SEND_OTHER_PAPER.JYU00331} placeholder={JYU00331LimitMsg.inputLimitGuide} {...params} />
                                            <p className="LengthOver-error">{JYU00331LimitMsg.inputLimitMsg}</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}