import { PageBase } from "components/page/user/contract/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";
import Block4 from "./block4";
import Block5 from "./block5";

class ImpPage2 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     */
    getPageItems = () => {
        return {
            // 区分所有建物
            // 一棟の建物の表示
            TOR00205: undefined, // 建物の名称
            TOR00206: undefined, // 住居表示
            TOR00207: undefined, // 所在
            TOR00208: undefined, // 構造
            TOR00209: undefined, // 延床面積
            // 専有部分の建物の表示
            TOR00210: undefined, // 家屋番号
            TOR00211: undefined, // 建物の名称
            TOR00212: undefined, // 種類
            TOR00213: undefined, // 構造
            TOR00214: undefined, // 床面積 階部分
            TOR00215: undefined, // 床面積 登記簿
            TOR00216: undefined, // 床面積 壁芯
            JYU00140: undefined, // 区分所有建物 建築時期
            JYU00141: undefined, // 区分所有建物 建築時期 新築
            JYU00142: false, // 区分所有建物 付属建物 有
            JYU01327: false, // 区分所有建物 付属建物 無
            JYU00143: undefined, // 区分所有建物 付属建物 内容
            JYU00147: undefined, // 区分所有建物 備考

            // 敷地権の目的たる土地
            TOR00217: undefined, // 所在および地番
            TOR00218: undefined, // 地目
            TOR00219: undefined, // 地積 公簿
            TOR00220: undefined, // 地積 実測
            TOR00221: undefined, // 敷地権の種類
            TOR00222: undefined, // 持分
            TOR00235: undefined, // 合筆数
            TOR00236: undefined, // 登記簿合計面積
            JYU00194: false, // 規約敷地の定め 有
            JYU01455: false, // 規約敷地の定め 無
            JYU00195: undefined, // 規約敷地の定め 詳細
            JYU00196: undefined, // 備考

            // 土地
            TOR00237: undefined, // 所在
            TOR00238: undefined, // 地番
            TOR00240: undefined, // 地目
            TOR00241: undefined, // 地積 公簿
            TOR00243: undefined, // 持分
            TOR00256: undefined, // 合筆数
            TOR00257: undefined, // 登記簿合計面積
            JYU00264: undefined, // 登記簿（公簿）面積による
            JYU00265: undefined, // 実測面積による
            JYU00266: undefined, // 測量によって得られた面積による
            JYU00251: undefined, // 実測合計面積（㎡）
            JYU00267: false, // 測量図 チェック
            JYU00269: false, // 日付 チェック
            JYU00270: undefined, // 日付 内容
            JYU00271: undefined, // 作成者
            JYU00268: false, // 確定測量図 チェック
            JYU00272: false, // 残代金支払日までに買主に交付 チェック
            JYU00273: false, // 作製・交付予定なし チェック
            JYU00291: false, // 所有権
            JYU00292: false, // 借地権
            JYU00293: false, // 借地権 地上権
            JYU00294: false, // 借地権 賃貸権
            JYU00295: undefined, // 借地権 借地対象面積
            JYU00296: undefined, // 土地 備考

            // 建物
            TOR00258: undefined, // 住居表示
            TOR00259: undefined, // 所在
            TOR00260: undefined, // 家屋番号
            TOR00261: undefined, // 種類
            TOR00262: undefined, // 構造
            JYU00306: false, // 登記簿 チェック
            JYU00307: false, // 現況 チェック
            JYU00308: undefined, // １階（㎡）
            JYU00309: undefined, // ２階（㎡）
            JYU00310: undefined, // ３階（㎡）
            JYU00311: undefined, // その他
            JYU00312: undefined, // 合計（㎡）
            JYU00313: undefined, // 建物 建築時期
            JYU00316: undefined, // 建物 建築時期 新築
            JYU00317: false, // 増築 チェック
            JYU00318: false, // 改築 チェック
            JYU00319: undefined, // 増築・改築日付
            JYU00322: undefined, // 建物 備考

            // 売主の表示と専有に関する事項
            JYU00323: false, // 登記簿記載の所有者 同じ
            JYU00324: false, // 登記簿記載の所有者 異なる
            JYU00325: undefined, // 住所
            JYU00326: undefined, // 氏名
            JYU00327: undefined, // 備考
            JYU00328: false, // 第三者による占有 有
            JYU01328: false, // 第三者による占有 無
            JYU00329: undefined, // 住所
            JYU00330: undefined, // 氏名
            JYU00331: undefined, // 占有に関する権利
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [
            "TOR00205", "TOR00206", "TOR00207", "TOR00208", "TOR00209", "TOR00210", "TOR00211", "TOR00212", "TOR00213", "TOR00214", "TOR00215", "TOR00216", // 区分所有建物
            "TOR00217", "TOR00218", "TOR00219", "TOR00220", "TOR00221", "TOR00222", "TOR00235", "TOR00236", // 敷地権の目的たる土地
            "TOR00237", "TOR00238", "TOR00240", "TOR00241", "TOR00243", "TOR00256", "TOR00257", // 土地
            "TOR00258", "TOR00259", "TOR00260", "TOR00261", "TOR00262", // 建物
        ];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 区分所有建物 */}
                    <li><Block2 {...params} /></li> {/** 敷地権の目的たる土地 */}
                    <li><Block3 {...params} /></li> {/** 土地 */}
                    <li><Block4 {...params} /></li> {/** 建物 */}
                    <li><Block5 {...params} /></li> {/** 売主の表示と専有に関する事項 */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
            </React.Fragment>

        );
    }
}

export default withRouter(ImpPage2);