import { PageBase } from "components/page/user/contractFrk/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";
import Block4 from "./block4";
import Block5 from "./block5";

class ImpPage2 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     */
    getPageItems = () => {
        return {
            // 区分所有建物
            // 一棟の建物の表示
            TOR00205: undefined, // 建物の名称
            TOR00300: undefined, // 建物の名称　棟
            TOR00301: undefined, // 建物の名称　階
            TOR00302: undefined, // 建物の名称　号室
            TOR00356: undefined, // 建物の名称　フリー
            TOR00206: undefined, // 住居表示
            TOR00207: undefined, // 所在
            TOR00208: undefined, // 構造
            TOR00209: undefined, // 延床面積
            // 専有部分の建物の表示
            TOR00210: undefined, // 家屋番号
            TOR00211: undefined, // 建物の名称
            TOR00212: undefined, // 種類
            TOR00213: undefined, // 構造
            TOR00214: undefined, // 床面積 階部分
            TOR00215: undefined, // 床面積 登記簿
            TOR00216: undefined, // 床面積 壁芯
            TOR00306: undefined, // 区分所有建物　建築時期　元号
            TOR00307: undefined, // 区分所有建物　建築時期　年
            TOR00308: undefined, // 区分所有建物　建築時期　月
            TOR00309: undefined, // 区分所有建物　建築時期　新築
            TOR00310: undefined, // 区分所有建物　建築時期　フリー
            TOR00311: false, // 区分所有建物　付属建物　有
            TOR00312: false, // 区分所有建物　付属建物　無
            TOR00314: undefined, // 区分所有建物　付属建物　フリー
            JYU00147: undefined, // 区分所有建物 備考

            // 敷地権の目的たる土地
            TOR00217: undefined, // 所在および地番
            TOR00218: undefined, // 地目
            TOR00219: undefined, // 地積 公簿
            TOR00221: undefined, // 敷地権の種類
            TOR00315: undefined, // 敷地権の割合
            TOR00235: undefined, // 合筆数
            TOR00236: undefined, // 登記簿合計面積
            JYU00194: false, // 規約敷地の定め 有
            JYU01455: false, // 規約敷地の定め 無
            JYU01577: undefined, // 規約敷地の定め　フリー
            JYU00195: undefined, // 規約敷地の定め 詳細
            JYU00196: undefined, // 備考

            // 土地
            TOR00237: undefined, // 所在
            TOR00238: undefined, // 地番
            TOR00240: undefined, // 地目
            TOR00241: undefined, // 地積 公簿
            TOR00243: undefined, // 持分
            TOR00256: undefined, // 合筆数
            TOR00257: undefined, // 登記簿合計面積
            JYU00264: undefined, // 登記簿（公簿）面積による
            JYU00265: undefined, // 実測面積による
            JYU01578: undefined, // 土地の売買対象面積　チェック
            JYU01579: undefined, // 土地の売買対象面積　内容
            JYU00251: undefined, // 実測面積（㎡）
            JYU01788: undefined, // 実測面積　フリー
            JYU00267: false, // 測量図面　測量図 チェック
            JYU00269: false, // 測量図面　日付 チェック
            JYU00268: false, // 測量図面　確定測量図 チェック
            JYU00272: false, // 測量図面　残代金支払日までに買主に交付 チェック
            JYU00273: false, // 測量図面　作製・交付予定なし チェック
            JYU01749: undefined, // 測量図面　日付　年
            JYU01582: undefined, // 測量図面　日付　月
            JYU01583: undefined, // 測量図面　日付　日
            JYU00271: undefined, // 測量図面　作成者
            JYU00291: false, // 権利の種類　所有権
            JYU00292: false, // 権利の種類　借地権
            JYU00293: false, // 権利の種類　借地権 地上権
            JYU00294: false, // 権利の種類　借地権 賃貸権
            JYU01789: undefined, // 権利の種類　フリー
            JYU00295: undefined, // 借地権 借地対象面積
            JYU00296: undefined, // 土地 備考

            // 建物
            TOR00258: undefined, // 住居表示
            TOR00259: undefined, // 所在
            TOR00260: undefined, // 家屋番号
            TOR00261: undefined, // 種類
            TOR00262: undefined, // 構造
            TOR00316: false, // 登記簿　チェック
            TOR00317: undefined, // １階（㎡）
            TOR00318: undefined, // ２階（㎡）
            TOR00319: undefined, // 床面積　フリー
            TOR00320: false, // 現況　チェック
            TOR00321: undefined, // その他
            TOR00322: undefined, // 合計（㎡）
            TOR00323: undefined, // 建物　建築時期　元号
            TOR00324: undefined, // 建物　建築時期　年
            TOR00325: undefined, // 建物　建築時期　月
            TOR00326: undefined, // 建物　建築時期　新築
            TOR00327: false, // 増築　チェック
            TOR00328: false, // 改築　チェック
            TOR00329: undefined, // 建物　増築・改築　元号
            TOR00330: undefined, // 建物　増築・改築　年
            TOR00331: undefined, // 建物　増築・改築　月
            JYU00322: undefined, // 建物 備考

            // 売主の表示と専有に関する事項
            JYU00323: false, // 登記簿記載の所有者 同じ
            JYU00324: false, // 登記簿記載の所有者 異なる
            JYU00325: undefined, // 住所
            JYU00326: undefined, // 氏名
            JYU00327: undefined, // 備考
            JYU00328: false, // 第三者による占有 有
            JYU01328: false, // 第三者による占有 無
            JYU00329: undefined, // 住所
            JYU00330: undefined, // 氏名
            JYU00331: undefined, // 占有に関する権利
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [
            // 区分所有建物
            "TOR00205", "TOR00300", "TOR00301", "TOR00302", "TOR00206", "TOR00207", "TOR00208",
            "TOR00209", "TOR00210", "TOR00211", "TOR00212", "TOR00213", "TOR00214", "TOR00215",
            "TOR00216", "TOR00306", "TOR00307", "TOR00308", "TOR00309", "TOR00310", "TOR00311",
            "TOR00312", "TOR00314", "TOR00356",

            // 敷地権の目的たる土地
            "TOR00217", "TOR00218", "TOR00219", "TOR00221", "TOR00315", "TOR00235",
            "TOR00236",

            // 土地
            "TOR00237", "TOR00238", "TOR00240", "TOR00241", "TOR00243", "TOR00256", "TOR00257",

            // 建物
            "TOR00258", "TOR00259", "TOR00260", "TOR00261", "TOR00262", "TOR00316", "TOR00317",
            "TOR00318", "TOR00319", "TOR00320", "TOR00321", "TOR00322", "TOR00323", "TOR00324",
            "TOR00325", "TOR00326", "TOR00327", "TOR00328", "TOR00329", "TOR00330", "TOR00331",
        ];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            contractTypeId: this.props.contractTypeId,  // 契約種別ID
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        // 敷地権の目的たる土地 または 土地の表示／非表示設定
        let blockItem = (<li><Block3 {...params} /></li>); // 土地

        if (this.props.contractType.indexOf("（敷地権）") !== -1 || this.props.contractType.indexOf("定期借地権付区分所有建物") !== -1) {
            blockItem = (<li><Block2 {...params} /></li>); // 敷地権の目的たる土地
        }

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 区分所有建物 */}
                    {blockItem} {/** 敷地権の目的たる土地 or 土地 */}
                    <li><Block4 {...params} /></li> {/** 建物 */}
                    <li><Block5 {...params} /></li> {/** 売主の表示と専有に関する事項 */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
            </React.Fragment>

        );
    }
}

export default withRouter(ImpPage2);