import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import Input from "components/page/user/contract/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 共有部分に関する規約等の定め */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020802",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        //JYU00813の入力上限判定
        const JYU00813LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00813_${this.props.displayTypeID}`, this.props.pageState.JYU00813);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">
                        <div className="dataTableUnit dataTableUnit--center">
                            <div className="dataTableUnit-input"><span className="checkBox-text">共用部分に関する規約等の定め</span></div>
                            <div className="dataTableUnit-text">（</div>
                            <div className="dataTableUnit-input">
                                <ul className="l-checkBox">
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU00805" name="JYU00805" checked={this.props.pageState.JYU00805} {...params} />
                                            <span className="checkBox-text">有</span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU01401" name="JYU01401" checked={this.props.pageState.JYU01401} {...params} />
                                            <span className="checkBox-text">無</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div className="dataTableUnit-text">）</div>
                        </div>
                    </div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">共用部分<br />の範囲</th>
                                <td className="dataTable-body">共用の玄関・廊下・階段・外壁・建物躯体・配線（主線）・配管（主管）等<br />別添「管理規約別表第 <Input noBlock id="ID_JYU00807" name="JYU00807" value={this.props.pageState.JYU00807} {...params} length="w80" /> 」に掲げる通りです。以下余白</td>
                                <td className="dataTable-body dataTable-body__w221">
                                    <Input id="ID_JYU00808" name="JYU00808" value={this.props.pageState.JYU00808} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">共用部分の<br />持分の割合</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00809" name="JYU00809" checked={this.props.pageState.JYU00809} {...params} />
                                                <span className="checkBox-text">1.専有部分の床面積の割合による</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00810" name="JYU00810" checked={this.props.pageState.JYU00810} {...params} />
                                                <span className="checkBox-text">2.下記の通り</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body dataTable-body__w221">
                                    <Input id="ID_JYU00812" name="JYU00812" value={this.props.pageState.JYU00812} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00813" name="JYU00813" value={this.props.pageState.JYU00813} text={SEND_OTHER_PAPER.JYU00813} placeholder={JYU00813LimitErrorMessage.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00813LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}