import { Footer } from "components/common/parts/footerUnauth";
import { FormLinkRecord } from "components/common/parts/formRecord";
import * as pageInfo from "constants/pageInfo";
import React from "react";

/** ログアウト画面 */
export default function Logout(props) {
    document.title = pageInfo.LOGOUT.title;
    return (
        <main>
            <div className="l-loginField">
                <div className="l-loginField l-left_area"></div>
                <div className="l-loginField l-right_area">
                    <div className="l-loginField-component">
                        <form>
                            <div className="loginField loginField--main">
                                <div className="loginField-read">ログアウトしました。</div>
                                <FormLinkRecord isCenter to={pageInfo.LOGIN.path}>ログイン画面へ戻る</FormLinkRecord>
                            </div>
                        </form >
                    </div>
                    <Footer />
                </div>
            </div>
        </main >
    );
}
