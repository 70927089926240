import * as AWS from "constants/aws";
import { FORMAT } from "constants/format";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as loginUserAction from "redux/actions/loginUserAction";
import * as sidemenuAction from "redux/actions/sidemenuAction";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";

/** サイドメニュー */
class SideMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (!this.props.agencyUserId) {
            this.requestUserInfo();
        }
    }

    /** 契約情報ナビメニューの活性状態更新 */
    handleChangeContractNaviPullDownActive = e => {
        if (this.props.contractPullDown) {
            this.props.closeContractPullDown();
        } else {
            this.props.openContractPullDown();
        }
    };

    /** システム管理者ナビメニューの活性状態更新 */
    handleChangeSystemManageNaviPullDownActive = e => {
        if (this.props.systemManagePullDown) {
            this.props.closeSystemManagePullDown();
        } else {
            this.props.openSystemManagePullDown();
        }
    };

    /** 取得APIを呼出し、stateを更新する */
    requestUserInfo = () => {
        awsReq.get(
            AWS.ENDPOINT.PATHS.LOGIN_USER,
            response => {
                const camelResponseData = convert.convertToFlatCamelCase(response.data);
                this.props.saveUserName(camelResponseData.userName);
                this.props.saveAgencyUserId(camelResponseData.agencyUserId);
                this.props.saveAgencyId(camelResponseData.agencyId);
                this.props.saveAgencyBranchId(camelResponseData.agencyBranchId);
                this.props.saveAuthority(camelResponseData.authority);
                this.props.saveUseFormat(camelResponseData.useFormat);
            },
            awsReq.defaultErrorHandler,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    render() {
        // 契約情報ナビメニュー
        const contractNaviList = [
            pageInfo.CONTRACT_LIST,
            pageInfo.CONTRACT_REGISTER,
        ];

        // 契約情報ナビメニュー(FRK書式)
        const contractNaviListFrk = [
            pageInfo.CONTRACT_LIST_FRK,
            pageInfo.CONTRACT_REGISTER_FRK,
        ];

        const defaultInputValueMaintenance = pageInfo.DEFAULT_INPUT_VALUE_MAINTENANCE_UPDATE;
        defaultInputValueMaintenance.path = defaultInputValueMaintenance.path.replace(":displayType", "01");

        // システム管理者ナビメニュー
        const systemManageNaviList = [
            pageInfo.MY_COMPANY_INFO_MAINTENANCE,
            pageInfo.BRANCH_AND_ORGANIZATION_INFO_LIST,
            pageInfo.EMPLOYEE_INFO_LIST,
            pageInfo.GUARANTEE_INFO_LIST,
            pageInfo.INPUT_ITEM_LIST,
            defaultInputValueMaintenance,
            pageInfo.TEMPLATE_LIST,
            pageInfo.CLAUSE_AND_DOCUMENT_MAINTENANCE
        ];

        // システム管理者ナビメニュー(FRK書式)
        const systemManageNaviListFrk = [
            pageInfo.MY_COMPANY_INFO_MAINTENANCE,
            pageInfo.BRANCH_AND_ORGANIZATION_INFO_LIST,
            pageInfo.EMPLOYEE_INFO_LIST_FRK,
            pageInfo.GUARANTEE_INFO_LIST,
            defaultInputValueMaintenance,
            pageInfo.TEMPLATE_LIST,
            pageInfo.CLAUSE_AND_DOCUMENT_MAINTENANCE_FRK
        ];

        return (
            <div className="nav">
                <div className="nav-body">
                    <div className="nav-header"></div>
                    <ul className="l-navAction">
                        <li>
                            <Navi to={pageInfo.DASHBOARD.path} naviLinkClass="navAction navAction--home" naviTextClass="navAction-text">{pageInfo.DASHBOARD.menu}</Navi>
                        </li>
                        <li>
                            <NaviPullDown
                                header="契約書管理"
                                naviList={this.props.useFormat === FORMAT.FRK.value ? contractNaviListFrk : contractNaviList}
                                onClick={this.handleChangeContractNaviPullDownActive}
                                active={this.props.contractPullDown}
                                headerClass="navAction navAction--contract"
                            />
                        </li>
                        {this.props.admin && (
                            <li>
                                <NaviPullDown
                                    header="システム管理"
                                    naviList={this.props.useFormat === FORMAT.FRK.value ? systemManageNaviListFrk : systemManageNaviList}
                                    onClick={this.handleChangeSystemManageNaviPullDownActive}
                                    active={this.props.systemManagePullDown}
                                    headerClass="navAction navAction--system"
                                />
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        login: state.loginStatus,
        admin: state.admin,
        contractPullDown: state.contractPullDown,
        systemManagePullDown: state.systemManagePullDown,
        useFormat: state.useFormat
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(sidemenuAction, dispatch),
        ...bindActionCreators(loginUserAction, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideMenu));

/** プルダウンナビメニュー */
function NaviPullDown(props) {
    const naviList = props.naviList;
    const naviListDom = naviList.map((navi, index) => (
        <li key={index}>
            <Navi to={navi.path} naviLinkClass="navPullDownAction" naviTextClass="div-pre">
                {navi.menu}
            </Navi>
        </li>
    ));

    // プルダウンの制御
    const headerClass = props.active ? props.headerClass + " is-active" : props.headerClass;
    const NaviPullDownClass = props.active ? "navPullDown is-active" : "navPullDown";

    return (
        <div className={NaviPullDownClass}>
            <div onClick={props.onClick} className="navPullDown-header">
                <div className={headerClass}>
                    <span className="navAction-text">{props.header}</span>
                </div>
            </div>
            <div className="navPullDown-body">
                <ul className="l-navPullDownAction">{naviListDom}</ul>
            </div>
        </div>
    );
}

/** ナビメニュー */
function Navi(props) {
    return (
        <Link to={props.to} className={props.naviLinkClass}>
            <span className={props.naviTextClass}>{props.children}</span>
        </Link>
    );
}
