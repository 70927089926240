import { eraMaster } from "components/page/user/contract/edit/contractEdit";
import { eraMaster as eraMasterFrk } from "components/page/user/contractFrk/edit/contractEdit";
import { FORMAT } from "constants/format";

/** now（String）から、元号 + 曜日の文字列を生成 */
export const genEraText = (dateString, format) => {

    // 日付でない場合、undefinedを返す
    if (!isValidDate(dateString)) {
        return { dayOfWeek: undefined, era: undefined };
    }

    const now = new Date(dateString);
    const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"];

    return { dayOfWeek: dayOfWeek[now.getDay()], era: convToEraStrict(now, format) };
};

/** FRK書式用のgenEraText */
export const genEraTextFrk = (dateString) => {
    return genEraText(dateString, FORMAT.FRK.value);
};

/**
 * 西暦を"元号 + xx年（例：令和02年）"へ変換する
 * ※日付まで見る厳密版
 * date は Date型
*/
export const convToEraStrict = (date, format) => {

    const generate = (era, startYear) => {
        let yearDsp = date.getFullYear() - startYear + 1;
        if (yearDsp === 1) {
            yearDsp = "元"; // 元年表示用
        } else {
            yearDsp = ('00' + yearDsp).slice(-2); // 元年以外
        }
        return `${era}${yearDsp}年`;
    };

    // 初期値を大正にとりあえずしておく
    let era = '大正'; // 表示する元号
    let start = new Date('1912/07/30'); // 元号の開始日

    // どちらから呼び出した元号マスタを使用するか判別する
    let discriminatedEraMaster = format === FORMAT.FRK.value ? eraMasterFrk : eraMaster
    discriminatedEraMaster.forEach(item => {
        const startOn = new Date(item.start_on);
        const endOn = new Date(item.end_on);

        if (startOn <= date && endOn >= date) {
            era = item.era_name;
            start = startOn;
        }
    });

    return generate(era, start.getFullYear());
};

/**
 * 西暦を"元号 + xx年（例：令和02年）"へ変換する
 * ※
 * カレンダー内での表示用なので、年しかみない
 * カレンダーの年プルダウンで元年表示させたいため、元号判定はその年の12/31で行う
 * 例：2019を12/31で判定すると令和元年、01/01で判定すると平成31年になる
*/
export const convToEra = (yearNow, format) => {
    return convToEraStrict(new Date(yearNow + "/12/31"), format);
};

/** FRK書式用のconvToEra */
export const convToEraFrk = (yearNow) => {
    return convToEra(yearNow, FORMAT.FRK.value);
};

/**
 * 文字列が日付かどうかの簡易判定
 * true: 日付である、false: 日付ではない
 *
 * ※2020/2/30等が通ってしまうのであくまで"簡易"
 */
export const isValidDate = str => {

    if (str === undefined) {
        return false;
    }

    // Date型できている時は必ず日付なので true
    if (str instanceof Date) {
        return true;
    }

    // 正規表現ベースでチェック
    if (str.match(/^\d{4}\/\d{1,2}\/\d{1,2}$/) === null) {
        return false;
    }

    // new Date()が可能かどうかでチェック
    if (new Date(str).toString() === "Invalid Date") {
        return false;
    }

    return true;
};

/** タイムゾーン付きの現在日時文字列を日本日付（yyyy/mm/dd）に変換する */
export const tzStrToDate = str => {
    if (str === undefined || str == null) {
        return "";
    }

    const date = new Date(str);

    if (date.toString() === "Invalid Date") {
        return "Invalid Date";
    }

    return date.toLocaleDateString("ja-JP", { timeZone: "Asia/Tokyo" });
};

/** タイムゾーン付きの現在日時文字列を日本日時（yyyy/mm/dd hh24:mi:ss）に変換する */
export const tzStrToDateTime = str => {
    if (str === undefined || str == null) {
        return "";
    }

    const date = new Date(str);

    if (date.toString() === "Invalid Date") {
        return "Invalid Date";
    }

    return date.toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });
};

/** CSVファイル名に使用する現在日時(YYYYMMDDHH24MISS)を取得する */
export const tzStrToCsvName = e => {
    const now = new Date();
    var csvTitleTime = "" + now.getFullYear() + padZero(now.getMonth() + 1) + padZero(now.getDate()) + padZero(now.getHours()) +
        padZero(now.getMinutes()) + padZero(now.getSeconds());
    function padZero(num) {
        return (num < 10 ? "0" : "") + num;
    }
    return csvTitleTime;
};

/** parmに使用する現在日時(YYYYMMDDHH24MISS)を取得する */
export const tzStrToParm = () => {
    const now = new Date();
    var parmDateTime = ""
        + now.getFullYear()
        + padZero(now.getMonth() + 1)
        + padZero(now.getDate())
        + padZero(now.getHours())
        + padZero(now.getMinutes())
        + padZero(now.getSeconds())
        + padZero(now.getMilliseconds());

    parmDateTime = encodeURIComponent(parmDateTime)

    function padZero(num) {
        return (num < 10 ? "0" : "") + num;
    }

    return parmDateTime;
};

/** 日付のセレクトボックスの選択肢を作成 */
export const genDayList = () => {
    let dayList = [];
    for (let i = 1; i <= 31; i++) {
        dayList.push(String(i));
    }
    return { item_name: "day", items: dayList };
}

/** 月のセレクトボックスの選択肢を作成 */
export const genMonthList = () => {
    let monthList = [];
    for (let i = 1; i <= 12; i++) {
        monthList.push(String(i));
    }
    return { item_name: "month", items: monthList };
}

/** 年のセレクトボックスの選択肢を作成 */
export const genJapaneseImperialYearList = () => {
    let japaneseImperialYearList = ["元"];
    for (let i = 2; i <= 64; i++) {
        japaneseImperialYearList.push(String(i));
    }
    return { item_name: "japaneseImperialYear", items: japaneseImperialYearList };
}

// 元号リストの生成
export const genEraNameList = () => {
    let eraNameList = eraMasterFrk.map(item => item.era_name);
    return { item_name: "EraName", items: eraNameList };

}