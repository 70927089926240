import { PageBase } from "components/page/user/contractFrk/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";
import Block4 from "./block4";
import Block5 from "./block5";

class ImpPage11 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     * ※チェックボックスの場合、falseにする
     */
    getPageItems = () => {
        return {

            // 宅地造成または建物建築の工事完了時における形状・構造等
            JYU01771: false, // 別紙にて説明します。
            JYU01772: false, // 該当しません。

            // 建物状況調査の結果の概要
            JYU00980: false, // 該当する
            JYU01282: false, // 該当しない
            JYU00981: false, // 建物状況調査の実施の有無　有
            JYU01283: false, // 建物状況調査の実施の有無　無
            JYU00982: undefined, // 建物状況調査の結果の概要

            // 建物の建築及び維持保全の状況に関する書類の保存の状況
            JYU00987: false, // 該当する
            JYU01284: false, // 該当しない
            JYU01667: false, // 確認の申請書および添付図書（新築時のもの）　有
            JYU01668: false, // 0確認の申請書および添付図書（新築時のもの）　無
            JYU01669: false, // 確認済証（新築時のもの）　有
            JYU01670: false, // 確認済証（新築時のもの）　無
            JYU01757: false, // 検査済証（新築時のもの）　有
            JYU01758: false, // 検査済証（新築時のもの）　無
            JYU01671: false, // 増改築等を行った建物に　該当する
            JYU01672: false, // 増改築等を行った建物に　該当しない
            JYU01673: false, // 増改築等を行った建物に　該当するか否か不明
            JYU01674: false, // 確認の申請書および添付図書（増改築時のもの）　有
            JYU01675: false, // 確認の申請書および添付図書（増改築時のもの）　無
            JYU01676: false, // 確認済証（増改築時のもの）　有
            JYU01677: false, // 確認済証（増改築時のもの）　無
            JYU01678: false, // 検査済証（増改築時のもの）　有
            JYU01679: false, // 検査済証（増改築時のもの）　無
            JYU01680: false, // 建物状況調査を実施した住宅に　該当する
            JYU01681: false, // 建物状況調査を実施した住宅に　該当しない
            JYU01682: false, // 建物状況調査を実施した住宅に　該当するか否か不明
            JYU01683: false, // 建物状況調査報告書（1年以内のものに限らない）　有
            JYU01684: false, // 建物状況調査報告書（1年以内のものに限らない）　無
            JYU01685: false, // 建築基準法第12条の規定による定期調査報告の対象である住宅に　該当する
            JYU01686: false, // 建築基準法第12条の規定による定期調査報告の対象である住宅に　該当しない
            JYU01687: false, // 建築基準法第12条の規定による定期調査報告の対象である住宅に　該当するか否か不明
            JYU01688: false, // 定期調査報告書（特定建築物）・定期検査報告書（昇降機等）　有
            JYU01689: false, // 定期調査報告書（特定建築物）・定期検査報告書（昇降機等）　無
            JYU01690: false, // 既存住宅性能評価（建築）を受けた住宅に　該当する
            JYU01691: false, // 既存住宅性能評価（建築）を受けた住宅に　該当しない
            JYU01692: false, // 既存住宅性能評価（建築）を受けた住宅に　該当するか否か不明
            JYU01693: false, // 既存住宅性能評価書（現状検査・評価書）　有
            JYU01694: false, // 既存住宅性能評価書（現状検査・評価書）　無
            JYU01695: false, // 昭和56年5月31日以前に新築の工事に着手した既存の建物に　該当する
            JYU01696: false, // 昭和56年5月31日以前に新築の工事に着手した既存の建物に　該当しない
            JYU01697: false, // 既存住宅性能評価書（現状検査・評価書）　記載中、1-1.耐震等級の区分　1以上（適）
            JYU01698: false, // 既存住宅性能評価書（現状検査・評価書）　記載中、1-1.耐震等級の区分　0（不適）
            JYU01699: false, // 耐震診断結果報告書　有
            JYU01700: false, // 耐震診断結果報告書　無
            JYU01701: false, // 結果報告書の記載中、新耐震基準の適合性を証する記載　有
            JYU01702: false, // 結果報告書の記載中、新耐震基準の適合性を証する記載　無
            JYU01703: false, // 既存住宅売買瑕疵保険の付保証明書　有
            JYU01704: false, // 既存住宅売買瑕疵保険の付保証明書　無
            JYU01705: false, // 耐震基準適合証明書　有
            JYU01706: false, // 耐震基準適合証明書　無
            JYU01707: undefined, //	その他　名称
            JYU01708: false, // その他　有
            JYU01709: false, // その他　無
            JYU01013: undefined, // 備考

            // 建築確認認証等の発行年月日・番号
            JYU01014: false, // 建築確認（新築時）　有
            JYU01751: undefined, //建築確認（新築時）　年
            JYU01712: undefined, //建築確認（新築時）　月
            JYU01752: undefined, //建築確認（新築時）　日
            JYU01016: undefined, // 建築確認（新築時）　条例番号
            JYU01017: false, // 検査済証　有
            JYU01753: undefined, //検査済証　年
            JYU01715: undefined, //検査済証　月
            JYU01754: undefined, //検査済証　日
            JYU01019: undefined, // 検査済証　条例番号
            JYU01020: undefined, // 備考

            // 性能住宅評価を受けた新築住宅の場合
            JYU01468: false, // 該当する
            JYU01469: false, // 該当しない
            JYU01470: false, // 設計住宅性能評価書　有
            JYU01471: false, // 設計住宅性能評価書　無
            JYU01472: false, // 建設住宅性能評価書　有
            JYU01473: false, // 建設住宅性能評価書　無
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            contractTypeId: this.props.contractTypeId,  // 契約種別ID
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block5 {...params} /></li> {/** 宅地造成または建物建築の工事完了時における形状・構造等（対象不動産が未完成物件または新規物件のとき） */}
                    <li><Block1 {...params} /></li> {/** 建物状況調査の結果の概要 */}
                    <li><Block2 {...params} /></li> {/** 建物の建築及び維持保全の状況に関する書類の保存の状況 */}
                    <li><Block3 {...params} /></li> {/** 建築確認認証等の発行年月日・番号 */}
                    <li><Block4 {...params} /></li> {/** 住宅性能評価を受けた新築住宅の場合 */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
            </React.Fragment>
        );
    }
}

export default withRouter(ImpPage11);