import { PageBase } from "components/page/user/contract/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import * as AWS from "../../../../../../../constants/aws";
import * as awsReq from "../../../../../../../util/awsRequest";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";
import Block4 from "./block4";

class EstPage3 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     * ※チェックボックスの場合、falseにする
     */
    getPageItems = () => {
        return {
            //代理人の署名による買主／売主の印刷
            FUD00372: undefined, // 37条書面への印刷
            TOR00094_ARR: [{
                TOR00094: undefined, // 買主 氏名
            },],
            FUD00348_ARR: [{
                FUD00348: undefined, // 買主 氏名 印刷
            },],
            TOR00072_ARR: [{
                TOR00072: undefined, // 売主 氏名
            },],
            FUD00345_ARR: [{
                FUD00345: undefined, // 売主 氏名 印刷
            },],

            //売主宅建業者
            //売主（宅地建物取引業者）
            JYU00096: undefined, // 免許証番号
            JYU00098: undefined, // 主たる事務所の所在地
            JYU00100: undefined, // 商号
            JYU00101: undefined, // 代表者氏名

            //宅地建物取引士
            JYU00102: undefined, // 登録番号
            JYU00103: undefined, // 氏名

            //宅地建物取引業者・宅地建物取引士（左）
            JYU00009: undefined, // 免許証番号
            JYU00010: undefined, // 免許年月日
            JYU00011: undefined, // 主たる事務所の所在地
            JYU00013: undefined, // 商号
            JYU00014: undefined, // 代表者氏名
            JYU00017: undefined, // 従たる事務所 部署名
            JYU00019: undefined, // 従たる事務所 責任者
            JYU00020: undefined, // 登録番号
            JYU00021: undefined, // 氏名

            //宅地建物取引業者・宅地建物取引士（右）
            JYU00052: undefined, // 免許証番号
            JYU00053: undefined, // 免許年月日
            JYU00054: undefined, // 主たる事務所の所在地
            JYU00056: undefined, // 商号
            JYU00057: undefined, // 代表者氏名
            JYU00060: undefined, // 従たる事務所 部署名
            JYU00062: undefined, // 従たる事務所 責任者
            JYU00063: undefined, // 登録番号
            JYU00064: undefined, // 氏名
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return ["FUD00348_ARR", "FUD00345_ARR"];
    }

    /** 動的に項目数が変動する項目のキー名を設定する ※参照のみの場合は不要 */
    getRefOnlyItemKeys = () => {
        return [
            //代理人の署名による買主／売主の印刷
            "TOR00094_ARR", "TOR00072_ARR",

            //売主宅建業者
            //売主（宅地建物取引業者）
            "JYU00096", "JYU00098", "JYU00100", "JYU00101",
            //宅地建物取引士
            "JYU00102", "JYU00103",

            //宅地建物取引業者・宅地建物取引士（左）
            "JYU00009", "JYU00010", "JYU00011", "JYU00013", "JYU00014", "JYU00017",
            "JYU00019", "JYU00020", "JYU00021",

            //宅地建物取引業者・宅地建物取引士（右）
            "JYU00052", "JYU00053", "JYU00054", "JYU00056", "JYU00057", "JYU00060",
            "JYU00062", "JYU00063", "JYU00064",
        ];
    }

    /**
     * 初期情報取得
     * ※ハンドラの処理が特有なので個別で設定
     */
    init = () => {

        if (!this.state.apicalling) {
            this.setState({ apicalling: true });
            awsReq.get(
                AWS.ENDPOINT.PATHS.CONTRACTS_PAGE
                    .replace(":contractId", this.props.match.params.contractId)
                    .replace(":pageId", this.props.match.params.pageId),
                this.initialRequestHandlerSp,
                awsReq.defaultErrorHandler,
                {}, {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }

    initialRequestHandlerSp = res => {

        // 以降、レスポンスをページコンポーネントのstateとして管理するため、setStateするための前処理
        // ※このページ特化
        let responseBody = res.data.input_content;

        // レスポンスボディにTOR00094_ARR, FUD00348_ARRがない場合、追加する
        let data = Object.assign({}, responseBody);
        if (data.TOR00094_ARR === undefined) { data.TOR00094_ARR = [{}]; }
        if (data.FUD00348_ARR === undefined) { data.FUD00348_ARR = [{}]; }

        // TOR00094_ARR（買主の氏名のリスト）分だけFUD00348_ARR（無、氏名／住所を印刷する）がない場合、差分を追加する
        let diff = data.TOR00094_ARR.length - data.FUD00348_ARR.length;
        for (let i = 0; i < diff; i++) {
            data.FUD00348_ARR.push({ FUD00348: this.props.selectboxData.MEI00009.items[0] }); //名称マスタの1レコード目を追加する
        }

        // レスポンスボディにTOR00072_ARR, FUD00345_ARRがない場合、追加する
        if (data.TOR00072_ARR === undefined) { data.TOR00072_ARR = [{}]; }
        if (data.FUD00345_ARR === undefined) { data.FUD00345_ARR = [{}]; }

        // TOR00072_ARR分（売主の氏名のリスト）だけFUD00345_ARR（無、氏名／住所を印刷する）がない場合、差分を追加する
        if (data.TOR00072_ARR !== undefined) {
            diff = data.TOR00072_ARR.length - data.FUD00345_ARR.length;
            for (let i = 0; i < diff; i++) {
                data.FUD00345_ARR.push({ FUD00345: this.props.selectboxData.MEI00009.items[0] }); //名称マスタの1レコード目を追加する
            }
        }

        res.data.input_content = data;

        // 以降、レスポンスをページコンポーネントのstateとして管理するため、setStateするための前処理
        // ※全ページ共通用
        this.initialRequestHandler(res);
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 代理人の署名による買主／売主の印刷 */}
                    <li><Block2 {...params} /></li> {/** 売主宅建業者 */}
                    <li><Block3 {...params} /></li> {/** 宅地建物取引業者・宅地建物取引士（左） */}
                    <li><Block4 {...params} /></li> {/** 宅地建物取引業者・宅地建物取引士（右） */}
                </ul>
            </React.Fragment>
        );
    }
}

export default withRouter(EstPage3);