import { PageBase } from "components/page/user/contract/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router-dom";
import PopupTmpleteList from "../../popup/popupTmpleteList";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";

class ImpPage8 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     * ※チェックボックスの場合、falseにする
     */
    getPageItems = () => {
        return {
            // 一棟の建物またはその敷地に関する権利およびこれらの管理・使用に関する事項
            // 敷地に関する権利の種類および内容
            JYU00790: undefined,  // 実測面積
            JYU00791: undefined,  // 登記簿面積
            JYU00792: undefined,  // 建築確認の対象面積
            JYU00793: false,  // 所有権
            JYU00794: false,  // 借地権
            JYU00795: false,  // 借地権 地上権
            JYU00796: false,  // 借地権 抵当権
            JYU00797: undefined,  // 対象面積
            JYU00798: false,  // 対象面積 登記簿
            JYU00799: false,  // 対象面積 実測
            JYU00800: undefined,  // 借地権の存続期間 日付（年月日）
            JYU00801: undefined,  // 地代
            JYU00804: undefined,  // 備考

            // 共有部分に関する規約等の定め
            JYU00805: false,  // 共用部分に関する規約等の定め 有
            JYU01401: false,  // 共用部分に関する規約等の定め 無
            JYU00807: undefined,  // 管理規約 項１
            JYU00808: undefined,  // 管理規約 条１
            JYU00809: false,  // 共有部分の持分の割合 床面積の割合
            JYU00810: false,  // 共有部分の持分の割合 その他
            JYU00812: undefined,  // 管理規約 条２
            JYU00813: undefined,  // 共有部分の持分の割合 その他 内容

            // 専用部分の用途その他の制限に関する規約等の定め
            JYU00814: false,  // 用途制限 有
            JYU01402: false,  // 用途制限 無
            JYU00816: undefined,  // 用途制限 内容
            JYU00817: undefined,  // 用途制限 管理規約１
            JYU01403: undefined,  // 用途制限 管理規約２
            JYU01404: undefined,  // 用途制限 管理規約３
            JYU00818: undefined,  // 用途制限 使用細則１
            JYU01405: undefined,  // 用途制限 使用細則２
            JYU01406: undefined,  // 用途制限 使用細則３
            JYU00819: false,  // ペットの飼育制限 有
            JYU01407: false,  // ペットの飼育制限 無
            JYU00820: undefined,  // ペットの飼育制限 内容
            JYU00821: undefined,  // ペットの飼育制限 管理規約１
            JYU01408: undefined,  // ペットの飼育制限 管理規約２
            JYU01409: undefined,  // ペットの飼育制限 管理規約３
            JYU00822: undefined,  // ペットの飼育制限 使用細則１
            JYU01410: undefined,  // ペットの飼育制限 使用細則２
            JYU01411: undefined,  // ペットの飼育制限 使用細則３
            JYU00823: false,  // フローリングの制限 有
            JYU01413: false,  // フローリングの制限 無
            JYU00824: undefined,  // フローリングの制限 内容
            JYU00825: undefined,  // フローリングの制限 管理規約１
            JYU01414: undefined,  // フローリングの制限 管理規約２
            JYU01415: undefined,  // フローリングの制限 管理規約３
            JYU00826: undefined,  // フローリングの制限 使用細則１
            JYU01416: undefined,  // フローリングの制限 使用細則２
            JYU01417: undefined,  // フローリングの制限 使用細則３
            JYU00827: false,  // 楽器の使用制限 有
            JYU01418: false,  // 楽器の使用制限 無
            JYU00828: undefined,  // 楽器の使用制限 内容
            JYU00829: undefined,  // 楽器の使用制限 管理規約１
            JYU01419: undefined,  // 楽器の使用制限 管理規約２
            JYU01420: undefined,  // 楽器の使用制限 管理規約３
            JYU00830: undefined,  // 楽器の使用規則 使用細則１
            JYU01421: undefined,  // 楽器の使用規則 使用細則２
            JYU01422: undefined,  // 楽器の使用規則 使用細則３
            JYU00833: undefined,  // 備考
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
            contractType: this.props.contractType, //契約種別名
            displayTypeID: this.props.displayTypeID, //表示区分ID
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block1 {...params} /></li> {/** 一棟の建物またはその敷地に関する権利およびこれらの管理・使用に関する事項 */}
                    <li><Block2 {...params} /></li> {/** 共有部分に関する規約等の定め */}
                    <li><Block3 {...params} /></li> {/** 専用部分の用途その他の制限に関する規約等の定め */}
                </ul>
                <PopupTmpleteList {...params} to={this.state.popupTarget} from={this.state.popupFrom} popupFlagName="dispPopupTmpl" />
            </React.Fragment>
        );
    }
}

export default withRouter(ImpPage8);