import BaseGrid from "components/common/frame/baseGrid";
import PaginationPage from "components/common/frame/paginationPage";
import { OutputRecord } from "components/common/parts/outputRecord";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";

/** 支店／組織情報一覧画面 */
class BranchandOrganizationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1, // 現ページ
            totalRecords: 0, // 総件数
        };
        this.apicalling = 0; // APIコール中のページを保持（0はコール無し。連続API防止措置なので簡易）
    }

    componentDidMount() {
        document.title = pageInfo.BRANCH_AND_ORGANIZATION_INFO_LIST.title;
        this.requestBranchAndOrganization();
    }

    componentDidUpdate() {
        if (!this.state.agencyBranches) {
            this.requestBranchAndOrganization();
        }
    }

    handlePageNumber = page => {
        this.setState({ page: page });
        this.setState({ agencyBranches: undefined });
    }

    render() {
        // 支店／組織情報一覧Dom
        const agencyBranchesList = this.state.agencyBranches;
        const agencyBranchesListDom = agencyBranchesList
            ? agencyBranchesList.map(agencyBranches => {
                const outputList = [
                    agencyBranches.displayId,
                    agencyBranches.branchName,
                    () => (<Link to={pageInfo.BRANCH_AND_ORGANIZATION_INFO_MAINTENANCE_UPDATE.path.replace(":id", agencyBranches.id)}
                        aria-label="link" className="baseAction">詳細</Link>)
                ];
                return (<OutputRecord outputList={outputList} key={agencyBranches.id} />);
            })
            : null;

        // 新規作成ボタンを有効にするかどうか
        const createBtnActive = this.state.branchContractQuantity === 0 ? false : true;

        return (
            <BaseGrid>
                <div className="l-fieldText">
                    <div className="fieldText fieldText--right">
                        <span>有効支店・組織登録数 {this.state.totalRecords}　（ご契約による登録可能数 {this.state.branchContractQuantity}）</span>
                    </div>
                </div>

                <PaginationPage activePage={this.state.page} totalItemsCount={this.state.totalRecords} onChange={this.handlePageNumber}
                    createButtonInActive={!createBtnActive}
                    toCreate={pageInfo.BRANCH_AND_ORGANIZATION_INFO_MAINTENANCE.path}>

                    <div className="dataList dataList__accordion is-open">
                        <div className="dataList-header"><div className="dataList-header-title">支店／組織情報一覧</div></div>
                        <div className="dataList-body">
                            <table className="dataTable">
                                <tbody>
                                    <tr>
                                        <th className="dataTable-title is-emphasis dataTable-title__w120">支店／組織ID</th>
                                        <th className="dataTable-title is-emphasis">名称</th>
                                        <th className="dataTable-title is-emphasis dataTable-title__w110"></th>
                                    </tr>

                                    {agencyBranchesListDom || null}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </PaginationPage>
            </BaseGrid>
        );
    }

    /** 一覧取得APIを呼出し、その結果をstateに格納する */
    requestBranchAndOrganization = () => {
        if (this.apicalling !== this.state.page) {
            this.apicalling = this.state.page;

            awsReq.post(
                AWS.ENDPOINT.PATHS.BRANCHAND_ORGANIZATION_LIST,
                response => {
                    this.apicalling = 0;
                    // 現在表示しているページとページ番号が同じレスポンスだった場合に画面へ反映させる
                    // ※古いリクエストが返ってきても無視する
                    if (this.state.page === response.data.page_number) {
                        this.setState(convert.convertToFlatCamelCase(response.data));
                    }
                },
                error => {
                    this.apicalling = 0;
                    this.setState({ agencyBranches: [] });
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                },
                { page: this.state.page },
                {},
                AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }
}

export default withRouter(BranchandOrganizationList);
