import { LinkToOtherDomain } from "components/common/parts/link";
import React from "react";

/** フッター（未認証） */
export const Footer = (props) => {

    return (
        <footer className="l-footer">
            <div className="footer">
                <div className="footer-unauth-copyright">Copyright SRE Holdings Corporation.</div>
                <div className="footer-unauth-policy">
                    <LinkToOtherDomain to="https://sre-group.co.jp/terms/privacy.html" className="footer-unauth-policy__action" target="_blank">
                        <span className="footer-unauth-policy__text">プライバシーポリシー</span>
                    </LinkToOtherDomain>
                </div>
            </div>
        </footer>
    );
};