import React from "react";
import { Link } from "react-router-dom";

/**
 * 登録ボタン、削除ボタンのレコード
 * props.registerSetting: 登録ボタンの属性情報
 * props.deleteSetting: 削除ボタンの属性情報
 * props.inActive: ボタンのアクティブステータス　非アクティブの場合は付ける
 */
export function RegisterAndDeleteButtonRecord(props) {
    let className = "baseAction";
    if (props.inActive) className += "  baseAction--inactive";

    return (
        <ul className="l-baseAction l-baseAction__rightTwo l-baseAction__doubleSpace">
            <li><Link to="" aria-label="link" className={className} {...props.deleteSetting}>削除</Link></li>
            <li><Link to="" aria-label="link" className={className} {...props.registerSetting}>登録</Link></li>
        </ul>
    );
}

/**
 * 登録ボタンのレコード
 * props.registerSetting: 登録ボタンの属性情報
 * props.inActive: ボタンのアクティブステータス　非アクティブの場合は付ける
 */
export function RegisterButtonRecord(props) {
    let className = "baseAction";
    if (props.inActive) className += "  baseAction--inactive";

    return (
        <ul className="l-baseAction l-baseAction__rightTwo l-baseAction__doubleSpace">
            <li>
                <Link to="" aria-label="link" className={className} {...props.registerSetting}>登録</Link>
            </li>
        </ul>
    );
}

export function Button(props) {
    let className = "baseAction";
    let to = props.to;
    let onClick = props.onClick;
    if (props.isActiveClass) className += " is-active";
    if (props.emphasisClass) className += " baseAction--emphasis";
    if (props.twoLinesClass) className += " baseAction--twoLines";

    // 非アクティブ制御
    if (props.inActive) {
        className += " baseAction--inactive";
        onClick = e => { e.preventDefault(); };
        to = "";
    }

    return (
        <Link to={to || ""} aria-label="link" className={className} onClick={onClick || null}>
            {props.children}
        </Link>
    );
}
