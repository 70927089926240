import { Footer } from "components/common/parts/footerUnauth";
import { FormLinkRecord } from "components/common/parts/formRecord";
import * as pageInfo from "constants/pageInfo";
import React from "react";

/** タイムアウト通知画面 */
export default function Timeout(props) {
    return (
        <main>
            <div className="l-loginField">
                <div className="l-loginField l-left_area"></div>
                <div className="l-loginField l-right_area">
                    <div className="l-loginField-component">
                        <form>
                            <div className="loginField loginField--main">
                                <div className="loginField-read">
                                    URLの有効時間が超過しました。<br />
                                    パスワードの変更が必要な場合は、改めて再発行手続きを行ってください。
                                </div>
                                <FormLinkRecord isCenter to={pageInfo.LOGIN.path}>ログイン画面へ戻る</FormLinkRecord>
                            </div>
                        </form>
                    </div>
                    <Footer />
                </div>
            </div>
        </main>
    );
}
