import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import Select from "components/page/user/contractFrk/edit/input/inputSelect";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import { DISPLAY_TYPES } from "constants/defaultInputValueTypes";
import { MESSAGES, SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import * as dateUtil from "util/dateUtil";
import Validation from "util/validate";
import InputTextRemark from "../../input/inputTextRemark";

/** 都市計画法に基づく制限 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020401",
        };
        this.reader = new FileReader(); // アップロード対象ファイル読込用のReader
        this.uploadFileName = undefined;
        // アップロードするファイル名// 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
        this.isFirstLoad = true; // 初回ロードかどうか
        this.isSameJYU01646 = this.props.pageState.JYU01646;
        this.isSameJYU01647 = this.props.pageState.JYU01647;
        this.isSameJYU01648 = this.props.pageState.JYU01648;
        this.isSameJYU01649 = this.props.pageState.JYU01649;
    }

    /** ファイルアップロードの選択がされた際のハンドラ */
    handleUploadMap = e => {
        const target = e.target.files[0];

        // APIコール失敗→ファイル選択→キャンセル押下でundefinedがくるのでその対応
        if (target === undefined) { return; }

        this.uploadFileName = target.name;
        this.reader.readAsArrayBuffer(target);

        // ファイルの読み込みが完了した時に発火されるイベント用のリスナーを登録
        const event = e => {
            this.reader.removeEventListener('load', event);
            this.props.updatePageState({ upload: { pageId: "0204", itemId: "JYU00595", data: this.reader.result, name: this.uploadFileName } });
        };
        this.reader.addEventListener('load', event);
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 文字数制限定義
        const JYU00412LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00412",
                this.props.pageState.JYU00412);
        const JYU00513LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00513",
                this.props.pageState.JYU00513);
        const JYU00558LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00558",
                this.props.pageState.JYU00558);
        const JYU00587LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00587",
                this.props.pageState.JYU00587);


        // 空欄の場合帳票にハイフンを印刷するための処理
        const HYPHEN_TEXT_0204 = "------";
        // プレースホルダーの設定
        const PLACE_HOLDE_TEXT = "未入力の場合、「" + HYPHEN_TEXT_0204 + "」を印字します。";

        if (this.isFirstLoad) {
            if (this.props.pageState.JYU00396 === HYPHEN_TEXT_0204) {
                this.props.pageState.JYU00396 = "";
            }
            if (this.props.pageState.JYU00398 === HYPHEN_TEXT_0204) {
                this.props.pageState.JYU00398 = "";
            }
            if (this.props.pageState.JYU00400 === HYPHEN_TEXT_0204) {
                this.props.pageState.JYU00400 = "";
            }
            if (this.props.pageState.JYU00402 === HYPHEN_TEXT_0204) {
                this.props.pageState.JYU00402 = "";
            }

            this.isFirstLoad = false;
        }

        // 非活性の設定
        let JYU01646_disabled = this.props.pageState.JYU01646;
        let JYU01647_disabled = this.props.pageState.JYU01647;
        let JYU01648_disabled = this.props.pageState.JYU01648;
        let JYU01649_disabled = this.props.pageState.JYU01649;

        // ハイフン挿入の設定
        const HYPHEN_TEXT = "--";
        if (!this.isSameJYU01646 && this.props.pageState.JYU01646) {
            this.isSameJYU01646 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00536", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00537", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00538", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00539", HYPHEN_TEXT));
        }

        if (this.isSameJYU01646 && !this.props.pageState.JYU01646) {
            this.isSameJYU01646 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00536", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00537", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00538", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00539", ""));
        }

        if (!this.isSameJYU01647 && this.props.pageState.JYU01647) {
            this.isSameJYU01647 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00542", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00543", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00544", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00545", HYPHEN_TEXT));
        }

        if (this.isSameJYU01647 && !this.props.pageState.JYU01647) {
            this.isSameJYU01647 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00542", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00543", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00544", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00545", ""));
        }

        if (!this.isSameJYU01648 && this.props.pageState.JYU01648) {
            this.isSameJYU01648 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00548", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00549", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00550", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00551", HYPHEN_TEXT));
        }

        if (this.isSameJYU01648 && !this.props.pageState.JYU01648) {
            this.isSameJYU01648 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00548", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00549", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00550", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00551", ""));
        }

        if (!this.isSameJYU01649 && this.props.pageState.JYU01649) {
            this.isSameJYU01649 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00554", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00555", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00556", HYPHEN_TEXT));
            this.props.updatePageState(this.genUpdateInformation("JYU00557", HYPHEN_TEXT));
        }

        if (this.isSameJYU01649 && !this.props.pageState.JYU01649) {
            this.isSameJYU01649 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00554", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00555", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00556", ""));
            this.props.updatePageState(this.genUpdateInformation("JYU00557", ""));
        }

        const uploadedMessage = this.props.pageState.JYU00595
            ? MESSAGES.CONTRACT_EDIT.MAPFILE_UPLOADED.replace(`:message:_${this.props.displayTypeID}`, this.props.pageState.JYU00595)
            : null;

        // 概略図の非活性設定
        let JYU00955_disabled = undefined;
        if (this.props.displayTypeID === DISPLAY_TYPES.SEGMENT_BUILDING.value || this.props.contractType.indexOf("定期借地権付区分所有建物") !== -1) {
            JYU00955_disabled = true;
        }

        // 前面道路を区分所有建物だったら一行追加する
        let frontRoad = undefined;
        if (this.props.displayTypeID === DISPLAY_TYPES.SEGMENT_BUILDING.value) {
            frontRoad = (<tr>
                <td className="dataTable-body dataTable-body__center is-rightborder">4</td>
                <td className="dataTable-body">
                    <ul className="l-checkBox">
                        <li>
                            <label className="checkBox">
                                <InputCheckbox id="ID_JYU00552" name="JYU00552" checked={this.props.pageState.JYU00552} {...params} />
                                <span className="checkBox-text">公道</span>
                            </label>
                        </li>
                        <li>
                            <label className="checkBox">
                                <InputCheckbox id="ID_JYU00553" name="JYU00553" checked={this.props.pageState.JYU00553} {...params} />
                                <span className="checkBox-text">私道</span>
                            </label>
                        </li>
                        <li>
                            <label className="checkBox">
                                <InputCheckbox id="ID_JYU01649" name="JYU01649" checked={this.props.pageState.JYU01649} {...params} />
                                <span className="checkBox-text">無</span>
                            </label>
                        </li>
                    </ul>
                </td>
                <td className="dataTable-body">
                    <div className="dataTableUnit dataTableUnit--center">
                        <Input id="ID_JYU00554" name="JYU00554" value={this.props.pageState.JYU00554} {...params} length="w80" disabled={JYU01649_disabled} />
                        <div className="dataTableUnit-text">番</div>
                    </div>
                </td>
                <td className="dataTable-body">
                    <div className="dataTableUnit dataTableUnit--center">
                        <Input id="ID_JYU00555" name="JYU00555" value={this.props.pageState.JYU00555} {...params} length="w80" disabled={JYU01649_disabled} />
                        <div className="dataTableUnit-text">側</div>
                    </div>
                </td>
                <td className="dataTable-body">
                    <div className="dataTableUnit dataTableUnit--center">
                        <Input id="ID_JYU00556" name="JYU00556" value={this.props.pageState.JYU00556} {...params} length="w80" disabled={JYU01649_disabled} />
                        <div className="dataTableUnit-text">ｍ</div>
                    </div>
                </td>
                <td className="dataTable-body">
                    <div className="dataTableUnit dataTableUnit--center">
                        <Input id="ID_JYU00557" name="JYU00557" value={this.props.pageState.JYU00557} {...params} length="w80" disabled={JYU01649_disabled} />
                        <div className="dataTableUnit-text">ｍ</div>
                    </div>
                </td>
            </tr>);
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">都市計画法に基づく制限（制限の内容等については、補足資料に記載されています。）</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">①区域区分</th>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00388" name="JYU00388" checked={this.props.pageState.JYU00388} {...params} />
                                                        <span className="checkBox-text">1.都市計画区域</span>
                                                    </label>
                                                </div>
                                                <div className="dataTableUnit-text">（</div>
                                                <div className="dataTableUnit-input">
                                                    <ul className="l-checkBox">
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU00389" name="JYU00389" checked={this.props.pageState.JYU00389} {...params} />
                                                                <span className="checkBox-text">1.市街化区域</span>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU00390" name="JYU00390" checked={this.props.pageState.JYU00390} {...params} />
                                                                <span className="checkBox-text">2.市街化調整区域</span>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU00391" name="JYU00391" checked={this.props.pageState.JYU00391} {...params} />
                                                                <span className="checkBox-text">3.区域区分のされていない区域</span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="dataTableUnit-text">）</div>
                                            </div>
                                        </div>
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00392" name="JYU00392" checked={this.props.pageState.JYU00392} {...params} />
                                                        <span className="checkBox-text">2.都市計画区域外</span>
                                                    </label>
                                                </div>
                                                <div className="dataTableUnit-text">（</div>
                                                <div className="dataTableUnit-text">準都市計画区域の指定：</div>
                                                <div className="dataTableUnit-input">
                                                    <ul className="l-checkBox">
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU00393" name="JYU00393" checked={this.props.pageState.JYU00393} {...params} />
                                                                <span className="checkBox-text">有</span>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU01329" name="JYU01329" checked={this.props.pageState.JYU01329} {...params} />
                                                                <span className="checkBox-text">無</span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="dataTableUnit-text">）</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120" rowSpan="4">②市街化調整区域の場合開発行為・旧住宅地造成事業法の許可等</th>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">既存宅地番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Select id="ID_JYU01588" name="JYU01588" value={this.props.pageState.JYU01588} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} />
                                        <Select id="ID_JYU01589" name="JYU01589" value={this.props.pageState.JYU01589} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} />
                                        <div className="dataTableUnit-text">年</div>
                                        <Select id="ID_JYU01590" name="JYU01590" value={this.props.pageState.JYU01590} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} />
                                        <div className="dataTableUnit-text">月</div>
                                        <Select id="ID_JYU01591" name="JYU01591" value={this.props.pageState.JYU01591} items={this.getSelectboxWithHyphen(dateUtil.genDayList())} {...params} />
                                        <div className="dataTableUnit-text">日</div>
                                        <Input id="ID_JYU00396" name="JYU00396" value={this.props.pageState.JYU00396} {...params} placeholder={PLACE_HOLDE_TEXT} length="wFlexFull" maxlength="16" />
                                        <div className="dataTableUnit-text">号</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">許可番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Select id="ID_JYU01592" name="JYU01592" value={this.props.pageState.JYU01592} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} />
                                        <Select id="ID_JYU01593" name="JYU01593" value={this.props.pageState.JYU01593} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} />
                                        <div className="dataTableUnit-text">年</div>
                                        <Select id="ID_JYU01594" name="JYU01594" value={this.props.pageState.JYU01594} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} />
                                        <div className="dataTableUnit-text">月</div>
                                        <Select id="ID_JYU01595" name="JYU01595" value={this.props.pageState.JYU01595} items={this.getSelectboxWithHyphen(dateUtil.genDayList())} {...params} />
                                        <div className="dataTableUnit-text">日</div>
                                        <Input id="ID_JYU00398" name="JYU00398" value={this.props.pageState.JYU00398} {...params} placeholder={PLACE_HOLDE_TEXT} length="wFlexFull" maxlength="16" />
                                        <div className="dataTableUnit-text">号</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">検査済番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Select id="ID_JYU01596" name="JYU01596" value={this.props.pageState.JYU01596} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} />
                                        <Select id="ID_JYU01597" name="JYU01597" value={this.props.pageState.JYU01597} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} />
                                        <div className="dataTableUnit-text">年</div>
                                        <Select id="ID_JYU01598" name="JYU01598" value={this.props.pageState.JYU01598} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} />
                                        <div className="dataTableUnit-text">月</div>
                                        <Select id="ID_JYU01599" name="JYU01599" value={this.props.pageState.JYU01599} items={this.getSelectboxWithHyphen(dateUtil.genDayList())} {...params} />
                                        <div className="dataTableUnit-text">日</div>
                                        <Input id="ID_JYU00400" name="JYU00400" value={this.props.pageState.JYU00400} {...params} placeholder={PLACE_HOLDE_TEXT} length="wFlexFull" maxlength="16" />
                                        <div className="dataTableUnit-text">号</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">公告</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Select id="ID_JYU01600" name="JYU01600" value={this.props.pageState.JYU01600} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} />
                                        <Select id="ID_JYU01601" name="JYU01601" value={this.props.pageState.JYU01601} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} />
                                        <div className="dataTableUnit-text">年</div>
                                        <Select id="ID_JYU01602" name="JYU01602" value={this.props.pageState.JYU01602} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} />
                                        <div className="dataTableUnit-text">月</div>
                                        <Select id="ID_JYU01603" name="JYU01603" value={this.props.pageState.JYU01603} items={this.getSelectboxWithHyphen(dateUtil.genDayList())} {...params} />
                                        <div className="dataTableUnit-text">日</div>
                                        <Input id="ID_JYU00402" name="JYU00402" value={this.props.pageState.JYU00402} {...params} placeholder={PLACE_HOLDE_TEXT} length="wFlexFull" maxlength="16" />
                                        <div className="dataTableUnit-text">号</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center" rowSpan="4">③都市計画<br />施設</th>
                                <td className="dataTable-body is-rightborder" rowSpan="4">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00403" name="JYU00403" checked={this.props.pageState.JYU00403} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01330" name="JYU01330" checked={this.props.pageState.JYU01330} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00404" name="JYU00404" checked={this.props.pageState.JYU00404} {...params} />
                                        <span className="checkBox-text">1.都市計画道路</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">（</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00405" name="JYU00405" checked={this.props.pageState.JYU00405} {...params} />
                                                        <span className="checkBox-text">1.計画決定</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00406" name="JYU00406" checked={this.props.pageState.JYU00406} {...params} />
                                                        <span className="checkBox-text">2.事業決定 </span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">名称</div>
                                        <Input id="ID_JYU00407" name="JYU00407" value={this.props.pageState.JYU00407} {...params} length="w201" />
                                        <div className="dataTableUnit-text">幅員</div>
                                        <Input id="ID_JYU00408" name="JYU00408" value={this.props.pageState.JYU00408} {...params} length="w60" />
                                        <div className="dataTableUnit-text">m</div>
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00409" name="JYU00409" checked={this.props.pageState.JYU00409} {...params} />
                                        <span className="checkBox-text">2.その他の都市計画施設</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">（</div>
                                        <Input id="ID_JYU00410" name="JYU00410" value={this.props.pageState.JYU00410} {...params} length="w483" />
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center">④市街地<br />開発事業</th>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <ul className="l-checkBox">
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_JYU00411" name="JYU00411" checked={this.props.pageState.JYU00411} {...params} />
                                                    <span className="checkBox-text">有</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_JYU01331" name="JYU01331" checked={this.props.pageState.JYU01331} {...params} />
                                                    <span className="checkBox-text">無</span>
                                                </label>
                                            </li>
                                        </ul>
                                        <Input id="ID_JYU01604" name="JYU01604" value={this.props.pageState.JYU01604} {...params} length="wFlexFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00412" name="JYU00412" value={this.props.pageState.JYU00412} text={SEND_OTHER_PAPER.JYU00412} placeholder={JYU00412LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00412LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="2">建築基準法に基づく制限（制限の内容等については、補足資料に記載されています。）</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">①用途地域</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--adjColumnWrap l-checkBox--adjColumnWrap__6row">
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00444" name="JYU00444" checked={this.props.pageState.JYU00444} {...params} />
                                                        <span className="checkBox-text">1.第1種低層住居専用地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00445" name="JYU00445" checked={this.props.pageState.JYU00445} {...params} />
                                                        <span className="checkBox-text">2.第2種低層住居専用地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00446" name="JYU00446" checked={this.props.pageState.JYU00446} {...params} />
                                                        <span className="checkBox-text">3.第1種中高層住居専用地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00447" name="JYU00447" checked={this.props.pageState.JYU00447} {...params} />
                                                        <span className="checkBox-text">4.第2種中高層住居専用地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00448" name="JYU00448" checked={this.props.pageState.JYU00448} {...params} />
                                                        <span className="checkBox-text">5.第1種住居地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00449" name="JYU00449" checked={this.props.pageState.JYU00449} {...params} />
                                                        <span className="checkBox-text">6.第2種住居地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00451" name="JYU00451" checked={this.props.pageState.JYU00451} {...params} />
                                                        <span className="checkBox-text">7.準住居地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00450" name="JYU00450" checked={this.props.pageState.JYU00450} {...params} />
                                                        <span className="checkBox-text">8.田園住居地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00452" name="JYU00452" checked={this.props.pageState.JYU00452} {...params} />
                                                        <span className="checkBox-text">9.近隣商業地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00453" name="JYU00453" checked={this.props.pageState.JYU00453} {...params} />
                                                        <span className="checkBox-text">10.商業地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00454" name="JYU00454" checked={this.props.pageState.JYU00454} {...params} />
                                                        <span className="checkBox-text">11.準工業地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00455" name="JYU00455" checked={this.props.pageState.JYU00455} {...params} />
                                                        <span className="checkBox-text">12.工業地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00456" name="JYU00456" checked={this.props.pageState.JYU00456} {...params} />
                                                        <span className="checkBox-text">13.工業専用地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00457" name="JYU00457" checked={this.props.pageState.JYU00457} {...params} />
                                                        <span className="checkBox-text">14.用途地域の指定なし</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00458" name="JYU00458" checked={this.props.pageState.JYU00458} {...params} />
                                                        <span className="checkBox-text">15.</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00459" name="JYU00459" value={this.excludeBlanks(this.props.pageState.JYU00459)} {...params} length="wFlexFull" maxlength="12" />
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center" rowSpan="2">②地域・<br />地区・街区</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00463" name="JYU00463" checked={this.props.pageState.JYU00463} {...params} />
                                                <span className="checkBox-text">特別用途地区</span>
                                            </label>
                                        </div>
                                        <Input id="ID_JYU00464" name="JYU00464" value={this.props.pageState.JYU00464} {...params} length="wFlexFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox l-checkBox--adjColumnWrap l-checkBox--adjColumnWrap__7row">
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00465" name="JYU00465" checked={this.props.pageState.JYU00465} {...params} />
                                                        <span className="checkBox-text">1.防火地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00466" name="JYU00466" checked={this.props.pageState.JYU00466} {...params} />
                                                        <span className="checkBox-text">2.準防火地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00467" name="JYU00467" checked={this.props.pageState.JYU00467} {...params} />
                                                        <span className="checkBox-text">3.高度地区</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00468" name="JYU00468" value={this.excludeBlanks(this.props.pageState.JYU00468)} {...params} length="wFlexFull" maxlength="7" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00469" name="JYU00469" checked={this.props.pageState.JYU00469} {...params} />
                                                        <span className="checkBox-text">4.高度利用地区</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00470" name="JYU00470" checked={this.props.pageState.JYU00470} {...params} />
                                                        <span className="checkBox-text">5.特定街区</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00471" name="JYU00471" checked={this.props.pageState.JYU00471} {...params} />
                                                        <span className="checkBox-text">6.景観地区</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00472" name="JYU00472" checked={this.props.pageState.JYU00472} {...params} />
                                                        <span className="checkBox-text">7.風致地区</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00473" name="JYU00473" checked={this.props.pageState.JYU00473} {...params} />
                                                        <span className="checkBox-text">8.災害危険区域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00474" name="JYU00474" checked={this.props.pageState.JYU00474} {...params} />
                                                        <span className="checkBox-text">9.地区計画区域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00475" name="JYU00475" checked={this.props.pageState.JYU00475} {...params} />
                                                        <span className="checkBox-text">10.特例容積率適用地区</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00476" name="JYU00476" checked={this.props.pageState.JYU00476} {...params} />
                                                        <span className="checkBox-text">11.特定用途制限地域</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00478" name="JYU00478" checked={this.props.pageState.JYU00478} {...params} />
                                                        <span className="checkBox-text">12.高層住居誘導地区</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00479" name="JYU00479" checked={this.props.pageState.JYU00479} {...params} />
                                                        <span className="checkBox-text">13.駐車場整備地区</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00480" name="JYU00480" checked={this.props.pageState.JYU00480} {...params} />
                                                        <span className="checkBox-text">14.都市再生特別地区</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00481" name="JYU00481" checked={this.props.pageState.JYU00481} {...params} />
                                                        <span className="checkBox-text">15.特定防災街区整備地区</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01605" name="JYU01605" checked={this.props.pageState.JYU01605} {...params} />
                                                        <span className="checkBox-text">16.特定用途誘導地区</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00482" name="JYU00482" checked={this.props.pageState.JYU00482} {...params} />
                                                        <span className="checkBox-text">17</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00483" name="JYU00483" value={this.excludeBlanks(this.props.pageState.JYU00483)} {...params} length="wFlexFull" maxlength="12" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-input marginWrap">
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00484" name="JYU00484" checked={this.props.pageState.JYU00484} {...params} />
                                                        <span className="checkBox-text">18</span>
                                                    </label>
                                                </div>
                                                <Input id="ID_JYU00485" name="JYU00485" value={this.excludeBlanks(this.props.pageState.JYU00485)} {...params} length="wFlexFull" maxlength="12" />
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title" rowSpan="2">③建築面積の敷地面積に対する<br />割合の限度（建ぺい率）</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00489" name="JYU00489" value={this.props.pageState.JYU00489} {...params} length="w120" prefix="指定" />
                                        <div className="dataTableUnit-text">%</div>
                                        <Input id="ID_JYU01607" name="JYU01607" value={this.props.pageState.JYU01607} {...params} length="wFlexFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <Input id="ID_JYU00490" name="JYU00490" value={this.props.pageState.JYU00490} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title" rowSpan="3">④建築物の延べ面積の敷地面積に<br />対する割合の限度（容積率）</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00501" name="JYU00501" value={this.props.pageState.JYU00501} {...params} length="w120" prefix="指定" />
                                        <div className="dataTableUnit-text">%</div>
                                        <Input id="ID_JYU01608" name="JYU01608" value={this.props.pageState.JYU01608} {...params} length="wFlexFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <Input id="ID_JYU00502" name="JYU00502" value={this.props.pageState.JYU00502} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__noborder">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">ただし前面道路により上記容積率がさらに制限されます。</div>
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-text">→道路幅員約</div>
                                                <Input id="ID_JYU00507" name="JYU00507" value={this.props.pageState.JYU00507} {...params} length="w120" />
                                                <div className="dataTableUnit-text">（m）×</div>
                                                <Input id="ID_JYU00509" name="JYU00509" value={this.props.pageState.JYU00509} {...params} length="w120" />
                                                <div className="dataTableUnit-text">/10×100%</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body dataTable-body__top" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00513" name="JYU00513" value={this.props.pageState.JYU00513} text={SEND_OTHER_PAPER.JYU00513} placeholder={JYU00513LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00513LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-title dataTable-title__left" colSpan="2">⑤敷地等と道路との関係</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="dataUpTable">
                        <div className="dataUpTable-file">
                            <div className="dataUpTable-file-title">敷地等と道路との関係（概略図）</div>
                            {uploadedMessage}
                            <div className="dataUpTable-file-body">
                                <input type="file" id="ID_JYU00595" name="JYU00595" accept="image/*" onChange={this.handleUploadMap} disabled={JYU00955_disabled} />
                            </div>
                        </div>
                        <div className="dataUpTable-table">
                            <table className="dataTable">
                                <tbody>
                                    <tr>
                                        <th className="dataTable-title dataTable-title__leftNoborder dataTable-title__w30 is-emphasis">道路の種類</th>
                                        <td className="dataTable-body">
                                            <ul className="l-checkBox l-checkBox--vertical">
                                                <li>
                                                    <label className="checkBox checkbox__topLine">
                                                        <InputCheckbox id="ID_JYU00524" name="JYU00524" checked={this.props.pageState.JYU00524} {...params} />
                                                        <span className="checkBox-text">1.道路法による道路</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox checkbox__topLine">
                                                        <InputCheckbox id="ID_JYU00525" name="JYU00525" checked={this.props.pageState.JYU00525} {...params} />
                                                        <span className="checkBox-text">2.都市計画法、土地区画整理法、旧住宅地造成事業法、都市再開発法、新都市基盤整備法、大都市法、密集市街地整備法による道路</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox checkbox__topLine">
                                                        <InputCheckbox id="ID_JYU00526" name="JYU00526" checked={this.props.pageState.JYU00526} {...params} />
                                                        <span className="checkBox-text">3.既存道（建築基準法第3章適用の際、現に存するもの）</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox checkbox__topLine">
                                                        <InputCheckbox id="ID_JYU00527" name="JYU00527" checked={this.props.pageState.JYU00527} {...params} />
                                                        <span className="checkBox-text">4.道路法、都市計画法、土地区画整理法、都市再開発法、新都市基盤整備法、大都市法、密集市街地整備法の事業による計画道路（2年以内に事業が執行予定、特定行政庁の指定あり）</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox checkbox__topLine">
                                                        <InputCheckbox id="ID_JYU00528" name="JYU00528" checked={this.props.pageState.JYU00528} {...params} />
                                                        <span className="checkBox-text">5.土地を建築物の敷地として利用するため、上記1〜4の法によらないで道を築造しようとする者が特定行政庁から指定を受けたもの（位置指定道路）</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox checkbox__topLine">
                                                        <InputCheckbox id="ID_JYU00529" name="JYU00529" checked={this.props.pageState.JYU00529} {...params} />
                                                        <span className="checkBox-text">6.上記3の既存道のうち、幅員が4m（6m）未満のもので特定行政庁が指定したもの（2項道路）</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="dataTable-body">&nbsp;</td>
                                        <td className="dataTable-body" colSpan="2">
                                            <label className="checkBox checkbox__topLine">
                                                <InputCheckbox id="ID_JYU00530" name="JYU00530" checked={this.props.pageState.JYU00530} {...params} />
                                                <span className="checkBox-text">7.建築基準法上の道路に該当しない通路（建築確認不可）</span>
                                            </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="dataTable-body">&nbsp;</td>
                                        <td className="dataTable-body" colSpan="2">都市計画区域、準都市計画区域内では、原則として上記道路の種類1〜6の建築基準法上の道路に2m以上接していないと、建築できません。（建築確認不可）</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">道路境界線後退（セットバック）による建築確認対象面積の減少</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00531" name="JYU00531" checked={this.props.pageState.JYU00531} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01332" name="JYU01332" checked={this.props.pageState.JYU01332} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="6">前面道路</td>
                            </tr>
                            <tr>
                                <td className="dataTable-title dataTable-title__w40">&nbsp;</td>
                                <th className="dataTable-title">公道・私道の別</th>
                                <th className="dataTable-title dataTable-title__w152">種類</th>
                                <th className="dataTable-title dataTable-title__w152">接道方向</th>
                                <th className="dataTable-title dataTable-title__w152">幅員</th>
                                <th className="dataTable-title dataTable-title__w152">接道の長さ</th>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__center is-rightborder">1</td>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00534" name="JYU00534" checked={this.props.pageState.JYU00534} {...params} />
                                                <span className="checkBox-text">公道</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00535" name="JYU00535" checked={this.props.pageState.JYU00535} {...params} />
                                                <span className="checkBox-text">私道</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01646" name="JYU01646" checked={this.props.pageState.JYU01646} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00536" name="JYU00536" value={this.props.pageState.JYU00536} {...params} length="w80" disabled={JYU01646_disabled} />
                                        <div className="dataTableUnit-text">番</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00537" name="JYU00537" value={this.props.pageState.JYU00537} {...params} length="w80" disabled={JYU01646_disabled} />
                                        <div className="dataTableUnit-text">側</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00538" name="JYU00538" value={this.props.pageState.JYU00538} {...params} length="w80" disabled={JYU01646_disabled} />
                                        <div className="dataTableUnit-text">ｍ</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00539" name="JYU00539" value={this.props.pageState.JYU00539} {...params} length="w80" disabled={JYU01646_disabled} />
                                        <div className="dataTableUnit-text">ｍ</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__center is-rightborder">2</td>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00540" name="JYU00540" checked={this.props.pageState.JYU00540} {...params} />
                                                <span className="checkBox-text">公道</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00541" name="JYU00541" checked={this.props.pageState.JYU00541} {...params} />
                                                <span className="checkBox-text">私道</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01647" name="JYU01647" checked={this.props.pageState.JYU01647} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00542" name="JYU00542" value={this.props.pageState.JYU00542} {...params} length="w80" disabled={JYU01647_disabled} />
                                        <div className="dataTableUnit-text">番</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00543" name="JYU00543" value={this.props.pageState.JYU00543} {...params} length="w80" disabled={JYU01647_disabled} />
                                        <div className="dataTableUnit-text">側</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00544" name="JYU00544" value={this.props.pageState.JYU00544} {...params} length="w80" disabled={JYU01647_disabled} />
                                        <div className="dataTableUnit-text">ｍ</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00545" name="JYU00545" value={this.props.pageState.JYU00545} {...params} length="w80" disabled={JYU01647_disabled} />
                                        <div className="dataTableUnit-text">ｍ</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__center is-rightborder">3</td>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00546" name="JYU00546" checked={this.props.pageState.JYU00546} {...params} />
                                                <span className="checkBox-text">公道</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00547" name="JYU00547" checked={this.props.pageState.JYU00547} {...params} />
                                                <span className="checkBox-text">私道</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01648" name="JYU01648" checked={this.props.pageState.JYU01648} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00548" name="JYU00548" value={this.props.pageState.JYU00548} {...params} length="w80" disabled={JYU01648_disabled} />
                                        <div className="dataTableUnit-text">番</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00549" name="JYU00549" value={this.props.pageState.JYU00549} {...params} length="w80" disabled={JYU01648_disabled} />
                                        <div className="dataTableUnit-text">側</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00550" name="JYU00550" value={this.props.pageState.JYU00550} {...params} length="w80" disabled={JYU01648_disabled} />
                                        <div className="dataTableUnit-text">ｍ</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit dataTableUnit--center">
                                        <Input id="ID_JYU00551" name="JYU00551" value={this.props.pageState.JYU00551} {...params} length="w80" disabled={JYU01648_disabled} />
                                        <div className="dataTableUnit-text">ｍ</div>
                                    </div>
                                </td>
                            </tr>
                            {frontRoad}
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body dataTable-body__top" colSpan="5">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00558" name="JYU00558" value={this.props.pageState.JYU00558} text={SEND_OTHER_PAPER.JYU00558} placeholder={JYU00558LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00558LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <td className="dataTable-title" colSpan="4">
                                    <div className="dataTableUnit dataTableUnit--between">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-text">道路位置指定：</div>
                                            <Select id="ID_JYU01609" name="JYU01609" value={this.props.pageState.JYU01609} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} />
                                            <Select id="ID_JYU01610" name="JYU01610" value={this.props.pageState.JYU01610} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} />
                                            <div className="dataTableUnit-text">年</div>
                                            <Select id="ID_JYU01611" name="JYU01611" value={this.props.pageState.JYU01611} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} />
                                            <div className="dataTableUnit-text">月</div>
                                            <Select id="ID_JYU01612" name="JYU01612" value={this.props.pageState.JYU01612} items={this.getSelectboxWithHyphen(dateUtil.genDayList())} {...params} />
                                            <div className="dataTableUnit-text">日</div>
                                        </div>
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-text">第</div>
                                            <Input id="ID_JYU00562" name="JYU00562" value={this.props.pageState.JYU00562} {...params} length="w391" />
                                            <div className="dataTableUnit-text">号</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-title  dataTable-title__left" colSpan="6">
                                    <div className="dataTableUnit-text">道路内では建築等の利用はできません。</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w300" rowSpan="2">条例による制限</th>
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00563" name="JYU00563" checked={this.props.pageState.JYU00563} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01333" name="JYU01333" checked={this.props.pageState.JYU01333} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-input dataTableUnit-input__wFull">
                                            <Input id="ID_JYU00564" name="JYU00564" value={this.props.pageState.JYU00564} {...params} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w325" colSpan="3">
                                    <Input id="ID_JYU01790" name="JYU01790" value={this.props.pageState.JYU01790} {...params} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w300">⑥私道の変更または廃止の制限</th>
                                <td className="dataTable-body dataTable-body__w279">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00566" name="JYU00566" checked={this.props.pageState.JYU00566} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01334" name="JYU01334" checked={this.props.pageState.JYU01334} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <th className="dataTable-title dataTable-title__w181">⑦壁面線の制限</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00567" name="JYU00567" checked={this.props.pageState.JYU00567} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01335" name="JYU01335" checked={this.props.pageState.JYU01335} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w300">⑧敷地面積の最低限度</th>
                                <td className="dataTable-body dataTable-body__w279">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00568" name="JYU00568" checked={this.props.pageState.JYU00568} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01336" name="JYU01336" checked={this.props.pageState.JYU01336} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <Input id="ID_JYU00569" name="JYU00569" value={this.props.pageState.JYU00569} {...params} length="w60" />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w181">⑨外壁後退</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00572" name="JYU00572" checked={this.props.pageState.JYU00572} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01337" name="JYU01337" checked={this.props.pageState.JYU01337} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="2">⑩建物の<br />高さの制限</th>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">1.絶対高さ<br />制限</th>
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00574" name="JYU00574" checked={this.props.pageState.JYU00574} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01338" name="JYU01338" checked={this.props.pageState.JYU01338} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">（</div>
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00575" name="JYU00575" checked={this.props.pageState.JYU00575} {...params} />
                                                        <span className="checkBox-text">10m</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00576" name="JYU00576" checked={this.props.pageState.JYU00576} {...params} />
                                                        <span className="checkBox-text">12m</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">）</div>
                                        <div className="dataTableUnit-subnote">※第1種・第2種低層住居専用地域、田園住居地域の場合</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center">2.道路斜線<br />制限</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00577" name="JYU00577" checked={this.props.pageState.JYU00577} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01339" name="JYU01339" checked={this.props.pageState.JYU01339} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <th className="dataTable-title dataTable-title__center">3.隣地斜線<br />制限</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00578" name="JYU00578" checked={this.props.pageState.JYU00578} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01340" name="JYU01340" checked={this.props.pageState.JYU01340} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <th className="dataTable-title dataTable-title__center">4.北側斜線<br />制限</th>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00579" name="JYU00579" checked={this.props.pageState.JYU00579} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01341" name="JYU01341" checked={this.props.pageState.JYU01341} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w300">⑪日影による中高層の建築物の制限</th>
                                <td className="dataTable-body dataTable-body__w400">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00580" name="JYU00580" checked={this.props.pageState.JYU00580} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01342" name="JYU01342" checked={this.props.pageState.JYU01342} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTableUnit-text">（</div>
                                        <Input id="ID_JYU01432" name="JYU01432" value={this.props.pageState.JYU01432} {...params} length="w242" />
                                        <div className="dataTableUnit-text">種）</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">⑫建築協定</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-input">
                                            <ul className="l-checkBox">
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU00585" name="JYU00585" checked={this.props.pageState.JYU00585} {...params} />
                                                        <span className="checkBox-text">有</span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="checkBox">
                                                        <InputCheckbox id="ID_JYU01343" name="JYU01343" checked={this.props.pageState.JYU01343} {...params} />
                                                        <span className="checkBox-text">無</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <Input id="ID_JYU01613" name="JYU01613" value={this.props.pageState.JYU01613} {...params} length="wFlexFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w300" rowSpan="2">⑬地方公共団体の条例等による制限：</th>
                                <td className="dataTable-body dataTable-body__w325" colSpan="3">
                                    <Input id="ID_JYU00586" name="JYU00586" value={this.props.pageState.JYU00586} {...params} length="w614" maxlength="34" />
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body dataTable-body__w325" colSpan="3">
                                    <Input id="ID_JYU01614" name="JYU01614" value={this.props.pageState.JYU01614} {...params} length="w614" maxlength="49" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body dataTable-body__top" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00587" name="JYU00587" value={this.props.pageState.JYU00587} text={SEND_OTHER_PAPER.JYU00587} placeholder={JYU00587LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00587LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >
        );
    }
}
