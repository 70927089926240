import { ORGANIZATION_POPUP as OP } from "constants/contractEditPageInfo";
import React from "react";
import InputDate from "../../input/inputDate";
import Input from "../../input/inputNormal";
import InputOrg from "../../input/inputOrg";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 台帳情報 */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010102",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">台帳情報</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">台帳作成日<br />（変更日）</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00001" name="TOR00001" value={this.props.pageState.TOR00001} {...params} />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">成立台帳<br />バージョン</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00002" name="TOR00002" value={this.props.pageState.TOR00002} {...params} length="w331" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">変更理由</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00003" name="TOR00003" value={this.props.pageState.TOR00003} {...params} length="w331" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">物件種別</th>
                                <td className="dataTable-body dataTable-body__w355">物件種別名</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">支店/組織</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputOrg id="ID_TOR00204" name="TOR00204" value={this.props.pageState.TOR00204} {...params} from={OP.SIT00002} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}