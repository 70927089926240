import InputCheckbox from "components/page/user/contract/edit/input/inputCheckbox";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { CONTRACT_EDIT_INFO } from "constants/contractEditPageInfo";
import React from "react";
import { Link } from "react-router-dom";

/** 区分所有建物 */
export default class Block2 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "030102",
        };
    }

    handleTo0101 = e => {
        e.preventDefault();
        // リロードをかける
        this.props.pageSwitch(CONTRACT_EDIT_INFO.PAGE_0101.id);
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <React.Fragment>
                <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                    <div className="dataList-header">
                        <div className="dataList-header-title">区分所有建物</div>
                        <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                    </div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title is-emphasis" colSpan="4">一棟の建物の表示</th>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">建物の名称</th>
                                    <td className="dataTable-body">{this.props.pageState.TOR00205}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住居表示</th>
                                    <td className="dataTable-body">{this.props.pageState.TOR00206}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在</th>
                                    <td className="dataTable-body">{this.props.pageState.TOR00207}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">構造</th>
                                    <td className="dataTable-body">{this.props.pageState.TOR00208}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">延べ床面積</th>
                                    <td className="dataTable-body">{this.props.pageState.TOR00209}m<sup>2</sup></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <th className="dataTable-title is-emphasis" colSpan="4">専有部分の建物の表示</th>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">家屋番号</th>
                                    <td className="dataTable-body dataTable-body__w355">{this.props.pageState.TOR00210}</td>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">建物の名称</th>
                                    <td className="dataTable-body dataTable-body__w355">{this.props.pageState.TOR00211}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">種類</th>
                                    <td className="dataTable-body dataTable-body__w355">{this.props.pageState.TOR00212}</td>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">構造</th>
                                    <td className="dataTable-body dataTable-body__w355">{this.props.pageState.TOR00213}</td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">床面積</th>
                                    <td className="dataTable-body" colSpan="3">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-text">{this.props.pageState.TOR00214}階部分</div>
                                            <div className="dataTableUnit-text">登記簿</div>
                                            <div className="dataTableUnit-text">{this.props.pageState.TOR00215}m<sup>2</sup></div>
                                            <div className="dataTableUnit-text">壁芯</div>
                                            <div className="dataTableUnit-text">{this.props.pageState.TOR00216}m<sup>2</sup></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="dataTable-title dataTable-title__w120 dataTable-title__center">付属建物</th>
                                    <td className="dataTable-body" colSpan="3">
                                        <div className="dataTableUnit">
                                            <div className="dataTableUnit-input">
                                                <ul className="l-checkBox">
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox disabled id="ID_JYU00142" name="JYU00142" checked={this.props.pageState.JYU00142} {...params} />
                                                            <span className="checkBox-text">有</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkBox">
                                                            <InputCheckbox disabled id="ID_JYU01327" name="JYU01327" checked={this.props.pageState.JYU01327} {...params} />
                                                            <span className="checkBox-text">無</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="dataTableUnit-text">{this.props.pageState.JYU00143}</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="l-dataListMore">
                    <Link to="" aria-label="link" className="dataListMore" onClick={this.handleTo0101}>上記内容を修正したい場合は、こちらをクリックしてください</Link>
                </div>
            </React.Fragment>
        );
    }
}