import React from "react";
import Input from "../../input/inputNormal";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 区分所有建物 */
export default class Block5 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010105",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">区分所有建物</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="4">一棟の建物の表示</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">建物の名称</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00205" name="TOR00205" value={this.props.pageState.TOR00205} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住居表示</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00206" name="TOR00206" value={this.props.pageState.TOR00206} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00207" name="TOR00207" value={this.props.pageState.TOR00207} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">構造</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00208" name="TOR00208" value={this.props.pageState.TOR00208} {...params} length="w391" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">延床面積</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00209" name="TOR00209" value={this.props.pageState.TOR00209} {...params} length="w112" />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="4">専有部分の建物の表示</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">家屋番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00210" name="TOR00210" value={this.props.pageState.TOR00210} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">建物の名称</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00211" name="TOR00211" value={this.props.pageState.TOR00211} {...params} />
                                        <div className="dataTableUnit-text"></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">種類</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00212" name="TOR00212" value={this.props.pageState.TOR00212} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">構造</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00213" name="TOR00213" value={this.props.pageState.TOR00213} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">床面積</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00214" name="TOR00214" value={this.props.pageState.TOR00214} {...params} length="w120" />
                                        <div className="dataTableUnit-text">階部分</div>
                                        <div className="dataTableUnit-text">登記簿</div>
                                        <Input id="ID_TOR00215" name="TOR00215" value={this.props.pageState.TOR00215} {...params} length="w120" />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                        <div className="dataTableUnit-text">壁芯</div>
                                        <Input id="ID_TOR00216" name="TOR00216" value={this.props.pageState.TOR00216} {...params} length="w120" />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}