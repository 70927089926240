import { ERROR_MESSAGE } from "../constants/errorMessage";
import { INPUT_LIMITS } from "../constants/inputLimits";
import { INPUT_LIMITS_FRK } from "../constants/inputLimitsFrk";

/** 入力値チェック */
export default class Validation {
    constructor() {
        // エラーメッセージ格納用
        this.errorMessage = {};
    }

    /**
     * エラーメッセージの存在チェック
     * true: エラー有り、false: エラー無し
     */
    isErrorMessage = () => {
        var result = false;
        const messagekeys = Object.keys(this.errorMessage);
        if (messagekeys.length) {
            messagekeys.forEach(key => {
                if (this.errorMessage[key]) {
                    result = true;
                }
            });
        }
        return result;
    };

    /**
     * paramsを基に、stateの値をバリデーションする
     * true: エラー有り、false: エラー無し
     */
    validateTotalInputText = (params, state) => {
        const paramsKeys = Object.keys(params);
        paramsKeys.forEach(key => {
            // バリデーションなしは、下記の分岐でスキップさせる
            if (params[key].validate) {
                params[key].validate(key, state[key]);
            }
        });

        return this.isErrorMessage();
    }

    /** 空文字チェック */
    notEmpty = (key, value) => {
        this.errorMessage[key] = value ? "" : ERROR_MESSAGE.IS_EMPTY;
    };

    /** 空文字チェック & 数値チェック */
    notEmptyAndIsNumber = (key, value) => {
        this.errorMessage[key] = undefined;

        // 数値は許容するため、0 を false と判定されると困るのでnotEmptyとは別に判定処理作る
        if (value === null || value === "" || value === undefined) {
            this.errorMessage[key] = ERROR_MESSAGE.IS_EMPTY;
        }

        if (!this.errorMessage[key] && !isNumber(value)) {
            this.errorMessage[key] = ERROR_MESSAGE.NOT_NUMBER;
        }
    };

    /** 空文字チェック & メールアドレス形式チェック */
    notEmptyAndIsEmail = (key, value) => {
        this.notEmpty(key, value);
        if (!this.errorMessage[key] && !isEmail(value)) {
            this.errorMessage[key] = ERROR_MESSAGE.NOT_EMAL;
        }
    };

    /** 同値チェック */
    notEqualValues = (key, value1, value2) => {
        this.errorMessage[key] = value1 === value2 ? "" : ERROR_MESSAGE.NOT_EQUAL_VALUES;
    };

    /** バリデート無し */
    noCheck = val => { }

    /** 空文字チェック & 日付形式チェック */
    notEmptyAndIsDate = (key, value) => {
        this.notEmpty(key, value);
        if (!this.errorMessage[key] && !isDate(value)) {
            this.errorMessage[key] = ERROR_MESSAGE.NOT_DATE;
        }
    };

    /**
     * テキストエリアの項目IDを基に、入力情報の値が帳票の出力欄に表示しきれるかバリデーションする
     * useTemplateFileNumber:出力に使用する帳票の識別ID（今後実装）
     * true: エラー有り、false: エラー無し
     */
    validateTextAreaWordCount = (templateItemID, value) => {
        // 最大文字数の設定値を取得
        let { maxLine, maxWord } = this.GetMaxSettingValue(templateItemID);

        // プレースフォルダ及びエラーメッセージの初期設定
        const inputLimitGuide = `※最大で1行に${maxWord}文字(全角)、${maxLine}行まで入力可能です。（1行あたり${maxWord}文字で自動的に改行されます。）`;
        let inputLimitErrorMessage = "";

        // 設定文字列がない場合は、処理を抜ける
        if (!value) {
            // 戻り値を設定する
            return { inputLimitErrorMessage, inputLimitGuide };
        }

        // 別紙送り以外の文字列を改行で分割する
        let valueArray = (value.split("\n-----\n")[0]).split("\n");

        // オーバー文字列を取得する
        let cntLine = 0;
        let cntWord = 0;
        for (let i = 0; i < valueArray.length; i++) {
            // 1行をMax文字数で分割し各行の文字数を取得する
            let tmpArray = this.DivideMaxChar(maxWord, valueArray[i]);
            if (tmpArray.length === 0) {
                // 行数をカウントアップする
                cntLine++;
            } else {
                for (let j = 0; j < tmpArray.length; j++) {
                    // 文字数を取得し文字がある場合はカウントする
                    let tmpWord = this.WordsCount(tmpArray[j]);
                    // 行数をカウントアップする
                    cntLine++;
                    // Max行数を超えた場合は、オーバー文字数をカウントする
                    if (maxLine < cntLine) {
                        cntWord = cntWord + tmpWord;
                    }
                }
            }
        }
        // オーバー文字数がある場合は、エラーメッセージを生成する
        if (cntWord !== 0) {
            inputLimitErrorMessage = `${cntWord}文字超えています。`;
        }

        // 戻り値を設定する
        return { inputLimitErrorMessage, inputLimitGuide };

    };

    /**
     * テキストエリアの項目IDを基に、入力情報の値が帳票の出力欄に表示しきれるかバリデーションする（FRK書式用）
     * useTemplateFileNumber:出力に使用する帳票の識別ID（今後実装）
     * true: エラー有り、false: エラー無し
     */
    validateTextAreaWordCountFrk = (templateItemID, value) => {
        // 最大文字数の設定値を取得
        let { maxLine, maxWord } = this.GetMaxSettingValueFrk(templateItemID);

        // プレースフォルダ及びエラーメッセージの初期設定
        const inputLimitGuide = `※最大で1行に${maxWord}文字(全角)、${maxLine}行まで入力可能です。（1行あたり${maxWord}文字で自動的に改行されます。）`;
        let inputLimitMsg = "";

        // 設定文字列がない場合は、処理を抜ける
        if (!value) {
            // 戻り値を設定する
            return { inputLimitMsg, inputLimitGuide };
        }

        // 別紙送り以外の文字列を改行で分割する
        let valueArray = (value.split("\n-----\n")[0]).split("\n");

        // オーバー文字列を取得する
        let cntLine = 0;
        let cntWord = 0;
        for (let i = 0; i < valueArray.length; i++) {
            // 1行をMax文字数で分割し各行の文字数を取得する
            let tmpArray = this.DivideMaxChar(maxWord, valueArray[i]);
            if (tmpArray.length === 0) {
                // 行数をカウントアップする
                cntLine++;
            } else {
                for (let j = 0; j < tmpArray.length; j++) {
                    // 文字数を取得し文字がある場合はカウントする
                    let tmpWord = this.WordsCount(tmpArray[j]);
                    // 行数をカウントアップする
                    cntLine++;
                    // Max行数を超えた場合は、オーバー文字数をカウントする
                    if (maxLine < cntLine) {
                        cntWord = cntWord + tmpWord;
                    }
                }
            }
        }
        // オーバー文字数がある場合は、エラーメッセージを生成する
        if (cntWord !== 0) {
            inputLimitMsg = `${cntWord}文字超えています。`;
        }

        // 戻り値を設定する
        return { inputLimitMsg, inputLimitGuide };

    };

    /**
     * テキストエリアの項目IDを基に、入力情報の値が帳票の出力欄に表示しきれるかバリデーションする（FRK書式用）
     * useTemplateFileNumber:出力に使用する帳票の識別ID（今後実装）
     * true: エラー有り、false: エラー無し
     */
    limitTextAreaFrk = (fromId, pageId, itemId, value) => {
        // 最大文字数の設定値を取得
        let { maxLine, maxWord } = this.getMaxLimitFrk(fromId, pageId, itemId);

        // プレースフォルダ及びエラーメッセージの初期設定
        const inputLimitGuide = `※最大で1行に${maxWord}文字(全角)、${maxLine}行まで入力可能です。（1行あたり${maxWord}文字で自動的に改行されます。）`;
        let inputLimitMsg = "";

        // 設定文字列がない場合は、処理を抜ける
        if (!value) {
            // 戻り値を設定する
            return { inputLimitMsg, inputLimitGuide };
        }

        // 別紙送り以外の文字列を改行で分割する
        let valueArray = (value.split("\n-----\n")[0]).split("\n");

        // オーバー文字列を取得する
        let cntLine = 0;
        let cntWord = 0;
        for (let i = 0; i < valueArray.length; i++) {
            // 1行をMax文字数で分割し各行の文字数を取得する
            let tmpArray = this.DivideMaxChar(maxWord, valueArray[i]);
            if (tmpArray.length === 0) {
                // 行数をカウントアップする
                cntLine++;
            } else {
                for (let j = 0; j < tmpArray.length; j++) {
                    // 文字数を取得し文字がある場合はカウントする
                    let tmpWord = this.WordsCount(tmpArray[j]);
                    // 行数をカウントアップする
                    cntLine++;
                    // Max行数を超えた場合は、オーバー文字数をカウントする
                    if (maxLine < cntLine) {
                        cntWord = cntWord + tmpWord;
                    }
                }
            }
        }
        // オーバー文字数がある場合は、エラーメッセージを生成する
        if (cntWord !== 0) {
            inputLimitMsg = `${cntWord}文字超えています。`;
        }

        // 戻り値を設定する
        return { inputLimitMsg, inputLimitGuide };

    };

    /**
     * 指定のMax文字数で文字列を配列に分割する
     */
    DivideMaxChar = (maxWord, value) => {
        // 文字列を配列に分割する
        let lenWord = "";
        let legthWord = 0;
        var splitArray = [];
        for (let i = 0; i < value.length; i++) {
            // 文字の判別（全角：1／半角：0.5）
            let sizeWord = 0;
            if (value[i].match(/[ -~]/)) {
                sizeWord = 0.5;
            } else {
                sizeWord = 1;
            }
            // 指定のMax文字数の場合、配列を追加する
            if (maxWord < legthWord + sizeWord) {
                splitArray.push(lenWord);
                lenWord = value[i];
                legthWord = sizeWord;
            } else {
                legthWord = legthWord + sizeWord;
                lenWord = lenWord + value[i];
            }
        }
        // 最終行に文字が含まれる場合は、配列を追加する
        if (lenWord.length > 0) {
            splitArray.push(lenWord);
        }

        // 戻り値を設定する
        return splitArray;
    }

    /**
     * 最大文字数の設定値を取得する
     * 設定情報は、項目ID_帳票区分でMax行数及び1行のMax文字数を取得する
     * また、設定場がない場合はMax行数：1及び1行のMax文字数：1を返す
     */
    GetMaxSettingValue = (templateItemID) => {
        let maxLine;
        let maxWord;
        // 対象項目の上限行数と上限文字数
        try {
            // ”項目ID”＋”_”＋”帳票区分”でINPUT_LIMITSから設定値を取得
            [maxLine, maxWord] = INPUT_LIMITS[templateItemID];
        } catch (TypeError) {
            // 設定師がない場合はデフォルト値を設定
            [maxLine, maxWord] = [1, 1];
        }

        // 戻り値を設定する
        return { maxLine, maxWord };
    }

    /**
     * 最大文字数の設定値を取得する(FRK書式用)
     * 設定情報は、項目ID_帳票区分でMax行数及び1行のMax文字数を取得する
     * また、設定場がない場合はMax行数：1及び1行のMax文字数：1を返す
     */
    GetMaxSettingValueFrk = (templateItemID) => {
        let maxLine;
        let maxWord;
        // 対象項目の上限行数と上限文字数
        try {
            // ”項目ID”＋”_”＋”帳票区分”でINPUT_LIMITSから設定値を取得
            [maxLine, maxWord] = INPUT_LIMITS_FRK[templateItemID];
        } catch (TypeError) {
            // 設定師がない場合はデフォルト値を設定
            [maxLine, maxWord] = [1, 1];
        }

        // 戻り値を設定する
        return { maxLine, maxWord };
    }
    /**
     * 最大文字数の設定値を取得する(FRK書式用)
     * 設定情報は、項目ID_帳票区分でMax行数及び1行のMax文字数を取得する
     * また、設定場がない場合はMax行数：1及び1行のMax文字数：1を返す
     */
    getMaxLimitFrk = (fromId, pageId, itemId) => {
        let maxLine;
        let maxWord;
        // 対象項目の上限行数と上限文字数
        try {
            // ”項目ID”＋”_”＋”帳票区分”でINPUT_LIMITSから設定値を取得
            const formLimit = INPUT_LIMITS_FRK[fromId];
            const pageLimit = formLimit[pageId];
            maxLine = pageLimit[itemId].numLine;
            maxWord = pageLimit[itemId].wordCnt;
        } catch (TypeError) {
            // 設定師がない場合はデフォルト値を設定
            maxLine = 1;
            maxWord = 1;
        }

        // 戻り値を設定する
        return { maxLine, maxWord };
    }

    /**
     * 文字数をカウントする
     */
    WordsCount = (words) => {
        //入力文字数
        let wordsLength = 0;
        for (let i = 0; i < words.length; i++) { //文字数カウント
            if (words[i].match(/[ -~]/)) {
                wordsLength += 0.5;
            } else {
                wordsLength += 1;
            }
        }
        return wordsLength;
    }

    validateIpAddressTextArea = (key, value) => {

        // 入力値が無い場合、許容する
        if (value === undefined || value === "") {
            this.errorMessage[key] = "";
            return;
        }

        let values = value.split("\n");
        values = values.filter(val => { return val !== ""; });
        let invalid = false;
        values.forEach(val => {
            invalid = invalid | !isIpAddress(val);
        });
        this.errorMessage[key] = invalid ? ERROR_MESSAGE.NOT_IP_ADDRESS_FORMAT : "";
    }

}

/** 数値チェック */
export const isNumber = val => {
    const pattern = /^[0-9]+$/;
    return pattern.test(val);
};

/**
 * Emailの形式チェック
 * 
 * 下記の形式に従ってバリデーションする
 * https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
 */
const isEmail = val => {
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return pattern.test(val);
};

/**
 * true: 日付である false: 日付でない
 */
const isDate = val => {
    const date = new Date(val);

    // 日付として成立していない場合
    if (date.toString() === "Invalid Date") {
        return false;
    }

    // 年が4桁でない場合
    if (!(date.getFullYear() >= 1000 && date.getFullYear() <= 9999)) {
        return false;
    }

    return true;
};

/**
 * IPアドレス形式チェック
 * true: IPアドレス形式になっている false: なっていない
 */
const isIpAddress = val => {
    const regex = /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/;
    return regex.test(val);
};