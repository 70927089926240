import { PageBase } from "components/page/user/contractFrk/edit/parts/pageBase";
import React from "react";
import { withRouter } from "react-router";
import Block1 from "./block1";
import Block2 from "./block2";
import Block4 from "./block4";

class BasPage2 extends PageBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getPageItems(),
        };
        this.setup();
    }

    /**
     * ページで使用する項目を定義する
     * ※データ取得時点で値が空になっている項目はundefinedにする
     * ※チェックボックスの場合、falseにする
     */
    getPageItems = () => {
        return {
            // 支払内容
            TOR00340: undefined, // 手付金　金額
            TOR00341: undefined, // 内金　日付１
            TOR00342: undefined, // 内金　金額１
            TOR00343: undefined, // 内金　日付２
            TOR00344: undefined, // 内金　金額２
            TOR00345: undefined, // 残代金　日付
            TOR00346: undefined, // 残代金　金額

            // 売買代金
            TOR00009: undefined, // 消費税等
            TOR00010: undefined, // 売買代金（税込）

            // ローン情報
            TOR00030: undefined, // ローン特約の有無
            TOR00033: undefined, // ローン解除期日
            TOR00031: undefined, // 金融機関名１
            TOR00032: undefined, // 承認取得期日１
            TOR00336: undefined, // 融資金額１
            TOR00268: undefined, // 金融機関名２
            TOR00269: undefined, // 承認取得期日２
            TOR00337: undefined, // 融資金額２
            TOR00271: undefined, // 金融機関名３
            TOR00272: undefined, // 承認取得期日３
            TOR00338: undefined, // 融資金額３
        };
    }

    /** 動的に項目数が変動する項目のキー名を設定する */
    getDynamicItemKeys = () => {
        return [];
    }

    /** 参照しか行われない項目のキー名を設定する */
    getRefOnlyItemKeys = () => {
        return [];
    }

    /** 初回表示時の初期情報取得 */
    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = () => {

        // リロード用 ※リロードが必要な場合、setState({init: false})する
        if (this.state.init === false) {
            this.init();
        }
    }

    render() {
        // データ取得されるまでは空を表示する
        // ※APIリクエストの正常時コールバック内でsetState({init: true})を行い、APIレスポンスの内容を使って再レンダリングさせる
        if (this.state.init === false) {
            return null;
        }

        // レンダリングのタイミングでページが持っている契約情報を大元に同期する
        this.props.sync(this.pickupContractInfo());

        const params = {
            pageState: this.state,
            updatePageState: this.updatePageState,
            contractTypeId: this.props.contractTypeId,  // 契約種別ID
            pageSwitch: this.props.pageSwitch,
            selectboxData: this.props.selectboxData, // 名称マスタのデータ
        };

        return (
            <React.Fragment>
                <ul className="l-dataList">
                    <li><Block4 {...params} /></li> {/** 売買代金 */}
                    <li><Block1 {...params} /></li> {/** 支払内容 */}
                    <li><Block2 {...params} /></li> {/** 融資条件 */}
                </ul>
            </React.Fragment>
        );
    }
}

export default withRouter(BasPage2);