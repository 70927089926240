import Pagenation from "components/common/parts/pagination";
import React from "react";
import { Link } from "react-router-dom";

/**
 * ページネーション機能があるコンポーネント
 * props.activePage: 現在のページ数
 * props.totalItemsCount: 合計のアイテム数
 * props.children: 表示Dom
 * props.toCreate: 新規作成ボタンの遷移先パス
 * props.createButtonInActive: 作成ボタンのアクティブステータス　非アクティブの場合付ける
 */
export default function PaginationPage(props) {
    const pageSettings = {
        activePage: props.activePage,
        totalItemsCount: props.totalItemsCount,
        onChange: props.onChange
    };

    // createButtonInActiveがある場合、作成ボタンを非アクティブにする
    var className = "baseAction";
    var to = props.toCreate;
    if (props.createButtonInActive) {
        className += " baseAction--inactive";
        to = "";
        var onClick = e => { e.preventDefault(); };
    }

    return (
        <>
            <div className="l-dataListHead">
                <div className="dataListHead">
                    <div className="dataListHead-action">
                        <Link to={to} aria-label="link" className={className} onClick={onClick || null}>新規作成</Link>
                    </div>
                    <div className="dataListHead-pagenation">
                        <Pagenation pageSettings={pageSettings} />
                    </div>
                </div>
            </div>
            {props.children}
            <Pagenation pageSettings={pageSettings} />
        </>
    );
}
