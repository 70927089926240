import { createStore } from "redux";
import { rootReducer } from "../reducers";

const reducer = (state, action) => {
    if (action.type === "ALL_CLEAR") {
        state = undefined;
    }
    return rootReducer(state, action);
};

export default createStore(reducer);
