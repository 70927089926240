import { ERROR_MESSAGE } from "constants/errorMessage";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import * as AWS from "../../../../../../constants/aws";
import * as awsReq from "../../../../../../util/awsRequest";
import Pagination from "../../../../../common/parts/pagination";
import PopupBase from "./popupBase";

/** ポップアップ画面：支店/組織情報一覧 */
class PopupOrgList extends PopupBase {
    constructor(props) {
        super(props);

        this.apicalling = 0; // APIコール中のページを保持（0はコール無し。連続API防止措置なので簡易）
    }

    // ポップアップの中身を表示するためのAPIリクエスト
    RequestAPI = () => {
        if (this.apicalling !== this.state.activePage) {
            this.apicalling = this.state.activePage;

            /** 組織一覧取得 */
            let query = { page: this.state.activePage };

            // キーワードがある時はキーワードをクエリに入れる
            if (this.state.keyword !== "") {
                query.keyword = this.state.keyword;
            }

            awsReq.post(
                AWS.ENDPOINT.PATHS.GET_POPUP_AGENCY_BRANCH_LIST,
                res => {
                    this.apicalling = 0;

                    // 現在表示しているページに対応しているレスポンスの場合、レスポンスをセットする
                    // ※レスポンスを全て画面反映させるとページ送りした分の全ての結果が順番に表示されてしまうため
                    // ※1ページ目を表示している時に8ページ目に行くためには、1,5,8と遷移する必要があるが（この時、5は中継地点なので結果表示の必要がない）、
                    //   レスポンスを全て画面表示すると、8ページ目を表示する前に5ページ目の内容を一瞬表示してしまうことの対策
                    if (this.state.activePage === res.data.page_number) {
                        this.setState({
                            data: res.data.agency_branches,
                            itemsCountPerPage: res.data.per_page,
                            totalItemsCount: res.data.total_records,
                        });
                    }
                },
                error => {
                    this.apicalling = 0;
                    this.setState({ data: [] });
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                },
                query,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }

    // 一覧表示のテーブル部分のDOMを生成
    createTable = list => {
        const ret = (
            <div className="dataList-body">
                <table className="dataTable">
                    <tbody>
                        <tr>
                            <th className="dataTable-title dataTable-title__w120 is-emphasis">支店／組織ID</th>
                            <th className="dataTable-title is-emphasis">名称</th>
                        </tr>
                        {list}
                    </tbody>
                </table>
            </div>
        );
        return ret;
    }

    // リスト表示用のDOMを生成
    createItems = data => {
        let list = [];
        data.forEach((element, idx) => {
            const isActive = this.selectedIdx === idx ? "is-active" : null;
            const item = (
                <tr key={idx} onClick={e => { this.selectedIdx = idx; this.setState({}); }} className={isActive}>
                    <td className="dataTable-body">{element.display_id}</td>
                    <td className="dataTable-body">{element.branch_name}</td>
                </tr>
            );
            list.push(item);
        });

        // 表示がおかしくなるので、空いている分の今日を埋める
        while (list.length !== this.state.itemsCountPerPage) {
            list.push(
                <tr key={list.length + 1}>
                    <td className="dataTable-body">　</td>
                    <td className="dataTable-body">　</td>
                </tr>
            );
        }
        return this.createTable(list);
    }

    // Loading中の空行DOMを生成
    createEmptyItems = () => {
        let list = [];
        for (let i = 0; i < this.state.itemsCountPerPage; i++) {
            const item = (
                <tr key={i} onClick={e => { this.selectedIdx = i; }}>
                    <td className="dataTable-body">　</td>
                    <td className="dataTable-body">　</td>
                </tr>
            );
            list.push(item);
        }

        return this.createTable(list);
    }

    // ポップアップ表示用のDOMを生成
    createFrame = (disp, table, pageSettings) => {
        return (
            <React.Fragment>
                <div className="l-modal_overlay is-active">
                    <div className="modal_overlay"></div>
                </div>
                <div className="l-modalBox">
                    <div className="modalBox">
                        <Pagination pageSettings={pageSettings} />
                        <div className="dataList dataList__accordion is-open">
                            <div className="dataList-header">
                                <div className="dataList-header-title">組織一覧</div>
                            </div>
                            {table}
                        </div>
                        <Pagination pageSettings={pageSettings} />
                        <ul className="l-baseAction l-baseAction__rightTwo">
                            <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleSelect}>選択</Link></li>
                            <li><Link to="" aria-label="link" className="baseAction" onClick={this.handleCancel}>キャンセル</Link></li>
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(PopupOrgList);