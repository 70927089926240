import { ORGANIZATION_POPUP as OP } from "constants/contractEditPageInfo";
import React from "react";
import { Link } from "react-router-dom";
import Input from "../../input/inputNormal";
import InputOrg from "../../input/inputOrg";
import Select from "../../input/inputSelect";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 売主様 */
export default class Block9 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010109",
        };
    }

    /** 削除ボタンのハンドラ */
    handleDeleteTOR00072 = e => {
        this.handleDelete(e, "TOR00072_ARR");
    }

    /** 追加ボタンのハンドラ */
    handleAddTOR00072 = e => {

        const addObj = {
            TOR00072: undefined, // 売主 氏名
            TOR00073: undefined, // 売主 住所
            TOR00076: undefined, // 売主 電話番号
        };

        this.handleAdd(e, "TOR00072_ARR", addObj);
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 動的項目の共通パラメータ
        const arrayName = "TOR00072_ARR"; // 動的項目の代表名
        const paramsForDynamic = {
            ...params,
            arrayName: arrayName,
            mode: "dynamic",
        };

        // ページが持っている動的項目の値を取得する
        const data = this.props.pageState[arrayName];

        let items = [];

        // 2行目以降の繰り返し部分の出力
        data.forEach((obj, idx) => {
            // 1行目は別でDOMを生成しているので飛ばす
            if (idx === 0) return;

            items.push(
                <React.Fragment key={idx}>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 is-emphasis" rowSpan="2">
                            <div className="dataTable-title-vr">{idx + 1}</div>
                            <div className="dataTable-title-vr">
                                <Link to="" id={`LINK_DEL_TOR00072_ARR_${idx}`} aria-label="link" data-idx={idx} data-array-name={arrayName} className="baseAction baseAction--minus baseAction__w40" onClick={this.handleDeleteTOR00072}>
                                    <span data-idx={idx} className="baseAction-text">削除</span>
                                </Link>
                            </div>
                        </th>
                        <th className="dataTable-title dataTable-title__w120">氏名</th>
                        <td className="dataTable-body" colSpan="4">
                            <div className="dataTableUnit">
                                <Input idx={idx} id={`ID_TOR00072_${idx}`} name="TOR00072" value={obj.TOR00072} {...paramsForDynamic} length="w272" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120">住所</th>
                        <td className="dataTable-body dataTable-body__w444" colSpan="2">
                            <div className="dataTableUnit">
                                <Input idx={idx} id={`ID_TOR00073_${idx}`} name="TOR00073" value={obj.TOR00073} {...paramsForDynamic} length="wFull" />
                            </div>
                        </td>
                        <th className="dataTable-title dataTable-title__w120">電話番号</th>
                        <td className="dataTable-body">
                            <div className="dataTableUnit">
                                <Input idx={idx} id={`ID_TOR00076_${idx}`} name="TOR00076" value={obj.TOR00076} {...paramsForDynamic} length="wFull" />
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            );
        });

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">売主様</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 is-emphasis" rowSpan="6">1</th>
                                <th className="dataTable-title dataTable-title__w120">個人/法人</th>
                                <td className="dataTable-body" colSpan="4">
                                    <Select id="ID_TOR00070" name="TOR00070" value={this.props.pageState.TOR00070} items={this.getSelectbox(this.props.selectboxData.MEI00005)} {...params} length="w272" />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">氏名</th>
                                <td className="dataTable-body dataTable-body__w297">
                                    <div className="dataTableUnit">
                                        <Input idx={0} id={`ID_TOR00072_${0}`} name="TOR00072" value={data[0].TOR00072} {...paramsForDynamic} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">ふりがな</th>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00071" name="TOR00071" value={this.props.pageState.TOR00071} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" rowSpan="2">住所</th>
                                <td className="dataTable-body dataTable-body__w444" colSpan="4">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">〒</div>
                                        <Input id="ID_TOR00074_1" name="TOR00074_1" value={this.props.pageState.TOR00074_1} {...params} length="w80" />
                                        <div className="dataTableUnit-text">ー</div>
                                        <Input id="ID_TOR00074_2" name="TOR00074_2" value={this.props.pageState.TOR00074_2} {...params} length="w80" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <Input idx={0} id={`ID_TOR00073_${0}`} name="TOR00073" value={data[0].TOR00073} {...paramsForDynamic} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">電話番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input idx={0} id={`ID_TOR00076_${0}`} name="TOR00076" value={data[0].TOR00076} {...paramsForDynamic} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">転居先住所</th>
                                <td className="dataTable-body dataTable-body__w444" colSpan="2">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00077" name="TOR00077" value={this.props.pageState.TOR00077} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">新電話番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00078" name="TOR00078" value={this.props.pageState.TOR00078} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">法人の場合<br />担当者名</th>
                                <td className="dataTable-body dataTable-body__w292">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00079" name="TOR00079" value={this.props.pageState.TOR00079} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">部署名</th>
                                <td className="dataTable-body dataTable-body__w292" colSpan="2">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00080" name="TOR00080" value={this.props.pageState.TOR00080} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>

                            {items}

                            <tr>
                                <td className="dataTable-body dataTable-body__center" colSpan="6">
                                    <Link to="" id="LINK_ADD_TOR00072_ARR" aria-label="link" data-array-name={arrayName} className="baseAction baseAction--plus baseAction__w40" onClick={this.handleAddTOR00072}>
                                        <span className="baseAction-text">追加</span>
                                    </Link>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title is-emphasis" colSpan="6">仲介者</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120">商号</th>
                                <td className="dataTable-body dataTable-body__w356" colSpan="2">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00087" name="TOR00087" value={this.props.pageState.TOR00087} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">支店/組織</th>
                                <td className="dataTable-body dataTable-body__w355" colSpan="2">
                                    <div className="dataTableUnit">
                                        <InputOrg id="ID_TOR00088" name="TOR00088" value={this.props.pageState.TOR00088} {...params} from={OP.SIT00002} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title">住所</th>
                                <td className="dataTable-body" colSpan="5">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00089" name="TOR00089" value={this.props.pageState.TOR00089} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title">主担当者名</th>
                                <td className="dataTable-body dataTable-body__w730" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputOrg id="ID_TOR00090" name="TOR00090" value={this.props.pageState.TOR00090} {...params} from={OP.SIT00004} />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120">電話番号</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00091" name="TOR00091" value={this.props.pageState.TOR00091} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >
        );
    }
}