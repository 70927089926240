import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { amplify_initialize } from "./config/amplify";
import store from "./redux/stores";
import * as serviceWorker from "./serviceWorker";


amplify_initialize();

const LOG_LEVEL_DEBUG = 1;
// const LOG_LEVEL_INFO = 2;
const LOG_LEVEL = LOG_LEVEL_DEBUG;

console.debugger = (...args) => {
    if (LOG_LEVEL <= LOG_LEVEL_DEBUG) {
        console.log(...args);
    }
};

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
