import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import React from "react";
import InputCheckbox from "../../input/inputCheckbox";
import InputDate from "../../input/inputDate";
import Input from "../../input/inputNormal";

/** 計画修繕積立金等に関する事項 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021001",
        };
        // 入力値のチェック用オブジェクトを生成
        this.isSameJYU01301 = this.props.pageState.JYU01301;
        this.isSameJYU01302 = this.props.pageState.JYU01302;
        this.isSameJYU01303 = this.props.pageState.JYU01303;
        this.isSameJYU01304 = this.props.pageState.JYU01304;
        this.isSameJYU01305 = this.props.pageState.JYU01305;
    }

    render() {

        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 非活性の設定
        let JYU01302_disabled = this.props.pageState.JYU01302;
        let JYU01303_disabled = this.props.pageState.JYU01303;
        let JYU01304_disabled = this.props.pageState.JYU01304;
        let JYU01305_disabled = this.props.pageState.JYU01305;

        // 挿入値の設定
        const AMOUNT_IN_ARREARS_ZERO = "金0";
        const HYPHEN_TEXT = "--";
        const HYPHEN_TEXT_2BYTE = "―";

        if (!this.isSameJYU01301 && this.props.pageState.JYU01301) {
            this.isSameJYU01301 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00939", HYPHEN_TEXT));
        }

        if (this.isSameJYU01301 && !this.props.pageState.JYU01301) {
            this.isSameJYU01301 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00939", ""));
        }

        if (!this.isSameJYU01302 && this.props.pageState.JYU01302) {
            this.isSameJYU01302 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00943", AMOUNT_IN_ARREARS_ZERO));
        }

        if (this.isSameJYU01302 && !this.props.pageState.JYU01302) {
            this.isSameJYU01302 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00943", ""));
        }

        if (!this.isSameJYU01303 && this.props.pageState.JYU01303) {
            this.isSameJYU01303 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00955", HYPHEN_TEXT_2BYTE));
        }

        if (this.isSameJYU01303 && !this.props.pageState.JYU01303) {
            this.isSameJYU01303 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00955", ""));
        }

        if (!this.isSameJYU01304 && this.props.pageState.JYU01304) {
            this.isSameJYU01304 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00961", AMOUNT_IN_ARREARS_ZERO));
        }

        if (this.isSameJYU01304 && !this.props.pageState.JYU01304) {
            this.isSameJYU01304 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00961", ""));
        }

        if (!this.isSameJYU01305 && this.props.pageState.JYU01305) {
            this.isSameJYU01305 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU00964", HYPHEN_TEXT_2BYTE));
        }

        if (this.isSameJYU01305 && !this.props.pageState.JYU01305) {
            this.isSameJYU01305 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00964", ""));
        }

        // 斜線対応と非活性の設定
        let JYU01301_disabled = undefined;
        let DomDiagonalLine = undefined;
        if (this.onloadFlg && this.props.pageState.JYU01301) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_JYU01301");
            JYU01301_disabled = true;
        }


        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU01301:AR_JYU01301-1,AR_JYU01301-2,AR_JYU01301-3")}>
                <div className="dataList-header">
                    <div className="dataList-header-title">計画修繕積立金等に関する事項</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">計画修繕<br />積立金制度</th>
                                <td className="dataTable-body dataTable-body__w476">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00938" name="JYU00938" checked={this.props.pageState.JYU00938} {...params} />
                                                <span className="checkBox-text">1.有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01301" name="JYU01301" checked={this.props.pageState.JYU01301} {...params} />
                                                <span className="checkBox-text">2.無　※チェックした場合は、斜線を引きます。</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">規約の定め</th>
                                <td className="dataTable-body dataTable-body__w240" colSpan="4">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">別添管理規約</div>
                                        <Input id="ID_JYU00939" name="JYU00939" value={this.props.pageState.JYU00939} {...params} length="w80" disabled={JYU01301_disabled} />
                                        <div className="dataTableUnit-text">条に記載されています。</div>
                                    </div>
                                </td>
                            </tr>
                            {DomDiagonalLine}
                            <tr id="AR_JYU01301-1">
                                <th className="dataTable-title dataTable-title__center">修繕積立金</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-text">月額</div>
                                                <Input id="ID_JYU00940" name="JYU00940" value={this.props.pageState.JYU00940} {...params} length="w145" />
                                                <div className="dataTableUnit-text">円</div>
                                                <div className="dataTableUnit-text">（</div>
                                                <InputDate eraOnly id="ID_JYU00941" name="JYU00941" value={this.props.pageState.JYU00941} {...params} length="w112" />
                                                <div className="dataTableUnit-text">現在</div>
                                                <div className="dataTableUnit-text">）</div>
                                                <Input id="ID_JYU01664" name="JYU01664" value={this.props.pageState.JYU01664} {...params} length="wFlexFull" />
                                            </div>
                                        </div>
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-text">当該専有部分に係る滞納の有無</div>
                                                <div className="dataTableUnit-input">
                                                    <ul className="l-checkBox">
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU00942" name="JYU00942" checked={this.props.pageState.JYU00942} {...params} />
                                                                <span className="checkBox-text">1.有</span>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU01302" name="JYU01302" checked={this.props.pageState.JYU01302} {...params} />
                                                                <span className="checkBox-text">2.無</span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-text">滞納額</div>
                                                <Input id="ID_JYU00943" name="JYU00943" value={this.props.pageState.JYU00943} {...params} length="w145" disabled={JYU01302_disabled} />
                                                <div className="dataTableUnit-text">円</div>
                                                <div className="dataTableUnit-text">（</div>
                                                <InputDate noEra id="ID_JYU00951" name="JYU00951" value={this.props.pageState.JYU00951} {...params} length="w112" />
                                                <div className="dataTableUnit-text">現在</div>
                                                <div className="dataTableUnit-text">）</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr id="AR_JYU01301-2">
                                <th className="dataTable-title dataTable-title__center" colSpan="2" rowSpan="2">すでに積立てられている額<br />（明確に会計が区分されている修繕積立金）</th>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00952" name="JYU00952" value={this.props.pageState.JYU00952} {...params} length="w145" />
                                        <div className="dataTableUnit-text">円</div>
                                        <div className="dataTableUnit-text">（</div>
                                        <InputDate eraOnly id="ID_JYU00953" name="JYU00953" value={this.props.pageState.JYU00953} {...params} length="w112" />
                                        <div className="dataTableUnit-text">現在</div>
                                        <div className="dataTableUnit-text">）</div>
                                    </div>
                                </td>
                            </tr>
                            <tr id="AR_JYU01301-3">
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-text">一棟の建物（団地）に係る滞納に関する情報の開示</div>
                                                <div className="dataTableUnit-input">
                                                    <ul className="l-checkBox">
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU00954" name="JYU00954" checked={this.props.pageState.JYU00954} {...params} />
                                                                <span className="checkBox-text">1.有</span>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU01303" name="JYU01303" checked={this.props.pageState.JYU01303} {...params} />
                                                                <span className="checkBox-text">2.無</span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-text">滞納額</div>
                                                <Input id="ID_JYU00955" name="JYU00955" value={this.props.pageState.JYU00955} {...params} length="w145" disabled={JYU01303_disabled} />
                                                <div className="dataTableUnit-text">円</div>
                                                <div className="dataTableUnit-text">（</div>
                                                <InputDate eraOnly id="ID_JYU00956" name="JYU00956" value={this.props.pageState.JYU00956} {...params} length="w112" />
                                                <div className="dataTableUnit-text">現在</div>
                                                <div className="dataTableUnit-text">）</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="2">通常の管理費</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-text">月額</div>
                                                <Input id="ID_JYU00958" name="JYU00958" value={this.props.pageState.JYU00958} {...params} length="w145" />
                                                <div className="dataTableUnit-text">円</div>
                                                <div className="dataTableUnit-text">（</div>
                                                <InputDate eraOnly id="ID_JYU00959" name="JYU00959" value={this.props.pageState.JYU00959} {...params} length="w112" />
                                                <div className="dataTableUnit-text">現在</div>
                                                <div className="dataTableUnit-text">）</div>
                                                <Input id="ID_JYU01665" name="JYU01665" value={this.props.pageState.JYU01665} {...params} length="wFlexFull" />
                                            </div>
                                        </div>
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-text">当該専有部分に係る滞納の有無</div>
                                                <div className="dataTableUnit-input">
                                                    <ul className="l-checkBox">
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU00960" name="JYU00960" checked={this.props.pageState.JYU00960} {...params} />
                                                                <span className="checkBox-text">1.有</span>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU01304" name="JYU01304" checked={this.props.pageState.JYU01304} {...params} />
                                                                <span className="checkBox-text">2.無</span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-text">滞納額</div>
                                                <Input id="ID_JYU00961" name="JYU00961" value={this.props.pageState.JYU00961} {...params} length="w145" disabled={JYU01304_disabled} />
                                                <div className="dataTableUnit-text">円</div>
                                                <div className="dataTableUnit-text">（</div>
                                                <InputDate eraOnly id="ID_JYU00962" name="JYU00962" value={this.props.pageState.JYU00962} {...params} length="w112" />
                                                <div className="dataTableUnit-text">現在</div>
                                                <div className="dataTableUnit-text">）</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableMultiple">
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-text">一棟の建物（団地）に係る滞納に関する情報の開示</div>
                                                <div className="dataTableUnit-input">
                                                    <ul className="l-checkBox">
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU00963" name="JYU00963" checked={this.props.pageState.JYU00963} {...params} />
                                                                <span className="checkBox-text">1.有</span>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="checkBox">
                                                                <InputCheckbox id="ID_JYU01305" name="JYU01305" checked={this.props.pageState.JYU01305} {...params} />
                                                                <span className="checkBox-text">2.無</span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTableMultiple-item">
                                            <div className="dataTableUnit">
                                                <div className="dataTableUnit-text">滞納額</div>
                                                <Input id="ID_JYU00964" name="JYU00964" value={this.props.pageState.JYU00964} {...params} length="w145" disabled={JYU01305_disabled} />
                                                <div className="dataTableUnit-text">円</div>
                                                <div className="dataTableUnit-text">（</div>
                                                <InputDate eraOnly id="ID_JYU00965" name="JYU00965" value={this.props.pageState.JYU00965} {...params} length="w112" />
                                                <div className="dataTableUnit-text">現在</div>
                                                <div className="dataTableUnit-text">）</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}