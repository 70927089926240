export function saveJwkInfo(jwk, publicKey) {
    return {
        type: "SAVE_JWK_INFO",
        jwkInfo: {
            jwk: jwk,
            pem: publicKey
        }
    };
}

export function saveIdToken(idToken) {
    return {
        type: "SAVE_ID_TOKEN",
        idToken: idToken
    };
}
