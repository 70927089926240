import BaseGrid from "components/common/frame/baseGrid";
import { RegisterButtonRecord } from "components/common/parts/buttonRecord";
import { InputCheckBoxRecord, InputRadioBoxRecord, InputSelectBoxRecord, InputTextRecord } from "components/common/parts/inputRecord";
import * as APP from "config/app";
import { AUTHORITY_FRK, AUTHORITY_FRK_MUFG } from "constants/authority";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import * as apiParamConverter from "util/apiParamConverter";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";
import Validation from "util/validate";

/** 従業員情報メンテナンス画面（FRK書式） */
class EmployeeInfoMaintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isStopped: false };

        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
        // 連続APIコール防止用のステータス
        // stateで管理すると、setSatateする度にrenderが走る為、クラス変数で定義
        this.apiCalling = false; // true: 登録 or 更新APIコール中


        // 画面から入力される値の情報
        this.params = {
            userName: {
                label: "氏名",
                validate: this.validation.notEmpty,
                apiParamKey: "user_name"
            },
            userNameKana: {
                label: "かな",
                validate: this.validation.notEmpty,
                apiParamKey: "user_name_kana"
            },
            employeeNumber: {
                label: "従業員番号",
                validate: this.validation.notEmpty,
                apiParamKey: "employee_number"
            },
            email: {
                label: "メールアドレス",
                validate: this.validation.notEmptyAndIsEmail,
                apiParamKey: "email"
            },
            agencyBranchId: {
                label: "支店／組織",
                validate: this.validation.notEmptyAndIsNumber,
                apiParamKey: "agency_branch_id"
            },
            realEstateNotaryNumber: {
                label: "宅建士登録番号",
                validate: this.validation.noCheck,
                apiParamKey: "real_estate_notary_number"
            },
            agencyUserId: {
                label: "ログイン・ユーザーID",
                validate: this.validation.notEmpty,
                apiParamKey: "agency_user_id"
            },
            authority: {
                label: "権限",
                validate: this.validation.notEmpty,
                apiParamKey: "authority"
            },
            isStopped: {
                label: "アカウントを停止",
                apiParamKey: "is_stopped"
            }
        };
    }

    componentDidMount() {
        document.title = pageInfo.EMPLOYEE_INFO_MAINTENANCE.title;

        // 支店リスト情報取得APIを呼出し、stateに更新する
        this.requestBranchList();
    }

    componentDidUpdate() {
        // パスパラメータがあり、stateが取得APIのレスポンスデータで更新されていない場合、
        // 取得APIを呼出し、レスポンスデータを入力ボックスの入力値を更新する
        // また、初期ユーザで1度も更新を行っていない場合、支店・組織が入っていおらずデフォルト値を手動で設定する必要があるので、
        // 支店・組織情報の取得が完了するのも待つ
        if (this.props.match.params.id && this.state.agencyBranches !== undefined && this.state.agencyUserId === undefined) {
            this.requestEmployee(this.props.match.params.id);
        }
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputText = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** セレクトボックスの入力値をstateに格納 */
    handleChangeInputSelectBox = e => {
        const target = e.target;
        this.setState({ [target.name]: Number(target.value) });
    }

    /** チェックボックスの入力値をstateに格納 */
    handleChangeInputCheckBox = e => {
        const target = e.target;
        this.setState(state => ({ [target.name]: !state[target.name] }));
    }

    /** 入力ボックスの入力値を空文字チェック */
    handleValidateInputText = e => {
        const target = e.target;
        const param = this.params[target.name];
        param.validate(target.name, target.value);
        this.setState({});  // 再renderする為の空セット
    }

    /** 登録ボタンのハンドラー */
    handleRegisterEmployee = e => {
        e.preventDefault();

        // 全入力項目のチェックし、エラーメッセージがない場合、後続処理（登録/更新APIの呼出し）をする
        if (!this.validation.validateTotalInputText(this.params, this.state)) {
            const requestBody = apiParamConverter.convertToApiReqCase(this.params, this.state);
            this.registerEmployee(requestBody);
        } else {
            this.setState({});
        }
    }

    render() {
        // パスパラメータが指定されており、stateに値が入っていない場合、nullを返す
        // ※componentDidMount、componentDidUpdateでリクエストを行い、レスポンスが入ってくるのを待つ
        if (this.props.match.params.id && !this.state.agencyUserId) {
            return null;
        }

        // Inputレコードに関する、タグの属性情報
        const bodySetting = { colSpan: "3" };
        const inputSetting = { onChange: this.handleChangeInputText, onBlur: this.handleValidateInputText };
        const titleSetting = { className: "dataTable-title dataTable-title__w120" };

        // 支店リスト
        const agencyBranches = this.state.agencyBranches;
        const branchList = agencyBranches
            ? agencyBranches.map(agencyBranche => ({ value: agencyBranche.id, display: agencyBranche.branchName }))
            : [];

        // アカウントを停止するを更新時のみ表示する
        const stopAccountDOM = this.props.match.params.id
            ? (
                <tr>
                    <InputCheckBoxRecord titleName={this.params.isStopped.label} checkboxText="する"
                        inputData={{ name: "isStopped", checked: this.state.isStopped }}
                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={{ onChange: this.handleChangeInputCheckBox }} />
                </tr>
            )
            : null;

        // 更新画面の場合、ログインユーザIDを入力不可にする
        const inputAgencyUserId = this.props.match.params.id ? true : false;

        // ボタンの表示制御
        // APIコール中は、非アクティブにする
        const buttonDom = this.apiCalling
            ? (<RegisterButtonRecord inActive registerSetting={{ onClick: e => { e.preventDefault(); } }} />)
            : (<RegisterButtonRecord registerSetting={{ onClick: this.handleRegisterEmployee }} />);

        let radioDataList = [];

        // MUFG様向けと他社様向けの表示分岐
        if (APP.OPERATOR_AGENCY_MUFG.includes(this.props.agencyId.toString())) {
            radioDataList = [AUTHORITY_FRK_MUFG.GENERAL, AUTHORITY_FRK_MUFG.MANAGER, AUTHORITY_FRK_MUFG.AREA_MANAGER, AUTHORITY_FRK_MUFG.CONTRACT_EXAMINATION];
        } else {
            radioDataList = [AUTHORITY_FRK.GENERAL, AUTHORITY_FRK.MANAGER, AUTHORITY_FRK.AREA_MANAGER, AUTHORITY_FRK.CONTRACT_EXAMINATION];
        }

        return (
            <BaseGrid>
                <div className="dataList dataList__accordion is-open">
                    <div className="dataList-header">従業員情報メンテナンス</div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <InputTextRecord titleName={this.params.userName.label}
                                        inputData={{ name: "userName", value: this.state.userName || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.userName || ""} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.userNameKana.label}
                                        inputData={{ name: "userNameKana", value: this.state.userNameKana || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.userNameKana || ""} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.employeeNumber.label}
                                        inputData={{ name: "employeeNumber", value: this.state.employeeNumber || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.employeeNumber || ""} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.email.label}
                                        inputData={{ name: "email", value: this.state.email || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.email || ""} />
                                </tr>

                                <tr>
                                    <InputSelectBoxRecord titleName={this.params.agencyBranchId.label}
                                        inputData={{ name: "agencyBranchId", value: this.state.agencyBranchId || "" }}
                                        optionsDataList={branchList}
                                        titleSetting={titleSetting} bodySetting={bodySetting}
                                        inputSetting={{ onChange: this.handleChangeInputSelectBox, onBlur: this.handleValidateInputText }}
                                        errorMessage={this.validation.errorMessage.agencyBranchId || ""} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.realEstateNotaryNumber.label}
                                        inputData={{ name: "realEstateNotaryNumber", value: this.state.realEstateNotaryNumber || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.realEstateNotaryNumber || ""} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.agencyUserId.label}
                                        textOnly={inputAgencyUserId}
                                        inputData={{ name: "agencyUserId", value: this.state.agencyUserId || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.agencyUserId || ""} />
                                </tr>

                                <tr>
                                    <InputRadioBoxRecord titleName={this.params.authority.label}
                                        inputData={{ name: "authority", value: this.state.authority || "" }}
                                        radioDataList={radioDataList}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.authority || ""} />
                                </tr>

                                {stopAccountDOM}

                            </tbody>
                        </table>
                    </div>
                </div>

                {buttonDom}

                <div className="l-pageAction l-pageAction--back">
                    <Link to={pageInfo.EMPLOYEE_INFO_LIST_FRK.path} aria-label="link" className="pageAction">一覧へもどる</Link>
                </div>
            </BaseGrid>
        );
    }

    /** 支店一覧（セレクト用）取得APIコール、state更新 */
    requestBranchList = () => {
        awsReq.get(
            AWS.ENDPOINT.PATHS.BRANCHAND_LIST,
            response => {
                const branchList = convert.convertToFlatCamelCase(response.data);
                const empty = [{ id: "", branchName: "　　　　" }]; // 支店組織が未登録だった時用（エラーメッセージを出す幅が足りないので無理やり伸ばす）
                this.setState({
                    agencyBranches: branchList.length !== 0 ? branchList : empty,
                    agencyBranchId: branchList.length !== 0 ? branchList[0].id : "",
                });
            },
            this.errorCallback,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    /** 従業員情報取得APIコール、state更新 */
    requestEmployee = id => {
        awsReq.get(
            AWS.ENDPOINT.PATHS.EMPLOYEE_DETAIL.replace(":id", id),
            response => {
                this.setState(convert.convertToFlatCamelCase(response.data));

                // レスポンスの支店・組織がnullの場合（初期ユーザで1度も更新していないユーザ）、
                // 取得している支店・組織情報の最初のモノをとりあえあず設定する
                if (this.state.agencyBranchId === null) {
                    this.setState({ agencyBranchId: this.state.agencyBranches[0].id });
                }
            },
            this.errorCallback,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    /** モードに応じて、従業員情報を登録、更新する */
    registerEmployee = requestBody => {
        this.apiCalling = true;
        this.setState({});

        // 宅建士登録番号に値が入っていない場合、空文字列を送る
        requestBody.real_estate_notary_number = this.state.realEstateNotaryNumber || "";

        if (this.props.match.params.id) {
            // 更新モード
            // 正常なAPIレスポンスが返却された後、再度、取得APIをコールする
            const successCallback = () => {
                this.props.history.push(pageInfo.EMPLOYEE_INFO_MAINTENANCE_UPDATE_FRK.path.replace(":id", this.props.match.params.id));
                this.apiCalling = false;
                this.setState({ agencyUserId: undefined });
            };

            awsReq.put(AWS.ENDPOINT.PATHS.EMPLOYEE_DETAIL_SYS.replace(":id", this.props.match.params.id),
                successCallback,
                this.errorCallback,
                requestBody,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );

        } else {
            // 登録モード
            // 正常なAPIレスポンスが返却された後、APIのレスポンスのLocationヘッダ末尾の文字列（ID部分）を取得し、このIDをパスパラメータとして遷移する
            const successCallback = response => {
                const id = response.headers["location"].split("/").slice(-1)[0];
                this.props.history.push(pageInfo.EMPLOYEE_INFO_MAINTENANCE_UPDATE_FRK.path.replace(":id", id));
                this.apiCalling = false;
                this.setState({ agencyUserId: undefined });
            };

            awsReq.post(AWS.ENDPOINT.PATHS.EMPLOYEE,
                successCallback,
                this.errorCallback,
                requestBody,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }

    /** APIコール時のエラーコールバック関数 */
    errorCallback = error => {
        alert(ERROR_MESSAGE.SERVER_ERROR);
        this.apiCalling = false;
        this.setState({}); // 再renderする為に、stateを空更新
    };
}

const mapStateToProps = state => {
    return {
        agencyId: state.agencyId
    };
};

export default withRouter(connect(mapStateToProps)(EmployeeInfoMaintenance));
