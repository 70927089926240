import axios from "axios";
import jwt from "jsonwebtoken";
import jwkToPem from "jwk-to-pem";
import * as AWS from "../constants/aws";


/**
 * IDトークンからデコードされた状態でヘッダ部分のみ抽出する
 */
export const getHeader = idToken => {
    var a = jwt.decode(idToken, { complete: true });
    return a.header;
};

export const getKid = idToken => {
    return getHeader(idToken).kid;
};

/**
 * 指定されたユーザプール用のjwks取得する
 */
export const getJwks = async userPoolId => {
    let url = `https://cognito-idp.ap-northeast-1.amazonaws.com/${userPoolId}/.well-known/jwks.json`;
    let res = await axios.get(url);
    return res.data;
};

/**
 * IDトークンのkidに対応したJWKを取得する
 */
export const getJwk = async (idToken, userPoolId) => {
    let kid = getKid(idToken);

    let jwks = await getJwks(userPoolId);

    // IDトークンのkidと一致する方のjwkを採用する
    var jwk = {};
    for (var item of jwks.keys) {
        if (kid === item.kid) {
            jwk = item;
        }
    }
    return jwk;
};

/**
 * JWKから公開鍵を生成する
 */
export const generatePemFromJwk = jwk => {
    return jwkToPem(jwk);
};

/**
 * IDトークンのkidと一致するJWKと、そこから生成される公開鍵を取得する
 */
export const generateJwkPemForIdToken = async (idToken, userPoolId = AWS.COGNITO.USER_POOL_ID) => {
    var jwk = await getJwk(idToken, userPoolId);
    var pem = generatePemFromJwk(jwk);
    return { jwk: jwk, pem: pem };
};

/**
 * 引数のIDトークンとローカルストレージのIDトークンが存在しているかどうか
 *
 * true: 存在している、false: 存在していない
 */
export const existLocalStorage = () => {
    // CognitoIdentityServiceProvider.2bfjuetnn146shhiq8a4vllelc.LastAuthUser
    // CognitoIdentityServiceProvider.2bfjuetnn146shhiq8a4vllelc.hosoya.idToken

    var template = "CognitoIdentityServiceProvider." + AWS.COGNITO.USER_POOL_WEB_CLIENT_ID + ".";
    // 認証したユーザ名取得
    var name = localStorage.getItem(template + "LastAuthUser");
    // IDトークン取得
    var nowIdToken = localStorage.getItem(template + name + ".idToken");
    return nowIdToken !== null;
};

/**
 * IDトークンの検証処理
 */
export const checkIdToken = (idToken, alg, pem) => {
    try {
        // IDトークンの検証
        var ret = jwt.verify(idToken, pem, { algorithms: [alg], ignoreExpiration: true }, function (err, decodedToken) {
            // トークンの検証エラーが発生している場合、検証NG
            // ※改ざんの検証、期限切れの検証は実施してくれるので、発行者(iss)の検証だけ自力でやる

            // 改ざん、期限切れNGの場合
            if (err) {
                console.error(err.message);
                return false;
            }

            // 発行者（iss）がCognitoのエンドポイント＋自分のユーザプールIDであることの確認
            if (decodedToken.iss !== AWS.COGNITO.ENDPOINT_URL + AWS.COGNITO.USER_POOL_ID) {
                return false;
            }
            return true;
        });
        return ret;

        // 処理中に例外が発生した場合はNG
    } catch (err) {
        console.error(err);
        return false;
    }
};
