import BaseGrid from "components/common/frame/baseGrid";
import { InputSelectBoxRecord, InputTextRecord } from "components/common/parts/inputRecord";
import { LinkToOtherDomain } from "components/common/parts/link";
import * as AWS from "constants/aws";
import { CONTRACT_EDIT_INFO, CONTRACT_TYPE, CONTRACT_TYPE_ID, EDIT_AUTHORITY } from "constants/contractEditPageInfo";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import * as awsReq from "util/awsRequest";
import * as dateUtil from "util/dateUtil";
import Validation from "util/validate";
import EmployeeList from "./popup/popupEmployeeList";
import PastContractList from "./popup/popupPastContractList";
import ShareList from "./popup/popupShareList";

class ContractRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contractTypeId: CONTRACT_TYPE_ID.ID_0101, // 契約種別ID（初期値はセレクトボックスの最初のヤツ）
            buildingName: undefined, // 物件名
            documentNo: undefined, // 契約No.
            shareList: [], // 共有先の状態（共有先のリスト（初期登録なのでAPIからの取得は無い））
            shareListTmp: [], // 契約情報共有の確定が押下されるまでのテンポラリ用途
            pastContract: undefined, // 情報一覧(過去物件)参照情報
            popupShareListActive: false, // 共有のポップアップを表示する：true
            popupEmployeeListActive: false, // 従業員一覧のポップアップを表示する：true
            popupPastContractListActive: false, // 情報一覧(過去物件)参照のポップアップを表示する：true
            initialize: false, // 初期設定済みかどうか
        };

        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
        // 画面から入力される値の情報
        this.params = {
            buildingName: { validate: this.validation.notEmpty, },
            documentNo: { validate: this.validation.notEmpty, },
        };
    }
    componentDidMount() {
        document.title = pageInfo.CONTRACT_REGISTER.title;
        // 初期化処理（普通に画面遷移してきた時はこっち）
        this.initialize();
    }

    componentDidUpdate() {
        // 初期化処理（リロード時はこっち）
        this.initialize();
    }

    /** 初期設定する */
    initialize = () => {
        // ログインユーザのagencyUserId、userNameをReduxから取得し、担当営業として設定する
        // ※
        // this.props.agencyUserId はRedux管理の値だが、取得できるようになるタイミングがあるようなので、
        // componentDidUpdate()の中でやる。。
        if (!this.state.initialize && this.props.agencyUserId) {
            this.setState({
                initialize: true,
                shareList: [{ "agency_user_id": this.props.agencyUserId, "user_name": this.props.userName, "sharing_authority_id": EDIT_AUTHORITY.EIGYO },],
                shareListTmp: [{ "agency_user_id": this.props.agencyUserId, "user_name": this.props.userName, "sharing_authority_id": EDIT_AUTHORITY.EIGYO },],
            });
        }
    }

    /** 契約種別IDのハンドラ */
    handleContractTypeIdSelect = e => {
        this.setState({ contractTypeId: e.target.value });
    }

    handleBuildingNameInput = e => {
        this.setState({ buildingName: e.target.value });
    }

    handleDocumentNoInput = e => {
        this.setState({ documentNo: e.target.value });
    }

    /** 入力ボックスの入力値を空文字チェック */
    handleInputOnBlur = e => {
        const target = e.target;
        const param = this.params[target.name];
        param.validate(target.name, target.value);
        this.setState({});  // 再renderする為の空セット
    }

    /** 共有先の更新 */
    updateShareList = arrays => {
        this.setState({ shareList: arrays });
    }

    /** 共有先（テンポラリ）の更新 */
    updateShareListTmp = arrays => {
        let sorted = JSON.parse(JSON.stringify(arrays));
        sorted.sort((a, b) => {
            if (a.agency_user_id > b.agency_user_id) {
                return 1;
            }
            if (a.agency_user_id < b.agency_user_id) {
                return -1;
            }
            return 0;
        });
        this.setState({ shareListTmp: sorted });
    }

    /** 編集ボタンのハンドラ */
    handleEditBtn = e => {
        e.preventDefault();
        this.openPopupShareList();
    }

    /** 共有のポップアップを開く */
    openPopupShareList = e => {
        this.setState({ popupShareListActive: true });
    }

    /** 従業員一覧のポップアップを開く */
    openPopupEmployeeList = () => {
        this.setState({ popupEmployeeListActive: true });
    }

    /** 情報一覧(過去物件)参照のハンドラ */
    handlePastContractBtn = e => {
        e.preventDefault();
        this.openPopupPastContractList();
    }

    /** 情報一覧(過去物件)のポップアップを開く */
    openPopupPastContractList = () => {
        this.setState({ popupPastContractListActive: true });
    }

    /** 情報一覧(過去物件)の更新 */
    updatePastContract = (obj) => {
        this.setState({ pastContract: obj });
    }

    /** 共有のポップアップを閉じる */
    closePopupShareList = () => {
        this.setState({ popupShareListActive: false });
    }

    /** 従業員一覧のポップアップを閉じる */
    closePopupEmployeeList = () => {
        this.setState({ popupEmployeeListActive: false });
    }

    /** 情報一覧(過去物件)のポップアップを閉じる */
    closePopupPastContractList = () => {
        this.setState({ popupPastContractListActive: false });
    }

    handleCreateBtn = e => {
        e.preventDefault();
        if (this.validation.validateTotalInputText(this.params, this.state)) {
            this.setState({});
            return;
        }

        // ポップアップの中身を表示するためのAPIリクエスト
        /** 情報一覧(過去物件)取得 */
        if (!this.apicalling) {
            this.apicalling = true;

            let query = {};

            query.contract_type_id = this.state.contractTypeId; // 契約種別ID
            query.building_name = this.state.buildingName; // 物件名
            query.document_no = this.state.documentNo; // 契約No.

            // 共有先のパラメータ作成
            // ※APIから取得した結果から、user_nameを除いたものをパラメータとして使用する
            query.contract_sharing_authority_list = this.state.shareList.map(item => {
                return {
                    agency_user_id: item.agency_user_id,
                    sharing_authority_id: item.sharing_authority_id,
                };
            });

            // 情報一覧(過去物件)のパラメータ作成 ※指定されている場合のみ（undefinedの場合、パラメータに設定されない）
            query.past_property_id = this.state.pastContract ? this.state.pastContract.id : undefined;

            awsReq.post(
                AWS.ENDPOINT.PATHS.CONTRACTS,
                res => {
                    this.apicalling = false;
                    const id = res.headers["location"].split("/").slice(-1)[0];
                    this.props.history.push(
                        pageInfo.CONTRACT_EDIT.path
                            .replace(":contractId", id)
                            .replace(":pageId", CONTRACT_EDIT_INFO.PAGE_0101.id)
                        + '?param=' + dateUtil.tzStrToParm()
                    );
                },
                error => {
                    this.apicalling = false;
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                    this.setState({});
                },
                query,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
            this.setState({});
        }
    }

    /** ポップアップ表示中に背景を暗くする用 */
    createModelOverlayActiveDOM = () => {
        if (this.state.popupShareListActive ||
            this.state.popupEmployeeListActive ||
            this.state.popupPastContractListActive) {
            return (
                <div className="l-modal_overlay is-active">
                    <div className="modal_overlay"></div>
                </div>
            );
        }
        return null;
    }

    render() {
        // 共有先一覧を表示用に整形
        // ※ 名前のリストを生成し、
        let shareListDp = this.state.shareList.map(item => {
            return item.user_name;
        });
        // ※ 空白区切りで１つの文字列にする
        shareListDp = shareListDp.join(" ");

        let pastBuildingName = this.state.pastContract ? this.state.pastContract.building_name : "　";
        let pastDocumentNo = this.state.pastContract ? this.state.pastContract.document_no : "　";

        // POST中は契約情報作成ボタンを押せなくする
        const createParams = (!this.apicalling)
            ? { className: "baseAction baseAction--agree is-active", onClick: this.handleCreateBtn }
            : { className: "baseAction baseAction--agree is-active baseAction--inactive", onClick: e => e.preventDefault() };

        // セレクトリストの生成
        const contractTypes = CONTRACT_TYPE;
        let contractTypeList = contractTypes
            ? contractTypes.map(contractType => ({ value: contractType.contract_type_id, display: contractType.name }))
            : [];
        const titleSetting = { className: "dataTable-title dataTable-title__w120" };

        // バリデートエラー時のエラーメッセージの設定
        let inputBuildingNameSettings = {
            errorMessage: this.validation.errorMessage.buildingName,
            class: "dataTableUnit-input dataTableUnit-input__wFull",
        };
        if (this.validation.errorMessage.buildingName) {
            inputBuildingNameSettings.class = inputBuildingNameSettings.class + " is-error";
        }

        let inputDocumentNoSettings = {
            errorMessage: this.validation.errorMessage.documentNo,
            class: "dataTableUnit-input dataTableUnit-input__w201",
        };
        if (this.validation.errorMessage.documentNo) {
            inputDocumentNoSettings.class = inputDocumentNoSettings.class + " is-error";
        }

        return (
            <React.Fragment>
                <BaseGrid>
                    <ul className="l-dataList">
                        <li>
                            <div className="dataList dataList__accordion is-open">
                                <div className="dataList-header">契約種別選択</div>
                                <div className="dataList-body">
                                    <table className="dataTable">
                                        <tbody>
                                            <tr>
                                                {/* <th className="dataTable-title dataTable-title__w120">契約書式</th>
                                                <td colSpan="2" className="dataTable-body">
                                                    <div className="selectBox" value={this.state.contractTypeId} onChange={this.handleContractTypeIdSelect}>
                                                        <ContractTypeList />
                                                    </div>
                                                </td> */}
                                                <InputSelectBoxRecord titleName="契約書式"
                                                    inputData={{ name: "contractTypeId", value: this.state.contractTypeId || "" }}
                                                    optionsDataList={contractTypeList} titleSetting={titleSetting}
                                                    inputSetting={{ onChange: this.handleContractTypeIdSelect }} />
                                            </tr>
                                            <tr>
                                                <InputTextRecord
                                                    titleName="物件名"
                                                    titleSetting={{ className: "dataTable-title dataTable-title__w120" }}
                                                    bodySetting={{ colSpan: "2" }}
                                                    inputData={{ name: "buildingName", value: this.state.buildingName || "" }}
                                                    inputSetting={{ onChange: this.handleBuildingNameInput, onBlur: this.handleInputOnBlur }}
                                                    errorMessage={this.validation.errorMessage.buildingName || ""} />
                                            </tr>
                                            <tr>
                                                <InputTextRecord
                                                    titleName="契約No."
                                                    titleSetting={{ className: "dataTable-title dataTable-title__w120" }}
                                                    bodySetting={{ colSpan: "2" }}
                                                    inputData={{ name: "documentNo", value: this.state.documentNo || "" }}
                                                    inputSetting={{ className: "input__w201", onChange: this.handleDocumentNoInput, onBlur: this.handleInputOnBlur }}
                                                    lengthForErrMsg="w201"
                                                    errorMessage={this.validation.errorMessage.documentNo || ""} />
                                            </tr>
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w120">共有先</th>
                                                <td className="dataTable-body">{shareListDp}</td>
                                                <td className="dataTable-body dataTable-body__w110">
                                                    <Link to="" aria-label="link" className="baseAction" onClick={this.handleEditBtn}>編集</Link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="dataList dataList__accordion is-open">
                                <div className="dataList-header">情報一覧参照</div>
                                <div className="dataList-body">
                                    <table className="dataTable">
                                        <tbody>
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w120">物件名</th>
                                                <td colSpan="2" className="dataTable-body">{pastBuildingName}</td>
                                            </tr>
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w120">契約No.</th>
                                                <td className="dataTable-body">{pastDocumentNo}</td>
                                                <td className="dataTable-body dataTable-body__w110">
                                                    <Link to="" aria-label="link" className="baseAction" onClick={this.handlePastContractBtn}>検索</Link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </li>
                    </ul>
                    {/* 個人情報の取り扱いについて部分 */}
                    <div className="l-personalInfo">
                        <div className="personalInfo">
                            {/* 規約本文 */}
                            <PersonalInfo />

                            <div className="personalInfo-note">※弊社への個人情報提供に関するお客様の承諾を得ていることをご確認の上、契約情報作成へお進みください。</div>
                            <div className="personalInfo-action">
                                <ul className="l-baseAction l-baseAction__centerRightAction">
                                    <li><Link to="" aria-label="link" {...createParams}>個人情報の取り扱いに同意の上<br />契約情報作成</Link></li>
                                    <li><Link to={pageInfo.DASHBOARD.path} aria-label="link" className="baseAction">キャンセル</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* ポップアップ表示中に背景を暗くする用DOM */}
                    {this.createModelOverlayActiveDOM()}

                    {/* 共有先ポップアップ */}
                    <ShareList
                        shareList={this.state.shareList}
                        shareListTmp={this.state.shareListTmp}
                        active={this.state.popupShareListActive}
                        onClose={this.closePopupShareList}
                        onAdd={this.openPopupEmployeeList}
                        onCommit={this.updateShareList}
                        updateShareListTmp={this.updateShareListTmp}
                    />

                    {/* 従業員一覧ポップアップ */}
                    <EmployeeList
                        shareList={this.state.shareListTmp} // テンポラリの方の情報を参照する
                        active={this.state.popupEmployeeListActive}
                        onClose={this.closePopupEmployeeList}
                        onCommit={this.updateShareListTmp}  // 確定押下時にテンポラリの方の情報を更新する
                    />

                    {/* 情報一覧(過去物件)ポップアップ */}
                    <PastContractList
                        active={this.state.popupPastContractListActive}
                        onClose={this.closePopupPastContractList}
                        updatePastContract={this.updatePastContract}
                    />
                </BaseGrid >
            </React.Fragment >
        );
    }
}

const PersonalInfo = () => {
    return (
        <React.Fragment>
            <div className="personalInfo-header">個人情報の取り扱いについて</div>
            <div className="personalInfo-main" tabIndex="0">
                <div className="personalInfo-main-read">SREホールディングス株式会社は、契約書類作成システム（以下「本サービス」といいます）のご利用に際しご入力いただいたお客様の個人情報を以下の定めに従い取り扱います。</div>
                <div className="personalInfo-main-subTitle">1.個人情報の収集・利用・管理責任部署</div>
                <p className="personalInfo-main-text">SREホールディングス株式会社　個人情報担当窓口</p>
                <div className="personalInfo-main-subTitle">2.収集する個人情報</div>
                <p className="personalInfo-main-text">お名前、ご住所、電話番号、FAX番号、電子メールアドレス、物件情報、その他本サービスのご利用に際し入力された個人情報</p>
                <div className="personalInfo-main-subTitle">3.個人情報の利用目的</div>
                <ul className="personalInfo-main-sublist">
                    <li>(1)本サービスの提供、およびお客様からのお問い合わせへの対応</li>
                    <li>(2)不動産に関する売買仲介、賃貸管理、リフォーム・リノベーション、コンサルティング、その他これらに附帯または関連する事業におけるサービスの提供、およびお客様からのお問い合わせへの対応</li>
                    <li>(3)上記(1)または(2)の利用目的の達成に必要な範囲での、個人情報の第三者への提供</li>
                    <li>(4)上記(1)または(2)の事業におけるサービス提供のための郵便物、電話、電子メール等による営業活動、マーケティング活動（アンケートのお願い等）および顧客動向等の調査・分析</li>
                    <li>(5)取引先との各種連絡および資料の授受</li>
                </ul>
                <div className="personalInfo-main-subTitle">4.利用目的の範囲内での利用</div>
                <p className="personalInfo-main-text">当社は、あらかじめお客様ご本人の同意を得た場合および法令により認められた場合を除き、上記3の利用目的の達成に必要な範囲内でのみ個人情報を取り扱います。</p>
                <div className="personalInfo-main-subTitle">5.委託先の監督</div>
                <p className="personalInfo-main-text">当社は、上記3の利用目的の達成に必要な範囲内において、お預かりした個人情報の取り扱いを第三者に委託する場合があります。これらの第三者は、個人情報の取り扱いにつき、十分なセキュリティ水準にあることを確認の上選定し、契約等を通じて、必要かつ適切な監督を行います。</p>
                <div className="personalInfo-main-subTitle">6.個人情報の第三者提供について</div>
                <p className="personalInfo-main-text">
                    当社は、お客様の個人データを、法令の規定に基づく場合のほか、オプトアウト制度を利用して、上記3の利用目的の達成に必要な範囲で、第三者に提供することがあります（個々の案件に応じて個人情報保護委員会への届出が必要な場合は、同委員会へ届出を行います）。なお、お客様ご本人からお申し出があった場合、当該本人が識別される個人データの第三者への提供を停止いたしますので、下記の「個人情報に関するお問い合わせ窓口」までお申し出ください。
                </p>
                <div className="personalInfo-main-subTitle">(1)提供する個人情報の項目</div>
                <p className="personalInfo-main-text">お名前、ご住所、電話番号、FAX番号、電子メールアドレス、物件情報のほか、当社事業におけるサービスの提供に必要な最低限の項目</p>
                <div className="personalInfo-main-subTitle">(2)提供の手段・方法</div>
                <p className="personalInfo-main-text">書面、郵便、電話、FAX、電子メール、インターネット、広告媒体等</p>
                <div className="personalInfo-main-subTitle">(3)提供する第三者</div>
                <p className="personalInfo-main-text">
                    不動産売買・賃貸の仲介における契約相手方となる者、その見込者、他の宅地建物取引業者、インターネット広告掲載業者、不動産事業者団体、および指定流通機構(物件登録、成約通知および同機構のデータを利用しての営業、価格査定等を実施する場合)等<br />不動産取引の附帯業務における金融機関、司法書士、土地家屋調査士、不動産鑑定士、不動産管理会社、リフォーム会社、引越会社、保険会社等
                </p>
                <div className="personalInfo-main-subTitle">7.保有個人データに関する事項</div>
                <div className="personalInfo-main-subTitle">(1)当該個人情報取扱業者(当社)の名称</div>
                <p className="personalInfo-main-text">SREホールディングス株式会社</p>
                <div className="personalInfo-main-subTitle">(2)すべての保有個人データの利用目的</div>
                <ul className="personalInfo-main-sublist">
                    <li>a.本サービスの提供</li>
                    <li>b.不動産に関する売買仲介、賃貸管理、リフォーム・リノベーション、コンサルティング、その他これらに附帯または関連する事業におけるサービスの提供</li>
                    <li>c.上記a.またはb.の利用目的の達成に必要な範囲での個人情報の第三者への提供</li>
                    <li>d.上記a.またはb.の事業におけるサービス提供のための郵便物、電話、電子メール等による営業活動、マーケティング活動(アンケートのお願い等)および顧客動向等の調査・分析</li>
                    <li>e.取引先との各種連絡および資料の授受</li>
                </ul>
                <div className="personalInfo-main-subTitle">(3)開示等の求めに応じる手続き</div>
                <p className="personalInfo-main-text">当社保有個人データに関する利用目的の通知、開示、訂正等、利用停止等、および第三者提供停止に関するお問い合わせにつきましては、下記の窓口にご請求ください。手続きの詳細は、「<LinkToOtherDomain to="https://sre-group.co.jp/terms/privacy.html" target="_blank">保有個人データの開示等の求めに応じる手続き(別サイト)</LinkToOtherDomain>」をご確認ください。</p>
                <div className="personalInfo-main-subTitle">(4)保有個人データの取り扱いについて当社が設置する苦情・相談のお申出先窓口</div>
                <p className="personalInfo-main-text">当社保有個人データに関する苦情・相談につきましては、下記の窓口にて承ります。</p>
                <div className="personalInfo-main-subTitle">8.入力必須とさせていただいた情報のご提供がない場合について</div>
                <p className="personalInfo-main-text">入力必須とさせていただいた情報のご提供がない場合には、サービスの提供に応じられない場合がございますので、ご了承ください。</p>
                <div className="personalInfo-main-subTitle">9.個人情報に関するお問い合わせ窓口</div>
                <p className="personalInfo-main-text">当社保有個人データに関する苦情・相談などにつきましては、下記の窓口までご連絡ください。</p>
                <div className="personalInfo-main-text__mt10">SREホールディングス株式会社 個人情報担当窓口</div>
                <div className="personalInfo-main-text">住所: 〒107-0061 東京都港区北青山3丁目1-2　青山セント・シオンビル 2F</div>
                <div className="personalInfo-main-text">電話番号: 03-6274-6504受付時間: 10：00～18：30</div>
                <div className="personalInfo-main-text">※毎週土曜日・日曜日、ゴールデンウィーク、夏季休業、祝日・年末年始等の当社休日を除く</div>
                <div className="personalInfo-main-subTitle">10.個人情報の安全管理措置等について</div>
                <p className="personalInfo-main-text">お預かりした個人情報はその利用目的の範囲内で正確かつ最新の内容に保つよう努め、不正アクセス、改ざん、漏洩等から守るべく適切な安全管理措置を講じます。<br />また法令により例外として認められる場合を除き、ご本人の同意を得ることなく第三者への開示、提供は行いません。</p>
                <div className="personalInfo-main-subTitle">11.クッキー、ウェブビーコン、その他の類似技術の使用について</div>
                <p className="personalInfo-main-text">当社における、クッキー、ウェブビーコンその他の類似技術の使用については、「ウェブサイトでの情報の取り扱いについて(別サイト)」をご参照ください。</p>
                <div className="personalInfo-main-subTitle">12.その他、個人情報の取り扱い方針について</div>
                <p className="personalInfo-main-text">
                    当社が、ご本人への通知、ご利用約款への同意取得等の方法により、別途、利用目的等を個別に明示した場合には、その個別の利用目的等の内容が、上記の記載に優先いたしますので、ご了承ください。<br />当社の個人情報の取り扱い方針につきましては、「<LinkToOtherDomain to={"https://sre-group.co.jp/terms/privacy.html"} target={"_blank"}>プライバシーポリシー(別サイト)</LinkToOtherDomain>」をご参照ください。
                </p>
                <div className="personalInfo-main-text__mt10">以上</div>
                <div className="personalInfo-main-text__mt10">SREホールディングス株式会社</div>
                <div className="personalInfo-main-text">代表取締役社長</div>
                <div className="personalInfo-main-text">西山　和良</div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        userName: state.userName,
        agencyUserId: state.agencyUserId,
    };
};

export default withRouter(connect(mapStateToProps)(ContractRegister));