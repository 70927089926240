import { EMPLOYEE_POPUP as EP, GUARANTEE_ASSOCIATION_POPUP as GA, ORGANIZATION_POPUP as OP } from "constants/contractEditPageInfo";
import React from "react";
import Validation from "util/validate";
import InputCheckbox from "../../input/inputCheckbox";
import InputDate from "../../input/inputDate";
import InputEmployee from "../../input/inputEmployee";
import InputGua from "../../input/inputGua";
import Input from "../../input/inputNormal";
import InputTextOrg from "../../input/inputTextOrg";
import InputTextRemark from "../../input/inputTextRemark";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 他社情報 */
export default class Block5 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020105",
        };
        // アップロードするファイル名// 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        //JYU00054の入力上限判定
        const JYU00054LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00054_${this.props.displayTypeID}`, this.props.pageState.JYU00054);
        //JYU00059の入力上限判定
        const JYU00059LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00059_${this.props.displayTypeID}`, this.props.pageState.JYU00059);
        //JYU00060の入力上限判定
        const JYU00060LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00060_${this.props.displayTypeID}`, this.props.pageState.JYU00060);
        //JYU00066の入力上限判定
        const JYU00066LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00066_${this.props.displayTypeID}`, this.props.pageState.JYU00066);
        //JYU00065の入力上限判定
        const JYU00065LimitErrorMessage = this.validation.validateTextAreaWordCount(`JYU00065_${this.props.displayTypeID}`, this.props.pageState.JYU00065);

        // 斜線対応の設定
        let DomDiagonalLine = undefined;
        if (this.onloadFlg && this.props.pageState.JYU01571) {
            // 表示サイズを取得後、styleに設定する
            DomDiagonalLine = this.DiagonalLine("AR_JYU01571");
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`} onLoad={this.findDisplaySize("AR_JYU01571:AJYU01571-1")}>
                <div className="dataList-header">
                    <ul className="l-checkBox">
                        <li>
                            <label className="checkBox">
                                <InputCheckbox id="ID_JYU01571" name="JYU01571" checked={this.props.pageState.JYU01571} {...params} />
                                <span className="checkBox-text">斜線</span>
                            </label>
                        </li>
                    </ul>
                    <div className="dataList-header-title is-emphasis">他社情報</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                {DomDiagonalLine}
                <div id="AJYU01571-1" className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis">取引態様</td>
                            </tr>
                            <tr>
                                <td className="dataTable-body">
                                    <ul className="l-checkBox">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00046" name="JYU00046" checked={this.props.pageState.JYU00046} {...params} />
                                                <span className="checkBox-text">売買</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00047" name="JYU00047" checked={this.props.pageState.JYU00047} {...params} />
                                                <span className="checkBox-text">交換</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00048" name="JYU00048" checked={this.props.pageState.JYU00048} {...params} />
                                                <span className="checkBox-text">媒介</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00049" name="JYU00049" checked={this.props.pageState.JYU00049} {...params} />
                                                <span className="checkBox-text">代理</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00050" name="JYU00050" checked={this.props.pageState.JYU00050} {...params} />
                                                <span className="checkBox-text">売主</span>
                                            </label>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="4">宅地建物取引業者</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">免許証番号</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00052" name="JYU00052" value={this.props.pageState.JYU00052} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">免許年月日</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_JYU00053" name="JYU00053" value={this.props.pageState.JYU00053} {...params} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">主たる事務所<br />の所在地</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope disableReturn id="ID_JYU00054" name="JYU00054" value={this.props.pageState.JYU00054} placeholder={JYU00054LimitErrorMessage.inputLimitGuide} {...params} height="h92" />
                                        <p className="LengthOver-error">{JYU00054LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">商号</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00056" name="JYU00056" value={this.props.pageState.JYU00056} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">代表者氏名</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00057" name="JYU00057" value={this.props.pageState.JYU00057} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">従たる事務所</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope disableReturn id="ID_JYU00059" name="JYU00059" value={this.props.pageState.JYU00059} placeholder={JYU00059LimitErrorMessage.inputLimitGuide} {...params} height="h84" />
                                        <p className="LengthOver-error">{JYU00059LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">部署名</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark disableLoope disableReturn id="ID_JYU00060" name="JYU00060" value={this.props.pageState.JYU00060} placeholder={JYU00060LimitErrorMessage.inputLimitGuide} {...params} height="h84" />
                                        <p className="LengthOver-error">{JYU00060LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">責任者<br />（役職・氏名）</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00062" name="JYU00062" value={this.props.pageState.JYU00062} {...params} length="wFull" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="4">説明をする宅地建物取引士</td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">登録番号</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputEmployee id="ID_JYU00063" name="JYU00063" value={this.props.pageState.JYU00063} {...params} from={EP.EMP00006} />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">氏名</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputEmployee id="ID_JYU00064" name="JYU00064" value={this.props.pageState.JYU00064} {...params} from={EP.EMP00001} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">業務に従事<br />する事務所名</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextOrg id="ID_JYU00066" name="JYU00066" value={this.props.pageState.JYU00066} placeholder={JYU00066LimitErrorMessage.inputLimitGuide} {...params} height="h84" from={OP.SIT00002} />
                                        <p className="LengthOver-error">{JYU00066LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">住所</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextOrg id="ID_JYU00065" name="JYU00065" value={this.props.pageState.JYU00065} placeholder={JYU00065LimitErrorMessage.inputLimitGuide} {...params} height="h92" from={OP.SIT00003} />
                                        <p className="LengthOver-error">{JYU00065LimitErrorMessage.inputLimitErrorMessage}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">電話番号</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU00068" name="JYU00068" value={this.props.pageState.JYU00068} {...params} length="w135" from={OP.SIT00003} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <td className="dataTable-title is-emphasis" colSpan="4">供託所等に関する説明</td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="4">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00069" name="JYU00069" checked={this.props.pageState.JYU00069} {...params} />
                                        <span className="checkBox-text">（1）供託所に営業保証金を供託</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">供託所</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00070" name="JYU00070" value={this.props.pageState.JYU00070} {...params} from={GA.HOS00001} />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在地</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00071" name="JYU00071" value={this.props.pageState.JYU00071} {...params} from={GA.HOS00002} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="dataTable-body" colSpan="4">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00072" name="JYU00072" checked={this.props.pageState.JYU00072} {...params} />
                                        <span className="checkBox-text">（2）宅地建物取引業保証協会の社員</span>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240 dataTable-title__center" rowSpan="2">宅地建物取引業保証<br />協会の名 称・住所</th>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">保証協会<br />の名称</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00073" name="JYU00073" value={this.props.pageState.JYU00073} {...params} from={GA.HOS00001} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">保証協会<br />の所在地</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00074" name="JYU00074" value={this.props.pageState.JYU00074} {...params} from={GA.HOS00002} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240 dataTable-title__center" rowSpan="3">宅地建物取引業保証<br />保証協会の<br />事務所・所在地</th>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">保証協会<br />の名称</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU01429" name="JYU01429" value={this.props.pageState.JYU01429} {...params} from={GA.HOS00001} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">事務所<br />の名称</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00075" name="JYU00075" value={this.props.pageState.JYU00075} {...params} from={GA.HOS00003} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">事務所<br />の所在地</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00076" name="JYU00076" value={this.props.pageState.JYU00076} {...params} from={GA.HOS00004} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240 dataTable-title__center">弁済業務保証金<br />の供託所名称</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00078" name="JYU00078" value={this.props.pageState.JYU00078} {...params} from={GA.HOS00001} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w240 dataTable-title__center">弁済業務保証金<br />の供託所所在地</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <InputGua id="ID_JYU00079" name="JYU00079" value={this.props.pageState.JYU00079} {...params} from={GA.HOS00002} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};
