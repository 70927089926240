import { Footer } from "components/common/parts/footerUnauth";
import { FormLinkRecord } from "components/common/parts/formRecord";
import * as pageInfo from "constants/pageInfo";
import React from "react";

/** セッションタイム画面 */
export default function SessionTimeout(props) {
    document.title = pageInfo.SESSION_TIMEOUT.title;
    localStorage.clear();

    return (
        <main>
            <div className="l-loginField">
                <div className="l-loginField l-left_area"></div>
                <div className="l-loginField l-right_area">
                    <div className="l-loginField-component">
                        <form>
                            <div className="loginField loginField--main">
                                <div className="loginField-read">長時間操作が行われなかったため<br />自動的にログアウトされました。</div>
                                <FormLinkRecord to={pageInfo.LOGIN.path} isCenter>ログイン画面へ戻る</FormLinkRecord>
                            </div>
                        </form>
                    </div>
                    <Footer />
                </div>
            </div>
        </main>
    );
}
