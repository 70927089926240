import InputDate from "components/page/user/contract/edit/input/inputDate";
import Input from "components/page/user/contract/edit/input/inputNormal";
import Select from "components/page/user/contract/edit/input/inputSelect";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import { CONSUMPTION_TAX_RATE, FRACTION_DIGIT, FRACTION_PROCESS } from "constants/contractEditPageInfo";
import React from "react";
import { Link } from "react-router-dom";
import * as math from "util/math";

/** 取引報酬額 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010301",
        };

        this.TOR00278total = 0;
        this.TOR00279total = 0;
        this.TOR00280total = 0;
        this.TOR00281total = 0;
        this.TOR00282total = 0;
        this.TOR00283total = 0;
        this.TOR00284total = 0;
        this.TOR00285total = 0;

        this.taxRate = this.props.pageState.taxRate; // 税率リストを取得
        this.fractionProcessType = this.props.pageState.fractionProcessType; // 端数処理の仕方（四捨五入等）
        this.digit = this.props.pageState.digit; // 端数処理対象の桁数
    }

    /** 合計部分表示用の足し算関数 */
    sum = (a, b) => {
        return math.sum(a, b) + "";
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        // ページ情報の合計値部分を更新
        const arrays = [
            { k1: this.props.pageState.TOR00130, k2: this.props.pageState.TOR00132, totalThisKey: "TOR00278total", targetPageStateKey: "TOR00278" },
            { k1: this.props.pageState.TOR00134, k2: this.props.pageState.TOR00136, totalThisKey: "TOR00279total", targetPageStateKey: "TOR00279" },
            { k1: this.props.pageState.TOR00141, k2: this.props.pageState.TOR00143, totalThisKey: "TOR00280total", targetPageStateKey: "TOR00280" },
            { k1: this.props.pageState.TOR00145, k2: this.props.pageState.TOR00147, totalThisKey: "TOR00281total", targetPageStateKey: "TOR00281" },
            { k1: this.props.pageState.TOR00153, k2: this.props.pageState.TOR00155, totalThisKey: "TOR00282total", targetPageStateKey: "TOR00282" },
            { k1: this.props.pageState.TOR00157, k2: this.props.pageState.TOR00159, totalThisKey: "TOR00283total", targetPageStateKey: "TOR00283" },
            { k1: this.props.pageState.TOR00165, k2: this.props.pageState.TOR00167, totalThisKey: "TOR00284total", targetPageStateKey: "TOR00284" },
            { k1: this.props.pageState.TOR00169, k2: this.props.pageState.TOR00171, totalThisKey: "TOR00285total", targetPageStateKey: "TOR00285" },
        ];

        arrays.forEach(item => {
            const newValue = this.sum(item.k1, item.k2);
            // アップデートがあった時点で合計値を旧と比較し、差分がある場合は新しい方で更新する
            if (this[item.totalThisKey] !== newValue) {
                this[item.totalThisKey] = newValue;
                this.props.updatePageState({ [item.targetPageStateKey]: newValue }, false);
            }
        });
    }

    convNaNto0 = val => {
        return isNaN(val) ? 0 : val;
    }

    handleCalculationTaxTOR00128 = e => {
        e.preventDefault();

        const val = this.convNaNto0(this.props.pageState.TOR00126);
        const tax = this.convNaNto0(this.props.pageState.TOR00274);
        const ret = this.calculationTax(val, tax, this.props.taxRoundingMethod, this.props.digitsId);
        const num1 = isNaN(Number(this.props.pageState.TOR00126)) ? 0 : Number(this.props.pageState.TOR00126);
        const num2 = ret;

        this.props.updatePageState({ TOR00127: ret, TOR00128: num1 + num2 });
    }

    handleCalculationTaxTOR00139 = e => {
        e.preventDefault();

        const val = this.convNaNto0(this.props.pageState.TOR00137);
        const tax = this.convNaNto0(this.props.pageState.TOR00275);
        const ret = this.calculationTax(val, tax, this.props.taxRoundingMethod, this.props.digitsId);
        const num1 = isNaN(Number(this.props.pageState.TOR00137)) ? 0 : Number(this.props.pageState.TOR00137);
        const num2 = ret;

        this.props.updatePageState({ TOR00138: ret, TOR00139: num1 + num2 });
    }

    handleCalculationTaxTOR00150 = e => {
        e.preventDefault();

        const val = this.convNaNto0(this.props.pageState.TOR00148);
        const tax = this.convNaNto0(this.props.pageState.TOR00276);
        const ret = this.calculationTax(val, tax, this.props.taxRoundingMethod, this.props.digitsId);
        const num1 = isNaN(Number(this.props.pageState.TOR00148)) ? 0 : Number(this.props.pageState.TOR00148);
        const num2 = ret;

        this.props.updatePageState({ TOR00149: ret, TOR00150: num1 + num2 });
    }

    handleCalculationTaxTOR00162 = e => {
        e.preventDefault();

        const val = this.convNaNto0(this.props.pageState.TOR00160);
        const tax = this.convNaNto0(this.props.pageState.TOR00277);
        const ret = this.calculationTax(val, tax, this.props.taxRoundingMethod, this.props.digitsId);
        const num1 = isNaN(Number(this.props.pageState.TOR00160)) ? 0 : Number(this.props.pageState.TOR00160);
        const num2 = ret;

        this.props.updatePageState({ TOR00161: ret, TOR00162: num1 + num2 });
    }

    /**
     * value: 値
     * tax: 税率
     * fractionProcessType: 端数処理の方法、01: 切り上げ、 02: 切り捨て、03: 四捨五入
     * fraction: 処理する桁 例：01 => 小数第一位、02 => 1桁目
     */
    calculationTax = (value, tax, fractionProcessType, fraction) => {

        // 01: 小数点以下を計算, 02: 1円の桁を計算
        let _fraction = -1;
        if (fraction === FRACTION_DIGIT.DECIMAL_POINT) { _fraction = -1; }
        if (fraction === FRACTION_DIGIT.DECIMAL) { _fraction = 1; }

        // 切り上げ
        if (fractionProcessType === FRACTION_PROCESS.CEIL) {
            return math.ceil10(value * (tax / 100), _fraction);
        }
        // 切り捨て
        if (fractionProcessType === FRACTION_PROCESS.FLOOR) {
            return math.floor10(value * (tax / 100), _fraction);
        }
        // 四捨五入
        if (fractionProcessType === FRACTION_PROCESS.ROUND) {
            return math.round10(value * (tax / 100), _fraction);
        }
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 消費税の税率部分
        // CSSの関係で選択を変えると、1項目目が選択できなくなるので1項目目に空項目を入れる
        let TAX = Object.assign([], CONSUMPTION_TAX_RATE);
        TAX.unshift("");

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">取引報酬額</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th colSpan="6" className="dataTable-title is-emphasis">受入手数料（売側）</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w80">税抜</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00126" name="TOR00126" value={this.props.pageState.TOR00126} {...params} />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w222">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">消費税</div>
                                        <Select type="consumptionTax" id="ID_TOR00274" name="TOR00274" value={this.props.pageState.TOR00274} items={TAX} {...params} />
                                        <div className="dataTableUnit-action">
                                            <Link to="" aria-label="link" className="baseAction baseAction--calculation" onClick={this.handleCalculationTaxTOR00128}></Link>
                                        </div>
                                    </div>
                                </th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00127" name="TOR00127" value={this.props.pageState.TOR00127} {...params} />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w80">合計</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00128" name="TOR00128" value={this.props.pageState.TOR00128} {...params} />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th rowSpan="2" className="dataTable-title dataTable-title__w120">&nbsp;</th>
                                <th colSpan="2" className="dataTable-title dataTable-title__w120 is-emphasis">予定</th>
                                <th colSpan="2" className="dataTable-title is-emphasis">実績</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w220 is-emphasis">日付</th>
                                <th className="dataTable-title dataTable-title__w195 is-emphasis">金額（税込）</th>
                                <th className="dataTable-title dataTable-title__w220 is-emphasis">日付</th>
                                <th className="dataTable-title dataTable-title__w195 is-emphasis">金額（税込）</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title">1</th>
                                <td className="dataTable-body dataTable-body__rightborder">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00129" name="TOR00129" value={this.props.pageState.TOR00129} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00130" name="TOR00130" value={this.props.pageState.TOR00130} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00133" name="TOR00133" value={this.props.pageState.TOR00133} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00134" name="TOR00134" value={this.props.pageState.TOR00134} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title">2</th>
                                <td className="dataTable-body dataTable-body__rightborder">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00131" name="TOR00131" value={this.props.pageState.TOR00131} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00132" name="TOR00132" value={this.props.pageState.TOR00132} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00135" name="TOR00135" value={this.props.pageState.TOR00135} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00136" name="TOR00136" value={this.props.pageState.TOR00136} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title">合計</th>
                                <td className="dataTable-body dataTable-body__rightborder" colSpan="2">
                                    <div className="dataTableUnit dataTableUnit__right">
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.sum(this.props.pageState.TOR00130, this.props.pageState.TOR00132))}</div>
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit dataTableUnit__right">
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.sum(this.props.pageState.TOR00134, this.props.pageState.TOR00136))}</div>
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th colSpan="6" className="dataTable-title is-emphasis">受入手数料（買側）</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w80">税抜</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00137" name="TOR00137" value={this.props.pageState.TOR00137} {...params} />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w222">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">消費税</div>
                                        <Select type="consumptionTax" id="ID_TOR00275" name="TOR00275" value={this.props.pageState.TOR00275} items={TAX} {...params} />
                                        <div className="dataTableUnit-action">
                                            <Link to="" aria-label="link" className="baseAction baseAction--calculation" onClick={this.handleCalculationTaxTOR00139}></Link>
                                        </div>
                                    </div>
                                </th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00138" name="TOR00138" value={this.props.pageState.TOR00138} {...params} />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w80">合計</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00139" name="TOR00139" value={this.props.pageState.TOR00139} {...params} />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th rowSpan="2" className="dataTable-title dataTable-title__w120">&nbsp;</th>
                                <th colSpan="2" className="dataTable-title dataTable-title__w120 is-emphasis">予定</th>
                                <th colSpan="2" className="dataTable-title is-emphasis">実績</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w220 is-emphasis">日付</th>
                                <th className="dataTable-title dataTable-title__w195 is-emphasis">金額（税込）</th>
                                <th className="dataTable-title dataTable-title__w220 is-emphasis">日付</th>
                                <th className="dataTable-title dataTable-title__w195 is-emphasis">金額（税込）</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title">1</th>
                                <td className="dataTable-body dataTable-body__rightborder">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00140" name="TOR00140" value={this.props.pageState.TOR00140} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00141" name="TOR00141" value={this.props.pageState.TOR00141} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00144" name="TOR00144" value={this.props.pageState.TOR00144} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00145" name="TOR00145" value={this.props.pageState.TOR00145} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title">2</th>
                                <td className="dataTable-body dataTable-body__rightborder">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00142" name="TOR00142" value={this.props.pageState.TOR00142} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00143" name="TOR00143" value={this.props.pageState.TOR00143} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00146" name="TOR00146" value={this.props.pageState.TOR00146} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00147" name="TOR00147" value={this.props.pageState.TOR00147} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title">合計</th>
                                <td className="dataTable-body dataTable-body__rightborder" colSpan="2">
                                    <div className="dataTableUnit dataTableUnit__right">
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.sum(this.props.pageState.TOR00141, this.props.pageState.TOR00143))}</div>
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit dataTableUnit__right">
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.sum(this.props.pageState.TOR00145, this.props.pageState.TOR00147))}</div>
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th colSpan="6" className="dataTable-title is-emphasis">支払手数料（売側）</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w80">税抜</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00148" name="TOR00148" value={this.props.pageState.TOR00148} {...params} />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w222">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">消費税</div>
                                        <Select type="consumptionTax" id="ID_TOR00276" name="TOR00276" value={this.props.pageState.TOR00276} items={TAX} {...params} />
                                        <div className="dataTableUnit-action">
                                            <Link to="" aria-label="link" className="baseAction baseAction--calculation" onClick={this.handleCalculationTaxTOR00150}></Link>
                                        </div>
                                    </div>
                                </th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00149" name="TOR00149" value={this.props.pageState.TOR00149} {...params} />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w80">合計</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00150" name="TOR00150" value={this.props.pageState.TOR00150} {...params} />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title">支払先</th>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00151" name="TOR00151" value={this.props.pageState.TOR00151} {...params} />
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th rowSpan="2" className="dataTable-title dataTable-title__w120">&nbsp;</th>
                                <th colSpan="2" className="dataTable-title dataTable-title__w120 is-emphasis">予定</th>
                                <th colSpan="2" className="dataTable-title is-emphasis">実績</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w220 is-emphasis">日付</th>
                                <th className="dataTable-title dataTable-title__w195 is-emphasis">金額（税込）</th>
                                <th className="dataTable-title dataTable-title__w220 is-emphasis">日付</th>
                                <th className="dataTable-title dataTable-title__w195 is-emphasis">金額（税込）</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title">1</th>
                                <td className="dataTable-body dataTable-body__rightborder">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00152" name="TOR00152" value={this.props.pageState.TOR00152} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00153" name="TOR00153" value={this.props.pageState.TOR00153} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00156" name="TOR00156" value={this.props.pageState.TOR00156} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00157" name="TOR00157" value={this.props.pageState.TOR00157} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title">2</th>
                                <td className="dataTable-body dataTable-body__rightborder">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00154" name="TOR00154" value={this.props.pageState.TOR00154} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00155" name="TOR00155" value={this.props.pageState.TOR00155} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00158" name="TOR00158" value={this.props.pageState.TOR00158} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00159" name="TOR00159" value={this.props.pageState.TOR00159} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title">合計</th>
                                <td className="dataTable-body dataTable-body__rightborder" colSpan="2">
                                    <div className="dataTableUnit dataTableUnit__right">
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.sum(this.props.pageState.TOR00153, this.props.pageState.TOR00155))}</div>
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit dataTableUnit__right">
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.sum(this.props.pageState.TOR00157, this.props.pageState.TOR00159))}</div>
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th colSpan="6" className="dataTable-title is-emphasis">支払手数料（買側）</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w80">税抜</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00160" name="TOR00160" value={this.props.pageState.TOR00160} {...params} />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w222">
                                    <div className="dataTableUnit">
                                        <div className="dataTableUnit-text">消費税</div>
                                        <Select type="consumptionTax" id="ID_TOR00277" name="TOR00277" value={this.props.pageState.TOR00277} items={TAX} {...params} />
                                        <div className="dataTableUnit-action">
                                            <Link to="" aria-label="link" className="baseAction baseAction--calculation" onClick={this.handleCalculationTaxTOR00162}></Link>
                                        </div>
                                    </div>
                                </th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00161" name="TOR00161" value={this.props.pageState.TOR00161} {...params} />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__w80">合計</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00162" name="TOR00162" value={this.props.pageState.TOR00162} {...params} />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title">支払先</th>
                                <td className="dataTable-body" colSpan="4">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00163" name="TOR00163" value={this.props.pageState.TOR00163} {...params} />
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th rowSpan="2" className="dataTable-title dataTable-title__w120">&nbsp;</th>
                                <th colSpan="2" className="dataTable-title dataTable-title__w120 is-emphasis">予定</th>
                                <th colSpan="2" className="dataTable-title is-emphasis">実績</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w220 is-emphasis">日付</th>
                                <th className="dataTable-title dataTable-title__w195 is-emphasis">金額（税込）</th>
                                <th className="dataTable-title dataTable-title__w220 is-emphasis">日付</th>
                                <th className="dataTable-title dataTable-title__w195 is-emphasis">金額（税込）</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title">1</th>
                                <td className="dataTable-body dataTable-body__rightborder">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00164" name="TOR00164" value={this.props.pageState.TOR00164} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00165" name="TOR00165" value={this.props.pageState.TOR00165} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00168" name="TOR00168" value={this.props.pageState.TOR00168} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00169" name="TOR00169" value={this.props.pageState.TOR00169} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title">2</th>
                                <td className="dataTable-body dataTable-body__rightborder">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00166" name="TOR00166" value={this.props.pageState.TOR00166} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00167" name="TOR00167" value={this.props.pageState.TOR00167} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00170" name="TOR00170" value={this.props.pageState.TOR00170} {...params} length="w108" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00171" name="TOR00171" value={this.props.pageState.TOR00171} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title">合計</th>
                                <td className="dataTable-body dataTable-body__rightborder" colSpan="2">
                                    <div className="dataTableUnit dataTableUnit__right">
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.sum(this.props.pageState.TOR00165, this.props.pageState.TOR00167))}</div>
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body" colSpan="2">
                                    <div className="dataTableUnit dataTableUnit__right">
                                        <div className="dataTableUnit-text">{this.amountCommaEdit(this.sum(this.props.pageState.TOR00169, this.props.pageState.TOR00171))}</div>
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}