export const AUTHORITY = {
    GENERAL: {
        value: "01",
        display: "一般"
    },
    CONFIRM: {
        value: "02",
        display: "確定"
    },
    SYSTEM_ADMIN: {
        value: "03",
        display: "システム管理者"
    },
};

export const AUTHORITY_FRK = {
    GENERAL: {
        value: "01",
        display: "一般"
    },
    AREA_MANAGER: {
        value: "02",
        display: "場所長"
    },
    CONTRACT_EXAMINATION: {
        value: "03",
        display: "契約審査部"
    },
    MANAGER: {
        value: "04",
        display: "課長"
    },
};

export const AUTHORITY_FRK_MUFG = {
    GENERAL: {
        value: "01",
        display: "一般"
    },
    AREA_MANAGER: {
        value: "02",
        display: "場所長"
    },
    CONTRACT_EXAMINATION: {
        value: "03",
        display: "契約審査部"
    },
    MANAGER: {
        value: "04",
        display: "課長"
    },
};