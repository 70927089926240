import BaseGrid from "components/common/frame/baseGrid";
import { RegisterAndDeleteButtonRecord, RegisterButtonRecord } from "components/common/parts/buttonRecord";
import { InputTextareaRecord, InputTextRecord } from "components/common/parts/inputRecord";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import * as apiParamConverter from "util/apiParamConverter";
import * as convert from "util/apiParamConverter";
import * as awsReq from "util/awsRequest";
import Validation from "util/validate";

/** 支店／組織情報メンテナンス画面 */
class BranchandOrganizationMaintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
        // 連続APIコール防止用のステータス
        // stateで管理すると、setSatateする度にrenderが走る為、クラス変数で定義
        this.apiCalling = false; // true: 登録 or 更新APIコール中

        // 画面から入力される値の情報
        this.params = {
            displayId: {
                label: "支店／組織ID",
                validate: this.validation.notEmpty,
                apiParamKey: "display_id"
            },
            branchName: {
                label: "支店／組織名",
                validate: this.validation.notEmpty,
                apiParamKey: "branch_name"
            },
            address: {
                label: "住所",
                validate: this.validation.notEmpty,
                apiParamKey: "address"
            },
            // phoneNumber: {
            //     label: "電話番号",
            //     validate: this.validation.noCheck,
            //     apiParamKey: "phone_number"
            // },
            chiefName: {
                label: (<span>責任者<br />（役職、氏名）</span>),
                validate: this.validation.notEmpty,
                apiParamKey: "chief_name"
            }
        };
    }

    componentDidMount() {
        document.title = pageInfo.BRANCH_AND_ORGANIZATION_INFO_MAINTENANCE.title;

        // パスパラメータがある場合、取得APIを呼出し、レスポンスデータを入力ボックスの入力値を更新する
        if (this.props.match.params.id) {
            const id = this.props.match.params.id;
            this.requestBranchandOrganization(id);
        }
    }

    componentDidUpdate() {
        // パスパラメータがあり、stateが取得APIのレスポンスデータで更新されていない場合、
        // 取得APIを呼出し、レスポンスデータを入力ボックスの入力値を更新する
        if (this.props.match.params.id && !this.state.id) {
            this.requestBranchandOrganization(this.props.match.params.id);
        }
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputText = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** 入力ボックスの入力値をバリデーション */
    handleValidateInputText = e => {
        const target = e.target;
        const param = this.params[target.name];
        param.validate(target.name, target.value);
        this.setState({});  // 再renderする為の空セット
    }

    // 登録ボタンのハンドラー
    handleRegisterBranchandOrganization = e => {
        e.preventDefault();
        // 全入力項目のチェックし、エラーメッセージがない場合、後続処理（登録/更新APIの呼出し）をする
        if (!this.validation.validateTotalInputText(this.params, this.state)) {
            const requestBody = apiParamConverter.convertToApiReqCase(this.params, this.state);
            this.registerBranchandOrganization(requestBody);

        } else {
            this.setState({}); // バリデートエラーがある場合、登録ボタン押下時点のエラー内容をレンダリングする
        }
    }

    // 削除ボタンのハンドラー
    handleDeleteBranchandOrganization = e => {
        e.preventDefault();
        // 更新モードのみ削除処理
        if (this.props.match.params.id) {
            awsReq.del(
                AWS.ENDPOINT.PATHS.BRANCHAND_ORGANIZATION_DETAIL.replace(":id", this.props.match.params.id),
                () => { this.props.history.push(pageInfo.BRANCH_AND_ORGANIZATION_INFO_LIST.path); },
                this.errorCallback,
                {}, AWS.ENDPOINT.REST_API.NAME
            );
        }
    }

    render() {
        // パスパラメータが指定されており、stateに値が入っていない場合、nullを返す
        // ※componentDidMount、componentDidUpdateでリクエストを行い、レスポンスが入ってくるのを待つ
        if (this.props.match.params.id && !this.state.id) {
            return null;
        }

        // Inputレコードに関する、タグの属性情報
        const bodySetting = { colSpan: "3" };
        const inputSetting = { onChange: this.handleChangeInputText, onBlur: this.handleValidateInputText };
        const titleSetting = { className: "dataTable-title dataTable-title__w120" };

        // ボタンの表示制御
        // 登録モードの場合、登録ボタンのみを表示し、更新モードの場合、登録ボタンと削除ボタンを表示する
        // なお、APIコール中は、非アクティブにする
        const buttonDom = this.props.match.params.id
            ? this.apiCalling
                ? (<RegisterAndDeleteButtonRecord inActive
                    registerSetting={{ onClick: e => { e.preventDefault(); } }}
                    deleteSetting={{ onClick: e => { e.preventDefault(); } }} />)
                : (<RegisterAndDeleteButtonRecord
                    registerSetting={{ onClick: this.handleRegisterBranchandOrganization }}
                    deleteSetting={{ onClick: this.handleDeleteBranchandOrganization }} />)
            : this.apiCalling
                ? (<RegisterButtonRecord inActive registerSetting={{ onClick: e => { e.preventDefault(); } }} />)
                : (<RegisterButtonRecord registerSetting={{ onClick: this.handleRegisterBranchandOrganization }} />);

        return (
            <BaseGrid>
                <div className="dataList dataList__accordion is-open">
                    <div className="dataList-header">支店／組織情報メンテナンス</div>
                    <div className="dataList-body">
                        <table className="dataTable">
                            <tbody>
                                <tr>
                                    <InputTextRecord titleName={this.params.displayId.label}
                                        inputData={{ name: "displayId", value: this.state.displayId || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.displayId || ""} />
                                </tr>

                                <tr>
                                    <InputTextRecord titleName={this.params.branchName.label}
                                        inputData={{ name: "branchName", value: this.state.branchName || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={inputSetting}
                                        errorMessage={this.validation.errorMessage.branchName || ""} />
                                </tr>

                                <tr>
                                    <InputTextareaRecord titleName={this.params.address.label}
                                        inputData={{ name: "address", value: this.state.address || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={{ ...inputSetting, cols: "30", rows: "10" }}
                                        errorMessage={this.validation.errorMessage.address || ""} />
                                </tr>

                                <tr>
                                    <InputTextareaRecord titleName={this.params.chiefName.label}
                                        inputData={{ name: "chiefName", value: this.state.chiefName || "" }}
                                        titleSetting={titleSetting} bodySetting={bodySetting} inputSetting={{ ...inputSetting, cols: "30", rows: "10" }}
                                        errorMessage={this.validation.errorMessage.chiefName || ""} />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {buttonDom}

                <div className="l-pageAction l-pageAction--back">
                    <Link to={pageInfo.BRANCH_AND_ORGANIZATION_INFO_LIST.path} aria-label="link" className="pageAction">一覧へもどる</Link>
                </div>
            </BaseGrid>
        );
    }

    /** 支店情報取得APIコール、state更新 */
    requestBranchandOrganization = id => {
        awsReq.get(
            AWS.ENDPOINT.PATHS.BRANCHAND_ORGANIZATION_DETAIL.replace(":id", id),
            response => { this.setState(convert.convertToFlatCamelCase(response.data)); },
            this.errorCallback,
            {}, {}, AWS.ENDPOINT.REST_API.NAME,
        );
    }

    /** モードに応じて、支店情報情報を登録、更新する */
    registerBranchandOrganization = requestBody => {
        this.apiCalling = true;
        this.setState({});  // ボタンを非活性にするためのレンダリングをさせる

        if (this.props.match.params.id) {
            // 更新モード
            // 正常なAPIレスポンスが返却された後、再度、取得APIをコールする
            const successCallback = () => {
                this.props.history.push(pageInfo.BRANCH_AND_ORGANIZATION_INFO_MAINTENANCE_UPDATE.path.replace(":id", this.props.match.params.id));
                this.apiCalling = false;
                this.setState({ id: undefined });
            };
            awsReq.put(AWS.ENDPOINT.PATHS.BRANCHAND_ORGANIZATION_DETAIL.replace(":id", this.props.match.params.id),
                successCallback,
                this.errorCallback,
                { id: Number(this.props.match.params.id), ...requestBody, },
                {}, AWS.ENDPOINT.REST_API.NAME);

        } else {
            // 登録モード
            // 正常なAPIレスポンスが返却された後、APIのレスポンスのLocationヘッダ末尾の文字列（ID部分）を取得し、このIDをパスパラメータとして遷移する
            const successCallback = response => {
                const id = response.headers["location"].split("/").slice(-1)[0];
                this.props.history.push(pageInfo.BRANCH_AND_ORGANIZATION_INFO_MAINTENANCE_UPDATE.path.replace(":id", id));
                this.apiCalling = false;
            };
            awsReq.post(AWS.ENDPOINT.PATHS.BRANCHAND_ORGANIZATION,
                successCallback,
                this.errorCallback,
                requestBody,
                {}, AWS.ENDPOINT.REST_API.NAME);
        }
    }

    /** APIコール時のエラーコールバック関数 */
    errorCallback = error => {
        alert(ERROR_MESSAGE.SERVER_ERROR);
        this.apiCalling = false;
        this.setState({}); // 再renderする為に、stateを空更新
    };
}

export default withRouter(BranchandOrganizationMaintenance);
