import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import * as dateUtil from "util/dateUtil";
import Validation from "util/validate";
import Select from "../../input/inputSelect";
import InputTextRemark from "../../input/inputTextRemark";

/** 建築確認認証等の発行年月日・番号 */
export default class Block3 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "021103",
        };// 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 文字数制限定義
        const JYU01020LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU01020",
                this.props.pageState.JYU01020);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">建築確認済証等の発行年月日・番号</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__left dataTable-title__w211">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01014" name="JYU01014" checked={this.props.pageState.JYU01014} {...params} />
                                        <span className="checkBox-text">建築確認（新築時）</span>
                                    </label>
                                </th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU01751" name="JYU01751" value={this.props.pageState.JYU01751} {...params} length="w242" />
                                        <div className="dataTableUnit-text">年</div>
                                        <Select id="ID_JYU01712" name="JYU01712" value={this.props.pageState.JYU01712} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} />
                                        <div className="dataTableUnit-text">月</div>
                                        <Select id="ID_JYU01752" name="JYU01752" value={this.props.pageState.JYU01752} items={this.getSelectboxWithHyphen(dateUtil.genDayList())} {...params} />
                                        <div className="dataTableUnit-text">日</div>
                                        <Input id="ID_JYU01016" name="JYU01016" value={this.props.pageState.JYU01016} {...params} length="wFlexFull" />
                                        <div className="dataTableUnit-text">号</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__left dataTable-title__w211">
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01017" name="JYU01017" checked={this.props.pageState.JYU01017} {...params} />
                                        <span className="checkBox-text">検査済証（新築時）</span>
                                    </label>
                                </th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_JYU01753" name="JYU01753" value={this.props.pageState.JYU01753} {...params} length="w242" />
                                        <div className="dataTableUnit-text">年</div>
                                        <Select id="ID_JYU01715" name="JYU01715" value={this.props.pageState.JYU01715} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} />
                                        <div className="dataTableUnit-text">月</div>
                                        <Select id="ID_JYU01754" name="JYU01754" value={this.props.pageState.JYU01754} items={this.getSelectboxWithHyphen(dateUtil.genDayList())} {...params} />
                                        <div className="dataTableUnit-text">日</div>
                                        <Input id="ID_JYU01019" name="JYU01019" value={this.props.pageState.JYU01019} {...params} length="wFlexFull" />
                                        <div className="dataTableUnit-text">号</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__left dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU01020" name="JYU01020" value={this.props.pageState.JYU01020} text={SEND_OTHER_PAPER.JYU01020} placeholder={JYU01020LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU01020LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}