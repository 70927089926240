import InputDate from "components/page/user/contract/edit/input/inputDate";
import Input from "components/page/user/contract/edit/input/inputNormal";
import { BlockBase, HeadlineHeader } from "components/page/user/contract/edit/parts/blockBase";
import React from "react";

/** 支払内容 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010201",
        };
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">支払内容</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            <tr>
                                <th className="dataTable-title dataTable-title__w120" colSpan="2" rowSpan="2">&nbsp;</th>
                                <th className="dataTable-title is-emphasis" colSpan="2">予定</th>
                                <th className="dataTable-title is-emphasis" colSpan="2">実績</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w220 is-emphasis">日付</th>
                                <th className="dataTable-title dataTable-title__w195 is-emphasis">金額</th>
                                <th className="dataTable-title dataTable-title__w220 is-emphasis">日付</th>
                                <th className="dataTable-title dataTable-title__w195 is-emphasis">金額</th>
                            </tr>
                            <tr>
                                <th className="dataTable-title" colSpan="2">手付金</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00114" name="TOR00114" value={this.props.pageState.TOR00114} {...params} length="w107" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00115" name="TOR00115" value={this.props.pageState.TOR00115} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00120" name="TOR00120" value={this.props.pageState.TOR00120} {...params} length="w107" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00121" name="TOR00121" value={this.props.pageState.TOR00121} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w60" rowSpan="2">内金</th>
                                <th className="dataTable-title dataTable-title__w60 is-emphasis">1</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00116" name="TOR00116" value={this.props.pageState.TOR00116} {...params} length="w107" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00117" name="TOR00117" value={this.props.pageState.TOR00117} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00122" name="TOR00122" value={this.props.pageState.TOR00122} {...params} length="w107" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00123" name="TOR00123" value={this.props.pageState.TOR00123} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__w60 is-emphasis">2</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00264" name="TOR00264" value={this.props.pageState.TOR00264} {...params} length="w107" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00265" name="TOR00265" value={this.props.pageState.TOR00265} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00266" name="TOR00266" value={this.props.pageState.TOR00266} {...params} length="w107" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00267" name="TOR00267" value={this.props.pageState.TOR00267} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title" colSpan="2">残代金</th>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00118" name="TOR00118" value={this.props.pageState.TOR00118} {...params} length="w107" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00119" name="TOR00119" value={this.props.pageState.TOR00119} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <InputDate id="ID_TOR00124" name="TOR00124" value={this.props.pageState.TOR00124} {...params} length="w107" />
                                    </div>
                                </td>
                                <td className="dataTable-body">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00125" name="TOR00125" value={this.props.pageState.TOR00125} {...params} length="w142" />
                                        <div className="dataTableUnit-text">円</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}