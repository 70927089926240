import InputCheckbox from "components/page/user/contractFrk/edit/input/inputCheckbox";
import InputDate from "components/page/user/contractFrk/edit/input/inputDate";
import Input from "components/page/user/contractFrk/edit/input/inputNormal";
import InputTextArea from "components/page/user/contractFrk/edit/input/inputTextArea";
import { BlockBase, HeadlineHeader } from "components/page/user/contractFrk/edit/parts/blockBase";
import { DISPLAY_TYPES } from "constants/defaultInputValueTypes";
import { SEND_OTHER_PAPER } from "constants/messages";
import React from "react";
import * as dateUtil from "util/dateUtil";
import Validation from "util/validate";
import Select from "../../input/inputSelect";
import InputTextRemark from "../../input/inputTextRemark";


/** 飲用水・電気・ガスの供給施設および排水施設の整備状況 */
export default class Block1 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "020701",
        };
        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
        this.isSameJYU01387 = this.props.pageState.JYU01387;
        this.isSameJYU01388 = this.props.pageState.JYU01388;
        this.isSameJYU01392 = this.props.pageState.JYU01392;
        this.isSameJYU01393 = this.props.pageState.JYU01393;
        this.isSameJYU01396 = this.props.pageState.JYU01396;
        this.isSameJYU01399 = this.props.pageState.JYU01399;
        this.isSameJYU01400 = this.props.pageState.JYU01400;

        // 固定出力文字の設定
        this.HYPHEN_TEXT_DATE = "--";
        this.HYPHEN_TEXT_AMOUNT = "--------------------------";
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 文字数制限定義
        const JYU00785LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU00785",
                this.props.pageState.JYU00785);

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 整備状況を設備毎に設定する
        let DOMMaintenanceHeader = this.DOMMaintenanceHeader(this.props);
        let DOMMaintenanceDrinkingWater = undefined;
        let DOMMaintenanceElectricity = undefined;
        let DOMMaintenanceGas = undefined;
        let DOMMaintenanceDrainage = undefined;
        let DOMMaintenanceSewag = undefined;
        let DOMMaintenanceMiscellaneousDrainage = undefined;
        let DOMMaintenanceRainwater = undefined;

        if (this.props.displayTypeID === DISPLAY_TYPES.LAND.value
            || this.props.displayTypeID === DISPLAY_TYPES.LAND_AND_BUILDING.value) {
            DOMMaintenanceDrinkingWater = this.MaintenanceDrinkingWater(this.props, params);
            DOMMaintenanceElectricity = this.MaintenanceElectricity(this.props, params);
            DOMMaintenanceGas = this.MaintenanceGas(this.props, params);
            DOMMaintenanceSewag = this.MaintenanceSewag(this.props, params);
            DOMMaintenanceMiscellaneousDrainage = this.MaintenanceMiscellaneousDrainage(this.props, params);
            DOMMaintenanceRainwater = this.MaintenanceRainwater(this.props, params);
        } else {
            DOMMaintenanceDrinkingWater = this.MaintenanceDrinkingWater(this.props, params);
            DOMMaintenanceElectricity = this.MaintenanceElectricity(this.props, params);
            DOMMaintenanceGas = this.MaintenanceGas(this.props, params);
            DOMMaintenanceDrainage = this.MaintenanceDrainage(this.props, params);
        }

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">飲用水・電気・ガスの供給施設および排水施設の整備状況</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            {DOMMaintenanceHeader}
                            {DOMMaintenanceDrinkingWater}
                            {DOMMaintenanceElectricity}
                            {DOMMaintenanceGas}
                            {DOMMaintenanceDrainage}
                            {DOMMaintenanceSewag}
                            {DOMMaintenanceMiscellaneousDrainage}
                            {DOMMaintenanceRainwater}
                            <tr>
                                <th className="dataTable-title dataTable-title__w120 dataTable-title__center">備考</th>
                                <td className="dataTable-body dataTable-body__top" colSpan="3">
                                    <div className="dataTableTextUnit">
                                        <InputTextRemark id="ID_JYU00785" name="JYU00785" value={this.props.pageState.JYU00785} text={SEND_OTHER_PAPER.JYU00785} placeholder={JYU00785LimitMsg.inputLimitGuide} {...params} />
                                        <p className="LengthOver-error">{JYU00785LimitMsg.inputLimitMsg}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    /**
     * Tableのヘッダーの設定
     */
    DOMMaintenanceHeader(props) {
        if (props.displayTypeID !== DISPLAY_TYPES.SEGMENT_BUILDING.value) {
            return (
                <tr>
                    <th className="dataTable-title dataTable-title__center dataTable-title__w120"></th>
                    <th className="dataTable-title dataTable-title__center dataTable-title__30per">直ちに利用可能な施設</th>
                    <th className="dataTable-title dataTable-title__center dataTable-title__30per">配管等の状況</th>
                    <th className="dataTable-title dataTable-title__center">整備予定・負担金</th>
                </tr>
            );
        } else {
            return (
                <tr>
                    <th className="dataTable-title dataTable-title__center dataTable-title__w120"></th>
                    <th className="dataTable-title dataTable-title__center dataTable-title__30per">直ちに利用可能な施設</th>
                    <th className="dataTable-title dataTable-title__center dataTable-title__30per">整備予定・負担金</th>
                    <th className="dataTable-title dataTable-title__center">備考</th>
                </tr>
            );
        }
    }

    /**
     * 整備状況：飲用水
     */
    MaintenanceDrinkingWater(props, params) {

        // 非活性の設定
        let JYU01387_disabled = this.props.pageState.JYU01387;

        // ハイフンの設定
        if (!this.isSameJYU01387 && this.props.pageState.JYU01387) {
            this.isSameJYU01387 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU01615", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU01616", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU01617", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU00716", this.HYPHEN_TEXT_AMOUNT));
        }

        if (this.isSameJYU01387 && !this.props.pageState.JYU01387) {
            this.isSameJYU01387 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00716", ""));
        }

        // 直ちに利用可能な施設
        let DOMAvailableFacilities = (<td className="dataTable-body dataTable-body__top"></td>);
        DOMAvailableFacilities = (
            <td className="dataTable-body dataTable-body__top is-rightborder">
                <div className="dataTableUnit_vr">
                    <div className="dataTableUnit-input">
                        <ul className="l-checkBox l-checkBox--vertical">
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00706" name="JYU00706" checked={this.props.pageState.JYU00706} {...params} />
                                    <span className="checkBox-text">1. 公営水道</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00707" name="JYU00707" checked={this.props.pageState.JYU00707} {...params} />
                                    <span className="checkBox-text">2.私営水道</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00708" name="JYU00708" checked={this.props.pageState.JYU00708} {...params} />
                                    <span className="checkBox-text">3.井戸</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </td>
        );

        // 配管・供給等の状況
        let DOMPipingSupply = (undefined);
        if (props.displayTypeID !== DISPLAY_TYPES.SEGMENT_BUILDING.value) {
            DOMPipingSupply = (
                <td className="dataTable-body dataTable-body__top">
                    <div className="dataTableMultiple">
                        <div className="dataTableMultiple-item">
                            <div className="dataTableUnit">
                                <div className="dataTableUnit-text">前面道路配管</div>
                                <div className="dataTableUnit-input">
                                    <ul className="l-checkBox l-checkBox--minOffset">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00709" name="JYU00709" checked={this.props.pageState.JYU00709} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01384" name="JYU01384" checked={this.props.pageState.JYU01384} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="dataTableUnit-text">(口径</div>
                                <Input id="ID_JYU00710" name="JYU00710" value={this.props.pageState.JYU00710} {...params} length="w60" />
                                <div className="dataTableUnit-text">mm)</div>
                            </div>
                        </div>
                        <div className="dataTableMultiple-item">
                            <div className="dataTableUnit">
                                <div className="dataTableUnit-text">敷地内引込管</div>
                                <div className="dataTableUnit-input">
                                    <ul className="l-checkBox l-checkBox--minOffset">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00711" name="JYU00711" checked={this.props.pageState.JYU00711} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01385" name="JYU01385" checked={this.props.pageState.JYU01385} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="dataTableUnit-text">(口径</div>
                                <Input id="ID_JYU00712" name="JYU00712" value={this.props.pageState.JYU00712} {...params} length="w60" />
                                <div className="dataTableUnit-text">mm)</div>
                            </div>
                        </div>
                        <div className="dataTableMultiple-item">
                            <div className="dataTableUnit">
                                <div className="dataTableUnit-text">私設管の有無</div>
                                <div className="dataTableUnit-input">
                                    <ul className="l-checkBox l-checkBox--minOffset">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00713" name="JYU00713" checked={this.props.pageState.JYU00713} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01386" name="JYU01386" checked={this.props.pageState.JYU01386} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            );
        }

        // 整備予定・負担金
        let DOMMaintenance = (<td className="dataTable-body dataTable-body__top"></td>);
        DOMMaintenance = (
            <td className="dataTable-body dataTable-body__top">
                <div className="dataTableMultiple">
                    <div className="dataTableMultiple-item">
                        <ul className="l-checkBox l-checkBox--minOffset">
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU01387" name="JYU01387" checked={this.props.pageState.JYU01387} {...params} />
                                    <span className="checkBox-text">無</span>

                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00714" name="JYU00714" checked={this.props.pageState.JYU00714} {...params} />
                                    <span className="checkBox-text">有</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <Select id="ID_JYU01615" name="JYU01615" value={this.props.pageState.JYU01615} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} disabled={JYU01387_disabled} length="w80" />
                            <Select id="ID_JYU01616" name="JYU01616" value={this.props.pageState.JYU01616} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} disabled={JYU01387_disabled} length="w80" />
                            <div className="dataTableUnit-text">年</div>
                            <Select id="ID_JYU01617" name="JYU01617" value={this.props.pageState.JYU01617} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} disabled={JYU01387_disabled} length="w80" />
                            <div className="dataTableUnit-text">月</div>
                            <div className="dataTableUnit-text">頃</div>
                        </div>
                    </div>
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <Input id="ID_JYU00716" name="JYU00716" value={this.props.pageState.JYU00716} {...params} disabled={JYU01387_disabled} />
                            <div className="dataTableUnit-text">円</div>
                        </div>
                    </div>
                </div>
            </td>
        );

        // 備考
        let DOMRemarks = undefined;
        // 文字数制限定義
        const JYU01618LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU01618",
                this.props.pageState.JYU01618);
        if (props.displayTypeID === DISPLAY_TYPES.SEGMENT_BUILDING.value) {
            DOMRemarks = this.CreateTextArea(params, "JYU01618", this.props.pageState.JYU01618, JYU01618LimitMsg);
        }

        return (
            <tr>
                <th className="dataTable-title dataTable-title__center">飲用水</th>
                {DOMAvailableFacilities}
                {DOMPipingSupply}
                {DOMMaintenance}
                {DOMRemarks}
            </tr>
        );
    }

    /**
     * 整備状況：電気
     */
    MaintenanceElectricity(props, params) {

        // 非活性の設定
        let JYU01388_disabled = this.props.pageState.JYU01388;

        // ハイフンの設定
        if (!this.isSameJYU01388 && this.props.pageState.JYU01388) {
            this.isSameJYU01388 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU01619", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU01620", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU01621", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU00729", this.HYPHEN_TEXT_AMOUNT));
        }

        if (this.isSameJYU01388 && !this.props.pageState.JYU01388) {
            this.isSameJYU01388 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00729", ""));
        }

        // 直ちに利用可能な施設
        let DOMAvailableFacilities = (<td className="dataTable-body dataTable-body__top"></td>);
        if (props.displayTypeID !== DISPLAY_TYPES.SEGMENT_BUILDING.value) {
            DOMAvailableFacilities = (
                <td className="dataTable-body dataTable-body__top is-rightborder">
                    <div className="dataTableUnit_vr">
                        <div className="dataTableUnit-input">
                            <ul className="l-checkBox l-checkBox--vertical">
                                <li>
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01474" name="JYU01474" checked={this.props.pageState.JYU01474} {...params} />
                                        <span className="checkBox-text">有</span>
                                    </label>
                                </li>
                                <li>
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01475" name="JYU01475" checked={this.props.pageState.JYU01475} {...params} />
                                        <span className="checkBox-text">無</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </td>
            );
        } else {
            DOMAvailableFacilities = (
                <td className="dataTable-body dataTable-body__top is-rightborder">
                    <div className="dataTableMultiple">
                        <div className="dataTableMultiple-item">
                            <label className="checkBox">
                                <InputCheckbox id="ID_JYU00718" name="JYU00718" checked={this.props.pageState.JYU00718} {...params} />
                                <span className="checkBox-text">1.一括受電方式である</span>
                            </label>
                        </div>
                        <div className="dataTableMultiple-item">
                            <div className="dataTableUnit">
                                <div className="dataTableUnit-text">一括受電サービス会社</div>
                                <Input id="ID_JYU00723" name="JYU00723" value={this.props.pageState.JYU00723} {...params} />
                            </div>
                        </div>
                        <div className="dataTableMultiple-item">
                            <div className="dataTableUnit">
                                <div className="dataTableUnit-text">契約期間</div>
                                <InputDate noEra id="ID_JYU00720" name="JYU00720" value={this.props.pageState.JYU00720} {...params} />
                                <div className="dataTableUnit-text">迄</div>
                            </div>
                        </div>
                        <div className="dataTableMultiple-item">
                            <label className="checkBox">
                                <InputCheckbox id="ID_JYU00721" name="JYU00721" checked={this.props.pageState.JYU00721} {...params} />
                                <span className="checkBox-text">2.一括受電方式でない</span>
                            </label>
                        </div>
                    </div>
                </td>
            );
        }

        // 配管・供給等の状況
        let DOMPipingSupply = undefined;
        // 文字数制限定義
        const JYU01622LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU01622",
                this.props.pageState.JYU01622);
        DOMPipingSupply = this.CreateTextArea(params, "JYU01622", this.props.pageState.JYU01622, JYU01622LimitMsg);

        // 整備予定・負担金
        let DOMMaintenance = undefined;
        if (props.displayTypeID !== DISPLAY_TYPES.SEGMENT_BUILDING.value) {
            DOMMaintenance = (
                <td className="dataTable-body dataTable-body__top">
                    <div className="dataTableMultiple">
                        <div className="dataTableMultiple-item">
                            <ul className="l-checkBox l-checkBox--minOffset">
                                <li>
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU01388" name="JYU01388" checked={this.props.pageState.JYU01388} {...params} />
                                        <span className="checkBox-text">無</span>
                                    </label>
                                </li>
                                <li>
                                    <label className="checkBox">
                                        <InputCheckbox id="ID_JYU00727" name="JYU00727" checked={this.props.pageState.JYU00727} {...params} />
                                        <span className="checkBox-text">有</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="dataTableMultiple-item">
                            <div className="dataTableUnit">
                                <Select id="ID_JYU01619" name="JYU01619" value={this.props.pageState.JYU01619} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} disabled={JYU01388_disabled} length="w80" />
                                <Select id="ID_JYU01620" name="JYU01620" value={this.props.pageState.JYU01620} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} disabled={JYU01388_disabled} length="w80" />
                                <div className="dataTableUnit-text">年</div>
                                <Select id="ID_JYU01621" name="JYU01621" value={this.props.pageState.JYU01621} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} disabled={JYU01388_disabled} length="w80" />
                                <div className="dataTableUnit-text">月</div>
                                <div className="dataTableUnit-text">頃</div>
                            </div>
                        </div>
                        <div className="dataTableMultiple-item">
                            <div className="dataTableUnit">
                                <div className="dataTableUnit-input">
                                    <Input id="ID_JYU00729" name="JYU00729" value={this.props.pageState.JYU00729} {...params} disabled={JYU01388_disabled} />
                                </div>
                                <div className="dataTableUnit-text">円</div>
                            </div>
                        </div>
                    </div>
                </td>
            );
        }

        // 備考
        let DOMRemarks = undefined;
        // 文字数制限定義
        const JYU01623LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU01623",
                this.props.pageState.JYU01623);
        if (props.displayTypeID === DISPLAY_TYPES.SEGMENT_BUILDING.value) {
            DOMRemarks = this.CreateTextArea(params, "JYU01623", this.props.pageState.JYU01623, JYU01623LimitMsg);
        }

        return (
            <tr>
                <th className="dataTable-title dataTable-title__center">電気</th>
                {DOMAvailableFacilities}
                {DOMPipingSupply}
                {DOMMaintenance}
                {DOMRemarks}
            </tr>
        );
    }

    /**
     * 整備状況：ガス
     */
    MaintenanceGas(props, params) {

        // 非活性の設定
        let JYU01392_disabled = this.props.pageState.JYU01392;

        // ハイフンの設定
        if (!this.isSameJYU01392 && this.props.pageState.JYU01392) {
            this.isSameJYU01392 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU01625", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU01626", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU01627", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU00743", this.HYPHEN_TEXT_AMOUNT));
        }

        if (this.isSameJYU01392 && !this.props.pageState.JYU01392) {
            this.isSameJYU01392 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00743", ""));
        }

        // 直ちに利用可能な施設
        let DOMAvailableFacilities = (<td className="dataTable-body dataTable-body__top"></td>);
        let DOMCheckbox = undefined;
        if (props.displayTypeID === DISPLAY_TYPES.SEGMENT_BUILDING.value) {
            DOMCheckbox = (
                <li>
                    <label className="checkBox">
                        <InputCheckbox id="ID_JYU01624" name="JYU01624" checked={this.props.pageState.JYU01624} {...params} />
                        <span className="checkBox-text">4.無</span>
                    </label>
                </li>
            );
        }


        DOMAvailableFacilities = (
            <td className="dataTable-body dataTable-body__top is-rightborder">
                <div className="dataTableUnit_vr">
                    <div className="dataTableUnit-input">
                        <ul className="l-checkBox l-checkBox--vertical">
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00731" name="JYU00731" checked={this.props.pageState.JYU00731} {...params} />
                                    <span className="checkBox-text">1. 都市ガス</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00732" name="JYU00732" checked={this.props.pageState.JYU00732} {...params} />
                                    <span className="checkBox-text">2.個別プロパン</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00733" name="JYU00733" checked={this.props.pageState.JYU00733} {...params} />
                                    <span className="checkBox-text">3.集中プロパン</span>
                                </label>
                            </li>
                            {DOMCheckbox}
                        </ul>
                    </div>
                </div>
            </td>
        );

        // 配管・供給等の状況
        let DOMPipingSupply = undefined;
        if (props.displayTypeID !== DISPLAY_TYPES.SEGMENT_BUILDING.value) {
            DOMPipingSupply = (
                <td className="dataTable-body dataTable-body__top">
                    <div className="dataTableMultiple">
                        <div className="dataTableMultiple-item">
                            <div className="dataTableUnit">
                                <div className="dataTableUnit-text">前面道路配管</div>
                                <div className="dataTableUnit-input">
                                    <ul className="l-checkBox l-checkBox--minOffset">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00736" name="JYU00736" checked={this.props.pageState.JYU00736} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01389" name="JYU01389" checked={this.props.pageState.JYU01389} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="dataTableUnit-text">(口径</div>
                                <div className="dataTableUnit-input">
                                    <Input id="ID_JYU00737" name="JYU00737" value={this.props.pageState.JYU00737} {...params} length="w60" />
                                </div>
                                <div className="dataTableUnit-text">mm)</div>
                            </div>
                        </div>
                        <div className="dataTableMultiple-item">
                            <div className="dataTableUnit">
                                <div className="dataTableUnit-text">敷地内引込管</div>
                                <div className="dataTableUnit-input">
                                    <ul className="l-checkBox l-checkBox--minOffset">
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU00738" name="JYU00738" checked={this.props.pageState.JYU00738} {...params} />
                                                <span className="checkBox-text">有</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkBox">
                                                <InputCheckbox id="ID_JYU01390" name="JYU01390" checked={this.props.pageState.JYU01390} {...params} />
                                                <span className="checkBox-text">無</span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            );
        }

        // 整備予定・負担金
        let DOMMaintenance = (<td className="dataTable-body dataTable-body__top"></td>);
        DOMMaintenance = (
            <td className="dataTable-body dataTable-body__top">
                <div className="dataTableMultiple">
                    <div className="dataTableMultiple-item">
                        <ul className="l-checkBox l-checkBox--minOffset">
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU01392" name="JYU01392" checked={this.props.pageState.JYU01392} {...params} />
                                    <span className="checkBox-text">無</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00741" name="JYU00741" checked={this.props.pageState.JYU00741} {...params} />
                                    <span className="checkBox-text">有</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <Select id="ID_JYU01625" name="JYU01625" value={this.props.pageState.JYU01625} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} disabled={JYU01392_disabled} length="w80" />
                            <Select id="ID_JYU01626" name="JYU01626" value={this.props.pageState.JYU01626} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} disabled={JYU01392_disabled} length="w80" />
                            <div className="dataTableUnit-text">年</div>
                            <Select id="ID_JYU01627" name="JYU01627" value={this.props.pageState.JYU01627} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} disabled={JYU01392_disabled} length="w80" />
                            <div className="dataTableUnit-text">月</div>
                            <div className="dataTableUnit-text">頃</div>
                        </div>
                    </div>
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-input">
                                <Input id="ID_JYU00743" name="JYU00743" value={this.props.pageState.JYU00743} {...params} disabled={JYU01392_disabled} />
                            </div>
                            <div className="dataTableUnit-text">円</div>
                        </div>
                    </div>
                </div>
            </td>
        );

        // 備考
        let DOMRemarks = undefined;
        // 文字数制限定義
        const JYU01628LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU01628",
                this.props.pageState.JYU01628);
        if (props.displayTypeID === DISPLAY_TYPES.SEGMENT_BUILDING.value) {
            DOMRemarks = this.CreateTextArea(params, "JYU01628", this.props.pageState.JYU01628, JYU01628LimitMsg);
        }

        return (
            <tr>
                <th className="dataTable-title dataTable-title__center">ガス</th>
                {DOMAvailableFacilities}
                {DOMPipingSupply}
                {DOMMaintenance}
                {DOMRemarks}
            </tr>
        );
    }

    /**
     * 整備状況：排水
     */
    MaintenanceDrainage(props, params) {

        // 非活性の設定
        let JYU01393_disabled = this.props.pageState.JYU01393;

        // ハイフンの設定
        if (!this.isSameJYU01393 && this.props.pageState.JYU01393) {
            this.isSameJYU01393 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU01631", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU01632", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU01633", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU00751", this.HYPHEN_TEXT_AMOUNT));
        }

        if (this.isSameJYU01393 && !this.props.pageState.JYU01393) {
            this.isSameJYU01393 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00751", ""));
        }

        // 直ちに利用可能な施設
        let DOMAvailableFacilities = (<td className="dataTable-body dataTable-body__top"></td>);
        DOMAvailableFacilities = (
            <td className="dataTable-body dataTable-body__top is-rightborder">
                <div className="dataTableUnit_vr">
                    <div className="dataTableUnit-input">
                        <ul className="l-checkBox l-checkBox--vertical">
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00745" name="JYU00745" checked={this.props.pageState.JYU00745} {...params} />
                                    <span className="checkBox-text">1. 公共下水</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00746" name="JYU00746" checked={this.props.pageState.JYU00746} {...params} />
                                    <span className="checkBox-text">2.個別浄化槽</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00747" name="JYU00747" checked={this.props.pageState.JYU00747} {...params} />
                                    <span className="checkBox-text">3.集中浄化槽</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU01629" name="JYU01629" checked={this.props.pageState.JYU01629} {...params} />
                                    <span className="checkBox-text">4.</span>
                                    <Input noBlock id="ID_JYU01630" name="JYU01630" value={this.props.pageState.JYU01630} {...params} length="w333" />
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </td>
        );

        // 整備予定・負担金
        let DOMMaintenance = (<td className="dataTable-body dataTable-body__top"></td>);
        DOMMaintenance = (
            <td className="dataTable-body dataTable-body__top">
                <div className="dataTableMultiple">
                    <div className="dataTableMultiple-item">
                        <ul className="l-checkBox l-checkBox--minOffset">
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU01393" name="JYU01393" checked={this.props.pageState.JYU01393} {...params} />
                                    <span className="checkBox-text">無</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00749" name="JYU00749" checked={this.props.pageState.JYU00749} {...params} />
                                    <span className="checkBox-text">有</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <Select id="ID_JYU01631" name="JYU01631" value={this.props.pageState.JYU01631} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} disabled={JYU01393_disabled} length="w80" />
                            <Select id="ID_JYU01632" name="JYU01632" value={this.props.pageState.JYU01632} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} disabled={JYU01393_disabled} length="w80" />
                            <div className="dataTableUnit-text">年</div>
                            <Select id="ID_JYU01633" name="JYU01633" value={this.props.pageState.JYU01633} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} disabled={JYU01393_disabled} length="w80" />
                            <div className="dataTableUnit-text">月</div>
                            <div className="dataTableUnit-text">頃</div>
                        </div>
                    </div>
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-input">
                                <Input id="ID_JYU00751" name="JYU00751" value={this.props.pageState.JYU00751} {...params} disabled={JYU01393_disabled} />
                            </div>
                            <div className="dataTableUnit-text">円</div>
                        </div>
                    </div>
                </div>
            </td>
        );

        // 備考
        // 文字数制限定義
        const JYU01634LimitMsg =
            this.validation.limitTextAreaFrk(
                this.props.contractTypeId,
                this.state.headlineId.substring(0, 4),
                "JYU01634",
                this.props.pageState.JYU01634);
        let DOMRemarks = this.CreateTextArea(params, "JYU01634", this.props.pageState.JYU01634, JYU01634LimitMsg);

        return (
            <tr>
                <th className="dataTable-title dataTable-title__center">排水</th>
                {DOMAvailableFacilities}
                {DOMMaintenance}
                {DOMRemarks}
            </tr>
        );
    }

    /**
     * 整備状況：汚水
     */
    MaintenanceSewag(props, params) {

        // 非活性の設定
        let JYU01396_disabled = this.props.pageState.JYU01396;

        // ハイフンの設定
        if (!this.isSameJYU01396 && this.props.pageState.JYU01396) {
            this.isSameJYU01396 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU01635", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU01636", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU01637", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU00768", this.HYPHEN_TEXT_AMOUNT));
        }

        if (this.isSameJYU01396 && !this.props.pageState.JYU01396) {
            this.isSameJYU01396 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00768", ""));
        }

        // 直ちに利用可能な施設
        let DOMAvailableFacilities = (<td className="dataTable-body dataTable-body__top"></td>);
        DOMAvailableFacilities = (
            <td className="dataTable-body dataTable-body__top is-rightborder">
                <div className="dataTableUnit_vr">
                    <div className="dataTableUnit-input">
                        <ul className="l-checkBox l-checkBox--vertical">
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00754" name="JYU00754" checked={this.props.pageState.JYU00754} {...params} />
                                    <span className="checkBox-text">1. 公共下水</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00755" name="JYU00755" checked={this.props.pageState.JYU00755} {...params} />
                                    <span className="checkBox-text">2.個別浄化槽</span>
                                </label>
                                <div className="dataTableUnit_vr dataTableUnit_vr--topspace">
                                    <div className="dataTableUnit-input">
                                        <div className="dataTableUnit-input-title">放流先&nbsp;（</div>
                                        <ul className="l-checkBox l-checkBox--vertical l-checkBox--leftspace l-checkBox--topspace">
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_JYU00756" name="JYU00756" checked={this.props.pageState.JYU00756} {...params} />
                                                    <span className="checkBox-text">1.埋設管</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_JYU00757" name="JYU00757" checked={this.props.pageState.JYU00757} {...params} />
                                                    <span className="checkBox-text">2.側溝</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkBox">
                                                    <InputCheckbox id="ID_JYU00758" name="JYU00758" checked={this.props.pageState.JYU00758} {...params} />
                                                    <span className="checkBox-text">3.浸透式&nbsp;&nbsp;）</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00759" name="JYU00759" checked={this.props.pageState.JYU00759} {...params} />
                                    <span className="checkBox-text">3.集中浄化槽</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00760" name="JYU00760" checked={this.props.pageState.JYU00760} {...params} />
                                    <span className="checkBox-text">4.汲取式</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </td>
        );

        // 配管・供給等の状況
        let DOMPipingSupply = (<td className="dataTable-body dataTable-body__top"></td>);
        DOMPipingSupply = (
            <td className="dataTable-body dataTable-body__top dataTable-body__bottomborder">
                <div className="dataTableMultiple">
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-text">前面道路配管</div>
                            <div className="dataTableUnit-input">
                                <ul className="l-checkBox l-checkBox--minOffset">
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU00761" name="JYU00761" checked={this.props.pageState.JYU00761} {...params} />
                                            <span className="checkBox-text">有</span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU01394" name="JYU01394" checked={this.props.pageState.JYU01394} {...params} />
                                            <span className="checkBox-text">無</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-text">私設管の有無</div>
                            <div className="dataTableUnit-input">
                                <ul className="l-checkBox l-checkBox--minOffset">
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU00762" name="JYU00762" checked={this.props.pageState.JYU00762} {...params} />
                                            <span className="checkBox-text">有</span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU01395" name="JYU01395" checked={this.props.pageState.JYU01395} {...params} />
                                            <span className="checkBox-text">無</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-text">浄化槽の設置</div>
                            <div className="dataTableUnit-input">
                                <ul className="l-checkBox l-checkBox--minOffset">
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU00763" name="JYU00763" checked={this.props.pageState.JYU00763} {...params} />
                                            <span className="checkBox-text">既設</span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU00764" name="JYU00764" checked={this.props.pageState.JYU00764} {...params} />
                                            <span className="checkBox-text">可</span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU00765" name="JYU00765" checked={this.props.pageState.JYU00765} {...params} />
                                            <span className="checkBox-text">不可</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        );

        // 整備予定・負担金
        let DOMMaintenance = (<td className="dataTable-body dataTable-body__top"></td>);
        DOMMaintenance = (
            <td className="dataTable-body dataTable-body__top dataTable-body__bottomborder">
                <div className="dataTableMultiple">
                    <div className="dataTableMultiple-item">
                        <ul className="l-checkBox l-checkBox--minOffset">
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU01396" name="JYU01396" checked={this.props.pageState.JYU01396} {...params} />
                                    <span className="checkBox-text">無</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00766" name="JYU00766" checked={this.props.pageState.JYU00766} {...params} />
                                    <span className="checkBox-text">有</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <Select id="ID_JYU01635" name="JYU01635" value={this.props.pageState.JYU01635} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} disabled={JYU01396_disabled} length="w80" />
                            <Select id="ID_JYU01636" name="JYU01636" value={this.props.pageState.JYU01636} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} disabled={JYU01396_disabled} length="w80" />
                            <div className="dataTableUnit-text">年</div>
                            <Select id="ID_JYU01637" name="JYU01637" value={this.props.pageState.JYU01637} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} disabled={JYU01396_disabled} length="w80" />
                            <div className="dataTableUnit-text">月</div>
                            <div className="dataTableUnit-text">頃</div>
                        </div>
                    </div>
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-input">
                                <Input id="ID_JYU00768" name="JYU00768" value={this.props.pageState.JYU00768} {...params} disabled={JYU01396_disabled} />
                            </div>
                            <div className="dataTableUnit-text">円</div>
                        </div>
                    </div>
                </div>
            </td>
        );

        return (
            <tr>
                <th className="dataTable-title dataTable-title__center">汚水</th>
                {DOMAvailableFacilities}
                {DOMPipingSupply}
                {DOMMaintenance}
            </tr>
        );
    }

    /**
     * 整備状況：雑排水
     */
    MaintenanceMiscellaneousDrainage(props, params) {

        // 非活性の設定
        let JYU01399_disabled = this.props.pageState.JYU01399;

        // ハイフンの設定
        if (!this.isSameJYU01399 && this.props.pageState.JYU01399) {
            this.isSameJYU01399 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU01638", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU01639", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU01640", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU00778", this.HYPHEN_TEXT_AMOUNT));
        }

        if (this.isSameJYU01399 && !this.props.pageState.JYU01399) {
            this.isSameJYU01399 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00778", ""));
        }

        // 直ちに利用可能な施設
        let DOMAvailableFacilities = (<td className="dataTable-body dataTable-body__top"></td>);
        DOMAvailableFacilities = (
            <td className="dataTable-body dataTable-body__top is-rightborder">
                <div className="dataTableUnit_vr">
                    <div className="dataTableUnit-input">
                        <ul className="l-checkBox l-checkBox--vertical">
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00769" name="JYU00769" checked={this.props.pageState.JYU00769} {...params} />
                                    <span className="checkBox-text">1. 公共下水</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00770" name="JYU00770" checked={this.props.pageState.JYU00770} {...params} />
                                    <span className="checkBox-text">2.個別浄化槽</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00771" name="JYU00771" checked={this.props.pageState.JYU00771} {...params} />
                                    <span className="checkBox-text">3.集中浄化槽</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00772" name="JYU00772" checked={this.props.pageState.JYU00772} {...params} />
                                    <span className="checkBox-text">4.側溝等</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00773" name="JYU00773" checked={this.props.pageState.JYU00773} {...params} />
                                    <span className="checkBox-text">5.浸透式</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </td>
        );

        // 配管・供給等の状況
        let DOMPipingSupply = (<td className="dataTable-body dataTable-body__top"></td>);
        DOMPipingSupply = (
            <td className="dataTable-body dataTable-body__top">
                <div className="dataTableMultiple">
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-text">前面道路配管</div>
                            <div className="dataTableUnit-input">
                                <ul className="l-checkBox l-checkBox--minOffset">
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU00774" name="JYU00774" checked={this.props.pageState.JYU00774} {...params} />
                                            <span className="checkBox-text">有</span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU01397" name="JYU01397" checked={this.props.pageState.JYU01397} {...params} />
                                            <span className="checkBox-text">無</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-text">私設管の有無</div>
                            <div className="dataTableUnit-input">
                                <ul className="l-checkBox l-checkBox--minOffset">
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU00775" name="JYU00775" checked={this.props.pageState.JYU00775} {...params} />
                                            <span className="checkBox-text">有</span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkBox">
                                            <InputCheckbox id="ID_JYU01398" name="JYU01398" checked={this.props.pageState.JYU01398} {...params} />
                                            <span className="checkBox-text">無</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        );

        // 整備予定・負担金
        let DOMMaintenance = (<td className="dataTable-body dataTable-body__top"></td>);
        DOMMaintenance = (
            <td className="dataTable-body dataTable-body__top">
                <div className="dataTableMultiple">
                    <div className="dataTableMultiple-item">
                        <ul className="l-checkBox l-checkBox--minOffset">
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU01399" name="JYU01399" checked={this.props.pageState.JYU01399} {...params} />
                                    <span className="checkBox-text">無</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00776" name="JYU00776" checked={this.props.pageState.JYU00776} {...params} />
                                    <span className="checkBox-text">有</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <Select id="ID_JYU01638" name="JYU01638" value={this.props.pageState.JYU01638} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} disabled={JYU01399_disabled} length="w80" />
                            <Select id="ID_JYU01639" name="JYU01639" value={this.props.pageState.JYU01639} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} disabled={JYU01399_disabled} length="w80" />
                            <div className="dataTableUnit-text">年</div>
                            <Select id="ID_JYU01640" name="JYU01640" value={this.props.pageState.JYU01640} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} disabled={JYU01399_disabled} length="w80" />
                            <div className="dataTableUnit-text">月</div>
                            <div className="dataTableUnit-text">頃</div>
                        </div>
                    </div>
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-input">
                                <Input id="ID_JYU00778" name="JYU00778" value={this.props.pageState.JYU00778} {...params} disabled={JYU01399_disabled} />
                            </div>
                            <div className="dataTableUnit-text">円</div>
                        </div>
                    </div>
                </div>
            </td>
        );

        return (
            <tr>
                <th className="dataTable-title dataTable-title__center">雑排水</th>
                {DOMAvailableFacilities}
                {DOMPipingSupply}
                {DOMMaintenance}
            </tr>
        );
    }

    /**
     * 整備状況：雨水
     */
    MaintenanceRainwater(props, params) {

        // 非活性の設定
        let JYU01400_disabled = this.props.pageState.JYU01400;

        // ハイフンの設定
        if (!this.isSameJYU01400 && this.props.pageState.JYU01400) {
            this.isSameJYU01400 = true;
            this.props.updatePageState(this.genUpdateInformation("JYU01641", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU01642", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU01643", this.HYPHEN_TEXT_DATE));
            this.props.updatePageState(this.genUpdateInformation("JYU00784", this.HYPHEN_TEXT_AMOUNT));
        }

        if (this.isSameJYU01400 && !this.props.pageState.JYU01400) {
            this.isSameJYU01400 = false;
            this.props.updatePageState(this.genUpdateInformation("JYU00784", ""));
        }

        // 直ちに利用可能な施設
        let DOMAvailableFacilities = (<td className="dataTable-body dataTable-body__top"></td>);
        DOMAvailableFacilities = (
            <td className="dataTable-body dataTable-body__top is-rightborder">
                <div className="dataTableUnit_vr">
                    <div className="dataTableUnit-input">
                        <ul className="l-checkBox l-checkBox--vertical">
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00779" name="JYU00779" checked={this.props.pageState.JYU00779} {...params} />
                                    <span className="checkBox-text">1. 公共下水</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00780" name="JYU00780" checked={this.props.pageState.JYU00780} {...params} />
                                    <span className="checkBox-text">2.側溝等</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00781" name="JYU00781" checked={this.props.pageState.JYU00781} {...params} />
                                    <span className="checkBox-text">3.浸透</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </td>
        );

        // 配管・供給等の状況
        let DOMPipingSupply = (<td className="dataTable-body dataTable-body__top">（浄化槽への雨水の流入はできません）</td>);

        // 整備予定・負担金
        let DOMMaintenance = (<td className="dataTable-body dataTable-body__top"></td>);
        DOMMaintenance = (
            <td className="dataTable-body dataTable-body__top">
                <div className="dataTableMultiple">
                    <div className="dataTableMultiple-item">
                        <ul className="l-checkBox l-checkBox--minOffset">
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU01400" name="JYU01400" checked={this.props.pageState.JYU01400} {...params} />
                                    <span className="checkBox-text">無</span>
                                </label>
                            </li>
                            <li>
                                <label className="checkBox">
                                    <InputCheckbox id="ID_JYU00782" name="JYU00782" checked={this.props.pageState.JYU00782} {...params} />
                                    <span className="checkBox-text">有</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <Select id="ID_JYU01641" name="JYU01641" value={this.props.pageState.JYU01641} items={this.getSelectboxWithHyphen(dateUtil.genEraNameList())} {...params} disabled={JYU01400_disabled} length="w80" />
                            <Select id="ID_JYU01642" name="JYU01642" value={this.props.pageState.JYU01642} items={this.getSelectboxWithHyphen(dateUtil.genJapaneseImperialYearList())} {...params} disabled={JYU01400_disabled} length="w80" />
                            <div className="dataTableUnit-text">年</div>
                            <Select id="ID_JYU01643" name="JYU01643" value={this.props.pageState.JYU01643} items={this.getSelectboxWithHyphen(dateUtil.genMonthList())} {...params} disabled={JYU01400_disabled} length="w80" />
                            <div className="dataTableUnit-text">月</div>
                            <div className="dataTableUnit-text">頃</div>
                        </div>
                    </div>
                    <div className="dataTableMultiple-item">
                        <div className="dataTableUnit">
                            <div className="dataTableUnit-input">
                                <Input id="ID_JYU00784" name="JYU00784" value={this.props.pageState.JYU00784} {...params} disabled={JYU01400_disabled} />
                            </div>
                            <div className="dataTableUnit-text">円</div>
                        </div>
                    </div>
                </div>
            </td>
        );

        return (
            <tr>
                <th className="dataTable-title dataTable-title__center">雨水</th>
                {DOMAvailableFacilities}
                {DOMPipingSupply}
                {DOMMaintenance}
            </tr>
        );
    }

    // 備考等のテキストエリアを作成
    CreateTextArea(params, textAreaID, textAreaVal, error) {
        return (
            <td className="dataTable-body dataTable-body__top">
                <div className="dataTableTextUnit">
                    <InputTextArea id={`ID_${textAreaID}`} name={textAreaID} value={textAreaVal} placeholder={error.inputLimitGuide} {...params} height="h148" />
                    <p className="LengthOver-error">{error.inputLimitMsg}</p>
                </div>
            </td>
        );
    }

}