import BaseGrid from "components/common/frame/baseGrid";
import {
    InputCheckBoxRecord, InputRadioBoxRecord, InputSelectBoxRecord,
    InputSelectBoxRecordWithoutRow, InputTextRecord, InputTextRecordWithoutRow
} from "components/common/parts/inputRecord";
import { LinkToOtherDomain } from "components/common/parts/link";
import { AUTHORITY_FRK } from "constants/authority";
import * as AWS from "constants/aws";
import { CONTRACT_EDIT_INFO_FRK, CONTRACT_TYPE_FRK, CONTRACT_TYPE_ID, EDIT_AUTHORITY } from "constants/contractEditPageInfo";
import { ERROR_MESSAGE } from "constants/errorMessage";
import { USE_CLAUSE } from "constants/format";
import * as pageInfo from "constants/pageInfo";
import { PREFECTURES } from "constants/prefectures";
import { REQUIRED_ITEM_LIST } from "constants/requiredItem";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as loginUserAction from "redux/actions/loginUserAction";
import * as awsReq from "util/awsRequest";
import * as dateUtil from "util/dateUtil";
import Validation from "util/validate";
import EmployeeList from "./popup/popupEmployeeList";
import PastContractList from "./popup/popupPastContractList";
import ShareList from "./popup/popupShareList";

class ContractRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contractTypeId: CONTRACT_TYPE_ID.ID_0101, // 契約種別ID（初期値はセレクトボックスの最初のヤツ）
            propertyPrefectures: undefined, // 都道府県
            propertyCity: undefined, // 市区町村
            propertyAddress: undefined, // 丁目
            buildingName: undefined, // 物件名（マンション名、備考など）
            documentNo: undefined, // 契約No.
            useClause: USE_CLAUSE.KANTOU_LOCAL.value, // 約款(デフォルト：関東地方)
            headquartersConfirm: false, // 本部確定フラグ
            shareList: [], // 共有先の状態（共有先のリスト（初期登録なのでAPIからの取得は無い））
            shareListTmp: [], // 契約情報共有の確定が押下されるまでのテンポラリ用途
            pastContract: undefined, // 情報一覧(過去物件)参照情報
            popupShareListActive: false, // 共有のポップアップを表示する：true
            popupEmployeeListActive: false, // 従業員一覧のポップアップを表示する：true
            popupPastContractListActive: false, // 情報一覧(過去物件)参照のポップアップを表示する：true
            initialize: false, // 初期設定済みかどうか
        };

        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();
        // 画面から入力される値の情報
        this.params = {
            propertyPrefectures: { validate: this.validation.noCheck, },
            propertyCity: { validate: this.validation.noCheck, },
            propertyAddress: { validate: this.validation.noCheck, },
            buildingName: { validate: this.validation.notEmpty, },
            documentNo: { validate: this.validation.notEmpty, },
            useClause: { validate: this.validation.notEmpty, },
            headquartersConfirm: { validate: this.validation.noCheck, },
        };
    }
    componentDidMount() {
        document.title = pageInfo.CONTRACT_REGISTER.title;
        // 初期化処理（普通に画面遷移してきた時はこっち）
        this.initialize();
    }

    componentDidUpdate() {
        // 初期化処理（リロード時はこっち）
        this.initialize();
    }

    /** 初期設定する */
    initialize = () => {
        // ログインユーザのagencyUserId、userNameをReduxから取得し、担当営業として設定する
        // また、ログインユーザの同一組織(agencyBranchId)に所属している課長及び場所長を共有先ユーザとして抽出する
        // ※
        // this.props.agencyUserId はRedux管理の値だが、取得できるようになるタイミングがあるようなので、
        // componentDidUpdate()の中でやる。
        if (!this.state.initialize && this.props.agencyUserId && this.props.authority) {
            if (this.props.agencyBranchId) {
                // 不動産仲介会社従業員情報一覧取得APIの呼出し
                // 同一支店・組織の全従業員を取得する必要があるため、pageを指定しない
                const query = {
                    agency_branch_id: this.props.agencyBranchId
                };
                awsReq.post(
                    AWS.ENDPOINT.PATHS.GET_POPUP_AGENCY_USER_LIST,
                    res => {
                        // 共有先の表示内容を編集
                        const shareUserList = displayShareUserList(this.props, res.data.agency_users);
                        // 共有先の表示内容を設定
                        this.setState({ initialize: true, shareList: shareUserList, shareListTmp: shareUserList, });
                    },
                    error => { alert(ERROR_MESSAGE.SERVER_ERROR); },
                    query,
                    {}, AWS.ENDPOINT.REST_API.NAME,
                );
            } else {
                // 共有先の表示内容を編集
                const shareUserList = displayShareUserList(this.props, undefined);
                // 共有先の表示内容を設定
                this.setState({ initialize: true, shareList: shareUserList, shareListTmp: shareUserList, });
            }
        }
    }

    /** 契約種別IDのハンドラ */
    handleContractTypeIdSelect = e => {
        this.setState({ contractTypeId: e.target.value });
    }

    /** 物件名_都道府県のハンドラ */
    handlePropertyPrefecturesSelect = e => {
        this.setState({ propertyPrefectures: e.target.value });
    }

    /** 物件名_市区町村のハンドラ */
    handlePropertyCityInput = e => {
        this.setState({ propertyCity: e.target.value });
    }

    /** 物件名_番地のハンドラ */
    handlePropertyAddressInput = e => {
        this.setState({ propertyAddress: e.target.value });
    }

    /** 物件名のハンドラ */
    handleBuildingNameInput = e => {
        this.setState({ buildingName: e.target.value });
    }

    /** 稟議書No.のハンドラ */
    handleDocumentNoInput = e => {
        this.setState({ documentNo: e.target.value });
    }

    /** 利用約款のハンドラ */
    handleUseClauseInput = e => {
        this.setState({ useClause: Number(e.target.value) });
    }

    /** 本部確定フラグのハンドラ */
    handleheadquartersConfirmCheck = e => {
        const target = !this.state.headquartersConfirm;
        this.setState({ headquartersConfirm: target });
    }

    /** 入力ボックスの入力値を空文字チェック */
    handleInputOnBlur = e => {
        const target = e.target;
        const param = this.params[target.name];
        param.validate(target.name, target.value);
        this.setState({});  // 再renderする為の空セット
    }

    /** 共有先の更新 */
    updateShareList = arrays => {
        this.setState({ shareList: arrays });
    }

    /** 共有先（テンポラリ）の更新 */
    updateShareListTmp = arrays => {
        let sorted = JSON.parse(JSON.stringify(arrays));
        sorted.sort((a, b) => {
            if (a.agency_user_id > b.agency_user_id) {
                return 1;
            }
            if (a.agency_user_id < b.agency_user_id) {
                return -1;
            }
            return 0;
        });
        this.setState({ shareListTmp: sorted });
    }

    /** 編集ボタンのハンドラ */
    handleEditBtn = e => {
        e.preventDefault();
        this.openPopupShareList();
    }

    /** 共有のポップアップを開く */
    openPopupShareList = e => {
        this.setState({ popupShareListActive: true });
    }

    /** 従業員一覧のポップアップを開く */
    openPopupEmployeeList = () => {
        this.setState({ popupEmployeeListActive: true });
    }

    /** 情報一覧(過去物件)参照のハンドラ */
    handlePastContractBtn = e => {
        e.preventDefault();
        this.openPopupPastContractList();
    }

    /** 情報一覧(過去物件)のポップアップを開く */
    openPopupPastContractList = () => {
        this.setState({ popupPastContractListActive: true });
    }

    /** 情報一覧(過去物件)の更新 */
    updatePastContract = (obj) => {
        this.setState({ pastContract: obj });
    }

    /** 共有のポップアップを閉じる */
    closePopupShareList = () => {
        this.setState({ popupShareListActive: false });
    }

    /** 従業員一覧のポップアップを閉じる */
    closePopupEmployeeList = () => {
        this.setState({ popupEmployeeListActive: false });
    }

    /** 情報一覧(過去物件)のポップアップを閉じる */
    closePopupPastContractList = () => {
        this.setState({ popupPastContractListActive: false });
    }

    handleCreateBtn = e => {
        e.preventDefault();
        if (this.validation.validateTotalInputText(this.params, this.state)) {
            this.setState({});
            return;
        }

        // ポップアップの中身を表示するためのAPIリクエスト
        /** 情報一覧(過去物件)取得 */
        if (!this.apicalling) {
            this.apicalling = true;

            let query = {};

            query.contract_type_id = this.state.contractTypeId; // 契約種別ID

            query.property_prefectures = this.state.propertyPrefectures; // 都道府県
            query.property_city = this.state.propertyCity; // 市区町村
            query.property_address = this.state.propertyAddress; // 丁目
            query.building_name = this.state.buildingName; // 物件名
            query.document_no = this.state.documentNo; // 契約No.
            query.use_clause = this.state.useClause; // 約款
            query.headquarters_confirm = this.state.headquartersConfirm; // 本部確定フラグ

            // 共有先のパラメータ作成
            // ※APIから取得した結果から、user_nameを除いたものをパラメータとして使用する
            query.contract_sharing_authority_list = this.state.shareList.map(item => {
                return {
                    agency_user_id: item.agency_user_id,
                    sharing_authority_id: item.sharing_authority_id,
                };
            });

            // 情報一覧(過去物件)のパラメータ作成 ※指定されている場合のみ（undefinedの場合、パラメータに設定されない）
            query.past_property_id = undefined;
            if (this.state.pastContract) {
                query.past_property_id = this.state.pastContract.id;
                query.required_item = REQUIRED_ITEM_LIST[this.state.contractTypeId];
            }

            awsReq.post(
                AWS.ENDPOINT.PATHS.CONTRACTS_FRK,
                res => {
                    this.apicalling = false;
                    const id = res.headers["location"].split("/").slice(-1)[0];
                    this.props.history.push(
                        pageInfo.CONTRACT_EDIT_FRK.path
                            .replace(":contractId", id)
                            .replace(":pageId", CONTRACT_EDIT_INFO_FRK.PAGE_0101.id)
                        + '?param=' + dateUtil.tzStrToParm()
                    );
                },
                error => {
                    this.apicalling = false;
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                    this.setState({});
                },
                query,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
            this.setState({});
        }
    }

    /** ポップアップ表示中に背景を暗くする用 */
    createModelOverlayActiveDOM = () => {
        if (this.state.popupShareListActive ||
            this.state.popupEmployeeListActive ||
            this.state.popupPastContractListActive) {
            return (
                <div className="l-modal_overlay is-active">
                    <div className="modal_overlay"></div>
                </div>
            );
        }
        return null;
    }

    render() {
        // 共有先一覧を表示用に整形
        // ※ 名前のリストを生成し、
        let shareListDp = this.state.shareList.map(item => {
            return item.user_name + displayAuthority(item.authority);
        });
        // ※ 空白区切りで１つの文字列にする
        shareListDp = shareListDp.join(" ");

        let pastBuildingName = this.state.pastContract ? this.state.pastContract.building_name : "　";
        let pastDocumentNo = this.state.pastContract ? this.state.pastContract.document_no : "　";

        // POST中は契約情報作成ボタンを押せなくする
        const createParams = (!this.apicalling)
            ? { className: "baseAction baseAction--agree is-active", onClick: this.handleCreateBtn }
            : { className: "baseAction baseAction--agree is-active baseAction--inactive", onClick: e => e.preventDefault() };

        // セレクトリストの生成
        const contractTypes = CONTRACT_TYPE_FRK;
        let contractTypeList = contractTypes
            ? contractTypes.map(contractType => ({ value: contractType.contract_type_id, display: contractType.name }))
            : [];
        const titleSetting = { className: "dataTable-title dataTable-title__w120" };

        const prefectures = PREFECTURES;
        let prefecturesList = prefectures
            ? prefectures.map(prefecture => ({ value: prefecture, display: prefecture }))
            : [];
        prefecturesList.unshift({ value: "", display: "" });

        // バリデートエラー時のエラーメッセージの設定
        let inputPropertyPrefecturesSettings = {
            errorMessage: this.validation.errorMessage.propertyPrefectures,
            class: "dataTableUnit-input dataTableUnit-input__wFull",
        };
        if (this.validation.errorMessage.propertyPrefectures) {
            inputPropertyPrefecturesSettings.class = inputPropertyPrefecturesSettings.class + " is-error";
        }

        let inputPropertyCitySettings = {
            errorMessage: this.validation.errorMessage.propertyCity,
            class: "dataTableUnit-input dataTableUnit-input__wFull",
        };
        if (this.validation.errorMessage.propertyCity) {
            inputPropertyCitySettings.class = inputPropertyCitySettings.class + " is-error";
        }

        let inputPropertyAddressSettings = {
            errorMessage: this.validation.errorMessage.propertyAddress,
            class: "dataTableUnit-input dataTableUnit-input__wFull",
        };
        if (this.validation.errorMessage.propertyAddress) {
            inputPropertyAddressSettings.class = inputPropertyAddressSettings.class + " is-error";
        }

        let inputBuildingNameSettings = {
            errorMessage: this.validation.errorMessage.buildingName,
            class: "dataTableUnit-input dataTableUnit-input__wFull",
        };
        if (this.validation.errorMessage.buildingName) {
            inputBuildingNameSettings.class = inputBuildingNameSettings.class + " is-error";
        }

        let inputDocumentNoSettings = {
            errorMessage: this.validation.errorMessage.documentNo,
            class: "dataTableUnit-input dataTableUnit-input__w201",
        };
        if (this.validation.errorMessage.documentNo) {
            inputDocumentNoSettings.class = inputDocumentNoSettings.class + " is-error";
        }

        return (
            <React.Fragment>
                <BaseGrid>
                    <ul className="l-dataList">
                        <li>
                            <div className="dataList dataList__accordion is-open">
                                <div className="dataList-header">契約種別選択</div>
                                <div className="dataList-body">
                                    <table className="dataTable">
                                        <tbody>
                                            <tr>
                                                <InputSelectBoxRecord titleName="契約書式"
                                                    inputData={{ name: "contractTypeId", value: this.state.contractTypeId || "" }}
                                                    optionsDataList={contractTypeList} titleSetting={titleSetting}
                                                    inputSetting={{ onChange: this.handleContractTypeIdSelect }}
                                                />
                                            </tr>
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w120">物件名</th>
                                                <td className="dataTable-body" colSpan="2">
                                                    <div className="dataTableUnit">
                                                        <InputSelectBoxRecordWithoutRow
                                                            inputData={{ name: "propertyPrefectures", value: this.state.propertyPrefectures || "" }}
                                                            inputSetting={{ onChange: this.handlePropertyPrefecturesSelect }}
                                                            optionsDataList={prefecturesList}
                                                            selectClassName="selectBox__w120"
                                                            lengthForErrMsg="w201"
                                                        />
                                                        <div className="dataTableUnit-text">／</div>
                                                        <InputTextRecordWithoutRow
                                                            inputData={{ name: "propertyCity", value: this.state.propertyCity || "" }}
                                                            inputSetting={{ onChange: this.handlePropertyCityInput, onBlur: this.handleInputOnBlur, placeholder: "市区町村" }}
                                                            lengthForErrMsg="w201"
                                                            inputClassName="input__w270"
                                                            errorMessage={this.validation.errorMessage.propertyCity || ""}
                                                        />
                                                        <div className="dataTableUnit-text">／</div>
                                                        <InputTextRecordWithoutRow
                                                            inputData={{ name: "propertyAddress", value: this.state.propertyAddress || "" }}
                                                            inputSetting={{ onChange: this.handlePropertyAddressInput, onBlur: this.handleInputOnBlur, placeholder: "丁目" }}
                                                            lengthForErrMsg="w201"
                                                            inputClassName="input__w270"
                                                            errorMessage={this.validation.errorMessage.propertyAddress || ""}
                                                        />
                                                        <InputTextRecordWithoutRow
                                                            inputData={{ name: "buildingName", value: this.state.buildingName || "" }}
                                                            inputSetting={{ className: "", onChange: this.handleBuildingNameInput, onBlur: this.handleInputOnBlur, placeholder: "マンション名、備考など" }}
                                                            lengthForErrMsg="w201"
                                                            inputClassName="wFull"
                                                            errorMessage={this.validation.errorMessage.buildingName || ""}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <InputTextRecord
                                                    titleName="稟議書No."
                                                    titleSetting={{ className: "dataTable-title dataTable-title__w120" }}
                                                    bodySetting={{ colSpan: "2" }}
                                                    inputData={{ name: "documentNo", value: this.state.documentNo || "" }}
                                                    inputSetting={{ className: "input__w201", onChange: this.handleDocumentNoInput, onBlur: this.handleInputOnBlur }}
                                                    lengthForErrMsg="w201"
                                                    errorMessage={this.validation.errorMessage.documentNo || ""}
                                                />
                                            </tr>
                                            <tr>
                                                <InputRadioBoxRecord
                                                    titleName="約款"
                                                    titleSetting={{ className: "dataTable-title dataTable-title__w120" }}
                                                    bodySetting={{ colSpan: "2" }}
                                                    inputSetting={{ onChange: this.handleUseClauseInput }}
                                                    inputData={{ name: "useClause", value: this.state.useClause }}
                                                    radioDataList={[
                                                        { display: USE_CLAUSE.KANTOU_LOCAL.display, value: USE_CLAUSE.KANTOU_LOCAL.value },
                                                        { display: USE_CLAUSE.KANSAI_LOCAL.display, value: USE_CLAUSE.KANSAI_LOCAL.value }
                                                    ]}
                                                    errorMessage={this.validation.errorMessage.useClause || ""}
                                                />
                                            </tr>
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w120">共有先</th>
                                                <td className="dataTable-body">{shareListDp}</td>
                                                <td className="dataTable-body dataTable-body__w110">
                                                    <Link to="" aria-label="link" className="baseAction" onClick={this.handleEditBtn}>編集</Link>
                                                </td>
                                            </tr>
                                            <tr>
                                                <InputCheckBoxRecord
                                                    titleName="確定"
                                                    titleSetting={titleSetting}
                                                    bodySetting={{ colSpan: "2" }}
                                                    inputSetting={{ onChange: this.handleheadquartersConfirmCheck }}
                                                    inputData={{ name: "headquartersConfirm", checked: this.state.headquartersConfirm }}
                                                    checkboxText={"本部稟議の場合はチェック要"}
                                                />
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </li>
                        {/* 参照: CONTRACT_CLOUD_ENHANCE-209 【仕様書】各機能の詳細確認
                        過去情報参照機能は、今後復活する可能性があるため一時的にコメントアウトとする
                        コメントアウトされた部分にのみ存在する変数・関数はそのまま放置している */}
                        {/* <li>
                            <div className="dataList dataList__accordion is-open">
                                <div className="dataList-header">情報一覧参照</div>
                                <div className="dataList-body">
                                    <table className="dataTable">
                                        <tbody>
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w120">物件名</th>
                                                <td colSpan="2" className="dataTable-body">{pastBuildingName}</td>
                                            </tr>
                                            <tr>
                                                <th className="dataTable-title dataTable-title__w120">稟議書No.</th>
                                                <td className="dataTable-body">{pastDocumentNo}</td>
                                                <td className="dataTable-body dataTable-body__w110">
                                                    <Link to="" aria-label="link" className="baseAction" onClick={this.handlePastContractBtn}>検索</Link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </li> */}
                    </ul>
                    {/* 個人情報の取り扱いについて部分 */}
                    <div className="l-personalInfo">
                        <div className="personalInfo">
                            {/* 規約本文 */}
                            <PersonalInfo />

                            <div className="personalInfo-note">※弊社への個人情報提供に関するお客様の承諾を得ていることをご確認の上、契約情報作成へお進みください。</div>
                            <div className="personalInfo-action">
                                <ul className="l-baseAction l-baseAction__centerRightAction">
                                    <li><Link to="" aria-label="link" {...createParams}>個人情報の取り扱いに同意の上<br />契約情報作成</Link></li>
                                    <li><Link to={pageInfo.DASHBOARD.path} aria-label="link" className="baseAction">キャンセル</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* ポップアップ表示中に背景を暗くする用DOM */}
                    {this.createModelOverlayActiveDOM()}

                    {/* 共有先ポップアップ */}
                    <ShareList
                        shareList={this.state.shareList}
                        shareListTmp={this.state.shareListTmp}
                        active={this.state.popupShareListActive}
                        onClose={this.closePopupShareList}
                        onAdd={this.openPopupEmployeeList}
                        onCommit={this.updateShareList}
                        updateShareListTmp={this.updateShareListTmp}
                    />

                    {/* 従業員一覧ポップアップ */}
                    <EmployeeList
                        shareList={this.state.shareListTmp} // テンポラリの方の情報を参照する
                        active={this.state.popupEmployeeListActive}
                        onClose={this.closePopupEmployeeList}
                        onCommit={this.updateShareListTmp}  // 確定押下時にテンポラリの方の情報を更新する
                    />

                    {/* 情報一覧(過去物件)ポップアップ */}
                    <PastContractList
                        active={this.state.popupPastContractListActive}
                        onClose={this.closePopupPastContractList}
                        updatePastContract={this.updatePastContract}
                        authority={this.props.authority}
                    />
                </BaseGrid >
            </React.Fragment >
        );
    }
}

const PersonalInfo = () => {
    return (
        <React.Fragment>
            <div className="personalInfo-header">個人情報の取り扱いについて</div>
            <div className="personalInfo-main" tabIndex="0">
                <div className="personalInfo-main-read">SREホールディングス株式会社は、契約書類作成システム（以下「本サービス」といいます）のご利用に際しご入力いただいたお客様の個人情報を以下の定めに従い取り扱います。</div>
                <div className="personalInfo-main-subTitle">1.個人情報の収集・利用・管理責任部署</div>
                <p className="personalInfo-main-text">SREホールディングス株式会社　個人情報担当窓口</p>
                <div className="personalInfo-main-subTitle">2.収集する個人情報</div>
                <p className="personalInfo-main-text">お名前、ご住所、電話番号、FAX番号、電子メールアドレス、物件情報、その他本サービスのご利用に際し入力された個人情報</p>
                <div className="personalInfo-main-subTitle">3.個人情報の利用目的</div>
                <ul className="personalInfo-main-sublist">
                    <li>(1)本サービスの提供、およびお客様からのお問い合わせへの対応</li>
                    <li>(2)不動産に関する売買仲介、賃貸管理、リフォーム・リノベーション、コンサルティング、その他これらに附帯または関連する事業におけるサービスの提供、およびお客様からのお問い合わせへの対応</li>
                    <li>(3)上記(1)または(2)の利用目的の達成に必要な範囲での、個人情報の第三者への提供</li>
                    <li>(4)上記(1)または(2)の事業におけるサービス提供のための郵便物、電話、電子メール等による営業活動、マーケティング活動（アンケートのお願い等）および顧客動向等の調査・分析</li>
                    <li>(5)取引先との各種連絡および資料の授受</li>
                </ul>
                <div className="personalInfo-main-subTitle">4.利用目的の範囲内での利用</div>
                <p className="personalInfo-main-text">当社は、あらかじめお客様ご本人の同意を得た場合および法令により認められた場合を除き、上記3の利用目的の達成に必要な範囲内でのみ個人情報を取り扱います。</p>
                <div className="personalInfo-main-subTitle">5.委託先の監督</div>
                <p className="personalInfo-main-text">当社は、上記3の利用目的の達成に必要な範囲内において、お預かりした個人情報の取り扱いを第三者に委託する場合があります。これらの第三者は、個人情報の取り扱いにつき、十分なセキュリティ水準にあることを確認の上選定し、契約等を通じて、必要かつ適切な監督を行います。</p>
                <div className="personalInfo-main-subTitle">6.個人情報の第三者提供について</div>
                <p className="personalInfo-main-text">
                    当社は、お客様の個人データを、法令の規定に基づく場合のほか、オプトアウト制度を利用して、上記3の利用目的の達成に必要な範囲で、第三者に提供することがあります（個々の案件に応じて個人情報保護委員会への届出が必要な場合は、同委員会へ届出を行います）。なお、お客様ご本人からお申し出があった場合、当該本人が識別される個人データの第三者への提供を停止いたしますので、下記の「個人情報に関するお問い合わせ窓口」までお申し出ください。
                </p>
                <div className="personalInfo-main-subTitle">(1)提供する個人情報の項目</div>
                <p className="personalInfo-main-text">お名前、ご住所、電話番号、FAX番号、電子メールアドレス、物件情報のほか、当社事業におけるサービスの提供に必要な最低限の項目</p>
                <div className="personalInfo-main-subTitle">(2)提供の手段・方法</div>
                <p className="personalInfo-main-text">書面、郵便、電話、FAX、電子メール、インターネット、広告媒体等</p>
                <div className="personalInfo-main-subTitle">(3)提供する第三者</div>
                <p className="personalInfo-main-text">
                    不動産売買・賃貸の仲介における契約相手方となる者、その見込者、他の宅地建物取引業者、インターネット広告掲載業者、不動産事業者団体、および指定流通機構(物件登録、成約通知および同機構のデータを利用しての営業、価格査定等を実施する場合)等<br />不動産取引の附帯業務における金融機関、司法書士、土地家屋調査士、不動産鑑定士、不動産管理会社、リフォーム会社、引越会社、保険会社等
                </p>
                <div className="personalInfo-main-subTitle">7.保有個人データに関する事項</div>
                <div className="personalInfo-main-subTitle">(1)当該個人情報取扱業者(当社)の名称</div>
                <p className="personalInfo-main-text">SREホールディングス株式会社</p>
                <div className="personalInfo-main-subTitle">(2)すべての保有個人データの利用目的</div>
                <ul className="personalInfo-main-sublist">
                    <li>a.本サービスの提供</li>
                    <li>b.不動産に関する売買仲介、賃貸管理、リフォーム・リノベーション、コンサルティング、その他これらに附帯または関連する事業におけるサービスの提供</li>
                    <li>c.上記a.またはb.の利用目的の達成に必要な範囲での個人情報の第三者への提供</li>
                    <li>d.上記a.またはb.の事業におけるサービス提供のための郵便物、電話、電子メール等による営業活動、マーケティング活動(アンケートのお願い等)および顧客動向等の調査・分析</li>
                    <li>e.取引先との各種連絡および資料の授受</li>
                </ul>
                <div className="personalInfo-main-subTitle">(3)開示等の求めに応じる手続き</div>
                <p className="personalInfo-main-text">当社保有個人データに関する利用目的の通知、開示、訂正等、利用停止等、および第三者提供停止に関するお問い合わせにつきましては、下記の窓口にご請求ください。手続きの詳細は、「<LinkToOtherDomain to="https://sre-group.co.jp/terms/privacy.html" target="_blank">保有個人データの開示等の求めに応じる手続き(別サイト)</LinkToOtherDomain>」をご確認ください。</p>
                <div className="personalInfo-main-subTitle">(4)保有個人データの取り扱いについて当社が設置する苦情・相談のお申出先窓口</div>
                <p className="personalInfo-main-text">当社保有個人データに関する苦情・相談につきましては、下記の窓口にて承ります。</p>
                <div className="personalInfo-main-subTitle">8.入力必須とさせていただいた情報のご提供がない場合について</div>
                <p className="personalInfo-main-text">入力必須とさせていただいた情報のご提供がない場合には、サービスの提供に応じられない場合がございますので、ご了承ください。</p>
                <div className="personalInfo-main-subTitle">9.個人情報に関するお問い合わせ窓口</div>
                <p className="personalInfo-main-text">当社保有個人データに関する苦情・相談などにつきましては、下記の窓口までご連絡ください。</p>
                <div className="personalInfo-main-text__mt10">SREホールディングス株式会社 個人情報担当窓口</div>
                <div className="personalInfo-main-text">住所: 〒107-0061 東京都港区北青山3丁目1-2　青山セント・シオンビル 2F</div>
                <div className="personalInfo-main-text">電話番号: 03-6274-6504受付時間: 10：00～18：30</div>
                <div className="personalInfo-main-text">※毎週土曜日・日曜日、ゴールデンウィーク、夏季休業、祝日・年末年始等の当社休日を除く</div>
                <div className="personalInfo-main-subTitle">10.個人情報の安全管理措置等について</div>
                <p className="personalInfo-main-text">お預かりした個人情報はその利用目的の範囲内で正確かつ最新の内容に保つよう努め、不正アクセス、改ざん、漏洩等から守るべく適切な安全管理措置を講じます。<br />また法令により例外として認められる場合を除き、ご本人の同意を得ることなく第三者への開示、提供は行いません。</p>
                <div className="personalInfo-main-subTitle">11.クッキー、ウェブビーコン、その他の類似技術の使用について</div>
                <p className="personalInfo-main-text">当社における、クッキー、ウェブビーコンその他の類似技術の使用については、「ウェブサイトでの情報の取り扱いについて(別サイト)」をご参照ください。</p>
                <div className="personalInfo-main-subTitle">12.その他、個人情報の取り扱い方針について</div>
                <p className="personalInfo-main-text">
                    当社が、ご本人への通知、ご利用約款への同意取得等の方法により、別途、利用目的等を個別に明示した場合には、その個別の利用目的等の内容が、上記の記載に優先いたしますので、ご了承ください。<br />当社の個人情報の取り扱い方針につきましては、「<LinkToOtherDomain to={"https://sre-group.co.jp/terms/privacy.html"} target={"_blank"}>プライバシーポリシー(別サイト)</LinkToOtherDomain>」をご参照ください。
                </p>
                <div className="personalInfo-main-text__mt10">以上</div>
                <div className="personalInfo-main-text__mt10">SREホールディングス株式会社</div>
                <div className="personalInfo-main-text">代表取締役社長</div>
                <div className="personalInfo-main-text">西山　和良</div>
            </div>
        </React.Fragment>
    );
};

/** FRK書式用共有先情報の表示編集 */
function displayShareUserList(props, data) {

    // デフォルト編集（ログインユーザ）
    let shareUserList = [
        {
            "agency_branch_id": props.agencyBranchId,
            "agency_user_id": props.agencyUserId,
            "authority": props.authority,
            "user_name": props.userName,
            "sharing_authority_id": EDIT_AUTHORITY.EIGYO
        }
    ];

    // 関連共有先を追加設定（同所属組織の課長及び場所長）
    for (var i in data) {
        // ログインユーザと重複の場合は追加しない
        if (props.agencyBranchId !== data[i].agency_branch_id
            || props.agencyUserId !== data[i].agency_user_id
            || props.authority !== data[i].authority) {

            // 課長、場所長のみ追加する
            if (data[i].authority === AUTHORITY_FRK.MANAGER.value
                || data[i].authority === AUTHORITY_FRK.AREA_MANAGER.value) {
                shareUserList[shareUserList.length] =
                {
                    "agency_branch_id": data[i].agency_branch_id,
                    "agency_user_id": data[i].agency_user_id,
                    "authority": data[i].authority,
                    "user_name": data[i].user_name,
                    "sharing_authority_id": EDIT_AUTHORITY.CAN_EDIT
                };
            }
        }
    }

    // 表示用共有先
    return shareUserList;

}

/** 表示用権限を取得 */
function displayAuthority(authority) {

    let displayName = undefined;

    if (authority === AUTHORITY_FRK.GENERAL.value) {
        displayName = "(" + AUTHORITY_FRK.GENERAL.display + ")";
    } else if (authority === AUTHORITY_FRK.MANAGER.value) {
        displayName = "(" + AUTHORITY_FRK.MANAGER.display + ")";
    } else if (authority === AUTHORITY_FRK.AREA_MANAGER.value) {
        displayName = "(" + AUTHORITY_FRK.AREA_MANAGER.display + ")";
    } else if (authority === AUTHORITY_FRK.CONTRACT_EXAMINATION.value) {
        displayName = "(" + AUTHORITY_FRK.CONTRACT_EXAMINATION.display + ")";
    }

    // 表示用権限
    return displayName;
}

const mapStateToProps = state => {
    return {
        userName: state.userName,
        agencyUserId: state.agencyUserId,
        agencyBranchId: state.agencyBranchId,
        authority: state.authority,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(loginUserAction, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractRegister));