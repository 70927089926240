import axios from "axios";
import { Footer } from "components/common/parts/footerUnauth";
import { FormButtonRecord, FormInputTextRecord, FormLinkRecord } from "components/common/parts/formRecord";
import * as AWS from "constants/aws";
import { ERROR_MESSAGE } from "constants/errorMessage";
import * as pageInfo from "constants/pageInfo";
import queryString from "query-string";
import React from "react";
import { withRouter } from "react-router-dom";
import Validation from "util/validate";

/** パスワードリマインダー画面 */
class PasswordReminder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginLoadingIndicator: false //ローディング表示中かどうか true:ローディング表示中
        };

        // 入力値のチェック用オブジェクトを生成
        this.validation = new Validation();

        // 画面から入力される値の情報
        this.params = {
            email: {
                label: "メールアドレス",
                placeholder: "メールアドレス",
                validate: this.validation.notEmptyAndIsEmail,
                apiParamKey: "email"
            }
        };

        // クエリストリングに含まれるパスワードの有効期限切れフラグを取得
        const parsedQuery = queryString.parse(this.props.location.search);
        this.isPasswordExpired = 'isExpired' in parsedQuery;
    }

    componentDidMount() {
        document.title = pageInfo.PASSWORD_REMINDER.title;
    }

    /** 入力ボックスの入力値をstateに格納 */
    handleChangeInputText = e => {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    /** 入力ボックスの入力値を空文字、メールアドレス形式チェック */
    handleValidateInputText = e => {
        const target = e.target;
        const param = this.params[target.name];
        param.validate(target.name, target.value);
        this.setState({});
    };

    /** 再発行ボタンのハンドラ */
    handleReissue = async e => {
        e.preventDefault();
        const query = { email_address: this.state.email };

        // 全入力項目のチェックし、エラーメッセージがない場合、後続処理（登録APIの呼出し）をする
        if (!this.validation.validateTotalInputText(this.params, this.state)) {
            // APIコール 仲介_API_パスワード再設定用メール送信
            try {
                this.setState({ loginLoadingIndicator: true });
                await axios.post(
                    `${AWS.ENDPOINT.REST_API.URL}${AWS.ENDPOINT.PATHS.SEND_AGENCY_PASSWORD_RESET_MAIL}`,
                    query,
                );
                this.setState({ loginLoadingIndicator: false });
                alert("メールを送信しました。");
            } catch (error) {
                this.setState({ loginLoadingIndicator: false });
                alert(ERROR_MESSAGE.SERVER_ERROR);
            }
        }
    }

    render() {
        if (this.state.loginLoadingIndicator) {
            //ローディングの回転.svgの位置設定
            const loadCircleTop = window.innerHeight / 2 - 40 + "px"; // -60はLoading画像サイズの半分ずらし
            const loadCircleLeft = window.innerWidth / 2 - 35 + "px"; // -60はLoading画像サイズの半分ずらし
            //ローディングのテキスト付の白枠の位置設定
            const loadFrameTop = window.innerHeight / 2 - 98 + "px"; // --98はLoading画像サイズの半分ずらし
            const loadFrameLeft = window.innerWidth / 2 - 229 + "px"; // --299はLoading画像サイズの半分ずらし

            return <div className="modal_overlay_loading"><img src="/img/loading_frame.png" alt="" style={{ position: "fixed", zIndex: 99, top: loadFrameTop, left: loadFrameLeft }} /> <img src="/img/loading.svg" alt="" style={{ position: "fixed", zIndex: 100, top: loadCircleTop, left: loadCircleLeft }} /></div>;
        }

        // Inputレコードに関する、タグの属性情報
        const inputSetting = { onChange: this.handleChangeInputText, onBlur: this.handleValidateInputText };

        return (
            <main>
                <div className="l-loginField">
                    <div className="l-loginField l-left_area"></div>
                    <div className="l-loginField l-right_area">
                        <div className="l-loginField-component">
                            <form>
                                <div className="loginField loginField--main">
                                    {this.isPasswordExpired && (
                                        <>
                                            <style>
                                                {".password_expired { color: #d7307d; width: 100%; font-size: 14px; margin-bottom: 15px; }"}
                                            </style>
                                            <div className="password_expired">
                                                パスワードの有効期限が過ぎています。<br />
                                                現在登録されているメールアドレスを入力して再発行ボタンをクリックしてください。<br />
                                                パスワード再設定用のメールをお送りいたします。
                                            </div>
                                        </>
                                    )}
                                    <div className="loginField-action">
                                        <FormInputTextRecord titleName={this.params.email.label}
                                            inputData={{ name: "email", value: this.state.email || "" }}
                                            inputSetting={{ placeholder: this.params.email.placeholder, ...inputSetting }}
                                            errorMessage={this.validation.errorMessage.email || ""}
                                        />

                                        <FormButtonRecord onClick={this.handleReissue}>再発行</FormButtonRecord>
                                        <FormLinkRecord to={pageInfo.LOGIN.path} isCenter>ログイン画面へ戻る</FormLinkRecord>
                                    </div>
                                </div>
                            </form >
                        </div>
                        <Footer />
                    </div>
                </div>
            </main>
        );
    }
}

export default withRouter(PasswordReminder);
