import React from "react";
import { Link } from "react-router-dom";
import Input from "../../input/inputNormal";
import Select from "../../input/inputSelect";
import { BlockBase, HeadlineHeader } from "../../parts/blockBase";

/** 土地 */
export default class Block7 extends BlockBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            headlineId: "010107",
        };
        this.TOR00257total = 0;
        this.TOR00257totalNew = 0;
        //初期表示の際に手動入力の合計面積を上書きしない制御用
        this.TOR00257update = 0;
    }

    /** 削除ボタンのハンドラ */
    handleDeleteTOR00237 = e => {
        this.handleDelete(e, "TOR00237_ARR");
    }

    /** 追加ボタンのハンドラ */
    handleAddTOR00237 = e => {

        const addObj = {
            TOR00237: undefined, // 所在
            TOR00238: undefined, // 地番
            // TOR00239: undefined, // 他筆（2件目以降は存在しない項目）
            TOR00240: undefined, // 地目
            TOR00241: undefined, // 地積 公簿
            TOR00242: undefined, // 地積 実測
            TOR00243: undefined, // 持分
        };

        this.handleAdd(e, "TOR00237_ARR", addObj);
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        // アップデートがあった時点で合計値を旧と比較し、差分がある場合は新しい方で更新する
        if (this.TOR00257total !== this.TOR00257totalNew && this.TOR00257update < 3) {
            this.TOR00257total = this.TOR00257totalNew;
            this.TOR00257update += 3;
        } else if (this.TOR00257total !== this.TOR00257totalNew && this.TOR00257update > 2) {
            this.TOR00257total = this.TOR00257totalNew;
            this.props.updatePageState({ TOR00257: this.TOR00257totalNew }, false);
        }
    }

    render() {
        const { hideBlockClass, params } = this.renderCommon();

        // 非表示判定の場合、null(react的な表示なし)を返す
        if (this.dohide(this.props.pageState.hidingHeadline)) { return null; }

        // 動的項目の共通パラメータ
        const arrayName = "TOR00237_ARR"; // 動的項目の代表名
        const paramsForDynamic = {
            ...params,
            arrayName: arrayName,
            mode: "dynamic",
        };

        // ページが持っている動的項目の値を取得する
        const data = this.props.pageState[arrayName];
        let items = [];

        let total = 0;
        data.forEach((obj, idx) => {
            // TOR00219に数値が入っている場合、totalに足す
            if (!(isNaN(obj.TOR00241) || obj.TOR00241 === "")) {
                total += parseFloat(obj.TOR00241);
            }
        });
        this.TOR00257totalNew = total.toFixed(2) + ""; // 文字列へ変換

        // ページが持っている値分だけ動的部分のブロックを生成する
        data.forEach((obj, idx) => {

            let itemTOR00238Name = "地番（代表）";
            let itemTOR00239 = (
                <React.Fragment>
                    <Input idx={idx} id={`ID_TOR00239_${idx}`} name="TOR00239" value={obj.TOR00239} {...paramsForDynamic} length="w112" />
                    <div className="dataTableUnit-text">筆</div>
                </React.Fragment>
            );

            // 2件目以降の表示制御
            if (idx !== 0) {
                itemTOR00238Name = "地番";
                itemTOR00239 = null;
            }

            const element = (
                <React.Fragment key={idx}>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center" rowSpan="5">
                            <div className="dataTable-title-vr">{idx + 1}</div>
                            <div className="dataTable-title-vr" style={{ display: idx === 0 ? "none" : "block" }}>
                                <Link to="" id={`LINK_DEL_TOR00237_ARR_${idx}`} aria-label="link" data-idx={idx} data-array-name={arrayName} className="baseAction baseAction--minus baseAction__w40" onClick={this.handleDeleteTOR00237}>
                                    <span data-idx={idx} className="baseAction-text">削除</span>
                                </Link>
                            </div>
                        </th>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">所在</th>
                        <td className="dataTable-body" colSpan="3">
                            <div className="dataTableUnit">
                                <Input idx={idx} id={`ID_TOR00237_${idx}`} name="TOR00237" value={obj.TOR00237} {...paramsForDynamic} length="wFull" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">
                            {itemTOR00238Name}
                        </th>
                        <td className="dataTable-body" colSpan="3">
                            <div className="dataTableUnit">
                                <Input idx={idx} id={`ID_TOR00238_${idx}`} name="TOR00238" value={obj.TOR00238} {...paramsForDynamic} length="w270" />
                                <div className="dataTableUnit-text">他</div>
                                {itemTOR00239}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">地目</th>
                        <td className="dataTable-body" colSpan="3">
                            <div className="dataTableUnit">
                                <Input idx={idx} id={`ID_TOR00240_${idx}`} name="TOR00240" value={obj.TOR00240} {...paramsForDynamic} length="w270" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">地積</th>
                        <td className="dataTable-body" colSpan="3">
                            <div className="dataTableUnit">
                                <div className="dataTableUnit-text">公簿</div>
                                <Input idx={idx} id={`ID_TOR00241_${idx}`} name="TOR00241" value={obj.TOR00241} {...paramsForDynamic} length="w112" />
                                <div className="dataTableUnit-text">m<sup>2</sup></div>
                                <div className="dataTableUnit-text">実測</div>
                                <Input idx={idx} id={`ID_TOR00242_${idx}`} name="TOR00242" value={obj.TOR00242} {...paramsForDynamic} length="w112" />
                                <div className="dataTableUnit-text">m<sup>2</sup></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="dataTable-title dataTable-title__w120 dataTable-title__center">持分</th>
                        <td className="dataTable-body" colSpan="3">
                            <div className="dataTableUnit">
                                <Input idx={idx} id={`ID_TOR00243_${idx}`} name="TOR00243" value={obj.TOR00243} {...paramsForDynamic} length="w270" />
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            );

            items.push(element);
        });

        return (
            <div className={`dataList dataList__accordion ${hideBlockClass}`}>
                <div className="dataList-header">
                    <div className="dataList-header-title">土地</div>
                    <HeadlineHeader onClick={this.handleHide} hide={this.state.hide} />
                </div>
                <div className="dataList-body">
                    <table className="dataTable">
                        <tbody>
                            {items}
                        </tbody>
                    </table>
                    <table className="dataTable dataTable__border">
                        <tbody>
                            <tr>
                                <td className="dataTable-body dataTable-body__center" colSpan="4">
                                    <Link to="" id="LINK_ADD_TOR00237_ARR" aria-label="link" data-array-name={arrayName} className="baseAction baseAction--plus baseAction__w40" onClick={this.handleAddTOR00237}>
                                        <span className="baseAction-text">追加</span>
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTableUni-title__w120">合計</th>
                                <td className="dataTable-body" colSpan="3">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00256" name="TOR00256" value={this.props.pageState.TOR00256} {...params} length="w112" />
                                        <div className="dataTableUnit-text">筆</div>
                                        <div className="dataTableUnit-text">地積（登記簿）</div>
                                        <Input id="ID_TOR00257" name="TOR00257" value={this.props.pageState.TOR00257} {...params} length="w270" />
                                        <div className="dataTableUnit-text">m<sup>2</sup></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">敷地の形状</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <Select id="ID_TOR00056" name="TOR00056" value={this.props.pageState.TOR00056} items={this.getSelectbox(this.props.selectboxData.MEI00003)} {...params} />
                                </td>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">道路種別</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <Select id="ID_TOR00057" name="TOR00057" value={this.props.pageState.TOR00057} items={this.getSelectbox(this.props.selectboxData.MEI00004)} {...params} />
                                </td>
                            </tr>
                            <tr>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">接道方向</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00058" name="TOR00058" value={this.props.pageState.TOR00058} {...params} length="wFull" />
                                    </div>
                                </td>
                                <th className="dataTable-title dataTable-title__center dataTable-title__w120">幅員</th>
                                <td className="dataTable-body dataTable-body__w355">
                                    <div className="dataTableUnit">
                                        <Input id="ID_TOR00059" name="TOR00059" value={this.props.pageState.TOR00059} {...params} />
                                        <div className="dataTableUnit-text">m</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}