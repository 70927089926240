import { TEMPLATE_POPUP as TP } from "constants/contractEditPageInfo";
import React from "react";
import { Link } from "react-router-dom";
import * as helper from "../helper";
import { InputBase } from "./inputBase";

export default class InputTextRemark extends InputBase {

    constructor(props) {
        super(props);

        this.state = {
            unit: "", // 表示する単位
            length: this.props.length, // 表示幅
            errorDisp: false // true: 表示する
        };

        // 先祖から設定された値の検証
        let tmp = {};
        tmp[helper.genValidKey(this.props.name)] = this.validate(this.props.value); // 有効化かどうか（true:有効）
        this.props.updatePageState(tmp, false); // 先祖のstateを更新するのでレンダリングが走る
    }

    /** 入力欄更新時のハンドラ */
    handleUpdate = e => {
        let inputValue = e.target.value;

        // 値の更新（先祖コンポーネントから渡されたstateの更新）
        let tmp = {};
        tmp[this.props.name] = inputValue; // 値
        tmp[helper.genValidKey(this.props.name)] = this.validate(inputValue); // 有効化かどうか（true:有効）
        this.props.updatePageState(tmp); // 先祖のstateを更新するのでレンダリングが走る
    }

    /** 初期表示用の処理 */
    componentDidMount() {
        /** 初期表示時に空の場合はエラーメッセージを出さない */
        if (this.props.value === undefined) { return; }

        /** 初期表示時に異常値の場合はエラーメッセージを出す */
        let inputValue = this.props.value;
        if (!this.validate(inputValue)) {
            this.setState({ errorDisp: true });
        }
    }

    /**
     * フォーカスアウト時のハンドラ
     * ※フォーカスアウトした際にエラーメッセージを出すかどうかを決める
     **/
    handlerBlur = e => {
        let inputValue = e.target.value;
        if (!this.validate(inputValue)) {
            this.setState({ errorDisp: true });
        } else {
            this.setState({ errorDisp: false });
        }
    }

    /** 表示する際に整形が必要な場合、表示用の変換形式をここで定義 */
    formatter = (str) => {
        return str;
    }

    /**
     * バリデートロジックを定義する。
     * true: エラー無し false: エラー有り
     **/
    validate = (val) => {
        // 入力無しは許容
        //
        // ※valの状態は下記を前提とする
        // 最初から入力欄が空だった場合はundefined
        // 変更して空にした場合は空文字列
        if (val === undefined) {
            return undefined;
        }
        return true;
    }

    /**
     * ポップアップの表示制御を行う
     * ・定型文一覧のポップアップの表示フラグをtrueへ変更する
     * ・ポップアップの入力時の値設定ターゲットをnameで指定された項目へ変更する
     */
    handlePopup = e => {
        e.preventDefault();
        this.props.updatePageState({ dispPopupTmpl: true, popupTarget: this.props.name, popupFrom: TP.TEXT });
    }

    handleSendPage = e => {
        e.preventDefault();
        const textarea = document.querySelector(`#${this.props.id}`);
        let sentence = this.props.pageState[this.props.name] || "";
        const len = sentence.length;
        const pos = textarea.selectionStart;
        const before = sentence.substr(0, pos);
        const word = this.props.text;
        const after = sentence.substr(pos, len);
        sentence = before + word + after;

        // 値の更新（先祖コンポーネントから渡されたstateの更新）
        this.props.updatePageState({
            [this.props.name]: `${sentence}`
        }); // 先祖のstateを更新するのでレンダリングが走る
    }

    render() {
        const disabled = this.isDisabled(this.props.pageState.disableItem);
        this.handlePopup = disabled ? e => { e.preventDefault(); } : this.handlePopup; // disabledの時はルーペボタン無効化
        this.handleSendPage = disabled ? e => { e.preventDefault(); } : this.handleSendPage; // disabledの時は送りボタン無効化

        let className = "textarea__h165";
        if (this.props.height === "h43") { className = "textarea__h43"; }
        if (this.props.height === "h84") { className = "textarea__h84"; }
        if (this.props.height === "h92") { className = "textarea__h92"; }
        if (this.props.height === "h148") { className = "textarea__h148"; }
        if (this.props.height === "h165") { className = "textarea__h165"; }

        // propsとしてvalueが設定がされていない場合、空文字列
        let dispValue = this.props.value === undefined ? "" : this.props.value + "";

        // propsとしてplaceholderが設定がされていない場合、空文字列
        let defText = this.props.placeholder === undefined ? "" : this.props.placeholder + "";

        // バリデートエラーの場合、入力値をそのまま出力する
        // バリデートエラーでない場合、formatterをかまして画面表示
        dispValue = this.validate(dispValue) ? this.formatter(dispValue) : dispValue;

        // ルーペボタンを表示するかどうか
        const loopeDOM = this.props.disableLoope ? null : <li><Link to="" aria-label="link" className="textAreaAction textAreaAction--loupe" onClick={this.handlePopup}></Link></li>;

        // 別紙送りボタンを表示するかどうか
        const returnDOM = this.props.disableReturn ? null : <li><Link to="" aria-label="link" className="textAreaAction textAreaAction--return" onClick={this.handleSendPage}></Link></li>;

        return (
            <React.Fragment>
                <ul className="dataTableTextUnit-list">
                    {loopeDOM}
                    {returnDOM}
                </ul>
                <div className="dataTableTextUnit-body">
                    <textarea id={this.props.id} aria-label="textarea" name={this.props.name}
                        onChange={this.handleUpdate} onBlur={this.handlerBlur} value={dispValue} className={className} disabled={disabled} placeholder={defText}>
                    </textarea>
                </div>
            </React.Fragment>
        );
    }
}