import { ERROR_MESSAGE } from "constants/errorMessage";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import * as AWS from "../../../../../../constants/aws";
import * as awsReq from "../../../../../../util/awsRequest";
import PopupBaseMultiSelect from "./popupBaseMultiSelect";

/** ポップアップ画面：定型文一覧 */
class PopupTmpleteList extends PopupBaseMultiSelect {
    constructor(props) {
        super(props);

        this.apicalling = false; // APIコール中制御 true: APIコール中
        this.selectValue = "";
    }

    // キーワード入力時のハンドラ
    handleKeyword = e => {
        e.preventDefault();
        this.setState({ keyword: e.target.value });
    }

    // クリアボタン押下のハンドラ
    handleClear = e => {
        e.preventDefault();
        this.selectedIdx = [];
        this.setState({ keyword: "", data: undefined });
    }

    // ポップアップの中身を表示するためのAPIリクエスト
    RequestAPI = () => {
        if (!this.apicalling) {
            this.apicalling = true;
            this.selectValue = "";

            /** 定型文一覧取得 */

            // APIに送信する template_headline_id を項目ID(例：TORXXXXX)から引く
            const targetHeadlineId = mapItemIdToHeadlineId[this.props.to];

            let query = {};

            // キーワードがある時はキーワードをクエリに入れる
            if (this.state.keyword !== "") {
                query.keyword = this.state.keyword;
            }

            awsReq.post(
                AWS.ENDPOINT.PATHS.GET_POPUP_TEMPLATE_LIST
                    .replace(":templateHeadlineId", targetHeadlineId),
                res => {
                    this.apicalling = false;
                    this.setState({
                        data: res.data.template,
                        headline: res.data.template_headline,
                        totalItemsCount: res.length
                    });
                },
                error => {
                    this.apicalling = false;
                    this.setState({ data: [] });
                    alert(ERROR_MESSAGE.SERVER_ERROR);
                },
                query,
                {}, AWS.ENDPOINT.REST_API.NAME,
            );
        }
    }

    handlePickup = e => {
        e.preventDefault();
        this.setState({ data: undefined });
    }

    // 選択済みのチェックボックスの制御
    handleCheck = i => {
        // 選択済みの場合、除外する
        if (this.selectedIdx.includes(i)) {
            this.selectedIdx = this.selectedIdx.filter(val => { return i !== val; });
        } else {
            // 未選択の場合、追加する
            this.selectedIdx.push(i);
        }
        this.pickUpCheck();
        this.setState({}); // 再renderしないと画面が更新されないので空打ちする
    }

    // 選択済み項目をピックアップして表示エリアに設定する
    pickUpCheck = () => {
        this.selectValue = this.selectString().join("\n\n") + "\n";
    }

    // 一覧表示のテーブル部分のDOMを生成
    createTable = list => {
        const ret = (
            <div className='modalBox-main tabindex="0"'>
                <table className="dataTable dataTable__fullBorder">
                    <tbody>
                        {list}
                    </tbody>
                </table>
            </div>
        );
        return ret;
    }

    // リスト表示用のDOMを生成
    createItems = data => {
        let list = [];
        data.forEach((element, idx) => {
            const item = (
                <tr key={idx}>
                    <td className="dataTable-title dataTable-title__w60">
                        <label className="checkBox checkBox__notext">
                            <input type="checkbox" className="checkBox-input" onChange={e => { this.handleCheck(idx); }} checked={this.selectedIdx.includes(idx)} />
                            <span className="checkBox-text"></span>
                        </label>
                    </td>
                    <th className="dataTable-title dataTable-title__w80">項目</th>
                    <td className="dataTable-body">
                        <Tooltip trigger="mouseenter" distance='5' arrow='true' title={element.template}>
                            <p className="dataTable-text">{element.template_item}</p>
                        </Tooltip>
                    </td>
                </tr>
            );
            list.push(item);
        });
        return this.createTable(list);
    }

    // Loading中の空行DOMを生成
    createEmptyItems = () => {
        let list = [];
        for (let i = 0; i < this.state.itemsCountPerPage; i++) {
            list.push(
                <tr key={i}>
                    <td className="dataTable-title dataTable-title__w60">
                        <label className="checkBox checkBox__notext">
                            <input type="checkbox" className="checkBox-input" checked={false} readOnly />
                            <span className="checkBox-text"></span>
                        </label>
                    </td>
                    <th className="dataTable-title dataTable-title__w152">　</th>
                    <td className="dataTable-body">　</td>
                </tr>
            );
        }

        return this.createTable(list);
    }

    // ポップアップ表示用のDOMを生成
    createFrame = (disp, table, pageSettings) => {

        // APIから値を取得している間は絞り込むボタンを押下させないようにする
        const pickupParams = this.state.data !== undefined ?
            { className: "baseAction", onClick: this.handlePickup }
            : { className: "baseAction baseAction-inactive", onClick: e => e.preventDefault() };

        return (
            <React.Fragment>
                <div className="l-modal_overlay is-active">
                    <div className="modal_overlay"></div>
                </div>
                <div className="l-modalBox">
                    <div className="modalBox">
                        <div className="modalBox-top">
                            <table className="dataTable dataTable__fullBorder">
                                <tbody>
                                    <tr>
                                        <th className="dataTable-title dataTable-title__w120">見出し</th>
                                        <td className="dataTable-body dataTable-body__w355">{this.state.headline}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="l-dataTable">
                                <table className="dataTable dataTable__fullBorder">
                                    <tbody>
                                        <tr>
                                            <th className="dataTable-title dataTable-title__w120">キーワード</th>
                                            <td className="dataTable-body dataTable-body__w355"><input type="text" aria-label="text" onChange={this.handleKeyword} value={this.state.keyword} /></td>
                                        </tr>
                                        <tr>
                                            <th className="dataTable-title dataTable-title__w120">&nbsp;</th>
                                            <td className="dataTable-body dataTable-body__w355">
                                                <ul className="l-baseAction l-baseAction__rightTwo">
                                                    <li><Link aria-label="link" to="" {...pickupParams}>絞り込み</Link></li>
                                                    <li><Link aria-label="link" to="" className="baseAction" onClick={this.handleClear}>クリア</Link></li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {table}
                        <div className="modalBox-main-edit">
                            <textarea className="modalBox-main-input" readOnly value={this.selectValue} />
                        </div>
                        <div className="modalBox-bottom">
                            <ul className="l-baseAction l-baseAction__rightTwo">
                                <li><Link aria-label="link" to="" className="baseAction" onClick={this.handleSelect}>選択</Link></li>
                                <li><Link aria-label="link" to="" className="baseAction" onClick={this.handleCancel}>キャンセル</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(PopupTmpleteList);

/** 項目に対してどのテンプレートが必要かのマッピング */
const mapItemIdToHeadlineId = {
    "TOR00199": "TPL00022",
    "JYU00147": "TPL00003",
    "JYU00196": "TPL00031",
    "JYU00296": "TPL00032",
    "JYU00322": "TPL00004",
    "JYU00327": "TPL00005",
    "JYU00331": "TPL00006",
    "JYU00412": "TPL00007",
    "JYU00513": "TPL00008",
    "JYU00558": "TPL00009",
    "JYU00587": "TPL00010",
    "JYU00693": "TPL00011",
    "JYU00701": "TPL00012",
    "JYU00705": "TPL00013",
    "JYU00785": "TPL00014",
    "JYU00804": "TPL00023",
    "JYU00813": "TPL00024",
    "JYU00833": "TPL00025",
    "JYU00918": "TPL00015",
    "JYU00934": "TPL00026",
    "JYU00979": "TPL00017",
    "JYU00982": "TPL00027",
    "JYU01013": "TPL00028",
    "JYU01020": "TPL00018",
    "JYU01100": "TPL00019",
    "JYU01121": "TPL00020",
    "JYU01457": "TPL00030",
    "JYU01222": "TPL00021",
    "FUD00336": "TPL00001",
    "FUD00109": "TPL00001",
    "FUD00135": "TPL00001",
    "FUD00202": "TPL00029",
    "FUD00333": "TPL00002",
};